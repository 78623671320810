import React from 'react';

interface UnGroupIconProps {
  width?: number; // Optional size prop to control the icon size
  height?: number; // Optional size prop to control the icon size
  color?: string; // Optional color prop to control the icon color
}

export const UnGroupIcon: React.FC<UnGroupIconProps> = ({ width = 24, height = 24, color = '#251D38' }: UnGroupIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <rect width="8" height="6" x="5" y="4" rx="1" />
      <rect width="8" height="6" x="11" y="14" rx="1" />
    </svg>
  );
};
