import { Box, MenuItem, SelectChangeEvent, Stack, Typography } from '@mui/material';
import { TasksTabs } from './TasksTabs';
import { TaskViewModeTabs } from './TaskViewModeTabs';
import { useState } from 'react';
import { ActionsIcon, ChevronDownIcon, RowCenterStack, StyledFilledInput, StyledFixedWidthTypography } from '@/shared';
import { Dropdown } from './styled/Dropdown';
import { capitalize } from 'lodash';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { selectActivePeopleDirViewCategories, selectCategoryListItems, selectPartiallySelectedCategories } from '@/features/people-directory';
import { NewTaskButton, selectTaskViewMode, TasksDropdownSearch, TaskVewFilterDropdown, TaskViewMode } from '@features/tasks';
import { DrawerType, openDrawer, OpenDrawerRequest } from '@features/drawer';
import { useTranslation } from 'react-i18next';

export const TasksHeader = () => {
  const { t } = useTranslation(['common, tasks']);
  const dispatch = useAppDispatch();
  const [groupBy, setGroupBy] = useState('dueDate');

  const taskViewMode = useAppSelector(selectTaskViewMode);
  // temp
  const categoryListItems = useAppSelector(selectCategoryListItems);
  const activePeopleDirViewCategories = useAppSelector(selectActivePeopleDirViewCategories);
  const partiallySelectedCategories = useAppSelector(selectPartiallySelectedCategories);

  const handleAllowedCaseChange = (e: SelectChangeEvent<string>) => {
    setGroupBy(e.target.value);
  };

  const handleCategorySelect = (selectedOption: { id: string; name: string }, isEnabled: boolean) => {};

  return (
    <Stack
      gap={2}
      sx={{
        padding: 2,
        borderRadius: 4,
        border: '1px solid',
        borderColor: (theme) => theme.palette.customTheme.borderMaster,
        backgroundColor: (theme) => theme.palette.customTheme.bgr,
        zIndex: (theme) => theme.zIndex.appBar
      }}
    >
      <RowCenterStack sx={{ justifyContent: 'space-between' }}>
        <TaskViewModeTabs />
        <RowCenterStack gap={'10px'}>
          <TaskVewFilterDropdown />
          <NewTaskButton
            onClick={() => {
              dispatch(openDrawer({ type: DrawerType.TasksAction } as OpenDrawerRequest));
            }}
            startIcon={<ActionsIcon color="#FFFFFF" />}
          >
            {t('new_task', { ns: 'tasks' })}
          </NewTaskButton>
        </RowCenterStack>
      </RowCenterStack>
      {taskViewMode !== TaskViewMode.SingleTask && (
        <>
          <RowCenterStack sx={{ justifyContent: 'space-between' }}>
            <RowCenterStack gap="10px">
              <TasksTabs />
              <Dropdown
                value={groupBy}
                onChange={(e) => handleAllowedCaseChange(e)}
                variant="outlined"
                IconComponent={ChevronDownIcon}
                renderValue={(value) => {
                  return (
                    <RowCenterStack gap={'4px'} sx={{ height: 20 }}>
                      <Typography sx={{ fontWeight: 400, fontSize: 12 }}>Group by |</Typography>
                      <Typography sx={{ fontWeight: 700, fontSize: 12 }}>{capitalize(value)}</Typography>
                    </RowCenterStack>
                  );
                }}
              >
                <MenuItem value={'dueDate'}>Due Date</MenuItem>
                <MenuItem value={'status'}>Status</MenuItem>
              </Dropdown>
            </RowCenterStack>
            {/* Temp */}
            <RowCenterStack gap={'4px'}>
              <TasksDropdownSearch
                options={categoryListItems}
                values={activePeopleDirViewCategories}
                partiallySelected={partiallySelectedCategories}
                label={'Search query'}
                onOptionSelect={handleCategorySelect}
              />
              <Box sx={{ width: 249 }}>
                <StyledFilledInput label="Search" value="-" />
              </Box>
            </RowCenterStack>
          </RowCenterStack>
        </>
      )}
    </Stack>
  );
};
