import { Empty } from '@bufbuild/protobuf/wkt';
import { createClient } from '@connectrpc/connect';
import { createGrpcWebTransport } from '@connectrpc/connect-web';
import {
  AudienceService,
  CreateAudienceRequest,
  CreateAudienceResponse,
  DeleteAudienceRequest,
  RetrieveAudienceByIdRequest,
  RetrieveAudienceByIdResponse,
  RetrieveAudienceDrawerItemsByEmployeeIdRequest,
  RetrieveAudienceDrawerItemsByEmployeeIdResponse,
  RetrieveAudiencesByEmployeeIdRequest,
  RetrieveAudiencesByEmployeeIdResponse,
  RetrieveAudiencesByIdsRequest,
  RetrieveAudiencesByIdsResponse,
  RetrieveAudiencesRequest,
  RetrieveAudiencesResponse,
  RetrieveAudiencesWithPostingRightsByEmployeeIdRequest,
  RetrieveAudiencesWithPostingRightsByEmployeeIdResponse,
  RetrieveEmployeeIdsByAudienceIdRequest,
  RetrieveEmployeeIdsByAudienceIdResponse,
  UpdateAudienceRequest,
  UpdateAudienceResponse,
  RetrieveAudienceMemberCountRequest,
  RetrieveAudienceMemberCountResponse,
  LeaveAudienceRequest
} from '@thrivea/networking-client';
import { msalInstance } from '@/main';
import { tokenRequest } from '@utils/authConfig';

const audienceClient = createClient(
  AudienceService,
  createGrpcWebTransport({
    baseUrl: import.meta.env.VITE_APP_API_URL,
    useBinaryFormat: true
  })
);

export const retrieveAudiencesByEmployeeId = async (request: RetrieveAudiencesByEmployeeIdRequest): Promise<RetrieveAudiencesByEmployeeIdResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await audienceClient.retrieveAudiencesByEmployeeId(request, { headers: new Headers({ Authorization: `Bearer ${account.accessToken}` }) });
};

export const createAudience = async (request: CreateAudienceRequest): Promise<CreateAudienceResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await audienceClient.createAudience(request, { headers: new Headers({ Authorization: `Bearer ${account.accessToken}` }) });
};

export const updateAudience = async (request: UpdateAudienceRequest): Promise<UpdateAudienceResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await audienceClient.updateAudience(request, { headers: new Headers({ Authorization: `Bearer ${account.accessToken}` }) });
};

export const deleteAudience = async (request: DeleteAudienceRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await audienceClient.deleteAudience(request, { headers: new Headers({ Authorization: `Bearer ${account.accessToken}` }) });
};

export const retrieveAudiences = async (request: RetrieveAudiencesRequest): Promise<RetrieveAudiencesResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await audienceClient.retrieveAudiences(request, { headers: new Headers({ Authorization: `Bearer ${account.accessToken}` }) });
};

export const retrieveEmployeeIdsByAudienceId = async (request: RetrieveEmployeeIdsByAudienceIdRequest): Promise<RetrieveEmployeeIdsByAudienceIdResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await audienceClient.retrieveEmployeeIdsByAudienceId(request, { headers: new Headers({ Authorization: `Bearer ${account.accessToken}` }) });
};

export const retrieveAudienceById = async (request: RetrieveAudienceByIdRequest): Promise<RetrieveAudienceByIdResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await audienceClient.retrieveAudienceById(request, { headers: new Headers({ Authorization: `Bearer ${account.accessToken}` }) });
};

export const retrieveAudienceDrawerItemsByEmployeeId = async (
  request: RetrieveAudienceDrawerItemsByEmployeeIdRequest
): Promise<RetrieveAudienceDrawerItemsByEmployeeIdResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await audienceClient.retrieveAudienceDrawerItemsByEmployeeId(request, { headers: new Headers({ Authorization: `Bearer ${account.accessToken}` }) });
};

export const retrieveAudiencesWithPostingRightsByEmployeeId = async (
  request: RetrieveAudiencesWithPostingRightsByEmployeeIdRequest
): Promise<RetrieveAudiencesWithPostingRightsByEmployeeIdResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await audienceClient.retrieveAudiencesWithPostingRightsByEmployeeId(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveAudiencesByIds = async (request: RetrieveAudiencesByIdsRequest): Promise<RetrieveAudiencesByIdsResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await audienceClient.retrieveAudiencesByIds(request, { headers: new Headers({ Authorization: `Bearer ${account.accessToken}` }) });
};

export const retrieveAudienceMemberCount = async (request: RetrieveAudienceMemberCountRequest): Promise<RetrieveAudienceMemberCountResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await audienceClient.retrieveAudienceMemberCount(request, { headers: new Headers({ Authorization: `Bearer ${account.accessToken}` }) });
};

export const leaveAudience = async (request: LeaveAudienceRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await audienceClient.leaveAudience(request, { headers: new Headers({ Authorization: `Bearer ${account.accessToken}` }) });
};
