import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@app/store';
import { ActionStatus } from '@shared/shared.model';
import { EmployeeListItem, RetrieveEmployeesByQueryRequest, RetrieveEmployeesByQueryResponse } from '@thrivea/organization-client';
import { groupEmployeesAlphabetically } from '@/utils';

interface EmployeesState {
  entities: {
    employeeListItems: {
      byId: { [key: string]: EmployeeListItem };
      allIds: string[];
    };
  };
  ui: {
    employeeListItemsStatus: ActionStatus;
  };
}

const initialState: EmployeesState = {
  entities: {
    employeeListItems: {
      byId: {},
      allIds: []
    }
  },
  ui: {
    employeeListItemsStatus: ActionStatus.Idle
  }
};

export const employeesSlice = createSlice({
  name: 'employees',
  initialState,
  reducers: {
    retrieveEmployeesRequested: (state, action: PayloadAction<RetrieveEmployeesByQueryRequest>) => {
      state.ui.employeeListItemsStatus = ActionStatus.Pending;
    },
    retrieveEmployeesSucceeded: (state, action: PayloadAction<RetrieveEmployeesByQueryResponse>) => {
      const { employees } = action.payload.employeeResults!;

      for (const employee of employees) {
        state.entities.employeeListItems.byId[employee.employeeId] = employee;
        state.entities.employeeListItems.allIds.push(employee.employeeId);
      }

      state.ui.employeeListItemsStatus = ActionStatus.Idle;
    },
    retrieveEmployeesFailed: (state) => {
      state.ui.employeeListItemsStatus = ActionStatus.Failed;
    }
  }
});

export const selectEmployeeListItemsStatus = (state: RootState) => state.employees.ui.employeeListItemsStatus;

const selectEmployeeListItemsById = (state: RootState) => state.employees.entities.employeeListItems.byId;
const selectEmployeeListItemsIds = (state: RootState) => state.employees.entities.employeeListItems.allIds;

export const selectEmployeeListItems = createSelector([selectEmployeeListItemsById, selectEmployeeListItemsIds], (byId, allIds) =>
  allIds.map((id) => byId[id])
);

const selectAutocompleteEmployeeItemsGroupedAlphabetically = createSelector([selectEmployeeListItems], (items) => groupEmployeesAlphabetically(items));

export const selectTaskAssigneesAutocompleteOptionsGroupedAlphabetically = createSelector(
  [selectAutocompleteEmployeeItemsGroupedAlphabetically],
  (groupedEmployeesAlphabetically) => Object.keys(groupedEmployeesAlphabetically).flatMap((key) => groupedEmployeesAlphabetically[key])
);

export const selectEmployeeAuthorById = createSelector([selectEmployeeListItemsById, (_, authorId: string) => authorId], (byId, authorId) => byId[authorId]);

export const { retrieveEmployeesRequested, retrieveEmployeesSucceeded, retrieveEmployeesFailed } = employeesSlice.actions;
export default employeesSlice.reducer;
