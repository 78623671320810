import React, { useState, useEffect, Fragment, useMemo } from 'react';
import { Button, Skeleton, Stack, Typography } from '@mui/material';
import { RowCenterEndStack, RowCenterStack, StyledBlurryModal, StyledTransparentFilledInput, StyledWorkDayFilledInput } from '@/shared';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { DayOfWeek, UpdateWorkingPatternRequest, WorkDay, WorkingPatternListItem } from '@thrivea/organization-client';
import {
  createWorkingPatternSchema,
  ModalCloseButton,
  ModalContent,
  selectSingleWorkingPattern,
  updateWorkingPatternByIdRequested
} from '@features/admin-settings';
import { zodResolver } from '@hookform/resolvers/zod';

interface AdminSettingsWorkingPatternEditProps {
  isOpen: boolean;
  handleCloseModal: () => void;
  workingPatternListItem: WorkingPatternListItem;
}

const dayOfWeek = Object.keys(DayOfWeek)
  .filter((key) => key !== 'DAY_OF_WEEK_UNSPECIFIED' && isNaN(Number(key))) // Filter out 'DAY_OF_WEEK_UNSPECIFIED' and numeric keys
  .map((key) => ({
    id: DayOfWeek[key as keyof typeof DayOfWeek],
    name: key
      .toLowerCase()
      .replace(/_/g, ' ')
      .replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
  }));

export const AdminSettingsWorkingPatternEdit: React.FC<AdminSettingsWorkingPatternEditProps> = ({ isOpen, handleCloseModal, workingPatternListItem }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['common', 'sites_working_patterns']);
  const workingPattern = useAppSelector(selectSingleWorkingPattern);
  const [totalHours, setTotalHours] = useState(workingPatternListItem.weeklyHours);
  const workingPatternSchema = useMemo(() => createWorkingPatternSchema(t), [t]);
  const { handleSubmit, control, reset, getValues } = useForm<UpdateWorkingPatternRequest>({
    mode: 'all',
    resolver: zodResolver(workingPatternSchema),
    defaultValues: {
      name: workingPatternListItem.name,
      description: workingPatternListItem.description,
      workDays: workingPattern.workWeek!.workDays
    }
  });

  const onSubmit = (data: UpdateWorkingPatternRequest) => {
    dispatch(
      updateWorkingPatternByIdRequested(
        new UpdateWorkingPatternRequest({
          workingPatternId: workingPatternListItem.id,
          name: data.name,
          description: data.description,
          workDays: dayOfWeek.map(
            (dow, index) =>
              new WorkDay({
                id: data.workDays[index].id,
                day: dow.id,
                hours: data.workDays[index].hours
              })
          )
        })
      )
    );
    handleCloseModal();
  };

  useEffect(() => {
    if (workingPattern.workWeek!.workDays.length === 0) return;
    reset({
      name: workingPatternListItem.name,
      description: workingPatternListItem.description,
      workDays: workingPattern.workWeek!.workDays
    });
  }, [workingPattern]);

  return (
    <StyledBlurryModal open={isOpen} onClose={handleCloseModal}>
      <ModalContent>
        <Stack component="form" onSubmit={handleSubmit(onSubmit)} gap={3}>
          <RowCenterStack>
            <Typography variant="h4">{t('edit_working_pattern', { ns: 'settings_working_patterns', value: workingPatternListItem.name })}</Typography>
            <ModalCloseButton onClick={handleCloseModal}>
              <CloseIcon />
            </ModalCloseButton>
          </RowCenterStack>
          <Typography variant="body1">{t('add_working_pattern_form.title', { ns: 'settings_working_patterns' })}</Typography>
          <Controller
            name="name"
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState }) => (
              <StyledTransparentFilledInput
                {...field}
                required
                id={field.name}
                label={t('add_working_pattern_form.name_label', { ns: 'settings_working_patterns' })}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
          <Controller
            name="description"
            control={control}
            render={({ field, fieldState }) => (
              <StyledTransparentFilledInput
                {...field}
                multiline
                minRows={3}
                id={field.name}
                label={t('add_working_pattern_form.desc_label', { ns: 'settings_working_patterns' })}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
          <RowCenterStack>
            {workingPattern.workWeek!.workDays.length === 0
              ? dayOfWeek.map((d, i) => <Skeleton variant="rectangular" width={55} height={40} key={d.id} />)
              : dayOfWeek.map((day, index) => (
                  <Fragment key={day.id}>
                    <Controller
                      name={`workDays.${index}.hours`}
                      control={control}
                      render={({ field, fieldState }) => (
                        <Stack>
                          <StyledWorkDayFilledInput
                            {...field}
                            value={field.value ?? 0}
                            label={t(`add_working_pattern_form.${day.name.toLowerCase()}`, { ns: 'settings_working_patterns' })}
                            id={`working_pattern_${day.name}`}
                            width={55}
                            onChange={(e) => {
                              const newValue = e.target.value === '' ? 0 : parseFloat(e.target.value);
                              field.onChange(newValue);

                              // Calculate total hours
                              const workDays = getValues().workDays;
                              const newTotalHours = workDays.reduce((total, day) => total + day.hours, 0);
                              setTotalHours(newTotalHours);
                            }}
                            slotProps={{ input: { inputMode: 'numeric' } }}
                            error={!!fieldState.error}
                            // helperText={fieldState.error ? fieldState.error.message : null}
                          />
                        </Stack>
                      )}
                    />
                  </Fragment>
                ))}
          </RowCenterStack>
          <RowCenterStack gap={1}>
            <Typography variant="body1">{t('add_working_pattern_form.weekly_total', { ns: 'settings_working_patterns' })}</Typography>
            <Typography variant="body1">{totalHours} hours</Typography>
          </RowCenterStack>
          <RowCenterEndStack gap={2}>
            <Button variant="outlined" onClick={handleCloseModal}>
              {t('cancel', { ns: 'common' })}
            </Button>
            <Button type="submit" variant="contained">
              {t('save', { ns: 'common' })}
            </Button>
          </RowCenterEndStack>
        </Stack>
      </ModalContent>
    </StyledBlurryModal>
  );
};
