import { styled } from '@mui/material';
import { TreeItem2Content } from '@mui/x-tree-view/TreeItem2';

export const AssetsTreeItemContent = styled(TreeItem2Content)(({ theme }) => ({
  width: '100%',
  height: 25,
  flexDirection: 'row-reverse',
  borderRadius: theme.spacing(0.7),
  margin: '0',
  padding: '4px 8px',
  color: theme.palette.common.black,
  fontSize: 14,
  fontWeight: 400,
  backgroundColor: theme.palette.customTheme.headerColorSub,
  transition: theme.transitions.create('border-left', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.standard
  }),
  '&:hover': {
    borderLeft: `8px solid ${theme.palette.primary.dark}`
  },
  [`&.Mui-focused`]: {
    backgroundColor: theme.palette.customTheme.selectItem
  }
}));
