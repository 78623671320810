import { IconButton, iconButtonClasses, IconButtonProps, styled } from '@mui/material';

interface TasksCollapseButtonProps extends IconButtonProps {
  isCollapsed?: boolean;
}

export const TasksCollapseButton = styled(({ isCollapsed, ...props }: TasksCollapseButtonProps) => <IconButton {...props} />, {
  shouldForwardProp: (prop) => prop !== 'isCollapsed'
})(({ isCollapsed }) => ({
  backgroundColor: '#F0F0F0',
  padding: '4px',
  borderRadius: '4px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  gap: '8px',
  '& svg': {
    transform: `rotate(${isCollapsed ? 180 : 0}deg)`
  }
}));
