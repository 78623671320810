import { Button, ButtonProps, alpha, styled } from '@mui/material';

export const NewTaskButton = styled(Button)<ButtonProps>(({ theme }) => ({
  backgroundColor: theme.palette.common.black,
  borderColor: theme.palette.customTheme.contColor,
  borderRadius: '8px',
  color: theme.palette.common.white,
  padding: 12,
  height: 43,
  fontWeight: 700,
  '&.Mui-disabled': {
    opacity: '0.25',
    backgroundColor: '#1F2020',
    borderColor: 'transparent',
    color: theme.palette.common.white,
    '& path': {
      fill: theme.palette.common.white
    }
  }
}));
