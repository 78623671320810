import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  openDrawer,
  closeDrawer,
  selectCurrentDrawerType,
  AssetsDrawerRequest,
  AssetsMultipleFilesDrawerRequest,
  AssetsShareFileDrawerRequest,
  AudienceDrawerRequest,
  AudienceRoleDrawerRequest,
  DrawerType,
  EmployeeRecordDrawerRequest,
  JobTitleDrawerRequest,
  NotificationsDrawerRequest,
  OpenDrawerRequest,
  PostAnalyticsDrawerRequest,
  SingleEmployeeDrawerRequest,
  SiteDrawerRequest,
  WorkingPatternDrawerRequest,
  TasksEditTaskDrawerRequest,
  openTasksEditTaskDrawer
} from '@features/drawer';
import { selectMultipleFiles } from '../assets';
import {
  openSiteDrawer,
  openSingleEmployeeDrawer,
  openWorkingPatternDrawer,
  openAssetsDrawer,
  openAssetsMultipleFilesDrawer,
  openJobTitle,
  openAudience,
  openPostAnalytics,
  openAudienceRole,
  openNotifications,
  openAssetsShareFile,
  openEmployeeRecord
} from './drawer.service';

function* openDrawerGenerator(action: PayloadAction<OpenDrawerRequest>) {
  const { type } = action.payload;

  switch (type) {
    case DrawerType.Site:
      const { siteId } = action.payload.request as SiteDrawerRequest;

      yield call(openSiteDrawer, siteId);

      break;
    case DrawerType.SingleEmployee:
      const { employeeId } = action.payload.request as SingleEmployeeDrawerRequest;

      yield call(openSingleEmployeeDrawer, employeeId);

      break;
    case DrawerType.WorkingPattern:
      const { workingPatternId, workingPatternName } = action.payload.request as WorkingPatternDrawerRequest;

      yield call(openWorkingPatternDrawer, workingPatternId, workingPatternName);

      break;
    case DrawerType.JobTitle:
      const { jobTitle } = action.payload.request as JobTitleDrawerRequest;

      yield call(openJobTitle, jobTitle);

      break;
    case DrawerType.Audience:
      const { audienceId } = action.payload.request as AudienceDrawerRequest;

      yield call(openAudience, audienceId);

      break;
    case DrawerType.AudienceRole:
      const { role } = action.payload.request as AudienceRoleDrawerRequest;

      yield call(openAudienceRole, role);

      break;
    case DrawerType.PostAnalytics:
      const { postId } = action.payload.request as PostAnalyticsDrawerRequest;

      yield call(openPostAnalytics, postId);

      break;
    case DrawerType.Notifications:
      const { hasUnreadNotifications, notificationsEmployeeId } = action.payload.request as NotificationsDrawerRequest;

      yield call(openNotifications, hasUnreadNotifications, notificationsEmployeeId);

      break;
    case DrawerType.EmployeeRecord:
      const { erbCategoryId, erTemplateSectionId } = action.payload.request as EmployeeRecordDrawerRequest;

      yield call(openEmployeeRecord, erbCategoryId, erTemplateSectionId);

      break;
    case DrawerType.EmployeeRecordCustomField:
      break;
    case DrawerType.Assets:
      const assetsRequest = action.payload.request as AssetsDrawerRequest;

      yield call(openAssetsDrawer, assetsRequest.fileId);

      break;
    case DrawerType.AssetsMultipleFiles:
      const { fileIds } = action.payload.request as AssetsMultipleFilesDrawerRequest;

      yield call(openAssetsMultipleFilesDrawer, fileIds);

      break;
    case DrawerType.AssetsShareFile:
      const assetsShareFileRequest = action.payload.request as AssetsShareFileDrawerRequest;

      yield call(openAssetsShareFile, assetsShareFileRequest.fileId);

      break;
    case DrawerType.TasksEditTask:
      const editTaskDrawerTab = action.payload.request as TasksEditTaskDrawerRequest;

      yield call(openTasksEditTaskDrawer, editTaskDrawerTab);

      break;
    default:
      break;
  }
}

function* closeDrawerGenerator() {
  const currentDrawer: DrawerType = yield select(selectCurrentDrawerType);

  switch (currentDrawer) {
    case DrawerType.Assets:
      yield put(selectMultipleFiles([]));
      break;
    default:
      break;
  }
}

function* openDrawerWatcher() {
  yield takeLatest(openDrawer.type, openDrawerGenerator);
}

function* closeDrawerWatcher() {
  yield takeLatest(closeDrawer.type, closeDrawerGenerator);
}

export function* drawerSagas() {
  yield all([fork(openDrawerWatcher), fork(closeDrawerWatcher)]);
}
