import { AvatarGroup, IconButton, Link, Stack, Table, TableBody, TableCell, TableContainer, TableRow, TableSortLabel, Typography } from '@mui/material';
import {
  BarsProgressIcon,
  CircleDotIcon,
  CircleDotOutlinedIcon,
  CirclePlusIcon,
  CommentIcon,
  IconSortDown,
  IconSortNeutral,
  IconSortUp,
  LinkIcon,
  RowCenterStack,
  StyledCheckbox,
  StyledTableHead,
  ThinBorderCheckbox,
  TicketIcon
} from '@/shared';
import { useState } from 'react';
import { ListTaskDescription } from './styled/ListTaskDescription';
import { TasksListRow } from './styled/TasksListRow';
import { TaskAvatar } from './styled/TaskAvatar';
import { TasksListCell } from './styled/TasksListCell';
import { TaskStatusChip } from './styled/TaskStatusChip';
import { startCase } from 'lodash';
import { TaskActionMenu, TasksListTasks } from '..';

interface TasksListTableProps {
  // define props here
}

enum TaskStatus {
  Draft,
  Assigned,
  InReview,
  Completed
}

const tempTaskListData = [
  {
    taskID: '#M9N001-P2',
    taskTitle: 'Enhancing the onboarding experience for new team members',
    reviewer: 'None',
    dueDate: '12/12/2025 | 12:30',
    status: TaskStatus.Completed
  },
  {
    taskID: '#M9N002-P2',
    taskTitle: 'Implementing a new performance review system',
    reviewer: 'Alex Johnson',
    dueDate: '11/22/2025 | 10:00',
    status: TaskStatus.Draft
  }
];

export const TasksListTable: React.FC<TasksListTableProps> = () => {
  const [isAscending, setIsAscending] = useState(false);
  const [orderBy, setOrderBy] = useState<string>('Task Title'); // update with Interface later

  return (
    <TableContainer>
      <Table
        sx={{
          tableLayout: 'fixed',
          width: 'auto'
        }}
      >
        <StyledTableHead sx={{ height: 58 }}>
          <TableRow>
            <TableCell
              sx={{
                padding: '0 20px 0 16px',
                width: 350
              }}
              align="left"
            >
              <RowCenterStack sx={{ gap: '20px', position: 'relative' }}>
                <StyledCheckbox
                  indeterminate={false}
                  checked={false}
                  onChange={() => {}}
                  icon={
                    <RowCenterStack gap={'20px'}>
                      <ThinBorderCheckbox sx={{ fontSize: 28 }} />
                      <Stack gap={'6px'}>
                        <BarsProgressIcon color="#9747FF" />
                        <TicketIcon color="#9747FF" />
                      </Stack>
                    </RowCenterStack>
                  }
                />
                <TableSortLabel
                  active={orderBy === 'Task Title'}
                  direction={isAscending ? 'asc' : 'desc'}
                  onClick={() => {}}
                  sx={{
                    gap: '20px',
                    position: 'relative'
                  }}
                  IconComponent={() => {
                    if (orderBy !== 'Task Title') return <IconSortNeutral color={'#D5E8E5'} />;

                    return isAscending ? <IconSortUp /> : <IconSortDown />;
                  }}
                >
                  <ListTaskDescription>Task Title</ListTaskDescription>
                </TableSortLabel>
              </RowCenterStack>
            </TableCell>
            <TableCell align="left" sx={{ width: 110 }}>
              <TableSortLabel
                active={orderBy === 'Owner'}
                direction={isAscending ? 'asc' : 'desc'}
                onClick={() => {}}
                sx={{
                  gap: '8px'
                }}
                IconComponent={() => {
                  if (orderBy !== 'Owner') return <IconSortNeutral color={'#D5E8E5'} />;

                  return isAscending ? <IconSortUp /> : <IconSortDown />;
                }}
              >
                <ListTaskDescription>Owner</ListTaskDescription>
              </TableSortLabel>
            </TableCell>
            <TableCell width={110} align="left">
              <TableSortLabel
                active={orderBy === 'Assigne'}
                direction={isAscending ? 'asc' : 'desc'}
                onClick={() => {}}
                sx={{
                  gap: '8px'
                }}
                IconComponent={() => {
                  if (orderBy !== 'Assigne') return <IconSortNeutral color={'#D5E8E5'} />;

                  return isAscending ? <IconSortUp /> : <IconSortDown />;
                }}
              >
                <ListTaskDescription>Assigne</ListTaskDescription>
              </TableSortLabel>
            </TableCell>
            <TableCell width={110} align="left">
              <TableSortLabel
                active={orderBy === 'Reviewer'}
                direction={isAscending ? 'asc' : 'desc'}
                onClick={() => {}}
                sx={{
                  gap: '8px'
                }}
                IconComponent={() => {
                  if (orderBy !== 'Reviewer') return <IconSortNeutral color={'#D5E8E5'} />;

                  return isAscending ? <IconSortUp /> : <IconSortDown />;
                }}
              >
                <ListTaskDescription>Reviewer</ListTaskDescription>
              </TableSortLabel>
            </TableCell>
            <TableCell width={160} align="left">
              <TableSortLabel
                active={orderBy === 'Due Date'}
                direction={isAscending ? 'asc' : 'desc'}
                onClick={() => {}}
                sx={{
                  gap: '20px'
                }}
                IconComponent={() => {
                  if (orderBy !== 'Due Date') return <IconSortNeutral color={'#D5E8E5'} />;

                  return isAscending ? <IconSortUp /> : <IconSortDown />;
                }}
              >
                <ListTaskDescription>Due Date</ListTaskDescription>
              </TableSortLabel>
            </TableCell>
            <TableCell
              width={50}
              align="left"
              sx={{
                padding: 0
              }}
            >
              <TableSortLabel
                active={orderBy === 'Due Date'}
                direction={isAscending ? 'asc' : 'desc'}
                onClick={() => {}}
                IconComponent={() => {
                  if (orderBy !== 'Due Date') return <IconSortNeutral color={'#D5E8E5'} />;

                  return isAscending ? <IconSortUp /> : <IconSortDown />;
                }}
              />
            </TableCell>
            <TableCell
              width={60}
              align="left"
              sx={{
                padding: 0
              }}
            >
              <TableSortLabel
                active={orderBy === 'Due Date'}
                direction={isAscending ? 'asc' : 'desc'}
                onClick={() => {}}
                IconComponent={() => {
                  if (orderBy !== 'Due Date') return <IconSortNeutral color={'#D5E8E5'} />;

                  return isAscending ? <IconSortUp /> : <IconSortDown />;
                }}
              />
            </TableCell>
            <TableCell
              width={60}
              align="left"
              sx={{
                padding: 0
              }}
            >
              <TableSortLabel
                active={orderBy === 'Due Date'}
                direction={isAscending ? 'asc' : 'desc'}
                onClick={() => {}}
                IconComponent={() => {
                  if (orderBy !== 'Due Date') return <IconSortNeutral color={'#D5E8E5'} />;

                  return isAscending ? <IconSortUp /> : <IconSortDown />;
                }}
              />
            </TableCell>
            <TableCell width={155} align="left">
              <TableSortLabel
                active={orderBy === 'Status'}
                direction={isAscending ? 'asc' : 'desc'}
                onClick={() => {}}
                sx={{
                  gap: '20px'
                }}
                IconComponent={() => {
                  if (orderBy !== 'Status') return <IconSortNeutral color={'#D5E8E5'} />;

                  return isAscending ? <IconSortUp /> : <IconSortDown />;
                }}
              >
                <ListTaskDescription>Status</ListTaskDescription>
              </TableSortLabel>
            </TableCell>
            <TableCell width={60} align="left" sx={{ backgroundColor: (theme) => theme.palette.customTheme.bgr }} />
          </TableRow>
        </StyledTableHead>
        <TableBody>
          {tempTaskListData.map((task) => (
            <>
              <TasksListRow status={task.status}>
                <TasksListCell>
                  <RowCenterStack sx={{ gap: '20px', position: 'relative', width: '100%' }}>
                    <StyledCheckbox
                      indeterminate={false}
                      onChange={() => {}}
                      icon={
                        <RowCenterStack sx={{ width: 62, justifyContent: 'space-between' }}>
                          <ThinBorderCheckbox sx={{ fontSize: 28 }} />
                          <TicketIcon color="#251D38" width={20} height={18} />
                        </RowCenterStack>
                      }
                    />
                    <Stack sx={{ width: '100%' }}>
                      <Link sx={{ fontWeight: 400 }}>{task.taskID}</Link>
                      <ListTaskDescription sx={{ width: 242 }}>{task.taskTitle}</ListTaskDescription>
                    </Stack>
                  </RowCenterStack>
                </TasksListCell>
                <TasksListCell>
                  <TaskAvatar src={'https://thispersondoesnotexist.com/'}>Idk</TaskAvatar>
                </TasksListCell>
                <TasksListCell>
                  <AvatarGroup
                    max={3}
                    variant="rounded"
                    spacing={-(-17)}
                    sx={{
                      '.MuiAvatar-root': {
                        border: (theme) => `1px solid ${theme.palette.common.black}`,
                        width: 34,
                        height: 34
                      },
                      '.MuiAvatarGroup-avatar': {
                        position: 'relative',
                        ':first-of-type': {
                          zIndex: (theme) => theme.zIndex.tooltip
                        }
                      }
                    }}
                    slotProps={{}}
                  >
                    <TaskAvatar position={1} alt="Remy Sharp" src={'https://thispersondoesnotexist.com/'} />
                    <TaskAvatar position={2} alt="Travis Howard" src={'https://thispersondoesnotexist.com/'} />
                    <TaskAvatar position={3} alt="Cindy Baker" src={'https://thispersondoesnotexist.com/'} />
                    <TaskAvatar position={4} alt="Agnes Walker" src={'https://thispersondoesnotexist.com/'} />
                    <TaskAvatar position={5} alt="Trevor Henderson" src={'https://thispersondoesnotexist.com/'} />
                  </AvatarGroup>
                </TasksListCell>
                <TasksListCell>{task.reviewer}</TasksListCell>
                <TasksListCell>{task.dueDate}</TasksListCell>
                <TasksListCell noBorder></TasksListCell>
                <TasksListCell noBorder>
                  <RowCenterStack gap={'2px'}>
                    <ListTaskDescription>2</ListTaskDescription>
                    <LinkIcon width={16} height={16} />
                  </RowCenterStack>
                </TasksListCell>
                <TasksListCell>
                  <RowCenterStack gap={'2px'}>
                    <ListTaskDescription>2</ListTaskDescription>
                    <CommentIcon size={16} />
                  </RowCenterStack>
                </TasksListCell>
                <TasksListCell>
                  <RowCenterStack>
                    <TaskStatusChip status={task.status} label={startCase(TaskStatus[task.status])} />
                    <TaskActionMenu taskId={task.taskID} />
                  </RowCenterStack>
                </TasksListCell>
                <TableCell sx={{ padding: '0', backgroundColor: (theme) => theme.palette.customTheme.bgr, border: 0 }}>
                  <Stack gap={'8px'} sx={{ alignItems: 'flex-start' }}>
                    <CirclePlusIcon width={16} height={16} />
                    <IconButton sx={{ padding: 0, gap: '4px' }}>
                      <CircleDotOutlinedIcon width={16} height={16} />
                      <Typography sx={{ fontWeight: 700 }}>2</Typography>
                    </IconButton>
                  </Stack>
                </TableCell>
              </TasksListRow>
              <TasksListRow status={TaskStatus.Draft} isDivider />
            </>
          ))}
          <TasksListTasks />
        </TableBody>
      </Table>
    </TableContainer>
  );
};
