import { PayloadAction } from '@reduxjs/toolkit';
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { Empty } from '@bufbuild/protobuf';
import {
  retrieveEmployeeProfileAndCoverReadSasTokenSucceeded,
  retrieveEmployeeProfileAndCoverReadSasTokenFailed,
  retrieveEmployeeProfileAndCoverReadSasTokenRequested,
  selectEmployeeProfilePictureFileMultiSize,
  selectEmployeeCoverPictureFileMultiSize,
  updateProfileMediaFailed,
  updateProfileMediaRequested,
  updateProfileMediaSucceeded
} from '@features/employee-profile';
import { updateProfileMedia } from '@api/employee-profile.api';
import { UpdateProfileMediaRequest } from '@thrivea/organization-client';
import * as Sentry from '@sentry/react';
import { RetrieveEmployeeProfileAndCoverCreateSasUriRequest, SasToken, SasUri } from '@thrivea/auth-client';
import { retrieveEmployeeProfileAndCoverCreateSasUri, retrieveEmployeeProfileAndCoverReadSasToken } from '@api/shared-access-signature.api';
import { UploadFileMultipleSizes, uploadFileMultipleSizes } from '@api/blob-storage.api';
import { updateCurrentUserProfilePicture } from '@features/shared';
import { showSuccess } from '@features/snackbar';
import { t } from 'i18next';
import { MultiSizeImageBlobs } from '@/shared';
import { setCurrentEmployeeProfileCoverPicture, setCurrentEmployeeProfilePicture } from '@features/admin-settings';

function* retrieveEmployeeProfileAndCoverReadSasTokeGenerator() {
  try {
    const sasToken: SasToken = yield call(retrieveEmployeeProfileAndCoverReadSasToken, new Empty());
    yield put(retrieveEmployeeProfileAndCoverReadSasTokenSucceeded(sasToken));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveEmployeeProfileAndCoverReadSasTokenFailed());
  }
}

function* updateEmployeeProfileMediaGenerator(action: PayloadAction<UpdateProfileMediaRequest>) {
  const successMessage = t('media_upload_successfully', { ns: 'employee_profile' });
  const profilePictureMultiSize: MultiSizeImageBlobs = yield select(selectEmployeeProfilePictureFileMultiSize);
  const profileCoverPictureMultiSize: MultiSizeImageBlobs = yield select(selectEmployeeCoverPictureFileMultiSize);

  let profilePictureUrl = '';
  let coverPictureUrl = '';

  try {
    const sasUri: SasUri = yield call(
      retrieveEmployeeProfileAndCoverCreateSasUri,
      new RetrieveEmployeeProfileAndCoverCreateSasUriRequest({
        employeeId: action.payload.employeeId
      })
    );

    if (profilePictureMultiSize) {
      const profilePictureRequest = {
        sasUri: sasUri.uri,
        multiSizeImage: profilePictureMultiSize
      } as UploadFileMultipleSizes;
      profilePictureUrl = yield call(uploadFileMultipleSizes, profilePictureRequest);
    }

    if (profileCoverPictureMultiSize) {
      const profileCoverRequest = {
        sasUri: sasUri.uri,
        multiSizeImage: profileCoverPictureMultiSize
      } as UploadFileMultipleSizes;
      coverPictureUrl = yield call(uploadFileMultipleSizes, profileCoverRequest);
    }

    if (!profilePictureMultiSize) {
      profilePictureUrl = action.payload.profilePictureUrl;
    }

    if (!profileCoverPictureMultiSize) {
      coverPictureUrl = action.payload.coverPictureUrl;
    }

    yield call(updateProfileMedia, {
      employeeId: action.payload.employeeId,
      profilePictureUrl,
      coverPictureUrl
    } as UpdateProfileMediaRequest);

    yield put(updateCurrentUserProfilePicture(profilePictureUrl));
    yield put(setCurrentEmployeeProfilePicture(profilePictureUrl));
    yield put(setCurrentEmployeeProfileCoverPicture(coverPictureUrl));
    yield put(showSuccess(successMessage));
  } catch (error) {
    Sentry.captureException(error);
    yield put(updateProfileMediaFailed());
  }
}

function* updateEmployeeProfileMediaWatcher() {
  yield takeLatest(updateProfileMediaRequested.type, updateEmployeeProfileMediaGenerator);
}

function* retrieveEmployeeProfileAndCoverReadSasTokeWatcher() {
  yield takeLatest(retrieveEmployeeProfileAndCoverReadSasTokenRequested.type, retrieveEmployeeProfileAndCoverReadSasTokeGenerator);
}

export function* employeeProfileSagas() {
  yield all([fork(retrieveEmployeeProfileAndCoverReadSasTokeWatcher), fork(updateEmployeeProfileMediaWatcher)]);
}
