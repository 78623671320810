import { Button, ButtonProps, styled } from '@mui/material';

export const AssetsMoveButton = styled(Button)<ButtonProps>(({ theme }) => ({
  height: 24,
  width: 46,
  backgroundColor: theme.palette.customTheme.focusItem,
  color: theme.palette.common.white,
  padding: '5px 8px',
  borderRadius: '4px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '4px',
  fontWeight: 700,
  fontSize: 12
}));
