import React from 'react';

interface CircleDotOutlinedIconProps {
  width?: number;
  height?: number;
  color?: string;
}

export const CircleDotOutlinedIcon: React.FC<CircleDotOutlinedIconProps> = ({ width = 22, height = 22, color = '#251D38' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill={color} viewBox="0 0 512 512">
      <path d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm256-96a96 96 0 1 1 0 192 96 96 0 1 1 0-192z" />
    </svg>
  );
};
