import { Tab, styled, tabClasses } from '@mui/material';

export const TabButton = styled(Tab, {
  shouldForwardProp: (prop) => prop !== 'isEditable' && prop !== 'isActive'
})<{
  isEditable?: boolean;
  isActive?: boolean;
}>(({ theme }) => ({
  position: 'relative',
  backgroundColor: 'transparent',
  height: 44,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '6px 14px',
  borderRadius: 0,
  fontSize: 14,
  fontWeight: 700,
  '&:after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: 2,
    backgroundColor: 'transparent',
    transition: theme.transitions.create(['background-color'])
  },
  [`&.${tabClasses.selected}`]: {
    backgroundColor: 'transparent',
    '&:after': {
      backgroundColor: theme.palette.customTheme.navColor
    }
  },
  '&:hover': {
    backgroundColor: 'transparent',
    '&:after': {
      backgroundColor: theme.palette.customTheme.navColor
    }
  }
}));
