import { Chip, ChipProps, styled } from '@mui/material';

export const AssetsExpiryChip = styled(Chip)<ChipProps>(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: '#E62E05',
  padding: '2px 8px',
  border: '1.5px solid',
  borderColor: '#E62E05',
  borderRadius: '16px',
  fontSize: 12,
  fontWeight: 500,
  lineHeight: '18px',
  minWidth: 84,
  height: 22
}));
