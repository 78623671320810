import React, { useState } from 'react';
import { Box, ClickAwayListener, Collapse, InputLabel, Stack } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { ChevronDownIcon, PrimaryApplyButton, PrimaryCancelButton, RowCenterStack, StyledDatePickerFilledInput, StyledTransparentButton } from '@/shared';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import Grid from '@mui/material/Grid2';
import { useAppDispatch } from 'src/app/hooks';

interface TasksFromToDateFilterProps {
  label: string;
}

export const TasksFromToDateFilter: React.FC<TasksFromToDateFilterProps> = ({ label }) => {
  const { t } = useTranslation(['tasks']);
  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [fromDate, setFromDate] = useState<string | null>(null);
  const [toDate, setToDate] = useState<string | null>(null);

  const handleFromDateChange = (date: DateTime | null) => {
    if (date) {
      const stringDate = date.toISODate();
      setFromDate(stringDate);
    } else {
      setFromDate(null);
    }
  };

  const handleToDateChange = (date: DateTime | null) => {
    if (date) {
      const stringDate = date.toISODate();
      setToDate(stringDate);
    } else {
      setToDate(null);
    }
  };

  const handleToggleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl((prevAnchorEl) => (prevAnchorEl ? null : event.currentTarget));
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isOpened = Boolean(anchorEl);

  const handleResetDates = () => {
    setFromDate(null);
    setToDate(null);
  };

  const handleApplyDates = () => {
    // Dispatch some action here
    // dispatch(someAction({ fromDate, toDate }));
    handleClose();
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Box
        sx={{
          width: '100%',
          zIndex: (theme) => theme.zIndex.tooltip,
          position: 'relative',
          borderRadius: 1,
          border: isOpened ? '1px solid' : 0,
          borderBottom: 0,
          borderColor: (theme) => theme.palette.primary.main,
          backgroundColor: (theme) => (isOpened ? theme.palette.common.white : theme.palette.customTheme.primaryA)
        }}
      >
        <Stack
          onClick={handleToggleClick}
          sx={{
            width: '100%',
            pl: 1,
            pr: 2,
            py: 1,
            height: 50,
            gap: 0,
            borderRadius: 1,
            position: 'relative'
          }}
        >
          <InputLabel
            shrink
            size="small"
            disabled
            sx={{
              fontSize: 14,
              zIndex: 10
            }}
          >
            {label}
          </InputLabel>
          <StyledTransparentButton
            sx={{
              transform: isOpened ? 'rotate(180deg)' : 'rotate(0deg)',
              alignSelf: 'flex-end'
            }}
          >
            <ChevronDownIcon />
          </StyledTransparentButton>
        </Stack>
        <Collapse
          in={isOpened}
          sx={{
            position: 'absolute',
            top: 45,
            left: -1,
            right: -1,
            borderBottomLeftRadius: 2,
            borderBottomRightRadius: 2,
            border: isOpened ? '1px solid' : 0,
            borderTop: 0,
            borderColor: (theme) => theme.palette.primary.main,
            backgroundColor: (theme) => theme.palette.customTheme.primaryA
          }}
        >
          <Stack
            gap={1}
            sx={{
              padding: '8px',
              backgroundColor: (theme) => theme.palette.common.white
            }}
          >
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <DatePicker
                format="yyyy-MM-dd"
                disableFuture
                value={fromDate ? DateTime.fromISO(fromDate) : null}
                onChange={handleFromDateChange}
                slots={{ textField: StyledDatePickerFilledInput }}
                slotProps={{
                  textField: {
                    placeholder: 'YYYY-MM-DD',
                    variant: 'filled',
                    label: t('date', { ns: 'tasks' }),
                    required: true,
                    fullWidth: true,
                    InputProps: {
                      disableUnderline: true,
                      required: true,
                      onBlur: () => {}
                    },
                    InputLabelProps: {
                      shrink: true
                    }
                  }
                }}
              />
              <DatePicker
                format="yyyy-MM-dd"
                disableFuture
                value={toDate ? DateTime.fromISO(toDate) : null}
                onChange={handleToDateChange}
                slots={{ textField: StyledDatePickerFilledInput }}
                slotProps={{
                  textField: {
                    placeholder: 'YYYY-MM-DD',
                    variant: 'filled',
                    label: t('to_date', { ns: 'tasks' }),
                    required: true,
                    fullWidth: true,
                    InputProps: {
                      disableUnderline: true,
                      required: true,
                      onBlur: () => {}
                    },
                    InputLabelProps: {
                      shrink: true
                    }
                  }
                }}
              />
            </LocalizationProvider>
            <Grid container columnSpacing={1}>
              <Grid size={6}>
                <PrimaryCancelButton onClick={handleResetDates}>Cancel</PrimaryCancelButton>
              </Grid>
              <Grid size={6}>
                <PrimaryApplyButton onClick={handleApplyDates}>Apply</PrimaryApplyButton>
              </Grid>
            </Grid>
          </Stack>
        </Collapse>
      </Box>
    </ClickAwayListener>
  );
};
