import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@app/store';
import {
  DeleteTasksViewRequest,
  DueDateType,
  RenameTasksViewRequest,
  TaskScope,
  TaskKindWithPrivate,
  TasksView,
  TasksViewFilters,
  TaskType,
  UpdateTasksViewRequest,
  SingleTaskItem,
  RetrieveEmployeesByQueryRequest,
  RetrieveEmployeesByQueryResponse,
  RetrieveTasksRequest,
  RetrieveTasksResponse,
  CreateSingleTaskRequest,
  TaskListItem,
  CreateTaskListRequest,
  RetrieveSingleTaskResponse,
  SingleTaskId,
  Reaction,
  ReactToTaskRequest,
  WithdrawTaskReactionRequest,
  CommentOnTaskRequest,
  Comment,
  ReactToTaskCommentRequest,
  WithdrawTaskCommentReactionRequest,
  RetrieveTasksForDependenciesResponse,
  SimpleTask,
  EditTaskRequest,
  TaskListId,
  RetrieveSingleTasksInTaskListResponse,
  SingleTaskIdWithEmployeeId,
  CreateTasksViewRequest,
  RetrieveSingleTasksForTaskListResponse,
  RetrieveSingleTaskRelationsResponse,
  RelationSingleTask,
  DeleteSingleTaskRequest,
  RetrieveSingleTaskRequest,
  DeleteTaskListRequest,
  EditTaskListRequest,
  RetrieveTaskListRelationsResponse,
  DeleteTaskCommentRequest,
  EditTaskCommentRequest,
  RelationTaskList,
  RetrieveFilesRequest,
  RetrieveFilesResponse,
  FileItem,
  FileType,
  ChangeTaskOwnerRequest,
  TasksViewSchema,
  TasksViewFiltersSchema,
  RetrieveEmployeesByQueryResponseSchema,
  SingleTaskItemSchema,
  CreateSingleTaskRequestSchema,
  EditTaskListRequestSchema,
  RetrieveSingleTaskResponseSchema,
  EditTaskRequestSchema,
  CreateTaskListRequestSchema,
  TaskListItemSchema,
  ReviewerSchema,
  TaskKindWithPrivateSchema,
  ReactionSchema,
  CommentSchema,
  GroupByDueDateRequestSchema,
  GroupByStatusRequestSchema,
  RetrieveTasksRequestSchema,
  SimpleTaskSchema
} from '@thrivea/organization-client';
import { chain, groupBy, merge, uniq } from 'lodash';
import {
  ASSIGNED,
  COMPLETED,
  DRAFT,
  IN_REVIEW,
  LoadTasksInitially,
  PagingByDueDates,
  PagingByStatuses,
  TasksGroupBy,
  TaskViewMode,
  DEPENDENCY_FOR_ID,
  DEPENDS_ON_ID,
  CreateSingleTaskRequestZodSchema
} from '@features/tasks';
import { ActionStatus, Option, Sorting } from 'src/shared';
import { flattenData, groupEmployeesAlphabetically, groupOptionsByType } from '@/utils';

import { DateTime } from 'luxon';
import { buildTreeItems, FileSystemItem, getAllParentFileIds, MY_FILES_FOLDER_ID, toSortFile } from '@features/assets';
import { TASKS_DEFAULT_VIEW_ID } from './tasks.model';
import { create } from '@bufbuild/protobuf';

export interface TaskCommentModel {
  id: string;
  text: string;
  authorId: string;
  isImportant: boolean;
  commentTime: DateTime;
  editedTime?: DateTime;
  reactions: { [key: string]: Reaction[] };
  attachmentUrls: string[];
}

const makeFileSystemId = (parentFileId: string, childFileId: string) => parentFileId + '|' + childFileId;

const mapComment = ({ commentId, authorId, isImportant, text, commentAt, editedAt, reactions, attachmentUrls }: Comment): TaskCommentModel => {
  const reactionDict = groupBy(reactions, (r) => r.emoji);

  return {
    id: commentId,
    authorId,
    isImportant,
    text,
    commentTime: DateTime.fromISO(commentAt!),
    editedTime: editedAt ? DateTime.fromISO(editedAt) : undefined,
    reactions: reactionDict,
    attachmentUrls
  } as TaskCommentModel;
};

export interface TasksState {
  entities: {
    tasks: {
      // we mean by this Tasks, Private Tasks and Task Lists
      byId: Record<string, TaskKindWithPrivate>;
      allIds: string[];
      singleTaskIds: string[];
      taskListIds: string[];
      privateTaskIds: string[];
      dueTodayIds: string[];
      dueThisWeekIds: string[];
      dueNextWeekIds: string[];
      dueLaterIds: string[];
      draftIds: string[];
      assignedIds: string[];
      inReviewIds: string[];
      completedIds: string[];
    };
    taskViews: {
      byId: { [key: string]: TasksView };
      savedIds: string[];
      unsavedIds: string[];
      hiddenIds: string[];
    };
    employeesAutocompleteItems: {
      allIds: string[];
    };
    assignees: {
      initialIds: string[];
      selectedIds: string[];
    };
    reviewers: {
      initialIds: string[];
      selectedIds: string[];
    };
    comments: {
      byId: { [key: string]: TaskCommentModel };
      allIds: string[];
    };
    tasksForDependencies: {
      byId: { [key: string]: SimpleTask };
      allIds: string[];
    };
    tasksDependsOnTasks: {
      byId: { [key: string]: SimpleTask };
      allIds: string[];
    };
    tasksDependsForTasks: {
      byId: { [key: string]: SimpleTask };
      allIds: string[];
    };
    taskFiles: {
      byId: { [key: string]: FileItem };
      allIds: string[];
    };
    taskFileSystem: {
      byId: { [key: string]: FileSystemItem };
      allIds: string[];
    };
  };
  ui: {
    viewMode: TaskViewMode; // List or Board
    scope: TaskScope; // All Tasks or My Tasks
    groupBy: TasksGroupBy; // Due Date or Status
    tasksStatus: ActionStatus;
    taskViewsStatus: ActionStatus;
    assigneesAutocompleteItemsStatus: ActionStatus;
    reviewersAutocompleteItemsStatus: ActionStatus;
    createTaskDrawer: {
      isAssetsModalOpened: boolean;
    };
    editTaskDrawer: {
      isAssetsModalOpened: boolean;
    };
    newComment: {
      isAssetsModalOpened: boolean;
    };
    paging: {
      dueDates: {
        activeSection?: DueDateType;
        sections: PagingByDueDates[];
      };
      statuses: {
        activeSection?: string;
        sections: PagingByStatuses[];
      };
    };
    createSingleTaskStatus: ActionStatus;
    employeesAutocompleteItemsStatus: ActionStatus;
    isSingleTaskFormValid: boolean;
    singleTaskDeleteStatus: ActionStatus;
    singleTaskViewStatus: ActionStatus;
    commentOnTaskStatus: ActionStatus;
    tasksForDependenciesStatus: ActionStatus;
    singleTasksInTaskListStatus: ActionStatus;
    singleTaskViewActionButtonStatus: ActionStatus;
    singleTasksInTaskListDrawer: SimpleTask[];
    singleTasksForTaskList: SimpleTask[];
    isLoadedTasksForTaskList: boolean;
    taskMyFilesSorting: Sorting;
    retrieveTaskFilesStatus: ActionStatus;
    taskFilesPageNumber: number;
    taskFilesPageSize: number;
    taskFilesTotalCount: number;
  };
  activeTasksViewId: string;
  isInitiallyLoaded: boolean;
  selectedSingleTaskId?: string;
  assigneesAutocompleteItems: RetrieveEmployeesByQueryResponse;
  reviewersAutocompleteItems: RetrieveEmployeesByQueryResponse;
  singleTaskRequest: CreateSingleTaskRequest;
  taskListRequest: CreateTaskListRequest;
  editTaskListRequest: EditTaskListRequest;
  singleTask: SingleTaskItem;
  singleTaskView: RetrieveSingleTaskResponse;
  drawerSingleTaskItemId: string;
  editSingleTaskRequest: EditTaskRequest;
  drawerTaskListItem: TaskListItem;
  drawerTaskListItemId: string;
  taskAttachmentUrls: string[];
  newCommentAttachmentUrls: string[];
  readOnlyTask: RetrieveSingleTaskResponse | undefined;
  taskSelectedFolderId: string;
  expandedTaskFolderIds: string[];
  editableComment: string | null;
}

const initialState: TasksState = {
  entities: {
    tasks: {
      byId: {},
      allIds: [],
      singleTaskIds: [],
      taskListIds: [],
      privateTaskIds: [],
      dueTodayIds: [],
      dueThisWeekIds: [],
      dueNextWeekIds: [],
      dueLaterIds: [],
      draftIds: [],
      assignedIds: [],
      inReviewIds: [],
      completedIds: []
    },
    taskViews: {
      byId: {
        [TASKS_DEFAULT_VIEW_ID]: create(TasksViewSchema, {
          tasksViewId: TASKS_DEFAULT_VIEW_ID,
          name: undefined,
          kind: {
            case: 'filters',
            value: create(TasksViewFiltersSchema, {
              type: TaskType.TASK_TYPE_UNSPECIFIED,
              statusIds: undefined,
              ownerIds: undefined,
              assigneeIds: undefined,
              reviewerIds: undefined,
              dueDateRange: undefined,
              searchQuery: undefined
            })
          }
        })
      },
      savedIds: [],
      unsavedIds: [],
      hiddenIds: []
    },
    employeesAutocompleteItems: {
      allIds: []
    },
    assignees: {
      initialIds: [],
      selectedIds: []
    },
    reviewers: {
      initialIds: [],
      selectedIds: []
    },
    comments: {
      byId: {},
      allIds: []
    },
    tasksForDependencies: {
      byId: {},
      allIds: []
    },
    tasksDependsOnTasks: {
      byId: {},
      allIds: []
    },
    tasksDependsForTasks: {
      byId: {},
      allIds: []
    },
    taskFiles: {
      byId: {},
      allIds: []
    },
    taskFileSystem: {
      byId: {},
      allIds: []
    }
  },
  ui: {
    viewMode: TaskViewMode.Board,
    scope: TaskScope.MY_TASKS,
    groupBy: TasksGroupBy.Status,
    tasksStatus: ActionStatus.Idle,
    taskViewsStatus: ActionStatus.Idle,
    assigneesAutocompleteItemsStatus: ActionStatus.Idle,
    reviewersAutocompleteItemsStatus: ActionStatus.Idle,
    createTaskDrawer: {
      isAssetsModalOpened: false
    },
    editTaskDrawer: {
      isAssetsModalOpened: false
    },
    newComment: {
      isAssetsModalOpened: false
    },
    paging: {
      dueDates: {
        activeSection: undefined,
        sections: [
          {
            dueDateType: DueDateType.DUE_TODAY,
            pageNumber: 1,
            pageSize: 10,
            totalCount: 0
          },
          {
            dueDateType: DueDateType.DUE_THIS_WEEK,
            pageNumber: 1,
            pageSize: 10,
            totalCount: 0
          },
          {
            dueDateType: DueDateType.DUE_NEXT_WEEK,
            pageNumber: 1,
            pageSize: 10,
            totalCount: 0
          },
          {
            dueDateType: DueDateType.DUE_LATER,
            pageNumber: 1,
            pageSize: 10,
            totalCount: 0
          }
        ]
      },
      statuses: {
        activeSection: undefined,
        sections: [
          {
            statusId: DRAFT,
            pageNumber: 1,
            pageSize: 10,
            totalCount: 0
          },
          {
            statusId: ASSIGNED,
            pageNumber: 1,
            pageSize: 10,
            totalCount: 0
          },
          {
            statusId: IN_REVIEW,
            pageNumber: 1,
            pageSize: 10,
            totalCount: 0
          },
          {
            statusId: COMPLETED,
            pageNumber: 1,
            pageSize: 10,
            totalCount: 0
          }
        ]
      }
    },
    createSingleTaskStatus: ActionStatus.Idle,
    employeesAutocompleteItemsStatus: ActionStatus.Idle,
    isSingleTaskFormValid: false,
    singleTaskDeleteStatus: ActionStatus.Idle,
    singleTaskViewStatus: ActionStatus.Idle,
    commentOnTaskStatus: ActionStatus.Idle,
    tasksForDependenciesStatus: ActionStatus.Idle,
    singleTasksInTaskListStatus: ActionStatus.Idle,
    singleTaskViewActionButtonStatus: ActionStatus.Idle,
    singleTasksInTaskListDrawer: [],
    singleTasksForTaskList: [],
    isLoadedTasksForTaskList: false,
    taskMyFilesSorting: {
      sortBy: 'name',
      sortDirection: 'asc'
    },
    retrieveTaskFilesStatus: ActionStatus.Idle,
    taskFilesPageNumber: 1,
    taskFilesPageSize: 10,
    taskFilesTotalCount: 0
  },
  activeTasksViewId: TASKS_DEFAULT_VIEW_ID,
  isInitiallyLoaded: false,
  selectedSingleTaskId: undefined,
  assigneesAutocompleteItems: create(RetrieveEmployeesByQueryResponseSchema, {}),
  reviewersAutocompleteItems: create(RetrieveEmployeesByQueryResponseSchema, {}),
  singleTask: create(SingleTaskItemSchema, {}),
  singleTaskRequest: create(CreateSingleTaskRequestSchema, {}),
  taskListRequest: create(CreateTaskListRequestSchema, {}),
  editTaskListRequest: create(EditTaskListRequestSchema, {}),
  singleTaskView: create(RetrieveSingleTaskResponseSchema, {}),
  editSingleTaskRequest: create(EditTaskRequestSchema, {}),
  drawerSingleTaskItemId: '',
  drawerTaskListItem: create(TaskListItemSchema, {}),
  drawerTaskListItemId: '',
  taskAttachmentUrls: [],
  newCommentAttachmentUrls: [],
  readOnlyTask: undefined,
  taskSelectedFolderId: MY_FILES_FOLDER_ID,
  expandedTaskFolderIds: [MY_FILES_FOLDER_ID],
  editableComment: null
};

export const tasksSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    loadTasksRequested: (state) => {
      state.ui.tasksStatus = ActionStatus.Pending;
      state.ui.taskViewsStatus = ActionStatus.Pending;
    },
    loadTasksSucceeded: (state, action: PayloadAction<LoadTasksInitially>) => {
      const { tasksResponse, tasksViewsResponse } = action.payload;

      switch (tasksResponse.kind.case) {
        case 'pagedTasksByDueDates':
          for (const pagedTasksByDueDateCategory of tasksResponse.kind.value.pagedTasksByDueDates) {
            const dueListIds: string[] = [];

            for (const task of pagedTasksByDueDateCategory.tasks) {
              switch (task.kind.case) {
                case 'singleTaskItem':
                  state.entities.tasks.byId[task.kind.value.singleTaskId] = task;
                  state.entities.tasks.singleTaskIds.push(task.kind.value.singleTaskId);
                  dueListIds.push(task.kind.value.singleTaskId);

                  break;
                case 'taskListItem':
                  state.entities.tasks.byId[task.kind.value.taskListId] = task;
                  state.entities.tasks.taskListIds.push(task.kind.value.taskListId);
                  dueListIds.push(task.kind.value.taskListId);

                  break;

                case 'privateTaskItem':
                  state.entities.tasks.byId[task.kind.value.id] = task;
                  state.entities.tasks.privateTaskIds.push(task.kind.value.id);
                  dueListIds.push(task.kind.value.id);

                  break;
              }
            }

            // After all tasks for particular due date type were saved, add task ids to that due date array
            switch (pagedTasksByDueDateCategory.dueDate) {
              case DueDateType.DUE_TODAY:
                state.entities.tasks.dueTodayIds = dueListIds;
                break;
              case DueDateType.DUE_THIS_WEEK:
                state.entities.tasks.dueThisWeekIds = dueListIds;
                break;
              case DueDateType.DUE_NEXT_WEEK:
                state.entities.tasks.dueNextWeekIds = dueListIds;
                break;
              case DueDateType.DUE_LATER:
                state.entities.tasks.dueLaterIds = dueListIds;
                break;
            }

            const index = state.ui.paging.dueDates.sections.findIndex((dd) => dd.dueDateType === pagedTasksByDueDateCategory.dueDate);
            state.ui.paging.dueDates.sections[index] = { ...state.ui.paging.dueDates.sections[index], totalCount: pagedTasksByDueDateCategory.totalCount };
          }

          break;

        case 'pagedTasksByStatuses':
          for (const pagedTasksByStatus of tasksResponse.kind.value.pagedTasksByStatuses) {
            const statusIds: string[] = [];

            for (const task of pagedTasksByStatus.tasks) {
              switch (task.kind.case) {
                case 'singleTaskItem':
                  state.entities.tasks.byId[task.kind.value.singleTaskId] = task;
                  state.entities.tasks.singleTaskIds.push(task.kind.value.singleTaskId);
                  statusIds.push(task.kind.value.singleTaskId);

                  break;
                case 'taskListItem':
                  state.entities.tasks.byId[task.kind.value.taskListId] = task;
                  state.entities.tasks.taskListIds.push(task.kind.value.taskListId);
                  statusIds.push(task.kind.value.taskListId);

                  break;

                case 'privateTaskItem':
                  state.entities.tasks.byId[task.kind.value.id] = task;
                  state.entities.tasks.privateTaskIds.push(task.kind.value.id);
                  statusIds.push(task.kind.value.id);

                  break;
              }
            }

            // After all tasks for particular statusId were saved, add task ids to that status array
            switch (pagedTasksByStatus.statusId) {
              case DRAFT:
                state.entities.tasks.draftIds = statusIds;
                break;
              case ASSIGNED:
                state.entities.tasks.assignedIds = statusIds;
                break;
              case IN_REVIEW:
                state.entities.tasks.inReviewIds = statusIds;
                break;
              case COMPLETED:
                state.entities.tasks.completedIds = statusIds;
                break;
            }

            const index = state.ui.paging.statuses.sections.findIndex((st) => st.statusId === pagedTasksByStatus.statusId);
            state.ui.paging.statuses.sections[index] = { ...state.ui.paging.statuses.sections[index], totalCount: pagedTasksByStatus.totalCount };
          }

          break;
      }

      for (const tasksView of tasksViewsResponse.tasksViews) {
        if (!state.entities.taskViews.byId.hasOwnProperty(tasksView.tasksViewId)) {
          state.entities.taskViews.byId[tasksView.tasksViewId] = tasksView;
          state.entities.taskViews.savedIds.push(tasksView.tasksViewId);
        }
      }

      state.isInitiallyLoaded = true;
      state.ui.tasksStatus = ActionStatus.Idle;
      state.ui.taskViewsStatus = ActionStatus.Idle;
    },
    loadTasksFailed: (state) => {
      state.ui.tasksStatus = ActionStatus.Failed;
      state.ui.taskViewsStatus = ActionStatus.Failed;
    },
    changeTasksViewMode: (state, action: PayloadAction<TaskViewMode>) => {
      state.ui.viewMode = action.payload;
    },
    changeTasksScope: (state, action: PayloadAction<TaskScope>) => {
      state.ui.scope = action.payload;
      state.ui.tasksStatus = ActionStatus.Pending;
    },
    changeTasksGroupBy: (state, action: PayloadAction<TasksGroupBy>) => {
      state.ui.groupBy = action.payload;
      state.ui.tasksStatus = ActionStatus.Pending;
    },
    retrieveTasksRequested: (state, _action: PayloadAction<RetrieveTasksRequest>) => {
      state.entities.tasks.singleTaskIds = [];
      state.entities.tasks.taskListIds = [];
      state.entities.tasks.privateTaskIds = [];
      state.entities.tasks.dueTodayIds = [];
      state.entities.tasks.dueThisWeekIds = [];
      state.entities.tasks.dueNextWeekIds = [];
      state.entities.tasks.dueLaterIds = [];
      state.entities.tasks.draftIds = [];
      state.entities.tasks.assignedIds = [];
      state.entities.tasks.inReviewIds = [];
      state.entities.tasks.completedIds = [];
    },
    retrieveTasksSucceeded: (state, action: PayloadAction<RetrieveTasksResponse>) => {
      const tasksResponse = action.payload;

      switch (tasksResponse.kind.case) {
        case 'pagedTasksByDueDates':
          for (const pagedTasksByDueDateCategory of tasksResponse.kind.value.pagedTasksByDueDates) {
            const dueListIds: string[] = [];

            for (const task of pagedTasksByDueDateCategory.tasks) {
              switch (task.kind.case) {
                case 'singleTaskItem':
                  if (!state.entities.tasks.byId.hasOwnProperty(task.kind.value.singleTaskId)) {
                    state.entities.tasks.byId[task.kind.value.singleTaskId] = task;
                    state.entities.tasks.singleTaskIds.push(task.kind.value.singleTaskId);
                  }
                  dueListIds.push(task.kind.value.singleTaskId);

                  break;
                case 'taskListItem':
                  if (!state.entities.tasks.byId.hasOwnProperty(task.kind.value.taskListId)) {
                    state.entities.tasks.byId[task.kind.value.taskListId] = task;
                    state.entities.tasks.taskListIds.push(task.kind.value.taskListId);
                  }
                  dueListIds.push(task.kind.value.taskListId);

                  break;

                case 'privateTaskItem':
                  if (!state.entities.tasks.byId.hasOwnProperty(task.kind.value.id)) {
                    state.entities.tasks.byId[task.kind.value.id] = task;
                    state.entities.tasks.privateTaskIds.push(task.kind.value.id);
                  }
                  dueListIds.push(task.kind.value.id);

                  break;
              }
            }

            // After all tasks for particular due date type were saved, add task ids to that due date array
            switch (pagedTasksByDueDateCategory.dueDate) {
              case DueDateType.DUE_TODAY:
                state.entities.tasks.dueTodayIds = dueListIds;
                break;
              case DueDateType.DUE_THIS_WEEK:
                state.entities.tasks.dueThisWeekIds = dueListIds;
                break;
              case DueDateType.DUE_NEXT_WEEK:
                state.entities.tasks.dueNextWeekIds = dueListIds;
                break;
              case DueDateType.DUE_LATER:
                state.entities.tasks.dueLaterIds = dueListIds;
                break;
            }

            const index = state.ui.paging.dueDates.sections.findIndex((dd) => dd.dueDateType === pagedTasksByDueDateCategory.dueDate);

            if (index !== -1) {
              state.ui.paging.dueDates[index] = {
                ...state.ui.paging.dueDates[index],
                totalCount: pagedTasksByDueDateCategory.totalCount
              };
            }
            // state.ui.paging.dueDates[index] = { ...state.ui.paging.dueDates[index], totalCount: pagedTasksByDueDateCategory.totalCount };
          }

          break;

        case 'pagedTasksByStatuses':
          for (const pagedTasksByStatus of tasksResponse.kind.value.pagedTasksByStatuses) {
            const statusIds: string[] = [];

            for (const task of pagedTasksByStatus.tasks) {
              switch (task.kind.case) {
                case 'singleTaskItem':
                  if (!state.entities.tasks.byId.hasOwnProperty(task.kind.value.singleTaskId)) {
                    state.entities.tasks.byId[task.kind.value.singleTaskId] = task;
                    state.entities.tasks.singleTaskIds.push(task.kind.value.singleTaskId);
                  }
                  statusIds.push(task.kind.value.singleTaskId);

                  break;
                case 'taskListItem':
                  if (!state.entities.tasks.byId.hasOwnProperty(task.kind.value.taskListId)) {
                    state.entities.tasks.byId[task.kind.value.taskListId] = task;
                    state.entities.tasks.taskListIds.push(task.kind.value.taskListId);
                  }
                  statusIds.push(task.kind.value.taskListId);

                  break;

                case 'privateTaskItem':
                  if (!state.entities.tasks.byId.hasOwnProperty(task.kind.value.id)) {
                    state.entities.tasks.byId[task.kind.value.id] = task;
                    state.entities.tasks.privateTaskIds.push(task.kind.value.id);
                  }
                  statusIds.push(task.kind.value.id);

                  break;
              }
            }

            // After all tasks for particular statusId were saved, add task ids to that status array
            switch (pagedTasksByStatus.statusId) {
              case DRAFT:
                state.entities.tasks.draftIds = statusIds;
                break;
              case ASSIGNED:
                state.entities.tasks.assignedIds = statusIds;
                break;
              case IN_REVIEW:
                state.entities.tasks.inReviewIds = statusIds;
                break;
              case COMPLETED:
                state.entities.tasks.completedIds = statusIds;
                break;
            }

            const index = state.ui.paging.statuses.sections.findIndex((st) => st.statusId === pagedTasksByStatus.statusId);
            if (index !== -1) {
              state.ui.paging.statuses.sections[index] = {
                ...state.ui.paging.statuses.sections[index],
                totalCount: pagedTasksByStatus.totalCount
              };
            } else {
              // Handle the case where the statusId is not found
              console.warn(`Status ID ${pagedTasksByStatus.statusId} not found in sections`);
            }

            // state.ui.paging.statuses[index] = { ...state.ui.paging.statuses[index], totalCount: pagedTasksByStatus.totalCount };
          }

          break;
      }

      state.ui.tasksStatus = ActionStatus.Idle;
    },
    retrieveTasksFailed: (state) => {
      state.ui.tasksStatus = ActionStatus.Failed;
    },

    nextPageByDueDate: (state, action: PayloadAction<DueDateType>) => {
      const index = state.ui.paging.dueDates.sections.findIndex((d) => d.dueDateType === action.payload);
      state.ui.paging.dueDates[index] = {
        ...state.ui.paging.dueDates[index],
        pageNumber: state.ui.paging.dueDates[index].pageNumber + 1
      };
      state.ui.paging.dueDates.activeSection = action.payload;
    },
    nextPageByStatus: (state, action: PayloadAction<string>) => {
      const index = state.ui.paging.statuses.sections.findIndex((s) => s.statusId === action.payload);
      state.ui.paging.statuses[index] = {
        ...state.ui.paging.statuses[index],
        pageNumber: state.ui.paging.statuses[index].pageNumber + 1
      };
      state.ui.paging.statuses.activeSection = action.payload;
    },
    openSingleTaskRequested: (state, _action: PayloadAction<string>) => {
      state.ui.singleTaskViewStatus = ActionStatus.Pending;

      state.entities.comments.byId = {};
      state.entities.comments.allIds = [];

      state.newCommentAttachmentUrls = [];
      state.taskAttachmentUrls = [];
    },
    openSingleTaskSucceeded: (state, action: PayloadAction<RetrieveSingleTaskResponse>) => {
      const singleTask = action.payload;
      const comments = action.payload.comments;

      state.singleTaskView = singleTask;
      for (const comment of comments) {
        state.entities.comments.byId[comment.commentId] = mapComment(comment);
        state.entities.comments.allIds.push(comment.commentId);
      }
      state.selectedSingleTaskId = action.payload.id;
      state.ui.viewMode = TaskViewMode.SingleTask;
      state.ui.singleTaskViewStatus = ActionStatus.Idle;
    },
    openSingleTaskFailed: (_state) => {},
    changeTasksView: (state, action: PayloadAction<string>) => {
      state.activeTasksViewId = action.payload;
    },
    addAssignee: (state, action: PayloadAction<string>) => {
      const assigneeId = action.payload;

      const index = state.entities.assignees.selectedIds.indexOf(assigneeId);

      if (index !== -1) {
        state.entities.assignees.selectedIds.splice(index, 1);
      } else {
        state.entities.assignees.selectedIds.push(assigneeId);
      }
    },

    removeAssignee: (state, action: PayloadAction<string>) => {
      const employeeId = action.payload;

      state.entities.assignees.selectedIds = state.entities.assignees.selectedIds.filter((id) => id !== employeeId);
      state.editSingleTaskRequest.assigneeIds = state.editSingleTaskRequest.assigneeIds.filter((id) => id !== employeeId);
    },
    resetAssignees: (state) => {
      state.entities.assignees.selectedIds = [];
    },
    addAllAssignees: (state, action: PayloadAction<string[]>) => {
      const assigneeIds = action.payload;

      state.entities.assignees.selectedIds = [...state.entities.reviewers.selectedIds, ...assigneeIds];
    },
    addReviewer: (state, action: PayloadAction<string>) => {
      const reviewerId = action.payload;

      const index = state.entities.reviewers.selectedIds.indexOf(reviewerId);

      if (index !== -1) {
        state.entities.reviewers.selectedIds.splice(index, 1);
      } else {
        state.entities.reviewers.selectedIds.push(reviewerId);
      }
    },
    addAllReviewers: (state, action: PayloadAction<string[]>) => {
      const reviewerIds = action.payload;

      state.entities.reviewers.selectedIds = [...state.entities.reviewers.selectedIds, ...reviewerIds];
    },
    resetReviewers: (state) => {
      state.entities.reviewers.selectedIds = [];
    },
    removeReviewer: (state, action: PayloadAction<string>) => {
      const employeeId = action.payload;

      state.entities.reviewers.selectedIds = state.entities.reviewers.selectedIds.filter((id) => id !== employeeId);
    },
    toggleVisibilityTasksView: (state, action: PayloadAction<{ viewId: string; hide: boolean }>) => {
      const { viewId, hide } = action.payload;

      if (hide) state.entities.taskViews.hiddenIds.push(viewId);
      else state.entities.taskViews.hiddenIds = state.entities.taskViews.hiddenIds.filter((id) => id !== viewId);

      state.activeTasksViewId = TASKS_DEFAULT_VIEW_ID;
    },
    renameTasksViewRequested: (_state, _action: PayloadAction<RenameTasksViewRequest>) => {},
    renameTasksViewSucceeded: (state, action: PayloadAction<RenameTasksViewRequest>) => {
      const { taskViewId, name } = action.payload;

      state.entities.taskViews.byId[taskViewId] = {
        ...state.entities.taskViews.byId[taskViewId],
        name
      };
    },
    renameTasksViewFailed: (_state) => {},
    updateTasksViewRequested: (_state, _action: PayloadAction<UpdateTasksViewRequest>) => {},
    updateTasksViewSucceeded: (state, action: PayloadAction<UpdateTasksViewRequest>) => {
      const { taskViewId } = action.payload;

      state.entities.taskViews.byId[taskViewId] = merge(create(TasksViewSchema, { ...state.entities.taskViews.byId[taskViewId] }), action.payload);

      state.entities.taskViews.savedIds.push(taskViewId);

      state.entities.taskViews.unsavedIds = state.entities.taskViews.unsavedIds.filter((id) => id !== taskViewId);
    },
    updateTasksViewFailed: (_state) => {},
    deleteTasksViewRequested: (_state, _action: PayloadAction<DeleteTasksViewRequest>) => {},
    deleteTasksViewSucceeded: (state, action: PayloadAction<DeleteTasksViewRequest>) => {
      const { taskViewId } = action.payload;

      delete state.entities.taskViews.byId[taskViewId];
      state.entities.taskViews.hiddenIds = state.entities.taskViews.hiddenIds.filter((id) => id !== taskViewId);
      state.entities.taskViews.savedIds = state.entities.taskViews.savedIds.filter((id) => id !== taskViewId);
      state.entities.taskViews.unsavedIds = state.entities.taskViews.unsavedIds.filter((id) => id !== taskViewId);

      state.activeTasksViewId = TASKS_DEFAULT_VIEW_ID;
    },
    deleteTasksViewFailed: (_state) => {},
    // TODO: 6 next
    retrieveAssigneeAutocompleteItemsRequested: (state, _action: PayloadAction<RetrieveEmployeesByQueryRequest>) => {
      state.ui.assigneesAutocompleteItemsStatus = ActionStatus.Pending;
    },
    retrieveAssigneeAutocompleteItemsSucceeded: (state, action: PayloadAction<RetrieveEmployeesByQueryResponse>) => {
      state.assigneesAutocompleteItems = action.payload;
      state.ui.assigneesAutocompleteItemsStatus = ActionStatus.Idle;
    },
    retrieveAssigneeAutocompleteItemsFailed: (state) => {
      state.ui.assigneesAutocompleteItemsStatus = ActionStatus.Idle;
    },
    retrieveReviewerAutocompleteItemsRequested: (state, _action: PayloadAction<RetrieveEmployeesByQueryRequest>) => {
      state.ui.reviewersAutocompleteItemsStatus = ActionStatus.Pending;
    },
    retrieveReviewerAutocompleteItemsSucceeded: (state, action: PayloadAction<RetrieveEmployeesByQueryResponse>) => {
      state.reviewersAutocompleteItems = action.payload;
      state.ui.reviewersAutocompleteItemsStatus = ActionStatus.Idle;
    },
    retrieveReviewerAutocompleteItemsFailed: (state) => {
      state.ui.reviewersAutocompleteItemsStatus = ActionStatus.Failed;
    },
    openAssetsModalOnCreateTask: (state) => {
      state.ui.createTaskDrawer.isAssetsModalOpened = true;
    },
    closeAssetsModalOnCreateTask: (state) => {
      state.ui.createTaskDrawer.isAssetsModalOpened = false;
    },
    openAssetsModalOnEditTask: (state) => {
      state.ui.editTaskDrawer.isAssetsModalOpened = true;
    },
    closeAssetsModalOnEditTask: (state) => {
      state.ui.editTaskDrawer.isAssetsModalOpened = false;
    },
    openAssetsModalOnTaskComment: (state) => {
      state.ui.newComment.isAssetsModalOpened = true;
    },
    closeAssetsModalOnTaskComment: (state) => {
      state.ui.newComment.isAssetsModalOpened = false;
    },
    updateSingleTaskRequestForm: (state, action: PayloadAction<Partial<CreateSingleTaskRequest>>) => {
      state.singleTaskRequest = merge(create(CreateSingleTaskRequestSchema, { ...state.singleTaskRequest }), action.payload);

      const validation = CreateSingleTaskRequestZodSchema.safeParse(action.payload);
      state.ui.isSingleTaskFormValid = validation.success;
    },
    editSingleTaskRequestForm: (state, action: PayloadAction<Partial<EditTaskRequest>>) => {
      const task = state.entities.tasks.byId[state.drawerSingleTaskItemId].kind.value as SingleTaskItem;

      state.editSingleTaskRequest = create(EditTaskRequestSchema, {
        ...task,
        ...action.payload
      } as EditTaskRequest);
    },
    // TODO: possible redundant
    validateSingleTaskRequestForm: (state, action: PayloadAction<Partial<CreateSingleTaskRequest>>) => {
      const validation = CreateSingleTaskRequestZodSchema.safeParse(action.payload);
      state.ui.isSingleTaskFormValid = validation.success;
    },
    retrieveSingleTaskRequested: (state, _action: PayloadAction<RetrieveSingleTaskRequest>) => {
      state.ui.singleTaskViewStatus = ActionStatus.Pending;

      state.entities.comments.byId = {};
      state.entities.comments.allIds = [];

      state.newCommentAttachmentUrls = [];
      state.taskAttachmentUrls = [];
    },
    retrieveSingleTaskSucceeded: (state, action: PayloadAction<RetrieveSingleTaskResponse>) => {
      const singleTask = action.payload;
      const comments = action.payload.comments;

      state.singleTaskView = singleTask;

      for (const comment of comments) {
        state.entities.comments.byId[comment.commentId] = mapComment(comment);
        state.entities.comments.allIds.push(comment.commentId);
      }

      state.ui.singleTaskViewStatus = ActionStatus.Idle;
    },
    retrieveSingleTaskFailed: (state) => {
      state.ui.singleTaskViewStatus = ActionStatus.Failed;
    },
    createSingleTaskRequested: (state, action: PayloadAction<CreateSingleTaskRequest>) => {
      const { title, description, dueAt, assigneeIds, reviewerIds, attachmentUrls, taskRelations, publicId, isPrivate, ownerId } = action.payload;

      state.singleTask = create(SingleTaskItemSchema, {
        singleTaskId: '',
        title,
        description,
        dueAt,
        assigneeIds,
        assigneeCount: assigneeIds.length,
        reviewerIds,
        reviewerCount: reviewerIds.length,
        ownerId,
        statusId: assigneeIds.length ? ASSIGNED : DRAFT,
        commentCount: 0,
        attachmentCount: attachmentUrls.length,
        relationCount: taskRelations.length,
        publicId,
        isPrivate,
        taskListId: '',
        taskListPublicId: '',
        taskListTotalTaskCount: 0,
        positionInTaskList: 0,
        isPinned: false,
        createdAt: new Date().toISOString(),
        lastModifiedAt: new Date().toISOString()
      });

      state.ui.createSingleTaskStatus = ActionStatus.Pending;
    },
    createSingleTaskSucceeded: (state, action: PayloadAction<SingleTaskId>) => {
      const taskId = action.payload.id;
      const taskStatus = state.singleTask.statusId;
      const taskDueAt = state.singleTask.dueAt;

      state.entities.tasks.byId[taskId] = create(TaskKindWithPrivateSchema, {
        kind: {
          case: 'singleTaskItem',
          value: create(SingleTaskItemSchema, {
            singleTaskId: taskId,
            title: state.singleTask.title,
            description: state.singleTask.description,
            dueAt: state.singleTask.dueAt,
            assigneeIds: state.singleTask.assigneeIds,
            assigneeCount: state.singleTask.assigneeCount,
            reviewerIds: state.singleTask.reviewerIds,
            reviewerCount: state.singleTask.reviewerCount,
            ownerId: state.singleTask.ownerId,
            statusId: state.singleTask.statusId,
            commentCount: state.singleTask.commentCount,
            attachmentCount: state.singleTask.attachmentCount,
            relationCount: state.singleTask.relationCount,
            publicId: state.singleTask.publicId,
            isPrivate: state.singleTask.isPrivate,
            taskListId: state.singleTask.taskListId,
            taskListPublicId: state.singleTask.taskListPublicId,
            taskListTotalTaskCount: state.singleTask.taskListTotalTaskCount,
            positionInTaskList: state.singleTask.positionInTaskList,
            isPinned: state.singleTask.isPinned,
            createdAt: state.singleTask.createdAt,
            lastModifiedAt: state.singleTask.lastModifiedAt
          })
        }
      });

      state.entities.tasks.singleTaskIds.push(taskId);

      if (taskStatus === DRAFT) {
        state.entities.tasks.draftIds.push(taskId);
      } else if (taskStatus === ASSIGNED) {
        state.entities.tasks.assignedIds.push(taskId);
      } else if (taskStatus === IN_REVIEW) {
        state.entities.tasks.inReviewIds.push(taskId);
      }

      const today = DateTime.now().startOf('day');
      const dueDate = DateTime.fromISO(taskDueAt).startOf('day');

      if (dueDate.hasSame(today, 'day')) {
        state.entities.tasks.dueTodayIds.push(taskId);
      } else if (dueDate <= today.plus({ days: 7 })) {
        state.entities.tasks.dueThisWeekIds.push(taskId);
      } else if (dueDate <= today.plus({ days: 14 })) {
        state.entities.tasks.dueNextWeekIds.push(taskId);
      } else {
        state.entities.tasks.dueLaterIds.push(taskId);
      }

      state.ui.createSingleTaskStatus = ActionStatus.Idle;

      state.singleTaskRequest = create(CreateSingleTaskRequestSchema, {});
      state.taskAttachmentUrls = [];
      state.entities.assignees.selectedIds = [];
      state.entities.reviewers.selectedIds = [];

      // Reset the form validation
      state.ui.isSingleTaskFormValid = false;
    },
    createSingleTaskFailed: (state) => {
      state.ui.createSingleTaskStatus = ActionStatus.Failed;
    },
    deleteSingleTaskRequested: (state, _action: PayloadAction<DeleteSingleTaskRequest>) => {
      state.ui.singleTaskDeleteStatus = ActionStatus.Pending;
    },
    deleteSingleTaskSucceeded: (state, action: PayloadAction<string>) => {
      const taskId = action.payload;

      state.entities.tasks.singleTaskIds = state.entities.tasks.singleTaskIds.filter((id) => id !== taskId);

      state.entities.tasks.allIds = state.entities.tasks.allIds.filter((id) => id !== taskId);
      state.entities.tasks.dueTodayIds = state.entities.tasks.dueTodayIds.filter((id) => id !== taskId);
      state.entities.tasks.dueThisWeekIds = state.entities.tasks.dueThisWeekIds.filter((id) => id !== taskId);
      state.entities.tasks.dueNextWeekIds = state.entities.tasks.dueNextWeekIds.filter((id) => id !== taskId);
      state.entities.tasks.dueLaterIds = state.entities.tasks.dueLaterIds.filter((id) => id !== taskId);
      state.entities.tasks.draftIds = state.entities.tasks.draftIds.filter((id) => id !== taskId);
      state.entities.tasks.assignedIds = state.entities.tasks.assignedIds.filter((id) => id !== taskId);
      state.entities.tasks.inReviewIds = state.entities.tasks.inReviewIds.filter((id) => id !== taskId);
      state.entities.tasks.completedIds = state.entities.tasks.completedIds.filter((id) => id !== taskId);

      delete state.entities.tasks.byId[taskId];

      state.ui.singleTaskDeleteStatus = ActionStatus.Idle;
    },
    deleteSingleTaskFailed: (state) => {
      state.ui.singleTaskDeleteStatus = ActionStatus.Failed;
    },
    editTaskRequested: (_state, _action: PayloadAction<EditTaskRequest>) => {},
    editTaskSucceeded: (state, action: PayloadAction<EditTaskRequest>) => {
      const singleTaskItem = state.entities.tasks.byId[action.payload.taskId].kind.value as SingleTaskItem;
      let singleTaskStatusId = singleTaskItem.statusId;
      if (singleTaskStatusId === DRAFT) {
        if (action.payload.assigneeIds.length > 0) {
          singleTaskStatusId = ASSIGNED;
        }
      } else if (singleTaskStatusId === ASSIGNED) {
        if (action.payload.assigneeIds.length === 0) {
          singleTaskStatusId = DRAFT;
        }
      }
      state.entities.tasks.byId[action.payload.taskId] = create(TaskKindWithPrivateSchema, {
        ...state.entities.tasks.byId[action.payload.taskId],
        kind: {
          case: 'singleTaskItem',
          value: create(SingleTaskItemSchema, {
            ...state.entities.tasks.byId[action.payload.taskId].kind.value,
            title: action.payload.title,
            description: action.payload.description,
            dueAt: action.payload.dueAt,
            assigneeIds: action.payload.assigneeIds,
            assigneeCount: action.payload.assigneeIds.length,
            reviewerIds: action.payload.reviewerIds,
            reviewerCount: action.payload.reviewerIds.length,
            statusId: singleTaskStatusId,
            lastModifiedAt: new Date().toISOString(),
            attachmentCount: action.payload.attachmentUrls.length,
            attachmentUrls: action.payload.attachmentUrls,
            relationCount: action.payload.taskRelations.length
          } as SingleTaskItem)
        }
      });

      state.singleTaskView = {
        ...state.singleTaskView,
        title: action.payload.title,
        statusId: singleTaskStatusId,
        description: action.payload.description,
        dueAt: action.payload.dueAt,
        assigneeIds: action.payload.assigneeIds,
        reviewers: action.payload.reviewerIds.map((id) =>
          create(ReviewerSchema, {
            id: id
          })
        ),
        attachmentUrls: action.payload.attachmentUrls,
        requireAllReviewers: action.payload.taskRelations.some((tr) => tr.kind.value?.relationTypeId === 'DEPENDS_ON_ID')
      };

      state.drawerSingleTaskItemId = '';
      state.taskAttachmentUrls = [];
      state.editSingleTaskRequest = {} as EditTaskRequest;
    },
    editTaskFailed: (_state) => {},
    archiveTaskRequested: (_state, _action: PayloadAction<SingleTaskId>) => {},
    archiveTaskSucceeded: (_state, _action: PayloadAction<string>) => {},
    archiveTaskFailed: (_state) => {},
    updateTaskListRequestForm: (state, action: PayloadAction<Partial<CreateTaskListRequest>>) => {
      state.taskListRequest = merge({}, state.taskListRequest, action.payload);
    },
    editTaskListRequestForm: (state, action: PayloadAction<Partial<EditTaskListRequest>>) => {
      state.editTaskListRequest = merge({}, state.editTaskListRequest, action.payload);
    },
    editTaskListRequestFormTaskIds: (state, action: PayloadAction<Partial<EditTaskListRequest>>) => {
      state.editTaskListRequest.taskIds = action.payload.taskIds!;
    },
    resetSingleTaskRequestForm: (state) => {
      state.entities.tasksDependsOnTasks.byId = {};
      state.entities.tasksDependsOnTasks.allIds = [];
      state.entities.tasksDependsForTasks.byId = {};
      state.entities.tasksDependsForTasks.allIds = [];
      state.ui.singleTasksInTaskListDrawer = [];
      state.ui.singleTasksForTaskList = [];
      state.singleTaskRequest = {} as CreateSingleTaskRequest;
      state.ui.isLoadedTasksForTaskList = false;
      state.entities.assignees.selectedIds = [];
      state.entities.assignees.initialIds = [];
      state.entities.reviewers.selectedIds = [];

      state.entities.reviewers.initialIds = [];
    },
    resetTaskListRequestForm: (state) => {
      state.entities.tasksDependsOnTasks.byId = {};
      state.entities.tasksDependsOnTasks.allIds = [];
      state.entities.tasksDependsForTasks.byId = {};
      state.entities.tasksDependsForTasks.allIds = [];
      state.ui.singleTasksInTaskListDrawer = [];
      state.ui.singleTasksForTaskList = [];
      state.taskListRequest = {} as CreateTaskListRequest;
      state.ui.isLoadedTasksForTaskList = false;
      state.entities.assignees.selectedIds = [];
      state.entities.assignees.initialIds = [];
      state.entities.reviewers.selectedIds = [];
      state.entities.reviewers.initialIds = [];
    },
    resetEditTaskListRequestForm: (state) => {
      state.entities.tasksDependsOnTasks.byId = {};
      state.entities.tasksDependsOnTasks.allIds = [];
      state.entities.tasksDependsForTasks.byId = {};
      state.entities.tasksDependsForTasks.allIds = [];
      state.ui.singleTasksInTaskListDrawer = [];
      state.ui.singleTasksForTaskList = [];
      state.editTaskListRequest = {} as EditTaskListRequest;
      state.ui.isLoadedTasksForTaskList = false;
      state.entities.assignees.selectedIds = [];
      state.entities.assignees.initialIds = [];
      state.entities.reviewers.selectedIds = [];
      state.entities.reviewers.initialIds = [];
    },
    createTaskListRequested: (_state, _action: PayloadAction<CreateTaskListRequest>) => {},
    createTaskListSucceeded: (state, action: PayloadAction<[TaskListId, CreateTaskListRequest, SingleTaskItem[]]>) => {
      const taskListId = action.payload[0].id;
      const taskList = action.payload[1];

      const tasks = action.payload[2];
      let hasDraftTask = false;
      let hasAssignedTask = false;
      let hasInReviewTask = false;
      let hasCompletedTask = false;

      // Iterate over task IDs to count the occurrences of each status
      for (const task of tasks) {
        if (task.statusId === DRAFT) {
          hasDraftTask = true;
        } else if (task.statusId === ASSIGNED) {
          hasAssignedTask = true;
        } else if (task.statusId === IN_REVIEW) {
          hasInReviewTask = true;
        } else if (task.statusId === COMPLETED) {
          hasCompletedTask = true;
        }
      }
      // Determine the overall status based on the counts
      let overallStatus = DRAFT;
      if (hasDraftTask) {
        overallStatus = DRAFT;
      } else if (hasAssignedTask) {
        overallStatus = ASSIGNED;
      } else if (hasInReviewTask) {
        overallStatus = IN_REVIEW;
      } else if (hasCompletedTask) {
        overallStatus = COMPLETED;
      }

      const taskListDueAt = tasks.reduce((furthest, task) => (task.dueAt > furthest.dueAt ? task : furthest), tasks[0]).dueAt;

      state.entities.tasks.byId[taskListId] = create(TaskKindWithPrivateSchema, {
        kind: {
          case: 'taskListItem',
          value: create(TaskListItemSchema, {
            taskListId: taskListId,
            title: taskList.title,
            description: taskList.description,
            owner: taskList.ownerId,
            statusId: overallStatus,
            publicId: taskList.publicId,
            isPrivate: true,
            createdAt: new Date().toISOString(),
            lastModifiedAt: '',
            singleTasks: tasks,
            shouldSendEmail: taskList.shouldSendEmail,
            attachmentCount: tasks.reduce((sum, t) => sum + t.attachmentCount, 0),
            assigneeCount: tasks.reduce((sum, t) => sum + t.assigneeCount, 0),
            reviewerCount: tasks.reduce((sum, t) => sum + t.reviewerCount, 0),
            relationCount: taskList.taskRelations.length,
            commentCount: tasks.reduce((sum, t) => sum + t.commentCount, 0),
            completedTaskCount: tasks.filter((t) => t.statusId === COMPLETED).length,
            totalTaskCount: tasks.length,
            dueAt: taskListDueAt,
            someAssigneeIds: tasks.reduce((acc, t) => [...acc, ...t.assigneeIds], [] as string[]),
            someReviewerIds: tasks.reduce((acc, t) => [...acc, ...t.reviewerIds], [] as string[]),
            isPinned: false
          })
        }
      });

      state.entities.tasks.taskListIds.push(taskListId);

      if (overallStatus === DRAFT) {
        state.entities.tasks.draftIds.push(taskListId);
      } else if (overallStatus === ASSIGNED) {
        state.entities.tasks.assignedIds.push(taskListId);
      } else if (overallStatus === IN_REVIEW) {
        state.entities.tasks.inReviewIds.push(taskListId);
      }

      const today = DateTime.now().startOf('day');
      const dueDate = DateTime.fromISO(taskListDueAt).startOf('day');

      if (dueDate.hasSame(today, 'day')) {
        state.entities.tasks.dueTodayIds.push(taskListId);
      } else if (today < dueDate && dueDate <= today.plus({ days: 7 })) {
        state.entities.tasks.dueThisWeekIds.push(taskListId);
      } else if (today.plus({ days: 7 }) < dueDate && dueDate <= today.plus({ days: 14 })) {
        state.entities.tasks.dueNextWeekIds.push(taskListId);
      } else if (today.plus({ days: 14 }) < dueDate) {
        state.entities.tasks.dueLaterIds.push(taskListId);
      }
    },
    createTaskListFailed: (_state) => {},
    retrieveEmployeesAutocompleteItemsRequested: (state, _action: PayloadAction<RetrieveEmployeesByQueryRequest>) => {
      state.ui.reviewersAutocompleteItemsStatus = ActionStatus.Pending;
      state.ui.assigneesAutocompleteItemsStatus = ActionStatus.Pending;
    },
    retrieveEmployeesAutocompleteItemsSucceeded: (state, action: PayloadAction<RetrieveEmployeesByQueryResponse>) => {
      state.reviewersAutocompleteItems = action.payload;
      state.assigneesAutocompleteItems = action.payload;
      state.ui.reviewersAutocompleteItemsStatus = ActionStatus.Idle;
      state.ui.assigneesAutocompleteItemsStatus = ActionStatus.Idle;
    },
    retrieveEmployeesAutocompleteItemsFailed: (state) => {
      state.ui.reviewersAutocompleteItemsStatus = ActionStatus.Failed;
      state.ui.assigneesAutocompleteItemsStatus = ActionStatus.Failed;
    },
    reactToTaskRequested: (state, action: PayloadAction<ReactToTaskRequest>) => {
      state.singleTaskView.reactions ??= [];

      state.singleTaskView = {
        ...state.singleTaskView,
        reactions: [
          ...state.singleTaskView.reactions,
          create(ReactionSchema, {
            authorId: action.payload.authorId,
            emoji: action.payload.emoji,
            reactedAt: new Date().toISOString()
          })
        ]
      };
    },
    reactToTaskSucceeded: (_state) => {},
    reactToTaskFailed: (state, action: PayloadAction<ReactToTaskRequest>) => {
      state.singleTaskView.reactions[action.payload.emoji] = state.singleTaskView.reactions[action.payload.emoji].filter(
        (r) => r.authorId !== action.payload.authorId
      );
    },
    withdrawTaskReactionRequested: (state, action: PayloadAction<WithdrawTaskReactionRequest>) => {
      state.singleTaskView.reactions = state.singleTaskView.reactions.filter(
        (r) => !(r.authorId === action.payload.authorId && r.emoji === action.payload.emoji)
      );
    },
    withdrawTaskReactionSucceeded: (_state) => {},
    withdrawTaskReactionFailed: (state, action: PayloadAction<WithdrawTaskReactionRequest>) => {
      state.singleTaskView.reactions ??= [];

      state.singleTaskView = {
        ...state.singleTaskView,
        reactions: [
          ...state.singleTaskView.reactions,
          create(ReactionSchema, {
            authorId: action.payload.authorId,
            emoji: action.payload.emoji,
            reactedAt: new Date().toISOString()
          })
        ]
      };
    },
    commentOnTaskRequested: (state, action: PayloadAction<CommentOnTaskRequest>) => {
      state.singleTaskView.comments ??= [];

      const { commentId, commentText, authorId, isImportant, attachmentUrls } = action.payload;

      state.singleTaskView = {
        ...state.singleTaskView,
        comments: [...state.singleTaskView.comments, create(CommentSchema, { commentId, text: commentText, authorId, isImportant, attachmentUrls })]
      };
      state.ui.commentOnTaskStatus = ActionStatus.Pending;
    },
    commentOnTaskSucceeded: (state, action: PayloadAction<CommentOnTaskRequest>) => {
      const comment = action.payload;

      state.entities.comments.byId[comment.commentId] = {
        id: comment.commentId,
        text: comment.commentText,
        authorId: comment.authorId,
        isImportant: comment.isImportant,
        reactions: {},
        commentTime: DateTime.now(),
        attachmentUrls: comment.attachmentUrls
      };
      state.entities.comments.allIds.push(comment.commentId);
      state.singleTaskView.attachmentUrls = [...state.singleTaskView.attachmentUrls, ...action.payload.attachmentUrls];

      state.ui.commentOnTaskStatus = ActionStatus.Idle;
      state.newCommentAttachmentUrls = [];
    },
    commentOnTaskFailed: (state, action: PayloadAction<string>) => {
      state.singleTaskView.comments = state.singleTaskView.comments.filter((c) => c.commentId !== action.payload);
      state.ui.commentOnTaskStatus = ActionStatus.Failed;
    },
    reactToTaskCommentRequested: (state, action: PayloadAction<ReactToTaskCommentRequest>) => {
      const { commentId, emoji, authorId } = action.payload;
      const comment = state.entities.comments.byId[commentId];

      if (!comment) return;

      comment.reactions[emoji] ??= [];

      comment.reactions[emoji].push({
        emoji,
        authorId,
        reactedAt: DateTime.now().toISO()
      } as Reaction);
    },

    reactToTaskCommentSucceeded: (_state) => {},
    reactToTaskCommentFailed: (state, action: PayloadAction<ReactToTaskCommentRequest>) => {
      const { commentId, emoji, authorId } = action.payload;
      const comment = state.entities.comments.byId[commentId];

      if (!comment || !comment.reactions[emoji]) return;

      comment.reactions[emoji] = comment.reactions[emoji].filter((r) => r.authorId !== authorId);
    },
    withdrawTaskCommentReactionRequested: (state, action: PayloadAction<WithdrawTaskCommentReactionRequest>) => {
      const { commentId, authorId, emoji } = action.payload;
      const comments = state.entities.comments.byId;
      const comment = comments[commentId];

      if (!comment) return;

      comments[commentId] = {
        ...comment,
        reactions: {
          ...comment.reactions,
          [emoji]: comment.reactions[emoji].filter((r) => !(r.authorId === authorId && r.emoji === emoji))
        }
      };
    },
    withdrawTaskCommentReactionSucceeded: (_state) => {},
    withdrawTaskCommentReactionFailed: (state, action: PayloadAction<WithdrawTaskCommentReactionRequest>) => {
      state.entities.comments.byId[action.payload.commentId].reactions[action.payload.emoji] ??= [];
      state.entities.comments.byId[action.payload.commentId].reactions[action.payload.emoji].push(
        create(ReactionSchema, {
          authorId: action.payload.authorId,
          emoji: action.payload.emoji,
          reactedAt: new Date().toISOString()
        })
      );
    },
    editTaskCommentRequested: (_state, _action: PayloadAction<EditTaskCommentRequest>) => {},
    editTaskCommentSucceeded: (state, action: PayloadAction<EditTaskCommentRequest>) => {
      const { commentId, commentText } = action.payload;
      const comment = state.entities.comments.byId[commentId];

      if (!comment) return;

      state.entities.comments.byId[commentId] = {
        ...comment,
        text: commentText,
        attachmentUrls: action.payload.attachmentUrls
      };
      state.singleTaskView.attachmentUrls = [...(state.singleTaskView.attachmentUrls || []), ...action.payload.attachmentUrls];
      state.newCommentAttachmentUrls = [];
      state.editableComment = null;
    },
    editTaskCommentFailed: (_state) => {},
    deleteTaskCommentRequested: (_state, _action: PayloadAction<DeleteTaskCommentRequest>) => {},
    deleteTaskCommentSucceeded: (state, action: PayloadAction<DeleteTaskCommentRequest>) => {
      const { commentId } = action.payload;
      const commentAttachmentUrls = state.entities.comments.byId[commentId].attachmentUrls;

      state.entities.comments.allIds = state.entities.comments.allIds.filter((id) => id !== commentId);
      state.singleTaskView.attachmentUrls = state.singleTaskView.attachmentUrls.filter((url) => !commentAttachmentUrls.includes(url));
    },
    deleteTaskCommentFailed: (_state) => {},
    retrieveTasksForDependenciesRequested: (state) => {
      state.ui.tasksForDependenciesStatus = ActionStatus.Pending;
    },
    retrieveTasksForDependenciesSucceeded: (state, action: PayloadAction<RetrieveTasksForDependenciesResponse>) => {
      const { tasks } = action.payload;

      for (const task of tasks) {
        state.entities.tasksForDependencies.byId[task.id] = task;
        state.entities.tasksForDependencies.allIds.push(task.id);
      }

      state.ui.tasksForDependenciesStatus = ActionStatus.Idle;
    },
    retrieveTasksForDependenciesFailed: (state) => {
      state.ui.tasksForDependenciesStatus = ActionStatus.Failed;
    },
    selectDependsOnTaskDependencies: (state, action: PayloadAction<SimpleTask[]>) => {
      for (const task of action.payload) {
        if (!state.entities.tasksDependsOnTasks.hasOwnProperty(task.id)) {
          state.entities.tasksDependsOnTasks.byId[task.id] = task;
          state.entities.tasksDependsOnTasks.allIds.push(task.id);
        }
      }

      const removeIds = state.entities.tasksDependsOnTasks.allIds.filter((id) => !action.payload.some((p) => id === p.id));
      for (const id of removeIds) {
        delete state.entities.tasksDependsOnTasks.byId[id];
        state.entities.tasksDependsOnTasks.allIds = state.entities.tasksDependsOnTasks.allIds.filter((p) => p !== id);
      }
    },
    selectDependsForTaskDependencies: (state, action: PayloadAction<SimpleTask[]>) => {
      for (const task of action.payload) {
        if (!state.entities.tasksDependsForTasks.hasOwnProperty(task.id)) {
          state.entities.tasksDependsForTasks.byId[task.id] = task;
          state.entities.tasksDependsForTasks.allIds.push(task.id);
        }
      }

      const removeIds = state.entities.tasksDependsForTasks.allIds.filter((id) => !action.payload.some((p) => id === p.id));
      for (const id of removeIds) {
        delete state.entities.tasksDependsForTasks.byId[id];
        state.entities.tasksDependsForTasks.allIds = state.entities.tasksDependsForTasks.allIds.filter((p) => p !== id);
      }
    },
    removeDependsForTaskDependencies: (_state, _action: PayloadAction<string>) => {},
    setDrawerSingleTaskItemId: (state, action: PayloadAction<string>) => {
      const drawerSingleTaskItem = state.entities.tasks.byId[action.payload].kind.value as SingleTaskItem;

      state.drawerSingleTaskItemId = action.payload;
      state.entities.assignees.selectedIds = drawerSingleTaskItem.assigneeIds;
      state.entities.reviewers.selectedIds = drawerSingleTaskItem.reviewerIds;

      const { $typeName: _typeName, ...rest } = drawerSingleTaskItem;
      state.editSingleTaskRequest = create(EditTaskRequestSchema, {
        ...rest,
        areAllReviewersRequired: drawerSingleTaskItem.allReviewersRequired
      });
    },
    retrieveSingleTasksInTaskListRequested: (state, _action: PayloadAction<TaskListId>) => {
      state.ui.singleTasksInTaskListStatus = ActionStatus.Pending;
    },
    retrieveSingleTasksInTaskListSucceeded: (state, action: PayloadAction<[TaskListId, RetrieveSingleTasksInTaskListResponse]>) => {
      const taskListId = action.payload[0].id;
      const singleTasks = action.payload[1].singleTasks;
      (state.entities.tasks.byId[taskListId].kind.value as TaskListItem).singleTasks = singleTasks;

      for (const task of singleTasks) {
        if (!state.entities.tasks.byId.hasOwnProperty(task.singleTaskId)) {
          state.entities.tasks.byId[task.singleTaskId] = create(TaskKindWithPrivateSchema, { kind: { case: 'singleTaskItem', value: task } });
          state.entities.tasks.singleTaskIds.push(task.singleTaskId);
        }
      }

      state.ui.singleTasksInTaskListStatus = ActionStatus.Idle;
    },
    retrieveSingleTasksInTaskListFailed: (state) => {
      state.ui.singleTasksInTaskListStatus = ActionStatus.Failed;
    },
    markAsCompleteRequested: (state, _action: PayloadAction<SingleTaskIdWithEmployeeId>) => {
      state.ui.singleTaskViewActionButtonStatus = ActionStatus.Pending;
    },
    markAsCompleteSucceeded: (state, action: PayloadAction<SingleTaskIdWithEmployeeId>) => {
      if (state.entities.tasks.byId.hasOwnProperty(action.payload.singleTaskId))
        (state.entities.tasks.byId[action.payload.singleTaskId].kind.value as SingleTaskItem).statusId = COMPLETED;

      state.singleTaskView = {
        ...state.singleTaskView,
        statusId: COMPLETED
      };
      state.ui.singleTaskViewActionButtonStatus = ActionStatus.Idle;
    },
    markAsCompleteFailed: (_state) => {},
    sendToReviewerRequested: (state, _action: PayloadAction<SingleTaskIdWithEmployeeId>) => {
      state.ui.singleTaskViewActionButtonStatus = ActionStatus.Pending;
    },
    sendToReviewerSucceeded: (state, action: PayloadAction<SingleTaskIdWithEmployeeId>) => {
      if (state.entities.tasks.byId.hasOwnProperty(action.payload.singleTaskId))
        (state.entities.tasks.byId[action.payload.singleTaskId].kind.value as SingleTaskItem).statusId = IN_REVIEW;

      state.singleTaskView = {
        ...state.singleTaskView,
        statusId: IN_REVIEW
      };
      state.ui.singleTaskViewActionButtonStatus = ActionStatus.Idle;
    },
    sendToReviewerFailed: (_state) => {},
    returnToAssigneeRequested: (state, _action: PayloadAction<SingleTaskIdWithEmployeeId>) => {
      state.ui.singleTaskViewActionButtonStatus = ActionStatus.Pending;
    },
    returnToAssigneeSucceeded: (state, action: PayloadAction<SingleTaskIdWithEmployeeId>) => {
      if (state.entities.tasks.byId.hasOwnProperty(action.payload.singleTaskId))
        (state.entities.tasks.byId[action.payload.singleTaskId].kind.value as SingleTaskItem).statusId = ASSIGNED;

      state.singleTaskView = {
        ...state.singleTaskView,
        statusId: ASSIGNED,
        reviewers: state.singleTaskView.reviewers.map((r) => create(ReviewerSchema, { id: r.id, hasReviewed: false }))
      };
      state.ui.singleTaskViewActionButtonStatus = ActionStatus.Idle;
    },
    returnToAssigneeFailed: (_state) => {},
    approveTaskRequested: (state, _action: PayloadAction<SingleTaskIdWithEmployeeId>) => {
      state.ui.singleTaskViewActionButtonStatus = ActionStatus.Pending;
    },
    approveTaskSucceeded: (state, action: PayloadAction<SingleTaskIdWithEmployeeId>) => {
      const { singleTaskId, employeeId } = action.payload;
      const taskExists = state.entities.tasks.byId.hasOwnProperty(singleTaskId);

      if (state.singleTaskView.requireAllReviewers) {
        const allReviewed = state.singleTaskView.reviewers.every((r) => r.hasReviewed === true || r.id === employeeId);

        if (allReviewed || state.singleTaskView.ownerId === employeeId) {
          if (taskExists) {
            (state.entities.tasks.byId[singleTaskId].kind.value as SingleTaskItem).statusId = COMPLETED;
          }

          state.singleTaskView = {
            ...state.singleTaskView,
            statusId: COMPLETED,
            reviewers: state.singleTaskView.reviewers.map((r) => create(ReviewerSchema, { id: r.id, hasReviewed: true }))
          };
        } else {
          state.singleTaskView = {
            ...state.singleTaskView,
            reviewers: state.singleTaskView.reviewers.map((r) => (r.id === employeeId ? create(ReviewerSchema, { id: r.id, hasReviewed: true }) : r))
          };
        }
      } else {
        if (taskExists) {
          (state.entities.tasks.byId[singleTaskId].kind.value as SingleTaskItem).statusId = COMPLETED;
        }

        state.singleTaskView = {
          ...state.singleTaskView,
          statusId: COMPLETED,
          reviewers: state.singleTaskView.reviewers.map((r) => (r.id === employeeId ? create(ReviewerSchema, { id: r.id, hasReviewed: true }) : r))
        };
      }

      state.ui.singleTaskViewActionButtonStatus = ActionStatus.Idle;
    },
    approveTaskFailed: (_state) => {},
    returnToReviewerRequested: (state, _action: PayloadAction<SingleTaskIdWithEmployeeId>) => {
      state.ui.singleTaskViewActionButtonStatus = ActionStatus.Pending;
    },
    returnToReviewerSucceeded: (state, action: PayloadAction<SingleTaskIdWithEmployeeId>) => {
      if (state.entities.tasks.byId.hasOwnProperty(action.payload.singleTaskId))
        (state.entities.tasks.byId[action.payload.singleTaskId].kind.value as SingleTaskItem).statusId = IN_REVIEW;

      state.singleTaskView = {
        ...state.singleTaskView,
        statusId: IN_REVIEW,
        reviewers: state.singleTaskView.reviewers.map((r) => create(ReviewerSchema, { id: r.id, hasReviewed: false }))
      };
      state.ui.singleTaskViewActionButtonStatus = ActionStatus.Idle;
    },
    returnToReviewerFailed: (_state) => {},
    createTempTaskView: (state, action: PayloadAction<CreateTasksViewRequest>) => {
      if (!state.entities.taskViews.byId[action.payload.tasksView!.tasksViewId]) {
        state.entities.taskViews.byId[action.payload.tasksView!.tasksViewId] = action.payload.tasksView!;
        state.entities.taskViews.unsavedIds.push(action.payload.tasksView!.tasksViewId);
        state.activeTasksViewId = action.payload.tasksView!.tasksViewId;
      }
    },
    updateTempTaskView: (state, action: PayloadAction<CreateTasksViewRequest>) => {
      state.entities.taskViews.byId[action.payload.tasksView!.tasksViewId] = {
        ...state.entities.taskViews.byId[action.payload.tasksView!.tasksViewId],
        ...action.payload.tasksView
      };

      state.entities.taskViews.unsavedIds.push(action.payload.tasksView!.tasksViewId);
    },
    createTaskViewRequested: (_state, _action: PayloadAction<CreateTasksViewRequest>) => {},
    createTaskViewSucceeded: (state, action: PayloadAction<CreateTasksViewRequest>) => {
      const taskViewId = action.payload.tasksView!.tasksViewId;

      state.entities.taskViews.savedIds.push(taskViewId);
      state.entities.taskViews.unsavedIds = state.entities.taskViews.unsavedIds.filter((id) => id !== taskViewId);
    },
    createTaskViewFailed: (_state) => {},
    hideTaskView: (state, action: PayloadAction<string>) => {
      state.entities.taskViews.hiddenIds.push(action.payload);
      state.activeTasksViewId = TASKS_DEFAULT_VIEW_ID;
    },
    retrieveSingleTasksForTaskListRequested: (_state) => {},
    retrieveSingleTasksForTaskListSucceeded: (state, action: PayloadAction<RetrieveSingleTasksForTaskListResponse>) => {
      if (state.ui.singleTasksInTaskListDrawer.length === 0) {
        state.ui.singleTasksForTaskList = action.payload.tasks;
      } else {
        state.ui.singleTasksForTaskList = [...state.ui.singleTasksForTaskList, ...action.payload.tasks];
      }
    },
    retrieveSingleTasksForTaskListFailed: (_state) => {},
    retrieveSingleTaskRelationsRequested: (state, _action: PayloadAction<SingleTaskId>) => {
      state.entities.tasksDependsOnTasks.byId = {};
      state.entities.tasksDependsOnTasks.allIds = [];
      state.entities.tasksDependsForTasks.byId = {};
      state.entities.tasksDependsForTasks.allIds = [];
    },
    retrieveSingleTaskRelationsSucceeded: (state, action: PayloadAction<RetrieveSingleTaskRelationsResponse>) => {
      const { tasks } = action.payload;

      for (const task of tasks) {
        const taskRelation = task.kind.case === 'singleTask' ? (task.kind.value as RelationSingleTask) : (task.kind.value as RelationTaskList);

        if (taskRelation.relationshipTypeId === DEPENDS_ON_ID) {
          state.entities.tasksDependsOnTasks.byId[taskRelation.id] = create(SimpleTaskSchema, {
            id: taskRelation.id,
            publicId: taskRelation.publicId,
            title: taskRelation.title,
            statusId: taskRelation.statusId,
            type: task.kind.case === 'singleTask' ? TaskType.SINGLE_TASK : TaskType.TASK_LIST
          });
          state.entities.tasksDependsOnTasks.allIds.push(taskRelation.id);
        } else if (taskRelation.relationshipTypeId === DEPENDENCY_FOR_ID) {
          state.entities.tasksDependsForTasks.byId[taskRelation.id] = create(SimpleTaskSchema, {
            id: taskRelation.id,
            publicId: taskRelation.publicId,
            title: taskRelation.title,
            statusId: taskRelation.statusId,
            type: task.kind.case === 'singleTask' ? TaskType.SINGLE_TASK : TaskType.TASK_LIST
          });
          state.entities.tasksDependsForTasks.allIds.push(taskRelation.id);
        }
      }
    },
    retrieveSingleTaskRelationsFailed: (_state) => {},
    deleteTaskListRequested: (_state, _action: PayloadAction<DeleteTaskListRequest>) => {},
    deleteTaskListSucceeded: (state, action: PayloadAction<DeleteTaskListRequest>) => {
      const taskListId = action.payload.taskListId;

      state.entities.tasks.taskListIds = state.entities.tasks.taskListIds.filter((id) => id !== taskListId);
      state.entities.tasks.allIds = state.entities.tasks.allIds.filter((id) => id !== taskListId);
      state.entities.tasks.dueTodayIds = state.entities.tasks.dueTodayIds.filter((id) => id !== taskListId);
      state.entities.tasks.dueThisWeekIds = state.entities.tasks.dueThisWeekIds.filter((id) => id !== taskListId);
      state.entities.tasks.dueNextWeekIds = state.entities.tasks.dueNextWeekIds.filter((id) => id !== taskListId);
      state.entities.tasks.dueLaterIds = state.entities.tasks.dueLaterIds.filter((id) => id !== taskListId);
      state.entities.tasks.draftIds = state.entities.tasks.draftIds.filter((id) => id !== taskListId);
      state.entities.tasks.assignedIds = state.entities.tasks.assignedIds.filter((id) => id !== taskListId);
      state.entities.tasks.inReviewIds = state.entities.tasks.inReviewIds.filter((id) => id !== taskListId);
      state.entities.tasks.completedIds = state.entities.tasks.completedIds.filter((id) => id !== taskListId);

      delete state.entities.tasks.byId[taskListId];
    },
    deleteTaskListFailed: (_state) => {},
    setDrawerTaskListItemId: (state, action: PayloadAction<string>) => {
      const drawerTaskListItemId = action.payload;
      const drawerTaskListItem = state.entities.tasks.byId[drawerTaskListItemId].kind.value as TaskListItem;

      state.drawerTaskListItemId = drawerTaskListItemId;
      state.drawerTaskListItem = drawerTaskListItem;

      state.editTaskListRequest = create(EditTaskListRequestSchema, {
        taskListId: drawerTaskListItemId,
        description: drawerTaskListItem.description,
        shouldSendEmail: drawerTaskListItem.shouldSendEmail,
        title: drawerTaskListItem.title
      });
      if (drawerTaskListItem.singleTasks.length !== 0) {
        state.ui.singleTasksInTaskListDrawer = drawerTaskListItem.singleTasks.map((t) =>
          create(SimpleTaskSchema, { id: t.singleTaskId, publicId: t.publicId, title: t.title, statusId: t.statusId, type: TaskType.SINGLE_TASK })
        );
      }
    },
    retrieveTaskListRelationsRequested: (_state, _action: PayloadAction<TaskListId>) => {},
    retrieveTaskListRelationsSucceeded: (state, action: PayloadAction<RetrieveTaskListRelationsResponse>) => {
      const { tasks } = action.payload;

      for (const task of tasks) {
        const taskRelation = task.kind.case === 'singleTask' ? (task.kind.value as RelationSingleTask) : (task.kind.value as RelationTaskList);

        if (taskRelation.relationshipTypeId === DEPENDS_ON_ID) {
          state.entities.tasksDependsOnTasks.byId[taskRelation.id] = create(SimpleTaskSchema, {
            id: taskRelation.id,
            publicId: taskRelation.publicId,
            title: taskRelation.title,
            statusId: taskRelation.statusId,
            type: task.kind.case === 'singleTask' ? TaskType.SINGLE_TASK : TaskType.TASK_LIST
          });
          state.entities.tasksDependsOnTasks.allIds.push(taskRelation.id);
        } else if (taskRelation.relationshipTypeId === DEPENDENCY_FOR_ID) {
          state.entities.tasksDependsForTasks.byId[taskRelation.id] = create(SimpleTaskSchema, {
            id: taskRelation.id,
            publicId: taskRelation.publicId,
            title: taskRelation.title,
            statusId: taskRelation.statusId,
            type: task.kind.case === 'singleTask' ? TaskType.SINGLE_TASK : TaskType.TASK_LIST
          });
          state.entities.tasksDependsForTasks.allIds.push(taskRelation.id);
        }
      }
    },
    retrieveTaskListRelationsFailed: (_state) => {},
    editTaskListRequested: (_state, _action: PayloadAction<EditTaskListRequest>) => {},
    editTaskListSucceeded: (state, action: PayloadAction<[EditTaskListRequest, SingleTaskItem[]]>) => {
      const editTaskListRequest = action.payload[0];
      const tasks = action.payload[1];
      let hasDraftTask = false;
      let hasAssignedTask = false;
      let hasInReviewTask = false;
      let hasCompletedTask = false;

      // Iterate over task IDs to count the occurrences of each status
      for (const task of tasks) {
        if (task.statusId === DRAFT) {
          hasDraftTask = true;
        } else if (task.statusId === ASSIGNED) {
          hasAssignedTask = true;
        } else if (task.statusId === IN_REVIEW) {
          hasInReviewTask = true;
        } else if (task.statusId === COMPLETED) {
          hasCompletedTask = true;
        }
      }
      // Determine the overall status based on the counts
      let overallStatus = DRAFT;
      if (hasDraftTask) {
        overallStatus = DRAFT;
      } else if (hasAssignedTask) {
        overallStatus = ASSIGNED;
      } else if (hasInReviewTask) {
        overallStatus = IN_REVIEW;
      } else if (hasCompletedTask) {
        overallStatus = COMPLETED;
      }
      const currentTask = state.entities.tasks.byId[editTaskListRequest.taskListId];
      const taskListDueAt = tasks.reduce((furthest, task) => (task.dueAt > furthest.dueAt ? task : furthest), tasks[0]).dueAt;
      state.entities.tasks.byId[editTaskListRequest.taskListId] = create(TaskKindWithPrivateSchema, {
        kind: {
          case: 'taskListItem',
          value: create(TaskListItemSchema, {
            taskListId: editTaskListRequest.taskListId,
            title: editTaskListRequest.title,
            description: editTaskListRequest.description,
            owner: (currentTask.kind.value as TaskListItem).owner,
            statusId: overallStatus,
            publicId: (currentTask.kind.value as TaskListItem).publicId,
            isPrivate: true,
            createdAt: (currentTask.kind.value as TaskListItem).createdAt,
            lastModifiedAt: new Date().toISOString(),
            singleTasks: tasks,
            shouldSendEmail: editTaskListRequest.shouldSendEmail,
            attachmentCount: tasks.reduce((sum, t) => sum + t.attachmentCount, 0),
            assigneeCount: tasks.reduce((sum, t) => sum + t.assigneeCount, 0),
            reviewerCount: tasks.reduce((sum, t) => sum + t.reviewerCount, 0),
            relationCount: editTaskListRequest.taskRelations.length,
            commentCount: tasks.reduce((sum, t) => sum + t.commentCount, 0),
            completedTaskCount: tasks.filter((t) => t.statusId === COMPLETED).length,
            totalTaskCount: tasks.length,
            dueAt: taskListDueAt,
            someAssigneeIds: tasks.reduce((acc, t) => [...acc, ...t.assigneeIds], [] as string[]),
            someReviewerIds: tasks.reduce((acc, t) => [...acc, ...t.reviewerIds], [] as string[]),
            isPinned: (currentTask.kind.value as TaskListItem).isPinned
          })
        }
      });

      if (overallStatus === DRAFT) {
        state.entities.tasks.draftIds.push(editTaskListRequest.taskListId);
      } else if (overallStatus === ASSIGNED) {
        state.entities.tasks.assignedIds.push(editTaskListRequest.taskListId);
      } else if (overallStatus === IN_REVIEW) {
        state.entities.tasks.inReviewIds.push(editTaskListRequest.taskListId);
      }

      const today = DateTime.now().startOf('day');
      const dueDate = DateTime.fromISO(taskListDueAt).startOf('day');

      if (dueDate.hasSame(today, 'day')) {
        state.entities.tasks.dueTodayIds.push(editTaskListRequest.taskListId);
      } else if (today < dueDate && dueDate <= today.plus({ days: 7 })) {
        state.entities.tasks.dueThisWeekIds.push(editTaskListRequest.taskListId);
      } else if (today.plus({ days: 7 }) < dueDate && dueDate <= today.plus({ days: 14 })) {
        state.entities.tasks.dueNextWeekIds.push(editTaskListRequest.taskListId);
      } else if (today.plus({ days: 14 }) < dueDate) {
        state.entities.tasks.dueLaterIds.push(editTaskListRequest.taskListId);
      }
    },
    editTaskListFailed: (_state) => {},
    addTaskAttachmentUrls: (state, action: PayloadAction<string[]>) => {
      state.taskAttachmentUrls = action.payload;

      if (state.drawerSingleTaskItemId) {
        state.editSingleTaskRequest = {
          ...state.editSingleTaskRequest,
          attachmentUrls: [...state.editSingleTaskRequest.attachmentUrls, ...action.payload]
        };
      } else {
        state.singleTaskRequest = {
          ...state.singleTaskRequest,
          attachmentUrls: action.payload
        };
      }
    },
    removeTaskAttachmentUrls: (state, action: PayloadAction<string>) => {
      state.taskAttachmentUrls = state.taskAttachmentUrls.filter((url) => {
        return action.payload !== url;
      });
      state.singleTaskRequest.attachmentUrls = state.singleTaskRequest.attachmentUrls.filter((url) => url !== action.payload);
    },
    resetTaskAttachmentUrls: (state) => {
      state.taskAttachmentUrls = [];
      state.singleTaskRequest.attachmentUrls = [];
    },
    editTaskAttachmentUrls: (state, action: PayloadAction<string[]>) => {
      state.editSingleTaskRequest.attachmentUrls = action.payload;
    },
    removeEditTaskAttachmentUrls: (state, action: PayloadAction<string>) => {
      state.editSingleTaskRequest = {
        ...state.editSingleTaskRequest,
        attachmentUrls: state.editSingleTaskRequest.attachmentUrls.filter((url) => url !== action.payload)
      };
    },
    setNewCommentAttachmentUrls: (state, action: PayloadAction<string[]>) => {
      state.newCommentAttachmentUrls = action.payload;
    },
    removeNewCommentAttachmentUrls: (state, action: PayloadAction<string>) => {
      state.newCommentAttachmentUrls = state.newCommentAttachmentUrls.filter((url) => url !== action.payload);
    },
    resetNewCommentAttachmentUrls: (state) => {
      state.newCommentAttachmentUrls = [];
    },
    setCommentAttachmentUrls: (state, action: PayloadAction<string[]>) => {
      const comment = state.entities.comments.byId[state.editableComment!];

      if (comment) {
        state.entities.comments.byId[state.editableComment!] = {
          ...comment,
          attachmentUrls: [...comment.attachmentUrls, ...action.payload]
        };
      }
    },
    removeCommentAttachmentUrls: (state, action: PayloadAction<{ url: string; commentId: string }>) => {
      const { commentId, url } = action.payload;
      const comment = state.entities.comments.byId[commentId];

      if (comment) {
        state.entities.comments.byId[commentId] = {
          ...comment,
          attachmentUrls: comment.attachmentUrls ? comment.attachmentUrls.filter((attachmentUrl) => attachmentUrl !== url) : []
        };
      }

      state.singleTaskView.attachmentUrls = state.singleTaskView.attachmentUrls.filter((attachmentUrl) => attachmentUrl !== url);
    },
    setSingleTasksInTaskListDrawer: (state, action: PayloadAction<SimpleTask[]>) => {
      state.ui.singleTasksInTaskListDrawer = action.payload;
    },
    retrieveSingleTasksInTaskListForDrawerRequested: (_state, _action: PayloadAction<TaskListId>) => {},
    retrieveSingleTasksInTaskListForDrawerSucceeded: (state, action: PayloadAction<SimpleTask[]>) => {
      state.ui.singleTasksInTaskListDrawer = action.payload;
      state.ui.singleTasksForTaskList = [...state.ui.singleTasksForTaskList, ...action.payload];
    },
    retrieveSingleTasksInTaskListForDrawerFailed: (_state) => {},
    setIsLoadedTasksForTaskList: (state, action: PayloadAction<boolean>) => {
      state.ui.isLoadedTasksForTaskList = action.payload;
    },
    setReadOnlyTask: (state, action: PayloadAction<RetrieveSingleTaskResponse>) => {
      state.readOnlyTask = action.payload;
    },
    unsetReadOnlyTask: (state) => {
      state.readOnlyTask = undefined;
      state.entities.tasksDependsForTasks.byId = {};
      state.entities.tasksDependsForTasks.allIds = [];
      state.entities.tasksDependsOnTasks.byId = {};
      state.entities.tasksDependsOnTasks.allIds = [];
    },
    retrieveTaskFilesRequested: (state, action: PayloadAction<RetrieveFilesRequest>) => {
      const { pageNumber, pageSize, folderId } = action.payload;

      state.ui.retrieveTaskFilesStatus = ActionStatus.Pending;
      state.ui.taskFilesPageNumber = pageNumber;
      state.ui.taskFilesPageSize = pageSize;

      state.taskSelectedFolderId = folderId;
    },
    retrieveTaskFilesSucceeded: (state, action: PayloadAction<RetrieveFilesResponse>) => {
      const { files, totalCount } = action.payload;

      for (const file of files) {
        state.entities.taskFiles.byId[file.id] = file;
        state.entities.taskFiles.allIds = uniq(state.entities.taskFiles.allIds.concat(file.id));

        const fileSystemId = makeFileSystemId(state.taskSelectedFolderId, file.id);

        if (!state.entities.taskFileSystem.hasOwnProperty(fileSystemId)) {
          state.entities.taskFileSystem.byId[fileSystemId] = {
            parentFileId: state.taskSelectedFolderId,
            childFileId: file.id,
            type: file.type
          };
          state.entities.taskFileSystem.allIds.push(fileSystemId);
        }
      }

      state.ui.retrieveTaskFilesStatus = ActionStatus.Idle;
      state.ui.taskFilesTotalCount = totalCount;
    },
    retrieveTaskFilesFailed: (state) => {
      state.ui.retrieveTaskFilesStatus = ActionStatus.Failed;
    },
    sortTaskFilesBy: (state, action: PayloadAction<Sorting>) => {
      const { sortBy, sortDirection } = action.payload;

      state.ui.taskMyFilesSorting.sortBy = sortBy;
      state.ui.taskMyFilesSorting.sortDirection = sortDirection;
    },
    selectTaskFolder: (state, action: PayloadAction<string>) => {
      const currentFolderId = action.payload;

      state.taskSelectedFolderId = currentFolderId;

      const tempParentIds: string[] = [currentFolderId];
      const parentIds = getAllParentFileIds(state.entities.taskFileSystem.byId, currentFolderId, tempParentIds);

      state.expandedTaskFolderIds = uniq(state.expandedTaskFolderIds.concat(parentIds));
    },
    setEditableComment: (state, action: PayloadAction<string | null>) => {
      state.editableComment = action.payload;
    },
    changeTaskOwnerRequested: (_state, _action: PayloadAction<ChangeTaskOwnerRequest>) => {},
    changeTaskOwnerSucceeded: (state, action: PayloadAction<ChangeTaskOwnerRequest>) => {
      if (state.entities.tasks.byId.hasOwnProperty(action.payload.taskId)) {
        (state.entities.tasks.byId[action.payload.taskId].kind.value! as SingleTaskItem).ownerId = action.payload.newOwnerId;
        state.entities.tasks.byId[action.payload.taskId] = {
          ...state.entities.tasks.byId[action.payload.taskId],
          kind: {
            case: 'singleTaskItem',
            value: create(SingleTaskItemSchema, {
              ...state.entities.tasks.byId[action.payload.taskId].kind.value!,
              ownerId: action.payload.newOwnerId
            } as SingleTaskItem)
          }
        };
      }
      if (state.singleTaskView !== undefined) {
        state.singleTaskView = {
          ...state.singleTaskView,
          ownerId: action.payload.newOwnerId
        };
      }
    },
    changeTaskOwnerFailed: (_state) => {}
  }
});

export const selectIsLoadedTasksForTaskList = (state: RootState) => state.tasks.ui.isLoadedTasksForTaskList;

export const selectTasksViewMode = (state: RootState) => state.tasks.ui.viewMode;
export const selectTasksScopeMode = (state: RootState) => state.tasks.ui.scope;
export const selectTasksGroupBy = (state: RootState) => state.tasks.ui.groupBy;

export const selectTasksPaging = (state: RootState) => state.tasks.ui.paging;

export const selectTasksById = (state: RootState) => state.tasks.entities.tasks.byId;
export const selectTasksByTaskId = (state: RootState, taskId: string) => state.tasks.entities.tasks.byId[taskId];

export const selectDueTodayTasks = (state: RootState) => state.tasks.entities.tasks.dueTodayIds.map((id) => state.tasks.entities.tasks.byId[id]);

export const selectDueThisWeekTasks = (state: RootState) => state.tasks.entities.tasks.dueThisWeekIds.map((id) => state.tasks.entities.tasks.byId[id]);

export const selectDueNextWeekTasks = (state: RootState) => state.tasks.entities.tasks.dueNextWeekIds.map((id) => state.tasks.entities.tasks.byId[id]);

export const selectDueLaterTasks = (state: RootState) => state.tasks.entities.tasks.dueLaterIds.map((id) => state.tasks.entities.tasks.byId[id]);

export const selectTotalTasksByDueDateType = (state: RootState, dueDateType: DueDateType) =>
  state.tasks.ui.paging.dueDates.sections.find((d) => d.dueDateType === dueDateType)!.totalCount;

export const selectDraftTaskIds = (state: RootState) => state.tasks.entities.tasks.draftIds;

export const selectDraftTasks = (state: RootState) => state.tasks.entities.tasks.draftIds.map((id) => state.tasks.entities.tasks.byId[id]);
export const selectAssignedTasks = (state: RootState) => state.tasks.entities.tasks.assignedIds.map((id) => state.tasks.entities.tasks.byId[id]);
export const selectInReviewTasks = (state: RootState) => state.tasks.entities.tasks.inReviewIds.map((id) => state.tasks.entities.tasks.byId[id]);
export const selectCompletedTasks = (state: RootState) => state.tasks.entities.tasks.completedIds.map((id) => state.tasks.entities.tasks.byId[id]);

export const selectTaskPublicIdById = (state: RootState, taskId: string) => state.tasks.entities.tasks.byId[taskId].kind.value!.publicId;

export const selectTotalTasksByStatus = (state: RootState, statusId: string) => {
  const section = state.tasks.ui.paging.statuses.sections.find((s) => s.statusId === statusId);
  return section ? section.totalCount : 0;
};

export const selectTasksByTaskListId = (state: RootState, taskListId: string) =>
  chain(state.tasks.entities.tasks.singleTaskIds)
    .map((id) => state.tasks.entities.tasks.byId[id])
    .filter((st) => st.kind.case === 'singleTaskItem' && st.kind.value.taskListId === taskListId)
    .value();

export const selectSelectedSingleTask = (state: RootState) => {
  if (state.tasks.selectedSingleTaskId === undefined) return undefined;

  return state.tasks.entities.tasks.byId[state.tasks.selectedSingleTaskId].kind.value as SingleTaskItem;
};

export const selectTasksViewsById = (state: RootState) => state.tasks.entities.taskViews.byId;
export const selectTasksViewsSavedIds = (state: RootState) => state.tasks.entities.taskViews.savedIds;
export const selectTasksViewsUnsavedIds = (state: RootState) => state.tasks.entities.taskViews.unsavedIds;
export const selectTasksViewsHiddenIds = (state: RootState) => state.tasks.entities.taskViews.hiddenIds;

export const selectTaskViewBySingleTaskId = (state: RootState, singleTaskId: string) => {
  const allTaskViewIds = state.tasks.entities.taskViews.savedIds.concat(state.tasks.entities.taskViews.unsavedIds);
  return allTaskViewIds.find(
    (id) => state.tasks.entities.taskViews.byId[id].kind.case === 'single' && state.tasks.entities.taskViews.byId[id].kind.value.singleTaskId === singleTaskId
  );
};

export const selectActiveTasksViewId = (state: RootState) => state.tasks.activeTasksViewId;
export const selectActiveTasksView = (state: RootState) => state.tasks.entities.taskViews.byId[state.tasks.activeTasksViewId];
export const selectActiveTasksViewFilters = (state: RootState): TasksViewFilters | undefined => {
  const activeTasksViewId = state.tasks.activeTasksViewId;
  const taskView = state.tasks.entities.taskViews.byId[activeTasksViewId];

  if (taskView && taskView.kind.case === 'filters') {
    return taskView.kind.value;
  }

  return undefined;
};

export const selectIsActiveViewSingleTaskView = (state: RootState) => state.tasks.entities.taskViews.byId[state.tasks.activeTasksViewId].kind.case === 'single';

export const selectIsActiveTasksViewUnsaved = (state: RootState) =>
  !state.tasks.entities.taskViews.savedIds.includes(state.tasks.activeTasksViewId) &&
  state.tasks.entities.taskViews.unsavedIds.includes(state.tasks.activeTasksViewId);

export const selectRetrieveTasksRequest = createSelector(
  [selectTasksViewsById, selectActiveTasksViewId, selectTasksGroupBy, selectTasksScopeMode, selectTasksPaging, (_, employeeId) => employeeId],
  (byId, activeTasksViewId, groupBy, taskScope, paging, employeeId) => {
    if (byId[activeTasksViewId].kind.case === 'single') return undefined;

    const currentViewFilters = byId[activeTasksViewId].kind.value as TasksViewFilters;

    if (groupBy === TasksGroupBy.DueDate) {
      const index = paging.dueDates.sections.findIndex((s) => s.dueDateType === paging.dueDates.activeSection);
      const safeIndex = index === -1 ? 0 : index;

      return create(RetrieveTasksRequestSchema, {
        employeeId,
        taskScope,
        pageNumber: paging.dueDates.sections[safeIndex].pageNumber,
        pageSize: paging.dueDates.sections[safeIndex].pageSize,
        groupBy: {
          case: 'dueDates',
          value: create(GroupByDueDateRequestSchema, {
            dates: [DueDateType.DUE_TODAY, DueDateType.DUE_THIS_WEEK, DueDateType.DUE_NEXT_WEEK, DueDateType.DUE_LATER]
          })
        },
        statusIds: currentViewFilters.statusIds,
        ownerIds: currentViewFilters.ownerIds,
        assigneeIds: currentViewFilters.assigneeIds,
        reviewerIds: currentViewFilters.reviewerIds
      } as RetrieveTasksRequest);
    }

    const index = paging.statuses.sections.findIndex((s) => s.statusId === paging.statuses.activeSection);
    const safeIndex = index === -1 ? 0 : index;

    return create(RetrieveTasksRequestSchema, {
      employeeId,
      taskScope,
      pageNumber: paging.statuses.sections[safeIndex].pageNumber,
      pageSize: paging.statuses.sections[safeIndex].pageSize,
      groupBy: {
        case: 'statuses',
        value: create(GroupByStatusRequestSchema, {
          statusIds: currentViewFilters.statusIds.length === 0 ? [DRAFT, ASSIGNED, IN_REVIEW, COMPLETED] : currentViewFilters.statusIds
        })
      },
      assigneeIds: currentViewFilters.assigneeIds,
      reviewerIds: currentViewFilters.reviewerIds,
      ownerIds: currentViewFilters.ownerIds,
      statusIds: currentViewFilters.statusIds,
      searchQuery: currentViewFilters.searchQuery,
      dueDateRange: currentViewFilters.dueDateRange,
      type: currentViewFilters.type
    });
  }
);

export const selectVisibleTasksViewsIds = createSelector(
  [selectTasksViewsSavedIds, selectTasksViewsUnsavedIds, selectTasksViewsHiddenIds],
  (savedIds, unsavedIds, hiddenIds) =>
    chain(uniq(savedIds.concat(unsavedIds))) // Ensure unique IDs
      .filter((id) => id !== TASKS_DEFAULT_VIEW_ID && !hiddenIds.includes(id))
      .value()
);
export const selectVisibleTasksViews = createSelector([selectVisibleTasksViewsIds, selectTasksViewsById], (visibleViewIds, byId) =>
  visibleViewIds.map((id) => byId[id])
);

export const selectAllTasksViews = createSelector(
  [selectVisibleTasksViewsIds, selectTasksViewsHiddenIds, selectTasksViewsById],
  (visibleIds, hiddenIds, byId) =>
    chain(uniq(visibleIds.concat(hiddenIds))) // Ensure unique IDs
      .map((id) => byId[id])
      .sortBy('lastModifiedAt')
      .value()
);

export const selectIsViewChanged = (state: RootState, viewId: string): boolean => {
  const savedIds = selectTasksViewsSavedIds(state);
  const unsavedIds = selectTasksViewsUnsavedIds(state);
  return savedIds.includes(viewId) && unsavedIds.includes(viewId);
};

export const selectTasksViewsAsOptions = createSelector([selectAllTasksViews], (views) => views.map((v) => ({ id: v.tasksViewId, name: v.name }) as Option));

// BEGIN Assignees -------------------
export const selectInitialAssigneeIds = (state: RootState) => state.tasks.entities.assignees.initialIds;
export const selectSelectedAssigneeIds = (state: RootState) => state.tasks.entities.assignees.selectedIds;

export const selectChosenAssigneeIds = createSelector([selectInitialAssigneeIds, selectSelectedAssigneeIds], (initialIds, selectedIds) =>
  chain(initialIds.concat(selectedIds)).uniq().value()
);

// TODO: audience group -> assignees
const selectAssigneesAutocompleteItems = (state: RootState) => state.tasks.assigneesAutocompleteItems;

// TODO: move to shared groupOptionsByType under types
const selectAssigneesAutocompleteItemsGroupedByType = createSelector([selectAssigneesAutocompleteItems], (items) => groupOptionsByType(flattenData(items)));

const selectAssigneesAutocompleteEmployeeItems = (state: RootState) => state.tasks.assigneesAutocompleteItems.employeeResults?.employees || [];

const selectAssigneesAutocompleteEmployeeItemsGroupedAlphabetically = createSelector([selectAssigneesAutocompleteEmployeeItems], (items) =>
  groupEmployeesAlphabetically(items)
);

export const selectTaskAssigneesAutocompleteOptions = createSelector(
  [selectAssigneesAutocompleteItemsGroupedByType, selectAssigneesAutocompleteEmployeeItemsGroupedAlphabetically, (_, searchText) => searchText],
  (groupedOptionsByType, groupedEmployeesAlphabetically, searchText) =>
    searchText.length >= 2
      ? Object.keys(groupedOptionsByType).flatMap((key) => groupedOptionsByType[key])
      : Object.keys(groupedEmployeesAlphabetically).flatMap((key) => groupedEmployeesAlphabetically[key])
);

export const selectAssigneesAutocompleteItemsStatus = (state: RootState) => state.tasks.ui.assigneesAutocompleteItemsStatus;

// END Assignees ----------

// BEGIN Reviewers -------------------
export const selectInitialReviewerIds = (state: RootState) => state.tasks.entities.reviewers.initialIds;
export const selectSelectedReviewerIds = (state: RootState) => state.tasks.entities.reviewers.selectedIds;

export const selectChosenReviewerIds = createSelector([selectInitialReviewerIds, selectSelectedReviewerIds], (initialIds, selectedIds) =>
  chain(initialIds.concat(selectedIds)).uniq().value()
);

export const selectReviewersAutocompleteItemsStatus = (state: RootState) => state.tasks.ui.reviewersAutocompleteItemsStatus;

// END Reviewers ----------

export const selectIsAssetsModalOpenedOnCreateTask = (state: RootState) => state.tasks.ui.createTaskDrawer.isAssetsModalOpened;
export const selectIsAssetsModalOpenedOnEditTask = (state: RootState) => state.tasks.ui.editTaskDrawer.isAssetsModalOpened;
export const selectIsAssetsModalOpenedOnTaskComment = (state: RootState) => state.tasks.ui.newComment.isAssetsModalOpened;

export const selectSingleTask = (state: RootState) => state.tasks.singleTask;
export const selectCreateSingleTaskStatus = (state: RootState) => state.tasks.ui.createSingleTaskStatus;

export const selectSingleTaskRequest = (state: RootState) => state.tasks.singleTaskRequest;
export const selectIsSingleTaskFormValid = (state: RootState) => state.tasks.ui.isSingleTaskFormValid;

export const selectSingleTaskView = (state: RootState) => state.tasks.singleTaskView;
export const selectSingleTaskViewStatus = (state: RootState) => state.tasks.ui.singleTaskViewStatus;

const selectSingleTaskViewReactionsRaw = (state: RootState) => state.tasks.singleTaskView.reactions;

export const selectSingleTaskViewReactions = createSelector([selectSingleTaskViewReactionsRaw], (reactions) =>
  reactions.reduce((acc: Record<string, Reaction[]>, reaction) => {
    const { emoji } = reaction;
    acc[emoji] = acc[emoji] ? [...acc[emoji], reaction] : [reaction];
    return acc;
  }, {})
);

export const selectCommentOnTaskCommentStatus = (state: RootState) => state.tasks.ui.commentOnTaskStatus;
export const selectSingleTaskCommentById = (state: RootState, commentId: string) => state.tasks.entities.comments.byId[commentId];
export const selectSingleTaskCommentsIds = (state: RootState) => state.tasks.entities.comments.allIds;

export const selectComments = (state: RootState) => state.tasks.entities.comments;

export const selectSingleTaskComments = (state: RootState) => state.tasks.singleTaskView.comments;

export const selectSingleTaskCommentReactionsGroupedByEmoji = (state: RootState, commentId: string) => state.tasks.entities.comments.byId[commentId].reactions;

export const selectTaskListTaskRequest = (state: RootState) => state.tasks.taskListRequest;
export const selectEditTaskListTaskRequest = (state: RootState) => state.tasks.editTaskListRequest;

export const selectTasksForDependencies = (state: RootState) =>
  uniq(state.tasks.entities.tasksForDependencies.allIds).map((id) => state.tasks.entities.tasksForDependencies.byId[id]);

export const selectTasksForDependenciesStatus = (state: RootState) => state.tasks.ui.tasksForDependenciesStatus;

export const selectTasksDependsOnTasksById = (state: RootState) => state.tasks.entities.tasksDependsOnTasks.byId;
export const selectTasksDependsOnTasksIds = (state: RootState) => state.tasks.entities.tasksDependsOnTasks.allIds;

export const selectTasksDependsOnTasks = createSelector([selectTasksDependsOnTasksById, selectTasksDependsOnTasksIds], (byId, chosenIds) =>
  uniq(chosenIds).map((id) => byId[id])
);

export const selectTasksDependsForTasksById = (state: RootState) => state.tasks.entities.tasksDependsForTasks.byId;
export const selectTasksDependsForTasksIds = (state: RootState) => state.tasks.entities.tasksDependsForTasks.allIds;

export const selectTasksDependsForTasks = createSelector([selectTasksDependsForTasksById, selectTasksDependsForTasksIds], (byId, ids) =>
  uniq(ids).map((id) => byId[id])
);

export const selectTasksStatus = (state: RootState) => state.tasks.ui.tasksStatus;

export const selectDrawerSingleTask = (state: RootState) => {
  if (state.tasks.drawerSingleTaskItemId === undefined) return undefined;

  return state.tasks.entities.tasks.byId[state.tasks.drawerSingleTaskItemId].kind.value as SingleTaskItem;
};

export const selectDrawerSingleTaskAttachmentUrls = (state: RootState) => {
  if (state.tasks.drawerSingleTaskItemId === undefined) return undefined;
  const singleTask = state.tasks.entities.tasks.byId[state.tasks.drawerSingleTaskItemId].kind.value as SingleTaskItem;
  return singleTask.attachmentUrls;
};

export const selectDrawerSingleTaskAssigneesIds = (state: RootState) => {
  const singleTaskItem = state.tasks.entities.tasks.byId[state.tasks.drawerSingleTaskItemId].kind.value! as SingleTaskItem;

  return singleTaskItem.assigneeIds;
};

export const selectEditSingleTaskRequest = (state: RootState) => state.tasks.editSingleTaskRequest;
export const selectEditSingleTaskRequestAttachmentUrls = (state: RootState) => state.tasks.editSingleTaskRequest.attachmentUrls;

export const selectSingleTasksInTaskListStatus = (state: RootState) => state.tasks.ui.singleTasksInTaskListStatus;

export const selectSingleTasksForTaskList = (state: RootState) => state.tasks.ui.singleTasksForTaskList;

export const selectDrawerTaskListItemId = (state: RootState) => state.tasks.drawerTaskListItemId;
export const selectDrawerTaskListItem = (state: RootState) => state.tasks.drawerTaskListItem;
export const selectTaskAttachmentUrls = (state: RootState) => state.tasks.taskAttachmentUrls;

export const selectSingleTasksInTaskListDrawer = (state: RootState) => state.tasks.ui.singleTasksInTaskListDrawer;

export const selectNewCommentUrls = (state: RootState) => state.tasks.newCommentAttachmentUrls;

export const selectTaskListTasks = (state: RootState, id: string) => (state.tasks.entities.tasks.byId[id].kind.value as TaskListItem).singleTasks;

export const selectReadOnlyTask = (state: RootState) => state.tasks.readOnlyTask;
export const selectTasksViewById = (state: RootState, tasksViewId: string) => state.tasks.entities.taskViews.byId[tasksViewId].name;

export const selectTaskFilesById = (state: RootState) => state.tasks.entities.taskFiles.byId;
export const selectTaskAllFilesIds = (state: RootState) => state.tasks.entities.taskFiles.allIds;
export const selectTaskFilesTotalCount = (state: RootState) => state.tasks.ui.taskFilesTotalCount;
export const selectTaskSelectedFolderId = (state: RootState) => state.tasks.taskSelectedFolderId;
export const selectRetrieveTaskFilesStatus = (state: RootState) => state.tasks.ui.retrieveTaskFilesStatus;
export const selectTaskFilesPageNumber = (state: RootState) => state.tasks.ui.taskFilesPageNumber;
export const selectTaskFilesPageSize = (state: RootState) => state.tasks.ui.taskFilesPageSize;
export const selectTaskExpandedFolderIds = (state: RootState) => state.tasks.expandedTaskFolderIds;

export const selectTaskFilesPageCount = createSelector([selectTaskFilesTotalCount, selectTaskFilesPageSize], (totalCount, pageSize) =>
  Math.ceil(totalCount / pageSize)
);

export const selectMemoizedTaskFilePagesStatusText = createSelector(
  [selectTaskFilesPageNumber, selectTaskFilesPageSize, selectTaskFilesPageCount],
  (pageNumber, pageSize, totalCount) => {
    const start = (pageNumber - 1) * pageSize + 1;
    const end = Math.min(pageNumber * pageSize, totalCount);

    return `${start} - ${end} of ${totalCount}`;
  }
);

const selectTaskFileSystem = (state: RootState) => state.tasks.entities.taskFileSystem;
const selectSortBy = (state: RootState) => state.tasks.ui.taskMyFilesSorting.sortBy;
const selectSortDirection = (state: RootState) => state.tasks.ui.taskMyFilesSorting.sortDirection;

export const selectTasksMyFoldersAsTreeItems = createSelector([selectTaskFileSystem, selectTaskFilesById], (taskFileSystem, taskFilesById) =>
  buildTreeItems(taskFileSystem, taskFilesById, MY_FILES_FOLDER_ID, 1)
);

export const selectTasksMyFilesSorting = (state: RootState) => state.tasks.ui.taskMyFilesSorting;
export const selectTasksMyFilesSortBySortFile = (state: RootState) => toSortFile(state.assets.ui.myFilesSorting.sortBy);

export const selectTaskFilesOnly = createSelector(
  [selectTaskFilesById, selectTaskFileSystem, selectTaskSelectedFolderId, selectSortBy, selectSortDirection],
  (byId, fileSystem, selectedFolderId, sortBy, sortDirection) => {
    return chain(fileSystem.byId)
      .entries()
      .filter(([_key, value]) => value.parentFileId === selectedFolderId && byId[value.childFileId].type !== FileType.FOLDER)
      .map(([_key, value]) => value.childFileId)
      .map((id) => byId[id])
      .orderBy([sortBy], [sortDirection])
      .value();
  }
);

export const selectEditableComment = (state: RootState) => state.tasks.editableComment;

export const selectSingleTaskViewActionButtonStatus = (state: RootState) => state.tasks.ui.singleTaskViewActionButtonStatus;
export const {
  addAssignee,
  resetAssignees,
  addAllAssignees,
  addReviewer,
  addAllReviewers,
  archiveTaskFailed,
  archiveTaskRequested,
  archiveTaskSucceeded,
  changeTasksGroupBy,
  changeTasksScope,
  changeTasksView,
  changeTasksViewMode,
  closeAssetsModalOnCreateTask,
  commentOnTaskFailed,
  commentOnTaskRequested,
  commentOnTaskSucceeded,
  createSingleTaskFailed,
  createSingleTaskRequested,
  createSingleTaskSucceeded,
  deleteSingleTaskFailed,
  deleteSingleTaskRequested,
  deleteSingleTaskSucceeded,
  deleteTasksViewFailed,
  deleteTasksViewRequested,
  deleteTasksViewSucceeded,
  editSingleTaskRequestForm,
  editTaskFailed,
  editTaskRequested,
  editTaskSucceeded,
  loadTasksFailed,
  loadTasksRequested,
  loadTasksSucceeded,
  nextPageByDueDate,
  nextPageByStatus,
  openAssetsModalOnCreateTask,
  reactToTaskCommentFailed,
  reactToTaskCommentRequested,
  reactToTaskCommentSucceeded,
  reactToTaskFailed,
  reactToTaskRequested,
  reactToTaskSucceeded,
  removeAssignee,
  resetReviewers,
  removeReviewer,
  renameTasksViewFailed,
  renameTasksViewRequested,
  renameTasksViewSucceeded,
  retrieveAssigneeAutocompleteItemsFailed,
  retrieveAssigneeAutocompleteItemsRequested,
  retrieveAssigneeAutocompleteItemsSucceeded,
  retrieveEmployeesAutocompleteItemsFailed,
  retrieveEmployeesAutocompleteItemsRequested,
  retrieveEmployeesAutocompleteItemsSucceeded,
  retrieveReviewerAutocompleteItemsFailed,
  retrieveReviewerAutocompleteItemsRequested,
  retrieveReviewerAutocompleteItemsSucceeded,
  retrieveSingleTaskFailed,
  retrieveSingleTaskRequested,
  retrieveSingleTaskSucceeded,
  retrieveTasksFailed,
  retrieveTasksForDependenciesFailed,
  retrieveTasksForDependenciesRequested,
  retrieveTasksForDependenciesSucceeded,
  retrieveTasksRequested,
  retrieveTasksSucceeded,
  setDrawerTaskListItemId,
  selectDependsForTaskDependencies,
  removeDependsForTaskDependencies,
  selectDependsOnTaskDependencies,
  setDrawerSingleTaskItemId,
  toggleVisibilityTasksView,
  updateSingleTaskRequestForm,
  updateTaskListRequestForm,
  updateTasksViewFailed,
  updateTasksViewRequested,
  updateTasksViewSucceeded,
  validateSingleTaskRequestForm,
  withdrawTaskCommentReactionFailed,
  withdrawTaskCommentReactionRequested,
  withdrawTaskCommentReactionSucceeded,
  withdrawTaskReactionFailed,
  withdrawTaskReactionRequested,
  withdrawTaskReactionSucceeded,
  retrieveSingleTasksInTaskListRequested,
  retrieveSingleTasksInTaskListSucceeded,
  retrieveSingleTasksInTaskListFailed,
  markAsCompleteFailed,
  markAsCompleteRequested,
  markAsCompleteSucceeded,
  sendToReviewerFailed,
  sendToReviewerRequested,
  sendToReviewerSucceeded,
  returnToAssigneeFailed,
  returnToAssigneeRequested,
  returnToAssigneeSucceeded,
  approveTaskRequested,
  approveTaskSucceeded,
  approveTaskFailed,
  returnToReviewerFailed,
  returnToReviewerRequested,
  returnToReviewerSucceeded,
  createTempTaskView,
  updateTempTaskView,
  createTaskViewRequested,
  createTaskViewSucceeded,
  createTaskViewFailed,
  hideTaskView,
  retrieveSingleTasksForTaskListRequested,
  retrieveSingleTasksForTaskListSucceeded,
  retrieveSingleTasksForTaskListFailed,
  createTaskListRequested,
  createTaskListSucceeded,
  createTaskListFailed,
  resetTaskListRequestForm,
  retrieveSingleTaskRelationsRequested,
  retrieveSingleTaskRelationsSucceeded,
  retrieveSingleTaskRelationsFailed,
  deleteTaskListRequested,
  deleteTaskListSucceeded,
  deleteTaskListFailed,
  retrieveTaskListRelationsRequested,
  retrieveTaskListRelationsSucceeded,
  retrieveTaskListRelationsFailed,
  editTaskListRequested,
  editTaskListSucceeded,
  editTaskListFailed,
  addTaskAttachmentUrls,
  removeTaskAttachmentUrls,
  resetTaskAttachmentUrls,
  removeEditTaskAttachmentUrls,
  editTaskListRequestForm,
  editTaskListRequestFormTaskIds,
  openAssetsModalOnTaskComment,
  closeAssetsModalOnTaskComment,
  setNewCommentAttachmentUrls,
  removeNewCommentAttachmentUrls,
  resetNewCommentAttachmentUrls,
  editTaskCommentRequested,
  editTaskCommentSucceeded,
  editTaskCommentFailed,
  deleteTaskCommentRequested,
  deleteTaskCommentSucceeded,
  deleteTaskCommentFailed,
  setCommentAttachmentUrls,
  removeCommentAttachmentUrls,
  closeAssetsModalOnEditTask,
  openAssetsModalOnEditTask,
  resetEditTaskListRequestForm,
  resetSingleTaskRequestForm,
  setSingleTasksInTaskListDrawer,
  retrieveSingleTasksInTaskListForDrawerFailed,
  retrieveSingleTasksInTaskListForDrawerRequested,
  retrieveSingleTasksInTaskListForDrawerSucceeded,
  setIsLoadedTasksForTaskList,
  setReadOnlyTask,
  unsetReadOnlyTask,
  retrieveTaskFilesRequested,
  retrieveTaskFilesSucceeded,
  retrieveTaskFilesFailed,
  selectTaskFolder,
  sortTaskFilesBy,
  setEditableComment,
  editTaskAttachmentUrls,
  openSingleTaskFailed,
  openSingleTaskRequested,
  openSingleTaskSucceeded,
  changeTaskOwnerFailed,
  changeTaskOwnerRequested,
  changeTaskOwnerSucceeded
} = tasksSlice.actions;
export default tasksSlice.reducer;
