import { Avatar, AvatarProps, styled } from '@mui/material';

interface TaskAvatarProps {
  position?: number;
}

export const TaskAvatar = styled(
  ({ position, ...props }: TaskAvatarProps & React.ComponentProps<typeof Avatar>) => (
    <Avatar
      {...props}
      imgProps={{
        loading: 'lazy',
        width: 36,
        height: 36
      }}
    />
  ),
  {
    shouldForwardProp: (prop) => prop !== 'position'
  }
)(({ theme, position }) => ({
  width: 36,
  height: 36,
  border: `1px solid ${theme.palette.common.black}`,
  borderRadius: 4,
  position: 'relative',
  zIndex: position ?? 1
}));
