import React, { useId, useState } from 'react';
import { Box, Typography, Collapse, List, ListItem, ClickAwayListener } from '@mui/material';
import {
  ChevronDownIcon,
  DuplicateIcon,
  BinIcon,
  PrimaryDarkButton,
  StyledFormSubmitButton,
  StyledBlurryModal,
  RowCenterStack,
  CancelEditIcon
} from '@/shared';
import { DeleteSiteByIdRequest, DuplicateSiteRequest, SiteListItem } from '@thrivea/organization-client';
import { useAppDispatch } from '@/app/hooks';
import {
  ActionButton,
  ActionCopy,
  ActionsList,
  AdminSettingsSiteLink,
  deleteSiteByIdRequested,
  duplicateSiteRequested,
  MenuActionButton,
  ModalContent,
  ModalContentStack
} from '@features/admin-settings';
import { useTranslation } from 'react-i18next';

interface SiteActionsProps {
  site: SiteListItem;
  menuName: string;
  isSiteMenuOpen: boolean;
  handleSiteMenuToggle: (menuName: string) => void;
  closeMenu: (menuName: string) => void;
}

export const SiteActions: React.FC<SiteActionsProps> = ({ site, menuName, isSiteMenuOpen, handleSiteMenuToggle, closeMenu }) => {
  const id = useId();
  const { t } = useTranslation(['settings_sites', 'common']);
  const dispatch = useAppDispatch();
  const [isDuplicateModalOpen, setIsDuplicateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleDuplicateSite = () => {
    dispatch(
      duplicateSiteRequested(
        new DuplicateSiteRequest({
          siteId: site.id
        })
      )
    );
    setIsDuplicateModalOpen(false);
  };

  const handleDeleteSite = () => {
    dispatch(
      deleteSiteByIdRequested(
        new DeleteSiteByIdRequest({
          siteId: site.id
        })
      )
    );
    setIsDeleteModalOpen(false);
  };

  const handleDuplicateModalOpen = () => {
    setIsDuplicateModalOpen(true);
  };

  const handleDuplicateModalClose = () => {
    setIsDuplicateModalOpen(false);
  };

  const handleDeleteModalOpen = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={() => closeMenu(menuName)}>
      <Box>
        <MenuActionButton
          aria-controls={site.id ? id + site.name : undefined}
          aria-haspopup="true"
          onClick={() => handleSiteMenuToggle(menuName)}
          variant="contained"
          endIcon={<ChevronDownIcon />}
          isMenuOpen={isSiteMenuOpen}
        >
          {t('actions', { ns: 'common' })}
        </MenuActionButton>
        <Collapse in={isSiteMenuOpen}>
          <ActionsList component={List} gap={1}>
            <ListItem disableGutters disablePadding>
              <AdminSettingsSiteLink siteId={site.id} />
            </ListItem>
            <ListItem disableGutters disablePadding>
              <ActionButton variant="contained" endIcon={<DuplicateIcon width={18} height={18} color="#000" />} onClick={handleDuplicateModalOpen}>
                {t('duplicate_site', { ns: 'settings_sites' })}
              </ActionButton>
            </ListItem>
            <ListItem disableGutters disablePadding>
              <ActionButton variant="contained" endIcon={<BinIcon width={18} height={18} customColor="#000" />} onClick={handleDeleteModalOpen}>
                {t('delete_site', { ns: 'settings_sites' })}
              </ActionButton>
            </ListItem>
          </ActionsList>
        </Collapse>
        <StyledBlurryModal component="div" open={isDuplicateModalOpen} onClose={handleDuplicateModalClose}>
          <ModalContent>
            <ModalContentStack>
              <Typography variant="subtitle1">{t('duplicate_site', { ns: 'settings_sites' })}</Typography>
              <ActionCopy variant="body1">
                {t('duplicate_question', { ns: 'settings_sites' })} <Box component="b">{site.name}</Box>
              </ActionCopy>
              <RowCenterStack gap={2}>
                <PrimaryDarkButton variant="contained" startIcon={<CancelEditIcon />} onClick={handleDuplicateModalClose}>
                  {t('cancel', { ns: 'common' })}
                </PrimaryDarkButton>
                <StyledFormSubmitButton variant="contained" onClick={handleDuplicateSite}>
                  {t('duplicate', { ns: 'common' })}
                </StyledFormSubmitButton>
              </RowCenterStack>
            </ModalContentStack>
          </ModalContent>
        </StyledBlurryModal>
        <StyledBlurryModal component="div" open={isDeleteModalOpen} onClose={handleDeleteModalClose}>
          <ModalContent>
            <ModalContentStack>
              <Typography variant="subtitle1">{t('delete_site', { ns: 'settings_sites' })}</Typography>
              <ActionCopy variant="body1">
                {t('delete_question', { ns: 'settings_sites' })} <Box component="b">{site.name}</Box>
              </ActionCopy>
              <RowCenterStack gap={2}>
                <PrimaryDarkButton variant="contained" startIcon={<CancelEditIcon />} onClick={handleDeleteModalClose}>
                  {t('cancel', { ns: 'common' })}
                </PrimaryDarkButton>
                <StyledFormSubmitButton variant="contained" onClick={handleDeleteSite}>
                  {t('delete', { ns: 'common' })}
                </StyledFormSubmitButton>
              </RowCenterStack>
            </ModalContentStack>
          </ModalContent>
        </StyledBlurryModal>
      </Box>
    </ClickAwayListener>
  );
};
