import { FormControlLabel, FormControlLabelProps, styled } from '@mui/material';

export const PaymentMethodLabel = styled(FormControlLabel)<FormControlLabelProps>(() => ({
  '& .MuiRadio-root': {
    padding: 0,
    paddingRight: '4px'
  },
  '& .MuiTypography-root': {
    fontSize: 14,
    fontWeight: 400
  }
}));
