import React from 'react';

interface DocTypeFileIconProps {
  width?: number;
  height?: number;
  color?: string;
}

export const DocTypeFileIcon: React.FC<DocTypeFileIconProps> = ({ width = 24, height = 27, color = '#000000' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 76 76">
      <path
        d="M1.5 8C1.5 4.41015 4.41015 1.5 8 1.5H68C71.5898 1.5 74.5 4.41015 74.5 8V89C74.5 92.5899 71.5899 95.5 68 95.5H8C4.41015 95.5 1.5 92.5899 1.5 89V8Z"
        stroke={color}
        strokeWidth="3"
      />
      <rect x="10.5" y="11.5" width="12" height="12" rx="2.5" stroke={color} strokeWidth="3" />
      <path d="M22.5 34H11" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M67 34H31" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M22.5 44.2856H11" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M67 44H31" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M22.5 54.5713H11" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M67 55H31" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M22.5 64.8574H11" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M67 65H31" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M22.5 75.1431H11" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M67 75H31" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M22.5 85.4287H11" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M67 85H31" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
