export enum TaskViewMode {
  List,
  Board,
  Timeline,
  Calendar,
  SingleTask
}

export enum TaskStatus {
  Draft,
  Assigned,
  InReview,
  Completed
}

export interface Task {
  status: TaskStatus;
  id: string;
  title: string;
  description: string;
  dueDate: string;
  reviewer: string;
  taskList?: Task[];
}
