import { Collapse, IconButton, Stack } from '@mui/material';
import { ListTaskDescription, ListTaskTitle, TaskChip, TaskListBar, TasksListTable } from '..';
import { RowCenterStack } from '@shared/components';
import { FilterIcon } from '@/shared';
import { useState } from 'react';

interface TasksListCategoryProps {
  title: string;
  description: string;
  tasksCount: number;
}

export const TasksListCategory: React.FC<TasksListCategoryProps> = ({ title, description, tasksCount }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleCollapseButtonClick = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <Stack>
      <TaskListBar>
        <RowCenterStack sx={{ justifyContent: 'space-between' }}>
          <Stack gap={'4px'}>
            <ListTaskTitle sx={{ color: '#251D38', fontSize: 20, fontWeight: '700', wordWrap: 'break-word' }}>{title}</ListTaskTitle>
            <ListTaskDescription sx={{ color: '#251D38', fontSize: 14, fontWeight: '700', wordWrap: 'break-word' }}>{description}</ListTaskDescription>
          </Stack>
          <RowCenterStack gap={1}>
            <TaskChip>{tasksCount}</TaskChip>
            <IconButton sx={{ padding: 0 }} onClick={handleCollapseButtonClick}>
              <FilterIcon />
            </IconButton>
          </RowCenterStack>
        </RowCenterStack>
      </TaskListBar>
      <Collapse
        in={isCollapsed}
        sx={{
          mt: isCollapsed ? 2 : 0
        }}
      >
        <TasksListTable />
      </Collapse>
    </Stack>
  );
};
