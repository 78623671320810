import { Box, BoxProps, styled } from '@mui/material';

export const TaskListBar = styled(Box)<BoxProps>(({ theme }) => ({
  padding: '7px 16px',
  borderRadius: 10,
  border: '1px solid',
  borderColor: theme.palette.customTheme.borderMaster,
  backgroundColor: theme.palette.customTheme.solidBgr,
  cursor: 'pointer'
}));
