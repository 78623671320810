import React, { useId, useState } from 'react';
import { Button, Box, Typography, Stack, Collapse, List, ListItem, ClickAwayListener } from '@mui/material';
import {
  ChevronDownIcon,
  BinIcon,
  StyledModalContent,
  PrimaryDarkButton,
  StyledFormSubmitButton,
  StyledBlurryModal,
  RowCenterStack,
  CancelEditIcon,
  StyledListItemButton,
  SecondaryDarkButton
} from '@/shared';
import { DeleteWorkingPatternByIdRequest, RetrieveWorkingPatternByIdRequest, WorkingPatternListItem } from '@thrivea/organization-client';
import { useAppDispatch } from '@/app/hooks';
import {
  deleteWorkingPatternByIdRequested,
  retrieveWorkingPatternByIdRequested,
  MenuActionButton,
  ActionsList,
  ActionButton,
  ActionCopy,
  ModalContent,
  ModalContentStack
} from '@features/admin-settings';
import { useTranslation } from 'react-i18next';
import { AdminSettingsWorkingPatternEdit } from './AdminSettingsWorkingPatternEdit';

interface WorkingPatternActionsProps {
  workingPatternListItem: WorkingPatternListItem;
  menuName: string;
  isWorkingPatternMenuOpen: boolean;
  handleWorkingPatternMenuToggle: (menuName: string) => void;
  closeMenu: (menuName: string) => void;
}

export const WorkingPatternActions: React.FC<WorkingPatternActionsProps> = ({
  workingPatternListItem,
  menuName,
  isWorkingPatternMenuOpen,
  handleWorkingPatternMenuToggle,
  closeMenu
}) => {
  const id = useId();
  const { t } = useTranslation(['settings_working_pattern', 'common', 'settings_sites']);
  const dispatch = useAppDispatch();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [isEditWorkingPatternModalOpen, setIsEditWorkingPatternModalOpen] = useState(false);

  const handleEditWorkingPatternModalOpen = (event: React.MouseEvent<HTMLElement>, wpId: string) => {
    event.stopPropagation();
    setIsEditWorkingPatternModalOpen(true);
    dispatch(
      retrieveWorkingPatternByIdRequested(
        new RetrieveWorkingPatternByIdRequest({
          workingPatternId: wpId
        })
      )
    );
  };

  const handleEditWorkingPatternModalClose = () => {
    setIsEditWorkingPatternModalOpen(false);
  };

  const handleDeleteSite = () => {
    dispatch(
      deleteWorkingPatternByIdRequested(
        new DeleteWorkingPatternByIdRequest({
          workingPatternId: workingPatternListItem.id
        })
      )
    );
    setIsDeleteModalOpen(false);
  };

  const handleDeleteModalOpen = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={() => closeMenu(menuName)}>
      <Box>
        <MenuActionButton
          aria-controls={workingPatternListItem.id ? id + workingPatternListItem.name : undefined}
          aria-haspopup="true"
          onClick={() => handleWorkingPatternMenuToggle(menuName)}
          variant="contained"
          endIcon={<ChevronDownIcon />}
          isMenuOpen={isWorkingPatternMenuOpen}
        >
          {t('actions', { ns: 'common' })}
        </MenuActionButton>
        <Collapse in={isWorkingPatternMenuOpen}>
          <ActionsList component={List} gap={1}>
            <ListItem disableGutters disablePadding>
              <StyledListItemButton divider onClick={(e) => handleEditWorkingPatternModalOpen(e, workingPatternListItem.id)}>
                {t('view_and_edit', { ns: 'settings_sites' })}
              </StyledListItemButton>
            </ListItem>
            <ListItem disableGutters disablePadding>
              <ActionButton variant="contained" endIcon={<BinIcon width={18} height={18} />} onClick={handleDeleteModalOpen}>
                {t('delete', { ns: 'common' })}
              </ActionButton>
            </ListItem>
          </ActionsList>
        </Collapse>
        <StyledBlurryModal component="div" open={isDeleteModalOpen} onClose={handleDeleteModalClose}>
          <ModalContent>
            <ModalContentStack>
              <Typography variant="subtitle1">{t('delete_working_pattern', { ns: 'settings_working_patterns' })}</Typography>
              <ActionCopy variant="body1">
                {t('delete_question', { ns: 'settings_sites' })} <Box component="b">{workingPatternListItem.name}</Box>
              </ActionCopy>
              <RowCenterStack gap={2}>
                <PrimaryDarkButton variant="contained" startIcon={<CancelEditIcon />} onClick={handleDeleteModalClose}>
                  {t('cancel', { ns: 'common' })}
                </PrimaryDarkButton>
                <SecondaryDarkButton startIcon={<BinIcon customColor="#000" width={22} height={22} />} variant="contained" onClick={handleDeleteSite}>
                  {t('delete', { ns: 'common' })}
                </SecondaryDarkButton>
              </RowCenterStack>
            </ModalContentStack>
          </ModalContent>
        </StyledBlurryModal>
        {isEditWorkingPatternModalOpen && (
          <AdminSettingsWorkingPatternEdit
            isOpen={isEditWorkingPatternModalOpen}
            handleCloseModal={handleEditWorkingPatternModalClose}
            workingPatternListItem={workingPatternListItem}
          />
        )}
      </Box>
    </ClickAwayListener>
  );
};
