import React from 'react';

interface DocsIconAttributes {
  size?: number; // Optional size prop to control the icon size
  color?: string; // Optional color prop to control the icon color
}

export const DocsIcon: React.FC<DocsIconAttributes> = ({ size = 20, color = '#717680' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" fill="none">
      <path
        d="M11.6667 1.89118V5.33329C11.6667 5.8 11.6667 6.03336 11.7575 6.21162C11.8374 6.36842 11.9649 6.4959 12.1217 6.5758C12.2999 6.66663 12.5333 6.66663 13 6.66663H16.4421M16.6667 8.32348V14.3333C16.6667 15.7334 16.6667 16.4335 16.3942 16.9683C16.1545 17.4387 15.7721 17.8211 15.3017 18.0608C14.7669 18.3333 14.0668 18.3333 12.6667 18.3333H7.33334C5.93321 18.3333 5.23315 18.3333 4.69837 18.0608C4.22796 17.8211 3.84551 17.4387 3.60583 16.9683C3.33334 16.4335 3.33334 15.7334 3.33334 14.3333V5.66663C3.33334 4.26649 3.33334 3.56643 3.60583 3.03165C3.84551 2.56124 4.22796 2.17879 4.69837 1.93911C5.23315 1.66663 5.93321 1.66663 7.33334 1.66663H10.0098C10.6213 1.66663 10.927 1.66663 11.2148 1.7357C11.4698 1.79694 11.7137 1.89795 11.9374 2.03503C12.1897 2.18963 12.4059 2.40582 12.8382 2.8382L15.4951 5.49505C15.9275 5.92743 16.1437 6.14362 16.2983 6.39591C16.4353 6.61959 16.5364 6.86346 16.5976 7.11855C16.6667 7.40627 16.6667 7.712 16.6667 8.32348Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
