import { PayloadAction } from '@reduxjs/toolkit';
import {
  RetrieveAnniversariesResponse,
  RetrieveBirthdaysResponse,
  RetrieveNewHiresResponse,
  RetrieveUpcomingEventsRequest,
  RetrieveUpcomingEventsResponse
} from '@thrivea/organization-client';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
  retrieveBirthdaysSucceeded,
  retrieveBirthdaysFailed,
  retrieveBirthdaysRequested,
  retrieveUpcomingEventsSucceeded,
  retrieveUpcomingEventsFailed,
  retrieveUpcomingEventsRequested,
  retrieveNewHiresSucceeded,
  retrieveNewHiresFailed,
  retrieveNewHiresRequested,
  retrieveAnniversariesSucceeded,
  retrieveAnniversariesFailed,
  retrieveAnniversariesRequested
} from '@features/homepage';
import { retrieveEmployeesBirthdays, retrieveUpcomingEvents, retrieveNewHires, retrieveAnniversaries } from '@api/widgets.api';
import * as Sentry from '@sentry/react';
import { EmptySchema } from '@bufbuild/protobuf/wkt';
import { create } from '@bufbuild/protobuf';

function* retrieveBirthdayItemsGenerator() {
  try {
    const response: RetrieveBirthdaysResponse = yield call(retrieveEmployeesBirthdays, create(EmptySchema, {}));
    yield put(retrieveBirthdaysSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveBirthdaysFailed());
  }
}

function* retrieveUpcomingEventsGenerator(action: PayloadAction<RetrieveUpcomingEventsRequest>) {
  try {
    const response: RetrieveUpcomingEventsResponse = yield call(retrieveUpcomingEvents, action.payload);
    yield put(retrieveUpcomingEventsSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveUpcomingEventsFailed());
  }
}

function* retrieveNewHiresGenerator() {
  try {
    const response: RetrieveNewHiresResponse = yield call(retrieveNewHires, create(EmptySchema, {}));
    yield put(retrieveNewHiresSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveNewHiresFailed());
  }
}

function* retrieveAnniversariesGenerator() {
  try {
    const response: RetrieveAnniversariesResponse = yield call(retrieveAnniversaries, create(EmptySchema, {}));
    yield put(retrieveAnniversariesSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveAnniversariesFailed());
  }
}

function* retrieveBirthdayItemsWatcher() {
  yield takeLatest(retrieveBirthdaysRequested.type, retrieveBirthdayItemsGenerator);
}

function* retrieveUpcomingEventsWatcher() {
  yield takeLatest(retrieveUpcomingEventsRequested.type, retrieveUpcomingEventsGenerator);
}

function* retrieveNewHiresWatcher() {
  yield takeLatest(retrieveNewHiresRequested.type, retrieveNewHiresGenerator);
}

function* retrieveAnniversariesWatcher() {
  yield takeLatest(retrieveAnniversariesRequested.type, retrieveAnniversariesGenerator);
}

export function* widgetsSagas() {
  yield all([fork(retrieveBirthdayItemsWatcher), fork(retrieveUpcomingEventsWatcher), fork(retrieveNewHiresWatcher), fork(retrieveAnniversariesWatcher)]);
}
