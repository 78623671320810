import { createClient } from '@connectrpc/connect';
import { createGrpcWebTransport } from '@connectrpc/connect-web';
import {
  GroupItem,
  GroupService,
  RetrieveGroupByIdRequest,
  RetrieveGroupByIdResponse,
  RetrieveGroupItemsRequest,
  RetrieveGroupItemsResponse,
  RetrieveGroupsRequest,
  RetrieveGroupsResponse,
  UpdateGroupRequest
} from '@thrivea/auth-client';
import { msalInstance } from 'src/main';
import { tokenRequest } from '@utils/authConfig';

const groupClient = createClient(
  GroupService,
  createGrpcWebTransport({
    baseUrl: import.meta.env.VITE_APP_API_URL,
    useBinaryFormat: true
  })
);

export const retrieveGroups = async (request: RetrieveGroupsRequest): Promise<RetrieveGroupsResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await groupClient.retrieveGroups(request, { headers: new Headers({ Authorization: `Bearer ${account.accessToken}` }) });
};

export const retrieveGroupItems = async (request: RetrieveGroupItemsRequest): Promise<RetrieveGroupItemsResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await groupClient.retrieveGroupItems(request, { headers: new Headers({ Authorization: `Bearer ${account.accessToken}` }) });
};

export const retrieveGroupById = async (request: RetrieveGroupByIdRequest): Promise<RetrieveGroupByIdResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await groupClient.retrieveGroupById(request, { headers: new Headers({ Authorization: `Bearer ${account.accessToken}` }) });
};

export const updateGroup = async (request: UpdateGroupRequest): Promise<GroupItem> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await groupClient.updateGroup(request, { headers: new Headers({ Authorization: `Bearer ${account.accessToken}` }) });
};
