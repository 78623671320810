import { Chip, chipClasses, ChipProps, styled } from '@mui/material';

interface BillingStatusChipStyleProps {
  backgroundColor: string;
  textColor: string;
}

export enum BillingStatus {
  Draft,
  Active,
  Void,
  Paid
}

interface BillingStatusChipProps extends ChipProps {
  status: BillingStatus;
}

export const BillingStatusChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'isAssigned' && prop !== 'isInReview' && prop !== 'isCompleted'
})<BillingStatusChipProps>(({ status, theme }) => {
  const row: BillingStatusChipStyleProps[] = [
    { backgroundColor: '#BEBABA', textColor: theme.palette.primary.main },
    { backgroundColor: '#87D3F8', textColor: theme.palette.primary.main },
    { backgroundColor: '#DC3545', textColor: theme.palette.common.white },
    { backgroundColor: '#2FBCC4', textColor: theme.palette.common.white }
  ];

  let idx = BillingStatus.Draft;
  if (status === BillingStatus.Active) idx = BillingStatus.Active;
  if (status === BillingStatus.Void) idx = BillingStatus.Void;
  if (status === BillingStatus.Paid) idx = BillingStatus.Paid;

  return {
    backgroundColor: row[idx].backgroundColor,
    color: row[idx].textColor,
    maxWidth: 50,
    height: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '3px 7px',
    borderRadius: 8,
    [`& .${chipClasses.label}`]: {
      fontSize: 12,
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: 'normal',
      padding: 0
    }
  };
});
