import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@app/store';
import {
  DeleteTasksViewRequest,
  DueDateType,
  EmployeeListItem,
  RenameTasksViewRequest,
  TaskScope,
  TaskKindWithPrivate,
  TasksView,
  TasksViewFilters,
  TaskType,
  UpdateTasksViewRequest,
  TaskKind,
  SingleTaskItem,
  RetrieveEmployeesByQueryRequest,
  RetrieveEmployeesByQueryResponse,
  RetrieveTasksRequest,
  GroupByDueDateRequest,
  GroupByStatusRequest,
  RetrieveTasksResponse,
  CreateSingleTaskRequest,
  TaskListItem,
  CreateTaskListRequest,
  RetrieveSingleTaskResponse,
  SingleTaskId,
  Reaction,
  ReactToTaskRequest,
  WithdrawTaskReactionRequest,
  CommentOnTaskRequest,
  Comment,
  ReactToTaskCommentRequest,
  WithdrawTaskCommentReactionRequest,
  RetrieveTasksForDependenciesResponse,
  SimpleTask,
  EditTaskRequest,
  TaskListId,
  RetrieveSingleTasksInTaskListResponse,
  Reviewer
} from '@thrivea/organization-client';
import { chain, groupBy, uniq } from 'lodash';
import {
  ASSIGNED,
  COMPLETED,
  DRAFT,
  IN_REVIEW,
  LoadTasksInitially,
  PagingByDueDates,
  PagingByStatuses,
  TasksGroupBy,
  TaskViewMode,
  validateSingleTaskForm
} from '@features/tasks';
import { ActionStatus, Option } from 'src/shared';
import { flattenData, groupEmployeesAlphabetically, groupOptionsByType } from '@/utils';

import { TASKS_DEFAULT_VIEW_ID } from './tasks.model';
import { DateTime } from 'luxon';
import { CommentModel } from '../homepage';

const mapComment = (comment: Comment): CommentModel => {
  const reactionDict = groupBy(comment.reactions, (r) => r.emoji);

  return {
    id: comment.commentId,
    authorId: comment.authorId,
    text: comment.text,
    commentTime: DateTime.fromISO(comment.commentAt!),
    editedTime: comment.editedAt ? DateTime.fromISO(comment.editedAt) : undefined,
    reactions: reactionDict
  } as CommentModel;
};

export interface TasksState {
  entities: {
    tasks: {
      // we mean by this Tasks, Private Tasks and Task Lists
      byId: { [key: string]: TaskKindWithPrivate };
      allIds: string[];
      deletedIds: string[];
      singleTaskIds: string[];
      taskListIds: string[];
      privateTaskIds: string[];
      dueTodayIds: string[];
      dueThisWeekIds: string[];
      dueNextWeekIds: string[];
      dueLaterIds: string[];
      draftIds: string[];
      assignedIds: string[];
      inReviewIds: string[];
      completedIds: string[];
    };
    taskViews: {
      byId: { [key: string]: TasksView };
      savedIds: string[];
      unsavedIds: string[];
      hiddenIds: string[];
    };
    employeesAutocompleteItems: {
      byId: { [key: string]: EmployeeListItem };
      allIds: string[];
    };
    assignees: {
      byId: { [key: string]: EmployeeListItem };
      initialIds: string[];
      selectedIds: string[];
    };
    reviewers: {
      byId: { [key: string]: EmployeeListItem };
      initialIds: string[];
      selectedIds: string[];
    };
    comments: {
      byId: { [key: string]: CommentModel };
      allIds: string[];
    };
    tasksForDependencies: {
      byId: { [key: string]: SimpleTask };
      allIds: string[];
    };
    tasksDependsOnTasks: {
      byId: { [key: string]: SimpleTask };
      allIds: string[];
    };
    tasksDependsForTasks: {
      byId: { [key: string]: SimpleTask };
      allIds: string[];
    };
    singleTasksInTaskList: {
      byId: { [key: string]: SingleTaskItem };
      allIds: string[];
    };
  };
  ui: {
    viewMode: TaskViewMode; // List or Board
    scope: TaskScope; // All Tasks or My Tasks
    groupBy: TasksGroupBy; // Due Date or Status
    tasksStatus: ActionStatus;
    taskViewsStatus: ActionStatus;
    assigneesAutocompleteItemsStatus: ActionStatus;
    reviewersAutocompleteItemsStatus: ActionStatus;
    createTaskDrawer: {
      isAssetsModalOpened: boolean;
    };
    newComment: {
      isAssetsModalOpened: boolean;
    };
    paging: {
      dueDates: {
        activeSection?: DueDateType;
        sections: PagingByDueDates[];
      };
      statuses: {
        activeSection?: string;
        sections: PagingByStatuses[];
      };
    };
    createSingleTaskStatus: ActionStatus;
    employeesAutocompleteItemsStatus: ActionStatus;
    isSingleTaskFormValid: boolean;
    singleTaskDeleteStatus: ActionStatus;
    singleTaskViewStatus: ActionStatus;
    commentOnTaskStatus: ActionStatus;
    tasksForDependenciesStatus: ActionStatus;
    singleTasksInTaskListStatus: ActionStatus;
  };
  activeTasksViewId: string;
  isInitiallyLoaded: boolean;
  selectedSingleTaskId?: string;
  assigneesAutocompleteItems: RetrieveEmployeesByQueryResponse;
  reviewersAutocompleteItems: RetrieveEmployeesByQueryResponse;
  singleTaskRequest: CreateSingleTaskRequest;
  taskListRequest: CreateTaskListRequest;
  singleTask: SingleTaskItem;
  singleTaskView: RetrieveSingleTaskResponse;
  drawerSingleTaskItemId: string;
  editSingleTaskRequest: EditTaskRequest;
}

const initialState: TasksState = {
  entities: {
    tasks: {
      byId: {},
      allIds: [],
      singleTaskIds: [],
      taskListIds: [],
      privateTaskIds: [],
      dueTodayIds: [],
      dueThisWeekIds: [],
      dueNextWeekIds: [],
      dueLaterIds: [],
      draftIds: [],
      assignedIds: [],
      inReviewIds: [],
      completedIds: [],
      deletedIds: []
    },
    taskViews: {
      byId: {
        [TASKS_DEFAULT_VIEW_ID]: new TasksView({
          tasksViewId: TASKS_DEFAULT_VIEW_ID,
          name: undefined,
          kind: {
            case: 'filters',
            value: new TasksViewFilters({
              type: TaskType.TASK_TYPE_UNSPECIFIED,
              statusIds: undefined,
              ownerIds: undefined,
              assigneeIds: undefined,
              reviewerIds: undefined,
              dueDateRange: undefined,
              searchQuery: undefined
            })
          }
        })
      },
      savedIds: [TASKS_DEFAULT_VIEW_ID],
      unsavedIds: [],
      hiddenIds: []
    },
    employeesAutocompleteItems: {
      byId: {},
      allIds: []
    },
    assignees: {
      byId: {},
      initialIds: [],
      selectedIds: []
    },
    reviewers: {
      byId: {},
      initialIds: [],
      selectedIds: []
    },
    comments: {
      byId: {},
      allIds: []
    },
    tasksForDependencies: {
      byId: {},
      allIds: []
    },
    tasksDependsOnTasks: {
      byId: {},
      allIds: []
    },
    tasksDependsForTasks: {
      byId: {},
      allIds: []
    },
    singleTasksInTaskList: {
      byId: {},
      allIds: []
    }
  },
  ui: {
    viewMode: TaskViewMode.List,
    scope: TaskScope.MY_TASKS,
    groupBy: TasksGroupBy.DueDate,
    tasksStatus: ActionStatus.Idle,
    taskViewsStatus: ActionStatus.Idle,
    assigneesAutocompleteItemsStatus: ActionStatus.Idle,
    reviewersAutocompleteItemsStatus: ActionStatus.Idle,
    createTaskDrawer: {
      isAssetsModalOpened: false
    },
    newComment: {
      isAssetsModalOpened: false
    },
    paging: {
      dueDates: {
        activeSection: undefined,
        sections: [
          {
            dueDateType: DueDateType.DUE_TODAY,
            pageNumber: 1,
            pageSize: 10,
            totalCount: 0
          },
          {
            dueDateType: DueDateType.DUE_THIS_WEEK,
            pageNumber: 1,
            pageSize: 10,
            totalCount: 0
          },
          {
            dueDateType: DueDateType.DUE_NEXT_WEEK,
            pageNumber: 1,
            pageSize: 10,
            totalCount: 0
          },
          {
            dueDateType: DueDateType.DUE_LATER,
            pageNumber: 1,
            pageSize: 10,
            totalCount: 0
          }
        ]
      },
      statuses: {
        activeSection: undefined,
        sections: [
          {
            statusId: DRAFT,
            pageNumber: 1,
            pageSize: 10,
            totalCount: 0
          },
          {
            statusId: ASSIGNED,
            pageNumber: 1,
            pageSize: 10,
            totalCount: 0
          },
          {
            statusId: IN_REVIEW,
            pageNumber: 1,
            pageSize: 10,
            totalCount: 0
          },
          {
            statusId: COMPLETED,
            pageNumber: 1,
            pageSize: 10,
            totalCount: 0
          }
        ]
      }
    },
    createSingleTaskStatus: ActionStatus.Idle,
    employeesAutocompleteItemsStatus: ActionStatus.Idle,
    isSingleTaskFormValid: false,
    singleTaskDeleteStatus: ActionStatus.Idle,
    singleTaskViewStatus: ActionStatus.Idle,
    commentOnTaskStatus: ActionStatus.Idle,
    tasksForDependenciesStatus: ActionStatus.Idle,
    singleTasksInTaskListStatus: ActionStatus.Idle
  },
  activeTasksViewId: TASKS_DEFAULT_VIEW_ID,
  isInitiallyLoaded: false,
  selectedSingleTaskId: undefined,
  assigneesAutocompleteItems: {} as RetrieveEmployeesByQueryResponse,
  reviewersAutocompleteItems: {} as RetrieveEmployeesByQueryResponse,
  singleTask: {} as SingleTaskItem,
  singleTaskRequest: {} as CreateSingleTaskRequest,
  taskListRequest: {} as CreateTaskListRequest,
  singleTaskView: {} as RetrieveSingleTaskResponse,
  editSingleTaskRequest: {} as EditTaskRequest,
  drawerSingleTaskItemId: ''
};

export const tasksSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    loadTasksRequested: (state) => {
      state.ui.tasksStatus = ActionStatus.Pending;
      state.ui.taskViewsStatus = ActionStatus.Pending;
    },
    loadTasksSucceeded: (state, action: PayloadAction<LoadTasksInitially>) => {
      const { tasksResponse, tasksViewsResponse } = action.payload;

      switch (tasksResponse.kind.case) {
        case 'pagedTasksByDueDates':
          for (const pagedTasksByDueDateCategory of tasksResponse.kind.value.pagedTasksByDueDates) {
            const dueListIds: string[] = [];

            for (const task of pagedTasksByDueDateCategory.tasks) {
              switch (task.kind.case) {
                case 'singleTaskItem':
                  state.entities.tasks.byId[task.kind.value.singleTaskId] = task;
                  state.entities.tasks.singleTaskIds.push(task.kind.value.singleTaskId);
                  dueListIds.push(task.kind.value.singleTaskId);

                  break;
                case 'taskListItem':
                  state.entities.tasks.byId[task.kind.value.taskListId] = task;
                  state.entities.tasks.taskListIds.push(task.kind.value.taskListId);
                  dueListIds.push(task.kind.value.taskListId);

                  break;

                case 'privateTaskItem':
                  state.entities.tasks.byId[task.kind.value.id] = task;
                  state.entities.tasks.privateTaskIds.push(task.kind.value.id);
                  dueListIds.push(task.kind.value.id);

                  break;
              }
            }

            // After all tasks for particular due date type were saved, add task ids to that due date array
            switch (pagedTasksByDueDateCategory.dueDate) {
              case DueDateType.DUE_TODAY:
                state.entities.tasks.dueTodayIds = dueListIds;
                break;
              case DueDateType.DUE_THIS_WEEK:
                state.entities.tasks.dueThisWeekIds = dueListIds;
                break;
              case DueDateType.DUE_NEXT_WEEK:
                state.entities.tasks.dueNextWeekIds = dueListIds;
                break;
              case DueDateType.DUE_LATER:
                state.entities.tasks.dueLaterIds = dueListIds;
                break;
            }

            const index = state.ui.paging.dueDates.sections.findIndex((dd) => dd.dueDateType === pagedTasksByDueDateCategory.dueDate);
            state.ui.paging.dueDates[index] = { ...state.ui.paging.dueDates[index], totalCount: pagedTasksByDueDateCategory.totalCount };
          }

          break;

        case 'pagedTasksByStatuses':
          for (const pagedTasksByStatus of tasksResponse.kind.value.pagedTasksByStatuses) {
            const statusIds: string[] = [];

            for (const task of pagedTasksByStatus.tasks) {
              switch (task.kind.case) {
                case 'singleTaskItem':
                  state.entities.tasks.byId[task.kind.value.singleTaskId] = task;
                  state.entities.tasks.singleTaskIds.push(task.kind.value.singleTaskId);
                  statusIds.push(task.kind.value.singleTaskId);

                  break;
                case 'taskListItem':
                  state.entities.tasks.byId[task.kind.value.taskListId] = task;
                  state.entities.tasks.taskListIds.push(task.kind.value.taskListId);
                  statusIds.push(task.kind.value.taskListId);

                  break;

                case 'privateTaskItem':
                  state.entities.tasks.byId[task.kind.value.id] = task;
                  state.entities.tasks.privateTaskIds.push(task.kind.value.id);
                  statusIds.push(task.kind.value.id);

                  break;
              }
            }

            // After all tasks for particular statusId were saved, add task ids to that status array
            switch (pagedTasksByStatus.statusId) {
              case DRAFT:
                state.entities.tasks.draftIds = statusIds;
                break;
              case ASSIGNED:
                state.entities.tasks.assignedIds = statusIds;
                break;
              case IN_REVIEW:
                state.entities.tasks.inReviewIds = statusIds;
                break;
              case COMPLETED:
                state.entities.tasks.completedIds = statusIds;
                break;
            }

            const index = state.ui.paging.statuses.sections.findIndex((st) => st.statusId === pagedTasksByStatus.statusId);
            state.ui.paging.statuses[index] = { ...state.ui.paging.statuses[index], totalCount: pagedTasksByStatus.totalCount };
          }

          break;
      }

      for (const tasksView of tasksViewsResponse.tasksViews) {
        state.entities.taskViews.byId[tasksView.tasksViewId] = tasksView;
        state.entities.taskViews.savedIds.push(tasksView.tasksViewId);
      }

      state.isInitiallyLoaded = true;
      state.ui.tasksStatus = ActionStatus.Idle;
      state.ui.taskViewsStatus = ActionStatus.Idle;
    },
    loadTasksFailed: (state) => {
      state.ui.tasksStatus = ActionStatus.Failed;
      state.ui.taskViewsStatus = ActionStatus.Failed;
    },
    retrieveTasksSucceeded: (state, action: PayloadAction<RetrieveTasksResponse>) => {
      const tasksResponse = action.payload;

      switch (tasksResponse.kind.case) {
        case 'pagedTasksByDueDates':
          for (const pagedTasksByDueDateCategory of tasksResponse.kind.value.pagedTasksByDueDates) {
            const dueListIds: string[] = [];

            for (const task of pagedTasksByDueDateCategory.tasks) {
              switch (task.kind.case) {
                case 'singleTaskItem':
                  state.entities.tasks.byId[task.kind.value.singleTaskId] = task;
                  state.entities.tasks.singleTaskIds.push(task.kind.value.singleTaskId);
                  dueListIds.push(task.kind.value.singleTaskId);

                  break;
                case 'taskListItem':
                  state.entities.tasks.byId[task.kind.value.taskListId] = task;
                  state.entities.tasks.taskListIds.push(task.kind.value.taskListId);
                  dueListIds.push(task.kind.value.taskListId);

                  break;

                case 'privateTaskItem':
                  state.entities.tasks.byId[task.kind.value.id] = task;
                  state.entities.tasks.privateTaskIds.push(task.kind.value.id);
                  dueListIds.push(task.kind.value.id);

                  break;
              }
            }

            // After all tasks for particular due date type were saved, add task ids to that due date array
            switch (pagedTasksByDueDateCategory.dueDate) {
              case DueDateType.DUE_TODAY:
                state.entities.tasks.dueTodayIds = dueListIds;
                break;
              case DueDateType.DUE_THIS_WEEK:
                state.entities.tasks.dueThisWeekIds = dueListIds;
                break;
              case DueDateType.DUE_NEXT_WEEK:
                state.entities.tasks.dueNextWeekIds = dueListIds;
                break;
              case DueDateType.DUE_LATER:
                state.entities.tasks.dueLaterIds = dueListIds;
                break;
            }

            const index = state.ui.paging.dueDates.sections.findIndex((dd) => dd.dueDateType === pagedTasksByDueDateCategory.dueDate);

            if (index !== -1) {
              state.ui.paging.dueDates[index] = {
                ...state.ui.paging.dueDates[index],
                totalCount: pagedTasksByDueDateCategory.totalCount
              };
            }
            // state.ui.paging.dueDates[index] = { ...state.ui.paging.dueDates[index], totalCount: pagedTasksByDueDateCategory.totalCount };
          }

          break;

        case 'pagedTasksByStatuses':
          for (const pagedTasksByStatus of tasksResponse.kind.value.pagedTasksByStatuses) {
            const statusIds: string[] = [];

            for (const task of pagedTasksByStatus.tasks) {
              switch (task.kind.case) {
                case 'singleTaskItem':
                  state.entities.tasks.byId[task.kind.value.singleTaskId] = task;
                  state.entities.tasks.singleTaskIds.push(task.kind.value.singleTaskId);
                  statusIds.push(task.kind.value.singleTaskId);

                  break;
                case 'taskListItem':
                  state.entities.tasks.byId[task.kind.value.taskListId] = task;
                  state.entities.tasks.taskListIds.push(task.kind.value.taskListId);
                  statusIds.push(task.kind.value.taskListId);

                  break;

                case 'privateTaskItem':
                  state.entities.tasks.byId[task.kind.value.id] = task;
                  state.entities.tasks.privateTaskIds.push(task.kind.value.id);
                  statusIds.push(task.kind.value.id);

                  break;
              }
            }

            // After all tasks for particular statusId were saved, add task ids to that status array
            switch (pagedTasksByStatus.statusId) {
              case DRAFT:
                state.entities.tasks.draftIds = statusIds;
                break;
              case ASSIGNED:
                state.entities.tasks.assignedIds = statusIds;
                break;
              case IN_REVIEW:
                state.entities.tasks.inReviewIds = statusIds;
                break;
              case COMPLETED:
                state.entities.tasks.completedIds = statusIds;
                break;
            }

            const index = state.ui.paging.statuses.sections.findIndex((st) => st.statusId === pagedTasksByStatus.statusId);
            if (index !== -1) {
              state.ui.paging.statuses.sections[index] = {
                ...state.ui.paging.statuses.sections[index],
                totalCount: pagedTasksByStatus.totalCount
              };
            } else {
              // Handle the case where the statusId is not found
              console.warn(`Status ID ${pagedTasksByStatus.statusId} not found in sections`);
            }

            // state.ui.paging.statuses[index] = { ...state.ui.paging.statuses[index], totalCount: pagedTasksByStatus.totalCount };
          }

          break;
      }

      state.ui.tasksStatus = ActionStatus.Idle;
    },
    retrieveTasksFailed: (state) => {
      state.ui.tasksStatus = ActionStatus.Failed;
    },
    changeTasksViewMode: (state, action: PayloadAction<TaskViewMode>) => {
      state.ui.viewMode = action.payload;
    },
    changeTasksScope: (state, action: PayloadAction<TaskScope>) => {
      state.ui.scope = action.payload;
    },
    changeTasksGroupBy: (state, action: PayloadAction<TasksGroupBy>) => {
      state.ui.groupBy = action.payload;
      state.ui.tasksStatus = ActionStatus.Pending;
    },
    nextPageByDueDate: (state, action: PayloadAction<DueDateType>) => {
      const index = state.ui.paging.dueDates.sections.findIndex((d) => d.dueDateType === action.payload);
      state.ui.paging.dueDates[index] = {
        ...state.ui.paging.dueDates[index],
        pageNumber: state.ui.paging.dueDates[index].pageNumber + 1
      };
      state.ui.paging.dueDates.activeSection = action.payload;
    },
    nextPageByStatus: (state, action: PayloadAction<string>) => {
      const index = state.ui.paging.statuses.sections.findIndex((s) => s.statusId === action.payload);
      state.ui.paging.statuses[index] = {
        ...state.ui.paging.statuses[index],
        pageNumber: state.ui.paging.statuses[index].pageNumber + 1
      };
      state.ui.paging.statuses.activeSection = action.payload;
    },
    openSingleTask: (state, action: PayloadAction<string>) => {
      state.selectedSingleTaskId = action.payload;
      state.ui.viewMode = TaskViewMode.SingleTask;
    },
    changeTasksView: (state, action: PayloadAction<string>) => {
      // If user selected the same Tasks View, then deselect it.
      if (state.activeTasksViewId === action.payload) {
        state.activeTasksViewId = TASKS_DEFAULT_VIEW_ID;
        return;
      }

      // If current view is Single Task, then switching to Tasks view defaults to List view
      const currentView = state.entities.taskViews.byId[state.activeTasksViewId];
      const nextView = state.entities.taskViews.byId[action.payload];

      if (currentView.kind.case === 'single' && nextView.kind.case === 'filters') {
        state.ui.viewMode = TaskViewMode.List;
      }

      state.activeTasksViewId = action.payload;
    },
    addAssignees: (state, action: PayloadAction<EmployeeListItem[]>) => {
      const addedEmployees = action.payload;

      for (const employee of addedEmployees) {
        state.entities.assignees.byId[employee.employeeId] = employee;
        state.entities.assignees.selectedIds.push(employee.employeeId);
      }
    },

    removeAssignee: (state, action: PayloadAction<string>) => {
      const employeeId = action.payload;

      state.entities.assignees.selectedIds = state.entities.assignees.selectedIds.filter((id) => id !== employeeId);
    },

    addReviewers: (state, action: PayloadAction<EmployeeListItem[]>) => {
      const addedEmployees = action.payload;

      for (const employee of addedEmployees) {
        state.entities.reviewers.byId[employee.employeeId] = employee;
        state.entities.reviewers.selectedIds.push(employee.employeeId);
      }
    },
    removeReviewer: (state, action: PayloadAction<string>) => {
      const employeeId = action.payload;

      state.entities.reviewers.selectedIds = state.entities.reviewers.selectedIds.filter((id) => id !== employeeId);
    },
    toggleVisibilityTasksView: (state, action: PayloadAction<{ viewId: string; hide: boolean }>) => {
      const { viewId, hide } = action.payload;

      if (hide) state.entities.taskViews.hiddenIds.push(viewId);
      else state.entities.taskViews.hiddenIds = state.entities.taskViews.hiddenIds.filter((id) => id !== viewId);

      state.activeTasksViewId = TASKS_DEFAULT_VIEW_ID;
    },
    renameTasksViewRequested: (state, action: PayloadAction<RenameTasksViewRequest>) => {},
    renameTasksViewSucceeded: (state, action: PayloadAction<RenameTasksViewRequest>) => {},
    renameTasksViewFailed: (state, action: PayloadAction<RenameTasksViewRequest>) => {},
    updateTasksViewRequested: (state, action: PayloadAction<UpdateTasksViewRequest>) => {},
    updateTasksViewSucceeded: (state, action: PayloadAction<UpdateTasksViewRequest>) => {},
    updateTasksViewFailed: (state, action: PayloadAction<UpdateTasksViewRequest>) => {},
    deleteTasksViewRequested: (state, action: PayloadAction<DeleteTasksViewRequest>) => {},
    deleteTasksViewSucceeded: (state, action: PayloadAction<DeleteTasksViewRequest>) => {},
    deleteTasksViewFailed: (state, action: PayloadAction<DeleteTasksViewRequest>) => {},
    // TODO: 6 next
    retrieveAssigneeAutocompleteItemsRequested: (state, action: PayloadAction<RetrieveEmployeesByQueryRequest>) => {
      state.ui.assigneesAutocompleteItemsStatus = ActionStatus.Pending;
    },
    retrieveAssigneeAutocompleteItemsSucceeded: (state, action: PayloadAction<RetrieveEmployeesByQueryResponse>) => {
      state.assigneesAutocompleteItems = action.payload;
      state.ui.assigneesAutocompleteItemsStatus = ActionStatus.Idle;
    },
    retrieveAssigneeAutocompleteItemsFailed: (state) => {
      state.ui.assigneesAutocompleteItemsStatus = ActionStatus.Idle;
    },
    retrieveReviewerAutocompleteItemsRequested: (state, action: PayloadAction<RetrieveEmployeesByQueryRequest>) => {
      state.ui.reviewersAutocompleteItemsStatus = ActionStatus.Pending;
    },
    retrieveReviewerAutocompleteItemsSucceeded: (state, action: PayloadAction<RetrieveEmployeesByQueryResponse>) => {
      state.reviewersAutocompleteItems = action.payload;
      state.ui.reviewersAutocompleteItemsStatus = ActionStatus.Idle;
    },
    retrieveReviewerAutocompleteItemsFailed: (state) => {
      state.ui.reviewersAutocompleteItemsStatus = ActionStatus.Failed;
    },
    openAssetsModalOnCreateTask: (state) => {
      state.ui.createTaskDrawer.isAssetsModalOpened = true;
    },
    closeAssetsModalOnCreateTask: (state) => {
      state.ui.createTaskDrawer.isAssetsModalOpened = false;
    },
    updateSingleTaskRequestForm: (state, action: PayloadAction<Partial<CreateSingleTaskRequest>>) => {
      state.singleTaskRequest = {
        ...state.singleTaskRequest,
        ...action.payload
      };
    },
    editSingleTaskRequestForm: (state, action: PayloadAction<Partial<EditTaskRequest>>) => {
      const task = state.entities.tasks.byId[action.payload.taskId!].kind.value as SingleTaskItem;

      state.editSingleTaskRequest = new EditTaskRequest({
        taskId: task.singleTaskId,
        title: action.payload.title ?? task.title,
        description: action.payload.description ?? task.description,
        dueAt: action.payload.dueAt ?? task.dueAt,
        attachmentUrls: action.payload.attachmentUrls ?? task.attachmentUrls,
        isPrivate: action.payload.isPrivate ?? task.isPrivate,
        shouldSendEmail: action.payload.shouldSendEmail ?? task.shouldSendEmail,
        assigneeIds: action.payload.assigneeIds ?? task.assigneeIds,
        reviewerIds: action.payload.reviewerIds ?? task.reviewerIds,
        taskRelations: action.payload.taskRelations!,
        areAllReviewersRequired: action.payload.areAllReviewersRequired!
      });
    },
    validateSingleTaskRequestForm: (state, action: PayloadAction<CreateSingleTaskRequest>) => {
      const validation = validateSingleTaskForm(action.payload);
      state.ui.isSingleTaskFormValid = validation.isValid;
    },
    retrieveSingleTaskRequested: (state, action: PayloadAction<SingleTaskId>) => {
      state.ui.singleTaskViewStatus = ActionStatus.Pending;

      state.entities.comments.byId = {};
      state.entities.comments.allIds = [];
    },
    retrieveSingleTaskSucceeded: (state, action: PayloadAction<RetrieveSingleTaskResponse>) => {
      const singleTask = action.payload;
      const comments = action.payload.comments;

      state.singleTaskView = singleTask;

      for (const comment of comments) {
        state.entities.comments.byId[comment.commentId] = mapComment(comment);
        state.entities.comments.allIds.push(comment.commentId);
      }

      state.ui.singleTaskViewStatus = ActionStatus.Idle;
    },
    retrieveSingleTaskFailed: (state) => {
      state.ui.singleTaskViewStatus = ActionStatus.Failed;
    },
    createSingleTaskRequested: (state, action: PayloadAction<CreateSingleTaskRequest>) => {
      state.singleTask = new SingleTaskItem({
        singleTaskId: '',
        title: action.payload.title,
        description: action.payload.description,
        dueAt: action.payload.dueAt,
        assigneeIds: action.payload.assigneeIds,
        assigneeCount: action.payload.assigneeIds.length,
        reviewerIds: action.payload.reviewerIds,
        reviewerCount: action.payload.reviewerIds.length,
        ownerId: action.payload.ownerId,
        statusId: action.payload.assigneeIds.length ? ASSIGNED : DRAFT,
        commentCount: 0,
        attachmentCount: action.payload.attachmentUrls.length,
        relationCount: action.payload.taskRelations.length,
        publicId: action.payload.publicId,
        isPrivate: action.payload.isPrivate,
        taskListId: '',
        taskListPublicId: '',
        taskListTotalTaskCount: 0,
        positionInTaskList: 0,
        isPinned: false,
        createdAt: new Date().toISOString(),
        lastModifiedAt: new Date().toISOString()
      });
      state.ui.createSingleTaskStatus = ActionStatus.Pending;
    },
    createSingleTaskSucceeded: (state, action: PayloadAction<SingleTaskId>) => {
      const taskId = action.payload.id;
      const taskStatus = state.singleTask.statusId;
      const taskDueAt = state.singleTask.dueAt;

      state.entities.tasks.byId[taskId] = new TaskKindWithPrivate({
        kind: {
          case: 'singleTaskItem',
          value: new SingleTaskItem({
            singleTaskId: taskId,
            title: state.singleTask.title,
            description: state.singleTask.description,
            dueAt: state.singleTask.dueAt,
            assigneeIds: state.singleTask.assigneeIds,
            assigneeCount: state.singleTask.assigneeCount,
            reviewerIds: state.singleTask.reviewerIds,
            reviewerCount: state.singleTask.reviewerCount,
            ownerId: state.singleTask.ownerId,
            statusId: state.singleTask.statusId,
            commentCount: state.singleTask.commentCount,
            attachmentCount: state.singleTask.attachmentCount,
            relationCount: state.singleTask.relationCount,
            publicId: state.singleTask.publicId,
            isPrivate: state.singleTask.isPrivate,
            taskListId: state.singleTask.taskListId,
            taskListPublicId: state.singleTask.taskListPublicId,
            taskListTotalTaskCount: state.singleTask.taskListTotalTaskCount,
            positionInTaskList: state.singleTask.positionInTaskList,
            isPinned: state.singleTask.isPinned,
            createdAt: state.singleTask.createdAt,
            lastModifiedAt: state.singleTask.lastModifiedAt
          })
        }
      });

      state.entities.tasks.singleTaskIds.push(taskId);

      if (taskStatus === DRAFT) {
        state.entities.tasks.draftIds.push(taskId);
      } else {
        state.entities.tasks.assignedIds.push(taskId);
      }

      const today = DateTime.now().startOf('day');
      const dueDate = DateTime.fromISO(taskDueAt).startOf('day');

      if (dueDate.hasSame(today, 'day')) {
        state.entities.tasks.dueTodayIds.push(taskId);
      } else if (dueDate <= today.plus({ days: 7 })) {
        state.entities.tasks.dueThisWeekIds.push(taskId);
      } else if (dueDate <= today.plus({ days: 14 })) {
        state.entities.tasks.dueNextWeekIds.push(taskId);
      } else {
        state.entities.tasks.dueLaterIds.push(taskId);
      }

      state.ui.createSingleTaskStatus = ActionStatus.Idle;

      state.singleTaskRequest = {} as CreateSingleTaskRequest;
      state.entities.assignees.selectedIds = [];
      state.entities.reviewers.selectedIds = [];
    },
    createSingleTaskFailed: (state) => {
      state.ui.createSingleTaskStatus = ActionStatus.Failed;
    },
    deleteSingleTaskRequested: (state, action: PayloadAction<SingleTaskId>) => {
      state.ui.singleTaskDeleteStatus = ActionStatus.Pending;
    },
    deleteSingleTaskSucceeded: (state, action: PayloadAction<string>) => {
      const taskId = action.payload;

      state.entities.tasks.singleTaskIds = state.entities.tasks.singleTaskIds.filter((id) => id !== taskId);
      state.entities.tasks.deletedIds.push(taskId);

      state.ui.singleTaskDeleteStatus = ActionStatus.Idle;
    },
    deleteSingleTaskFailed: (state) => {
      state.ui.singleTaskDeleteStatus = ActionStatus.Failed;
    },
    editTaskRequested: (state, action: PayloadAction<EditTaskRequest>) => {},
    editTaskSucceeded: (state, action: PayloadAction<EditTaskRequest>) => {
      const singleTaskItem = state.entities.tasks.byId[action.payload.taskId].kind.value as SingleTaskItem;

      state.entities.tasks.byId[action.payload.taskId] = {
        ...state.entities.tasks.byId[action.payload.taskId],
        kind: {
          case: 'singleTaskItem',
          value: new SingleTaskItem({
            ...state.entities.tasks.byId[action.payload.taskId].kind.value,
            title: action.payload.title,
            description: action.payload.description,
            dueAt: action.payload.dueAt,
            assigneeIds: action.payload.assigneeIds,
            assigneeCount: action.payload.assigneeIds.length,
            reviewerIds: action.payload.reviewerIds,
            reviewerCount: action.payload.reviewerIds.length,
            statusId: singleTaskItem.statusId,
            lastModifiedAt: new Date().toISOString(),
            attachmentCount: action.payload.attachmentUrls.length
          })
        }
      };

      state.singleTaskView = {
        ...state.singleTaskView,
        title: action.payload.title,
        description: action.payload.description,
        dueAt: action.payload.dueAt,
        assigneeIds: action.payload.assigneeIds,
        reviewers: action.payload.reviewerIds.map(
          (id) =>
            new Reviewer({
              id: id
            })
        ),
        attachmentUrls: action.payload.attachmentUrls
      };
    },
    editTaskFailed: (state) => {},
    archiveTaskRequested: (state, action: PayloadAction<SingleTaskId>) => {},
    archiveTaskSucceeded: (state, action: PayloadAction<string>) => {},
    archiveTaskFailed: (state) => {},
    updateTaskListRequestForm: (state, action: PayloadAction<CreateTaskListRequest>) => {
      state.taskListRequest = {
        ...state.taskListRequest,
        ...action.payload
      };
    },
    retrieveEmployeesAutocompleteItemsRequested: (state, _action: PayloadAction<RetrieveEmployeesByQueryRequest>) => {
      state.ui.reviewersAutocompleteItemsStatus = ActionStatus.Pending;
      state.ui.assigneesAutocompleteItemsStatus = ActionStatus.Pending;
    },
    retrieveEmployeesAutocompleteItemsSucceeded: (state, action: PayloadAction<RetrieveEmployeesByQueryResponse>) => {
      state.reviewersAutocompleteItems = action.payload;
      state.assigneesAutocompleteItems = action.payload;
      state.ui.reviewersAutocompleteItemsStatus = ActionStatus.Idle;
      state.ui.assigneesAutocompleteItemsStatus = ActionStatus.Idle;
    },
    retrieveEmployeesAutocompleteItemsFailed: (state) => {
      state.ui.reviewersAutocompleteItemsStatus = ActionStatus.Failed;
      state.ui.assigneesAutocompleteItemsStatus = ActionStatus.Failed;
    },
    reactToTaskRequested: (state, action: PayloadAction<ReactToTaskRequest>) => {
      state.singleTaskView.reactions ??= [];

      state.singleTaskView = {
        ...state.singleTaskView,
        reactions: [
          ...state.singleTaskView.reactions,
          new Reaction({
            authorId: action.payload.authorId,
            emoji: action.payload.emoji,
            reactedAt: new Date().toISOString()
          })
        ]
      };
    },
    reactToTaskSucceeded: (state) => {},
    reactToTaskFailed: (state, action: PayloadAction<ReactToTaskRequest>) => {
      state.singleTaskView.reactions[action.payload.emoji] = state.singleTaskView.reactions[action.payload.emoji].filter(
        (r) => r.authorId !== action.payload.authorId
      );
    },
    withdrawTaskReactionRequested: (state, action: PayloadAction<WithdrawTaskReactionRequest>) => {
      state.singleTaskView.reactions = state.singleTaskView.reactions.filter(
        (r) => !(r.authorId === action.payload.authorId && r.emoji === action.payload.emoji)
      );
    },
    withdrawTaskReactionSucceeded: (state) => {},
    withdrawTaskReactionFailed: (state, action: PayloadAction<WithdrawTaskReactionRequest>) => {
      state.singleTaskView.reactions ??= [];

      state.singleTaskView = {
        ...state.singleTaskView,
        reactions: [
          ...state.singleTaskView.reactions,
          new Reaction({
            authorId: action.payload.authorId,
            emoji: action.payload.emoji,
            reactedAt: new Date().toISOString()
          })
        ]
      };
    },
    commentOnTaskRequested: (state, action: PayloadAction<CommentOnTaskRequest>) => {
      state.singleTaskView.comments ??= [];

      state.singleTaskView = {
        ...state.singleTaskView,
        comments: [
          ...state.singleTaskView.comments,
          new Comment({
            ...action.payload
          })
        ]
      };
      state.ui.commentOnTaskStatus = ActionStatus.Pending;
    },
    commentOnTaskSucceeded: (state) => {
      state.ui.commentOnTaskStatus = ActionStatus.Idle;
    },
    commentOnTaskFailed: (state, action: PayloadAction<string>) => {
      state.singleTaskView.comments = state.singleTaskView.comments.filter((c) => c.commentId !== action.payload);
      state.ui.commentOnTaskStatus = ActionStatus.Failed;
    },
    reactToTaskCommentRequested: (state, action: PayloadAction<ReactToTaskCommentRequest>) => {
      const { commentId, emoji, authorId } = action.payload;
      const comment = state.entities.comments.byId[commentId];

      if (!comment) return;

      comment.reactions[emoji] ??= [];

      comment.reactions[emoji].push({
        emoji,
        authorId,
        reactedAt: DateTime.now().toISO()
      } as Reaction);
    },

    reactToTaskCommentSucceeded: (state) => {},
    reactToTaskCommentFailed: (state, action: PayloadAction<ReactToTaskCommentRequest>) => {
      const { commentId, emoji, authorId } = action.payload;
      const comment = state.entities.comments.byId[commentId];

      if (!comment || !comment.reactions[emoji]) return;

      comment.reactions[emoji] = comment.reactions[emoji].filter((r) => r.authorId !== authorId);
    },
    withdrawTaskCommentReactionRequested: (state, action: PayloadAction<WithdrawTaskCommentReactionRequest>) => {
      const { commentId, authorId, emoji } = action.payload;
      const comments = state.entities.comments.byId;
      const comment = comments[commentId];

      if (!comment) return;

      comments[commentId] = {
        ...comment,
        reactions: {
          ...comment.reactions,
          [emoji]: comment.reactions[emoji].filter((r) => !(r.authorId === authorId && r.emoji === emoji))
        }
      };
    },
    withdrawTaskCommentReactionSucceded: (state) => {},
    withdrawTaskCommentReactionFailed: (state, action: PayloadAction<WithdrawTaskCommentReactionRequest>) => {
      state.entities.comments.byId[action.payload.commentId].reactions[action.payload.emoji] ??= [];
      state.entities.comments.byId[action.payload.commentId].reactions[action.payload.emoji].push(action.payload);
    },
    retrieveTasksForDependenciesRequested: (state) => {
      state.ui.tasksForDependenciesStatus = ActionStatus.Pending;
    },
    retrieveTasksForDependenciesSucceeded: (state, action: PayloadAction<RetrieveTasksForDependenciesResponse>) => {
      const { tasks } = action.payload;

      for (const task of tasks) {
        state.entities.tasksForDependencies.byId[task.id] = task;
        state.entities.tasksForDependencies.allIds.push(task.id);
      }

      state.ui.tasksForDependenciesStatus = ActionStatus.Idle;
    },
    retrieveTasksForDependenciesFailed: (state) => {
      state.ui.tasksForDependenciesStatus = ActionStatus.Failed;
    },
    selectDependsOnTaskDependencies: (state, action: PayloadAction<SimpleTask[]>) => {
      for (const task of action.payload) {
        if (!state.entities.tasksDependsOnTasks.hasOwnProperty(task.id)) {
          state.entities.tasksDependsOnTasks.byId[task.id] = task;
          state.entities.tasksDependsOnTasks.allIds.push(task.id);
        }
      }
    },
    selectDependsForTaskDependencies: (state, action: PayloadAction<SimpleTask[]>) => {
      for (const task of action.payload) {
        if (!state.entities.tasksDependsForTasks.hasOwnProperty(task.id)) {
          state.entities.tasksDependsForTasks.byId[task.id] = task;
          state.entities.tasksDependsForTasks.allIds.push(task.id);
        }
      }
    },
    setDrawerSingleTaskItemId: (state, action: PayloadAction<string>) => {
      const drawerSingleTaskItem = state.entities.tasks.byId[action.payload].kind.value as SingleTaskItem;

      state.drawerSingleTaskItemId = action.payload;
      state.entities.assignees.selectedIds = drawerSingleTaskItem.assigneeIds;
      state.entities.reviewers.selectedIds = drawerSingleTaskItem.reviewerIds;
    },
    retrieveSingleTasksInTaskListRequested: (state, action: PayloadAction<TaskListId>) => {
      state.ui.singleTasksInTaskListStatus = ActionStatus.Pending;
    },
    retrieveSingleTasksInTaskListSucceeded: (state, action: PayloadAction<RetrieveSingleTasksInTaskListResponse>) => {
      const { singleTasks } = action.payload;

      for (const task of singleTasks) {
        state.entities.singleTasksInTaskList.byId[task.singleTaskId] = task;
        state.entities.singleTasksInTaskList.allIds.push(task.singleTaskId);
      }

      state.ui.singleTasksInTaskListStatus = ActionStatus.Idle;
    },
    retrieveSingleTasksInTaskListFailed: (state) => {
      state.ui.singleTasksInTaskListStatus = ActionStatus.Failed;
    }
  }
});

export const selectTasksViewMode = (state: RootState) => state.tasks.ui.viewMode;
export const selectTasksScopeMode = (state: RootState) => state.tasks.ui.scope;
export const selectTasksGroupBy = (state: RootState) => state.tasks.ui.groupBy;

export const selectTasksPaging = (state: RootState) => state.tasks.ui.paging;

// export const selectDueTodayTasks = (state: RootState) => state.tasks.entities.tasks.dueTodayIds.map((id) => state.tasks.entities.tasks.byId[id]);
// export const selectDueThisWeekTasks = (state: RootState) => state.tasks.entities.tasks.dueThisWeekIds.map((id) => state.tasks.entities.tasks.byId[id]);
// export const selectDueNextWeekTasks = (state: RootState) => state.tasks.entities.tasks.dueNextWeekIds.map((id) => state.tasks.entities.tasks.byId[id]);
// export const selectDueLaterTasks = (state: RootState) => state.tasks.entities.tasks.dueLaterIds.map((id) => state.tasks.entities.tasks.byId[id]);
export const selectDueTodayTasks = (state: RootState) =>
  state.tasks.entities.tasks.dueTodayIds.filter((id) => !state.tasks.entities.tasks.deletedIds.includes(id)).map((id) => state.tasks.entities.tasks.byId[id]);

export const selectDueThisWeekTasks = (state: RootState) =>
  state.tasks.entities.tasks.dueThisWeekIds
    .filter((id) => !state.tasks.entities.tasks.deletedIds.includes(id))
    .map((id) => state.tasks.entities.tasks.byId[id]);

export const selectDueNextWeekTasks = (state: RootState) =>
  state.tasks.entities.tasks.dueNextWeekIds
    .filter((id) => !state.tasks.entities.tasks.deletedIds.includes(id))
    .map((id) => state.tasks.entities.tasks.byId[id]);

export const selectDueLaterTasks = (state: RootState) =>
  state.tasks.entities.tasks.dueLaterIds.filter((id) => !state.tasks.entities.tasks.deletedIds.includes(id)).map((id) => state.tasks.entities.tasks.byId[id]);
export const selectTotalTasksByDueDateType = (state: RootState, dueDateType: DueDateType) =>
  state.tasks.ui.paging.dueDates.sections.find((d) => d.dueDateType === dueDateType)!.totalCount;

// export const selectDraftTasks = (state: RootState) => state.tasks.entities.tasks.draftIds.map((id) => state.tasks.entities.tasks.byId[id]);
// export const selectAssignedTasks = (state: RootState) => state.tasks.entities.tasks.assignedIds.map((id) => state.tasks.entities.tasks.byId[id]);
// export const selectInReviewTasks = (state: RootState) => state.tasks.entities.tasks.inReviewIds.map((id) => state.tasks.entities.tasks.byId[id]);
// export const selectCompletedTasks = (state: RootState) => state.tasks.entities.tasks.completedIds.map((id) => state.tasks.entities.tasks.byId[id]);

export const selectDraftTasks = (state: RootState) =>
  state.tasks.entities.tasks.draftIds.filter((id) => !state.tasks.entities.tasks.deletedIds.includes(id)).map((id) => state.tasks.entities.tasks.byId[id]);
export const selectAssignedTasks = (state: RootState) =>
  state.tasks.entities.tasks.assignedIds.filter((id) => !state.tasks.entities.tasks.deletedIds.includes(id)).map((id) => state.tasks.entities.tasks.byId[id]);
export const selectInReviewTasks = (state: RootState) =>
  state.tasks.entities.tasks.inReviewIds.filter((id) => !state.tasks.entities.tasks.deletedIds.includes(id)).map((id) => state.tasks.entities.tasks.byId[id]);
export const selectCompletedTasks = (state: RootState) =>
  state.tasks.entities.tasks.completedIds.filter((id) => !state.tasks.entities.tasks.deletedIds.includes(id)).map((id) => state.tasks.entities.tasks.byId[id]);

export const selectTaskPublicIdById = (state: RootState, taskId: string) => state.tasks.entities.tasks.byId[taskId].kind.value!.publicId;

export const selectTotalTasksByStatus = (state: RootState, statusId: string) => {
  const section = state.tasks.ui.paging.statuses.sections.find((s) => s.statusId === statusId);
  return section ? section.totalCount : 0;
};

export const selectTasksByTaskListId = (state: RootState, taskListId: string) =>
  chain(state.tasks.entities.tasks.singleTaskIds)
    .map((id) => state.tasks.entities.tasks.byId[id])
    .filter((st) => st.kind.case === 'singleTaskItem' && st.kind.value.taskListId === taskListId)
    .value();

export const selectSelectedSingleTask = (state: RootState) => {
  if (state.tasks.selectedSingleTaskId === undefined) return undefined;

  return state.tasks.entities.tasks.byId[state.tasks.selectedSingleTaskId].kind.value as SingleTaskItem;
};

export const selectTasksViewsById = (state: RootState) => state.tasks.entities.taskViews.byId;
export const selectTasksViewsSavedIds = (state: RootState) => state.tasks.entities.taskViews.savedIds;
export const selectTasksViewsUnsavedIds = (state: RootState) => state.tasks.entities.taskViews.unsavedIds;
export const selectTasksViewsHiddenIds = (state: RootState) => state.tasks.entities.taskViews.hiddenIds;

export const selectActiveTasksViewId = (state: RootState) => state.tasks.activeTasksViewId;
export const selectIsActiveViewSingleTaskView = (state: RootState) => state.tasks.entities.taskViews.byId[state.tasks.activeTasksViewId].kind.case === 'single';

export const selectRetrieveTasksRequest = createSelector(
  [selectTasksViewsById, selectActiveTasksViewId, selectTasksGroupBy, selectTasksScopeMode, selectTasksPaging, (_, employeeId) => employeeId],
  (byId, activeTasksViewId, groupBy, taskScope, paging, employeeId) => {
    if (byId[activeTasksViewId].kind.case === 'single') return undefined;

    const currentViewFilters = byId[activeTasksViewId].kind.value as TasksViewFilters;

    if (groupBy === TasksGroupBy.DueDate) {
      const index = paging.dueDates.sections.findIndex((s) => s.dueDateType === paging.dueDates.activeSection);
      const safeIndex = index === -1 ? 0 : index;

      return new RetrieveTasksRequest({
        employeeId,
        taskScope,
        pageNumber: paging.dueDates.sections[safeIndex].pageNumber,
        pageSize: paging.dueDates.sections[safeIndex].pageSize,
        groupBy: {
          case: 'dueDates',
          value: new GroupByDueDateRequest({
            dates: [DueDateType.DUE_TODAY, DueDateType.DUE_THIS_WEEK, DueDateType.DUE_NEXT_WEEK, DueDateType.DUE_LATER]
          })
        },
        ...currentViewFilters
      });
    } else {
      const index = paging.statuses.sections.findIndex((s) => s.statusId === paging.statuses.activeSection);
      const safeIndex = index === -1 ? 0 : index;

      return new RetrieveTasksRequest({
        employeeId,
        taskScope,
        pageNumber: paging.statuses.sections[safeIndex].pageNumber,
        pageSize: paging.statuses.sections[safeIndex].pageSize,
        groupBy: {
          case: 'statuses',
          value: new GroupByStatusRequest({
            statusIds: currentViewFilters.statusIds.length === 0 ? [DRAFT, ASSIGNED, IN_REVIEW, COMPLETED] : currentViewFilters.statusIds
          })
        },
        ...currentViewFilters
      });
    }
  }
);

export const selectVisibleTasksViewsIds = createSelector([selectTasksViewsSavedIds, selectTasksViewsUnsavedIds], (savedIds, unsavedIds) =>
  chain(savedIds.concat(unsavedIds))
    .filter((id) => id !== TASKS_DEFAULT_VIEW_ID)
    .uniq()
    .value()
);

export const selectVisibleTasksViews = createSelector([selectVisibleTasksViewsIds, selectTasksViewsById], (visibleViewIds, byId) =>
  visibleViewIds.map((id) => byId[id])
);

export const selectAllTasksViews = createSelector(
  [selectVisibleTasksViewsIds, selectTasksViewsHiddenIds, selectTasksViewsById],
  (visibleIds, hiddenIds, byId) =>
    chain(visibleIds.concat(hiddenIds))
      .map((id) => byId[id])
      .sortBy('lastModifiedAt')
      .value()
);

export const selectIsViewChanged = createSelector(
  [selectTasksViewsSavedIds, selectTasksViewsUnsavedIds, (_, viewId: string) => viewId],
  (savedIds, unsavedIds, viewId) => savedIds.includes(viewId) && unsavedIds.includes(viewId)
);

export const selectTasksViewsAsOptions = createSelector([selectAllTasksViews], (views) => views.map((v) => ({ id: v.tasksViewId, name: v.name }) as Option));

// BEGIN Assignees -------------------
const selectAssigneesById = (state: RootState) => state.tasks.entities.assignees.byId;
export const selectInitialAssigneeIds = (state: RootState) => state.tasks.entities.assignees.initialIds;
export const selectSelectedAssigneeIds = (state: RootState) => state.tasks.entities.assignees.selectedIds;

export const selectChosenAssigneeIds = createSelector([selectInitialAssigneeIds, selectSelectedAssigneeIds], (initialIds, selectedIds) =>
  chain(initialIds.concat(selectedIds)).uniq().value()
);

export const selectChosenAssignees = createSelector([selectAssigneesById, selectChosenAssigneeIds], (byId, chosenAssigneeIds) =>
  chosenAssigneeIds.map((id) => byId[id])
);

// TODO: audience group -> assignees
const selectAssigneesAutocompleteItems = (state: RootState) => state.tasks.assigneesAutocompleteItems;

// TODO: move to shared groupOptionsByType under types
const selectAssigneesAutocompleteItemsGroupedByType = createSelector([selectAssigneesAutocompleteItems], (items) => groupOptionsByType(flattenData(items)));

const selectAssigneesAutocompleteEmployeeItems = (state: RootState) => state.tasks.assigneesAutocompleteItems.employeeResults?.employees || [];

const selectAssigneesAutocompleteEmployeeItemsGroupedAlphabetically = createSelector([selectAssigneesAutocompleteEmployeeItems], (items) =>
  groupEmployeesAlphabetically(items)
);

export const selectTaskAssigneesAutocompleteOptions = createSelector(
  [selectAssigneesAutocompleteItemsGroupedByType, selectAssigneesAutocompleteEmployeeItemsGroupedAlphabetically, (_, searchText) => searchText],
  (groupedOptionsByType, groupedEmployeesAlphabetically, searchText) =>
    searchText.length >= 2
      ? Object.keys(groupedOptionsByType).flatMap((key) => groupedOptionsByType[key])
      : Object.keys(groupedEmployeesAlphabetically).flatMap((key) => groupedEmployeesAlphabetically[key])
);

export const selectAssigneesAutocompleteItemsStatus = (state: RootState) => state.tasks.ui.assigneesAutocompleteItemsStatus;

// END Assignees ----------

// BEGIN Reviewers -------------------
const selectReviewersById = (state: RootState) => state.tasks.entities.reviewers.byId;
export const selectInitialReviewerIds = (state: RootState) => state.tasks.entities.reviewers.initialIds;
export const selectSelectedReviewerIds = (state: RootState) => state.tasks.entities.reviewers.selectedIds;

export const selectChosenReviewerIds = createSelector([selectInitialReviewerIds, selectSelectedReviewerIds], (initialIds, selectedIds) =>
  chain(initialIds.concat(selectedIds)).uniq().value()
);

export const selectChosenReviewers = createSelector([selectReviewersById, selectChosenReviewerIds], (byId, chosenReviewerIds) =>
  chosenReviewerIds.map((id) => byId[id])
);

// TODO: audience group -> reviewers
const selectReviewersAutocompleteItems = (state: RootState) => state.tasks.reviewersAutocompleteItems;

// TODO: move to shared groupOptionsByType under types
const selectReviewersAutocompleteItemsGroupedByType = createSelector([selectReviewersAutocompleteItems], (items) => groupOptionsByType(flattenData(items)));

const selectReviewersAutocompleteEmployeeItems = (state: RootState) => state.tasks.reviewersAutocompleteItems.employeeResults?.employees || [];

const selectReviewersAutocompleteEmployeeItemsGroupedAlphabetically = createSelector([selectReviewersAutocompleteEmployeeItems], (items) =>
  groupEmployeesAlphabetically(items)
);

export const selectTaskReviewersAutocompleteOptions = createSelector(
  [selectReviewersAutocompleteItemsGroupedByType, selectReviewersAutocompleteEmployeeItemsGroupedAlphabetically, (_, searchText) => searchText],
  (groupedOptionsByType, groupedEmployeesAlphabetically, searchText) =>
    searchText.length >= 2
      ? Object.keys(groupedOptionsByType).flatMap((key) => groupedOptionsByType[key])
      : Object.keys(groupedEmployeesAlphabetically).flatMap((key) => groupedEmployeesAlphabetically[key])
);

export const selectReviewersAutocompleteItemsStatus = (state: RootState) => state.tasks.ui.reviewersAutocompleteItemsStatus;

// END Reviewers ----------

export const selectIsAssetsModalOpenedOnCreateTask = (state: RootState) => state.tasks.ui.createTaskDrawer.isAssetsModalOpened;
export const selectIsAssetsModalOpenedOnTaskComment = (state: RootState) => state.tasks.ui.newComment.isAssetsModalOpened;

const tempTaskListData: TaskKind[] = [
  new TaskKind({
    kind: {
      case: 'singleTaskItem',
      value: new SingleTaskItem({
        title: 'Design Homepage',
        description: 'Create a responsive design for the homepage.',
        publicId: '#M9N001-P2',
        assigneeCount: 1,
        assigneeIds: [],
        reviewerCount: 0,
        reviewerIds: [],
        dueAt: '2024-12-01',
        statusId: ASSIGNED
      })
    }
  }),
  new TaskKind({
    kind: {
      case: 'singleTaskItem',
      value: new SingleTaskItem({
        title: 'Write Documentation',
        description: 'Prepare technical documentation for the API.',
        publicId: 'M9N001-P2',
        assigneeCount: 1,
        assigneeIds: [],
        reviewerCount: 0,
        reviewerIds: ['111'], // Alex Johnson
        dueAt: '2024-12-05',
        statusId: ASSIGNED
      })
    }
  }),
  new TaskKind({
    kind: {
      case: 'singleTaskItem',
      value: new SingleTaskItem({
        publicId: '#M9N003-P2',
        title: 'Code Review',
        description: 'Review the pull request for the new feature.',
        assigneeCount: 1,
        assigneeIds: [],
        reviewerCount: 0,
        reviewerIds: ['111'], // Alice Johnson
        dueAt: '2024-11-10',
        statusId: ASSIGNED
      })
    }
  }),
  new TaskKind({
    kind: {
      case: 'singleTaskItem',
      value: new SingleTaskItem({
        title: 'Fix Bugs',
        description: 'Resolve high-priority bugs in the production environment.',
        publicId: '#M9N004-P2',
        assigneeCount: 1,
        assigneeIds: [],
        reviewerCount: 0,
        reviewerIds: ['111'], // Bob Brown
        dueAt: '2024-12-05',
        statusId: ASSIGNED
      })
    }
  }),
  new TaskKind({
    kind: {
      case: 'taskListItem',
      value: new TaskListItem({
        taskListId: '12345',
        title: 'Mock Task List',
        description: 'This is a description for the mock task list.',
        publicId: 'public-67890',
        totalTaskCount: 50,
        completedTaskCount: 20,
        relationCount: 5,
        someAssigneeIds: ['user-1', 'user-2', 'user-3'],
        assigneeCount: 3,
        someReviewerIds: ['reviewer-1', 'reviewer-2'],
        reviewerCount: 2,
        owner: 'owner-123',
        dueAt: '2024-12-31T23:59:59Z',
        statusId: IN_REVIEW,
        commentCount: 10,
        attachmentCount: 5,
        isPrivate: false,
        isPinned: true,
        createdAt: '2024-01-01T10:00:00Z',
        lastModifiedAt: '2024-12-01T15:00:00Z'
      })
    }
  })
];

export const selectTempDependencyTasks = (state: RootState) => {
  return tempTaskListData;
};

export const selectSingleTask = (state: RootState) => state.tasks.singleTask;
export const selectCreateSingleTaskStatus = (state: RootState) => state.tasks.ui.createSingleTaskStatus;

export const selectSingleTaskRequest = (state: RootState) => state.tasks.singleTaskRequest;
export const selectIsSingleTaskFormValid = (state: RootState) => state.tasks.ui.isSingleTaskFormValid;

export const selectSingleTaskView = (state: RootState) => state.tasks.singleTaskView;
export const selectSingleTaskViewStatus = (state: RootState) => state.tasks.ui.singleTaskViewStatus;

export const selectSingleTaskViewReactions = (state: RootState): { [key: string]: Reaction[] } => {
  const reactionsDictionary: { [key: string]: Reaction[] } = {};

  const reactions = state.tasks.singleTaskView.reactions;

  if (!reactions || reactions.length === 0) {
    return reactionsDictionary;
  }

  reactions.forEach((reaction: Reaction) => {
    const emoji = reaction.emoji;
    if (!reactionsDictionary[emoji]) {
      reactionsDictionary[emoji] = [];
    }
    reactionsDictionary[emoji].push(reaction);
  });

  return reactionsDictionary;
};

export const selectCommentOnTaskCommentStatus = (state: RootState) => state.tasks.ui.commentOnTaskStatus;
export const selectSingleTaskCommentById = (state: RootState, commentId: string) => state.tasks.entities.comments.byId[commentId];
export const selectSingleTaskCommentsIds = (state: RootState) => state.tasks.entities.comments.allIds;

export const selectComments = (state: RootState) => state.tasks.entities.comments;

export const selectSingleTaskComments = (state: RootState) => state.tasks.singleTaskView.comments;

export const selectSingleTaskCommentReactionsGroupedByEmoji = (state: RootState, commentId: string) => state.tasks.entities.comments.byId[commentId].reactions;

export const selectTaskListTaskRequest = (state: RootState) => state.tasks.taskListRequest;

export const selectTasksForDependencies = (state: RootState) =>
  uniq(state.tasks.entities.tasksForDependencies.allIds).map((id) => state.tasks.entities.tasksForDependencies.byId[id]);

export const selectTasksForDependenciesStatus = (state: RootState) => state.tasks.ui.tasksForDependenciesStatus;

export const selectTasksDependsOnTasksById = (state: RootState) => state.tasks.entities.tasksDependsOnTasks.byId;
export const selectTasksDependsOnTasksIds = (state: RootState) => state.tasks.entities.tasksDependsOnTasks.allIds;
export const selectTasksDependsOnTasks = createSelector([selectTasksDependsOnTasksById, selectTasksDependsOnTasksIds], (byId, ids) =>
  uniq(ids).map((id) => byId[id])
);

export const selectTasksDependsForTasksById = (state: RootState) => state.tasks.entities.tasksDependsForTasks.byId;
export const selectTasksDependsForTasksIds = (state: RootState) => state.tasks.entities.tasksDependsForTasks.allIds;

export const selectTasksDependsForTasks = createSelector([selectTasksDependsForTasksById, selectTasksDependsForTasksIds], (byId, ids) =>
  uniq(ids).map((id) => byId[id])
);

export const selectTasksStatus = (state: RootState) => state.tasks.ui.tasksStatus;

export const selectDrawerSingleTask = (state: RootState) => {
  if (state.tasks.drawerSingleTaskItemId === undefined) return undefined;

  return state.tasks.entities.tasks.byId[state.tasks.drawerSingleTaskItemId].kind.value as SingleTaskItem;
};

export const selectDrawerSingleTaskAssigneesIds = (state: RootState) => {
  const singleTaskItem = state.tasks.entities.tasks.byId[state.tasks.drawerSingleTaskItemId].kind.value! as SingleTaskItem;

  return singleTaskItem.assigneeIds;
};

export const selectEditSingleTaskRequest = (state: RootState) => state.tasks.editSingleTaskRequest;

export const selectSingleTasksInTaskListById = (state: RootState) => state.tasks.entities.singleTasksInTaskList.byId;
export const selectSingleTasksInTaskListIds = (state: RootState) => state.tasks.entities.singleTasksInTaskList.allIds;
export const selectSingleTasksInTaskListStatus = (state: RootState) => state.tasks.ui.singleTasksInTaskListStatus;

export const selectSingleTasksInTaskList = createSelector([selectSingleTasksInTaskListById, selectSingleTasksInTaskListIds], (byId, ids) =>
  uniq(ids).map((id) => byId[id])
);

export const {
  addAssignees,
  addReviewers,
  archiveTaskFailed,
  archiveTaskRequested,
  archiveTaskSucceeded,
  changeTasksGroupBy,
  changeTasksScope,
  changeTasksView,
  changeTasksViewMode,
  closeAssetsModalOnCreateTask,
  commentOnTaskFailed,
  commentOnTaskRequested,
  commentOnTaskSucceeded,
  createSingleTaskFailed,
  createSingleTaskRequested,
  createSingleTaskSucceeded,
  deleteSingleTaskFailed,
  deleteSingleTaskRequested,
  deleteSingleTaskSucceeded,
  deleteTasksViewFailed,
  deleteTasksViewRequested,
  deleteTasksViewSucceeded,
  editSingleTaskRequestForm,
  editTaskFailed,
  editTaskRequested,
  editTaskSucceeded,
  loadTasksFailed,
  loadTasksRequested,
  loadTasksSucceeded,
  nextPageByDueDate,
  nextPageByStatus,
  openAssetsModalOnCreateTask,
  openSingleTask,
  reactToTaskCommentFailed,
  reactToTaskCommentRequested,
  reactToTaskCommentSucceeded,
  reactToTaskFailed,
  reactToTaskRequested,
  reactToTaskSucceeded,
  removeAssignee,
  removeReviewer,
  renameTasksViewFailed,
  renameTasksViewRequested,
  renameTasksViewSucceeded,
  retrieveAssigneeAutocompleteItemsFailed,
  retrieveAssigneeAutocompleteItemsRequested,
  retrieveAssigneeAutocompleteItemsSucceeded,
  retrieveEmployeesAutocompleteItemsFailed,
  retrieveEmployeesAutocompleteItemsRequested,
  retrieveEmployeesAutocompleteItemsSucceeded,
  retrieveReviewerAutocompleteItemsFailed,
  retrieveReviewerAutocompleteItemsRequested,
  retrieveReviewerAutocompleteItemsSucceeded,
  retrieveSingleTaskFailed,
  retrieveSingleTaskRequested,
  retrieveSingleTaskSucceeded,
  retrieveTasksFailed,
  retrieveTasksForDependenciesFailed,
  retrieveTasksForDependenciesRequested,
  retrieveTasksForDependenciesSucceeded,
  retrieveTasksSucceeded,
  selectDependsForTaskDependencies,
  selectDependsOnTaskDependencies,
  setDrawerSingleTaskItemId,
  toggleVisibilityTasksView,
  updateSingleTaskRequestForm,
  updateTaskListRequestForm,
  updateTasksViewFailed,
  updateTasksViewRequested,
  updateTasksViewSucceeded,
  validateSingleTaskRequestForm,
  withdrawTaskCommentReactionFailed,
  withdrawTaskCommentReactionRequested,
  withdrawTaskCommentReactionSucceded,
  withdrawTaskReactionFailed,
  withdrawTaskReactionRequested,
  withdrawTaskReactionSucceeded,
  retrieveSingleTasksInTaskListRequested,
  retrieveSingleTasksInTaskListSucceeded,
  retrieveSingleTasksInTaskListFailed
} = tasksSlice.actions;
export default tasksSlice.reducer;
