import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

export const MenuActionButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isMenuOpen'
})<{ isMenuOpen: boolean }>(({ isMenuOpen, theme }) => ({
  backgroundColor: isMenuOpen ? theme.palette.customTheme.contColor : 'transparent',
  color: isMenuOpen ? theme.palette.common.white : theme.palette.common.black,
  border: isMenuOpen ? undefined : `1px solid ${theme.palette.customTheme.contColor}`,
  borderRadius: '7px',
  width: '100%',
  padding: '12px',
  lineHeight: 'normal',
  fontWeight: 700,
  boxShadow: 'none',
  '& .MuiButton-endIcon': {
    marginTop: '2px',
    transform: isMenuOpen ? 'rotate(180deg)' : undefined,
    transition: 'transform 0.3s ease-in-out',
    '& path': {
      fill: isMenuOpen ? theme.palette.common.white : theme.palette.common.black
    }
  },
  '&:hover': {
    backgroundColor: isMenuOpen ? theme.palette.primary.dark : theme.palette.secondary.dark,
    borderColor: 'transparent'
  }
}));
