import { Tabs, tabsClasses, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { changeTasksView, selectActiveTasksViewId, selectVisibleTasksViews, TASKS_DEFAULT_VIEW_ID } from '@features/tasks';
import { FilterTab } from '@features/people-directory';
import { SyntheticEvent } from 'react';

export const TasksViewsTabs = () => {
  const dispatch = useAppDispatch();

  const activeTasksViewId = useAppSelector(selectActiveTasksViewId);
  const visibleTasksViews = useAppSelector(selectVisibleTasksViews);

  const handleTasksViewClick = (_event: SyntheticEvent<HTMLDivElement, MouseEvent>, tasksViewId: string): void => {
    dispatch(changeTasksView(tasksViewId));
  };

  return (
    <Tabs
      value={activeTasksViewId === TASKS_DEFAULT_VIEW_ID ? false : activeTasksViewId}
      variant="scrollable"
      scrollButtons="auto"
      sx={{
        minHeight: 43,
        borderRadius: 2,
        maxWidth: '90%',
        justifyContent: 'left',
        [`& .${tabsClasses.flexContainer}`]: {
          gap: '8px'
        }
      }}
    >
      {visibleTasksViews.map((vtv) => (
        <FilterTab
          key={vtv.tasksViewId}
          value={vtv.tasksViewId}
          label={
            <Typography fontWeight={700} noWrap>
              {vtv.name}
            </Typography>
          }
          onClick={(e) => handleTasksViewClick(e, vtv.tasksViewId)}
        />
      ))}
    </Tabs>
  );
};
