import React from 'react';

interface GroupIconProps {
  width?: number; // Optional size prop to control the icon size
  height?: number; // Optional size prop to control the icon size
  color?: string; // Optional color prop to control the icon color
}

export const GroupIcon: React.FC<GroupIconProps> = ({ width = 24, height = 24, color = '#251D38' }: GroupIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M3 7V5c0-1.1.9-2 2-2h2" />
      <path d="M17 3h2c1.1 0 2 .9 2 2v2" />
      <path d="M21 17v2c0 1.1-.9 2-2 2h-2" />
      <path d="M7 21H5c-1.1 0-2-.9-2-2v-2" />
      <rect width="7" height="5" x="7" y="7" rx="1" />
      <rect width="7" height="5" x="10" y="12" rx="1" />
    </svg>
  );
};
