import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  IndustryCategory,
  IndustrySubcategory,
  RetrieveIndustryCategoryResponse,
  RetrieveIndustrySubCategoriesRequest,
  RetrieveIndustrySubCategoriesResponse,
  UpdateIncorporationStepRequest,
  UpdateNameAndIndustryStepRequest,
  UpdateEmployeeIdStepRequest,
  UpdateSiteCalendarStepRequest,
  UpsertSiteDetailsStepRequest,
  UpsertLocalizationStepRequest,
  RetrieveWorkingPatternsResponse,
  WorkingPattern,
  UpdateSiteWorkingPatternStepRequest,
  RetrieveAdminOnboardingFlowResponse,
  ImportEmployeeRequest,
  UpdateManagerToEmployeeRequest,
  UpdateManagerToEmployeesRequest,
  ImportEmployeesRequest,
  Employees,
  AdminOnboardingFlowStep,
  UpsertSiteDetailsStepResponse,
  AssignedManagers,
  AssignedManager,
  SetupOrganizationStep,
  NameAndIndustryStep,
  IncorporationStep,
  EmployeeIdStep,
  SetupSiteStep,
  SiteDetailsStep,
  LocalizationStep,
  SiteCalendarStep,
  SiteWorkingPatternStep,
  RetrieveAllEmployeesResponse
} from '@thrivea/organization-client';
import { RootState } from '@app/store';
import { ActionStatus, MultiSizeImageBlobs } from '@/shared';
import { AdminOnboardingFlowInitData, AssignManagersData } from '@features/admin-onboarding';
import { FileWithStatus, UploadedFile } from '@api/blob-storage.api';
import { trimQueryParams } from '@utils/trimQueryParams';
import { SasToken, SasUri } from '@thrivea/auth-client';

export interface ListDropDownItem2 {
  id: string;
  label: string;
}

interface AdminOnboardingState {
  registeredName: string;
  industryCategories: IndustryCategory[];
  industryCategoriesStatus: ActionStatus;
  industrySubcategories: IndustrySubcategory[];
  industrySubcategory: IndustrySubcategory;
  siteActiveStep: number;
  companyActiveStep: number;
  invitePeopleActiveStep: number;
  siteId: string;
  locationId: string;
  localizationId: string;
  nameAndIndustryStep: UpdateNameAndIndustryStepRequest;
  siteDetailsStep: UpsertSiteDetailsStepRequest;
  siteWorkingPatternStep: UpdateSiteWorkingPatternStepRequest;
  incorporationCountry: string;
  workingPatterns: WorkingPattern[];
  workingPatternsStatus: ActionStatus;
  adminOnboardingFlow: RetrieveAdminOnboardingFlowResponse;
  adminOnboardingFlowStatus: ActionStatus;
  adminOnboardingFlowSteps: AdminOnboardingFlowStep[];
  adminOnboardingFlowStepsStatus: ActionStatus;
  importEmployee: ImportEmployeeRequest;
  importedEmployees: Employees;
  managers: RetrieveAllEmployeesResponse;
  importedEmployeesStatus: ActionStatus;
  updatedManagers: UpdateManagerToEmployeeRequest;
  updatingCalendarStepStatus: ActionStatus;
  assignedManagers: AssignedManager[];
  nameAndIndustryStepStatus: ActionStatus;
  incorporationStepStatus: ActionStatus;
  employeeIdStepStatus: ActionStatus;
  siteDetailsStepStatus: ActionStatus;
  localizationStepStatus: ActionStatus;
  calendarStepStatus: ActionStatus;
  workingPatternsStepStatus: ActionStatus;
  updatedAssignedManagersStatus: ActionStatus;
  assignedManagersStatus: ActionStatus;
  siteMediaFileWithStatus?: FileWithStatus;
  uploadSiteMediaStatus: ActionStatus;
  siteMediaUploadProgress: number;
  siteWriteSasUri: string;
  siteDeleteSasToken: string;
  siteReadSasToken: string;
  siteCoverImageFile: MultiSizeImageBlobs | undefined | null;
}

const initialState: AdminOnboardingState = {
  registeredName: '',
  industryCategories: [],
  industryCategoriesStatus: ActionStatus.Idle,
  industrySubcategories: [],
  industrySubcategory: {} as IndustrySubcategory,
  siteActiveStep: 0,
  companyActiveStep: 0,
  invitePeopleActiveStep: 0,
  siteId: '',
  locationId: '',
  localizationId: '',
  nameAndIndustryStep: {} as UpdateNameAndIndustryStepRequest,
  siteDetailsStep: {} as UpsertSiteDetailsStepRequest,
  siteWorkingPatternStep: {} as UpdateSiteWorkingPatternStepRequest,
  incorporationCountry: '',
  workingPatterns: [],
  workingPatternsStatus: ActionStatus.Idle,
  adminOnboardingFlow: new RetrieveAdminOnboardingFlowResponse({
    setupOrganizationStep: new SetupOrganizationStep({
      nameAndIndustryStep: new NameAndIndustryStep(),
      incorporationStep: new IncorporationStep(),
      employeeIdStep: new EmployeeIdStep()
    }),
    setupSiteStep: new SetupSiteStep({
      siteDetailsStep: new SiteDetailsStep(),
      localizationStep: new LocalizationStep(),
      siteCalendarStep: new SiteCalendarStep(),
      siteWorkingPatternStep: new SiteWorkingPatternStep()
    })
  }),
  adminOnboardingFlowStatus: ActionStatus.Idle,
  adminOnboardingFlowSteps: [
    new AdminOnboardingFlowStep({ stepName: 'Step1_1' }),
    new AdminOnboardingFlowStep({ stepName: 'Step1_2' }),
    new AdminOnboardingFlowStep({ stepName: 'Step1_3' }),
    new AdminOnboardingFlowStep({ stepName: 'Step1' }),
    new AdminOnboardingFlowStep({ stepName: 'Step2_1' }),
    new AdminOnboardingFlowStep({ stepName: 'Step2_2' }),
    new AdminOnboardingFlowStep({ stepName: 'Step2_3' }),
    new AdminOnboardingFlowStep({ stepName: 'Step2_4' }),
    new AdminOnboardingFlowStep({ stepName: 'Step2' }),
    new AdminOnboardingFlowStep({ stepName: 'Step3_1' }),
    new AdminOnboardingFlowStep({ stepName: 'Step3_2' }),
    new AdminOnboardingFlowStep({ stepName: 'Step3' })
  ],
  adminOnboardingFlowStepsStatus: ActionStatus.Idle,
  importEmployee: {} as ImportEmployeeRequest,
  importedEmployees: new Employees({ totalCount: 0n, employees: [] }),
  managers: new RetrieveAllEmployeesResponse({ employees: [] }),
  importedEmployeesStatus: ActionStatus.Idle,
  updatedManagers: {} as UpdateManagerToEmployeeRequest,
  updatingCalendarStepStatus: ActionStatus.Idle,
  assignedManagers: [],
  nameAndIndustryStepStatus: ActionStatus.Idle,
  incorporationStepStatus: ActionStatus.Idle,
  employeeIdStepStatus: ActionStatus.Idle,
  siteDetailsStepStatus: ActionStatus.Idle,
  localizationStepStatus: ActionStatus.Idle,
  calendarStepStatus: ActionStatus.Idle,
  workingPatternsStepStatus: ActionStatus.Idle,
  updatedAssignedManagersStatus: ActionStatus.Idle,
  assignedManagersStatus: ActionStatus.Idle,
  siteMediaUploadProgress: 0,
  uploadSiteMediaStatus: ActionStatus.Idle,
  siteWriteSasUri: '',
  siteReadSasToken: '',
  siteDeleteSasToken: '',
  siteCoverImageFile: undefined
};

export const adminOnboardingSlice = createSlice({
  name: 'admin-onboarding',
  initialState,
  reducers: {
    retrieveIndustryCategoriesRequested: (state) => {},
    retrieveIndustryCategoriesSucceeded: (state, action: PayloadAction<RetrieveIndustryCategoryResponse>) => {
      state.industryCategories = action.payload.industryCategories;
    },
    retrieveIndustryCategoriesFailed: (state) => {
      // TODO:
    },
    retrieveIndustrySubcategoriesRequested: (state, action: PayloadAction<RetrieveIndustrySubCategoriesRequest>) => {},
    retrieveIndustrySubcategoriesSucceeded: (state, action: PayloadAction<RetrieveIndustrySubCategoriesResponse>) => {
      state.industrySubcategories = action.payload.industrySubcategories;
    },
    retrieveIndustrySubcategoriesFailed: (state) => {
      // TODO:
    },
    industrySubcategorySelected: (state, action: PayloadAction<ListDropDownItem2>) => {
      state.industrySubcategory = { id: action.payload.id, name: action.payload.label } as IndustrySubcategory;
    },
    updateNameAndIndustryStepRequested: (state, action: PayloadAction<UpdateNameAndIndustryStepRequest>) => {
      state.nameAndIndustryStep = action.payload;
      state.nameAndIndustryStepStatus = ActionStatus.Pending;
    },
    updateNameAndIndustryStepSucceeded: (state) => {
      const step1_1 = state.adminOnboardingFlowSteps.find((step) => step.stepName === 'Step1_1')!;
      step1_1.isCompleted = true;

      state.nameAndIndustryStepStatus = ActionStatus.Idle;
    },
    updateNameAndIndustryStepFailed: (state) => {
      state.nameAndIndustryStepStatus = ActionStatus.Failed;
    },
    updateIncorporationStepRequested: (state, action: PayloadAction<UpdateIncorporationStepRequest>) => {
      state.incorporationStepStatus = ActionStatus.Pending;
    },
    updateIncorporationStepSucceeded: (state) => {
      const step1_2 = state.adminOnboardingFlowSteps.find((step) => step.stepName === 'Step1_2')!;
      step1_2.isCompleted = true;

      state.incorporationStepStatus = ActionStatus.Idle;
    },
    updateIncorporationStepFailed: (state) => {
      state.incorporationStepStatus = ActionStatus.Failed;
    },
    upsertSiteDetailsStepRequested: (state, action: PayloadAction<UpsertSiteDetailsStepRequest>) => {
      state.siteDetailsStep = action.payload;
      state.siteDetailsStepStatus = ActionStatus.Pending;
    },
    upsertSiteDetailsStepSucceeded: (state, action: PayloadAction<UpsertSiteDetailsStepResponse>) => {
      const step2_1 = state.adminOnboardingFlowSteps.find((step) => step.stepName === 'Step2_1')!;
      step2_1.isCompleted = true;

      state.siteId = action.payload.siteId;
      state.siteDetailsStepStatus = ActionStatus.Idle;
    },
    upsertSiteDetailsStepFailed: (state) => {
      state.siteDetailsStepStatus = ActionStatus.Failed;
    },
    incorporationCountrySelected: (state, action: PayloadAction<string>) => {
      state.incorporationCountry = action.payload;
    },
    decrementCompanyActiveStep: (state) => {
      state.companyActiveStep -= 1;
    },
    decrementSiteActiveStep: (state) => {
      state.siteActiveStep -= 1;
    },
    updateEmployeeIdStepRequested: (state, action: PayloadAction<UpdateEmployeeIdStepRequest>) => {
      state.employeeIdStepStatus = ActionStatus.Pending;
    },
    updateEmployeeIdStepSucceeded: (state) => {
      const step1 = state.adminOnboardingFlowSteps.find((step) => step.stepName === 'Step1')!;
      const step1_3 = state.adminOnboardingFlowSteps.find((step) => step.stepName === 'Step1_3')!;

      step1_3.isCompleted = true;
      step1.isCompleted = true;

      state.employeeIdStepStatus = ActionStatus.Idle;
    },
    updateEmployeeIdStepFailed: (state) => {
      state.employeeIdStepStatus = ActionStatus.Failed;
    },
    upsertLocalizationStepRequested: (state, action: PayloadAction<UpsertLocalizationStepRequest>) => {
      state.localizationStepStatus = ActionStatus.Pending;
    },
    upsertLocalizationStepSucceeded: (state) => {
      const step2_2 = state.adminOnboardingFlowSteps.find((step) => step.stepName === 'Step2_2')!;
      step2_2.isCompleted = true;

      state.localizationStepStatus = ActionStatus.Idle;
    },
    upsertLocalizationStepFailed: (state) => {
      state.localizationStepStatus = ActionStatus.Failed;
    },
    updateSiteCalendarStepRequested: (state, action: PayloadAction<UpdateSiteCalendarStepRequest>) => {
      state.calendarStepStatus = ActionStatus.Pending;
    },
    updateSiteCalendarStepSucceeded: (state) => {
      const step2_3 = state.adminOnboardingFlowSteps.find((step) => step.stepName === 'Step2_3')!;
      step2_3.isCompleted = true;

      state.updatingCalendarStepStatus = ActionStatus.Idle;
      state.calendarStepStatus = ActionStatus.Idle;
    },
    updateSiteCalendarStepFailed: (state) => {
      state.updatingCalendarStepStatus = ActionStatus.Idle;
      state.calendarStepStatus = ActionStatus.Failed;
    },
    retrieveWorkingPatternsRequested: (state) => {
      state.workingPatternsStatus = ActionStatus.Pending;
    },
    retrieveWorkingPatternsSucceeded: (state, action: PayloadAction<RetrieveWorkingPatternsResponse>) => {
      state.workingPatterns = action.payload.workingPatterns;
      state.workingPatternsStatus = ActionStatus.Idle;
    },
    retrieveWorkingPatternsFailed: (state) => {},
    updateSiteWorkingPatternRequested: (state, action: PayloadAction<UpdateSiteWorkingPatternStepRequest>) => {
      state.workingPatternsStepStatus = ActionStatus.Pending;
    },
    updateSiteWorkingPatternSucceeded: (state) => {
      const step2 = state.adminOnboardingFlowSteps.find((step) => step.stepName === 'Step2')!;
      const step2_4 = state.adminOnboardingFlowSteps.find((step) => step.stepName === 'Step2_4')!;

      step2.isCompleted = true;
      step2_4.isCompleted = true;

      state.workingPatternsStepStatus = ActionStatus.Idle;
    },
    updateSiteWorkingPatternFailed: (state) => {
      state.workingPatternsStepStatus = ActionStatus.Failed;
    },
    importEmployeesStepRequested: (state, action: PayloadAction<ImportEmployeesRequest>) => {
      state.importedEmployeesStatus = ActionStatus.Pending;
    },
    importEmployeesStepSucceeded: (state) => {
      const step3_1 = state.adminOnboardingFlowSteps.find((step) => step.stepName === 'Step3_1')!;
      step3_1.isCompleted = true;

      state.importedEmployeesStatus = ActionStatus.Idle;
    },
    importEmployeesStepFailed: (state) => {},
    loadAssignManagersRequested: (state) => {
      state.importedEmployees = new Employees({ totalCount: 0n, employees: [] });
      state.managers = new RetrieveAllEmployeesResponse({ employees: [] });
      state.importedEmployeesStatus = ActionStatus.Pending;
    },
    loadAssignManagersSucceeded: (state, action: PayloadAction<AssignManagersData>) => {
      state.importedEmployees = action.payload.importedEmployeees;
      state.managers = action.payload.allEmployeesResponse;
      state.importedEmployeesStatus = ActionStatus.Idle;
    },
    loadAssignManagersFailed: (state) => {
      state.importedEmployeesStatus = ActionStatus.Failed;
    },
    retrieveAssignedManagersRequested: (state) => {
      state.assignedManagersStatus = ActionStatus.Pending;
    },
    retrieveAssignedManagersSucceeded: (state, action: PayloadAction<AssignedManagers>) => {
      state.assignedManagers = action.payload.assignedManagers;
      state.assignedManagersStatus = ActionStatus.Idle;
    },
    retrieveAssignedManagersFailed: (state) => {
      state.assignedManagersStatus = ActionStatus.Failed;
    },
    resetAssignedManagers: (state) => {
      const step3_1 = state.adminOnboardingFlowSteps.find((step) => step.stepName === 'Step3_1')!;
      step3_1.isCompleted = false;

      state.managers = new RetrieveAllEmployeesResponse({ employees: [] });
      state.assignedManagers = [];
    },
    updateEmployeesManagerRequested: (state, action: PayloadAction<UpdateManagerToEmployeesRequest>) => {
      state.updatedAssignedManagersStatus = ActionStatus.Pending;
    },
    updateEmployeesManagerSucceeded: (state) => {
      const step3_2 = state.adminOnboardingFlowSteps.find((step) => step.stepName === 'Step3_2')!;
      step3_2.isCompleted = true;

      state.updatedAssignedManagersStatus = ActionStatus.Idle;
    },
    updateEmployeesManagerFailed: (state) => {
      state.updatedAssignedManagersStatus = ActionStatus.Failed;
    },
    changeRegisteredName: (state, action: PayloadAction<string>) => {
      state.adminOnboardingFlow.setupOrganizationStep!.nameAndIndustryStep!.registeredName = action.payload;
    },
    updateSetupOrganizationStep: (state) => {
      const step2 = state.adminOnboardingFlowSteps.find((step) => step.stepName === 'Step2')!;
      step2.isCompleted = true;
    },
    updateInvitePeopleStep: (state) => {
      const step3 = state.adminOnboardingFlowSteps.find((step) => step.stepName === 'Step3')!;
      step3.isCompleted = false;
    },
    sendEmployeeInvitationEmailRequested: () => {},
    sendEmployeeInvitationEmailSucceeded: () => {},
    sendEmployeeInvitationEmailFailed: () => {},
    uploadSiteMediaRequested: (state, action: PayloadAction<File>) => {
      state.siteMediaFileWithStatus = {
        isUploaded: false,
        isUploading: false,
        name: action.payload.name,
        file: action.payload,
        url: ''
      } as FileWithStatus;

      state.uploadSiteMediaStatus = ActionStatus.Pending;
    },
    uploadSiteMediaSucceeded: (state) => {
      state.uploadSiteMediaStatus = ActionStatus.Idle;
    },
    uploadSiteMediaFailed: (state) => {
      state.uploadSiteMediaStatus = ActionStatus.Failed;
      state.siteMediaFileWithStatus = {} as FileWithStatus;
    },
    updateSiteMediaUploadProgress: (state, action: PayloadAction<number>) => {
      state.siteMediaUploadProgress = action.payload;
    },
    deleteSiteMedia: (state) => {
      state.siteMediaFileWithStatus = undefined;
      state.adminOnboardingFlow.setupSiteStep!.siteDetailsStep!.coverImageUrl = '';
    },
    uploadSiteFile: (state, action: PayloadAction<FileWithStatus>) => {
      state.siteMediaFileWithStatus!.isUploading = true;
      state.siteMediaFileWithStatus!.isUploaded = false;
    },
    siteFileUploaded: (state, action: PayloadAction<UploadedFile>) => {
      state.siteMediaFileWithStatus!.isUploading = false;
      state.siteMediaFileWithStatus!.isUploaded = true;
      state.siteMediaFileWithStatus!.url = trimQueryParams(action.payload.url);
    },
    retrieveSiteWriteSasUriRequested: (state) => {},
    retrieveSiteWriteSasUriSucceeded: (state, action: PayloadAction<SasUri>) => {
      state.siteWriteSasUri = action.payload.uri;
    },
    retrieveSiteWriteSasUriFailed: (state) => {},
    retrieveSiteReadSasTokenRequested: (state) => {},
    retrieveSiteReadSasTokenSucceeded: (state, action: PayloadAction<SasToken>) => {
      state.siteReadSasToken = action.payload.token;
    },
    retrieveSiteReadSasTokenFailed: (state) => {},
    loadAdminOnboardingFlowRequested: (state) => {
      state.adminOnboardingFlowStatus = ActionStatus.Pending;
      state.adminOnboardingFlowStepsStatus = ActionStatus.Pending;
    },
    loadAdminOnboardingFlowSucceeded: (state, action: PayloadAction<AdminOnboardingFlowInitData>) => {
      const { flow, steps } = action.payload;

      state.adminOnboardingFlow = flow;
      state.adminOnboardingFlowSteps = steps.adminOnboardingFlowSteps;

      state.adminOnboardingFlowStatus = ActionStatus.Idle;
      state.adminOnboardingFlowStepsStatus = ActionStatus.Idle;

      if (flow.setupSiteStep!.siteDetailsStep!.coverImageUrl !== '') {
        state.siteMediaFileWithStatus = {
          url: flow.setupSiteStep!.siteDetailsStep!.coverImageUrl,
          isUploaded: true,
          isUploading: false
        } as FileWithStatus;
      }

      if (flow.setupSiteStep?.siteDetailsStep?.siteId !== undefined) {
        state.siteId = flow.setupSiteStep.siteDetailsStep.siteId;
      }
    },
    loadAdminOnboardingFlowFailed: (state) => {
      state.adminOnboardingFlowStatus = ActionStatus.Failed;
      state.adminOnboardingFlowStepsStatus = ActionStatus.Failed;
    },

    setSiteCoverImageFile: (state, action: PayloadAction<MultiSizeImageBlobs | undefined>) => {
      state.siteCoverImageFile = action.payload;
    },
    removeSiteCoverImageFile: (state) => {
      state.siteCoverImageFile = null;
    },
    resetSiteCoverFile: (state) => {
      state.siteCoverImageFile = undefined;
    }
  }
});

export const selectIndustryCategories = (state: RootState) => state.adminOnboarding.industryCategories;

export const selectIndustrySubcategories = (state: RootState) => state.adminOnboarding.industrySubcategories;

export const selectIndustrySubcategory = (state: RootState) => state.adminOnboarding.industrySubcategory;

export const selectSiteId = (state: RootState) => state.adminOnboarding.siteId;

export const selectLocationId = (state: RootState) => state.adminOnboarding.locationId;

export const selectNameAndIndustryStep = (state: RootState) => state.adminOnboarding.nameAndIndustryStep;

export const selectUserOnboardingSiteActiveStep = (state: RootState) => state.adminOnboarding.siteActiveStep;

export const selectIncorporationCountry = (state: RootState) => state.adminOnboarding.incorporationCountry;

export const selectUserOnboardingCompanyActiveStep = (state: RootState) => state.adminOnboarding.companyActiveStep;

export const selectWorkingPatterns = (state: RootState) => state.adminOnboarding.workingPatterns;

export const selectWorkingPatternsStatus = (state: RootState) => state.adminOnboarding.workingPatternsStatus;

export const selectLocalizationId = (state: RootState) => state.adminOnboarding.localizationId;

export const selectInvitePeopleActiveStepTemp = (state: RootState) => state.adminOnboarding.invitePeopleActiveStep;

export const selectAdminOnboardingFlow = (state: RootState) => state.adminOnboarding.adminOnboardingFlow;

export const selectAdminNameAndIndustryStep = (state: RootState) => state.adminOnboarding.adminOnboardingFlow.setupOrganizationStep!.nameAndIndustryStep!;

export const selectIncorporationStep = (state: RootState) => state.adminOnboarding.adminOnboardingFlow.setupOrganizationStep!.incorporationStep!;

export const selectEmployeeStep = (state: RootState) => state.adminOnboarding.adminOnboardingFlow.setupOrganizationStep!.employeeIdStep!;

export const selectSiteDetailsStep = (state: RootState) => state.adminOnboarding.adminOnboardingFlow.setupSiteStep!.siteDetailsStep!;
export const selectAdminOnboardingSiteId = (state: RootState) => state.adminOnboarding.siteId!;

export const selectSiteLocalizationStep = (state: RootState) => state.adminOnboarding.adminOnboardingFlow.setupSiteStep!.localizationStep!;

export const selectSiteCalendarStep = (state: RootState) => state.adminOnboarding.adminOnboardingFlow.setupSiteStep!.siteCalendarStep!;

export const selectSiteWorkingPatternStep = (state: RootState) => state.adminOnboarding.adminOnboardingFlow.setupSiteStep!.siteWorkingPatternStep!;

export const selectAdminOnboardingFlowSteps = (state: RootState) => state.adminOnboarding.adminOnboardingFlowSteps;

export const selectAdminOnboardingFlowStepsStatus = (state: RootState) => state.adminOnboarding.adminOnboardingFlowStepsStatus;

export const selectAdminOnboardingFlowStatus = (state: RootState) => state.adminOnboarding.adminOnboardingFlowStatus;

export const selectImportedEmployees = (state: RootState) => state.adminOnboarding.importedEmployees;

export const selectManagers = (state: RootState) => state.adminOnboarding.managers.employees;

export const selectImportedEmployeesStatus = (state: RootState) => state.adminOnboarding.importedEmployeesStatus;

export const selectHasCreateCompanyStepStarted = (state: RootState) =>
  !state.adminOnboarding.adminOnboardingFlowSteps.find((step) => step.stepName === 'Step1_1')!.isCompleted;

export const selectIsCompanyStepFinished = (state: RootState) =>
  state.adminOnboarding.adminOnboardingFlowSteps.find((step) => step.stepName === 'Step1')!.isCompleted;

export const selectIsCompanyNameAndIndustryStepFinished = (state: RootState) =>
  state.adminOnboarding.adminOnboardingFlowSteps.find((step) => step.stepName === 'Step1_1')!.isCompleted;

export const selectIsCompanyIncorporationStepFinished = (state: RootState) =>
  state.adminOnboarding.adminOnboardingFlowSteps.find((step) => step.stepName === 'Step1_2')!.isCompleted;

export const selectIsCompanyEmployeeStepFinished = (state: RootState) =>
  state.adminOnboarding.adminOnboardingFlowSteps.find((step) => step.stepName === 'Step1_3')!.isCompleted;

export const selectHasSitesStepStarted = (state: RootState) =>
  !state.adminOnboarding.adminOnboardingFlowSteps.find((step) => step.stepName === 'Step2_1')!.isCompleted;

export const selectIssSitesSiteDetailsStepFinished = (state: RootState) =>
  state.adminOnboarding.adminOnboardingFlowSteps.find((step) => step.stepName === 'Step2_1')!.isCompleted;

export const selectIsSitesLocalizationStepFinished = (state: RootState) =>
  state.adminOnboarding.adminOnboardingFlowSteps.find((step) => step.stepName === 'Step2_2')!.isCompleted;

export const selectIsSitesCalendarStepFinished = (state: RootState) =>
  state.adminOnboarding.adminOnboardingFlowSteps.find((step) => step.stepName === 'Step2_3')!.isCompleted;

export const selectIsSitesWorkingPatternsStepFinished = (state: RootState) =>
  state.adminOnboarding.adminOnboardingFlowSteps.find((step) => step.stepName === 'Step2_4')!.isCompleted;

export const selectIsSitesStepFinished = (state: RootState) =>
  state.adminOnboarding.adminOnboardingFlowSteps.find((step) => step.stepName === 'Step2')!.isCompleted;

export const selectHasInvitationStepStarted = (state: RootState) =>
  !state.adminOnboarding.adminOnboardingFlowSteps.find((step) => step.stepName === 'Step3_1')!.isCompleted;

export const selectIsInvitationStepFinished = (state: RootState) =>
  state.adminOnboarding.adminOnboardingFlowSteps.find((step) => step.stepName === 'Step3')!.isCompleted;

export const selectIsImportPeopleSubStepFinished = (state: RootState) =>
  state.adminOnboarding.adminOnboardingFlowSteps.find((step) => step.stepName === 'Step3_1')!.isCompleted;

export const selectIsAssignManagersSubStepFinished = (state: RootState) =>
  state.adminOnboarding.adminOnboardingFlowSteps.find((step) => step.stepName === 'Step3_2')!.isCompleted;

export const selectUpdatingCalendarStepStatus = (state: RootState) => state.adminOnboarding.updatingCalendarStepStatus;

export const selectAssignedManagers = (state: RootState) => state.adminOnboarding.assignedManagers;

export const selectNameAndIndustryStepStatus = (state: RootState) => state.adminOnboarding.nameAndIndustryStepStatus;
export const selectIncorporationStepStatus = (state: RootState) => state.adminOnboarding.incorporationStepStatus;
export const selectEmployeeIdStepStatus = (state: RootState) => state.adminOnboarding.employeeIdStepStatus;
export const selectSiteDetailsStepStatus = (state: RootState) => state.adminOnboarding.siteDetailsStepStatus;
export const selectLocalizationStepStatus = (state: RootState) => state.adminOnboarding.localizationStepStatus;
export const selectCalendarStepStatus = (state: RootState) => state.adminOnboarding.calendarStepStatus;
export const selectWorkingPatternsStepStatus = (state: RootState) => state.adminOnboarding.workingPatternsStepStatus;

export const selectUpdatedAssignedManagersStatus = (state: RootState) => state.adminOnboarding.updatedAssignedManagersStatus;
export const selectAssignedManagersStatus = (state: RootState) => state.adminOnboarding.assignedManagersStatus;

export const selectSiteMediaFileWithStatus = (state: RootState) => state.adminOnboarding.siteMediaFileWithStatus;
export const selectUploadSiteMediaStatus = (state: RootState) => state.adminOnboarding.uploadSiteMediaStatus;
export const selectSiteMediaUploadProgress = (state: RootState) => state.adminOnboarding.siteMediaUploadProgress;

export const selectSiteWriteSasUri = (state: RootState) => state.adminOnboarding.siteWriteSasUri;
export const selectSiteDeleteSasToken = (state: RootState) => state.adminOnboarding.siteDeleteSasToken;
export const selectSiteReadSasToken = (state: RootState) => state.adminOnboarding.siteReadSasToken;

export const selectSiteCoverImageFile = (state: RootState) => state.adminOnboarding.siteCoverImageFile;

export const {
  retrieveIndustryCategoriesRequested,
  retrieveIndustryCategoriesSucceeded,
  retrieveIndustryCategoriesFailed,
  retrieveIndustrySubcategoriesRequested,
  retrieveIndustrySubcategoriesSucceeded,
  retrieveIndustrySubcategoriesFailed,
  industrySubcategorySelected,
  updateNameAndIndustryStepRequested,
  updateNameAndIndustryStepSucceeded,
  updateNameAndIndustryStepFailed,
  updateIncorporationStepRequested,
  updateIncorporationStepSucceeded,
  updateIncorporationStepFailed,
  upsertSiteDetailsStepRequested,
  upsertSiteDetailsStepSucceeded,
  upsertSiteDetailsStepFailed,
  upsertLocalizationStepRequested,
  upsertLocalizationStepSucceeded,
  upsertLocalizationStepFailed,
  incorporationCountrySelected,
  decrementCompanyActiveStep,
  updateEmployeeIdStepFailed,
  updateEmployeeIdStepRequested,
  updateEmployeeIdStepSucceeded,
  updateSiteCalendarStepFailed,
  updateSiteCalendarStepRequested,
  updateSiteCalendarStepSucceeded,
  decrementSiteActiveStep,
  retrieveWorkingPatternsRequested,
  retrieveWorkingPatternsFailed,
  retrieveWorkingPatternsSucceeded,
  updateSiteWorkingPatternFailed,
  updateSiteWorkingPatternRequested,
  updateSiteWorkingPatternSucceeded,
  importEmployeesStepRequested,
  importEmployeesStepSucceeded,
  importEmployeesStepFailed,
  loadAssignManagersRequested,
  loadAssignManagersSucceeded,
  loadAssignManagersFailed,
  updateEmployeesManagerRequested,
  updateEmployeesManagerSucceeded,
  updateEmployeesManagerFailed,
  changeRegisteredName,
  updateSetupOrganizationStep,
  updateInvitePeopleStep,
  sendEmployeeInvitationEmailFailed,
  sendEmployeeInvitationEmailRequested,
  sendEmployeeInvitationEmailSucceeded,
  retrieveAssignedManagersRequested,
  retrieveAssignedManagersSucceeded,
  retrieveAssignedManagersFailed,
  deleteSiteMedia,
  retrieveSiteReadSasTokenFailed,
  retrieveSiteReadSasTokenRequested,
  retrieveSiteReadSasTokenSucceeded,
  retrieveSiteWriteSasUriFailed,
  retrieveSiteWriteSasUriRequested,
  retrieveSiteWriteSasUriSucceeded,
  siteFileUploaded,
  updateSiteMediaUploadProgress,
  uploadSiteFile,
  uploadSiteMediaFailed,
  uploadSiteMediaRequested,
  uploadSiteMediaSucceeded,
  resetAssignedManagers,
  loadAdminOnboardingFlowRequested,
  loadAdminOnboardingFlowSucceeded,
  loadAdminOnboardingFlowFailed,
  setSiteCoverImageFile,
  resetSiteCoverFile,
  removeSiteCoverImageFile
} = adminOnboardingSlice.actions;
export default adminOnboardingSlice.reducer;
