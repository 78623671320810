import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

export const SiteCardTitle = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'completed'
})<{ completed: boolean }>(({ completed }) => ({
  height: 60,
  overflow: 'hidden',
  fontStyle: completed ? 'italic' : 'normal'
}));
