import React from 'react';

interface SlidersIconProps {
  width?: number;
  height?: number;
  color?: string;
}

export const SlidersIcon: React.FC<SlidersIconProps> = ({ width = 24, height = 29, color = '#251D38' }) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="sliders">
        <path id="Vector" d="M4 24.675V16.6494" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path id="Vector_2" d="M4 12.0632V4.0376" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path id="Vector_3" d="M12 24.6751V14.3564" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path id="Vector_4" d="M12 9.77019V4.0376" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path id="Vector_5" d="M20 24.675V18.9424" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path id="Vector_6" d="M20 14.3563V4.0376" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path id="Vector_7" d="M1 16.6494H7" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path id="Vector_8" d="M9 9.77051H15" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path id="Vector_9" d="M17 18.9424H23" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </g>
    </svg>
  );
};
