import { styled, TableCell, TableCellProps } from '@mui/material';

interface CustomTableCellProps extends TableCellProps {
  noBorder?: boolean;
}

export const TasksListCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== 'width' && prop !== 'noBorder'
})<CustomTableCellProps>(({ width, noBorder, theme }) => {
  return {
    width,
    borderBottom: 0,
    padding: '4px 16px',
    borderRight: noBorder ? 0 : '1px solid',
    borderColor: theme.palette.common.white,
    fontWeight: 700,
    ':last-of-type': {
      borderRight: 0
    }
  };
});
