import { create } from '@bufbuild/protobuf';
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import {
  EmployeeListItem,
  EmployeeListItemSchema,
  RetrieveAllEmployeesResponse,
  TableErbFieldValue,
  TextErbFieldValue,
  UpdateErSectionRequest
} from '@thrivea/organization-client';
import { retrieveAllEmployees } from '@api/employees.api';
import { retrieveEmployeesRequested, retrieveEmployeesSucceeded, retrieveEmployeesFailed, updateEmployee, selectEmployeeListItemById } from '@features/shared';
import { selectErbFieldValueById, updateErSectionSucceeded } from '@features/employee-record-page';
import { PayloadAction } from '@reduxjs/toolkit';
import { ERB_FIELD_DISPLAY_NAME_ID, ERB_FIELD_WORK_HISTORY_ID, WORK_HISTORY_JOB_TITLE_ID } from '@features/employee-record-builder';
import { EmptySchema } from '@bufbuild/protobuf/wkt';

function* retrieveEmployeesRequestedGenerator() {
  try {
    const response: RetrieveAllEmployeesResponse = yield call(retrieveAllEmployees, create(EmptySchema));
    yield put(retrieveEmployeesSucceeded(response));
  } catch (error) {
    yield put(retrieveEmployeesFailed());
  }
}

function* updateErSectionSucceededGenerator(action: PayloadAction<UpdateErSectionRequest>) {
  const { employeeId, changedFieldValues } = action.payload;

  const previousEmployeeListItem: EmployeeListItem = yield select(selectEmployeeListItemById, employeeId);
  const employeeListItem = create(EmployeeListItemSchema, {
    ...previousEmployeeListItem
  });

  const changedDisplayName = changedFieldValues.find((fv) => fv.erbFieldId === ERB_FIELD_DISPLAY_NAME_ID);

  if (changedDisplayName) {
    const displayName = (changedDisplayName.kind.value as TextErbFieldValue).value;
    employeeListItem.displayName = displayName;
  }

  const changedWorkHistory = changedFieldValues.find((fv) => fv.erbFieldId === ERB_FIELD_WORK_HISTORY_ID);

  if (changedWorkHistory) {
    const workHistoryTableValues: TableErbFieldValue = yield select(selectErbFieldValueById, ERB_FIELD_WORK_HISTORY_ID);

    const jobTitle = workHistoryTableValues.rows[0].cells.find((c) => c.erbTableFieldColumnId === WORK_HISTORY_JOB_TITLE_ID)!.value.value as TextErbFieldValue;

    employeeListItem.jobTitle = jobTitle.value;
  }

  yield put(updateEmployee(employeeListItem));
}

function* updateErSectionSucceededWatcher() {
  yield takeLatest(updateErSectionSucceeded.type, updateErSectionSucceededGenerator);
}

function* retrieveEmployeesRequestedWatcher() {
  yield takeLatest(retrieveEmployeesRequested.type, retrieveEmployeesRequestedGenerator);
}

export function* employeesSagas() {
  yield all([fork(retrieveEmployeesRequestedWatcher), fork(updateErSectionSucceededWatcher)]);
}
