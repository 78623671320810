import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { NavLink } from 'react-router';
import { useAppSelector } from '@app/hooks';
import { selectNavigationIsExpanded } from '@features/navigation';
import { AssetsTooltip } from 'src/features/assets';

interface NavListItemProps {
  isDisabled: boolean;
  name: string;
  url: string;
  icon: JSX.Element;
}

export const NavListItem: React.FC<NavListItemProps> = ({ isDisabled, name, url, icon }) => {
  const navigationIsExpanded = useAppSelector(selectNavigationIsExpanded);

  return (
    <ListItemButton
      disabled={isDisabled}
      disableGutters
      component={NavLink}
      to={url}
      sx={{
        height: 40,
        padding: '12px 8px',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '6px',
        backgroundColor: (theme) => theme.palette.common.white,
        '&.active': {
          backgroundColor: (theme) => theme.palette.customTheme.drawerBackground
        },

        transition: (theme) =>
          theme.transitions.create(['background-color'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.complex
          })
      }}
    >
      {!navigationIsExpanded && (
        <AssetsTooltip title={name} placement="right" arrow>
          <ListItemIcon
            className="MuiLinkIcon"
            sx={{
              minWidth: 'auto',
              '& svg': {
                height: '22px'
              },
              marginRight: 0
            }}
          >
            {icon}
          </ListItemIcon>
        </AssetsTooltip>
      )}
      {navigationIsExpanded && (
        <>
          <ListItemIcon
            className="MuiLinkIcon"
            sx={{
              minWidth: 'auto',
              '& svg': {
                height: '22px'
              },
              marginRight: '8px'
            }}
          >
            {icon}
          </ListItemIcon>
          <ListItemText
            sx={{
              '& .MuiTypography-root': {
                fontSize: '16px',
                lineHeight: '24px',
                fontWeight: 500
              }
            }}
          >
            {name}
          </ListItemText>
        </>
      )}
    </ListItemButton>
  );
};
