import { CardContent, CardContentProps, styled } from '@mui/material';

export const SiteCardContent = styled(CardContent)<CardContentProps>(({ theme }) => ({
  backgroundColor: theme.palette.customTheme.contBgr,
  position: 'relative',
  zIndex: 1,
  padding: '21px 24px',
  maxWidth: 316,
  margin: '-30px auto 0',
  borderRadius: theme.spacing(2)
}));
