import { Box, BoxProps, styled } from '@mui/material';

export const SiteWorkingPatternDay = styled(Box)<BoxProps>(({ theme }) => ({
  borderRight: `1px solid ${theme.palette.divider}`,
  backgroundColor: 'transparent',
  padding: 1,
  width: 60,
  height: 40,
  textAlign: 'center',
  fontWeight: 700,
  lineHeight: 1.5
}));
