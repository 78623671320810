import { Chip, chipClasses, ChipProps, styled, TableRow, TableRowProps } from '@mui/material';

interface TaskStatusChipStyleProps {
  backgroundColor: string;
  textColor: string;
}

enum TaskStatus {
  Draft,
  Assigned,
  InReview,
  Completed
}

interface TaskStatusChipProps extends ChipProps {
  status: TaskStatus;
}

export const TaskStatusChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'isAssigned' && prop !== 'isInReview' && prop !== 'isCompleted'
})<TaskStatusChipProps>(({ status, theme }) => {
  // Define an array for row styling based on TaskStatus
  const row: TaskStatusChipStyleProps[] = [
    { backgroundColor: '#BEBABA', textColor: theme.palette.primary.main },
    { backgroundColor: theme.palette.customTheme.selectItem, textColor: theme.palette.primary.main },
    { backgroundColor: 'rgba(252, 199, 93, 0.30)', textColor: theme.palette.primary.main },
    { backgroundColor: theme.palette.secondary.main, textColor: theme.palette.common.white }
  ];

  let idx = TaskStatus.Draft;
  if (status === TaskStatus.Assigned) idx = TaskStatus.Assigned;
  if (status === TaskStatus.InReview) idx = TaskStatus.InReview;
  if (status === TaskStatus.Completed) idx = TaskStatus.Completed;

  return {
    backgroundColor: row[idx].backgroundColor,
    color: row[idx].textColor,
    width: 90,
    height: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '3px 7px',
    borderRadius: 8,
    [`& .${chipClasses.label}`]: {
      fontSize: 12,
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: 'normal',
      padding: 0
    }
  };
});
