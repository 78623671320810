import { createClient } from '@connectrpc/connect';
import { createGrpcWebTransport } from '@connectrpc/connect-web';
import {
  AssetsService,
  CopyFilesRequest,
  CreateFolderRequest,
  EditDescriptionRequest,
  HardDeleteFilesRequest,
  MoveFilesRequest,
  PinFolderRequest,
  RenameFileRequest,
  RestoreFilesRequest,
  RetrieveFileActivitiesRequest,
  RetrieveFileActivitiesResponse,
  RetrieveFilesAccessRequest,
  RetrieveFilesAccessResponse,
  RetrieveFilesInfoRequest,
  RetrieveFilesInfoResponse,
  RetrieveFilesRequest,
  RetrieveFilesResponse,
  RetrieveRecentFilesRequest,
  RetrieveSharedFilesRequest,
  RetrieveStarredFilesRequest,
  RetrieveTotalStorageUsageRequest,
  RetrieveTotalStorageUsageResponse,
  RetrieveTrashBinFilesRequest,
  RetrieveTrashBinFilesResponse,
  RevokeAccessForAllRequest,
  ShareFileRequest,
  SoftDeleteFilesRequest,
  StarFileRequest,
  UploadFilesRequest
} from '@thrivea/organization-client';
import { msalInstance } from '@/main';
import { tokenRequest } from '@utils/authConfig';
import { Empty } from '@bufbuild/protobuf/wkt';

const assetsClient = createClient(
  AssetsService,
  createGrpcWebTransport({
    baseUrl: import.meta.env.VITE_APP_API_URL,
    useBinaryFormat: true
  })
);

export const retrieveFiles = async (request: RetrieveFilesRequest): Promise<RetrieveFilesResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await assetsClient.retrieveFiles(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveSharedFiles = async (request: RetrieveSharedFilesRequest): Promise<RetrieveFilesResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await assetsClient.retrieveSharedFiles(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveRecentFiles = async (request: RetrieveRecentFilesRequest): Promise<RetrieveFilesResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await assetsClient.retrieveRecentFiles(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveStarredFiles = async (request: RetrieveStarredFilesRequest): Promise<RetrieveFilesResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await assetsClient.retrieveStarredFiles(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const uploadFiles = async (request: UploadFilesRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await assetsClient.uploadFiles(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveFilesInfo = async (request: RetrieveFilesInfoRequest): Promise<RetrieveFilesInfoResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await assetsClient.retrieveFilesInfo(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const copyFiles = async (request: CopyFilesRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await assetsClient.copyFiles(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const createFolder = async (request: CreateFolderRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await assetsClient.createFolder(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const editDescription = async (request: EditDescriptionRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await assetsClient.editDescription(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const renameFile = async (request: RenameFileRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await assetsClient.renameFile(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const softDeleteFiles = async (request: SoftDeleteFilesRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await assetsClient.softDeleteFiles(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const moveFiles = async (request: MoveFilesRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await assetsClient.moveFiles(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const starFile = async (request: StarFileRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await assetsClient.starFile(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveTrashBinFiles = async (request: RetrieveTrashBinFilesRequest): Promise<RetrieveTrashBinFilesResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await assetsClient.retrieveTrashBinFiles(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveFilesAccess = async (request: RetrieveFilesAccessRequest): Promise<RetrieveFilesAccessResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await assetsClient.retrieveFilesAccess(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const shareFiles = async (request: ShareFileRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await assetsClient.shareFile(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const revokeAccessForAll = async (request: RevokeAccessForAllRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await assetsClient.revokeAccessForAll(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const restoreFiles = async (request: RestoreFilesRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await assetsClient.restoreFiles(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const hardDeleteFiles = async (request: HardDeleteFilesRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await assetsClient.hardDeleteFiles(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const pinFolder = async (request: PinFolderRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await assetsClient.pinFolder(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveFileActivities = async (request: RetrieveFileActivitiesRequest): Promise<RetrieveFileActivitiesResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await assetsClient.retrieveFileActivities(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveTotalStorageUsage = async (request: RetrieveTotalStorageUsageRequest): Promise<RetrieveTotalStorageUsageResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await assetsClient.retrieveTotalStorageUsage(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};
