import { Stack, StackProps, styled } from '@mui/material';

export const TasksHeaderStack = styled(Stack)<StackProps>(() => ({
  position: 'relative',
  width: '100%',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '16px 16px 0'
}));
