import { Fragment, useState } from 'react';
import { Stack, AvatarGroup, TableCell, Link, Collapse, IconButton } from '@mui/material';
import { startCase } from 'lodash';
import {
  RowCenterStack,
  StyledCheckbox,
  ThinBorderCheckbox,
  TicketIcon,
  LinkIcon,
  CommentIcon,
  CirclePlusIcon,
  CircleDotIcon,
  ChevronDownIcon,
  BarsProgressIcon,
  EclipsedTypography
} from '@/shared';
import {
  TasksListRow,
  TasksListCell,
  ListTaskDescription,
  TaskAvatar,
  TaskStatusChip,
  TasksListTaskRow,
  TaskActionMenu,
  TasksCollapseButton
} from '@features/tasks';
import { useAppDispatch } from '@app/hooks';

enum TaskStatus {
  Draft,
  Assigned,
  InReview,
  Completed
}

const tempTaskListData = [
  {
    taskID: '#M9N001-P2',
    taskTitle: 'Enhancing the onboarding experience for new team members',
    reviewer: 'None',
    dueDate: '12/12/2025 | 12:30',
    status: TaskStatus.Assigned
  },
  {
    taskID: '#M9N002-P2',
    taskTitle: 'Implementing a new performance review system',
    reviewer: 'Alex Johnson',
    dueDate: '11/22/2025 | 10:00',
    status: TaskStatus.InReview
  }
];

export const TasksListTasks = () => {
  const dispatch = useAppDispatch();

  const [isCollapsed, setIsCollapsed] = useState(true);

  return (
    <Fragment>
      <TasksListTaskRow status={TaskStatus.Assigned}>
        <TasksListCell colSpan={9}>Task List: Stevo Cvijetic Onobarding Process</TasksListCell>
      </TasksListTaskRow>
      <TasksListTaskRow status={TaskStatus.Assigned}>
        <TasksListCell>
          <RowCenterStack sx={{ gap: '20px', position: 'relative', width: '100%' }}>
            <StyledCheckbox
              indeterminate={false}
              onChange={() => {}}
              icon={
                <RowCenterStack sx={{ width: 62, justifyContent: 'space-between' }}>
                  <ThinBorderCheckbox sx={{ fontSize: 28 }} />
                  <BarsProgressIcon color="#251D38" width={20} height={18} />
                </RowCenterStack>
              }
            />
            <Stack sx={{ width: '100%' }}>
              <ListTaskDescription sx={{ width: 242 }}>Stevo Cvijetic Onobarding Process</ListTaskDescription>
            </Stack>
          </RowCenterStack>
        </TasksListCell>
        <TasksListCell>
          <TaskAvatar src={'https://thispersondoesnotexist.com/'}>Idk</TaskAvatar>
        </TasksListCell>
        <TasksListCell>
          <AvatarGroup
            max={3}
            variant="rounded"
            spacing={-(-17)}
            sx={{
              '.MuiAvatar-root': {
                border: (theme) => `1px solid ${theme.palette.common.black}`,
                width: 34,
                height: 34
              },
              '.MuiAvatarGroup-avatar': {
                position: 'relative',
                ':first-of-type': {
                  zIndex: (theme) => theme.zIndex.tooltip
                }
              }
            }}
            slotProps={{}}
          >
            <TaskAvatar position={1} alt="Remy Sharp" src={'https://thispersondoesnotexist.com/'} />
            <TaskAvatar position={2} alt="Travis Howard" src={'https://thispersondoesnotexist.com/'} />
            <TaskAvatar position={3} alt="Cindy Baker" src={'https://thispersondoesnotexist.com/'} />
            <TaskAvatar position={4} alt="Agnes Walker" src={'https://thispersondoesnotexist.com/'} />
            <TaskAvatar position={5} alt="Trevor Henderson" src={'https://thispersondoesnotexist.com/'} />
          </AvatarGroup>
        </TasksListCell>
        <TasksListCell>
          <AvatarGroup
            max={3}
            variant="rounded"
            spacing={-(-17)}
            sx={{
              '.MuiAvatar-root': {
                border: (theme) => `1px solid ${theme.palette.common.black}`,
                width: 34,
                height: 34
              },
              '.MuiAvatarGroup-avatar': {
                position: 'relative',
                ':first-of-type': {
                  zIndex: (theme) => theme.zIndex.tooltip
                }
              }
            }}
            slotProps={{}}
          >
            <TaskAvatar position={1} alt="Remy Sharp" src={'https://thispersondoesnotexist.com/'} />
            <TaskAvatar position={2} alt="Travis Howard" src={'https://thispersondoesnotexist.com/'} />
            <TaskAvatar position={3} alt="Cindy Baker" src={'https://thispersondoesnotexist.com/'} />
            <TaskAvatar position={4} alt="Agnes Walker" src={'https://thispersondoesnotexist.com/'} />
            <TaskAvatar position={5} alt="Trevor Henderson" src={'https://thispersondoesnotexist.com/'} />
          </AvatarGroup>
        </TasksListCell>
        <TasksListCell>12/12/2025 | 12:30</TasksListCell>
        <TasksListCell noBorder sx={{ width: 60 }}>
          <RowCenterStack gap={'2px'}>
            <ListTaskDescription>4</ListTaskDescription>
            <TicketIcon width={16} height={16} />
          </RowCenterStack>
        </TasksListCell>
        <TasksListCell noBorder sx={{ width: 60 }}>
          <RowCenterStack gap={'2px'}>
            <ListTaskDescription>2</ListTaskDescription>
            <LinkIcon width={16} height={16} />
          </RowCenterStack>
        </TasksListCell>
        <TasksListCell sx={{ width: 60 }}>
          <RowCenterStack gap={'2px'}>
            <ListTaskDescription>2</ListTaskDescription>
            <CommentIcon size={16} />
          </RowCenterStack>
        </TasksListCell>
        <TasksListCell sx={{ border: 0 }}>
          <RowCenterStack>
            <TaskStatusChip status={TaskStatus.Assigned} label={startCase(TaskStatus[TaskStatus.Assigned])} />
            <TaskActionMenu taskId={'a97aa3bd-07e1-497f-8d34-8a27759481c8'} />
          </RowCenterStack>
        </TasksListCell>
        <TableCell sx={{ width: 60, padding: '0', backgroundColor: (theme) => theme.palette.customTheme.bgr, border: 0 }}>
          <Stack gap={'8px'} sx={{ alignItems: 'flex-start' }}>
            <CirclePlusIcon width={16} height={16} />
            <IconButton sx={{ padding: 0, gap: '4px' }}>
              <CircleDotIcon width={16} height={16} />
            </IconButton>
          </Stack>
        </TableCell>
      </TasksListTaskRow>
      <TasksListTaskRow status={TaskStatus.Assigned}>
        <TableCell colSpan={10} sx={{ padding: 0, paddingLeft: '28px', border: 0 }}>
          <Collapse in={isCollapsed} timeout={'auto'} unmountOnExit>
            {tempTaskListData.map((task) => (
              <Fragment key={task.taskID}>
                <TasksListRow status={task.status}>
                  <TasksListCell sx={{ width: 330 }}>
                    <RowCenterStack sx={{ gap: '20px', position: 'relative', width: '100%', paddingLeft: 2 }}>
                      <RowCenterStack gap={1} sx={{ justifyContent: 'space-between', position: 'relative' }}>
                        <Stack
                          sx={{
                            marginTop: -5
                          }}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="46" viewBox="0 0 25 46" fill="none">
                            <path d="M25 45.5H5C2.79086 45.5 1 43.7091 1 41.5V0.5" stroke="#251D38" />
                          </svg>
                          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="10" viewBox="0 0 25 10" fill="none">
                            <path d="M25 0.5H5C2.79086 0.5 1 2.29086 1 4.5V9.5" stroke="#251D38" />
                          </svg>
                        </Stack>
                        <TicketIcon color="#251D38" width={20} height={18} />
                      </RowCenterStack>
                      <Stack sx={{ width: 210 }}>
                        <Link sx={{ fontWeight: 400 }}>{task.taskID}</Link>
                        <EclipsedTypography isBolded>{task.taskTitle}</EclipsedTypography>
                      </Stack>
                    </RowCenterStack>
                  </TasksListCell>
                  <TasksListCell sx={{ width: 110 }}>
                    <TaskAvatar src={'https://thispersondoesnotexist.com/'}>Idk</TaskAvatar>
                  </TasksListCell>
                  <TasksListCell sx={{ width: 110 }}>
                    <AvatarGroup
                      max={3}
                      variant="rounded"
                      spacing={-(-17)}
                      sx={{
                        '.MuiAvatar-root': {
                          border: (theme) => `1px solid ${theme.palette.common.black}`,
                          width: 34,
                          height: 34
                        },
                        '.MuiAvatarGroup-avatar': {
                          position: 'relative',
                          ':first-of-type': {
                            zIndex: (theme) => theme.zIndex.tooltip
                          }
                        }
                      }}
                      slotProps={{}}
                    >
                      <TaskAvatar position={1} alt="Remy Sharp" src={'https://thispersondoesnotexist.com/'} />
                      <TaskAvatar position={2} alt="Travis Howard" src={'https://thispersondoesnotexist.com/'} />
                      <TaskAvatar position={3} alt="Cindy Baker" src={'https://thispersondoesnotexist.com/'} />
                      <TaskAvatar position={4} alt="Agnes Walker" src={'https://thispersondoesnotexist.com/'} />
                      <TaskAvatar position={5} alt="Trevor Henderson" src={'https://thispersondoesnotexist.com/'} />
                    </AvatarGroup>
                  </TasksListCell>
                  <TasksListCell sx={{ width: 110 }}>{task.reviewer}</TasksListCell>
                  <TasksListCell sx={{ width: 160 }}>{task.dueDate}</TasksListCell>
                  <TasksListCell noBorder sx={{ width: 60 }}></TasksListCell>
                  <TasksListCell noBorder sx={{ width: 60 }}>
                    <RowCenterStack gap={'2px'}>
                      <ListTaskDescription>2</ListTaskDescription>
                      <LinkIcon width={16} height={16} />
                    </RowCenterStack>
                  </TasksListCell>
                  <TasksListCell sx={{ width: 60 }}>
                    <RowCenterStack gap={'2px'}>
                      <ListTaskDescription>2</ListTaskDescription>
                      <CommentIcon size={16} />
                    </RowCenterStack>
                  </TasksListCell>
                  <TasksListCell sx={{ width: 155, border: 0 }}>
                    <RowCenterStack>
                      <TaskStatusChip status={task.status} label={startCase(TaskStatus[task.status])} />
                      <TaskActionMenu taskId={task.taskID} />
                    </RowCenterStack>
                  </TasksListCell>
                  <TableCell sx={{ width: 60, padding: '0', backgroundColor: (theme) => theme.palette.customTheme.bgr, border: 0 }}>
                    <Stack gap={'8px'} sx={{ alignItems: 'flex-start' }}>
                      <CirclePlusIcon width={16} height={16} />
                      <IconButton sx={{ padding: 0, gap: '4px' }}>
                        <CircleDotIcon width={16} height={16} />
                      </IconButton>
                    </Stack>
                  </TableCell>
                </TasksListRow>
              </Fragment>
            ))}
          </Collapse>
        </TableCell>
      </TasksListTaskRow>
      <TasksListTaskRow status={TaskStatus.Assigned}>
        <TasksListCell colSpan={9}>
          <TasksCollapseButton isCollapsed={isCollapsed} onClick={() => setIsCollapsed(!isCollapsed)}>
            <ChevronDownIcon />
            <ListTaskDescription>Task 1/4</ListTaskDescription>
          </TasksCollapseButton>
        </TasksListCell>
      </TasksListTaskRow>
    </Fragment>
  );
};
