import { Stack, styled } from '@mui/material';

export const InvoicesCenterStack = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  width: '100%',
  minHeight: 'unset',
  backgroundColor: theme.palette.customTheme.drawerBackground,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(4),
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  borderBottomLeftRadius: 10,
  borderBottomRightRadius: 10,
  position: 'sticky',
  left: 0,
  [theme.breakpoints.up('md')]: {
    justifyContent: 'space-between'
  }
}));
