import { SyntheticEvent } from 'react';
import { Avatar, Tabs, tabsClasses, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { changeTasksScope, selectTasksScopeMode } from '@features/tasks';
import { FilterTab } from '@features/people-directory';
import { PeopleFilledIcon, PictureSizeSuffix, RowCenterStack } from '@/shared';
import { selectCurrentUserInitials, selectCurrentUserProfilePictureUrl } from '@app/user';
import { selectEmployeeProfileAndCoverReadSasToken } from '@/features/employee-profile';
import { pictureUrl } from '@/utils';
import { TaskScope } from '@thrivea/organization-client';

export const TasksTabs = () => {
  const dispatch = useAppDispatch();

  const scope = useAppSelector(selectTasksScopeMode);

  const currentUserInitials = useAppSelector(selectCurrentUserInitials);
  const currentUserProfilePicture = useAppSelector(selectCurrentUserProfilePictureUrl);
  const employeeProfileSasToken = useAppSelector(selectEmployeeProfileAndCoverReadSasToken);

  const handleTaskViewTabsChange = (_event: SyntheticEvent, newValue: TaskScope) => {
    dispatch(changeTasksScope(newValue));
  };

  return (
    <Tabs
      value={scope}
      variant="scrollable"
      scrollButtons="auto"
      onChange={handleTaskViewTabsChange}
      sx={{
        minHeight: 43,
        borderRadius: 2,
        maxWidth: '90%',
        justifyContent: 'left',
        [`& .${tabsClasses.flexContainer}`]: {
          gap: '8px'
        }
      }}
    >
      <FilterTab
        value={TaskScope.ALL_TASKS}
        label={
          <RowCenterStack gap={'12px'}>
            <PeopleFilledIcon />
            <Typography sx={{ fontWeight: 700, fontSize: 16 }}> All tasks </Typography>
          </RowCenterStack>
        }
      />
      <FilterTab
        value={TaskScope.MY_TASKS}
        label={
          <RowCenterStack gap={2}>
            <Avatar
              sx={{
                width: 34,
                height: 34,
                borderRadius: '6px',
                border: '1px solid',
                borderColor: (theme) => theme.palette.common.black
              }}
              src={pictureUrl(currentUserProfilePicture, employeeProfileSasToken, PictureSizeSuffix.sm)}
              alt="Profile image"
            >
              {currentUserInitials}
            </Avatar>
            <Typography sx={{ fontWeight: 700, fontSize: 16 }}>My tasks </Typography>
          </RowCenterStack>
        }
      />
    </Tabs>
  );
};
