import { styled } from '@mui/material';
import { Link } from 'react-router';

export const MenuLink = styled(Link)(({ theme }) => ({
  width: '100%',
  fontSize: 14,
  fontWeight: 400,
  padding: 0,
  textDecoration: 'none',
  color: theme.palette.text.primary
}));
