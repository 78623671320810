import { styled } from '@mui/material';
import Grid, { Grid2Props } from '@mui/material/Grid2';

export const BoardGridItem = styled(Grid)<Grid2Props>(({ theme }) => ({
  position: 'relative',
  padding: '12px 8px',
  maxHeight: 'calc(100vh - 234px)',
  height: '100%',
  overflowY: 'scroll',
  backgroundColor: theme.palette.customTheme.primaryA,
  borderRadius: '8px'
}));
