import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import {
  Department,
  EmployeeListItem,
  EmployeeRecord,
  ErbFieldValue,
  RetrieveDepartmentsResponse,
  RetrieveEmployeeRecordRequest,
  RetrieveEmployeesByQueryRequest,
  RetrieveEmployeesByQueryResponse,
  RetrieveSitesResponse,
  Site,
  UpdateErSectionRequest
} from '@thrivea/organization-client';
import { RootState } from '@app/store';
import { ActionStatus } from 'src/shared';
import { getScalarFieldValue } from '@features/employee-record-page';
import { selectErbScalarFieldsBySectionId } from '@features/employee-record-builder';
import { keyBy, map, mapValues } from 'lodash';

export interface EmployeeRecordState {
  entities: {
    employeeRecordFieldValues: {
      byId: { [key: string]: ErbFieldValue };
      allIds: string[];
    };
    employeeItems: {
      byId: { [key: string]: EmployeeListItem };
      allIds: string[];
    };
    departments: {
      byId: { [key: string]: Department };
      allIds: string[];
    };
    sites: {
      byId: { [key: string]: Site };
      allIds: string[];
    };
  };
  ui: {
    retrieveEmployeeRecordRequestedStatus: ActionStatus;
    retrieveDepartmentsRequestedStatus: ActionStatus;
    retrieveSitesRequestedStatus: ActionStatus;
    updateErSectionRequestedStatus: ActionStatus;
    retrieveEmployeeItemsRequestedStatus: ActionStatus;
  };
}

const initialState: EmployeeRecordState = {
  entities: {
    employeeRecordFieldValues: {
      byId: {},
      allIds: []
    },
    employeeItems: {
      byId: {},
      allIds: []
    },
    departments: {
      byId: {},
      allIds: []
    },
    sites: {
      byId: {},
      allIds: []
    }
  },
  ui: {
    retrieveEmployeeRecordRequestedStatus: ActionStatus.Idle,
    retrieveDepartmentsRequestedStatus: ActionStatus.Idle,
    retrieveSitesRequestedStatus: ActionStatus.Idle,
    updateErSectionRequestedStatus: ActionStatus.Idle,
    retrieveEmployeeItemsRequestedStatus: ActionStatus.Idle
  }
};

export const employeeRecordSlice = createSlice({
  name: 'peopleDir',
  initialState,
  reducers: {
    retrieveEmployeeRecordRequested: (state, _action: PayloadAction<RetrieveEmployeeRecordRequest>) => {
      state.ui.retrieveEmployeeRecordRequestedStatus = ActionStatus.Pending;
    },
    retrieveEmployeeRecordSucceeded: (state, action: PayloadAction<EmployeeRecord>) => {
      const { fieldValues } = action.payload;

      for (const value of fieldValues) {
        state.entities.employeeRecordFieldValues.byId[value.erbFieldId] = value;
        state.entities.employeeRecordFieldValues.allIds.push(value.erbFieldId);
      }

      state.ui.retrieveEmployeeRecordRequestedStatus = ActionStatus.Idle;
    },
    retrieveEmployeeRecordFailed: (state) => {
      state.ui.retrieveEmployeeRecordRequestedStatus = ActionStatus.Failed;
    },
    updateErSectionRequested: (state, _action: PayloadAction<UpdateErSectionRequest>) => {
      state.ui.updateErSectionRequestedStatus = ActionStatus.Pending;
    },
    updateErSectionSucceeded: (state) => {
      state.ui.updateErSectionRequestedStatus = ActionStatus.Idle;
    },
    updateErSectionFailed: (state) => {
      state.ui.updateErSectionRequestedStatus = ActionStatus.Failed;
    },
    retrieveEmployeeItemsRequested: (state, _action: PayloadAction<RetrieveEmployeesByQueryRequest>) => {
      state.ui.retrieveEmployeeItemsRequestedStatus = ActionStatus.Pending;
    },
    retrieveEmployeeItemsSucceeded: (state, action: PayloadAction<RetrieveEmployeesByQueryResponse>) => {
      const employees = action.payload.employeeResults!.employees.filter((e) => !state.entities.employeeItems.byId.hasOwnProperty(e.employeeId));

      for (const employee of employees) {
        state.entities.employeeItems.byId[employee.employeeId] = employee;
        state.entities.employeeItems.allIds.push(employee.employeeId);
      }

      state.ui.retrieveEmployeeItemsRequestedStatus = ActionStatus.Idle;
    },
    retrieveEmployeeItemsFailed: (state) => {
      state.ui.retrieveEmployeeItemsRequestedStatus = ActionStatus.Failed;
    },
    retrieveDepartmentsRequested: (state) => {
      state.ui.retrieveDepartmentsRequestedStatus = ActionStatus.Pending;
    },
    retrieveDepartmentsSucceeded: (state, action: PayloadAction<RetrieveDepartmentsResponse>) => {
      const departments = action.payload.departments.filter((d) => !state.entities.departments.byId.hasOwnProperty(d.id));

      for (const department of departments) {
        state.entities.departments.byId[department.id] = department;
        state.entities.departments.allIds.push(department.id);
      }

      state.ui.retrieveDepartmentsRequestedStatus = ActionStatus.Idle;
    },
    retrieveDepartmentsFailed: (state) => {
      state.ui.retrieveDepartmentsRequestedStatus = ActionStatus.Failed;
    },
    retrieveSitesRequested: (state) => {
      state.ui.retrieveSitesRequestedStatus = ActionStatus.Pending;
    },
    retrieveSitesSucceeded: (state, action: PayloadAction<RetrieveSitesResponse>) => {
      const sites = action.payload.sites.filter((d) => !state.entities.sites.byId.hasOwnProperty(d.id));

      for (const site of sites) {
        state.entities.sites.byId[site.id] = site;
        state.entities.sites.allIds.push(site.id);
      }

      state.ui.retrieveSitesRequestedStatus = ActionStatus.Idle;
    },
    retrieveSitesFailed: (state) => {
      state.ui.retrieveSitesRequestedStatus = ActionStatus.Failed;
    }
  }
});

const selectErbFieldValuesById = (state: RootState) => state.employeeRecord.entities.employeeRecordFieldValues.byId;
const selectErbFieldValuesIds = (state: RootState) => state.employeeRecord.entities.employeeRecordFieldValues.allIds;

const selectErbFieldValues = createSelector([selectErbFieldValuesById, selectErbFieldValuesIds], (byId, ids) => ids.map((id) => byId[id]));

export const selectErbFieldValueById = (state: RootState, fieldId: string) => state.employeeRecord.entities.employeeRecordFieldValues.byId[fieldId];

export const selectRetrieveEmployeeRecordRequestedStatus = (state: RootState) => state.employeeRecord.ui.retrieveEmployeeRecordRequestedStatus;

export const selectErbScalarFieldDefaultValuesBySectionId = createSelector(
  [selectErbFieldValuesById, selectErbScalarFieldsBySectionId, selectErbFieldValues],
  (fieldValuesById, erbFieldsInSection, erbFieldValues) => {
    const erbFieldsAndValues = map(erbFieldsInSection, (field) => ({
      erbScalarField: field!,
      erbFieldValue: fieldValuesById[field!.id]
    }));

    return mapValues(
      keyBy(erbFieldsAndValues, (fav) => fav.erbScalarField.id),
      (fav) => getScalarFieldValue(fav.erbScalarField, erbFieldValues)
    );
  }
);

const selectEmployeeItemsById = (state: RootState) => state.employeeRecord.entities.employeeItems.byId;
const selectEmployeeItemsIds = (state: RootState) => state.employeeRecord.entities.employeeItems.allIds;
export const selectRetrieveEmployeeItemsRequestedStatus = (state: RootState) => state.employeeRecord.ui.retrieveEmployeeItemsRequestedStatus;

export const selectEmployeeItems = createSelector([selectEmployeeItemsById, selectEmployeeItemsIds], (byId, ids) => ids.map((id) => byId[id]));

const selectDepartmentsById = (state: RootState) => state.employeeRecord.entities.departments.byId;
const selectDepartmentsIds = (state: RootState) => state.employeeRecord.entities.departments.allIds;
export const selectRetrieveDepartmentsRequestedStatus = (state: RootState) => state.employeeRecord.ui.retrieveDepartmentsRequestedStatus;

export const selectDepartments = createSelector([selectDepartmentsById, selectDepartmentsIds], (byId, ids) => ids.map((id) => byId[id]));

const selectSitesById = (state: RootState) => state.employeeRecord.entities.sites.byId;
const selectSitesIds = (state: RootState) => state.employeeRecord.entities.sites.allIds;
export const selectRetrieveSitesRequestedStatus = (state: RootState) => state.employeeRecord.ui.retrieveSitesRequestedStatus;

export const selectSites = createSelector([selectSitesById, selectSitesIds], (byId, ids) => ids.map((id) => byId[id]));

export const {
  retrieveEmployeeRecordRequested,
  retrieveEmployeeRecordSucceeded,
  retrieveEmployeeRecordFailed,
  updateErSectionRequested,
  updateErSectionSucceeded,
  updateErSectionFailed,
  retrieveEmployeeItemsRequested,
  retrieveEmployeeItemsSucceeded,
  retrieveEmployeeItemsFailed,
  retrieveDepartmentsRequested,
  retrieveDepartmentsSucceeded,
  retrieveDepartmentsFailed,
  retrieveSitesRequested,
  retrieveSitesSucceeded,
  retrieveSitesFailed
} = employeeRecordSlice.actions;

export default employeeRecordSlice.reducer;
