import { create } from '@bufbuild/protobuf';
import { Stack, Typography, IconButton } from '@mui/material';
import { FileAccessRole, RevokeAccessForAllRequestSchema } from '@thrivea/organization-client';
import { t } from 'i18next';
import { initials, pictureUrl } from '@/utils';
import { AssetsSharePeopleIcon, CloseIcon, GenericSelect, PictureSizeSuffix, PrimaryTypographyBodyBold, RowCenterStack, StyledAvatar } from '@/shared';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { selectEmployeeProfileAndCoverReadSasToken } from '@features/employee-profile';
import {
  AssetsTooltip,
  removeEmployeeFromShareList,
  revokeAccessForAllRequested,
  selectAccessItems,
  selectAssetsDrawerFileItem,
  selectAssetsDrawerFolderItem,
  updateEmployeeAccessRole
} from '@features/assets';
import { selectCurrentEmployeeId } from '@features/shared';
import { useState } from 'react';
import { capitalize } from 'lodash';
import { openDrawer, DrawerType, OpenDrawerRequest } from '@features/drawer';

const fileAccessRoleOptions = Object.values(FileAccessRole)
  .filter((value) => typeof value === 'number') // Keep only numeric enum values
  .slice(1)
  .map((value) => ({
    label: FileAccessRole[value as FileAccessRole], // Get the name from the enum
    value: value as number // Ensure the value is a number
  }));

export const AssetsShareEmployeeList = () => {
  const dispatch = useAppDispatch();

  const accessItems = useAppSelector(selectAccessItems);
  const employeeProfileSasToken = useAppSelector(selectEmployeeProfileAndCoverReadSasToken);
  const currentEmployeeId = useAppSelector(selectCurrentEmployeeId);
  const assetsDrawerFileItem = useAppSelector(selectAssetsDrawerFileItem);
  const assetsDrawerFolderItem = useAppSelector(selectAssetsDrawerFolderItem);

  const [roles, setRoles] = useState<Record<string, FileAccessRole>>({});

  const handleRetrieveSingleEmployeeInfo = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, employeeId: string) => {
    e.stopPropagation();
    e.preventDefault();

    dispatch(openDrawer({ type: DrawerType.SingleEmployee, request: { employeeId } } as OpenDrawerRequest));
  };

  return (
    <Stack
      gap={2}
      sx={{
        backgroundColor: (theme) => theme.palette.grey[600],
        padding: 2,
        mt: 2,
        borderRadius: (theme) => theme.spacing(2)
      }}
    >
      <RowCenterStack sx={{ width: '100%', justifyContent: 'space-between' }}>
        <Stack gap={1} sx={{ width: 'calc(100% - 40px)' }}>
          <PrimaryTypographyBodyBold sx={{ fontSize: 24 }}>{t('sharing_title', { ns: 'assets' })}</PrimaryTypographyBodyBold>
          <Typography>{t('sharing_desc', { ns: 'assets' })}</Typography>
        </Stack>
        <AssetsTooltip title={t('revoke_access_all', { ns: 'assets' })}>
          {/* TODO  this will update store instead of calling api (remove saga) */}
          <IconButton
            sx={{ padding: 0, margin: 0 }}
            onClick={() =>
              dispatch(
                revokeAccessForAllRequested(
                  create(RevokeAccessForAllRequestSchema, {
                    fileId: assetsDrawerFileItem.id ? assetsDrawerFileItem.id : assetsDrawerFolderItem.id
                  })
                )
              )
            }
          >
            <AssetsSharePeopleIcon width={24} height={24} />
          </IconButton>
        </AssetsTooltip>
      </RowCenterStack>
      <Stack gap={1}>
        {accessItems.map((employee) => (
          <RowCenterStack
            key={employee.id}
            sx={{
              padding: 0,
              borderRadius: '12px',
              backgroundColor: 'transparent',
              justifyContent: 'space-between'
            }}
          >
            <RowCenterStack gap={1}>
              <StyledAvatar
                onClick={(e) => handleRetrieveSingleEmployeeInfo(e, employee.id)}
                src={pictureUrl(employee.profilePicture, employeeProfileSasToken, PictureSizeSuffix.sm)}
                width={34}
                height={34}
              >
                {initials(employee.name)}
              </StyledAvatar>
              <Stack
                sx={{
                  alignItems: 'flex-start',
                  maxWidth: 150
                }}
              >
                <PrimaryTypographyBodyBold>
                  {employee.name} {currentEmployeeId === employee.id && '(You)'}
                </PrimaryTypographyBodyBold>
                <Typography>{employee.jobTitle}</Typography>
              </Stack>
            </RowCenterStack>

            <RowCenterStack gap={1}>
              <GenericSelect<number>
                options={fileAccessRoleOptions}
                value={employee.accessRole} // Default role
                onChange={(value) => {
                  setRoles((prev) => ({
                    ...prev,
                    [employee.id]: value as FileAccessRole
                  }));

                  dispatch(
                    updateEmployeeAccessRole({
                      employeeId: employee.id,
                      role: value as FileAccessRole
                    })
                  );
                }}
                renderValue={(value) => capitalize(FileAccessRole[value])}
                displayEmpty
              />

              <AssetsTooltip title="Remove from list" arrow>
                <IconButton sx={{ padding: 0 }} onClick={() => dispatch(removeEmployeeFromShareList(employee.id))}>
                  <CloseIcon width={24} height={24} />
                </IconButton>
              </AssetsTooltip>
            </RowCenterStack>
          </RowCenterStack>
        ))}
      </Stack>
    </Stack>
  );
};
