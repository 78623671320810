import { Empty } from '@bufbuild/protobuf';
import { createClient } from '@connectrpc/connect';
import { createGrpcWebTransport } from '@connectrpc/connect-web';
import {
  RetrieveOrganizationResponse,
  SettingsService,
  UpdateOrganizationRequest,
  RetrieveSitesResponse,
  RetrieveCalendarsResponse,
  ImportEmployeesRequest
} from '@thrivea/organization-client';
import { msalInstance } from '@/main';
import { tokenRequest } from '@utils/authConfig';

export const settingsClient = createClient(
  SettingsService,
  createGrpcWebTransport({
    baseUrl: import.meta.env.VITE_APP_API_URL,
    useBinaryFormat: true
  })
);

export const retrieveOrganization = async (request: Empty): Promise<RetrieveOrganizationResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await settingsClient.retrieveOrganization(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const updateOrganization = async (request: UpdateOrganizationRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await settingsClient.updateOrganization(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveSites = async (request: Empty): Promise<RetrieveSitesResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await settingsClient.retrieveSites(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveCalendars = async (request: Empty): Promise<RetrieveCalendarsResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await settingsClient.retrieveCalendars(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const importEmployees = async (request: ImportEmployeesRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await settingsClient.importEmployees(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};
