import { ErTemplateField } from '@thrivea/organization-client';
import { CalculatedIcon, HistoryTableFieldIcon, RegularFieldIcon, TableFieldIcon } from '@/shared';

interface EmployeeRecordFieldIconProps {
  field: ErTemplateField;
  isSelected: boolean;
}

export const EmployeeRecordFieldIcon: React.FC<EmployeeRecordFieldIconProps> = ({ field, isSelected }) => {
  if (field.kind.case === 'tableField') {
    if (field.kind.value.erbTableField!.isHistorical) {
      return <HistoryTableFieldIcon isSelected={isSelected} />;
    }
    return <TableFieldIcon isSelected={isSelected} />;
  }
  if (field.kind.case === 'scalarField' && field.kind.value.erbScalarField!.isCalculated) {
    return <CalculatedIcon isSelected={isSelected} />;
  }
  return <RegularFieldIcon width={16} height={16} isSelected={isSelected} />;
};
