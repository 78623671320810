import { create } from '@bufbuild/protobuf';
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import {
  retrieveAudienceGroupsSucceeded,
  retrieveAudienceGroupsFailed,
  retrieveAudienceGroupsRequested,
  createAudienceSucceeded,
  createAudienceFailed,
  createAudienceRequested,
  deleteAudienceSucceeded,
  deleteAudienceFailed,
  deleteAudienceRequested,
  retrieveAudienceGroupAutocompleteItemsRequested,
  addSelectedEmployeeToAudienceGroupRequested,
  retrieveAudienceByIdSucceeded,
  retrieveAudienceByIdFailed,
  retrieveAudienceByIdRequested,
  updateAudienceSucceeded,
  updateAudienceFailed,
  updateAudienceRequested,
  LoadAudienceEmployeesRequest,
  loadAudienceGroupMembersRequested,
  retrieveAudienceGroupAutocompleteItemsSucceeded,
  retrieveAudienceGroupAutocompleteItemsFailed,
  loadAudienceGroupMembersSucceeded,
  loadAudienceGroupMembersFailed,
  addSelectedEmployeeToAudienceGroupSucceeded,
  addSelectedEmployeeToAudienceGroupFailed,
  retrieveAudienceGroupSelectedEmployeesTotalCountFailed,
  retrieveAudienceGroupSelectedEmployeesTotalCountRequested,
  selectEmployeesToBeAddedCount,
  selectCurrentAudienceGroupName,
  retrieveAudienceMemberCountSucceeded,
  retrieveAudienceMemberCountFailed,
  retrieveAudienceMemberCountRequested,
  retrieveAudienceGroupSelectedEmployeesTotalCountSucceeded
} from '@features/admin-settings';
import {
  RetrieveEmployeesByIdsRequestSchema,
  RetrieveEmployeesByQueryRequest,
  RetrieveEmployeesByQueryResponse,
  RetrieveEmployeesBySelectionRequest,
  RetrieveEmployeesBySelectionRequestSchema,
  RetrieveEmployeesBySelectionResponse,
  RetrieveEmployeesTotalCountBySelectedRequest,
  RetrieveEmployeesTotalCountBySelectedResponse
} from '@thrivea/organization-client';
import {
  CreateAudienceRequest,
  CreateAudienceResponse,
  DeleteAudienceRequest,
  RetrieveAudienceByIdRequest,
  RetrieveAudienceByIdResponse,
  RetrieveAudienceMemberCountRequest,
  RetrieveAudienceMemberCountResponse,
  RetrieveAudiencesRequest,
  RetrieveAudiencesResponse,
  UpdateAudienceRequest,
  UpdateAudienceResponse
} from '@thrivea/networking-client';
import { createAudience, deleteAudience, retrieveAudienceById, retrieveAudienceMemberCount, retrieveAudiences, updateAudience } from '@api/audience.api';
import { PayloadAction } from '@reduxjs/toolkit';
import { retrieveEmployeesByQuery, retrieveEmployeesBySelection, retrieveEmployeesTotalCountBySelected } from '@api/employees.api';
import { t } from 'i18next';
import { showSuccess } from '@features/snackbar';
import * as Sentry from '@sentry/react';
import { retrieveAudienceDrawerEmployeeItemsRequested } from '@features/drawer';

function* retrieveAudienceGroupsRequestedGenerator(action: PayloadAction<RetrieveAudiencesRequest>) {
  try {
    const response: RetrieveAudiencesResponse = yield call(retrieveAudiences, action.payload);
    yield put(retrieveAudienceGroupsSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveAudienceGroupsFailed());
  }
}

function* addAudienceGroupGenerator(action: PayloadAction<CreateAudienceRequest>) {
  try {
    const response: CreateAudienceResponse = yield call(createAudience, action.payload);
    const successMessage = t('audience_created_with_count', {
      ns: 'settings_permissions',
      audience: response.audience?.audienceName,
      count: response.audience?.employeeCount
    });

    yield put(createAudienceSucceeded(response));
    yield put(showSuccess(successMessage));
  } catch (error) {
    Sentry.captureException(error);
    yield put(createAudienceFailed());
  }
}

function* updateAudienceRequestedGenerator(action: PayloadAction<UpdateAudienceRequest>) {
  try {
    const response: UpdateAudienceResponse = yield call(updateAudience, action.payload);
    const successMessage = t('audience_updated_with_count', {
      ns: 'settings_permissions',
      audience: response.audience!.audienceName,
      count: response.audience!.employeeCount
    });
    yield put(updateAudienceSucceeded(response));
    yield put(showSuccess(successMessage));
  } catch (error) {
    Sentry.captureException(error);
    yield put(updateAudienceFailed());
  }
}

function* deleteAudienceGroupGenerator(action: PayloadAction<DeleteAudienceRequest>) {
  try {
    const { audienceId } = action.payload;
    const successMessage = t('deleted_audience_group', { ns: 'settings_permissions' });
    yield call(deleteAudience, action.payload);
    yield put(deleteAudienceSucceeded(audienceId));
    yield put(showSuccess(successMessage));
  } catch (error) {
    Sentry.captureException(error);
    yield put(deleteAudienceFailed());
  }
}

function* retrieveAudienceGroupAutocompleteItemsRequestedGenerator(action: PayloadAction<RetrieveEmployeesByQueryRequest>) {
  try {
    const response: RetrieveEmployeesByQueryResponse = yield call(retrieveEmployeesByQuery, action.payload);
    yield put(retrieveAudienceGroupAutocompleteItemsSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveAudienceGroupAutocompleteItemsFailed());
  }
}

function* loadAudienceGroupMembersRequestedGenerator(action: PayloadAction<LoadAudienceEmployeesRequest>) {
  try {
    const response: RetrieveEmployeesBySelectionResponse = yield call(
      retrieveEmployeesBySelection,
      create(RetrieveEmployeesBySelectionRequestSchema, { selectedEmployeeIds: action.payload.employeeIds })
    );
    yield put(loadAudienceGroupMembersSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(loadAudienceGroupMembersFailed());
  }
}

function* addSelectedEmployeeToAudienceGroupRequestedGenerator(action: PayloadAction<RetrieveEmployeesBySelectionRequest>) {
  try {
    const response: RetrieveEmployeesBySelectionResponse = yield call(retrieveEmployeesBySelection, action.payload);
    yield put(addSelectedEmployeeToAudienceGroupSucceeded(response));

    const employeesToBeAddedCount: number = yield select(selectEmployeesToBeAddedCount);
    const currentAudienceGroupName = yield select(selectCurrentAudienceGroupName);
    yield put(showSuccess(t('added_employee_with_count', { ns: 'common', count: employeesToBeAddedCount, name: currentAudienceGroupName })));
  } catch (error) {
    Sentry.captureException(error);
    yield put(addSelectedEmployeeToAudienceGroupFailed());
  }
}

function* retrieveAudienceGroupSelectedEmployeesTotalCountRequestedGenerator(action: PayloadAction<RetrieveEmployeesTotalCountBySelectedRequest>) {
  try {
    const response: RetrieveEmployeesTotalCountBySelectedResponse = yield call(retrieveEmployeesTotalCountBySelected, action.payload);
    yield put(retrieveAudienceGroupSelectedEmployeesTotalCountSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveAudienceGroupSelectedEmployeesTotalCountFailed());
  }
}

function* retrieveAudienceByIdGenerator(action: PayloadAction<RetrieveAudienceByIdRequest>) {
  try {
    const response: RetrieveAudienceByIdResponse = yield call(retrieveAudienceById, action.payload);
    const employeeIds = response.employeeIdsWithRole.map((e) => e.employeeId);
    yield put(retrieveAudienceByIdSucceeded(response));
    yield put(retrieveAudienceDrawerEmployeeItemsRequested(create(RetrieveEmployeesByIdsRequestSchema, { employeeIds })));
  } catch (error) {
    yield put(retrieveAudienceByIdFailed());
  }
}

function* retrieveAudienceMemberCountRequestedGenerator(action: PayloadAction<RetrieveAudienceMemberCountRequest>) {
  try {
    const response: RetrieveAudienceMemberCountResponse = yield call(retrieveAudienceMemberCount, action.payload);
    yield put(retrieveAudienceMemberCountSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveAudienceMemberCountFailed());
  }
}

function* retrieveAudienceGroupsRequestedWatcher() {
  yield takeLatest(retrieveAudienceGroupsRequested.type, retrieveAudienceGroupsRequestedGenerator);
}

function* updateAudienceRequestedWatcher() {
  yield takeLatest(updateAudienceRequested.type, updateAudienceRequestedGenerator);
}

function* addAudienceGroupWatcher() {
  yield takeLatest(createAudienceRequested.type, addAudienceGroupGenerator);
}

function* deleteAudienceGroupWatcher() {
  yield takeLatest(deleteAudienceRequested.type, deleteAudienceGroupGenerator);
}

function* retrieveAudienceGroupAutocompleteItemsRequestedWatcher() {
  yield takeLatest(retrieveAudienceGroupAutocompleteItemsRequested.type, retrieveAudienceGroupAutocompleteItemsRequestedGenerator);
}

function* addSelectedEmployeeToAudienceGroupRequestedWatcher() {
  yield takeLatest(addSelectedEmployeeToAudienceGroupRequested.type, addSelectedEmployeeToAudienceGroupRequestedGenerator);
}

function* retrieveAudienceGroupSelectedEmployeesTotalCountRequestedWatcher() {
  yield takeLatest(retrieveAudienceGroupSelectedEmployeesTotalCountRequested.type, retrieveAudienceGroupSelectedEmployeesTotalCountRequestedGenerator);
}

function* retrieveAudienceByIdWatcher() {
  yield takeLatest(retrieveAudienceByIdRequested.type, retrieveAudienceByIdGenerator);
}

function* loadAudienceGroupMembersRequestedWatcher() {
  yield takeLatest(loadAudienceGroupMembersRequested.type, loadAudienceGroupMembersRequestedGenerator);
}

function* retrieveAudienceMemberCountRequestedWatcher() {
  yield takeLatest(retrieveAudienceMemberCountRequested.type, retrieveAudienceMemberCountRequestedGenerator);
}

export function* audienceSagas() {
  yield all([
    fork(retrieveAudienceGroupsRequestedWatcher),
    fork(updateAudienceRequestedWatcher),
    fork(addAudienceGroupWatcher),
    fork(deleteAudienceGroupWatcher),
    fork(retrieveAudienceGroupAutocompleteItemsRequestedWatcher),
    fork(addSelectedEmployeeToAudienceGroupRequestedWatcher),
    fork(retrieveAudienceGroupSelectedEmployeesTotalCountRequestedWatcher),
    fork(retrieveAudienceByIdWatcher),
    fork(loadAudienceGroupMembersRequestedWatcher),
    fork(retrieveAudienceMemberCountRequestedWatcher)
  ]);
}
