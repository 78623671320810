import { IconButton, IconButtonProps, styled } from '@mui/material';

export const TaskIconButton = styled(IconButton)<IconButtonProps>(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 35,
  padding: '12px 16px',
  backgroundColor: theme.palette.customTheme.solidBgr,
  borderRadius: 2
}));
