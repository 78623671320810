import React, { useRef, useState } from 'react';
import { Stack, Typography, Box, Skeleton, IconButton, alpha, Button, Popover } from '@mui/material';
import { CircleIcon, PictureSizeSuffix, QuillEditor, RowCenterStack, ActionStatus, StyledAvatar } from '@/shared';
import {
  CommentReactions,
  EmojiPicker,
  reactToCommentRequested,
  selectCommentReactionsGroupedByEmoji,
  selectDeleteCommentStatus,
  sanitizeConfig,
  selectDeletedCommentId,
  PostCommentMenu,
  editCommentRequested,
  selectAudienceIdByPostId,
  selectMentionEmployeesIds,
  retrieveMentionEmployeesRequested
} from '@features/homepage';
import { DateTime } from 'luxon';
import DOMPurify from 'dompurify';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { selectEmployeeProfileAndCoverReadSasToken, selectLocale } from '@features/employee-profile';
import { EditCommentRequest, ReactToCommentRequest, RetrieveEmployeeIdsByAudienceIdRequest } from '@thrivea/networking-client';
import keys from 'lodash/keys';
import { selectCurrentEmployeeId, selectEmployeeListItemsById } from '@features/shared';
import { DrawerType, OpenDrawerRequest, openDrawer } from '@features/drawer';
import { selectEmployeeListItemById } from '@features/shared';
import { initials } from '@utils/initials';
import { pictureUrl } from '@utils/pictureUrl';
import { useTranslation } from 'react-i18next';
import { removeBomCharacters } from '@utils/removeBomCharacters';

import Quill from 'quill';

export interface PostCommentProps {
  postId: string;
  commentId: string;
  authorId: string;
  createdTime: DateTime;
  editedTime?: DateTime;
  message: string;
  handleSetCommentEditable: (id: string) => void;
  isEditable: boolean;
}

interface Emoji {
  id: number;
  native: string;
}

export const PostComment: React.FC<PostCommentProps> = ({
  postId,
  commentId,
  authorId,
  createdTime,
  editedTime,
  message,
  isEditable,
  handleSetCommentEditable
}) => {
  const { t } = useTranslation(['homepage']);
  const dispatch = useAppDispatch();

  const deleteCommentStatus = useAppSelector<ActionStatus>(selectDeleteCommentStatus);
  const deletedCommentId = useAppSelector(selectDeletedCommentId);
  const commentReactionsGroupedByEmoji = useAppSelector((state) => selectCommentReactionsGroupedByEmoji(state, commentId));
  const locale = useAppSelector<string>(selectLocale);
  const currentUserId = useAppSelector(selectCurrentEmployeeId);
  const author = useAppSelector((state) => selectEmployeeListItemById(state, authorId));
  const profileAndCoverPictureSasToken = useAppSelector(selectEmployeeProfileAndCoverReadSasToken);
  const audienceIdByPostId = useAppSelector((state) => selectAudienceIdByPostId(state, postId));
  const mentionEmployeesIds = useAppSelector(selectMentionEmployeesIds);
  const employeeListItemById = useAppSelector(selectEmployeeListItemsById);

  const mentionEmployees = mentionEmployeesIds.map((id) => employeeListItemById[id]);

  const [text, setText] = useState(message);

  const quillRef = useRef<Quill | null>(null);

  const rteContent = { __html: DOMPurify.sanitize(message, sanitizeConfig) };

  const hasCurrentUserAlreadyReacted = (emoji: string) => {
    return commentReactionsGroupedByEmoji.hasOwnProperty(emoji) && commentReactionsGroupedByEmoji[emoji].some((r) => r.authorId === currentUserId);
  };

  const handleEmojiSelect = (emoji: Emoji) => {
    if (hasCurrentUserAlreadyReacted(emoji.native)) return;

    dispatch(
      reactToCommentRequested(
        new ReactToCommentRequest({
          postId,
          commentId,
          emoji: emoji.native,
          authorId: currentUserId
        })
      )
    );
  };

  const handleEmployeeDrawerOpen = (employeeId: string) => {
    dispatch(openDrawer({ type: DrawerType.SingleEmployee, request: { employeeId } } as OpenDrawerRequest));
  };

  const handleCommentEdit = () => {
    dispatch(
      editCommentRequested(
        new EditCommentRequest({
          postId,
          commentId,
          commentText: removeBomCharacters(text)
        })
      )
    );
    handleSetCommentEditable(commentId);
  };

  return (
    <>
      {deleteCommentStatus === ActionStatus.Idle && deletedCommentId === commentId && (
        <Stack
          gap={2}
          sx={{
            padding: 0,
            alignItems: 'stretch',
            m: 0,
            borderRadius: '8px',
            flexDirection: 'row'
          }}
        >
          <Skeleton width={36} height={36} />
          <Stack gap={2}>
            <RowCenterStack gap={1}>
              <Skeleton width={200} height={15} />
              <Skeleton width={70} height={15} />
              <Skeleton width={64} height={15} />
            </RowCenterStack>
          </Stack>
        </Stack>
      )}
      <Stack
        sx={{
          flexDirection: 'row',
          alignItems: 'flex-start',
          padding: 0,
          m: 0,
          borderRadius: '8px',
          py: 1
        }}
        key={commentId}
      >
        {author === undefined ? (
          <Skeleton animation="pulse" variant="rectangular" width={46} height={46} />
        ) : (
          <IconButton onClick={() => handleEmployeeDrawerOpen(authorId)} sx={{ padding: 0 }}>
            <StyledAvatar
              width={36}
              height={36}
              src={pictureUrl(author.profilePictureUrl, profileAndCoverPictureSasToken, PictureSizeSuffix.sm)}
              alt={author.displayName}
            >
              {initials(author.displayName)}
            </StyledAvatar>
          </IconButton>
        )}
        <RowCenterStack
          sx={{
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            flexWrap: 'wrap',
            gap: 1,
            '&:hover': {
              '& .reactions': {
                opacity: 1
              }
            }
          }}
        >
          <Stack
            gap={1}
            sx={{
              padding: '0 8px',
              borderRadius: '16px',
              position: 'relative',
              alignItems: 'flex-start'
            }}
          >
            <RowCenterStack
              gap={1}
              sx={{
                alignItems: 'center'
              }}
            >
              {author === undefined ? (
                <Skeleton animation="pulse" height={20} width={200} />
              ) : (
                <Typography sx={{ cursor: 'pointer' }} onClick={() => handleEmployeeDrawerOpen(authorId)}>
                  {author.displayName}
                </Typography>
              )}
              {!editedTime && (
                <RowCenterStack gap={1}>
                  <CircleIcon size={4} color={'#251D3873'} />
                  <Typography
                    component="span"
                    variant="caption"
                    sx={{ lineHeight: 'normal', color: (theme) => alpha(theme.palette.customTheme.contColor, 0.45) }}
                  >
                    {createdTime.toRelative({ locale })}
                  </Typography>
                </RowCenterStack>
              )}
              {editedTime && (
                <RowCenterStack gap={1}>
                  <CircleIcon size={4} color={'#251D3873'} />
                  <Typography
                    component="span"
                    variant="caption"
                    sx={{ lineHeight: 'normal', color: (theme) => alpha(theme.palette.customTheme.contColor, 0.45) }}
                  >
                    {editedTime.toRelative({ locale })}
                  </Typography>
                  <CircleIcon size={4} color={'#251D3873'} />
                  <Typography
                    component="span"
                    variant="caption"
                    sx={{ lineHeight: 'normal', color: (theme) => alpha(theme.palette.customTheme.contColor, 0.45) }}
                  >
                    {t('edited', { ns: 'homepage' })}
                  </Typography>
                </RowCenterStack>
              )}
              <PostCommentMenu postId={postId} commentId={commentId} authorId={authorId} handleSetCommentEditable={handleSetCommentEditable} />
            </RowCenterStack>
            {isEditable && (
              <Stack
                onFocus={() => {
                  dispatch(
                    retrieveMentionEmployeesRequested(
                      new RetrieveEmployeeIdsByAudienceIdRequest({
                        audienceId: audienceIdByPostId
                      })
                    )
                  );
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Escape') {
                    handleSetCommentEditable(commentId);
                  }
                  if (event.key === 'Enter' && event.shiftKey) {
                    handleCommentEdit();
                  }
                }}
                sx={{
                  flexDirection: 'row',
                  alignItems: 'flex-end',
                  position: 'relative',
                  borderRadius: '7px',
                  padding: '12px',
                  bgcolor: (theme) => theme.palette.grey[300],
                  width: '100%',
                  '& .quill': {
                    width: '100%',
                    padding: 0,
                    backgroundColor: 'inherit',
                    flexDirection: 'row-reverse',
                    alignItems: 'flex-end',
                    '& .ql-container': {
                      width: '100%'
                    }
                  },
                  '& .ql-customControl': {
                    opacity: 0.5,
                    mb: '4px'
                  }
                }}
              >
                <QuillEditor text={text} setText={setText} ref={quillRef} employeeListItems={mentionEmployees} isComment />
                <Button variant="contained" color="primary" disabled={text.length === 0} onClick={handleCommentEdit}>
                  <Typography sx={{ color: (theme) => theme.palette.common.white }}>{t('update')}</Typography>
                </Button>
              </Stack>
            )}
            {!isEditable && (
              <Stack gap={1}>
                <Stack
                  gap={2}
                  sx={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'flex-end'
                  }}
                >
                  <Box component="div" dangerouslySetInnerHTML={rteContent} sx={{ minHeight: 16 }} />
                </Stack>
                <RowCenterStack
                  gap="4px"
                  sx={{
                    padding: 0,
                    position: 'relative',
                    flexWrap: 'wrap'
                  }}
                >
                  {keys(commentReactionsGroupedByEmoji).length > 0 && <CommentReactions postId={postId} commentId={commentId} />}
                  <Box
                    className="reactions"
                    sx={{
                      width: 40,
                      height: 28,
                      opacity: 0,
                      transition: 'opacity 0.3s'
                    }}
                  >
                    <EmojiPicker onEmojiSelect={handleEmojiSelect} />
                  </Box>
                </RowCenterStack>
              </Stack>
            )}
          </Stack>
        </RowCenterStack>
      </Stack>
    </>
  );
};
