import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { styled, buttonClasses } from '@mui/material';

export const PostButton = styled(LoadingButton)<LoadingButtonProps>(({ theme, loading, disabled }) => ({
  position: 'relative',
  border: '1px solid',
  borderColor: 'transparent',
  padding: '12px',
  backgroundColor: theme.palette.grey[800],
  fontSize: 16,
  fontWeight: 700,
  borderRadius: '10px',
  justifyContent: 'center',
  alignItems: 'center',
  minWidth: 132,
  height: 40,
  [`& .${buttonClasses.endIcon}`]: {
    position: 'absolute',
    top: 2,
    right: 6,
    height: '90%',
    padding: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '7px 7px 0 7px'
  },
  '& svg': {
    opacity: loading || disabled ? 0.25 : 1
  },
  '&:hover': {
    backgroundColor: theme.palette.customTheme.contColor,
    color: theme.palette.common.white,
    [`& .${buttonClasses.startIcon}`]: {
      '& svg,path,g': {
        fill: theme.palette.common.white,
        stroke: theme.palette.common.white
      }
    },
    [`& .${buttonClasses.endIcon}`]: {
      backgroundColor: theme.palette.primary.dark,
      '& svg,path': {
        fill: theme.palette.common.white
      }
    }
  }
}));
