import { create } from '@bufbuild/protobuf';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  UpsertLocalizationStepRequest,
  UpsertSiteDetailsStepRequest,
  ImportEmployeesRequest,
  RetrieveIndustryCategoryResponse,
  RetrieveIndustrySubCategoriesRequest,
  RetrieveIndustrySubCategoriesResponse,
  RetrieveWorkingPatternsResponse,
  UpdateEmployeeIdStepRequest,
  UpdateIncorporationStepRequest,
  UpdateManagerToEmployeesRequest,
  UpdateNameAndIndustryStepRequest,
  UpdateSiteCalendarStepRequest,
  UpdateSiteWorkingPatternStepRequest,
  UpsertSiteDetailsStepResponse,
  AssignedManagers,
  UpsertSiteDetailsStepRequestSchema,
  RetrieveImportedEmployeesRequestSchema,
  RetrieveAssignedManagersRequestSchema
} from '@thrivea/organization-client';
import { all, call, delay, fork, put, select, takeLatest } from 'redux-saga/effects';
import {
  upsertSiteDetailsStep,
  upsertLocalizationStep,
  retrieveIndustryCategories,
  retrieveIndustrySubcategories,
  retrieveWorkingPatterns,
  updateIncorporationStep,
  updateNameAndIndustryStep,
  updateSiteCalendarStep,
  updateSiteWorkingPatternStep,
  updateEmployeeIdStep,
  retrieveAdminOnboardingFlow,
  retrieveAdminOnboardingFlowSteps,
  importEmployeesStep,
  retrieveImportedEmployees,
  updateManagerToEmployees,
  sendEmployeeInvitationEMails,
  retrieveAssignedManagers
} from '@api/organization.api';
import {
  retrieveIndustryCategoriesRequested,
  retrieveIndustryCategoriesSucceeded,
  retrieveIndustrySubcategoriesRequested,
  retrieveIndustrySubcategoriesSucceeded,
  updateIncorporationStepRequested,
  updateIncorporationStepSucceeded,
  updateIncorporationStepFailed,
  updateNameAndIndustryStepRequested,
  updateNameAndIndustryStepSucceeded,
  updateNameAndIndustryStepFailed,
  upsertSiteDetailsStepRequested,
  upsertSiteDetailsStepSucceeded,
  upsertSiteDetailsStepFailed,
  updateSiteCalendarStepRequested,
  updateSiteCalendarStepFailed,
  updateSiteCalendarStepSucceeded,
  upsertLocalizationStepSucceeded,
  upsertLocalizationStepFailed,
  upsertLocalizationStepRequested,
  retrieveWorkingPatternsRequested,
  retrieveWorkingPatternsFailed,
  retrieveWorkingPatternsSucceeded,
  updateSiteWorkingPatternRequested,
  updateSiteWorkingPatternFailed,
  updateSiteWorkingPatternSucceeded,
  updateEmployeeIdStepSucceeded,
  updateEmployeeIdStepFailed,
  updateEmployeeIdStepRequested,
  importEmployeesStepRequested,
  importEmployeesStepSucceeded,
  importEmployeesStepFailed,
  loadAssignManagersRequested,
  loadAssignManagersSucceeded,
  loadAssignManagersFailed,
  updateEmployeesManagerRequested,
  updateEmployeesManagerSucceeded,
  updateEmployeesManagerFailed,
  sendEmployeeInvitationEmailSucceeded,
  sendEmployeeInvitationEmailFailed,
  sendEmployeeInvitationEmailRequested,
  retrieveIndustryCategoriesFailed,
  retrieveIndustrySubcategoriesFailed,
  AssignManagersData,
  retrieveAssignedManagersFailed,
  retrieveAssignedManagersSucceeded,
  retrieveAssignedManagersRequested,
  uploadSiteMediaRequested,
  retrieveSiteWriteSasUriRequested,
  retrieveSiteReadSasTokenRequested,
  selectSiteWriteSasUri,
  uploadSiteMediaSucceeded,
  retrieveSiteWriteSasUriSucceeded,
  uploadSiteMediaFailed,
  retrieveSiteWriteSasUriFailed,
  retrieveSiteReadSasTokenSucceeded,
  retrieveSiteReadSasTokenFailed,
  handleSiteMediaUploaded,
  handleStartUploadingSiteMedia,
  handleUpdateSiteMediaUploadProgress,
  selectSiteMediaFileWithStatus,
  loadAdminOnboardingFlowRequested,
  loadAdminOnboardingFlowSucceeded,
  AdminOnboardingFlowInitData,
  loadAdminOnboardingFlowFailed,
  selectSiteCoverImageFile
} from '@features/admin-onboarding';
import { FileWithStatus, uploadFileMultipleSizes, UploadFileMultipleSizes, uploadFileWithProgress, UploadFileWithProgressRequest } from '@api/blob-storage.api';
import * as Sentry from '@sentry/react';
import { t } from 'i18next';
import { showSuccess, showWarning } from '@features/snackbar';
import { SasToken, SasUri } from '@thrivea/auth-client';
import { retrieveAdminOnboardingFlowSiteCreateSasUri, retrieveSiteReadSasToken } from '@api/shared-access-signature.api';
import { MultiSizeImageBlobs } from 'src/shared';
import { retrieveAllEmployees } from 'src/api/employees.api';
import { EmptySchema } from '@bufbuild/protobuf/wkt';

function* retrieveIndustryCategoriesGenerator() {
  try {
    const industryCategories: RetrieveIndustryCategoryResponse = yield call(retrieveIndustryCategories, create(EmptySchema));
    yield put(retrieveIndustryCategoriesSucceeded(industryCategories));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveIndustryCategoriesFailed());
  }
}

function* retrieveIndustrySubCategoriesGenerator(action: PayloadAction<RetrieveIndustrySubCategoriesRequest>) {
  try {
    const industrySubcategories: RetrieveIndustrySubCategoriesResponse = yield call(retrieveIndustrySubcategories, action.payload);
    yield put(retrieveIndustrySubcategoriesSucceeded(industrySubcategories));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveIndustrySubcategoriesFailed());
  }
}

function* updateNameAndIndustryStepGenerator(action: PayloadAction<UpdateNameAndIndustryStepRequest>) {
  const successMessage = t('name_and_industry_completed', { ns: 'onboarding' });
  try {
    yield call(updateNameAndIndustryStep, action.payload);
    yield put(updateNameAndIndustryStepSucceeded());
    yield put(showSuccess(successMessage));
  } catch (error) {
    Sentry.captureException(error);
    yield put(showWarning(error));
    yield put(updateNameAndIndustryStepFailed());
  }
}

function* updateIncorporationStepGenerator(action: PayloadAction<UpdateIncorporationStepRequest>) {
  const successMessage = t('incorporation_completed', { ns: 'onboarding' });
  try {
    yield call(updateIncorporationStep, action.payload);
    yield put(updateIncorporationStepSucceeded());
    yield put(showSuccess(successMessage));
  } catch (error) {
    Sentry.captureException(error);
    yield put(showWarning(error));
    yield put(updateIncorporationStepFailed());
  }
}

function* updateEmployeeIdStepGenerator(action: PayloadAction<UpdateEmployeeIdStepRequest>) {
  const successMessage = t('employee_completed', { ns: 'onboarding' });
  try {
    yield call(updateEmployeeIdStep, action.payload);
    yield put(updateEmployeeIdStepSucceeded());
    yield put(showSuccess(successMessage));
  } catch (error) {
    Sentry.captureException(error);
    yield put(showWarning(error));
    yield put(updateEmployeeIdStepFailed());
  }
}

// function* upsertSiteDetailsStepGenerator(action: PayloadAction<UpsertSiteDetailsStepRequest>) {
//   const successMessage = t('site_details_completed', { ns: 'onboarding' });
//   try {
//     const response: UpsertSiteDetailsStepResponse = yield call(upsertSiteDetailsStep, action.payload);
//     yield put(upsertSiteDetailsStepSucceeded(response));
//     yield put(showSuccess(successMessage));
//   } catch (error) {
//     Sentry.captureException(error);
//     yield put(showWarning(error));
//     yield put(upsertSiteDetailsStepFailed());
//   }
// }

function* upsertSiteDetailsStepGenerator(action: PayloadAction<UpsertSiteDetailsStepRequest>) {
  const siteCoverImageFile: MultiSizeImageBlobs = yield select(selectSiteCoverImageFile);
  let coverImageUrl = '';

  try {
    if (siteCoverImageFile) {
      const sasUri: string = yield select(selectSiteWriteSasUri);
      const request = {
        sasUri,
        multiSizeImage: siteCoverImageFile
      } as UploadFileMultipleSizes;
      coverImageUrl = yield call(uploadFileMultipleSizes, request);
    } else {
      coverImageUrl = action.payload.coverImageUrl;
    }

    const response: UpsertSiteDetailsStepResponse = yield call(
      upsertSiteDetailsStep,
      create(UpsertSiteDetailsStepRequestSchema, {
        location: action.payload.location,
        name: action.payload.name,
        coverImageUrl
      })
    );
    yield put(upsertSiteDetailsStepSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(upsertSiteDetailsStepFailed());
  }
}

function* upsertLocalizationStepGenerator(action: PayloadAction<UpsertLocalizationStepRequest>) {
  const successMessage = t('site_localization_completed', { ns: 'onboarding' });
  try {
    yield call(upsertLocalizationStep, action.payload);
    yield put(upsertLocalizationStepSucceeded());
    yield put(showSuccess(successMessage));
  } catch (error) {
    Sentry.captureException(error);
    yield put(showWarning(error));
    yield put(upsertLocalizationStepFailed());
  }
}

function* updateSiteCalendarStepGenerator(action: PayloadAction<UpdateSiteCalendarStepRequest>) {
  const successMessage = t('site_calendar_completed', { ns: 'onboarding' });
  try {
    yield call(updateSiteCalendarStep, action.payload);
    yield put(updateSiteCalendarStepSucceeded());
    yield put(showSuccess(successMessage));
  } catch (error) {
    Sentry.captureException(error);
    yield put(showWarning(error));
    yield put(updateSiteCalendarStepFailed());
  }
}

function* retrieveWorkingPatternsGenerator() {
  try {
    const workingPatterns: RetrieveWorkingPatternsResponse = yield call(retrieveWorkingPatterns, create(EmptySchema));
    yield put(retrieveWorkingPatternsSucceeded(workingPatterns));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveWorkingPatternsFailed());
  }
}

function* updateSiteWorkingPatternGenerator(action: PayloadAction<UpdateSiteWorkingPatternStepRequest>) {
  const successMessage = t('site_working_pattern_completed', { ns: 'onboarding' });
  try {
    yield call(updateSiteWorkingPatternStep, action.payload);
    yield put(updateSiteWorkingPatternSucceeded());
    yield put(showSuccess(successMessage));
  } catch (error) {
    Sentry.captureException(error);
    yield put(showWarning(error));
    yield put(updateSiteWorkingPatternFailed());
  }
}

function* importEmployeesStepGenerator(action: PayloadAction<ImportEmployeesRequest>) {
  try {
    yield call(importEmployeesStep, action.payload);
    yield put(importEmployeesStepSucceeded());
  } catch (error) {
    Sentry.captureException(error);
    yield put(importEmployeesStepFailed());
  }
}

function* loadAssignManagersGenerator() {
  try {
    const assignManagersData: AssignManagersData = yield all({
      importedEmployeees: call(retrieveImportedEmployees, create(RetrieveImportedEmployeesRequestSchema, { pageNumber: 1, pageSize: 100 })),
      allEmployeesResponse: call(retrieveAllEmployees, create(EmptySchema))
    });
    yield put(loadAssignManagersSucceeded(assignManagersData));
  } catch (error) {
    Sentry.captureException(error);
    yield put(loadAssignManagersFailed());
  }
}

function* updateManagerToEmployeesGenerator(action: PayloadAction<UpdateManagerToEmployeesRequest>) {
  try {
    yield call(updateManagerToEmployees, action.payload);
    yield put(updateEmployeesManagerSucceeded());
  } catch (error) {
    Sentry.captureException(error);
    yield put(updateEmployeesManagerFailed());
  }
}

function* sendEmployeeInvitationMailsGenerator() {
  try {
    yield call(sendEmployeeInvitationEMails, create(EmptySchema));
    yield put(sendEmployeeInvitationEmailSucceeded());
  } catch (error) {
    Sentry.captureException(error);
    yield put(sendEmployeeInvitationEmailFailed());
  }
}

function* retrieveAssignedManagersGenerator() {
  try {
    const assignedManagersData: AssignedManagers = yield call(
      retrieveAssignedManagers,
      create(RetrieveAssignedManagersRequestSchema, { pageNumber: 1, pageSize: 100 })
    );
    yield put(retrieveAssignedManagersSucceeded(assignedManagersData));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveAssignedManagersFailed());
  }
}

const MAX_RETRY_COUNT = 5;

function* uploadSiteMediaGenerator() {
  let retryCount = 0;
  const fileWithStatus: FileWithStatus = yield select(selectSiteMediaFileWithStatus);
  let lastError;

  while (retryCount < MAX_RETRY_COUNT) {
    const sasUri: string = yield select(selectSiteWriteSasUri);
    const request = {
      fileWithStatus,
      sasUri,
      handleUpdateFileUploadProgress: handleUpdateSiteMediaUploadProgress,
      handleStartUploadingFile: handleStartUploadingSiteMedia,
      handleFileUploaded: handleSiteMediaUploaded
    } as UploadFileWithProgressRequest;
    try {
      yield call(uploadFileWithProgress, request);
      yield put(uploadSiteMediaSucceeded());
      return;
    } catch (error) {
      lastError = error;
      retryCount++;

      const sasUri: SasUri = yield call(retrieveAdminOnboardingFlowSiteCreateSasUri, create(EmptySchema));
      yield put(retrieveSiteWriteSasUriSucceeded(sasUri));

      yield delay(500 * retryCount + 1);
    }
  }

  Sentry.captureException(lastError);
  yield put(uploadSiteMediaFailed());
}

function* retrieveAdminOnboardingFlowSiteWriteSasUriGenerator() {
  try {
    const sasUri: SasUri = yield call(retrieveAdminOnboardingFlowSiteCreateSasUri, create(EmptySchema));
    yield put(retrieveSiteWriteSasUriSucceeded(sasUri));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveSiteWriteSasUriFailed());
  }
}

function* retrieveSiteReadSasTokenGenerator() {
  try {
    const sasToken: SasToken = yield call(retrieveSiteReadSasToken, create(EmptySchema));
    yield put(retrieveSiteReadSasTokenSucceeded(sasToken));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveSiteReadSasTokenFailed());
  }
}

function* loadAdminOnboardingFlowGenerator() {
  try {
    const adminOnboardingFlowInitData: AdminOnboardingFlowInitData = yield all({
      flow: call(retrieveAdminOnboardingFlow, create(EmptySchema)),
      steps: call(retrieveAdminOnboardingFlowSteps, create(EmptySchema))
    });

    yield put(loadAdminOnboardingFlowSucceeded(adminOnboardingFlowInitData));
  } catch (error) {
    Sentry.captureException(error);
    yield put(loadAdminOnboardingFlowFailed());
  }
}

function* retrieveIndustryCategoriesRequestedWatcher() {
  yield takeLatest(retrieveIndustryCategoriesRequested.type, retrieveIndustryCategoriesGenerator);
}

function* retrieveIndustrySubcategoriesRequestedWatcher() {
  yield takeLatest(retrieveIndustrySubcategoriesRequested.type, retrieveIndustrySubCategoriesGenerator);
}

function* updateNameAndIndustryStepWatcher() {
  yield takeLatest(updateNameAndIndustryStepRequested.type, updateNameAndIndustryStepGenerator);
}

function* updateIncorporationStepWatcher() {
  yield takeLatest(updateIncorporationStepRequested.type, updateIncorporationStepGenerator);
}

function* updateEmployeeIdStepWatcher() {
  yield takeLatest(updateEmployeeIdStepRequested.type, updateEmployeeIdStepGenerator);
}

function* upsertSiteDetailsStepWatcher() {
  yield takeLatest(upsertSiteDetailsStepRequested.type, upsertSiteDetailsStepGenerator);
}

function* upsertSiteLocalizationStepWatcher() {
  yield takeLatest(upsertLocalizationStepRequested.type, upsertLocalizationStepGenerator);
}

function* updateSiteCalendarStepWatcher() {
  yield takeLatest(updateSiteCalendarStepRequested.type, updateSiteCalendarStepGenerator);
}

function* retrieveWorkingPatternsWatcher() {
  yield takeLatest(retrieveWorkingPatternsRequested.type, retrieveWorkingPatternsGenerator);
}

function* updateSiteWorkingPatternWatcher() {
  yield takeLatest(updateSiteWorkingPatternRequested.type, updateSiteWorkingPatternGenerator);
}

function* importEmployeesStepWatcher() {
  yield takeLatest(importEmployeesStepRequested.type, importEmployeesStepGenerator);
}

function* loadAssignManagersWatcher() {
  yield takeLatest(loadAssignManagersRequested.type, loadAssignManagersGenerator);
}

function* updateManagerToEmployeesWatcher() {
  yield takeLatest(updateEmployeesManagerRequested.type, updateManagerToEmployeesGenerator);
}

function* sendEmployeeInvitationEmailWatcher() {
  yield takeLatest(sendEmployeeInvitationEmailRequested.type, sendEmployeeInvitationMailsGenerator);
}

function* retrieveAssignedManagersWatcher() {
  yield takeLatest(retrieveAssignedManagersRequested.type, retrieveAssignedManagersGenerator);
}

function* uploadSiteAdminOnboardingMediaRequestedWatcher() {
  yield takeLatest(uploadSiteMediaRequested.type, uploadSiteMediaGenerator);
}

function* retrieveSiteAdminOnboardingWriteSasUriRequestedWatcher() {
  yield takeLatest(retrieveSiteWriteSasUriRequested.type, retrieveAdminOnboardingFlowSiteWriteSasUriGenerator);
}

function* retrieveSiteAdminOnboardingReadSasTokenRequestedWatcher() {
  yield takeLatest(retrieveSiteReadSasTokenRequested.type, retrieveSiteReadSasTokenGenerator);
}

function* loadAdminOnboardingFlowRequestedWatcher() {
  yield takeLatest(loadAdminOnboardingFlowRequested.type, loadAdminOnboardingFlowGenerator);
}

export function* adminOnboardingFlowSagas() {
  yield all([
    fork(retrieveIndustrySubcategoriesRequestedWatcher),
    fork(retrieveIndustryCategoriesRequestedWatcher),
    fork(updateNameAndIndustryStepWatcher),
    fork(updateIncorporationStepWatcher),
    fork(updateEmployeeIdStepWatcher),
    fork(upsertSiteDetailsStepWatcher),
    fork(upsertSiteLocalizationStepWatcher),
    fork(updateSiteCalendarStepWatcher),
    fork(retrieveWorkingPatternsWatcher),
    fork(updateSiteWorkingPatternWatcher),
    fork(importEmployeesStepWatcher),
    fork(loadAssignManagersWatcher),
    fork(updateManagerToEmployeesWatcher),
    fork(sendEmployeeInvitationEmailWatcher),
    fork(retrieveAssignedManagersWatcher),
    fork(uploadSiteAdminOnboardingMediaRequestedWatcher),
    fork(retrieveSiteAdminOnboardingWriteSasUriRequestedWatcher),
    fork(retrieveSiteAdminOnboardingReadSasTokenRequestedWatcher),
    fork(loadAdminOnboardingFlowRequestedWatcher)
  ]);
}
