import { PopperProps } from '@mui/material';
import { forwardRef } from 'react';
import { PopperBox } from './styled/PopperBox';

export const CustomPermissionGroupAutocompletePopper = forwardRef<HTMLDivElement, PopperProps>(function CustomPopper(props, ref) {
  const { children, open, anchorEl, ...other } = props;

  const getChildren = () => {
    if (typeof children === 'function') {
      return children({ placement: 'bottom-start' });
    }

    return children;
  };

  return (
    <PopperBox ref={ref} open={open} anchorEl={anchorEl as HTMLElement} {...other}>
      {getChildren()}
    </PopperBox>
  );
});
