import React from 'react';

const Used = ({ width, height }) => (
  <svg width={width} height={height} viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.414 14.414V2.78H6.172V3.746H4.506V13.448H6.172V14.414H3.414ZM7.84369 14.708V2.5H8.93569V14.708H7.84369ZM16.2378 14.414H13.4798V13.448H15.1458V3.746H13.4798V2.78H16.2378V14.414Z"
      fill="#DDD5E8"
    />
  </svg>
);

const PresentInOtherSection = ({ width, height }) => (
  <svg width={width} height={height} viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.414 14.414V2.78H6.172V3.746H4.506V13.448H6.172V14.414H3.414ZM16.2378 14.414H13.4798V13.448H15.1458V3.746H13.4798V2.78H16.2378V14.414Z"
      fill="white"
      fillOpacity="0.75"
    />
    <path d="M7.84369 14.708V2.5H8.93569V14.708H7.84369Z" fill="#251D38" />
  </svg>
);

const Unused = ({ width, height }) => (
  <svg width={width} height={height} viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.414 14.414V2.78H6.172V3.746H4.506V13.448H6.172V14.414H3.414ZM16.2378 14.414H13.4798V13.448H15.1458V3.746H13.4798V2.78H16.2378V14.414Z"
      fill="#9747FF"
    />
    <path d="M7.84369 14.708V2.5H8.93569V14.708H7.84369Z" fill="#251D38" />
  </svg>
);

interface RegularFieldIconProps {
  width?: number;
  height?: number;
  isSelected?: boolean;
  isPresentInOtherSections?: boolean;
}

export const RegularFieldIcon: React.FC<RegularFieldIconProps> = ({ width = 14, height = 13, isSelected, isPresentInOtherSections }: RegularFieldIconProps) => {
  if (isSelected) return <Used width={width} height={height} />;
  if (isPresentInOtherSections) return <PresentInOtherSection width={width} height={height} />;

  return <Unused width={width} height={height} />;
};
