import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import {
  CategoryListItem,
  CreatePeopleDirViewRequest,
  DeletePeopleDirViewRequest,
  Department,
  EmployeeListItem,
  EmployeeRecord,
  EntityType,
  ErbField,
  ErbTableField,
  FieldListItem,
  PeopleDirView,
  RenameSavedViewRequest,
  RetrieveAllPeopleDirCategoriesSectionsFieldsResponse,
  RetrieveEmployeeRecordsRequest,
  RetrieveEmployeeRecordsResponse,
  RetrievePeopleDirViewsRequest,
  RetrievePeopleDirViewsResponse,
  SectionListItem,
  Site,
  TableErbFieldValue,
  TextErbFieldValue,
  UpdatePeopleDirViewRequest,
  ViewFieldItem
} from '@thrivea/organization-client';
import { RootState } from '@app/store';
import { ActionStatus } from '@/shared';
import { PpdAdditionalData, PpdColumn, PpdData } from '@features/people-directory';
import { chain, uniq } from 'lodash';
import { getStringValue } from '../employee-record-page';

export interface EmployeeRecordState {
  entities: {
    employeeRecords: {
      byId: { [key: string]: EmployeeRecord };
      allIds: string[];
    };
    categoryListItems: {
      byId: { [key: string]: CategoryListItem };
      allIds: string[];
    };
    sectionListItems: {
      byId: { [key: string]: SectionListItem };
      allIds: string[];
    };
    fieldListItems: {
      byId: { [key: string]: FieldListItem };
      allIds: string[];
    };
    peopleDirViews: {
      byId: { [key: string]: PeopleDirView };
      savedIds: string[];
      unsavedIds: string[];
      hiddenIds: string[];
    };
    erbFields: {
      byId: { [key: string]: ErbField };
      allIds: string[];
    };
    employeeListItems: {
      byId: { [key: string]: EmployeeListItem };
      allIds: string[];
    };
    sites: {
      byId: { [key: string]: Site };
      allIds: string[];
    };
    departments: {
      byId: { [key: string]: Department };
      allIds: string[];
    };
  };
  ui: {
    retrieveEmployeeRecordStatus: ActionStatus;
    retrieveEmployeeRecordsStatus: ActionStatus;
    retrieveAllPeopleDirCategoriesSectionsFieldsStatus: ActionStatus;
    createPeopleDirViewStatus: ActionStatus;
    retrievePeopleDirViewsStatus: ActionStatus;
    renameSavedViewStatus: ActionStatus;
    loadPeopleDirectoryStatus: ActionStatus;
    deleteSavedViewStatus: ActionStatus;
    updateSavedViewStatus: ActionStatus;
    retrieveTwoStepDataStatus: ActionStatus;
    employeeRecordsTotalCount: number;
    pageNumber: number;
    pageSize: number;
  };
  activePeopleDirViewId: string;
  visibleColumns: PpdColumn[];
  isPeopleDirectoryLoaded: boolean;
}

export const ALL_DATA_ID = '2c6838c3-537f-4fca-9ba4-87204d5c8e52';

const initialState: EmployeeRecordState = {
  entities: {
    employeeRecords: {
      byId: {},
      allIds: []
    },
    categoryListItems: {
      byId: {},
      allIds: []
    },
    sectionListItems: {
      byId: {},
      allIds: []
    },
    fieldListItems: {
      byId: {},
      allIds: []
    },
    peopleDirViews: {
      byId: {
        [ALL_DATA_ID]: new PeopleDirView({
          id: ALL_DATA_ID,
          name: 'All Data',
          categoryIds: [],
          sectionIds: [],
          fields: []
        })
      },
      savedIds: [ALL_DATA_ID],
      unsavedIds: [],
      hiddenIds: []
    },
    erbFields: {
      byId: {},
      allIds: []
    },
    employeeListItems: {
      byId: {},
      allIds: []
    },
    sites: {
      byId: {},
      allIds: []
    },
    departments: {
      byId: {},
      allIds: []
    }
  },
  ui: {
    retrieveEmployeeRecordStatus: ActionStatus.Idle,
    retrieveEmployeeRecordsStatus: ActionStatus.Idle,
    retrieveAllPeopleDirCategoriesSectionsFieldsStatus: ActionStatus.Idle,
    createPeopleDirViewStatus: ActionStatus.Idle,
    retrievePeopleDirViewsStatus: ActionStatus.Idle,
    renameSavedViewStatus: ActionStatus.Idle,
    loadPeopleDirectoryStatus: ActionStatus.Idle,
    deleteSavedViewStatus: ActionStatus.Idle,
    updateSavedViewStatus: ActionStatus.Idle,
    retrieveTwoStepDataStatus: ActionStatus.Idle,
    employeeRecordsTotalCount: 0,
    pageNumber: 1,
    pageSize: 25
  },
  activePeopleDirViewId: ALL_DATA_ID,
  visibleColumns: [],
  isPeopleDirectoryLoaded: false
};

export const peopleDirSlice = createSlice({
  name: 'peopleDir',
  initialState,
  reducers: {
    loadPeopleDirectoryRequested: (state, _action: PayloadAction<string>) => {
      state.ui.loadPeopleDirectoryStatus = ActionStatus.Pending;
      state.ui.retrieveEmployeeRecordsStatus = ActionStatus.Pending;
    },
    loadPeopleDirectorySucceeded: (state, action: PayloadAction<PpdData>) => {
      const { employeeRecords, totalCount } = action.payload.employeeRecords;
      const { erbFields } = action.payload.allErbFields;
      const { categoryListItems, sectionListItems, fieldListItems } = action.payload.allPeopleDirCategoriesSectionsFields;
      const { peopleDirViews } = action.payload.peopleDirViews;

      for (const employeeRecord of employeeRecords) {
        if (!state.entities.employeeRecords.byId.hasOwnProperty(employeeRecord.employeeId)) {
          state.entities.employeeRecords.byId[employeeRecord.employeeId] = employeeRecord;
          state.entities.employeeRecords.allIds.push(employeeRecord.employeeId);
        }
      }

      for (const peopleDirView of peopleDirViews) {
        state.entities.peopleDirViews.byId[peopleDirView.id] = peopleDirView;
        state.entities.peopleDirViews.savedIds.push(peopleDirView.id);
      }

      for (const category of categoryListItems) {
        state.entities.categoryListItems.byId[category.id] = category;
        state.entities.categoryListItems.allIds.push(category.id);

        if (state.activePeopleDirViewId === ALL_DATA_ID) {
          state.entities.peopleDirViews.byId[ALL_DATA_ID].categoryIds.push(category.id);
        }
      }

      for (const section of sectionListItems) {
        state.entities.sectionListItems.byId[section.id] = section;
        state.entities.sectionListItems.allIds.push(section.id);

        if (state.activePeopleDirViewId === ALL_DATA_ID) {
          state.entities.peopleDirViews.byId[ALL_DATA_ID].sectionIds.push(section.id);
        }
      }

      for (const field of fieldListItems) {
        state.entities.fieldListItems.byId[field.id] = field;
        state.entities.fieldListItems.allIds.push(field.id);

        if (state.activePeopleDirViewId === ALL_DATA_ID) {
          state.entities.peopleDirViews.byId[ALL_DATA_ID].fields.push(field);
        }
      }

      for (const erbField of erbFields) {
        state.entities.erbFields.byId[erbField.kind.value!.id] = erbField;
        state.entities.erbFields.allIds.push(erbField.kind.value!.id);
      }

      state.visibleColumns = erbFields.flatMap((field) => {
        if (field.kind.case === 'scalarField') {
          return {
            fieldId: field.kind.value.id,
            name: field.kind.value.name,
            isSortable: field.kind.value.isSortable,
            isVisible: true,
            tableFieldId: undefined,
            erbFieldCategoryId: sectionListItems.find((sli) => sli.id === fieldListItems.find((fli) => fli.id === field.kind.value!.id)!.sectionId)!.categoryId,
            erbFieldSectionId: fieldListItems.find((fli) => fli.id === field.kind.value!.id)!.sectionId
          } as PpdColumn;
        }

        if (field.kind.case === 'tableField') {
          return field.kind.value.columns
            .filter((c) => !c.isHiddenInPeopleDir)
            .map(
              (c) =>
                ({
                  fieldId: c.id,
                  name: c.name,
                  isSortable: c.isSortableInPeopleDir,
                  isVisible: true,
                  tableFieldId: field.kind.value!.id,
                  erbFieldCategoryId: sectionListItems.find((sli) => sli.id === fieldListItems.find((fli) => fli.id === c.id)!.sectionId)!.categoryId,
                  erbFieldSectionId: fieldListItems.find((fli) => fli.id === c.id)!.sectionId
                }) as PpdColumn
            );
        }

        return []; // Return an empty array for other cases to avoid undefined
      });

      state.ui.loadPeopleDirectoryStatus = ActionStatus.Idle;
      state.ui.retrieveEmployeeRecordsStatus = ActionStatus.Idle;
      state.ui.employeeRecordsTotalCount = totalCount;
      state.isPeopleDirectoryLoaded = true;
    },
    retrieveTwoStepDataRequested: (state, action: PayloadAction<string[]>) => {
      state.ui.retrieveTwoStepDataStatus = ActionStatus.Pending;
    },
    retrieveTwoStepDataSucceeded: (state, action: PayloadAction<PpdAdditionalData>) => {
      const { employeeResults, sitesResponse, departmentsResponse } = action.payload;

      for (const employee of employeeResults.employees) {
        state.entities.employeeListItems.byId[employee.employeeId] = employee;
        state.entities.employeeListItems.allIds = uniq(state.entities.employeeListItems.allIds.concat([employee.employeeId]));
      }

      for (const site of sitesResponse.sites) {
        state.entities.sites.byId[site.id] = site;
        state.entities.sites.allIds = uniq(state.entities.sites.allIds.concat([site.id]));
      }

      for (const department of departmentsResponse.departments) {
        state.entities.departments.byId[department.id] = department;
        state.entities.departments.allIds = uniq(state.entities.departments.allIds.concat([department.id]));
      }

      state.ui.retrieveTwoStepDataStatus = ActionStatus.Idle;
    },
    retrieveTwoStepDataFailed: (state) => {
      state.ui.retrieveTwoStepDataStatus = ActionStatus.Failed;
    },
    loadPeopleDirectoryFailed: (state) => {
      state.ui.retrieveEmployeeRecordsStatus = ActionStatus.Failed;
      state.ui.loadPeopleDirectoryStatus = ActionStatus.Failed;
    },
    retrieveEmployeeRecordsRequested: (state, action: PayloadAction<RetrieveEmployeeRecordsRequest>) => {
      const { pageNumber, pageSize } = action.payload;
      state.ui.retrieveEmployeeRecordsStatus = ActionStatus.Pending;
      state.ui.pageNumber = pageNumber;
      state.ui.pageSize = pageSize;
    },

    retrieveEmployeeRecordsSucceeded: (state, action: PayloadAction<RetrieveEmployeeRecordsResponse>) => {
      const { employeeRecords, totalCount } = action.payload;

      state.entities.employeeRecords = {
        byId: {},
        allIds: []
      };

      for (const employeeRecord of employeeRecords) {
        state.entities.employeeRecords.byId[employeeRecord.employeeId] = employeeRecord;
        state.entities.employeeRecords.allIds.push(employeeRecord.employeeId);
      }
      state.ui.employeeRecordsTotalCount = totalCount;
      state.ui.retrieveEmployeeRecordsStatus = ActionStatus.Idle;
    },
    retrieveEmployeeRecordsFailed: (state) => {
      state.ui.retrieveEmployeeRecordsStatus = ActionStatus.Failed;
    },
    retrieveAllPeopleDirCategoriesSectionsFieldsRequested: (state) => {
      state.ui.retrieveAllPeopleDirCategoriesSectionsFieldsStatus = ActionStatus.Pending;
    },
    retrieveAllPeopleDirCategoriesSectionsFieldsSucceeded: (state, action: PayloadAction<RetrieveAllPeopleDirCategoriesSectionsFieldsResponse>) => {
      const { categoryListItems, fieldListItems, sectionListItems } = action.payload;

      for (const category of categoryListItems) {
        state.entities.categoryListItems.byId[category.id] = category;
        state.entities.categoryListItems.allIds.push(category.id);

        if (state.activePeopleDirViewId === ALL_DATA_ID) {
          state.entities.peopleDirViews.byId[ALL_DATA_ID].categoryIds.push(category.id);
        }
      }

      for (const section of sectionListItems) {
        state.entities.sectionListItems.byId[section.id] = section;
        state.entities.sectionListItems.allIds.push(section.id);

        if (state.activePeopleDirViewId === ALL_DATA_ID) {
          state.entities.peopleDirViews.byId[ALL_DATA_ID].sectionIds.push(section.id);
        }
      }

      for (const field of fieldListItems) {
        state.entities.fieldListItems.byId[field.id] = field;
        state.entities.fieldListItems.allIds.push(field.id);

        if (state.activePeopleDirViewId === ALL_DATA_ID) {
          state.entities.peopleDirViews.byId[ALL_DATA_ID].fields.push(field);
        }
      }

      state.ui.retrieveAllPeopleDirCategoriesSectionsFieldsStatus = ActionStatus.Idle;
    },
    retrieveAllPeopleDirCategoriesSectionsFieldsFailed: (state) => {
      state.ui.retrieveAllPeopleDirCategoriesSectionsFieldsStatus = ActionStatus.Failed;
    },
    createPeopleDirViewRequested: (state, _action: PayloadAction<CreatePeopleDirViewRequest>) => {
      state.ui.createPeopleDirViewStatus = ActionStatus.Pending;
    },
    createPeopleDirViewSucceeded: (state) => {
      state.entities.peopleDirViews.savedIds.push(state.activePeopleDirViewId);
      state.entities.peopleDirViews.unsavedIds = state.entities.peopleDirViews.unsavedIds.filter((id) => id !== state.activePeopleDirViewId);

      state.ui.createPeopleDirViewStatus = ActionStatus.Idle;
    },
    createPeopleDirViewFailed: (state) => {
      state.ui.createPeopleDirViewStatus = ActionStatus.Failed;
    },
    retrievePeopleDirViewsRequested: (state, _action: PayloadAction<RetrievePeopleDirViewsRequest>) => {
      state.ui.retrievePeopleDirViewsStatus = ActionStatus.Pending;
    },
    retrievePeopleDirViewsSucceeded: (state, action: PayloadAction<RetrievePeopleDirViewsResponse>) => {
      const { peopleDirViews } = action.payload;

      for (const peopleDirView of peopleDirViews) {
        state.entities.peopleDirViews.byId[peopleDirView.id] = peopleDirView;
        state.entities.peopleDirViews.savedIds.push(peopleDirView.id);
      }

      state.ui.retrievePeopleDirViewsStatus = ActionStatus.Idle;
    },
    retrievePeopleDirViewsFailed: (state) => {
      state.ui.retrievePeopleDirViewsStatus = ActionStatus.Failed;
    },
    renameSavedViewRequested: (state, _action: PayloadAction<RenameSavedViewRequest>) => {
      state.ui.renameSavedViewStatus = ActionStatus.Pending;
    },
    renameSavedViewSucceeded: (state, action: PayloadAction<{ id: string; name: string }>) => {
      const { id, name } = action.payload;

      state.entities.peopleDirViews.byId[id] = {
        ...state.entities.peopleDirViews.byId[id],
        name: name
      };

      state.ui.renameSavedViewStatus = ActionStatus.Idle;
    },
    renameSavedViewFailed: (state) => {
      state.ui.renameSavedViewStatus = ActionStatus.Failed;
    },
    deletePeopleDirViewRequested: (state, _action: PayloadAction<DeletePeopleDirViewRequest>) => {
      state.ui.deleteSavedViewStatus = ActionStatus.Pending;
    },
    deletePeopleDirViewSucceeded: (state, action: PayloadAction<string>) => {
      delete state.entities.peopleDirViews.byId[action.payload];
      state.entities.peopleDirViews.savedIds = state.entities.peopleDirViews.savedIds.filter((id) => id !== action.payload);
      state.entities.peopleDirViews.hiddenIds = state.entities.peopleDirViews.hiddenIds.filter((id) => id !== action.payload);

      state.activePeopleDirViewId = ALL_DATA_ID;
      state.ui.deleteSavedViewStatus = ActionStatus.Idle;
    },
    deletePeopleDirViewFailed: (state) => {
      state.ui.deleteSavedViewStatus = ActionStatus.Failed;
    },
    selectCategory: (state, action: PayloadAction<{ categoryId: string; isEnabled: boolean }>) => {
      const activePeopleDirViewId = state.activePeopleDirViewId;
      const { categoryId, isEnabled } = action.payload;

      if (activePeopleDirViewId === ALL_DATA_ID) {
        const newPeopleDirViewId = crypto.randomUUID();

        if (!isEnabled) {
          const categoryIds = state.entities.categoryListItems.allIds.filter((id) => id !== categoryId);
          const sectionIds = state.entities.sectionListItems.allIds.filter((id) => state.entities.sectionListItems.byId[id].categoryId !== categoryId);
          const fields = chain(state.entities.fieldListItems.allIds)
            .filter((id) => sectionIds.includes(state.entities.fieldListItems.byId[id].sectionId))
            .map(
              (fieldId) =>
                new ViewFieldItem({
                  id: fieldId,
                  isTableColumn: false
                })
            )
            .value();

          state.entities.peopleDirViews.byId[newPeopleDirViewId] = new PeopleDirView({
            id: newPeopleDirViewId,
            name: 'New People Dir View',
            categoryIds,
            sectionIds,
            fields
          });
          state.activePeopleDirViewId = newPeopleDirViewId;
          state.entities.peopleDirViews.unsavedIds.push(newPeopleDirViewId);

          state.visibleColumns = state.visibleColumns.map((vc) => ({
            ...vc,
            isVisible: new Set(fields.map((field) => field.id)).has(vc.fieldId)
          }));
        }
      } else {
        const currentCategoryIds = state.entities.peopleDirViews.byId[state.activePeopleDirViewId].categoryIds;
        const updatedCategoryIds = isEnabled ? uniq([...currentCategoryIds, categoryId]) : currentCategoryIds.filter((id) => id !== categoryId);
        const currentSectionIds = state.entities.peopleDirViews.byId[state.activePeopleDirViewId].sectionIds;
        const updatedSectionIds = isEnabled
          ? uniq([
              ...currentSectionIds,
              ...state.entities.sectionListItems.allIds.filter((id) => state.entities.sectionListItems.byId[id].categoryId === categoryId)
            ])
          : currentSectionIds.filter((id) => state.entities.sectionListItems.byId[id].categoryId !== categoryId);

        const fields = state.entities.fieldListItems.allIds
          .filter((id) => updatedSectionIds.includes(state.entities.fieldListItems.byId[id].sectionId))
          .map(
            (fieldId) =>
              new ViewFieldItem({
                id: fieldId,
                isTableColumn: false
              })
          );

        state.entities.peopleDirViews.byId[state.activePeopleDirViewId] = {
          ...state.entities.peopleDirViews.byId[state.activePeopleDirViewId],
          categoryIds: updatedCategoryIds,
          sectionIds: updatedSectionIds,
          fields
        };

        if (state.entities.peopleDirViews.unsavedIds.find((id) => id === state.activePeopleDirViewId) === undefined)
          state.entities.peopleDirViews.unsavedIds.push(state.activePeopleDirViewId);

        state.visibleColumns = state.visibleColumns.map((vc) => ({
          ...vc,
          isVisible: new Set(fields.map((field) => field.id)).has(vc.fieldId)
        }));
      }
    },
    selectSection: (state, action: PayloadAction<{ sectionId: string; isEnabled: boolean }>) => {
      const activePeopleDirViewId = state.activePeopleDirViewId;
      const { sectionId, isEnabled } = action.payload;

      if (activePeopleDirViewId === ALL_DATA_ID) {
        const newPeopleDirViewId = crypto.randomUUID();

        if (!isEnabled) {
          const categoryIds = state.entities.peopleDirViews.byId[activePeopleDirViewId].categoryIds;
          const sectionIds = state.entities.sectionListItems.allIds.filter((id) => id !== sectionId);
          const fields = chain(state.entities.fieldListItems.allIds)
            .filter((id) => sectionIds.includes(state.entities.fieldListItems.byId[id].sectionId))
            .map(
              (fieldId) =>
                new ViewFieldItem({
                  id: fieldId,
                  isTableColumn: false
                })
            )
            .value();

          state.entities.peopleDirViews.byId[newPeopleDirViewId] = new PeopleDirView({
            id: newPeopleDirViewId,
            name: 'New People Dir View',
            categoryIds,
            sectionIds,
            fields
          });
          state.activePeopleDirViewId = newPeopleDirViewId;
          state.entities.peopleDirViews.unsavedIds.push(newPeopleDirViewId);

          state.visibleColumns = state.visibleColumns.map((vc) => ({
            ...vc,
            isVisible: new Set(fields.map((field) => field.id)).has(vc.fieldId)
          }));
        }
      } else {
        const categoryIds = state.entities.peopleDirViews.byId[state.activePeopleDirViewId].categoryIds;

        const currentSectionIds = state.entities.peopleDirViews.byId[state.activePeopleDirViewId].sectionIds;
        const updatedSectionIds = isEnabled ? uniq([...currentSectionIds, sectionId]) : currentSectionIds.filter((id) => id !== sectionId);

        const fields = chain(state.entities.fieldListItems.allIds)
          .filter((id) => updatedSectionIds.includes(state.entities.fieldListItems.byId[id].sectionId))
          .map(
            (fieldId) =>
              new ViewFieldItem({
                id: fieldId,
                isTableColumn: false
              })
          )
          .value();

        state.entities.peopleDirViews.byId[state.activePeopleDirViewId] = {
          ...state.entities.peopleDirViews.byId[state.activePeopleDirViewId],
          categoryIds,
          sectionIds: updatedSectionIds,
          fields
        };

        if (state.entities.peopleDirViews.unsavedIds.find((id) => id === state.activePeopleDirViewId) === undefined)
          state.entities.peopleDirViews.unsavedIds.push(state.activePeopleDirViewId);

        state.visibleColumns = state.visibleColumns.map((vc) => ({
          ...vc,
          isVisible: new Set(fields.map((field) => field.id)).has(vc.fieldId)
        }));
      }
    },
    selectField: (state, action: PayloadAction<{ fieldId: string; isEnabled: boolean }>) => {
      const activePeopleDirViewId = state.activePeopleDirViewId;
      const { fieldId, isEnabled } = action.payload;

      if (activePeopleDirViewId === ALL_DATA_ID) {
        const newPeopleDirViewId = crypto.randomUUID();

        if (!isEnabled) {
          const categoryIds = state.entities.peopleDirViews.byId[activePeopleDirViewId].categoryIds;
          const sectionIds = state.entities.peopleDirViews.byId[activePeopleDirViewId].sectionIds;

          const fields = state.entities.fieldListItems.allIds
            .filter((id) => id !== fieldId)
            .map(
              (fieldId) =>
                new ViewFieldItem({
                  id: fieldId,
                  isTableColumn: false
                })
            );

          state.entities.peopleDirViews.byId[newPeopleDirViewId] = new PeopleDirView({
            id: newPeopleDirViewId,
            name: 'New People Dir View',
            categoryIds,
            sectionIds,
            fields
          });
          state.activePeopleDirViewId = newPeopleDirViewId;

          if (state.entities.peopleDirViews.unsavedIds.find((id) => id === state.activePeopleDirViewId) === undefined)
            state.entities.peopleDirViews.unsavedIds.push(state.activePeopleDirViewId);

          state.visibleColumns = state.visibleColumns.map((vc) => ({
            ...vc,
            isVisible: new Set(fields.map((field) => field.id)).has(vc.fieldId)
          }));
        }
      } else {
        const categoryIds = state.entities.peopleDirViews.byId[state.activePeopleDirViewId].categoryIds;
        const sectionIds = state.entities.peopleDirViews.byId[state.activePeopleDirViewId].sectionIds;

        // TODO: return to this and check table columns
        const fields = isEnabled
          ? [
              ...state.entities.peopleDirViews.byId[activePeopleDirViewId].fields,
              new ViewFieldItem({
                id: fieldId,
                isTableColumn: false
              })
            ]
          : state.entities.peopleDirViews.byId[activePeopleDirViewId].fields.filter((field) => field.id !== fieldId);

        state.entities.peopleDirViews.byId[state.activePeopleDirViewId] = {
          ...state.entities.peopleDirViews.byId[state.activePeopleDirViewId],
          categoryIds,
          sectionIds,
          fields
        };

        if (state.entities.peopleDirViews.unsavedIds.find((id) => id === state.activePeopleDirViewId) === undefined)
          state.entities.peopleDirViews.unsavedIds.push(state.activePeopleDirViewId);

        state.visibleColumns = state.visibleColumns.map((vc) => ({
          ...vc,
          isVisible: new Set(fields.map((field) => field.id)).has(vc.fieldId)
        }));
      }
    },
    setActivePeopleDirViewId: (state, action: PayloadAction<string>) => {
      state.activePeopleDirViewId = action.payload;

      const fields = state.entities.peopleDirViews.byId[action.payload].fields;

      state.visibleColumns = state.visibleColumns.map((vc) => ({
        ...vc,
        isVisible: new Set(fields.map((field) => field.id)).has(vc.fieldId)
      }));
    },
    toggleVisibilityPeopleDirView: (state, action: PayloadAction<{ viewId: string; hide: boolean }>) => {
      const { viewId, hide } = action.payload;

      if (hide) state.entities.peopleDirViews.hiddenIds.push(viewId);
      else state.entities.peopleDirViews.hiddenIds = state.entities.peopleDirViews.hiddenIds.filter((id) => id !== viewId);

      state.activePeopleDirViewId = ALL_DATA_ID;

      const fields = state.entities.peopleDirViews.byId[ALL_DATA_ID].fields;

      state.visibleColumns = state.visibleColumns.map((vc) => ({
        ...vc,
        isVisible: new Set(fields.map((field) => field.id)).has(vc.fieldId)
      }));
    },
    updatePeopleDirViewRequested: (state, _action: PayloadAction<UpdatePeopleDirViewRequest>) => {
      state.ui.updateSavedViewStatus = ActionStatus.Pending;
    },
    updatePeopleDirViewSucceeded: (state, action: PayloadAction<string>) => {
      state.ui.updateSavedViewStatus = ActionStatus.Idle;
      state.entities.peopleDirViews.unsavedIds = state.entities.peopleDirViews.unsavedIds.filter((id) => id !== action.payload);
    },
    updatePeopleDirViewFailed: (state) => {
      state.ui.updateSavedViewStatus = ActionStatus.Failed;
    }
  }
});

export const selectCategoryListItemsById = (state: RootState) => state.peopleDir.entities.categoryListItems.byId;
export const selectCategoryListItemsIds = (state: RootState) => state.peopleDir.entities.categoryListItems.allIds;
export const selectCategoryListItems = createSelector([selectCategoryListItemsById, selectCategoryListItemsIds], (byId, ids) => ids.map((id) => byId[id]));

export const selectSectionListItemsById = (state: RootState) => state.peopleDir.entities.sectionListItems.byId;
export const selectSectionListItemsIds = (state: RootState) => state.peopleDir.entities.sectionListItems.allIds;
export const selectSectionListItems = createSelector([selectSectionListItemsById, selectSectionListItemsIds], (byId, ids) => ids.map((id) => byId[id]));

export const selectFieldListItemsById = (state: RootState) => state.peopleDir.entities.fieldListItems.byId;
export const selectFieldListItemsIds = (state: RootState) => state.peopleDir.entities.fieldListItems.allIds;
export const selectFieldListItems = createSelector([selectFieldListItemsById, selectFieldListItemsIds], (byId, ids) => ids.map((id) => byId[id]));

export const selectPeopleDirViewsById = (state: RootState) => state.peopleDir.entities.peopleDirViews.byId;
export const selectPeopleDirViewsSavedIds = (state: RootState) => state.peopleDir.entities.peopleDirViews.savedIds;
export const selectPeopleDirViewsUnsavedIds = (state: RootState) => state.peopleDir.entities.peopleDirViews.unsavedIds;
export const selectPeopleDirViewsHiddenIds = (state: RootState) => state.peopleDir.entities.peopleDirViews.hiddenIds;

const selectAllPeopleDirViewsIds = createSelector([selectPeopleDirViewsSavedIds, selectPeopleDirViewsUnsavedIds], (savedIds, unsavedIds) =>
  uniq([...savedIds, ...unsavedIds])
);

export const selectVisibleNonAllDataPeopleDirViewsIds = createSelector([selectAllPeopleDirViewsIds, selectPeopleDirViewsHiddenIds], (allIds, hiddenIds) =>
  allIds.filter((id) => id !== ALL_DATA_ID && !hiddenIds.includes(id))
);

export const selectVisiblePeopleDirViewsIds = createSelector([selectAllPeopleDirViewsIds, selectPeopleDirViewsHiddenIds], (allIds, hiddenIds) =>
  allIds.filter((id) => !hiddenIds.includes(id))
);

export const selectAllPeopleDirViews = createSelector([selectAllPeopleDirViewsIds, selectPeopleDirViewsById], (allIds, byId) => allIds.map((id) => byId[id]));

export const selectPeopleDirViewsAsOptions = createSelector([selectAllPeopleDirViews], (views) => views.map((v) => ({ id: v.id, name: v.name })));

export const selectAllDataPeopleDirView = createSelector([selectPeopleDirViewsById], (byId) => byId[ALL_DATA_ID]);

export const selectVisibleNonAllDataPeopleDirViews = createSelector(
  [selectVisibleNonAllDataPeopleDirViewsIds, selectPeopleDirViewsById],
  (visibleViewIds, byId) => visibleViewIds.map((id) => byId[id])
);

export const selectActivePeopleDirViewId = (state: RootState) => state.peopleDir.activePeopleDirViewId;

export const selectActivePeopleDirView = (state: RootState) => state.peopleDir.entities.peopleDirViews.byId[state.peopleDir.activePeopleDirViewId];

export const selectIsViewSaved = (state: RootState, viewId: string) => state.peopleDir.entities.peopleDirViews.savedIds.includes(viewId);
export const selectViewName = (state: RootState, viewId: string) => state.peopleDir.entities.peopleDirViews.byId[viewId].name;

export const selectActivePeopleDirViewCategoryIds = createSelector([selectActivePeopleDirView], (activeView) => activeView.categoryIds);
export const selectActivePeopleDirViewSectionIds = createSelector([selectActivePeopleDirView], (activeView) => activeView.sectionIds);
export const selectActivePeopleDirViewFieldIds = createSelector([selectActivePeopleDirView], (activeView) => activeView.fields.map((field) => field.id));

export const selectActivePeopleDirViewCategories = createSelector(
  [selectActivePeopleDirViewCategoryIds, selectCategoryListItemsById],
  (activePeopleDirViewCategoryIds, categoryListItemById) => activePeopleDirViewCategoryIds.map((id) => categoryListItemById[id])
);

export const selectActivePeopleDirViewSections = createSelector(
  [selectActivePeopleDirViewSectionIds, selectSectionListItemsById],
  (activePeopleDirViewSectionIds, sectionListItemById) => activePeopleDirViewSectionIds.map((id) => sectionListItemById[id])
);

export const selectActivePeopleDirViewFields = createSelector([selectActivePeopleDirView, (state) => state], (activeView, state) =>
  activeView.fields.map((f) => state.peopleDir.entities.fieldListItems.byId[f.id])
);

export const selectIsViewChanged = createSelector(
  [selectPeopleDirViewsSavedIds, selectPeopleDirViewsUnsavedIds, (_, viewId: string) => viewId],
  (savedIds, unsavedIds, viewId) => savedIds.includes(viewId) && unsavedIds.includes(viewId)
);

export const selectActiveViewIndex = createSelector([selectVisibleNonAllDataPeopleDirViewsIds, selectActivePeopleDirViewId], (visibleIds, activeViewId) => {
  const index = visibleIds.findIndex((id) => id === activeViewId);
  return index > -1 ? index : false;
});

export const selectIsActiveViewUnsaved = (state: RootState) =>
  !state.peopleDir.entities.peopleDirViews.savedIds.includes(state.peopleDir.activePeopleDirViewId) &&
  state.peopleDir.entities.peopleDirViews.unsavedIds.includes(state.peopleDir.activePeopleDirViewId);

export const selectIsViewNew = (state: RootState, viewId: string) => state.peopleDir.entities.peopleDirViews.unsavedIds.includes(viewId);

const selectVisibleColumns = (state: RootState) => state.peopleDir.visibleColumns;

export const selectMemoizedVisibleColumns = createSelector([selectVisibleColumns], (visibleColumns) =>
  chain(visibleColumns)
    .filter((vc) => vc.isVisible)
    .value()
);

export const selectEmployeeRecordById = (state: RootState) => state.peopleDir.entities.employeeRecords.byId;
export const selectEmployeeRecordIds = (state: RootState) => state.peopleDir.entities.employeeRecords.allIds;
export const selectEmployeeRecords = createSelector([selectEmployeeRecordById, selectEmployeeRecordIds], (byId, ids) => ids.map((id) => byId[id]));
export const selectEmployeeRecordsStatus = (state: RootState) => state.peopleDir.ui.retrieveEmployeeRecordsStatus;

export const selectEmployeeRecordsFieldValues = createSelector([selectEmployeeRecords], (employeeRecords) => employeeRecords.map((er) => er.fieldValues));

export const selectErbFieldsById = (state: RootState) => state.peopleDir.entities.erbFields.byId;
export const selectErbFieldsIds = (state: RootState) => state.peopleDir.entities.erbFields.allIds;

export const selectEmployeeIdsFromEmployeeRecords = createSelector(
  [selectEmployeeRecordById, selectEmployeeRecordIds, selectErbFieldsById, selectErbFieldsIds],
  (employeeRecordById, employeeRecordIds, erbFieldsById, erbFieldIds) => {
    const erbScalarFieldIdsWithLinkedEntitiesPersonType = chain(erbFieldIds)
      .filter(
        (id) =>
          erbFieldsById[id].kind.case === 'scalarField' &&
          erbFieldsById[id].kind.value!.type!.options!.kind.case === 'linkedEntities' &&
          erbFieldsById[id].kind.value!.type!.options!.kind.value.entityType === EntityType.PERSON
      )
      .map((id) => erbFieldsById[id].kind.value!.id)
      .value();

    const employeeIdsFromScalarFields = employeeRecordIds.flatMap((id) =>
      chain(employeeRecordById[id].fieldValues)
        .filter((fv) => erbScalarFieldIdsWithLinkedEntitiesPersonType.includes(fv.erbFieldId))
        .map((fv) => getStringValue(fv))
        .value()
    );

    const employeeIdsFromTableFields = chain(erbFieldIds)
      .filter((id) => erbFieldsById[id].kind.case === 'tableField')
      .flatMap((id) =>
        (erbFieldsById[id].kind.value as ErbTableField).columns
          .filter((c) => c.type!.options!.kind.case === 'linkedEntities' && c.type!.options!.kind.value.entityType === EntityType.PERSON)
          .flatMap((c) =>
            chain(employeeRecordIds)
              .filter((erId) => employeeRecordById[erId].fieldValues.findIndex((fv) => fv.erbFieldId === id) > -1)
              .map(
                (erId) =>
                  (
                    (employeeRecordById[erId].fieldValues.find((fv) => fv.erbFieldId === id)!.kind.value as TableErbFieldValue).rows
                      .flatMap((r) => r.cells)
                      .find((rc) => rc.erbTableFieldColumnId === c.id)!.value.value as TextErbFieldValue
                  ).value
              )
              .value()
          )
      )
      .value();

    return employeeIdsFromScalarFields.concat(employeeIdsFromTableFields);
  }
);

export const selectPartiallySelectedCategories = createSelector(
  [selectCategoryListItemsIds, selectActivePeopleDirView, selectSectionListItemsById, selectSectionListItemsIds],
  (allCategoryIds, activeView, sectionListItemsById, allSectionIds) =>
    allCategoryIds.map((categoryId) => {
      const categoriesSectionsLength = activeView.sectionIds.filter((id) => sectionListItemsById[id].categoryId === categoryId).length;

      return {
        optionId: categoryId,
        isPartiallySelected:
          categoriesSectionsLength >= 1 && categoriesSectionsLength < allSectionIds.filter((id) => sectionListItemsById[id].categoryId === categoryId).length
      };
    })
);

export const selectPartiallySelectedSections = createSelector(
  [selectSectionListItemsIds, selectActivePeopleDirView, selectFieldListItemsById, selectFieldListItemsIds],
  (allSectionIds, activeView, fieldListItemsById, allFieldIds) =>
    allSectionIds.map((sectionId) => {
      const sectionFieldsLength = activeView.fields.filter((field) => fieldListItemsById[field.id].sectionId === sectionId).length;

      return {
        optionId: sectionId,
        isPartiallySelected: sectionFieldsLength >= 1 && sectionFieldsLength < allFieldIds.filter((id) => fieldListItemsById[id].sectionId === sectionId).length
      };
    })
);

export const selectIsPeopleDirectoryLoaded = (state: RootState) => state.peopleDir.isPeopleDirectoryLoaded;

export const selectEmployeeRecordsTotalCount = (state: RootState) => state.peopleDir.ui.employeeRecordsTotalCount;

export const selectPageNumber = (state: RootState) => state.peopleDir.ui.pageNumber;
export const selectPageSize = (state: RootState) => state.peopleDir.ui.pageSize;

export const selectMemoizedPagesStatusText = createSelector(
  [selectPageNumber, selectPageSize, selectEmployeeRecordsTotalCount],
  (pageNumber, pageSize, totalCount) => {
    const start = (pageNumber - 1) * pageSize + 1;
    const end = Math.min(pageNumber * pageSize, totalCount);

    return `${start} - ${end} of ${totalCount}`;
  }
);

export const selectPagesCount = createSelector([selectEmployeeRecordsTotalCount, selectPageSize], (totalCount, pageSize) => Math.ceil(totalCount / pageSize));

export const selectEmployeeItemsById = (state: RootState) => state.peopleDir.entities.employeeListItems.byId;
export const selectSitesById = (state: RootState) => state.peopleDir.entities.sites.byId;
export const selectDepartmentsById = (state: RootState) => state.peopleDir.entities.departments.byId;

export const selectRetrieveTwoStepDataStatus = (state: RootState) => state.peopleDir.ui.retrieveTwoStepDataStatus;

export const {
  createPeopleDirViewFailed,
  createPeopleDirViewRequested,
  createPeopleDirViewSucceeded,
  deletePeopleDirViewFailed,
  deletePeopleDirViewRequested,
  deletePeopleDirViewSucceeded,
  toggleVisibilityPeopleDirView,
  loadPeopleDirectoryFailed,
  loadPeopleDirectoryRequested,
  loadPeopleDirectorySucceeded,
  renameSavedViewFailed,
  renameSavedViewRequested,
  renameSavedViewSucceeded,
  retrieveAllPeopleDirCategoriesSectionsFieldsFailed,
  retrieveAllPeopleDirCategoriesSectionsFieldsRequested,
  retrieveAllPeopleDirCategoriesSectionsFieldsSucceeded,
  retrieveEmployeeRecordsFailed,
  retrieveEmployeeRecordsRequested,
  retrieveEmployeeRecordsSucceeded,
  retrievePeopleDirViewsFailed,
  retrievePeopleDirViewsRequested,
  retrievePeopleDirViewsSucceeded,
  selectCategory,
  selectField,
  selectSection,
  setActivePeopleDirViewId,
  updatePeopleDirViewFailed,
  updatePeopleDirViewRequested,
  updatePeopleDirViewSucceeded,
  retrieveTwoStepDataRequested,
  retrieveTwoStepDataSucceeded,
  retrieveTwoStepDataFailed
} = peopleDirSlice.actions;

export default peopleDirSlice.reducer;
