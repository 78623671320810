import { PaperProps } from '@mui/material';
import { EmployeeListItem } from '@thrivea/organization-client';
import { forwardRef } from 'react';
import { PaperTransparent } from '@features/admin-settings';

export const CustomPermissionGroupAutocompletePaper = forwardRef<HTMLDivElement, PaperProps & { selectedOptions: EmployeeListItem[] | undefined }>(
  ({ children, selectedOptions, ...props }, ref) => (
    <PaperTransparent {...props} ref={ref} elevation={0}>
      {children}
    </PaperTransparent>
  )
);
