import React, { useState, useEffect } from 'react';
import { Stack, Box, Typography, Button, Modal } from '@mui/material';
import { AddIcon, RowCenterStack, StyledIconButton, StyledImageGradient, StyledModalContent } from '@/shared';
import { Close } from '@mui/icons-material';
import { FileUploader } from 'react-drag-drop-files';
import { IMAGE_FILE_TYPES } from '@/utils';
import { ImageCropper, MultiSizeImageBlobs } from './ImageCropper';

interface InlineImageUploadProps {
  isEditable: boolean;
  width: number;
  height: number;
  sm?: number;
  md?: number;
  lg?: number;
  buttonTextLabel: string;
  removeTextLabel: string;
  uploadTextLabel: string;
  placeholderSrc: string;
  urlSrc: string;
  imageAltText: string;
  multiSizeImage: MultiSizeImageBlobs | undefined | null;
  onChange: (file: MultiSizeImageBlobs) => void;
  onRemove: () => void;
}

export const InlineImageUpload: React.FC<InlineImageUploadProps> = ({
  isEditable,
  width,
  height,
  sm = 36,
  md = 46,
  lg = 150,
  placeholderSrc,
  urlSrc,
  buttonTextLabel,
  removeTextLabel,
  uploadTextLabel,
  imageAltText,
  multiSizeImage,
  onChange,
  onRemove
}) => {
  const [open, setOpen] = useState(false);
  const [cropperFile, setCropperFile] = useState<File | null>(null);
  const [src, setSrc] = useState('');
  const isImageSet = src !== placeholderSrc || multiSizeImage;

  useEffect(() => {
    if (multiSizeImage === undefined) {
      setSrc(urlSrc || placeholderSrc);
    }
  }, [urlSrc, placeholderSrc, multiSizeImage]);

  const handleCrop = (croppedDataUrl: MultiSizeImageBlobs) => {
    onChange(croppedDataUrl);
    setOpen(false);
  };

  const handleFileChangeClick = (file: File) => {
    setCropperFile(file);
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  const handleRemoveButtonClick = () => {
    setSrc(placeholderSrc);
    onRemove();
  };

  return (
    <Stack
      gap={2}
      sx={{
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
        position: 'relative',
        '&:hover': {
          '& .Mui-LogoAction': {
            opacity: 1
          }
        }
      }}
    >
      <Stack
        sx={{
          width,
          height
        }}
      >
        <StyledImageGradient isEditable={isEditable} width={width} height={height} />
        <Box
          component="img"
          src={multiSizeImage ? URL.createObjectURL(multiSizeImage.lg) : src}
          alt={imageAltText}
          width={width}
          height={height}
          loading="lazy"
          sx={{
            position: 'relative',
            zIndex: 1,
            objectFit: 'contain',
            borderRadius: '7px'
          }}
        />
        {isEditable && (
          <Stack
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              cursor: 'pointer',
              zIndex: 3,
              alignItems: 'flex-start',
              justifyContent: 'center',
              width,
              height,
              label: {
                width,
                height: isImageSet ? 'calc(100% - 40px)' : height,
                display: 'flex',
                alignItems: isImageSet ? 'center' : 'flex-start',
                justifyContent: 'center',
                p: isImageSet ? 0 : 1
              }
            }}
          >
            {isImageSet && (
              <Box
                onClick={handleRemoveButtonClick}
                sx={{
                  zIndex: 2,
                  width: '100%'
                }}
              >
                <RowCenterStack>
                  <StyledIconButton>
                    <Close
                      sx={{
                        width: 24,
                        height: 24,
                        color: (theme) => theme.palette.common.white
                      }}
                    />
                  </StyledIconButton>
                  <Typography
                    className="Mui-LogoAction"
                    sx={{
                      fontSize: 14,
                      color: (theme) => theme.palette.common.white,
                      opacity: 0,
                      transition: 'opacity 0.3s ease-in-out'
                    }}
                  >
                    {removeTextLabel}
                  </Typography>
                </RowCenterStack>
              </Box>
            )}
            <FileUploader multiple={false} handleChange={handleFileChangeClick} name="file" maxSize={10} types={IMAGE_FILE_TYPES} classes="Mui-FileUpload">
              <Typography
                className="Mui-LogoAction"
                sx={{
                  fontSize: 14,
                  color: (theme) => theme.palette.common.white,
                  width: '100%',
                  textAlign: 'center',
                  transform: isImageSet ? 'translateY(-20px)' : 'none',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: isImageSet ? 'center' : 'flex-start'
                }}
              >
                <AddIcon color="#FFFFFF" />
                {src === placeholderSrc ? buttonTextLabel : 'Update image'}
              </Typography>
            </FileUploader>
          </Stack>
        )}
      </Stack>
      {isEditable && (
        <Stack
          gap={1}
          sx={{
            width: `calc(100% - ${width + 50}px)`,
            '& .Mui-FileUpload:focus-within': {
              outline: 'none'
            }
          }}
        >
          <FileUploader multiple={false} handleChange={handleFileChangeClick} name="file" types={IMAGE_FILE_TYPES} classes="Mui-FileUpload">
            <Button
              endIcon={<AddIcon />}
              sx={{
                padding: '6px 20px 7px 26px',
                justifyContent: 'space-between',
                backgroundColor: (theme) => theme.palette.background.default,
                borderRadius: '7px',
                fontWeight: 700,
                color: (theme) => theme.palette.customTheme.contColor,
                '& path': {
                  fill: (theme) => theme.palette.customTheme.contColor
                }
              }}
              variant="outlined"
            >
              {buttonTextLabel}
            </Button>
          </FileUploader>
          <Typography variant="body2" sx={{ fontWeight: 400 }}>
            {uploadTextLabel}
          </Typography>
        </Stack>
      )}
      <Modal
        open={open}
        onClose={handleModalClose}
        sx={{
          background: 'rgba(217, 217, 217, 0.60)',
          backdropFilter: 'blur(10px)'
        }}
      >
        <StyledModalContent sx={{ minWidth: 1000, padding: 0, borderRadius: '4px' }}>
          {cropperFile && (
            <ImageCropper src={cropperFile} onCrop={handleCrop} handleModalClose={handleModalClose} aspectRatio={width / height} sm={sm} md={md} lg={lg} />
          )}
        </StyledModalContent>
      </Modal>
    </Stack>
  );
};
