import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { RetrieveDepartmentsResponse } from '@thrivea/organization-client';
import * as Sentry from '@sentry/react';
import { PayloadAction } from '@reduxjs/toolkit';
import { retrieveDepartments } from '@api/employee-profile.api';
import { Empty } from '@bufbuild/protobuf/wkt';
import { retrieveDepartmentsFailed, retrieveDepartmentsRequested, retrieveDepartmentsSucceeded } from '@features/shared';

function* retrieveDepartmentsRequestedGenerator(action: PayloadAction<Empty>) {
  try {
    const response: RetrieveDepartmentsResponse = yield call(retrieveDepartments, action.payload);
    yield put(retrieveDepartmentsSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveDepartmentsFailed());
  }
}

function* retrieveDepartmentsRequestedWatcher() {
  yield takeLatest(retrieveDepartmentsRequested.type, retrieveDepartmentsRequestedGenerator);
}

export function* departmentSagas() {
  yield all([fork(retrieveDepartmentsRequestedWatcher)]);
}
