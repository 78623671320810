import { Stack, StackProps, styled } from '@mui/material';

export const AudienceGroupAutocompletePaperStack = styled(Stack)<StackProps>(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  backgroundColor: theme.palette.customTheme.drawerBackground,
  padding: '16px',
  height: 40,
  borderRadius: '6px'
}));
