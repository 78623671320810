import { create } from '@bufbuild/protobuf';
import { useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import {
  CancelEditIcon,
  PrimaryDarkButton,
  RowCenterEndStack,
  RowCenterStack,
  SecondaryDarkButton,
  StyledBlurryModal,
  StyledTransparentFilledInput,
  StyledWorkDayFilledInput
} from '@/shared';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { CreateWorkingPatternRequest, CreateWorkingPatternRequestSchema, DayOfWeek } from '@thrivea/organization-client';
import { Controller, useForm } from 'react-hook-form';
import { createWorkingPatternRequested, createWorkingPatternSchema, ModalCloseButton, ModalContent } from '@features/admin-settings';
import { useAppDispatch } from '@app/hooks';
import { zodResolver } from '@hookform/resolvers/zod';
import { keys } from 'lodash';

interface AdminSettingsWorkingPatternAddProps {
  isOpen: boolean;
  handleCloseModal: () => void;
}

const daysOfWeek = keys(DayOfWeek)
  .filter((key) => key !== 'DAY_OF_WEEK_UNSPECIFIED' && isNaN(Number(key))) // Filter out 'DAY_OF_WEEK_UNSPECIFIED' and numeric keys
  .map((key) => ({
    id: DayOfWeek[key as keyof typeof DayOfWeek],
    name: key
      .toLowerCase()
      .replace(/_/g, ' ')
      .replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
  }));

export const AdminSettingsWorkingPatternAdd = ({ isOpen, handleCloseModal }: AdminSettingsWorkingPatternAddProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['common', 'sites_working_patterns']);
  const workingPatternSchema = createWorkingPatternSchema(t);

  const { handleSubmit, reset, control, getValues } = useForm<CreateWorkingPatternRequest>({
    mode: 'all',
    resolver: zodResolver(workingPatternSchema),
    defaultValues: {
      name: '',
      description: '',
      workDays: []
    }
  });
  const [totalHours, setTotalHours] = useState(0);
  const [_workHours, setWorkHours] = useState(daysOfWeek.map(() => 0));

  const handleHoursChange = (index: number, value: number) => {
    setWorkHours((prev) => {
      const newHours = [...prev];
      newHours[index] = value;
      setTotalHours(newHours.reduce((acc, hour) => acc + hour, 0));

      return newHours;
    });
  };

  const onSubmit = ({ name, description, workDays }: CreateWorkingPatternRequest) => {
    // Ensure all days are included, with hours=0 for unedited days
    const completeWorkDays = daysOfWeek.map((day, index) => {
      // Try to find this day in the submitted workDays
      const existingDay = workDays.find((wd) => wd.day === day.id || (wd.day === undefined && getValues(`workDays.${index}.day`) === day.id));

      return {
        id: existingDay?.id || uuidv4(),
        day: day.id,
        hours: existingDay?.hours || 0
      };
    });

    dispatch(
      createWorkingPatternRequested(
        create(CreateWorkingPatternRequestSchema, {
          name,
          description,
          workDays: completeWorkDays
        })
      )
    );

    reset({
      name: '',
      description: '',
      workDays: []
    });

    handleCloseModal();
  };

  return (
    <StyledBlurryModal open={isOpen} onClose={handleCloseModal}>
      <ModalContent>
        <Stack component="form" onSubmit={handleSubmit(onSubmit)} gap={3} sx={{ width: '50%' }}>
          <RowCenterStack>
            <Typography variant="h4"> {t('add_working_pattern_form.btn_txt', { ns: 'settings_working_patterns' })}</Typography>
            <ModalCloseButton onClick={handleCloseModal}>
              <CloseIcon />
            </ModalCloseButton>
          </RowCenterStack>
          <Typography variant="body1">{t('add_working_pattern_form.title', { ns: 'settings_working_patterns' })}</Typography>
          <Controller
            name="name"
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState }) => (
              <StyledTransparentFilledInput
                {...field}
                required
                id={field.name}
                label={t('add_working_pattern_form.name_label', { ns: 'settings_working_patterns' })}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
          <Controller
            name="description"
            control={control}
            render={({ field, fieldState }) => (
              <StyledTransparentFilledInput
                {...field}
                multiline
                minRows={3}
                id={field.name}
                label={t('add_working_pattern_form.desc_label', { ns: 'settings_working_patterns' })}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
          <RowCenterStack>
            {daysOfWeek.map((day, index) => (
              <div key={day.id}>
                <Controller
                  name={`workDays.${index}.hours`}
                  defaultValue={0}
                  control={control}
                  rules={{ min: 0, max: 24 }}
                  render={({ field }) => (
                    <StyledWorkDayFilledInput
                      {...field}
                      label={t(`add_working_pattern_form.${day.name.toLowerCase()}`, { ns: 'settings_working_patterns' })}
                      id={`working_pattern_${day.name}`}
                      width={55}
                      onChange={(e) => {
                        const val = e.target.value === '' ? 0 : parseFloat(e.target.value);
                        if (val < 0 || val > 24) {
                          e.preventDefault();
                        } else {
                          field.onChange(val);
                          handleHoursChange(index, val);
                        }
                      }}
                      slotProps={{ input: { inputMode: 'numeric' } }}
                    />
                  )}
                />
                <input type="hidden" {...control.register(`workDays.${index}.id`)} value={uuidv4()} />
                <input type="hidden" {...control.register(`workDays.${index}.day`, { valueAsNumber: true })} value={day.id} />
              </div>
            ))}
          </RowCenterStack>
          <RowCenterStack gap={1}>
            <Typography variant="body1"> {t('add_working_pattern_form.weekly_total', { ns: 'settings_working_patterns' })}</Typography>
            <Typography variant="body1"> {t('hour_with_count', { count: totalHours, ns: 'common' })}</Typography>
          </RowCenterStack>
          <RowCenterEndStack gap={2}>
            <PrimaryDarkButton onClick={handleCloseModal} startIcon={<CancelEditIcon />}>
              {t('cancel', { ns: 'common' })}
            </PrimaryDarkButton>
            <SecondaryDarkButton type="submit">{t('save', { ns: 'common' })}</SecondaryDarkButton>
          </RowCenterEndStack>
        </Stack>
      </ModalContent>
    </StyledBlurryModal>
  );
};
