import { forwardRef } from 'react';
import { TreeItem2Provider } from '@mui/x-tree-view/TreeItem2Provider';
import { useTreeItem2, UseTreeItem2Parameters } from '@mui/x-tree-view/useTreeItem2';
import { AssetsModalTreeItemLabel, AssetsTransitionComponent, AssetsTreeItemContent, AssetsTreeItemIcon, AssetsTreeItemRoot } from '@features/assets';
import { TreeItem2Checkbox, TreeItem2IconContainer } from '@mui/x-tree-view/TreeItem2';
import { TreeItem2DragAndDropOverlay } from '@mui/x-tree-view/TreeItem2DragAndDropOverlay';
import clsx from 'clsx';
import { ChevronRight } from '@mui/icons-material';
import { ChevronDownIcon } from 'src/shared';

interface AssetsModalTreeItemProps extends Omit<UseTreeItem2Parameters, 'rootRef'>, Omit<React.HTMLAttributes<HTMLLIElement>, 'onFocus'> { }

const isExpandable = (reactChildren: React.ReactNode) => {
  if (Array.isArray(reactChildren)) {
    return reactChildren.length > 0 && reactChildren.some(isExpandable);
  }
  return Boolean(reactChildren);
};

export const AssetsModalTreeItem = forwardRef(function AssetsTreeItem(props: AssetsModalTreeItemProps, ref: React.Ref<HTMLLIElement>) {
  const { id, itemId, label, disabled, children, ...other } = props;

  const uniqueId = `${id}-${itemId}-${label}`; // Generate a unique ID for each item

  const {
    getRootProps,
    getContentProps,
    getIconContainerProps,
    getCheckboxProps,
    getLabelProps,
    getDragAndDropOverlayProps,
    status,
    getGroupTransitionProps,
    publicAPI
  } = useTreeItem2({ id: uniqueId, itemId, children, label, disabled, rootRef: ref }); // Pass `uniqueId` here

  const item = publicAPI.getItem(itemId);
  const expandable = isExpandable(children);
  const icon = () => <AssetsTreeItemIcon level={item.nestingLevel} />;

  return (
    <TreeItem2Provider getItemId={(item) => crypto.randomUUID()} itemId={item.id} sx={{ width: '100%' }}>
      <AssetsTreeItemRoot
        sx={{
          width: '100%'
        }}
        {...getRootProps(other)}
      >
        <AssetsTreeItemContent
          {...getContentProps({
            className: clsx('content', {
              'Mui-expanded': status.expanded,
              'Mui-selected': status.selected,
              'Mui-focused': status.focused,
              'Mui-disabled': status.disabled
            })
          })}
        >
          {status.expandable && (
            <TreeItem2IconContainer {...getIconContainerProps()}>{status.expanded ? <ChevronDownIcon /> : <ChevronRight />}</TreeItem2IconContainer>
          )}
          <TreeItem2Checkbox {...getCheckboxProps()} />
          <AssetsModalTreeItemLabel {...getLabelProps({ id: item.id, icon, expandable: expandable && status.expanded, focused: status.focused })} />
          <TreeItem2DragAndDropOverlay {...getDragAndDropOverlayProps()} />
        </AssetsTreeItemContent>
        {children && <AssetsTransitionComponent {...getGroupTransitionProps()} />}
      </AssetsTreeItemRoot>
    </TreeItem2Provider>
  );
});
