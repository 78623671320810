import { create } from '@bufbuild/protobuf';
import React, { useCallback, useEffect, useState } from 'react';
import { Stack, Typography, Box, MenuItem, AutocompleteInputChangeReason } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Abc, Add as AddIcon } from '@mui/icons-material';
import {
  HighlightText,
  RowCenterStack,
  StyledGroupWrapper,
  StyledCountOpenDrawerButton,
  StyledGroupCheckbox,
  ChevronDownIcon,
  PictureSizeSuffix,
  ActionStatus,
  StyledAvatar,
  ThinBorderCheckbox,
  StyledFormControlLabel,
  StyledSelect,
  RowCenterEndStackWrap,
  RowCenterJustifyBetweenStack,
  MembersInput
} from '@/shared';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import {
  retrieveAudienceGroupAutocompleteItemsRequested,
  addSelectedEmployeeToAudienceGroupRequested,
  selectSiteReadSasToken,
  setCurrentJobTitle,
  retrieveAudienceGroupSelectedEmployeesTotalCountRequested,
  selectAudienceGroupAutocompleteSelectedEmployeesTotalCount,
  selectAudienceGroupAutocompleteItemsStatus,
  GroupAutocompleteItemsSkeleton,
  CustomAudienceGroupAutocompletePopper,
  CustomAudienceGroupAutocompletePaper,
  CustomAudienceGroupAutocompletePaperProps,
  selectAudienceGroupAutocompleteOptions,
  noSelectedItems,
  changeRoleForSelectedEmployees,
  selectCurrentAudienceRole,
  selectChosenAudienceGroupMemberIds,
  GroupAutocomplete,
  AddNewGroupButton,
  ListSubHeader,
  AudienceListItemButton,
  AudienceDivider,
  GroupTitle
} from '@features/admin-settings';
import {
  EmployeeListItem,
  JobTitleListItem,
  QueryFilter,
  QueryFilterSchema,
  RetrieveEmployeesByQueryRequestSchema,
  RetrieveEmployeesBySelectionRequestSchema,
  RetrieveEmployeesTotalCountBySelectedRequestSchema,
  SearchFilterCategory,
  SiteListItem
} from '@thrivea/organization-client';
import { useTranslation } from 'react-i18next';
import { initials, pictureUrl } from '@/utils';
import { selectEmployeeProfileAndCoverReadSasToken } from '@features/employee-profile';
import { AudienceRole } from '@thrivea/networking-client';
import debounce from 'lodash/debounce';
import { DrawerType, openDrawer, OpenDrawerRequest } from '@features/drawer';

interface AdminSettingsAudienceGroupAutocompleteProps {
  isEditable: boolean;
}

type OptionType = SiteListItem | JobTitleListItem | EmployeeListItem;

export const isEmployeeListItem = (option: OptionType | string): option is EmployeeListItem => {
  return (option as EmployeeListItem).employeeId !== undefined;
};

export const isJobTitleListItem = (option: OptionType): option is JobTitleListItem => {
  return (option as JobTitleListItem).name !== undefined && (option as SiteListItem).address === undefined;
};

export const isSiteListItem = (option: OptionType): option is SiteListItem => {
  return (option as SiteListItem).address !== undefined;
};

export const AdminSettingsAudienceGroupAutocomplete: React.FC<AdminSettingsAudienceGroupAutocompleteProps> = ({ isEditable }) => {
  const { t } = useTranslation(['common']);
  const dispatch = useAppDispatch();

  const employeeProfileSasToken = useAppSelector(selectEmployeeProfileAndCoverReadSasToken);
  const siteSasToken = useAppSelector(selectSiteReadSasToken);
  const selectedAudienceGroupEmployeesTotalCount = useAppSelector(selectAudienceGroupAutocompleteSelectedEmployeesTotalCount);
  const chosenAudienceGroupEmployeeIds = useAppSelector(selectChosenAudienceGroupMemberIds);

  const [searchText, setSearchText] = useState('');

  const [isSitesFilterChecked, setIsSitesFilterChecked] = useState<boolean>(true);
  const [isJobTitleFilterChecked, setIsJobTitleFilterChecked] = useState<boolean>(true);

  const selectedAudienceRole = useAppSelector(selectCurrentAudienceRole);

  const [selectedEmployeeIds, setSelectedEmployeeIds] = useState<string[]>([]);
  const [selectedSiteIds, setSelectedSiteIds] = useState<string[]>([]);
  const [selectedJobTitleNames, setSelectedJobTitleNames] = useState<string[]>([]);

  const audienceGroupAutocompleteOptions = useAppSelector((state) => selectAudienceGroupAutocompleteOptions(state, searchText));
  const audienceGroupAutocompleteItemsStatus = useAppSelector(selectAudienceGroupAutocompleteItemsStatus);

  useEffect(() => {
    if (hasSelected())
      dispatch(
        retrieveAudienceGroupSelectedEmployeesTotalCountRequested(
          create(RetrieveEmployeesTotalCountBySelectedRequestSchema, {
            selectedEmployeeIds: selectedEmployeeIds,
            selectedJobTitleNames: selectedJobTitleNames,
            selectedSitesIds: selectedSiteIds
          })
        )
      );
    else dispatch(noSelectedItems());
  }, [selectedEmployeeIds, selectedSiteIds, selectedJobTitleNames]);

  const debouncedSearch = useCallback(
    debounce((value) => {
      const filters: QueryFilter[] =
        value.length >= 2
          ? [
              create(QueryFilterSchema, {
                filter: SearchFilterCategory.SITE,
                pageNumber: 1,
                pageSize: 3
              }),
              create(QueryFilterSchema, {
                filter: SearchFilterCategory.JOB_TITLE,
                pageNumber: 1,
                pageSize: 3
              }),
              create(QueryFilterSchema, {
                filter: SearchFilterCategory.EMPLOYEE,
                pageNumber: 1,
                pageSize: 10
              })
            ]
          : [
              create(QueryFilterSchema, {
                filter: SearchFilterCategory.EMPLOYEE,
                pageNumber: 1,
                pageSize: 10
              })
            ];

      dispatch(
        retrieveAudienceGroupAutocompleteItemsRequested(
          create(RetrieveEmployeesByQueryRequestSchema, {
            searchText: value,
            filters
          })
        )
      );
    }, 500),
    []
  );

  useEffect(() => {
    debouncedSearch(searchText);

    // Cleanup the debounced function
    return () => {
      debouncedSearch.cancel();
    };
  }, [searchText, debouncedSearch]);

  const hasSelected = () => selectedEmployeeIds.length > 0 || selectedSiteIds.length > 0 || selectedJobTitleNames.length > 0;

  const handleInputChange = (event: React.SyntheticEvent, value: string, reason: AutocompleteInputChangeReason): void => {
    setSearchText(value);
  };

  const handleAddToGroupClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();

    dispatch(
      addSelectedEmployeeToAudienceGroupRequested(
        create(RetrieveEmployeesBySelectionRequestSchema, {
          selectedEmployeeIds: selectedEmployeeIds,
          selectedJobTitleNames: selectedJobTitleNames,
          selectedSitesIds: selectedSiteIds
        })
      )
    );

    setSelectedEmployeeIds([]);
    setSelectedJobTitleNames([]);
    setSelectedSiteIds([]);
  };

  const isOptionSelected = (option: OptionType) => {
    if (isSiteListItem(option)) {
      return selectedSiteIds.some((site) => site === option.id);
    }

    if (isJobTitleListItem(option)) {
      return selectedJobTitleNames.some((job) => job === option.name);
    }

    if (isEmployeeListItem(option)) {
      return selectedEmployeeIds.some((employeeId) => employeeId === option.employeeId);
    }

    return false;
  };

  const handleSelectedCheckboxChange = (option: OptionType, event: React.ChangeEvent<HTMLInputElement>) => {
    if (isSiteListItem(option)) {
      setSelectedSiteIds((prev) => {
        if (event.target.checked) {
          return !prev.includes(option.id) ? [...prev, option.id] : prev;
        } else {
          return prev.filter((siteId) => siteId !== option.id);
        }
      });
    }

    if (isJobTitleListItem(option)) {
      setSelectedJobTitleNames((prev) => {
        if (event.target.checked) {
          return !prev.includes(option.name) ? [...prev, option.name] : prev;
        } else {
          return prev.filter((jobName) => jobName !== option.name);
        }
      });
    }

    if (isEmployeeListItem(option)) {
      setSelectedEmployeeIds((prev) => {
        if (event.target.checked) {
          return !prev.includes(option.employeeId) ? [...prev, option.employeeId] : prev;
        } else {
          return prev.filter((employeeId) => employeeId !== option.employeeId);
        }
      });
    }
  };

  const handleSitesFilterCheckboxClick = useCallback(() => {
    setIsSitesFilterChecked((prev) => !prev);
  }, []);

  const handleJobTitleFilterCheckboxClick = useCallback(() => {
    setIsJobTitleFilterChecked((prev) => !prev);
  }, []);

  const handleAutocompleteItemSiteClick = (e: React.MouseEvent<HTMLButtonElement | HTMLDivElement, MouseEvent>, siteId: string) => {
    e.stopPropagation();
    e.preventDefault();

    dispatch(openDrawer({ type: DrawerType.Site, request: { siteId } } as OpenDrawerRequest));
  };

  const handleAutocompleteItemEmployeeClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, employeeId: string) => {
    e.stopPropagation();
    e.preventDefault();

    dispatch(openDrawer({ type: DrawerType.SingleEmployee, request: { employeeId } } as OpenDrawerRequest));
  };

  const handleAutocompleteItemJobTitleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, jobTitle: string) => {
    e.stopPropagation();
    e.preventDefault();

    dispatch(setCurrentJobTitle(jobTitle));
    dispatch(openDrawer({ type: DrawerType.JobTitle, request: { jobTitle } } as OpenDrawerRequest));
  };

  return (
    <Stack gap={2}>
      <StyledGroupWrapper expanded disabled={!isEditable}>
        <GroupAutocomplete
          disabled={!isEditable}
          onInputChange={handleInputChange}
          loading={audienceGroupAutocompleteItemsStatus === ActionStatus.Pending}
          loadingText={<GroupAutocompleteItemsSkeleton />}
          multiple
          renderTags={() => null}
          options={audienceGroupAutocompleteOptions.filter(
            (option) => !(isJobTitleListItem(option) && !isJobTitleFilterChecked) && !(isSiteListItem(option) && !isSitesFilterChecked)
          )}
          filterOptions={(x) => x}
          noOptionsText={t('no_results', { ns: 'common', query: searchText })}
          disableCloseOnSelect
          disableClearable
          clearOnBlur={false}
          blurOnSelect={false}
          clearOnEscape={true}
          popupIcon={<ChevronDownIcon />}
          forcePopupIcon
          groupBy={(option) => {
            if (searchText.length >= 2) {
              if (isSiteListItem(option)) {
                return t('sites_label', { ns: 'common' });
              }

              if (isJobTitleListItem(option)) {
                return t('job_titles_label', { ns: 'common' });
              }

              if (isEmployeeListItem(option)) {
                return t('employees_label', { ns: 'common' });
              }

              return 'Other'; // Handle unexpected types if any
            } else {
              if (isEmployeeListItem(option)) {
                return option.displayName[0].toUpperCase();
              }

              return t('employees_label', { ns: 'common' });
            }
          }}
          PopperComponent={CustomAudienceGroupAutocompletePopper}
          renderInput={(params) => <MembersInput {...params} variant="outlined" placeholder={t('search_audience_placeholder', { ns: 'settings_audience' })} />}
          renderOption={(_props, option) => {
            const key = 'id' in option ? option.id : 'name' in option ? option.name : 'employeeId' in option ? option.employeeId : crypto.randomUUID();

            return (
              <AudienceListItemButton key={key} selected={isOptionSelected(option)}>
                {isSiteListItem(option) && (
                  <StyledFormControlLabel
                    control={
                      <StyledGroupCheckbox
                        icon={<ThinBorderCheckbox />}
                        checked={isOptionSelected(option)}
                        onChange={(event) => {
                          handleSelectedCheckboxChange(option, event);
                        }}
                      />
                    }
                    label={
                      <Grid container>
                        <Grid size={6} alignContent="left">
                          <RowCenterStack gap={1}>
                            <StyledAvatar
                              src={pictureUrl(option.coverImageUrl, siteSasToken, PictureSizeSuffix.lg)}
                              width={34}
                              height={34}
                              sx={{
                                borderRadius: '4px'
                              }}
                            >
                              {initials(option.city)}
                            </StyledAvatar>
                            <Stack>
                              <HighlightText color="rgba(47, 188, 196, 0.40)" bold text={option.name} highlight={searchText} />
                              <HighlightText color="rgba(47, 188, 196, 0.40)" text={option.address} highlight={searchText} />
                            </Stack>
                          </RowCenterStack>
                        </Grid>
                        <Grid size={4} alignContent="left">
                          <StyledCountOpenDrawerButton
                            onClick={(e) => {
                              handleAutocompleteItemSiteClick(e, option.id);
                            }}
                          >
                            {t('employee_with_count', { ns: 'common', count: option.employeeCount })}
                          </StyledCountOpenDrawerButton>
                        </Grid>
                      </Grid>
                    }
                  />
                )}
                {isJobTitleListItem(option) && (
                  <StyledFormControlLabel
                    control={
                      <StyledGroupCheckbox
                        icon={<ThinBorderCheckbox />}
                        checked={isOptionSelected(option)}
                        onChange={(event) => {
                          handleSelectedCheckboxChange(option, event);
                        }}
                      />
                    }
                    label={
                      <Grid container>
                        <Grid size={6} alignContent="left">
                          <RowCenterStack gap={2}>
                            <Abc />
                            <HighlightText color="rgba(47, 188, 196, 0.40)" bold text={option.name} highlight={searchText} />
                          </RowCenterStack>
                        </Grid>
                        <Grid size={4} alignContent="left">
                          <StyledCountOpenDrawerButton
                            onClick={(e) => {
                              handleAutocompleteItemJobTitleClick(e, option.name);
                            }}
                          >
                            {t('employee_with_count', { ns: 'common', count: option.employeeCount })}
                          </StyledCountOpenDrawerButton>
                        </Grid>
                      </Grid>
                    }
                  />
                )}
                {isEmployeeListItem(option) && (
                  <StyledFormControlLabel
                    disabled={chosenAudienceGroupEmployeeIds.includes(option.employeeId)}
                    control={
                      <StyledGroupCheckbox
                        icon={<ThinBorderCheckbox />}
                        checked={isOptionSelected(option) || chosenAudienceGroupEmployeeIds.includes(option.employeeId)}
                        onChange={(event) => {
                          handleSelectedCheckboxChange(option, event);
                        }}
                      />
                    }
                    label={
                      <Grid container rowSpacing={1}>
                        <Grid size={{ xs: 12, md: 4 }} alignContent="left">
                          <RowCenterStack gap={1}>
                            <StyledAvatar
                              onClick={(e) => handleAutocompleteItemEmployeeClick(e, option.employeeId)}
                              src={pictureUrl(option.profilePictureUrl, employeeProfileSasToken, PictureSizeSuffix.sm)}
                              width={34}
                              height={34}
                            >
                              {initials(option.displayName)}
                            </StyledAvatar>
                            <Stack>
                              <HighlightText color="rgba(47, 188, 196, 0.40)" bold text={option.displayName} highlight={searchText} />
                              <HighlightText color="rgba(47, 188, 196, 0.40)" text={option.jobTitle} highlight={searchText} />
                            </Stack>
                          </RowCenterStack>
                        </Grid>
                        <Grid size={{ xs: 12, md: 4 }} alignContent="left">
                          <HighlightText color="rgba(47, 188, 196, 0.40)" text={option.emailAddress} highlight={searchText} />
                        </Grid>
                        <Grid size={{ xs: 12, md: 4 }} alignContent="left">
                          <StyledCountOpenDrawerButton
                            disabled={chosenAudienceGroupEmployeeIds.includes(option.employeeId)}
                            sx={{ justifyContent: 'left' }}
                            onClick={(e) => {
                              handleAutocompleteItemSiteClick(e, option.siteId);
                            }}
                          >
                            <HighlightText color="rgba(47, 188, 196, 0.40)" text={option.siteName} highlight={searchText} />
                          </StyledCountOpenDrawerButton>
                        </Grid>
                      </Grid>
                    }
                  />
                )}
              </AudienceListItemButton>
            );
          }}
          renderGroup={(params) => (
            <Box component="div" key={params.key}>
              <ListSubHeader disableGutters disableSticky inset>
                <RowCenterJustifyBetweenStack
                  sx={{
                    mb: 1,
                    height: 20,
                    gap: '20px'
                  }}
                >
                  <GroupTitle variant="body2">{params.group}</GroupTitle>
                  <AudienceDivider />
                </RowCenterJustifyBetweenStack>
              </ListSubHeader>
              {params.children}
            </Box>
          )}
          getOptionLabel={(option) => {
            if (isJobTitleListItem(option) || isSiteListItem(option)) {
              return option.name;
            }

            if (isEmployeeListItem(option)) {
              return option.displayName;
            }

            return option;
          }}
          slots={{
            paper: CustomAudienceGroupAutocompletePaper
          }}
          slotProps={{
            paper: {
              sitesSelected: isSitesFilterChecked,
              jobTitlesSelected: isJobTitleFilterChecked,
              handleSitesChecked: handleSitesFilterCheckboxClick,
              handleJobTitlesChecked: handleJobTitleFilterCheckboxClick,
              searchText: searchText
            } as CustomAudienceGroupAutocompletePaperProps
          }}
        />
      </StyledGroupWrapper>
      <RowCenterEndStackWrap gap={2}>
        {selectedAudienceGroupEmployeesTotalCount < 1 && (
          <Typography variant="body2" sx={{ fontWeight: 700 }}>
            {t('no_people_selected', { ns: 'settings_permissions' })}
          </Typography>
        )}
        {selectedAudienceGroupEmployeesTotalCount > 0 && (
          <Typography variant="body2" sx={{ fontWeight: 700 }}>
            {selectedAudienceGroupEmployeesTotalCount} people selected
          </Typography>
        )}
        <StyledSelect
          disabled={!isEditable}
          value={selectedAudienceRole ?? AudienceRole.AUDIENCE_ROLE_UNDEFINED}
          onChange={(e) => dispatch(changeRoleForSelectedEmployees(e.target.value as AudienceRole))}
          IconComponent={() => {
            return (
              <Box className="MuiSvg-icon">
                <ChevronDownIcon />
              </Box>
            );
          }}
          renderValue={(_selected) => (
            <RowCenterStack>
              <Typography variant="caption" sx={{ fontWeight: 700, color: (theme) => theme.palette.customTheme.globalContentColorMuted }}>
                {t(AudienceRole[selectedAudienceRole].toLowerCase(), { ns: 'settings_permissions' })}
              </Typography>
              <MenuItem disabled>{t('chose_role', { ns: 'settings_permissions' })}</MenuItem>
            </RowCenterStack>
          )}
        >
          <MenuItem value={AudienceRole.AUDIENCE_ROLE_UNDEFINED} disabled>
            {t('chose_role', { ns: 'settings_permissions' })}
          </MenuItem>
          <MenuItem key={AudienceRole.VIEWER} value={AudienceRole.VIEWER}>
            {t(AudienceRole[AudienceRole.VIEWER].toLowerCase(), { ns: 'settings_permissions' })}
          </MenuItem>
          <MenuItem key={AudienceRole.COMMENTER} value={AudienceRole.COMMENTER}>
            {t(AudienceRole[AudienceRole.COMMENTER].toLowerCase(), { ns: 'settings_permissions' })}
          </MenuItem>
          <MenuItem key={AudienceRole.CONTRIBUTOR} value={AudienceRole.CONTRIBUTOR}>
            {t(AudienceRole[AudienceRole.CONTRIBUTOR].toLowerCase(), { ns: 'settings_permissions' })}
          </MenuItem>
          <MenuItem key={AudienceRole.EDITOR} value={AudienceRole.EDITOR}>
            {t(AudienceRole[AudienceRole.EDITOR].toLowerCase(), { ns: 'settings_permissions' })}
          </MenuItem>
          <MenuItem key={AudienceRole.OWNER} value={AudienceRole.OWNER}>
            {t(AudienceRole[AudienceRole.OWNER].toLowerCase(), { ns: 'settings_permissions' })}
          </MenuItem>
        </StyledSelect>
        <AddNewGroupButton
          disabled={selectedAudienceGroupEmployeesTotalCount === 0}
          variant="contained"
          onMouseDown={(event) => {
            event.preventDefault();
            event.stopPropagation();
          }}
          onClick={(event) => handleAddToGroupClick(event)}
          startIcon={<AddIcon />}
        >
          {t('add_to_group', { ns: 'settings_permissions' })}
        </AddNewGroupButton>
      </RowCenterEndStackWrap>
    </Stack>
  );
};
