import { create } from '@bufbuild/protobuf';
import { Divider, Stack } from '@mui/material';
import {
  AddComment,
  reactToPostRequested,
  selectPostReactionsGroupedByEmoji,
  selectPostById,
  CommentList,
  BaseReactions,
  withdrawPostReactionRequested
} from '@features/homepage';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { ReactToPostRequest, ReactToPostRequestSchema, WithdrawPostReactionRequest } from '@thrivea/networking-client';
import { RowCenterStack } from '@/shared';
import { selectCurrentEmployeeId, selectEmployeeListItemsById } from '@features/shared';
import { EmojiPicker } from './EmojiPicker';
import { AllowedTo } from 'react-abac';
import { AudiencePermissions } from '@features/abac';
import { keys } from 'lodash';

interface Emoji {
  id: number;
  native: string;
}

interface PostActionsProps {
  postId: string;
}

export const PostActions = ({ postId }: PostActionsProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const currentUserId = useAppSelector(selectCurrentEmployeeId);
  const employee = useAppSelector(selectEmployeeListItemsById);
  const groupedPostReactionsDict = useAppSelector((state) => selectPostReactionsGroupedByEmoji(state, postId));
  const post = useAppSelector((state) => selectPostById(state, postId));

  const hasCurrentUserAlreadyReacted = (emoji: string) =>
    groupedPostReactionsDict.hasOwnProperty(emoji) && groupedPostReactionsDict[emoji]?.some((r) => r.authorId === currentUserId);

  const handleEmojiToggle = (emoji: string) => {
    if (groupedPostReactionsDict[emoji].some((reaction) => reaction.authorId === currentUserId)) {
      dispatch(
        withdrawPostReactionRequested({
          postId,
          emoji,
          authorId: currentUserId
        } as WithdrawPostReactionRequest)
      );
    } else {
      dispatch(
        reactToPostRequested({
          postId,
          emoji,
          authorId: currentUserId
        } as ReactToPostRequest)
      );
    }
  };

  const handleEmojiSelect = (emoji: Emoji) => {
    if (hasCurrentUserAlreadyReacted(emoji.native)) return;

    dispatch(reactToPostRequested(create(ReactToPostRequestSchema, { authorId: currentUserId, emoji: emoji.native, postId })));
  };

  return (
    <Stack gap={1}>
      <RowCenterStack
        gap={1}
        sx={{
          width: '100%',
          padding: '0 16px'
        }}
      >
        {keys(groupedPostReactionsDict).length > 0 && (
          <BaseReactions
            currentUserId={currentUserId}
            reactionsGroupedByEmoji={groupedPostReactionsDict}
            selectAuthors={employee}
            handleEmojiToggle={handleEmojiToggle}
          />
        )}
        <EmojiPicker onEmojiSelect={handleEmojiSelect} />
      </RowCenterStack>

      {post.commentIds.length > 0 && (
        <>
          <Divider />
          <CommentList postId={postId} />
        </>
      )}
      <Stack sx={{ padding: '16px' }}>
        <AllowedTo perform={AudiencePermissions.COMMENT_ON_POST} data={post.audienceIds[0]}>
          <AddComment postId={postId} authorId={currentUserId} audienceId={post.audienceIds[0]} />
        </AllowedTo>
      </Stack>
    </Stack>
  );
};
