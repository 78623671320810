import {
  BoolErbFieldValue,
  CalculatedErbFieldOptions,
  DateErbFieldValue,
  ErbFieldValue,
  ErbScalarField,
  ErbTableFieldRowCell,
  ErTemplateField,
  GenericSetErbFieldValue,
  GenericSetErbFieldValueLocalizedMulti,
  GenericSetErbFieldValueLocalizedSingle,
  LocalizedValue,
  NumberErbFieldValue,
  TableErbFieldValue,
  TextErbFieldValue,
  ErbTableFieldColumn,
  GenericSetLocalizedOption,
  ErbField,
  EmployeeRecord,
  ErbTableField
} from '@thrivea/organization-client';
import { DateTime } from 'luxon';
import { PpdColumn } from '../people-directory';

export const getScalarFieldValue = (field: ErbScalarField, allFieldValues: ErbFieldValue[]) => {
  if (field.isCalculated) {
    return getCalculatedValue(field.options!.kind.value as CalculatedErbFieldOptions, allFieldValues);
  }
  const value = allFieldValues.find((f) => f.erbFieldId === field.id)!;

  if (field.type!.options!.kind.case === 'dropdownSelect' && value !== undefined && value.kind.case === 'textValue') {
    const r = new GenericSetErbFieldValueLocalizedSingle({ id: getStringValue(value), values: { en: 'temp' } });
    return new GenericSetLocalizedOption({ key: getStringValue(value), translations: { en: 'temp' } });
  }
  return getNonCalculatedValue(allFieldValues.find((f) => f.erbFieldId === field.id)!);
};

export const getNonCalculatedValue = (fieldValue: ErbFieldValue) => {
  if (fieldValue === undefined) return;
  if (fieldValue.kind.case === 'textValue') {
    return getStringValue(fieldValue);
  }
  if (fieldValue.kind.case === 'numberValue') return fieldValue.kind.value.value as number;
  if (fieldValue.kind.case === 'dateValue') return fieldValue.kind.value.value as string;
  if (fieldValue.kind.case === 'boolValue') return fieldValue.kind.value.value as boolean;
  if (fieldValue.kind.case === 'genericSetValue') return fieldValue.kind.value.option;
  if (fieldValue.kind.case === 'genericSetLocalizedSingle') {
    return { key: fieldValue.kind.value.id, translations: fieldValue.kind.value.values as { [key: string]: string } } as GenericSetLocalizedOption;
  }
  if (fieldValue.kind.case === 'genericSetLocalizedMulti') {
    return Object.keys(fieldValue.kind.value.values).map((k) => {
      return { key: k, translations: (fieldValue.kind.value as GenericSetErbFieldValueLocalizedMulti).values[k].translations as { [key: string]: string } };
    });
  }
  // TODO: Add for other types
};

export const getFieldValuePpd = (field: ErbScalarField, allFieldValues: ErbFieldValue[]) => {
  if (field.isCalculated) {
    return getCalculatedValue(field.options!.kind.value as CalculatedErbFieldOptions, allFieldValues);
  }

  return getNonCalculatedValuePpd(allFieldValues.find((f) => f.erbFieldId === field.id)!);
};

const getNonCalculatedValuePpd = (fieldValue: ErbFieldValue) => {
  if (fieldValue === undefined) return undefined;

  if (fieldValue.kind.case === 'textValue') return getStringValue(fieldValue);
  if (fieldValue.kind.case === 'numberValue') return fieldValue.kind.value.value as number;
  if (fieldValue.kind.case === 'dateValue') return DateTime.fromISO(fieldValue.kind.value.value).toFormat('yyyy LLL dd') as string;
  if (fieldValue.kind.case === 'boolValue') return fieldValue.kind.value.value as boolean;
  if (fieldValue.kind.case === 'genericSetValue') return fieldValue.kind.value.option;
  if (fieldValue.kind.case === 'genericSetLocalizedSingle') {
    return fieldValue.kind.value.values['en'];
  }
  if (fieldValue.kind.case === 'genericSetLocalizedMulti') {
    return Object.keys(fieldValue.kind.value.values).map((k) => {
      return { key: k, translations: (fieldValue.kind.value as GenericSetErbFieldValueLocalizedMulti).values[k].translations as { [key: string]: string } };
    });
  }
};

const getColumnFieldValuePpd = (tableId: string, columnId: string, allFieldValues: ErbFieldValue[]) => {
  // TODO: temporary till correct seed
  if (allFieldValues.find((f) => f.erbFieldId === tableId) === undefined) return;

  const tableFieldValue = allFieldValues.find((f) => f.erbFieldId === tableId)!.kind.value as TableErbFieldValue;

  const tableFieldValuesLength = tableFieldValue.rows.length;
  const cellValue = tableFieldValue.rows[tableFieldValuesLength - 1].cells.find((f) => f.erbTableFieldColumnId === columnId);
  if (!cellValue) return;

  if (cellValue.value.case === 'textValue') return cellValue.value.value.value;
  if (cellValue.value.case === 'numberValue') return cellValue.value.value.value as number;
  if (cellValue.value.case === 'dateValue') return cellValue.value.value.value as string;
  if (cellValue.value.case === 'boolValue') return cellValue.value.value.value as boolean;
  if (cellValue.value.case === 'genericSetValue') return cellValue.value.value.option;
  if (cellValue.value.case === 'genericSetLocalizedSingle') {
    return cellValue.value.value.values['en'];
  }
  if (cellValue.value.case === 'genericSetLocalizedMulti') {
    return cellValue.value.value as GenericSetErbFieldValueLocalizedMulti;
  }
};

export const getErbScalarFieldId = (field: ErTemplateField) => (field.kind.case === 'scalarField' && field.kind.value.erbScalarField!.id) as string;

export const getStringValue = (field: ErbFieldValue) =>
  ((field.kind.case === 'textValue' || field.kind.case === 'numberValue') && field.kind.value.value) as string;

export const getDateValue = (field: ErbFieldValue) => DateTime.fromISO((field.kind.case === 'dateValue' && field.kind.value.value) as string);

export const getCalculatedValue = (fieldOptions: CalculatedErbFieldOptions, allFieldValues: ErbFieldValue[]): string | undefined => {
  const jsExpression = fieldOptions.jsExpression;
  const params = fieldOptions.params;

  try {
    const paramNames = Object.keys(params);
    const paramValues = Object.values(params).map((fieldId) => getNonCalculatedValue(allFieldValues.find((f) => f.erbFieldId === fieldId)!));

    const runFunc = new Function(...paramNames, jsExpression);

    const result = runFunc(...paramValues);

    return paramValues.some((p) => p === undefined) ? undefined : result;
  } catch (error) {
    // TODO: Sentry
    return undefined;
  }
};

export const getCalculatedValueAfterUpdate = (
  fieldOptions: CalculatedErbFieldOptions,
  unchangedFieldValues: ErbFieldValue[],
  changedFieldValues: ErbFieldValue[]
): string | undefined => {
  const jsExpression = fieldOptions.jsExpression;
  const params = fieldOptions.params;
  const allFieldValues = [...unchangedFieldValues, ...changedFieldValues];
  try {
    const paramNames = Object.keys(params);
    const paramValues = Object.values(params).map((fieldId) => getNonCalculatedValue(allFieldValues.find((f) => f.erbFieldId === fieldId)!));

    const runFunc = new Function(...paramNames, jsExpression);

    const result = runFunc(...paramValues);

    return paramValues.some((p) => p === undefined) ? undefined : result;
  } catch (error) {
    // TODO: Sentry
    return undefined;
  }
};

// better naming, it maps a value to the field type, and converts it accordingly
export const mapToColumnErbFieldValue = (column: ErbTableFieldColumn, value: any): ErbFieldValue => {
  const erbFieldValue = new ErbFieldValue();
  erbFieldValue.erbFieldId = column.id;

  if (column.type!.options!.kind.case === 'dateTime') {
    const dateValue = new DateErbFieldValue();
    dateValue.value = new Date(value).toISOString();
    erbFieldValue.kind = { case: 'dateValue', value: dateValue };
  } else if (column.type!.options!.kind.case === 'singleCheckbox') {
    const boolValue = new BoolErbFieldValue();
    boolValue.value = value as boolean;
    erbFieldValue.kind = { case: 'boolValue', value: boolValue };
  } else if (column.type!.options!.kind.case === 'multipleCheckboxes') {
    const genericSetValue = new GenericSetErbFieldValueLocalizedMulti();
    for (const val of value) {
      genericSetValue.values[val.key] = new LocalizedValue({ translations: val.translations });
    }
    erbFieldValue.kind = { case: 'genericSetLocalizedMulti', value: genericSetValue };
  } else if (column.type!.options!.kind.case === 'dropdownSelect') {
    const genericSetValue = new GenericSetErbFieldValueLocalizedSingle({ id: value.key, values: value.translations });
    erbFieldValue.kind = { case: 'genericSetLocalizedSingle', value: genericSetValue };
  } else if (column.type!.options!.kind.case === 'number') {
    const numberValue = new NumberErbFieldValue();
    numberValue.value = value;
    erbFieldValue.kind = { case: 'numberValue', value: numberValue };
  } else {
    const textValue = new TextErbFieldValue();
    textValue.value = value;
    erbFieldValue.kind = { case: 'textValue', value: textValue };
  }

  return erbFieldValue;
};

export const mapToErbFieldValue = (field: ErbScalarField, value: any): ErbFieldValue => {
  const erbFieldValue = new ErbFieldValue();
  erbFieldValue.erbFieldId = field.id;

  if (field.type!.options!.kind.case === 'dateTime') {
    const dateValue = new DateErbFieldValue();

    dateValue.value = new Date(value).toISOString();

    erbFieldValue.kind = { case: 'dateValue', value: dateValue };
  } else if (field.type!.options!.kind.case === 'singleCheckbox') {
    const boolValue = new BoolErbFieldValue();
    boolValue.value = value as boolean;

    erbFieldValue.kind = { case: 'boolValue', value: boolValue };
  } else if (field.type!.options!.kind.case === 'multipleCheckboxes') {
    const genericSetValue = new GenericSetErbFieldValueLocalizedMulti();
    for (const val of value) {
      genericSetValue.values[val.key] = new LocalizedValue({ translations: val.translations });
    }
    erbFieldValue.kind = { case: 'genericSetLocalizedMulti', value: genericSetValue };
  } else if (field.type!.options!.kind.case === 'dropdownSelect') {
    const genericSetValue = new GenericSetErbFieldValueLocalizedSingle({ id: value.key, values: value.translations });

    erbFieldValue.kind = { case: 'genericSetLocalizedSingle', value: genericSetValue };
  } else if (field.type!.options!.kind.case === 'number') {
    const numberValue = new NumberErbFieldValue();
    numberValue.value = value;
    erbFieldValue.kind = { case: 'numberValue', value: numberValue };
  } else {
    const textValue = new TextErbFieldValue();

    textValue.value = value;
    erbFieldValue.kind = { case: 'textValue', value: textValue };
  }

  return erbFieldValue;
};

// Utility function to retrieve value as a string for rendering in a table cell
export const retrieveTableRowCellValue = (
  field: ErbTableFieldRowCell
): string | number | boolean | GenericSetErbFieldValueLocalizedSingle | GenericSetErbFieldValueLocalizedMulti | GenericSetErbFieldValue => {
  switch (field.value.case) {
    case 'textValue':
      return (field.value.value as TextErbFieldValue).value;
    case 'boolValue':
      return (field.value.value as BoolErbFieldValue).value;
    case 'numberValue':
      return (field.value.value as NumberErbFieldValue).value;
    case 'dateValue':
      return (field.value.value as DateErbFieldValue).value;
    case 'genericSetValue':
      return field.value.value as GenericSetErbFieldValue;
    case 'genericSetLocalizedMulti':
      return field.value.value as GenericSetErbFieldValueLocalizedMulti;
    case 'genericSetLocalizedSingle':
      return field.value.value as GenericSetErbFieldValueLocalizedSingle;
    default:
      return '-';
  }
};

export const mapEmployeeRecordsToRows = (
  employeeRecords: EmployeeRecord[],
  visibleColumns: PpdColumn[],
  erbFieldsById: Record<string, any>
): Record<string, any>[] => {
  return employeeRecords.map((record) => {
    // Initialize base row structure
    const row: Record<string, any> = {
      id: record.employeeId,
      employeeId: record.employeeId,
      status: record.status
    };

    // Iterate over visible columns and populate fields
    visibleColumns.map((column) => {
      const { fieldId, tableFieldId } = column;

      if (!tableFieldId) {
        // Scalar field mapping
        const field = erbFieldsById[fieldId];
        if (field) {
          const fieldValue = getFieldValuePpd(field.kind.value as ErbScalarField, record.fieldValues);
          row[fieldId] = fieldValue ?? '-';
        }
      } else {
        // Table field mapping
        const erbTableField = erbFieldsById[tableFieldId]?.kind?.value as ErbTableField | undefined;
        if (erbTableField) {
          const fieldValue = getColumnFieldValuePpd(tableFieldId, fieldId, record.fieldValues);

          row[fieldId] = fieldValue ?? '-';
        }
      }
    });

    return row;
  });
};
