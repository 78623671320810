import { Skeleton, Stack } from '@mui/material';
import { AutocompleteItemSkeleton } from './styled/AutocompleteItemSkeleton';

export const GroupAutocompleteItemsSkeleton = () => {
  return (
    <Stack gap={2}>
      <AutocompleteItemSkeleton />
      <AutocompleteItemSkeleton />
      <AutocompleteItemSkeleton />
      <AutocompleteItemSkeleton />
      <AutocompleteItemSkeleton />
      <AutocompleteItemSkeleton />
    </Stack>
  );
};
