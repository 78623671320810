import { createClient } from '@connectrpc/connect';
import { createGrpcWebTransport } from '@connectrpc/connect-web';
import {
  CreateWorkingPatternRequest,
  CreateWorkingPatternResponse,
  DeleteWorkingPatternByIdRequest,
  RetrieveWorkingPatternByIdRequest,
  RetrieveWorkingPatternByIdResponse,
  RetrieveWorkingPatternListItemsRequest,
  RetrieveWorkingPatternListItemsResponse,
  UpdateWorkingPatternRequest,
  UpdateWorkingPatternResponse,
  WorkingPatternService
} from '@thrivea/organization-client';
import { msalInstance } from '@/main';
import { tokenRequest } from '@utils/authConfig';
import { Empty } from '@bufbuild/protobuf/wkt';

const workingPatternClient = createClient(
  WorkingPatternService,
  createGrpcWebTransport({
    baseUrl: import.meta.env.VITE_APP_API_URL,
    useBinaryFormat: true
  })
);

export const retrieveWorkingPatternListItems = async (request: RetrieveWorkingPatternListItemsRequest): Promise<RetrieveWorkingPatternListItemsResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await workingPatternClient.retrieveWorkingPatternListItems(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const createWorkingPattern = async (request: CreateWorkingPatternRequest): Promise<CreateWorkingPatternResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await workingPatternClient.createWorkingPattern(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const deleteWorkingPatternById = async (request: DeleteWorkingPatternByIdRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await workingPatternClient.deleteWorkingPatternById(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveWorkingPatternById = async (request: RetrieveWorkingPatternByIdRequest): Promise<RetrieveWorkingPatternByIdResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await workingPatternClient.retrieveWorkingPatternById(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const updateWorkingPatternById = async (request: UpdateWorkingPatternRequest): Promise<UpdateWorkingPatternResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await workingPatternClient.updateWorkingPattern(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};
