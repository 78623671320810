import { Stack, StackProps, styled } from '@mui/material';
import { TaskStatus } from '@features/tasks';

interface BoardTaskProps {
  backgroundColor: string;
  textColor: string;
}

interface TasksListTaskRowProps extends StackProps {
  status: TaskStatus;
}

export const BoardTask = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'status'
})<TasksListTaskRowProps>(({ status, theme }) => {
  // Define an array for row styling based on TaskStatus
  const row: BoardTaskProps[] = [
    { backgroundColor: theme.palette.grey[600], textColor: theme.palette.primary.main },
    { backgroundColor: theme.palette.common.white, textColor: theme.palette.primary.main },
    { backgroundColor: 'rgba(252, 199, 93, 0.30)', textColor: theme.palette.primary.main },
    { backgroundColor: theme.palette.customTheme.selectLabel, textColor: theme.palette.primary.main }
  ];

  let idx = TaskStatus.Draft;
  if (status === TaskStatus.Assigned) idx = TaskStatus.Assigned;
  if (status === TaskStatus.InReview) idx = TaskStatus.InReview;
  if (status === TaskStatus.Completed) idx = TaskStatus.Completed;

  return {
    backgroundColor: row[idx].backgroundColor,
    color: row[idx].textColor,
    position: 'relative',
    border: 0,
    borderRadius: 8,
    padding: 16
  };
});
