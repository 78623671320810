import { Stack } from '@mui/material';
import { GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton, GridToolbarQuickFilter } from '@mui/x-data-grid-pro';
import { RowCenterJustifyBetweenStack, RowCenterStack, StyledFilledInput, SearchIcon } from '@/shared';
import { PeopleDirectoryTableActions, selectSelectedEmployeeRowIds } from '@features/people-directory';
import { useAppSelector } from '@/app/hooks';

export const PeopleDirectoryGridToolbar = () => {
  const selectedEmployeeRowIds = useAppSelector(selectSelectedEmployeeRowIds);

  return (
    <GridToolbarContainer
      sx={{ margin: 0, paddingTop: '8px', backgroundColor: (theme) => theme.palette.common.white, borderTopLeftRadius: '6px', borderTopRightRadius: '6px' }}
    >
      <Stack gap={1} sx={{ width: '100%', paddingLeft: '8px' }}>
        <RowCenterJustifyBetweenStack>
          <RowCenterStack gap={1}>
            <GridToolbarExport />
            <PeopleDirectoryTableActions isDisabled={selectedEmployeeRowIds.length === 0} />
          </RowCenterStack>
          <RowCenterStack gap={1}>
            <GridToolbarQuickFilter
              slots={{
                input: (props) => (
                  <StyledFilledInput
                    placeholder="Search employees"
                    value={props.value}
                    onChange={props.onChange}
                    fullWidth
                    slotProps={{
                      input: {
                        startAdornment: <SearchIcon width={14} height={14} />
                      }
                    }}
                  />
                )
              }}
              sx={{
                width: 250,
                '& .MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-formControl': {
                  paddingTop: 0,
                  borderRadius: '6px',
                  minHeight: 'auto',
                  height: 40
                }
              }}
            />
            <GridToolbarFilterButton />
          </RowCenterStack>
        </RowCenterJustifyBetweenStack>
      </Stack>
    </GridToolbarContainer>
  );
};
