import { useEffect, useState } from 'react';
import keys from 'lodash/keys';
import { Typography, IconButton, Stack } from '@mui/material';
import { RowCenterStack, StyledTooltip } from '@/shared';
import { RenderEmoji } from '@features/homepage';
import { fetchEmojisWithShortcodes } from '@utils/emojiMartShortcode';
import { useTranslation } from 'react-i18next';
import { EmployeeListItem } from '@thrivea/organization-client';

interface BaseReaction {
  emoji: string;
  authorId: string;
}

interface BaseReactionsProps {
  reactionsGroupedByEmoji: Record<string, BaseReaction[]>;
  selectAuthors: Record<string, EmployeeListItem>;
  currentUserId: string;
  handleEmojiToggle: (emoji: string) => void;
  isImportant?: boolean;
}

export const BaseReactions = ({ reactionsGroupedByEmoji, selectAuthors, currentUserId, isImportant, handleEmojiToggle }: BaseReactionsProps): JSX.Element => {
  const { t } = useTranslation(['common']);
  const [emojisWithShortcodes, setEmojisWithShortcodes] = useState<Record<string, string>>({});

  useEffect(() => {
    const fetchData = async () => {
      const emojis = await fetchEmojisWithShortcodes(keys(reactionsGroupedByEmoji));
      setEmojisWithShortcodes(emojis);
    };
    fetchData();
  }, [reactionsGroupedByEmoji]);

  return (
    <>
      {keys(reactionsGroupedByEmoji).map((emoji) => (
        <StyledTooltip
          key={emoji}
          isVisible
          title={
            <Stack
              direction="row"
              gap={2}
              sx={{
                wordBreak: 'break-word',
                whiteSpace: 'pre-wrap',
                borderRadius: (theme) => theme.spacing(1),
                padding: (theme) => theme.spacing(2),
                '& img': {
                  maxWidth: '24px !important',
                  maxHeight: '24px !important'
                }
              }}
            >
              <RenderEmoji native={emoji || ''} />
              <RowCenterStack
                sx={{
                  flexWrap: 'wrap'
                }}
              >
                {reactionsGroupedByEmoji[emoji].slice(0, 10).map((reaction, index) => (
                  <Typography key={reaction.authorId}>
                    {selectAuthors[reaction.authorId].displayName || ''}
                    {index < reactionsGroupedByEmoji[emoji].length - 1 ? ', ' : ' '}
                  </Typography>
                ))}
                {reactionsGroupedByEmoji[emoji].length > 10 && (
                  <Typography>{t('reaction_with_count', { ns: 'common', count: reactionsGroupedByEmoji[emoji].length - 10 })}</Typography>
                )}
                <Typography>
                  {' '}
                  reacted with{' '}
                  <Typography component="span" fontWeight={700}>
                    {emojisWithShortcodes[emoji]}
                  </Typography>
                </Typography>
              </RowCenterStack>
            </Stack>
          }
          enterDelay={500}
          leaveDelay={200}
          placement="top"
        >
          <IconButton
            onClick={() => handleEmojiToggle(emoji)}
            sx={{
              bgcolor: (theme) =>
                reactionsGroupedByEmoji[emoji]?.some((reaction) => reaction.authorId === currentUserId)
                  ? isImportant
                    ? theme.palette.grey[300]
                    : theme.palette.customTheme.drawerBackground
                  : theme.palette.grey[300],
              minWidth: 32,
              maxHeight: 28,
              padding: '0 7px',
              borderRadius: '7px',
              '&:hover': { transform: 'scale(1.1)' }
            }}
          >
            <RowCenterStack gap={1}>
              <RenderEmoji native={emoji || ''} />
              <Typography
                variant="caption"
                sx={{
                  fontWeight: 900,
                  mt: 0.15,
                  lineHeight: 'normal'
                }}
              >
                {reactionsGroupedByEmoji[emoji].length}
              </Typography>
            </RowCenterStack>
          </IconButton>
        </StyledTooltip>
      ))}
    </>
  );
};
