import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@app/store';
import { UpdateProfileMediaRequest } from '@thrivea/organization-client';
import { MultiSizeImageBlobs } from '@/shared';
import { SasToken } from '@thrivea/auth-client';

export interface ProfileMedia {
  profilePictureUrl: string;
  coverPictureUrl: string;
}

interface EmployeeProfileState {
  locale: string;

  currentEmployeeProfilePictureUrl: string;
  currentEmployeeCoverPictureUrl: string;

  employeeProfileAndCoverReadSasToken: string;
  employeeProfilePictureFile?: MultiSizeImageBlobs;
  employeeProfilePictureFileMultiSize: MultiSizeImageBlobs | undefined | null;
  employeeProfileCoverPictureMultiSize: MultiSizeImageBlobs | undefined | null;
  employeeProfileCoverPictureFile?: MultiSizeImageBlobs;
}

const initialState: EmployeeProfileState = {
  locale: 'en',

  currentEmployeeProfilePictureUrl: '',
  currentEmployeeCoverPictureUrl: '',

  employeeProfileAndCoverReadSasToken: '',
  employeeProfilePictureFileMultiSize: undefined,
  employeeProfileCoverPictureMultiSize: undefined
};

const employeeProfileSlice = createSlice({
  name: 'employee-profile',
  initialState,
  reducers: {
    localize: (state, action: PayloadAction<string>) => {
      state.locale = action.payload;
    },
    retrieveEmployeeProfileAndCoverReadSasTokenRequested: (state) => {},
    retrieveEmployeeProfileAndCoverReadSasTokenSucceeded: (state, action: PayloadAction<SasToken>) => {
      state.employeeProfileAndCoverReadSasToken = action.payload.token;
    },
    retrieveEmployeeProfileAndCoverReadSasTokenFailed: (state) => {},
    setEmployeeProfilePictureFileMultipleSizes: (state, action: PayloadAction<MultiSizeImageBlobs | undefined>) => {
      state.employeeProfilePictureFileMultiSize = action.payload;
    },
    removeEmployeeProfilePictureFileMultipleSizes: (state) => {
      state.employeeProfilePictureFileMultiSize = null;
    },
    resetEmployeeProfilePictureFileMultipleSizes: (state) => {
      state.employeeProfilePictureFileMultiSize = undefined;
    },
    setEmployeeCoverPictureFileMultipleSizes: (state, action: PayloadAction<MultiSizeImageBlobs | undefined>) => {
      if (action.payload) {
        state.employeeProfileCoverPictureMultiSize = {
          sm: action.payload.sm,
          md: action.payload.md,
          lg: action.payload.lg,
          og: action.payload.og
        } as MultiSizeImageBlobs;
      }
    },
    removeEmployeeProfileCoverPictureFileMultiSize: (state) => {
      state.employeeProfileCoverPictureMultiSize = null;
    },
    resetEmployeeProfileCoverPictureFileMultiSize: (state) => {
      state.employeeProfileCoverPictureMultiSize = undefined;
    },
    updateProfileMediaRequested: (state, action: PayloadAction<UpdateProfileMediaRequest>) => {},
    updateProfileMediaSucceeded: (state, action: PayloadAction<ProfileMedia>) => {
      state.currentEmployeeProfilePictureUrl = action.payload.profilePictureUrl;
      state.currentEmployeeCoverPictureUrl = action.payload.coverPictureUrl;
    },
    updateProfileMediaFailed: (state) => {}
  }
});

export const selectLocale = (state: RootState) => state.employeeProfile.locale;
export const selectEmployeeProfilePictureFile = (state: RootState) => state.employeeProfile.employeeProfilePictureFile;
export const selectEmployeeProfileCoverPictureFile = (state: RootState) => state.employeeProfile.employeeProfileCoverPictureFile!;
export const selectEmployeeProfileAndCoverReadSasToken = (state: RootState) => state.employeeProfile.employeeProfileAndCoverReadSasToken;
export const selectEmployeeProfilePictureFileMultiSize = (state: RootState) => state.employeeProfile.employeeProfilePictureFileMultiSize;
export const selectEmployeeCoverPictureFileMultiSize = (state: RootState) => state.employeeProfile.employeeProfileCoverPictureMultiSize;

export const {
  localize,
  updateProfileMediaRequested,
  updateProfileMediaSucceeded,
  updateProfileMediaFailed,
  retrieveEmployeeProfileAndCoverReadSasTokenRequested,
  retrieveEmployeeProfileAndCoverReadSasTokenSucceeded,
  retrieveEmployeeProfileAndCoverReadSasTokenFailed,
  setEmployeeProfilePictureFileMultipleSizes,
  removeEmployeeProfilePictureFileMultipleSizes,
  resetEmployeeProfilePictureFileMultipleSizes,
  setEmployeeCoverPictureFileMultipleSizes,
  removeEmployeeProfileCoverPictureFileMultiSize,
  resetEmployeeProfileCoverPictureFileMultiSize
} = employeeProfileSlice.actions;
export default employeeProfileSlice.reducer;
