import { Fragment, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Stack, Box, Fade, ListItemIcon, ListItemText, ListSubheader, List } from '@mui/material';
import {
  RowCenterStack,
  SettingsIcon,
  InfoIcon,
  BillingCard,
  PerspectiveIcon,
  BuildingIcon,
  CalendarClock,
  AudienceIcon,
  PermissionLockIcon,
  UploadIcon,
  StyledTransparentIconButton,
  ChevronLeftIcon
} from '@/shared';
import { ERBuilderNavigationItem, NavListItem, selectNavigationIsExpanded, StyledNavListItemButton } from '@features/navigation';
import { useAppSelector } from '@app/hooks';
import { AssetsTooltip } from '@features/assets';

const SettingsAccordionItem = ({ item, navigationIsExpanded }) => (
  <Fragment key={item.id}>
    {navigationIsExpanded && (
      <ListSubheader
        disableSticky
        sx={{
          color: (theme) => theme.palette.customTheme.globalContentColorMuted,
          padding: '8px 4px',
          textTransform: 'uppercase',
          height: 47,
          fontSize: 14,
          lineHeight: '30px',
          fontWeight: 700
        }}
      >
        {item.name}
      </ListSubheader>
    )}
    {item.childItems.map((c) => (
      <Fragment key={c.id}>{c.isERB ? <ERBuilderNavigationItem /> : <NavListItem isDisabled={false} name={c.name} url={c.url} icon={c.icon} />}</Fragment>
    ))}
  </Fragment>
);

const SettingsAccordionList = ({ subActionsList, navigationIsExpanded }) => (
  <Stack gap={1}>
    {subActionsList.map((item) => (
      <List key={item.id} sx={{ width: '100%', p: '4px' }}>
        {item.items.map((subItem) => (
          <SettingsAccordionItem key={subItem.id} item={subItem} navigationIsExpanded={navigationIsExpanded} />
        ))}
      </List>
    ))}
  </Stack>
);

export const SettingsAccordion = () => {
  const location = useLocation();
  const isLocationActive = location.pathname.includes('/settings');
  const navigationIsExpanded = useAppSelector(selectNavigationIsExpanded);
  const [isItemExpanded, setIsItemExpanded] = useState(isLocationActive);
  const navigate = useNavigate();

  const subActionsList = [
    {
      id: 1,
      name: '',
      hash: '#',
      items: [
        {
          id: '7793ad6d-d91e-4c8f-a351-41216349a7af',
          name: 'Account',
          url: '',
          childItems: [
            {
              id: '4d17ffda-635e-44b2-a504-8e8b71d51312',
              name: 'Company Info',
              url: '/settings/my-company',
              icon: <InfoIcon width={20} height={20} />
            },
            {
              id: 'c5766ab5-2261-4d6b-8dac-599c73c5f642',
              name: 'Billing',
              url: '/settings/billing',
              icon: <BillingCard width={20} height={20} />
            }
          ]
        },
        {
          id: 'dcfbe381-bafe-4765-b9b9-c848d5256c67',
          name: 'Data Management',
          url: '',
          childItems: [
            {
              id: 'd2c4d187-28e3-49ec-8e13-f0faf4940bb1',
              name: 'Import',
              url: '/settings/import',
              icon: <UploadIcon width={20} height={20} />
            }
          ]
        },
        {
          id: 'db2c6973-9e55-4410-b778-873b3793e4bc',
          name: 'Configuration',
          url: '',
          childItems: [
            {
              id: 'f28114f0-20e4-4392-b2ee-e9c12cbc3c98',
              name: 'Sites',
              url: '/settings/sites',
              icon: <BuildingIcon width={20} height={20} />
            },
            {
              id: 'dbb4791b-c14b-4b1d-a108-43b61ebf05cf',
              name: 'Working Patterns',
              url: '/settings/working-patterns',
              icon: <CalendarClock width={20} height={20} />
            },
            {
              id: '874321d2-d0ce-46cf-a76a-962840e9987e',
              name: 'Audiences',
              url: '/settings/audiences',
              icon: <AudienceIcon width={20} height={20} />
            },
            {
              id: '014915bf-7465-4fd0-a069-7199aa072283',
              name: 'Permissions',
              url: '/settings/permissions',
              icon: <PermissionLockIcon width={20} height={20} />
            },
            {
              id: 'f6ca4a59-14e4-421d-8e24-b4c3f1c3c713',
              name: 'Builder',
              url: '/settings/employee-record-builder',
              icon: <PerspectiveIcon width={20} height={20} />,
              isERB: true
            }
          ]
        }
      ]
    }
  ];

  const handleSettingsButtonClick = () => {
    setIsItemExpanded(!isItemExpanded);
    navigate('/settings');
  };

  return (
    <Stack>
      {!isItemExpanded && (
        <StyledNavListItemButton disableGutters onClick={handleSettingsButtonClick}>
          {!navigationIsExpanded && (
            <AssetsTooltip title="Settings" placement="right" arrow>
              <ListItemIcon
                className="MuiLinkIcon"
                sx={{
                  minWidth: 'auto',
                  '& svg': {
                    height: '22px'
                  },
                  marginRight: 0
                }}
              >
                <SettingsIcon />
              </ListItemIcon>
            </AssetsTooltip>
          )}
          {navigationIsExpanded && (
            <>
              <ListItemIcon
                className="MuiLinkIcon"
                sx={{
                  minWidth: 'auto',
                  '& svg': {
                    height: '22px'
                  },
                  marginRight: '8px'
                }}
              >
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText
                sx={{
                  '& .MuiTypography-root': {
                    fontSize: '16px',
                    lineHeight: '24px',
                    fontWeight: 500
                  }
                }}
              >
                Settings
              </ListItemText>
            </>
          )}
        </StyledNavListItemButton>
      )}
      <Fade in={isItemExpanded}>
        <Stack
          gap={1}
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: 'calc(100% - 72px)',
            backgroundColor: (theme) => theme.palette.common.white,
            padding: '0 16px 16px',
            overflowY: 'auto'
          }}
        >
          <RowCenterStack
            sx={{
              position: 'sticky',
              top: 0,
              backgroundColor: 'inherit',
              padding: '16px 0',
              justifyContent: navigationIsExpanded ? 'flex-start' : 'center',
              zIndex: 100
            }}
          >
            <Box>
              <StyledTransparentIconButton onClick={() => setIsItemExpanded(false)}>
                <ChevronLeftIcon width={20} height={20} />
              </StyledTransparentIconButton>
            </Box>
          </RowCenterStack>
          <SettingsAccordionList subActionsList={subActionsList} navigationIsExpanded={navigationIsExpanded} />
        </Stack>
      </Fade>
    </Stack>
  );
};
