import { create } from '@bufbuild/protobuf';
import { forwardRef, useEffect, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { RowCenterStack, MultiSizeImageBlobs, PictureSizeSuffix } from '@/shared';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import {
  removeEmployeeProfileCoverPictureFileMultiSize,
  removeEmployeeProfilePictureFileMultipleSizes,
  selectEmployeeCoverPictureFileMultiSize,
  selectEmployeeProfileAndCoverReadSasToken,
  selectEmployeeProfilePictureFileMultiSize,
  setEmployeeCoverPictureFileMultipleSizes,
  setEmployeeProfilePictureFileMultipleSizes,
  updateProfileMediaRequested
} from '@features/employee-profile';
import { pictureUrl } from '@utils/pictureUrl';
import {
  EmployeeRecordCoverImage,
  EmployeeRecordProfileImage,
  selectEmployeeRecordCoverPicture,
  selectEmployeeRecordProfilePicture
} from '@features/employee-record-page';
import { selectEmployeeListItemById } from '@features/shared';
import { UpdateProfileMediaRequestSchema } from '@thrivea/organization-client';

interface EmployeeRecordMedia {
  employeeId: string;
}

export const EMPLOYEE_PROFILE_PICTURE_PLACEHOLDER = '/images/logo-placeholder.png';
export const EMPLOYEE_PROFILE_COVER_PLACEHOLDER = '/images/cover-image-placeholder.png';

export const EmployeeRecordMedia = forwardRef<HTMLDivElement, EmployeeRecordMedia>(({ employeeId }, _ref) => {
  const dispatch = useAppDispatch();

  const employeeProfilePictureFileMultiSize = useAppSelector(selectEmployeeProfilePictureFileMultiSize);
  const employeeProfileCoverFileMultiSize = useAppSelector(selectEmployeeCoverPictureFileMultiSize);
  const employeeProfileAndCoverReadSasToken = useAppSelector(selectEmployeeProfileAndCoverReadSasToken);
  const employeeLisItem = useAppSelector((state) => selectEmployeeListItemById(state, employeeId));
  const profilePictureUrl = useAppSelector(selectEmployeeRecordProfilePicture);
  const coverPictureUrl = useAppSelector(selectEmployeeRecordCoverPicture);

  const [profilePicture, setProfilePicture] = useState<string>('');
  const [coverPicture, setCoverPicture] = useState<string>('');

  useEffect(() => {
    setProfilePicture(profilePictureUrl);
    setCoverPicture(coverPictureUrl);
  }, []);

  const handleSetEmployeeProfilePictureFile = (file: MultiSizeImageBlobs | undefined) => {
    if (file) {
      dispatch(setEmployeeProfilePictureFileMultipleSizes(file));
    } else {
      dispatch(setEmployeeProfilePictureFileMultipleSizes(undefined));
    }
  };

  const handleRemoveEmployeeProfilePictureFile = () => {
    dispatch(removeEmployeeProfilePictureFileMultipleSizes());
    setProfilePicture('');
  };

  const handleSetEmployeeProfileCoverFileMultipleSize = (file: MultiSizeImageBlobs | undefined) => {
    if (file) {
      dispatch(setEmployeeCoverPictureFileMultipleSizes(file));
    } else {
      dispatch(setEmployeeCoverPictureFileMultipleSizes(undefined));
    }
  };

  const handleRemoveEmployeeProfileCoverFile = () => {
    dispatch(removeEmployeeProfileCoverPictureFileMultiSize());
    setCoverPicture('');
  };

  const onUpdate = () => {
    dispatch(
      updateProfileMediaRequested(
        create(UpdateProfileMediaRequestSchema, {
          employeeId: employeeId,
          profilePictureUrl: profilePicture,
          coverPictureUrl: coverPicture
        })
      )
    );
  };

  return (
    <>
      <EmployeeRecordCoverImage
        sm={1000}
        md={1500}
        lg={2000}
        uploadTextLabel={'Upload'}
        placeholderSrc={''}
        urlSrc={pictureUrl(coverPictureUrl, employeeProfileAndCoverReadSasToken, PictureSizeSuffix.lg) ?? EMPLOYEE_PROFILE_COVER_PLACEHOLDER}
        imageAltText={''}
        multiSizeImage={employeeProfileCoverFileMultiSize}
        onChange={handleSetEmployeeProfileCoverFileMultipleSize}
        onRemove={handleRemoveEmployeeProfileCoverFile}
        onUpdate={onUpdate}
      />
      <RowCenterStack gap={'12px'}>
        <EmployeeRecordProfileImage
          sm={1000}
          md={1000}
          lg={1000}
          width={96}
          height={96}
          placeholderSrc={''}
          urlSrc={pictureUrl(profilePictureUrl, employeeProfileAndCoverReadSasToken, PictureSizeSuffix.lg) ?? ''}
          imageAltText={''}
          multiSizeImage={employeeProfilePictureFileMultiSize}
          onChange={handleSetEmployeeProfilePictureFile}
          onRemove={handleRemoveEmployeeProfilePictureFile}
          employeeId={employeeId}
          onUpdate={onUpdate}
        />
        <Stack gap={'2px'} sx={{ marginTop: '-4px' }}>
          <Typography sx={{ fontWeight: 600 }}>{employeeLisItem.displayName}</Typography>
          <Typography sx={{ color: '#535862', fontSize: 14 }}>{employeeLisItem.jobTitle}</Typography>
        </Stack>
      </RowCenterStack>
    </>
  );
});

EmployeeRecordMedia.displayName = 'EmployeeRecordMedia';
