import 'src/index.css';
import 'src/i18n';
import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router';
import { Provider } from 'react-redux';
import { AuthenticationResult, EventType, PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import * as Sentry from '@sentry/react';
import { store } from '@app/store';
import { App } from 'src/App';
import { msalConfig } from '@utils/authConfig';
import { MuiXLicense } from '@shared/components/MuiXLicense';

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN_APP,
  environment: import.meta.env.VITE_ENVIRONMENT,
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes
    }),
    Sentry.replayIntegration()
  ],

  enabled: import.meta.env.VITE_SENTRY_ENABLED === 'true',

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 0.01,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/thrivea\.com\//, /^https:\/\/app-thrivea.*\.azurewebsites\.net\//],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
});

export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.initialize().then(() => {
  // Default to using the first account if no account is active on page load
  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  // Optional - This will update account state if a user signs in from another tab or window
  msalInstance.enableAccountStorageEvents();

  msalInstance.addEventCallback((event) => {
    if (
      event.eventType === EventType.LOGIN_SUCCESS ||
      event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
      event.eventType === EventType.SSO_SILENT_SUCCESS
    ) {
      const account = (event.payload as AuthenticationResult).account;
      msalInstance.setActiveAccount(account);
    }
  });

  if (/Mobi|Android/i.test(navigator.userAgent)) {
    // You could also set up more detailed logging here
    localStorage.setItem('deviceType', 'mobile');

    // Add an error handler for authentication failures
    window.addEventListener('error', (event) => {
      Sentry.captureException(event.error);
      console.error('Global error caught:', event.error);
      if (event.error && event.error.message && event.error.message.includes('authentication')) {
        // Log authentication errors
        console.error('Authentication error detected');
      }
    });
  }
});

const rootElem = document.getElementById('root')!;
const root = createRoot(rootElem);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <MsalProvider instance={msalInstance}>
        <App />
        <MuiXLicense />
      </MsalProvider>
    </Provider>
  </React.StrictMode>
);
