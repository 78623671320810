import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import {
  archiveTask,
  commentOnTask,
  createSingleTask,
  deleteSingleTask,
  editTask,
  reactToTask,
  reactToTaskComment,
  retrieveSingleTask,
  retrieveSingleTasksInTaskList,
  retrieveTasks,
  retrieveTasksForDependencies,
  retrieveTasksViews,
  withdrawTaskCommentReaction,
  withdrawTaskReaction
} from '@api/tasks.api';
import {
  CommentOnTaskRequest,
  CreateSingleTaskRequest,
  EditTaskRequest,
  ReactToTaskCommentRequest,
  ReactToTaskRequest,
  RetrieveEmployeesByQueryRequest,
  RetrieveEmployeesByQueryResponse,
  RetrieveSingleTaskResponse,
  RetrieveSingleTasksInTaskListResponse,
  RetrieveTasksForDependenciesResponse,
  RetrieveTasksRequest,
  RetrieveTasksResponse,
  RetrieveTasksViewsRequest,
  SingleTaskId,
  TaskListId,
  WithdrawTaskCommentReactionRequest,
  WithdrawTaskReactionRequest
} from '@thrivea/organization-client';
import {
  loadTasksRequested,
  loadTasksSucceeded,
  LoadTasksInitially,
  loadTasksFailed,
  changeTasksGroupBy,
  selectRetrieveTasksRequest,
  retrieveTasksSucceeded,
  retrieveTasksFailed,
  changeTasksScope,
  nextPageByDueDate,
  nextPageByStatus,
  createSingleTaskSucceeded,
  createSingleTaskFailed,
  createSingleTaskRequested,
  retrieveAssigneeAutocompleteItemsSucceeded,
  retrieveAssigneeAutocompleteItemsFailed,
  retrieveAssigneeAutocompleteItemsRequested,
  retrieveReviewerAutocompleteItemsSucceeded,
  retrieveReviewerAutocompleteItemsFailed,
  retrieveReviewerAutocompleteItemsRequested,
  deleteSingleTaskSucceeded,
  deleteSingleTaskFailed,
  deleteSingleTaskRequested,
  retrieveSingleTaskSucceeded,
  retrieveSingleTaskFailed,
  retrieveSingleTaskRequested,
  reactToTaskSucceeded,
  reactToTaskRequested,
  reactToTaskFailed,
  withdrawTaskReactionSucceeded,
  withdrawTaskReactionFailed,
  withdrawTaskReactionRequested,
  commentOnTaskSucceeded,
  commentOnTaskFailed,
  commentOnTaskRequested,
  reactToTaskCommentSucceeded,
  reactToTaskCommentFailed,
  reactToTaskCommentRequested,
  withdrawTaskCommentReactionRequested,
  withdrawTaskCommentReactionSucceded,
  withdrawTaskCommentReactionFailed,
  selectTaskPublicIdById,
  retrieveTasksForDependenciesSucceeded,
  retrieveTasksForDependenciesFailed,
  retrieveTasksForDependenciesRequested,
  editTaskSucceeded,
  editTaskFailed,
  editTaskRequested,
  archiveTaskSucceeded,
  archiveTaskFailed,
  archiveTaskRequested,
  retrieveSingleTasksInTaskListSucceeded,
  retrieveSingleTasksInTaskListFailed,
  retrieveSingleTasksInTaskListRequested
} from '@features/tasks';
import * as Sentry from '@sentry/react';
import { selectCurrentEmployeeId } from '@app/user';
import { PayloadAction } from '@reduxjs/toolkit';
import { retrieveEmployeesByQuery } from '@/api/employees.api';
import { closeDrawer } from '../drawer';
import { showSuccess } from '../snackbar';
import { t } from 'i18next';
import { Empty } from '@bufbuild/protobuf';

function* loadTasksRequestedGenerator() {
  const employeeId: string = yield select(selectCurrentEmployeeId);
  const request: RetrieveTasksRequest = yield select(selectRetrieveTasksRequest, employeeId);

  try {
    const loadTasksInitially: LoadTasksInitially = yield all({
      tasksResponse: call(retrieveTasks, request),
      tasksViewsResponse: call(retrieveTasksViews, new RetrieveTasksViewsRequest({ employeeId }))
    });
    yield put(loadTasksSucceeded(loadTasksInitially));
  } catch (error) {
    Sentry.captureException(error);
    yield put(loadTasksFailed());
  }
}

function* retrieveGroupedTasks() {
  const employeeId: string = yield select(selectCurrentEmployeeId);
  const request: RetrieveTasksRequest = yield select(selectRetrieveTasksRequest, employeeId);

  try {
    const response: RetrieveTasksResponse = yield call(retrieveTasks, request);
    yield put(retrieveTasksSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveTasksFailed());
  }
}

function* createSingleTaskRequestedGenerator(action: PayloadAction<CreateSingleTaskRequest>) {
  try {
    const response = yield call(createSingleTask, action.payload);
    yield put(createSingleTaskSucceeded(response));
    yield put(closeDrawer());
    yield put(showSuccess(t('created_task_successfully', { ns: 'tasks', name: action.payload.publicId })));
  } catch (error) {
    Sentry.captureException(error);
    yield put(createSingleTaskFailed());
  }
}

function* retrieveAssigneeAutocompleteItemsRequestedGenerator(action: PayloadAction<RetrieveEmployeesByQueryRequest>) {
  try {
    const response: RetrieveEmployeesByQueryResponse = yield call(retrieveEmployeesByQuery, action.payload);
    yield put(retrieveAssigneeAutocompleteItemsSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveAssigneeAutocompleteItemsFailed());
  }
}

function* retrieveReviewerAutocompleteItemsRequestedGenerator(action: PayloadAction<RetrieveEmployeesByQueryRequest>) {
  try {
    const response: RetrieveEmployeesByQueryResponse = yield call(retrieveEmployeesByQuery, action.payload);
    yield put(retrieveReviewerAutocompleteItemsSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveReviewerAutocompleteItemsFailed());
  }
}

function* deleteSingleTaskRequestedGenerator(action: PayloadAction<SingleTaskId>) {
  const taskPublicId = yield select((state) => selectTaskPublicIdById(state, action.payload.id));

  try {
    yield call(deleteSingleTask, action.payload);
    yield put(deleteSingleTaskSucceeded(action.payload.id));
    yield put(showSuccess(t('deleted_task_successfully', { ns: 'tasks', name: taskPublicId })));
  } catch (error) {
    Sentry.captureException(error);
    yield put(deleteSingleTaskFailed());
  }
}

function* retrieveSingleTaskRequestedGenerator(action: PayloadAction<SingleTaskId>) {
  try {
    const response: RetrieveSingleTaskResponse = yield call(retrieveSingleTask, action.payload);
    yield put(retrieveSingleTaskSucceeded(response));
  } catch (error) {
    console.log('retrieveSingleTaskRequestedGenerator', error);
    Sentry.captureException(error);
    yield put(retrieveSingleTaskFailed());
  }
}

function* reactToTaskRequestedGenerator(action: PayloadAction<ReactToTaskRequest>) {
  try {
    yield call(reactToTask, action.payload);
    yield put(reactToTaskSucceeded());
  } catch (error) {
    Sentry.captureException(error);
    yield put(reactToTaskFailed(action.payload));
  }
}

function* withdrawTaskReactionRequestedGenerator(action: PayloadAction<WithdrawTaskReactionRequest>) {
  try {
    yield call(withdrawTaskReaction, action.payload);
    yield put(withdrawTaskReactionSucceeded());
  } catch (error) {
    Sentry.captureException(error);
    yield put(withdrawTaskReactionFailed(action.payload));
  }
}

function* commentOnTaskRequestedGenerator(action: PayloadAction<CommentOnTaskRequest>) {
  try {
    yield call(commentOnTask, action.payload);
    yield put(commentOnTaskSucceeded());
  } catch (error) {
    Sentry.captureException(error);
    yield put(commentOnTaskFailed(action.payload.taskId));
  }
}

function* reactToTaskCommentRequestedGenerator(action: PayloadAction<ReactToTaskCommentRequest>) {
  try {
    yield call(reactToTaskComment, action.payload);
    yield put(reactToTaskCommentSucceeded());
  } catch (error) {
    Sentry.captureException(error);
    yield put(reactToTaskCommentFailed(action.payload));
  }
}

function* withdrawTaskCommentReactionRequestedGenerator(action: PayloadAction<WithdrawTaskCommentReactionRequest>) {
  try {
    yield call(withdrawTaskCommentReaction, action.payload);
    yield put(withdrawTaskCommentReactionSucceded());
  } catch (error) {
    Sentry.captureException(error);
    yield put(withdrawTaskCommentReactionFailed(action.payload));
  }
}

function* retrieveTasksForDependenciesRequestedGenerator(action: PayloadAction<Empty>) {
  try {
    const response: RetrieveTasksForDependenciesResponse = yield call(retrieveTasksForDependencies, action.payload);
    yield put(retrieveTasksForDependenciesSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveTasksForDependenciesFailed());
  }
}

function* editTaskRequestedGenerator(action: PayloadAction<EditTaskRequest>) {
  try {
    yield call(editTask, action.payload);
    yield put(editTaskSucceeded(action.payload));
  } catch (error) {
    console.log('editTaskRequestedGenerator', error);
    Sentry.captureException(error);
    yield put(editTaskFailed());
  }
}

function* archiveTaskRequestedGenerator(action: PayloadAction<SingleTaskId>) {
  try {
    yield call(archiveTask, action.payload);
    yield put(archiveTaskSucceeded(action.payload.id));
  } catch (error) {
    Sentry.captureException(error);
    yield put(archiveTaskFailed());
  }
}

function* retrieveSingleTasksInTaskListRequestedGenerator(action: PayloadAction<TaskListId>) {
  try {
    const response: RetrieveSingleTasksInTaskListResponse = yield call(retrieveSingleTasksInTaskList, action.payload);
    yield put(retrieveSingleTasksInTaskListSucceeded(response));
  } catch (error) {
    console.log('retrieveSingleTasksInTaskListRequestedGenerator error', error);
    Sentry.captureException(error);
    yield put(retrieveSingleTasksInTaskListFailed());
  }
}

function* loadTasksRequestedWatcher() {
  yield takeLatest(loadTasksRequested.type, loadTasksRequestedGenerator);
}

function* changeTasksGroupByWatcher() {
  yield takeLatest(changeTasksGroupBy.type, retrieveGroupedTasks);
}

function* changeTasksScopeWatcher() {
  yield takeLatest(changeTasksScope.type, retrieveGroupedTasks);
}

function* nextPageByDueDateWatcher() {
  yield takeLatest(nextPageByDueDate.type, retrieveGroupedTasks);
}

function* nextPageByStatusWatcher() {
  yield takeLatest(nextPageByStatus.type, retrieveGroupedTasks);
}

function* createSingleTaskRequestedWatcher() {
  yield takeLatest(createSingleTaskRequested.type, createSingleTaskRequestedGenerator);
}

function* retrieveEmployeesAutocompleteItemsRequestedWatcher() {
  yield takeLatest(retrieveAssigneeAutocompleteItemsRequested.type, retrieveAssigneeAutocompleteItemsRequestedGenerator);
}

function* retrieveReviewerAutocompleteItemsRequestedWatcher() {
  yield takeLatest(retrieveReviewerAutocompleteItemsRequested.type, retrieveReviewerAutocompleteItemsRequestedGenerator);
}

function* deleteSingleTaskRequestedWatcher() {
  yield takeLatest(deleteSingleTaskRequested.type, deleteSingleTaskRequestedGenerator);
}

function* retrieveSingleTaskRequestedWatcher() {
  yield takeLatest(retrieveSingleTaskRequested.type, retrieveSingleTaskRequestedGenerator);
}

function* reactToTaskRequestedWatcher() {
  yield takeLatest(reactToTaskRequested.type, reactToTaskRequestedGenerator);
}

function* withdrawTaskReactionRequestedWatcher() {
  yield takeLatest(withdrawTaskReactionRequested.type, withdrawTaskReactionRequestedGenerator);
}

function* commentOnTaskRequestedWatcher() {
  yield takeLatest(commentOnTaskRequested.type, commentOnTaskRequestedGenerator);
}

function* reactToSingleTaskCommentRequestedWatcher() {
  yield takeLatest(reactToTaskCommentRequested.type, reactToTaskCommentRequestedGenerator);
}

function* withdrawTaskCommentRequestedWatcher() {
  yield takeLatest(withdrawTaskCommentReactionRequested.type, withdrawTaskCommentReactionRequestedGenerator);
}

function* retrieveTasksForDependenciesRequestedWatcher() {
  yield takeLatest(retrieveTasksForDependenciesRequested.type, retrieveTasksForDependenciesRequestedGenerator);
}

function* editTaskRequestedWatcher() {
  yield takeLatest(editTaskRequested.type, editTaskRequestedGenerator);
}

function* archiveTaskRequestedWatcher() {
  yield takeLatest(archiveTaskRequested.type, archiveTaskRequestedGenerator);
}

function* retrieveSingleTasksInTaskListRequestedWatcher() {
  yield takeLatest(retrieveSingleTasksInTaskListRequested.type, retrieveSingleTasksInTaskListRequestedGenerator);
}

export function* tasksSagas() {
  yield all([
    fork(loadTasksRequestedWatcher),
    fork(changeTasksGroupByWatcher),
    fork(changeTasksScopeWatcher),
    fork(nextPageByDueDateWatcher),
    fork(nextPageByStatusWatcher),
    fork(createSingleTaskRequestedWatcher),
    fork(retrieveEmployeesAutocompleteItemsRequestedWatcher),
    fork(retrieveReviewerAutocompleteItemsRequestedWatcher),
    fork(deleteSingleTaskRequestedWatcher),
    fork(retrieveSingleTaskRequestedWatcher),
    fork(reactToTaskRequestedWatcher),
    fork(withdrawTaskReactionRequestedWatcher),
    fork(commentOnTaskRequestedWatcher),
    fork(reactToSingleTaskCommentRequestedWatcher),
    fork(withdrawTaskCommentRequestedWatcher),
    fork(retrieveTasksForDependenciesRequestedWatcher),
    fork(editTaskRequestedWatcher),
    fork(archiveTaskRequestedWatcher),
    fork(retrieveSingleTasksInTaskListRequestedWatcher)
  ]);
}
