import { IconButton, IconButtonProps, styled } from '@mui/material';

export const TaskIconButton = styled(IconButton)<IconButtonProps>(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 35,
  height: 35,
  backgroundColor: '#F5F5F5',
  borderRadius: '50%'
}));
