import { Stack, StackProps, styled } from '@mui/material';

export const TaskChip = styled(Stack)<StackProps>(({ theme }) => ({
  padding: '3px 7px',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '8px',
  backgroundColor: theme.palette.customTheme.primaryA40,
  fontSize: 12,
  fontWeight: 700
}));
