import { Chip, chipClasses, ChipProps, styled } from '@mui/material';
import { FileAccessRole } from '@thrivea/organization-client';

interface TaskStatusChipStyleProps {
  backgroundColor: string;
  textColor: string;
}

interface AccessChipProps extends ChipProps {
  status: FileAccessRole;
}

export const AccessChip = styled(Chip)<AccessChipProps>(({ status, theme }) => {
  const row: TaskStatusChipStyleProps[] = [
    { backgroundColor: theme.palette.customTheme.primary40, textColor: theme.palette.primary.main },
    { backgroundColor: theme.palette.customTheme.primaryA40, textColor: theme.palette.primary.main },
    { backgroundColor: theme.palette.customTheme.primary40, textColor: theme.palette.primary.main }
  ];

  let idx = FileAccessRole.VIEWER;
  if (status === FileAccessRole.MANAGER) idx = FileAccessRole.MANAGER;
  if (status === FileAccessRole.OWNER) idx = FileAccessRole.OWNER;

  // Ensure idx is within the bounds of the row array
  if (idx < 0 || idx >= row.length) {
    console.warn(`Invalid access role index: ${idx}. Defaulting to VIEWER.`);
    idx = FileAccessRole.VIEWER; // Fallback to VIEWER
  }

  return {
    backgroundColor: row[idx].backgroundColor,
    color: row[idx].textColor,
    width: 'auto',
    height: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '3px 7px',
    borderRadius: 8,
    [`& .${chipClasses.label}`]: {
      fontSize: 12,
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: 'normal',
      padding: 0
    }
  };
});
