import React from 'react';

interface FolderIconAttributes {
  width?: number;
  height?: number;
  color?: string;
}

export const FolderIcon: React.FC<FolderIconAttributes> = ({ width = 20, height = 20, color = '#717680' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20" fill="none">
      <path
        d="M10.8333 5.83333L9.90371 3.9741C9.63616 3.439 9.50238 3.17144 9.3028 2.97597C9.12631 2.80311 8.91359 2.67164 8.68005 2.59109C8.41596 2.5 8.11683 2.5 7.51857 2.5H4.33332C3.3999 2.5 2.93319 2.5 2.57667 2.68166C2.26307 2.84144 2.0081 3.09641 1.84831 3.41002C1.66666 3.76654 1.66666 4.23325 1.66666 5.16667V5.83333M1.66666 5.83333H14.3333C15.7335 5.83333 16.4335 5.83333 16.9683 6.10582C17.4387 6.3455 17.8212 6.72795 18.0608 7.19836C18.3333 7.73314 18.3333 8.4332 18.3333 9.83333V13.5C18.3333 14.9001 18.3333 15.6002 18.0608 16.135C17.8212 16.6054 17.4387 16.9878 16.9683 17.2275C16.4335 17.5 15.7335 17.5 14.3333 17.5H5.66666C4.26653 17.5 3.56646 17.5 3.03168 17.2275C2.56128 16.9878 2.17882 16.6054 1.93914 16.135C1.66666 15.6002 1.66666 14.9001 1.66666 13.5V5.83333Z"
        stroke={color}
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
