import { createGrpcWebTransport } from '@connectrpc/connect-web';
import { createClient } from '@connectrpc/connect';
import {
  AddPaymentMethodRequest,
  ChangePrimaryPaymentMethodRequest,
  CheckCouponValidityRequest,
  CheckCouponValidityResponse,
  EditInvoiceSettingsRequest,
  PaymentService,
  RetrieveInvoiceSettingsResponse,
  RetrieveInvoicesResponse,
  RetrievePaymentMethodsResponse,
  Subscription
} from '@thrivea/payment-client'; // Adjust the path as necessary
import { msalInstance } from 'src/main';
import { tokenRequest } from 'src/utils';
import { Empty } from '@bufbuild/protobuf/wkt';

const paymentClient = createClient(
  PaymentService,
  createGrpcWebTransport({
    baseUrl: import.meta.env.VITE_APP_API_URL,
    useBinaryFormat: true
  })
);

export const checkCouponValidity = async (request: CheckCouponValidityRequest): Promise<CheckCouponValidityResponse> => {
  return await paymentClient.checkCouponValidity(request);
};

export const retrieveSubscription = async (request: Empty): Promise<Subscription> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await paymentClient.retrieveSubscription(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveInvoiceSettings = async (request: Empty): Promise<RetrieveInvoiceSettingsResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await paymentClient.retrieveInvoiceSettings(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveInvoices = async (request: Empty): Promise<RetrieveInvoicesResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await paymentClient.retrieveInvoices(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrievePaymentMethods = async (request: Empty): Promise<RetrievePaymentMethodsResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await paymentClient.retrievePaymentMethods(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const editInvoiceSettings = async (request: EditInvoiceSettingsRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await paymentClient.editInvoiceSettings(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const addPaymentMethod = async (request: AddPaymentMethodRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await paymentClient.addPaymentMethod(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const changePrimaryPaymentMethod = async (request: ChangePrimaryPaymentMethodRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await paymentClient.changePrimaryPaymentMethod(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};
