import { Typography, Stack, Box, FormControl, IconButton, MenuItem, CircularProgress } from '@mui/material';
import Grid from '@mui/material/Grid2';
import {
  RowCenterStack,
  VisuallyHidden,
  EditIcon,
  CancelEditIcon,
  StyledSelect,
  ChevronDownIcon,
  PrimaryDarkButton,
  StyledFormSubmitButton,
  StyledFormWrapper,
  StyledFormHeader,
  StyledFilledInput,
  StyledFormLabel,
  ActionStatus
} from '@/shared';
import { ForwardedRef, forwardRef, useEffect, useId, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useAppSelector } from '@app/hooks';
import { selectCurrentEmployeeId } from '@app/user';
import { AccountType, Financial, UpdateFinancialRequest } from '@thrivea/organization-client';
import { useDispatch } from 'react-redux';
import { createFinancialSchema, selectFinancialCategory, selectProfileNotification, updateFinancialRequested } from '@features/employee-profile';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { lowerCase, snakeCase } from 'lodash';
import { AllowedTo } from 'react-abac';
import { GroupPermissions, ProfileCategoryInfo } from '@features/abac';

interface FinancialCategoryProps {
  ref: ForwardedRef<HTMLDivElement>;
  employeeId: string;
}

const accountTypes = Object.keys(AccountType)
  .filter((key) => !isNaN(Number(key)))
  .slice(1) // remove unspecified option
  .map((key) => ({
    id: parseInt(key, 10) - 1, //deduct by 1 as we are removing first option
    name: AccountType[Number(key)].toLowerCase()
  }));

export const FinancialCategory = forwardRef<HTMLDivElement, FinancialCategoryProps>(({ employeeId }, ref) => {
  const id = useId();
  const { t } = useTranslation(['common', 'employee_profile', 'account_types']);
  const name = t('financial_category_title', { ns: 'employee_profile' });
  const { status, category } = useAppSelector(selectProfileNotification);
  const isPendingAndMatchingName = status === ActionStatus.Pending && category === lowerCase(name);
  const [isEditable, setIsEditable] = useState(false);
  const [isSummaryVisible, setIsSummaryVisible] = useState(false);
  const dispatch = useDispatch();
  const financialCategory = useAppSelector(selectFinancialCategory);
  const financialSchema = useMemo(() => createFinancialSchema(t), [t]);
  const {
    formState: { dirtyFields, errors },
    control,
    getValues,
    handleSubmit,
    reset
  } = useForm<Financial>({
    mode: 'all',
    resolver: zodResolver(financialSchema),
    defaultValues: {
      accountName: financialCategory.accountName,
      routingNumber: financialCategory.routingNumber,
      accountNumber: financialCategory.accountName,
      bankName: financialCategory.bankName,
      branchAddress: financialCategory.branchAddress,
      swiftCode: financialCategory.swiftCode,
      iban: financialCategory.iban,
      sortCode: financialCategory.sortCode,
      accountType: financialCategory.accountType
    }
  });

  const onSubmit = (data: any) => {
    setIsEditable(false);
    dispatch(
      updateFinancialRequested({
        ...data,
        employeeId
      } as UpdateFinancialRequest)
    );
  };

  const handleToggleEditable = () => {
    setIsEditable(!isEditable);
  };

  const handleCloseEditable = () => {
    setIsEditable(false);
    reset({
      accountName: financialCategory.accountName,
      routingNumber: financialCategory.routingNumber,
      accountNumber: financialCategory.accountName,
      bankName: financialCategory.bankName,
      branchAddress: financialCategory.branchAddress,
      swiftCode: financialCategory.swiftCode,
      iban: financialCategory.iban,
      sortCode: financialCategory.sortCode,
      accountType: financialCategory.accountType
    });
  };

  useEffect(() => {
    reset({
      accountName: financialCategory.accountName,
      routingNumber: financialCategory.routingNumber,
      accountNumber: financialCategory.accountName,
      bankName: financialCategory.bankName,
      branchAddress: financialCategory.branchAddress,
      swiftCode: financialCategory.swiftCode,
      iban: financialCategory.iban,
      sortCode: financialCategory.sortCode,
      accountType: financialCategory.accountType
    });
  }, [financialCategory]);

  return (
    <StyledFormWrapper isEditable={isEditable} id={snakeCase(name)} ref={ref}>
      <Box
        component="form"
        name={name}
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          backgroundColor: 'transparent'
        }}
      >
        <Stack>
          <StyledFormHeader isEditable={isEditable} className="Mui-ProfileFiledHeader">
            <RowCenterStack gap={1}>
              {isPendingAndMatchingName && <CircularProgress size={24} thickness={4} />}
              <Typography component="h3" variant="h5" fontWeight={700}>
                {name}
              </Typography>
            </RowCenterStack>
            <AllowedTo perform={GroupPermissions.EDIT_PROFILE} data={{ employeeId, categoryName: 'financial' } as ProfileCategoryInfo}>
              <IconButton
                onClick={handleToggleEditable}
                sx={{
                  opacity: '0',
                  display: isEditable ? 'none' : 'inline-flex'
                }}
              >
                <VisuallyHidden>Edit {name}</VisuallyHidden>
                <EditIcon />
              </IconButton>
            </AllowedTo>
            <RowCenterStack
              gap={2}
              sx={{
                display: isEditable ? 'flex' : 'none'
              }}
            >
              <PrimaryDarkButton variant="contained" onClick={handleCloseEditable} startIcon={<CancelEditIcon />}>
                {t('cancel', { ns: 'common' })}
              </PrimaryDarkButton>
              <StyledFormSubmitButton
                type="submit"
                disabled={Object.values(dirtyFields).length === 0 || Object.values(errors).length !== 0}
                variant="contained"
              >
                {t('done', { ns: 'common' })}
              </StyledFormSubmitButton>
            </RowCenterStack>
          </StyledFormHeader>
          <Grid
            container
            spacing={2}
            sx={{
              padding: 2
            }}
          >
            <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
              <Controller
                name="accountName"
                control={control}
                render={({ field, fieldState }) => (
                  <StyledFilledInput
                    {...field}
                    id={id + field.name}
                    label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                    disabled={!isEditable}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    onChange={(e) => field.onChange(e.target.value)}
                  />
                )}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
              <Controller
                name="routingNumber"
                control={control}
                render={({ field, fieldState }) => (
                  <StyledFilledInput
                    {...field}
                    id={id + field.name}
                    label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                    disabled={!isEditable}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    onChange={(e) => field.onChange(e.target.value)}
                  />
                )}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
              <Controller
                name="accountNumber"
                control={control}
                render={({ field, fieldState }) => (
                  <StyledFilledInput
                    {...field}
                    id={id + field.name}
                    label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                    disabled={!isEditable}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    onChange={(e) => field.onChange(e.target.value)}
                  />
                )}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
              <Controller
                name="bankName"
                control={control}
                render={({ field, fieldState }) => (
                  <StyledFilledInput
                    {...field}
                    id={id + field.name}
                    label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                    disabled={!isEditable}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    onChange={(e) => field.onChange(e.target.value)}
                  />
                )}
              />
            </Grid>

            <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
              <Controller
                name="branchAddress"
                control={control}
                render={({ field, fieldState }) => (
                  <StyledFilledInput
                    {...field}
                    id={id + field.name}
                    label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                    disabled={!isEditable}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    onChange={(e) => field.onChange(e.target.value)}
                  />
                )}
              />
            </Grid>

            <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
              <Controller
                name="swiftCode"
                control={control}
                render={({ field, fieldState }) => (
                  <StyledFilledInput
                    {...field}
                    id={id + field.name}
                    label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                    disabled={!isEditable}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    onChange={(e) => field.onChange(e.target.value)}
                  />
                )}
              />
            </Grid>

            <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
              <Controller
                name="iban"
                control={control}
                render={({ field, fieldState }) => (
                  <StyledFilledInput
                    {...field}
                    id={id + field.name}
                    label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                    disabled={!isEditable}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    onChange={(e) => field.onChange(e.target.value)}
                  />
                )}
              />
            </Grid>

            <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
              <Controller
                name="sortCode"
                control={control}
                render={({ field, fieldState }) => (
                  <StyledFilledInput
                    {...field}
                    id={id + field.name}
                    label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                    disabled={!isEditable}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    onChange={(e) => field.onChange(e.target.value)}
                  />
                )}
              />
            </Grid>

            <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
              <Controller
                name="accountType"
                control={control}
                render={({ field }) => (
                  <FormControl
                    fullWidth
                    sx={{
                      position: 'relative'
                    }}
                  >
                    <StyledFormLabel shrink disabled={!isEditable} htmlFor={id + field.name}>
                      {t(snakeCase(field.name), { ns: 'employee_profile' })}
                    </StyledFormLabel>
                    <StyledSelect
                      {...field}
                      value={getValues('accountType') ?? 'none'}
                      disabled={!isEditable}
                      inputProps={{ id: id + field.name }}
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                      IconComponent={() => {
                        return (
                          <Box className="MuiSvg-icon">
                            <ChevronDownIcon />
                          </Box>
                        );
                      }}
                    >
                      <MenuItem value="none" disabled>
                        Select
                      </MenuItem>
                      {accountTypes &&
                        accountTypes.map((type) => (
                          <MenuItem key={type.id} value={type.id}>
                            {t(type.name, { ns: 'account_types' })}
                          </MenuItem>
                        ))}
                    </StyledSelect>
                  </FormControl>
                )}
              />
            </Grid>
          </Grid>
        </Stack>
      </Box>
    </StyledFormWrapper>
  );
});
