import { Box, Typography } from '@mui/material';
import { TreeItem2Label } from '@mui/x-tree-view/TreeItem2';
import { ElementType, ReactNode } from 'react';

interface AssetsTreeItemLabelProps {
  children: ReactNode;
  icon?: ElementType;
  expandable?: boolean;
  nestingLevel: number;
}

export const AssetsTreeItemLabel: React.FC<AssetsTreeItemLabelProps> = ({ children, icon, expandable, nestingLevel, ...props }) => {
  return (
    <TreeItem2Label
      {...props}
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: '12px 4px 12px 16px',
        gap: '8px',
        height: 25
      }}
    >
      {icon && <Box component={icon} className="labelIcon" color="inherit" sx={{ mr: 1, fontSize: '1.2rem' }} />}

      <Typography variant="body2">{children}</Typography>
    </TreeItem2Label>
  );
};
