import React from 'react';

interface CustomCheckBoxProps {
  size?: number; // Optional size prop to control the icon size
  color?: string; // Optional color prop to control the icon color
}

export const CustomCheckBox: React.FC<CustomCheckBoxProps> = ({ size = 16, color = '#251D38' }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill="#fff" stroke={color} d="M0.5 0.5h15v15H0.5z" />
    </svg>
  );
};
