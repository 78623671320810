import { RowCenterStack } from '@/shared';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

export const PostSkeleton = () => (
  <Stack gap={3} sx={{ backgroundColor: (theme) => theme.palette.grey[600], padding: 2, borderRadius: 2 }}>
    <RowCenterStack gap={2}>
      <Skeleton animation="pulse" variant="rounded" width={46} height={46} />
      <RowCenterStack gap={2}>
        <Skeleton animation="pulse" height={20} width={200} />
        <Skeleton animation="pulse" height={20} width={100} />
        <Skeleton animation="pulse" height={20} width={100} />
      </RowCenterStack>
    </RowCenterStack>
    <Skeleton sx={{ height: 200 }} animation="pulse" variant="rounded" />
    <RowCenterStack gap={2}>
      <Skeleton animation="pulse" height={36} width={36} />
      <Skeleton animation="pulse" height={36} width={36} />
      <Skeleton animation="pulse" height={36} width={36} />
      <Skeleton animation="pulse" height={36} width={36} />
    </RowCenterStack>
    <Skeleton sx={{ height: 55 }} animation="pulse" variant="rounded" />
  </Stack>
);
