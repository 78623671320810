import React from 'react';
import { AvatarGroup, Box, Divider, Stack, SvgIcon, Typography } from '@mui/material';
import {
  BoardTask,
  ListTaskDescription,
  ListTaskTitle,
  Task,
  TaskActionMenu,
  TaskAvatar,
  TaskStatus,
  TaskStatusChip,
  TasksBoardTasksList
} from '@features/tasks';
import { ClockIcon, CommentIcon, EclipsedTypography, LinkIcon, RowCenterStack } from 'src/shared';
import { startCase } from 'lodash';
import { useTranslation } from 'react-i18next';

interface TasksBoardTaskProps {
  task: Task;
}

export const TasksBoardTask: React.FC<TasksBoardTaskProps> = ({ task }) => {
  const { t } = useTranslation(['common, tasks']);

  if (task.taskList) {
    return <TasksBoardTasksList task={task} />;
  }

  return (
    <BoardTask status={task.status} gap={2}>
      <RowCenterStack sx={{ justifyContent: 'space-between' }}>
        <Typography sx={{ fontSize: 14 }}>{task.id}</Typography>
        <TaskActionMenu taskId={task.id} />
      </RowCenterStack>
      <Stack
        sx={{
          flexDirection: 'row',
          alignItems: 'flex-start',
          justifyContent: 'space-between'
        }}
      >
        <Box sx={{ width: '100%' }}>
          <SvgIcon sx={{ float: 'left' }} viewBox="0 0 576 512">
            <path d="M64 64C28.7 64 0 92.7 0 128l0 64c0 8.8 7.4 15.7 15.7 18.6C34.5 217.1 48 235 48 256s-13.5 38.9-32.3 45.4C7.4 304.3 0 311.2 0 320l0 64c0 35.3 28.7 64 64 64l448 0c35.3 0 64-28.7 64-64l0-64c0-8.8-7.4-15.7-15.7-18.6C541.5 294.9 528 277 528 256s13.5-38.9 32.3-45.4c8.3-2.9 15.7-9.8 15.7-18.6l0-64c0-35.3-28.7-64-64-64L64 64zm64 112l0 160c0 8.8 7.2 16 16 16l288 0c8.8 0 16-7.2 16-16l0-160c0-8.8-7.2-16-16-16l-288 0c-8.8 0-16 7.2-16 16zM96 160c0-17.7 14.3-32 32-32l320 0c17.7 0 32 14.3 32 32l0 192c0 17.7-14.3 32-32 32l-320 0c-17.7 0-32-14.3-32-32l0-192z" />
          </SvgIcon>
          <Divider
            orientation="vertical"
            sx={{ borderColor: (theme) => theme.palette.customTheme.navColor, height: 16, mx: '8px', mt: '4px', float: 'left', borderWidth: 2 }}
          />
          <ListTaskTitle>{task.title}</ListTaskTitle>
        </Box>
        <TaskStatusChip status={task.status} label={startCase(TaskStatus[task.status])} />
      </Stack>
      <EclipsedTypography>{task.description}</EclipsedTypography>
      <RowCenterStack>
        <RowCenterStack gap={'2px'}>
          <ClockIcon />
          <Typography sx={{ fontSize: 14 }}>{t('due_date_label', { ns: 'tasks' })} | </Typography>
        </RowCenterStack>
        <Typography sx={{ fontSize: 14, fontWeight: 700 }}>{task.dueDate}</Typography>
      </RowCenterStack>
      <RowCenterStack sx={{ justifyContent: 'space-between' }}>
        <AvatarGroup
          max={3}
          variant="rounded"
          spacing={-(-17)}
          sx={{
            '.MuiAvatar-root': {
              border: (theme) => `1px solid ${theme.palette.common.black}`,
              width: 34,
              height: 34
            },
            '.MuiAvatarGroup-avatar': {
              position: 'relative',
              ':first-of-type': {
                zIndex: 99
              }
            }
          }}
          slotProps={{}}
        >
          <TaskAvatar position={1} alt="Remy Sharp" src={'https://thispersondoesnotexist.com/'} />
          <TaskAvatar position={2} alt="Travis Howard" src={'https://thispersondoesnotexist.com/'} />
          <TaskAvatar position={3} alt="Cindy Baker" src={'https://thispersondoesnotexist.com/'} />
          <TaskAvatar position={4} alt="Agnes Walker" src={'https://thispersondoesnotexist.com/'} />
          <TaskAvatar position={5} alt="Trevor Henderson" src={'https://thispersondoesnotexist.com/'} />
        </AvatarGroup>
        <RowCenterStack gap={'4px'}>
          <RowCenterStack gap={'2px'}>
            <ListTaskDescription>2</ListTaskDescription>
            <LinkIcon width={16} height={16} />
          </RowCenterStack>
          <RowCenterStack gap={'2px'}>
            <ListTaskDescription>4</ListTaskDescription>
            <CommentIcon size={16} />
          </RowCenterStack>
        </RowCenterStack>
      </RowCenterStack>
    </BoardTask>
  );
};
