import React from 'react';
import { Select, MenuItem, SelectChangeEvent, SelectProps } from '@mui/material';
import { ChevronDownIcon } from '@/shared';
import { capitalize } from 'lodash';

interface GenericSelectProps<T> extends Omit<SelectProps<T>, 'onChange'> {
  options: { label: string; value: T }[];
  value: T;
  onChange: (value: T) => void;
  renderValue?: (value: T) => React.ReactNode;
}

export const GenericSelect = <T extends string | number>({ options, value, onChange, renderValue, ...rest }: GenericSelectProps<T>) => {
  const handleChange = (event: SelectChangeEvent<T>) => {
    onChange(event.target.value as T);
  };

  return (
    <Select
      value={value}
      onChange={handleChange}
      renderValue={renderValue}
      IconComponent={(props) => <ChevronDownIcon {...props} />}
      MenuProps={{
        elevation: 0,
        PaperProps: {
          sx: {
            backgroundColor: 'transparent'
          }
        },
        MenuListProps: {
          sx: {
            backgroundColor: 'transparent',
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            '& .MuiMenuItem-root': {
              height: 20,
              fontSize: 12,
              fontWeight: 700,
              backgroundColor: (theme) => theme.palette.common.white,
              borderRadius: '8px'
            }
          }
        }
      }}
      sx={{
        '& div[role="combobox"]': {
          borderRadius: '10px',
          height: 20,
          minHeight: 'auto',
          padding: '3px 7px'
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderWidth: 0
        },
        '& .MuiSelect-select': {
          border: 0,
          outline: 0,
          padding: '3px 16px',
          backgroundColor: (theme) => theme.palette.customTheme.selectItem,
          borderRadius: '8px',
          height: 20,
          fontSize: 12,
          fontWeight: 700
        },
        '& .MuiPaper-root': {
          backgroundColor: 'transparent'
        },
        '& svg': {
          position: 'absolute',
          top: 9,
          right: 12,
          width: 11,
          height: 11
        },
        '& fieldset': {
          border: 0,
          outline: 0
        }
      }}
      {...rest}
    >
      {options.map((option) => (
        <MenuItem key={String(option.value)} value={option.value}>
          {capitalize(option.label)}
        </MenuItem>
      ))}
    </Select>
  );
};
