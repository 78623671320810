import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

interface CloseIconAttributes extends Omit<SvgIconProps, 'color'> {
  customColor?: string;
}

export const CloseIcon: React.FC<CloseIconAttributes> = ({ width = 18, height = 19, customColor = '#251D38', ...props }) => {
  return (
    <SvgIcon viewBox="0 0 12 12" style={{ width, height }} {...props}>
      <path d="M9 3L3 9M3 3L9 9" stroke={customColor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </SvgIcon>
  );
};
