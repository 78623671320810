import { configureStore, combineReducers } from '@reduxjs/toolkit';
import welcome from '@features/welcome/welcome.slice';
import posts from '@features/homepage/posts.slice';
import navigation from '@features/navigation/navigation.slice';
import theme from '@app/theme/theme.slice';
import employeeProfile from '@features/employee-profile/employee-profile.slice';
import adminOnboarding from '@features/admin-onboarding/admin-onboarding.slice';
import abac from '@features/abac/abac.slice';
import protectedRoute from '@features/protected-routes/protected-routes.slice';
import user from '@app/user/user.slice';
import widgets from '@features/homepage/widgets.slice';
import adminSettings from '@features/admin-settings/admin-settings.slice';
import notifications from '@features/notifications/notifications.slice';
import site from '@shared/sites/sites.slice';
import snackbar from '@features/snackbar/snackbar.slice';
import permissionGroup from '@features/admin-settings/permissions.slice';
import audienceGroup from '@features/admin-settings/audience.slice';
import error from '@app/error/error.slice';
import audienceDrawer from '@features/drawer/audience-drawer.slice';
import drawer from '@features/drawer/drawer.slice';
import employeeRecordBuilder from '@features/employee-record-builder/erb.slice';
import employeeRecord from '@features/employee-record-page/erp.slice';
import peopleDir from '@features/people-directory/people-dir.slice';
import assets from '@features/assets/assets.slice';
import tasks from '@features/tasks/tasks.slice';
import createSagaMiddleware from 'redux-saga';
import { rootSaga } from '@app/saga';

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

const reducer = combineReducers({
  welcome,
  posts,
  navigation,
  theme,
  employeeProfile,
  adminOnboarding,
  abac,
  adminSettings,
  protectedRoute,
  user,
  widgets,
  notifications,
  site,
  snackbar,
  permissionGroup,
  audienceGroup,
  error,
  audienceDrawer,
  drawer,
  employeeRecordBuilder,
  employeeRecord,
  peopleDir,
  assets,
  tasks
});

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(sagaMiddleware),
  devTools: true
});

// then run the saga
sagaMiddleware.run(rootSaga);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
