import { useTheme } from '@mui/material';
import React from 'react';

interface PeopleIconProps {
  width?: number;
  height?: number;
  color?: string;
}

export const PeopleIcon: React.FC<PeopleIconProps> = ({ width = 23, height = 15, color = '#000000' }) => {
  const theme = useTheme();

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox={`0 0 ${width} ${height}`}>
      <path
        fill={color}
        d="M17.25 14.25a.75.75 0 1 1-1.5 0A2.25 2.25 0 0 0 13.5 12H9a2.25 2.25 0 0 0-2.25 2.25.75.75 0 1 1-1.5 0A3.75 3.75 0 0 1 9 10.5h4.5a3.75 3.75 0 0 1 3.75 3.75ZM8.25 6a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm1.5 0a1.5 1.5 0 1 0 3 0 1.5 1.5 0 0 0-3 0Zm9 1.5h-3a.75.75 0 1 0 0 1.5h3A2.25 2.25 0 0 1 21 11.25a.75.75 0 1 0 1.5 0 3.75 3.75 0 0 0-3.75-3.75ZM15 3a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm1.5 0a1.5 1.5 0 1 0 3 0 1.5 1.5 0 0 0-3 0Zm-9 5.25a.75.75 0 0 0-.75-.75h-3A3.75 3.75 0 0 0 0 11.25a.75.75 0 1 0 1.5 0A2.25 2.25 0 0 1 3.75 9h3a.75.75 0 0 0 .75-.75ZM1.5 3a3 3 0 1 1 6 0 3 3 0 0 1-6 0ZM3 3a1.5 1.5 0 1 0 3 0 1.5 1.5 0 0 0-3 0Z"
      />
    </svg>
  );
};
