import React from 'react';

const Used = ({ width, height }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 14 14" fill="none">
    <path
      d="M12.082 10.7996C12.082 11.3996 11.882 11.7996 11.582 12.0996C11.282 12.3996 10.882 12.5996 10.382 12.5996C9.88203 12.5996 9.18203 12.5996 8.48203 12.5996H7.78203V9.79961H12.282C12.282 10.0996 12.282 10.3996 12.282 10.6996L12.082 10.7996ZM7.68203 0.0996094H5.58203C4.78203 0.0996094 4.08203 0.0996094 3.48203 0.0996094C2.98203 0.0996094 2.18203 0.399609 1.48203 0.999609C0.782031 1.69961 0.682031 2.49961 0.582031 3.09961C0.582031 3.59961 0.582031 4.19961 0.582031 4.89961V9.09961C0.582031 9.79961 0.582031 10.3996 0.582031 10.8996C0.582031 11.4996 0.782031 12.2996 1.48203 12.9996C2.18203 13.6996 2.98203 13.8996 3.48203 13.8996C4.08203 13.8996 4.78203 13.8996 5.58203 13.8996H8.38203C9.18203 13.8996 9.88203 13.8996 10.482 13.8996C10.982 13.8996 11.782 13.5996 12.482 12.9996C13.182 12.2996 13.282 11.4996 13.382 10.8996C13.382 10.3996 13.382 9.79961 13.382 9.09961V8.39961H7.58203V0.0996094H7.68203ZM1.88203 3.39961C1.88203 2.79961 2.08203 2.39961 2.38203 2.09961C2.68203 1.79961 3.08203 1.59961 3.58203 1.59961C4.08203 1.59961 4.78203 1.59961 5.48203 1.59961H6.18203V4.39961H1.88203C1.88203 4.09961 1.88203 3.79961 1.88203 3.49961V3.39961ZM1.78203 5.69961H6.28203V8.49961H1.78203V5.69961ZM6.28203 12.6996H5.58203C4.78203 12.6996 4.18203 12.6996 3.68203 12.6996C3.08203 12.6996 2.78203 12.4996 2.48203 12.1996C2.18203 11.8996 2.08203 11.4996 1.98203 10.8996C1.98203 10.5996 1.98203 10.2996 1.98203 9.99961H6.48203V12.7996L6.28203 12.6996Z"
      fill="#D5DCE8"
    />
    <path
      d="M10.6824 2.89941C10.5824 2.89941 10.4824 2.99941 10.4824 3.09941V4.39941C10.4824 4.39941 10.4824 4.49941 10.4824 4.59941L11.0824 5.19941C11.0824 5.19941 11.1824 5.19941 11.2824 5.19941C11.3824 5.19941 11.3824 5.19941 11.4824 5.19941C11.4824 5.19941 11.4824 4.99941 11.4824 4.89941L10.8824 4.29941V3.09941C10.8824 2.99941 10.7824 2.89941 10.6824 2.89941Z"
      fill="#D5DCE8"
    />
    <path
      d="M12.9816 2.69989C12.5816 2.09989 11.9816 1.69989 11.1816 1.59989C10.2816 1.39989 9.38164 1.69989 8.78164 2.39989V2.19989C8.78164 2.19989 8.78164 1.99989 8.68164 1.99989C8.68164 1.99989 8.58164 1.99989 8.48164 1.99989C8.28164 1.99989 8.18164 2.19989 8.18164 2.29989V3.39989C8.18164 3.39989 8.18164 3.59989 8.28164 3.59989C8.28164 3.59989 8.28164 3.59989 8.48164 3.59989H9.58164C9.78164 3.59989 9.88164 3.39989 9.88164 3.19989C9.88164 3.19989 9.88164 2.99989 9.78164 2.99989C9.78164 2.99989 9.68164 2.99989 9.58164 2.99989H9.48164C9.88164 2.59989 10.5816 2.39989 11.1816 2.49989C11.6816 2.49989 12.0816 2.89989 12.3816 3.29989C12.6816 3.69989 12.7816 4.19989 12.6816 4.69989C12.4816 5.69989 11.4816 6.39989 10.4816 6.19989C10.2816 6.19989 10.0816 6.29989 9.98164 6.49989C9.98164 6.69989 10.0816 6.89989 10.2816 6.99989C10.5816 6.99989 10.8816 6.99989 11.0816 6.99989C12.2816 6.89989 13.1816 5.99989 13.3816 4.79989C13.4816 4.09989 13.3816 3.39989 12.8816 2.79989L12.9816 2.69989Z"
      fill="#D5DCE8"
    />
  </svg>
);

const PresentInOtherSection = ({ width, height }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 14 14" fill="none">
    <path
      d="M12.082 10.7996C12.082 11.3996 11.882 11.7996 11.582 12.0996C11.282 12.3996 10.882 12.5996 10.382 12.5996C9.88203 12.5996 9.18203 12.5996 8.48203 12.5996H7.78203V9.79961H12.282C12.282 10.0996 12.282 10.3996 12.282 10.6996L12.082 10.7996ZM7.68203 0.0996094H5.58203C4.78203 0.0996094 4.08203 0.0996094 3.48203 0.0996094C2.98203 0.0996094 2.18203 0.399609 1.48203 0.999609C0.782031 1.69961 0.682031 2.49961 0.582031 3.09961C0.582031 3.59961 0.582031 4.19961 0.582031 4.89961V9.09961C0.582031 9.79961 0.582031 10.3996 0.582031 10.8996C0.582031 11.4996 0.782031 12.2996 1.48203 12.9996C2.18203 13.6996 2.98203 13.8996 3.48203 13.8996C4.08203 13.8996 4.78203 13.8996 5.58203 13.8996H8.38203C9.18203 13.8996 9.88203 13.8996 10.482 13.8996C10.982 13.8996 11.782 13.5996 12.482 12.9996C13.182 12.2996 13.282 11.4996 13.382 10.8996C13.382 10.3996 13.382 9.79961 13.382 9.09961V8.39961H7.58203V0.0996094H7.68203ZM1.88203 3.39961C1.88203 2.79961 2.08203 2.39961 2.38203 2.09961C2.68203 1.79961 3.08203 1.59961 3.58203 1.59961C4.08203 1.59961 4.78203 1.59961 5.48203 1.59961H6.18203V4.39961H1.88203C1.88203 4.09961 1.88203 3.79961 1.88203 3.49961V3.39961ZM1.78203 5.69961H6.28203V8.49961H1.78203V5.69961ZM6.28203 12.6996H5.58203C4.78203 12.6996 4.18203 12.6996 3.68203 12.6996C3.08203 12.6996 2.78203 12.4996 2.48203 12.1996C2.18203 11.8996 2.08203 11.4996 1.98203 10.8996C1.98203 10.5996 1.98203 10.2996 1.98203 9.99961H6.48203V12.7996L6.28203 12.6996Z"
      fill="#251D38"
    />
    <path
      d="M10.6824 2.89941C10.5824 2.89941 10.4824 2.99941 10.4824 3.09941V4.39941C10.4824 4.39941 10.4824 4.49941 10.4824 4.59941L11.0824 5.19941C11.0824 5.19941 11.1824 5.19941 11.2824 5.19941C11.3824 5.19941 11.3824 5.19941 11.4824 5.19941C11.4824 5.19941 11.4824 4.99941 11.4824 4.89941L10.8824 4.29941V3.09941C10.8824 2.99941 10.7824 2.89941 10.6824 2.89941Z"
      fill="#251D38"
    />
    <path
      d="M12.9816 2.69989C12.5816 2.09989 11.9816 1.69989 11.1816 1.59989C10.2816 1.39989 9.38164 1.69989 8.78164 2.39989V2.19989C8.78164 2.19989 8.78164 1.99989 8.68164 1.99989C8.68164 1.99989 8.58164 1.99989 8.48164 1.99989C8.28164 1.99989 8.18164 2.19989 8.18164 2.29989V3.39989C8.18164 3.39989 8.18164 3.59989 8.28164 3.59989C8.28164 3.59989 8.28164 3.59989 8.48164 3.59989H9.58164C9.78164 3.59989 9.88164 3.39989 9.88164 3.19989C9.88164 3.19989 9.88164 2.99989 9.78164 2.99989C9.78164 2.99989 9.68164 2.99989 9.58164 2.99989H9.48164C9.88164 2.59989 10.5816 2.39989 11.1816 2.49989C11.6816 2.49989 12.0816 2.89989 12.3816 3.29989C12.6816 3.69989 12.7816 4.19989 12.6816 4.69989C12.4816 5.69989 11.4816 6.39989 10.4816 6.19989C10.2816 6.19989 10.0816 6.29989 9.98164 6.49989C9.98164 6.69989 10.0816 6.89989 10.2816 6.99989C10.5816 6.99989 10.8816 6.99989 11.0816 6.99989C12.2816 6.89989 13.1816 5.99989 13.3816 4.79989C13.4816 4.09989 13.3816 3.39989 12.8816 2.79989L12.9816 2.69989Z"
      fill="#251D38"
    />
  </svg>
);

const Unused = ({ width, height }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 14 14" fill="none">
    <path
      d="M12.082 10.7996C12.082 11.3996 11.882 11.7996 11.582 12.0996C11.282 12.3996 10.882 12.5996 10.382 12.5996C9.88203 12.5996 9.18203 12.5996 8.48203 12.5996H7.78203V9.79961H12.282C12.282 10.0996 12.282 10.3996 12.282 10.6996L12.082 10.7996ZM7.68203 0.0996094H5.58203C4.78203 0.0996094 4.08203 0.0996094 3.48203 0.0996094C2.98203 0.0996094 2.18203 0.399609 1.48203 0.999609C0.782031 1.69961 0.682031 2.49961 0.582031 3.09961C0.582031 3.59961 0.582031 4.19961 0.582031 4.89961V9.09961C0.582031 9.79961 0.582031 10.3996 0.582031 10.8996C0.582031 11.4996 0.782031 12.2996 1.48203 12.9996C2.18203 13.6996 2.98203 13.8996 3.48203 13.8996C4.08203 13.8996 4.78203 13.8996 5.58203 13.8996H8.38203C9.18203 13.8996 9.88203 13.8996 10.482 13.8996C10.982 13.8996 11.782 13.5996 12.482 12.9996C13.182 12.2996 13.282 11.4996 13.382 10.8996C13.382 10.3996 13.382 9.79961 13.382 9.09961V8.39961H7.58203V0.0996094H7.68203ZM1.88203 3.39961C1.88203 2.79961 2.08203 2.39961 2.38203 2.09961C2.68203 1.79961 3.08203 1.59961 3.58203 1.59961C4.08203 1.59961 4.78203 1.59961 5.48203 1.59961H6.18203V4.39961H1.88203C1.88203 4.09961 1.88203 3.79961 1.88203 3.49961V3.39961ZM1.78203 5.69961H6.28203V8.49961H1.78203V5.69961ZM6.28203 12.6996H5.58203C4.78203 12.6996 4.18203 12.6996 3.68203 12.6996C3.08203 12.6996 2.78203 12.4996 2.48203 12.1996C2.18203 11.8996 2.08203 11.4996 1.98203 10.8996C1.98203 10.5996 1.98203 10.2996 1.98203 9.99961H6.48203V12.7996L6.28203 12.6996Z"
      fill="#478EFF"
    />
    <path
      d="M10.6824 2.89941C10.5824 2.89941 10.4824 2.99941 10.4824 3.09941V4.39941C10.4824 4.39941 10.4824 4.49941 10.4824 4.59941L11.0824 5.19941C11.0824 5.19941 11.1824 5.19941 11.2824 5.19941C11.3824 5.19941 11.3824 5.19941 11.4824 5.19941C11.4824 5.19941 11.4824 4.99941 11.4824 4.89941L10.8824 4.29941V3.09941C10.8824 2.99941 10.7824 2.89941 10.6824 2.89941Z"
      fill="#478EFF"
    />
    <path
      d="M12.9816 2.69989C12.5816 2.09989 11.9816 1.69989 11.1816 1.59989C10.2816 1.39989 9.38164 1.69989 8.78164 2.39989V2.19989C8.78164 2.19989 8.78164 1.99989 8.68164 1.99989C8.68164 1.99989 8.58164 1.99989 8.48164 1.99989C8.28164 1.99989 8.18164 2.19989 8.18164 2.29989V3.39989C8.18164 3.39989 8.18164 3.59989 8.28164 3.59989C8.28164 3.59989 8.28164 3.59989 8.48164 3.59989H9.58164C9.78164 3.59989 9.88164 3.39989 9.88164 3.19989C9.88164 3.19989 9.88164 2.99989 9.78164 2.99989C9.78164 2.99989 9.68164 2.99989 9.58164 2.99989H9.48164C9.88164 2.59989 10.5816 2.39989 11.1816 2.49989C11.6816 2.49989 12.0816 2.89989 12.3816 3.29989C12.6816 3.69989 12.7816 4.19989 12.6816 4.69989C12.4816 5.69989 11.4816 6.39989 10.4816 6.19989C10.2816 6.19989 10.0816 6.29989 9.98164 6.49989C9.98164 6.69989 10.0816 6.89989 10.2816 6.99989C10.5816 6.99989 10.8816 6.99989 11.0816 6.99989C12.2816 6.89989 13.1816 5.99989 13.3816 4.79989C13.4816 4.09989 13.3816 3.39989 12.8816 2.79989L12.9816 2.69989Z"
      fill="#478EFF"
    />
  </svg>
);

interface HistoryTableFieldIconProps {
  width?: number;
  height?: number;
  isSelected?: boolean;
  isPresentInOtherSections?: boolean;
}

export const HistoryTableFieldIcon: React.FC<HistoryTableFieldIconProps> = ({
  width = 14,
  height = 14,
  isSelected,
  isPresentInOtherSections
}: HistoryTableFieldIconProps) => {
  if (isSelected) return <Used width={width} height={height} />;
  if (isPresentInOtherSections) return <PresentInOtherSection width={width} height={height} />;

  return <Unused width={width} height={height} />;
};
