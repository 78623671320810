import { Box, Skeleton, Typography } from '@mui/material';
import { ActionStatus } from 'src/shared';
import { Department } from '@thrivea/organization-client';
import { useAppSelector } from '@app/hooks';
import { selectRetrieveTwoStepDataStatus } from '@features/people-directory';

interface PeopleDirectoryTableDepartmentCellProps {
  department: Department;
}

export const PeopleDirectoryTableDepartmentCell = ({ department }: PeopleDirectoryTableDepartmentCellProps): JSX.Element => {
  const retrieveTwoStepDataStatus = useAppSelector(selectRetrieveTwoStepDataStatus);

  // TODO: use click handler
  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        alignItems: 'center'
      }}
    >
      {retrieveTwoStepDataStatus === ActionStatus.Pending && <Skeleton animation="wave" />}
      {retrieveTwoStepDataStatus === ActionStatus.Idle && (
        <Typography variant="body2" sx={{ fontWeight: 700 }}>
          {department.name}
        </Typography>
      )}
    </Box>
  );
};
