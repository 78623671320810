import React, { Fragment, useState } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { Link, ListItemIcon, ListItemText, Box, Collapse, List, IconButton } from '@mui/material';
import { kebabCase } from 'lodash';
import { useAppSelector } from '@app/hooks';
import { selectErbFieldCategoriesWithActiveSections, selectErTemplateSections } from '@features/employee-record-builder';
import { NavListItem, selectNavigationIsExpanded, StyledNavListItemButton } from '@features/navigation';
import { RowCenterStack, ChevronDownIcon, VisuallyHidden, PerspectiveIcon, GroupIcon, UnGroupIcon } from '@/shared';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { t } from 'i18next';
import { AssetsTooltip } from '@features/assets';

const SectionList = ({ sections }) => (
  <List sx={{ width: '100%', paddingLeft: '16px' }}>
    {sections.map((s) => (
      <Link href={`#${kebabCase(s.name)}`} data-to-scrollspy-id={kebabCase(s.name)} key={s.id} style={{ textDecoration: 'none' }}>
        <StyledNavListItemButton>
          <ListItemText
            primary={s.name}
            sx={{
              '& .MuiTypography-root': {
                fontSize: '16px',
                lineHeight: '24px',
                fontWeight: 500
              }
            }}
          />
        </StyledNavListItemButton>
      </Link>
    ))}
  </List>
);

const CategoryList = ({ categories, primaryItemOpen, handlePrimaryItemClick }) => (
  <List sx={{ width: '100%', paddingLeft: '16px' }}>
    {categories.map((c) => (
      <Fragment key={c.categoryId}>
        <StyledNavListItemButton onClick={(e) => handlePrimaryItemClick(e, c.categoryId)}>
          <ListItemText
            primary={c.categoryName}
            sx={{
              '& .MuiTypography-root': {
                fontSize: '16px',
                lineHeight: '24px',
                fontWeight: 500
              }
            }}
          />
          {c.sections.length > 0 && (
            <IconButton disableFocusRipple sx={{ p: 0, m: 0 }}>
              {primaryItemOpen === c.categoryId ? (
                <>
                  <ExpandLess />
                  <VisuallyHidden>Expand {c.categoryName}</VisuallyHidden>
                </>
              ) : (
                <>
                  <ExpandMore />
                  <VisuallyHidden>Expand {c.categoryName}</VisuallyHidden>
                </>
              )}
            </IconButton>
          )}
        </StyledNavListItemButton>
        {c.sections.length > 0 && (
          <Collapse in={primaryItemOpen === c.categoryId} timeout="auto" unmountOnExit>
            <SectionList sections={c.sections} />
          </Collapse>
        )}
      </Fragment>
    ))}
  </List>
);

export const ERBuilderNavigationItem = () => {
  const navigationIsExpanded = useAppSelector(selectNavigationIsExpanded);
  const sections = useAppSelector(selectErTemplateSections);
  const categoriesWithActiveSections = useAppSelector(selectErbFieldCategoriesWithActiveSections);

  const [isSubExpanded, setIsSubExpanded] = useState(false);
  const [isGrouped, setIsGrouped] = useState(false);
  const [primaryItemOpen, setPrimaryItemOpen] = useState('');

  const navigate = useNavigate();
  const location = useLocation();

  const handlePrimaryItemClick = (e: React.MouseEvent<HTMLDivElement>, name: string) => {
    e.preventDefault();
    setPrimaryItemOpen(name === primaryItemOpen ? '' : name);
  };

  const handleGroupedButtonClicked = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsGrouped(!isGrouped);
    setPrimaryItemOpen(categoriesWithActiveSections[0].categoryId);
  };

  const handleBuilderButtonClick = () => {
    setIsSubExpanded(!isSubExpanded);
    if (location.pathname !== '/settings/employee-record-builder') {
      navigate('/settings/employee-record-builder');
    }
  };

  return (
    <>
      {!navigationIsExpanded && (
        <NavListItem isDisabled={false} name="Builder" url="/settings/employee-record-builder" icon={<PerspectiveIcon width={20} height={20} />} />
      )}
      {navigationIsExpanded && (
        <>
          <StyledNavListItemButton onClick={handleBuilderButtonClick}>
            <RowCenterStack gap={1}>
              <ListItemIcon sx={{ width: 20, height: 20, minWidth: 'unset' }}>
                <PerspectiveIcon width={20} height={20} />
              </ListItemIcon>

              <ListItemText
                primary={'Builder'}
                sx={{
                  '& .MuiTypography-root': {
                    fontSize: '16px',
                    lineHeight: '24px',
                    fontWeight: 500
                  }
                }}
              />
              {isSubExpanded && (
                <AssetsTooltip title={isGrouped ? t('categories', { ns: 'common' }) : t('list_sections', { ns: 'common' })}>
                  <IconButton
                    onClick={(e) => handleGroupedButtonClicked(e)}
                    sx={{
                      backgroundColor: (theme) => theme.palette.customTheme.drawerBackground,
                      borderRadius: '8px'
                    }}
                  >
                    {!isGrouped && <GroupIcon width={16} height={16} color="#251D38" />}
                    {isGrouped && <UnGroupIcon width={16} height={16} color="#251D38" />}
                  </IconButton>
                </AssetsTooltip>
              )}
            </RowCenterStack>
            <AssetsTooltip title={t('expand_name', { ns: 'common', name: 'Builder' })}>
              <IconButton disableFocusRipple sx={{ p: 0, m: 0 }}>
                <Box
                  sx={{
                    paddingRight: '4px',
                    '& svg': {
                      transform: isSubExpanded ? 'rotate(0deg)' : 'rotate(-90deg)'
                    }
                  }}
                >
                  <ChevronDownIcon />
                </Box>
                <VisuallyHidden>{t('expand_name', { ns: 'common', name: 'Builder' })}</VisuallyHidden>
              </IconButton>
            </AssetsTooltip>
          </StyledNavListItemButton>
          <Collapse in={isSubExpanded}>
            {!isGrouped ? (
              <SectionList sections={sections} />
            ) : (
              <CategoryList categories={categoriesWithActiveSections} primaryItemOpen={primaryItemOpen} handlePrimaryItemClick={handlePrimaryItemClick} />
            )}
          </Collapse>
        </>
      )}
    </>
  );
};
