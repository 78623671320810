import { createClient } from '@connectrpc/connect';
import { createGrpcWebTransport } from '@connectrpc/connect-web';
import {
  CommentOnTaskRequest,
  CreateSingleTaskRequest,
  CreateTaskListRequest,
  CreateTasksViewRequest,
  DateRange,
  DeleteTaskCommentRequest,
  DeleteTasksViewRequest,
  DueDateType,
  EditTaskCommentRequest,
  EditTaskListRequest,
  EditTaskRequest,
  PagedPrivateTasksByDueDate,
  PagedPrivateTasksByDueDates,
  PagedPrivateTasksByStatus,
  PagedPrivateTasksByStatuses,
  PinForAllParticipantsRequest,
  PinRequest,
  ReactToTaskCommentRequest,
  ReactToTaskRequest,
  RenameTasksViewRequest,
  RetrieveAllTasksRequest,
  RetrieveAllTasksResponse,
  RetrieveSingleTaskRelationsResponse,
  RetrieveSingleTaskResponse,
  RetrieveSingleTasksForTaskListResponse,
  RetrieveSingleTasksInTaskListResponse,
  RetrieveTaskListRelationsResponse,
  RetrieveTasksForDependenciesResponse,
  RetrieveTasksRequest,
  RetrieveTasksResponse,
  RetrieveTaskStatusesResponse,
  RetrieveTasksViewsRequest,
  RetrieveTasksViewsResponse,
  SearchQuery,
  SingleTaskId,
  SingleTaskIdWithEmployeeId,
  SingleTaskItem,
  SingleTaskView,
  TaskKindWithPrivate,
  TaskListId,
  TasksService,
  TasksView,
  TasksViewFilters,
  TaskType,
  UnpinForAllParticipantsRequest,
  UnpinRequest,
  UpdateTasksViewRequest,
  WithdrawTaskCommentReactionRequest,
  WithdrawTaskReactionRequest
} from '@thrivea/organization-client';
import { DateTime } from 'luxon';
import { ASSIGNED, COMPLETED, DRAFT, IN_REVIEW } from 'src/features/tasks';
import { msalInstance } from '@/main';
import { tokenRequest } from '@/utils';
import { Empty } from '@bufbuild/protobuf';

export const tasksClient = createClient(
  TasksService,
  createGrpcWebTransport({
    baseUrl: import.meta.env.VITE_APP_API_URL,
    useBinaryFormat: true
  })
);

const tasks: TaskKindWithPrivate[] = [
  new TaskKindWithPrivate({
    kind: {
      case: 'singleTaskItem',
      value: new SingleTaskItem({
        singleTaskId: '1',
        title: 'Enhancing the onboarding experience for new team members',
        publicId: '#M9N001-P2',
        assigneeCount: 1,
        assigneeIds: [],
        reviewerCount: 0,
        reviewerIds: [],
        description: '',
        dueAt: '12/03/2024 | 17:00',
        statusId: COMPLETED
      })
    }
  }),
  new TaskKindWithPrivate({
    kind: {
      case: 'singleTaskItem',
      value: new SingleTaskItem({
        singleTaskId: '2',
        title: 'Implementing a new performance review system',
        description: '',
        publicId: '#M9N002-P2',
        assigneeCount: 1,
        assigneeIds: [],
        reviewerCount: 0,
        reviewerIds: ['111'], // Alex Johnson
        dueAt: '12/03/2024 | 17:00',
        statusId: DRAFT
      })
    }
  }),
  new TaskKindWithPrivate({
    kind: {
      case: 'singleTaskItem',
      value: new SingleTaskItem({
        singleTaskId: '3',
        publicId: '#M9N003-P2',
        title: 'Implementing a new performance review system',
        description:
          'Exercitation qui Lorem esse ullamco Lorem excepteur deserunt anim. Culpa ullamco Lorem officia qui. Ad veniam cupidatat cupidatat aute nisi enim duis fugiat do officia fugiat ea fugiat. Anim anim excepteur eu proident mollit sit quis. Irure ex sint minim pariatur consequat id duis ut sit elit. Excepteur id do eiusmod non duis.Sunt nisi laboris et consequat eiusmod eu pariatur eiusmod veniam id magna incididunt consequat cillum. Amet nostrud aute commodo do magna occaecat cupidatat. Dolore Lorem mollit mollit cillum adipisicing non dolor cillum tempor dolor. Anim enim consectetur amet adipisicing mollit dolor. Reprehenderit eiusmod consequat velit exercitation irure cupidatat fugiat.',
        assigneeCount: 1,
        assigneeIds: [],
        reviewerCount: 0,
        reviewerIds: ['Alex Johnson'], // Alex Johnson
        dueAt: '12/03/2024 | 17:00',
        statusId: IN_REVIEW
      })
    }
  }),
  new TaskKindWithPrivate({
    kind: {
      case: 'singleTaskItem',
      value: new SingleTaskItem({
        singleTaskId: '4',
        publicId: '#M9N004-P2',
        title: 'Fill out Heart Count survey',
        description: 'Team, we need to fill asap Heart Count survey on how we are satisfied with cookies in the office.',
        assigneeCount: 1,
        assigneeIds: [],
        reviewerCount: 0,
        reviewerIds: ['Alex Johnson'], // Alex Johnson
        dueAt: '12/03/2024 | 17:00',
        statusId: ASSIGNED
      })
    }
  })
];

export const retrieveTasks = async (request: RetrieveTasksRequest): Promise<RetrieveTasksResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await tasksClient.retrieveTasks(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });

  // if (request.groupBy.case === 'dueDates')
  //   return new Promise<RetrieveTasksResponse>((resolve) => {
  //     // Create a handmade object of type RetrieveTasksResponse
  //     const handmadeResponse: RetrieveTasksResponse = new RetrieveTasksResponse({
  //       kind: {
  //         case: 'pagedTasksByDueDates',
  //         value: new PagedPrivateTasksByDueDates({
  //           pagedTasksByDueDates: [
  //             new PagedPrivateTasksByDueDate({
  //               dueDate: DueDateType.DUE_TODAY,
  //               totalCount: 2,
  //               tasks: [tasks[0], tasks[3]]
  //             }),
  //             new PagedPrivateTasksByDueDate({
  //               dueDate: DueDateType.DUE_THIS_WEEK,
  //               totalCount: 1,
  //               tasks: [tasks[1]]
  //             }),
  //             new PagedPrivateTasksByDueDate({
  //               dueDate: DueDateType.DUE_NEXT_WEEK,
  //               totalCount: 1,
  //               tasks: [tasks[2]]
  //             }),
  //             new PagedPrivateTasksByDueDate({
  //               dueDate: DueDateType.DUE_LATER,
  //               totalCount: 0,
  //               tasks: []
  //             })
  //           ]
  //         })
  //       }
  //     });

  //     // Resolve the promise with the object
  //     resolve(handmadeResponse);
  //   });

  // return new Promise<RetrieveTasksResponse>((resolve) => {
  //   // Create a handmade object of type RetrieveTasksResponse
  //   const handmadeResponse: RetrieveTasksResponse = new RetrieveTasksResponse({
  //     kind: {
  //       case: 'pagedTasksByStatuses',
  //       value: new PagedPrivateTasksByStatuses({
  //         pagedTasksByStatuses: [
  //           new PagedPrivateTasksByStatus({
  //             statusId: DRAFT,
  //             totalCount: 1,
  //             tasks: tasks.filter((t) => t.kind.case === 'singleTaskItem' && t.kind.value.statusId === DRAFT)
  //           }),
  //           new PagedPrivateTasksByStatus({
  //             statusId: ASSIGNED,
  //             totalCount: 1,
  //             tasks: tasks.filter((t) => t.kind.case === 'singleTaskItem' && t.kind.value.statusId === ASSIGNED)
  //           }),
  //           new PagedPrivateTasksByStatus({
  //             statusId: IN_REVIEW,
  //             totalCount: 1,
  //             tasks: tasks.filter((t) => t.kind.case === 'singleTaskItem' && t.kind.value.statusId === IN_REVIEW)
  //           }),
  //           new PagedPrivateTasksByStatus({
  //             statusId: COMPLETED,
  //             totalCount: 1,
  //             tasks: tasks.filter((t) => t.kind.case === 'singleTaskItem' && t.kind.value.statusId === COMPLETED)
  //           })
  //         ]
  //       })
  //     }
  //   });

  //   // Resolve the promise with the object
  //   resolve(handmadeResponse);
  // });
};

export const retrieveAllTasks = async (request: RetrieveAllTasksRequest): Promise<RetrieveAllTasksResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await tasksClient.retrieveAllTasks(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveTasksViews = async (request: RetrieveTasksViewsRequest): Promise<RetrieveTasksViewsResponse> => {
  // const account = await msalInstance.acquireTokenSilent(tokenRequest);
  // return await tasksClient.retrieveTasksViews(request, {
  //   headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  // });

  return new Promise<RetrieveTasksViewsResponse>((resolve) => {
    // Create a handmade object of type RetrieveTasksResponse
    const handmadeResponse: RetrieveTasksViewsResponse = new RetrieveTasksViewsResponse({
      tasksViews: [
        new TasksView({
          tasksViewId: '1',
          name: 'Tasks View 1',
          createdAt: DateTime.fromJSDate(new Date()).toISODate()!.toString(),
          lastModifiedAt: DateTime.fromJSDate(new Date()).toISODate()!.toString(),
          kind: {
            case: 'filters',
            value: new TasksViewFilters({
              type: TaskType.TASK_TYPE_UNSPECIFIED,
              statusIds: [],
              ownerIds: [],
              assigneeIds: [],
              reviewerIds: [],
              dueDateRange: new DateRange({}),
              searchQuery: new SearchQuery({})
            })
          }
        }),
        new TasksView({
          tasksViewId: '2',
          name: 'Single Task View',
          createdAt: DateTime.fromJSDate(new Date()).toISODate()!.toString(),
          lastModifiedAt: DateTime.fromJSDate(new Date()).toISODate()!.toString(),
          kind: {
            case: 'single',
            value: new SingleTaskView({
              singleTaskId: '1'
            })
          }
        })
      ]
    });

    // Resolve the promise with the object
    resolve(handmadeResponse);
  });
};

export const createSingleTask = async (request: CreateSingleTaskRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await tasksClient.createSingleTask(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const createTaskView = async (request: CreateTasksViewRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await tasksClient.createTasksView(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const renameTasksView = async (request: RenameTasksViewRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await tasksClient.renameTasksView(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const deleteTasksView = async (request: DeleteTasksViewRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await tasksClient.deleteTasksView(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const updateTasksView = async (request: UpdateTasksViewRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await tasksClient.updateTasksView(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveTasksForDependencies = async (request: Empty): Promise<RetrieveTasksForDependenciesResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await tasksClient.retrieveTasksForDependencies(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveSingleTasksForTaskList = async (request: Empty): Promise<RetrieveSingleTasksForTaskListResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await tasksClient.retrieveSingleTasksForTaskList(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const deleteSingleTask = async (request: SingleTaskId): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await tasksClient.deleteSingleTask(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveSingleTask = async (request: SingleTaskId): Promise<RetrieveSingleTaskResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await tasksClient.retrieveSingleTask(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const reactToTask = async (request: ReactToTaskRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await tasksClient.reactToTask(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const withdrawTaskReaction = async (request: WithdrawTaskReactionRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await tasksClient.withdrawTaskReaction(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const commentOnTask = async (request: CommentOnTaskRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await tasksClient.commentOnTask(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const reactToTaskComment = async (request: ReactToTaskCommentRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await tasksClient.reactToTaskComment(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const withdrawTaskCommentReaction = async (request: WithdrawTaskCommentReactionRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await tasksClient.withdrawTaskCommentReaction(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const editTaskComment = async (request: EditTaskCommentRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await tasksClient.editTaskComment(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const deleteTaskComment = async (request: DeleteTaskCommentRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await tasksClient.deleteTaskComment(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const createTaskList = async (request: CreateTaskListRequest): Promise<TaskListId> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await tasksClient.createTaskList(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const editTaskList = async (request: EditTaskListRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await tasksClient.editTaskList(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const deleteTaskList = async (request: TaskListId): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await tasksClient.deleteTaskList(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveTaskStatuses = async (request: Empty): Promise<RetrieveTaskStatusesResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await tasksClient.retrieveTaskStatuses(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveSingleTaskRelations = async (request: SingleTaskId): Promise<RetrieveSingleTaskRelationsResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await tasksClient.retrieveSingleTaskRelations(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveTaskListRelations = async (request: TaskListId): Promise<RetrieveTaskListRelationsResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await tasksClient.retrieveTaskListRelations(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const pin = async (request: PinRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await tasksClient.pin(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const unpin = async (request: UnpinRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await tasksClient.unpin(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const pinForAllParticipants = async (request: PinForAllParticipantsRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await tasksClient.pinForAllParticipants(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const unpinForAllParticipants = async (request: UnpinForAllParticipantsRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await tasksClient.unpinForAllParticipants(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const markTaskAsCompleted = async (request: SingleTaskIdWithEmployeeId): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await tasksClient.markTaskAsCompleted(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const sendTaskToReviewer = async (request: SingleTaskIdWithEmployeeId): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await tasksClient.sendTaskToReviewer(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const approveTask = async (request: SingleTaskIdWithEmployeeId): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await tasksClient.approveTask(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const returnToReviewer = async (request: SingleTaskIdWithEmployeeId): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await tasksClient.returnToReviewer(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const returnTaskToAssignee = async (request: SingleTaskIdWithEmployeeId): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await tasksClient.returnTaskToAssignee(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const editTask = async (request: EditTaskRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await tasksClient.editTask(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const archiveTask = async (request: SingleTaskId): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await tasksClient.archiveTask(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveSingleTasksInTaskList = async (request: TaskListId): Promise<RetrieveSingleTasksInTaskListResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await tasksClient.retrieveSingleTasksInTaskList(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};
