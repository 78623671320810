import React from 'react';

interface AssetsSharePeopleIconProps {
  width?: number;
  height?: number;
  color?: string;
  opacity?: number;
}

export const AssetsSharePeopleIcon: React.FC<AssetsSharePeopleIconProps> = ({ width = 25, height = 25, color = '#251D38', opacity = 0.45 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 25 25" fill="none">
      <path
        d="M17 4.5L22 9.5M22 4.5L17 9.5M16.5 21.5V20.3C16.5 18.6198 16.5 17.7798 16.173 17.138C15.8854 16.5735 15.4265 16.1146 14.862 15.827C14.2202 15.5 13.3802 15.5 11.7 15.5H7.3C5.61984 15.5 4.77976 15.5 4.13803 15.827C3.57354 16.1146 3.1146 16.5735 2.82698 17.138C2.5 17.7798 2.5 18.6198 2.5 20.3V21.5M13 8C13 9.933 11.433 11.5 9.5 11.5C7.567 11.5 6 9.933 6 8C6 6.067 7.567 4.5 9.5 4.5C11.433 4.5 13 6.067 13 8Z"
        stroke={color}
        strokeOpacity={opacity}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
