import { createClient } from '@connectrpc/connect';
import { createGrpcWebTransport } from '@connectrpc/connect-web';
import {
  DeactivateEmployeesRequest,
  EmployeeResults,
  EmployeeService,
  ResendInvitationEmailRequest,
  RetrieveAllEmployeesResponse,
  RetrieveEmployeeBasicInfoRequest,
  RetrieveEmployeeBasicInfoResponse,
  RetrieveEmployeesByIdsRequest,
  RetrieveEmployeesByJobTitleRequest,
  RetrieveEmployeesByJobTitleResponse,
  RetrieveEmployeesByManagerIdRequest,
  RetrieveEmployeesByManagerIdResponse,
  RetrieveEmployeesByQueryRequest,
  RetrieveEmployeesByQueryResponse,
  RetrieveEmployeesBySelectionRequest,
  RetrieveEmployeesBySelectionResponse,
  RetrieveEmployeesBySiteIdRequest,
  RetrieveEmployeesBySiteIdResponse,
  RetrieveEmployeesByWorkingPatternIdRequest,
  RetrieveEmployeesByWorkingPatternIdResponse,
  RetrieveEmployeesTotalCountBySelectedRequest,
  RetrieveEmployeesTotalCountBySelectedResponse,
  RetrieveEmployeesTotalCountResponse
} from '@thrivea/organization-client';
import { msalInstance } from '@/main';
import { tokenRequest } from '@utils/authConfig';
import { Empty } from '@bufbuild/protobuf/wkt';

const employeesClient = createClient(
  EmployeeService,
  createGrpcWebTransport({
    baseUrl: import.meta.env.VITE_APP_API_URL,
    useBinaryFormat: true
  })
);

export const retrieveEmployeeBasicInfo = async (request: RetrieveEmployeeBasicInfoRequest): Promise<RetrieveEmployeeBasicInfoResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await employeesClient.retrieveEmployeeBasicInfo(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveEmployeesBySiteId = async (request: RetrieveEmployeesBySiteIdRequest): Promise<RetrieveEmployeesBySiteIdResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await employeesClient.retrieveEmployeesBySiteId(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveEmployeesByWorkingPatternId = async (
  request: RetrieveEmployeesByWorkingPatternIdRequest
): Promise<RetrieveEmployeesByWorkingPatternIdResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await employeesClient.retrieveEmployeesByWorkingPatternId(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveEmployeesByManagerId = async (request: RetrieveEmployeesByManagerIdRequest): Promise<RetrieveEmployeesByManagerIdResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await employeesClient.retrieveEmployeesByManagerId(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveEmployeesByQuery = async (request: RetrieveEmployeesByQueryRequest): Promise<RetrieveEmployeesByQueryResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await employeesClient.retrieveEmployeesByQuery(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveEmployeesBySelection = async (request: RetrieveEmployeesBySelectionRequest): Promise<RetrieveEmployeesBySelectionResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await employeesClient.retrieveEmployeesBySelection(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveEmployeesByJobTitle = async (request: RetrieveEmployeesByJobTitleRequest): Promise<RetrieveEmployeesByJobTitleResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await employeesClient.retrieveEmployeesByJobTitle(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveEmployeesTotalCountBySelected = async (
  request: RetrieveEmployeesTotalCountBySelectedRequest
): Promise<RetrieveEmployeesTotalCountBySelectedResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await employeesClient.retrieveEmployeesTotalCountBySelected(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveEmployeesByIds = async (request: RetrieveEmployeesByIdsRequest): Promise<EmployeeResults> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await employeesClient.retrieveEmployeesByIds(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveEmployeesTotalCount = async (request: Empty): Promise<RetrieveEmployeesTotalCountResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await employeesClient.retrieveEmployeesTotalCount(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveAllEmployees = async (request: Empty): Promise<RetrieveAllEmployeesResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await employeesClient.retrieveAllEmployees(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const resendInvitationEmail = async (request: ResendInvitationEmailRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await employeesClient.resendInvitationEmail(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const deactivateEmployees = async (request: DeactivateEmployeesRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await employeesClient.deactivateEmployees(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};
