import { styled, Tab, tabClasses } from '@mui/material';

export const StyledTaskViewTab = styled(Tab, {
  shouldForwardProp: (prop) => prop !== 'isActive'
})<{
  isActive?: boolean;
}>(({ isActive, theme }) => ({
  backgroundColor: 'transparent',
  border: '2px solid',
  borderColor: isActive ? theme.palette.customTheme.navColorSelected : theme.palette.primary.main,
  padding: '4px 10px 4px 12px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 28,
  borderRadius: '16px',
  color: isActive ? theme.palette.customTheme.navColorSelected : theme.palette.primary.main,
  opacity: 1,
  fontSize: 14,
  fontWeight: 500,
  transition: theme.transitions.create('background-color, border-color, opacity', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.standard
  }),
  '&:hover': {
    opacity: 0.5
  },
  [`&.${tabClasses.selected}`]: {
    backgroundColor: 'transparent',
    borderColor: theme.palette.customTheme.navColorSelected
  }
}));
