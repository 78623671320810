import React, { useCallback } from 'react';
import { SelectionBox, useSelectionContainer } from '@air/react-drag-to-select';
import { alpha } from '@mui/material';
import { useAppDispatch } from 'src/app/hooks';
import { openDrawer, DrawerType, OpenDrawerRequest } from 'src/features/drawer';

interface MouseSelectionProps {
  onSelectionChange: (box: SelectionBox) => void;
  selectedIndexes: Set<string>;
}

export const AssetsSelection: React.FC<MouseSelectionProps> = React.memo(({ onSelectionChange, selectedIndexes }) => {
  const dispatch = useAppDispatch();

  const { DragSelection } = useSelectionContainer({
    eventsElement: document.getElementById('root'),
    onSelectionChange: useCallback(onSelectionChange, [onSelectionChange]),
    onSelectionStart: () => console.log('Selection Started'),
    onSelectionEnd: () => {
      const fileIds = Array.from(selectedIndexes);

      if (fileIds.length > 0) {
        dispatch(openDrawer({ type: DrawerType.AssetsMultipleFiles, request: { fileIds } } as OpenDrawerRequest));
      }
    },
    shouldStartSelecting: (target) => {
      if (target instanceof HTMLElement) {
        let el = target;
        // Traverse up the DOM tree to find a parent with data-disableselect
        while (el.parentElement && !el.dataset.disableselect) {
          el = el.parentElement;
        }
        // If a parent with data-disableselect is found, check its value
        return el.dataset.disableselect !== 'true';
      }
      // If the target doesn't exist, return false
      return false;
    },
    selectionProps: {
      style: {
        zIndex: 9999,
        backgroundColor: alpha('#2FBCC4', 0.4),
        borderColor: '#2FBCC4'
      }
    }
  });

  return <DragSelection />;
});
