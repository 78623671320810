import { styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { forwardRef } from 'react';

export const AssetsTooltip = styled(
  forwardRef(({ className, ...props }: TooltipProps, ref) => <Tooltip ref={ref} {...props} classes={{ popper: className }} />)
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.customTheme.contColor,
    padding: '12px 8px',
    borderRadius: '8px'
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.customTheme.contColor
  }
}));
