import { SyntheticEvent } from 'react';
import { Avatar, Tabs, tabsClasses, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { ALL_TASKS_ID, selectActiveTaskViewId, selectTasksViews, setActiveTaskId, TasksTab } from '@features/tasks';
import { FilterTab } from '@features/people-directory';
import { PeopleFilledIcon, PictureSizeSuffix, RowCenterStack } from '@/shared';
import { selectCurrentEmployeeId, selectCurrentUserInitials, selectCurrentUserProfilePictureUrl } from '@app/user';
import { selectEmployeeProfileAndCoverReadSasToken } from '@/features/employee-profile';
import { pictureUrl } from '@/utils';

export const TasksTabs = () => {
  const dispatch = useAppDispatch();

  const tasksViews = useAppSelector(selectTasksViews);
  const activeTaskViewId = useAppSelector(selectActiveTaskViewId);
  const currentEmployeeId = useAppSelector(selectCurrentEmployeeId);
  const currentUserInitials = useAppSelector(selectCurrentUserInitials);
  const currentUserProfilePicture = useAppSelector(selectCurrentUserProfilePictureUrl);
  const employeeProfileSasToken = useAppSelector(selectEmployeeProfileAndCoverReadSasToken);

  const handleTaskViewTabsChange = (_event: SyntheticEvent, newValue: string) => {
    dispatch(setActiveTaskId(newValue));
  };

  return (
    <Tabs
      value={activeTaskViewId}
      variant="scrollable"
      scrollButtons="auto"
      onChange={handleTaskViewTabsChange}
      sx={{
        minHeight: 43,
        borderRadius: 2,
        maxWidth: '90%',
        justifyContent: 'left',
        [`& .${tabsClasses.flexContainer}`]: {
          gap: '8px'
        }
      }}
    >
      <FilterTab
        value={ALL_TASKS_ID}
        label={
          <RowCenterStack gap={'12px'}>
            <PeopleFilledIcon />
            <Typography sx={{ fontWeight: 700, fontSize: 16 }}> All tasks </Typography>
          </RowCenterStack>
        }
      />
      <FilterTab
        value={currentEmployeeId}
        label={
          <RowCenterStack gap={2}>
            <Avatar
              sx={{
                width: 34,
                height: 34,
                borderRadius: '6px',
                border: '1px solid',
                borderColor: (theme) => theme.palette.common.black
              }}
              src={pictureUrl(currentUserProfilePicture, employeeProfileSasToken, PictureSizeSuffix.sm)}
              alt="Profile image"
            >
              {currentUserInitials}
            </Avatar>
            <Typography sx={{ fontWeight: 700, fontSize: 16 }}>My tasks </Typography>
          </RowCenterStack>
        }
      />
      {tasksViews.map((view) => (
        <TasksTab key={view.id} view={view} />
      ))}
    </Tabs>
  );
};
