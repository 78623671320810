import { styled, Tab, tabClasses } from '@mui/material';

export const FilterTab = styled(Tab, {
  shouldForwardProp: (prop) => prop !== 'isEditable' && prop !== 'isActive'
})<{
  isEditable?: boolean;
  isActive?: boolean;
}>(({ isEditable, isActive, theme }) => ({
  backgroundColor: 'transparent',
  border: 0,
  borderBottom: '1px solid',
  borderColor: 'transparent',
  padding: '0 4px 12px 4px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 32,
  borderRadius: 0,
  color: theme.palette.primary.main,
  opacity: 1,
  fontWeight: 500,
  '&:hover': {
    [`& .${tabClasses.icon}`]: {
      opacity: 1
    }
  },
  [`&.${tabClasses.selected}`]: {
    backgroundColor: 'transparent',
    borderColor: theme.palette.customTheme.navColorSelected
  },
  [`& .${tabClasses.icon}`]: {
    opacity: 0
  }
}));
