import { Empty } from '@bufbuild/protobuf';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
  retrieveInitialAppStateAndUserFailed,
  retrieveInitialAppStateAndUserRequested,
  retrieveInitialAppStateAndUserSucceeded
} from '@features/protected-routes';
import { retrieveInitialSetup } from '@api/organization.api';
import {
  RetrieveErTemplateRequest,
  RetrieveInitialSetupResponse} from '@thrivea/organization-client';
import { setCurrentEmployeeIdAndTenantId } from '@features/shared';
import { setCurrentAuthor } from '@features/homepage';
import * as Sentry from '@sentry/react';
import { retrieveGroupsRequested, retrieveAudiencesByEmployeeIdRequested, setAbacUserId } from '@features/abac';
import { RetrieveAudiencesByEmployeeIdRequest } from '@thrivea/networking-client';
import { RetrieveGroupsRequest } from '@thrivea/auth-client';
import { hasUnreadNotificationsRequested, retrieveLastNotificationDateTimeRequested, retrieveNotificationsRequested } from '@features/notifications';
import { CheckNewNotificationRequest, HasUnreadNotificationsRequest, RetrieveNotificationsRequest } from '@thrivea/notification-client';
import { Code, ConnectError } from '@connectrpc/connect';
import { redirectToErrorPage } from '@app/error';
import { RetrieveLatestDeploymentRequest } from '@thrivea/admin-onboarding-client';
import { retrieveLatestDeploymentRequested } from '@features/snackbar';
import { retrieveEmployeesSucceeded } from '@features/shared';
import {
  loadPaymentMethods,
  retrieveOrganizationLogoSasTokenRequested,
  retrieveOrganizationRequested
} from '@features/admin-settings';
import { retrieveEmployeeProfileAndCoverReadSasTokenRequested } from '@features/employee-profile';
import { RetrievePaymentMethodsResponse } from '@thrivea/payment-client';
import { retrievePaymentMethods } from '@api/payment.api';
import { DEFAULT_ER_TEMPLATE_ID, retrieveErTemplateRequested } from '@features/employee-record-builder';
import { retrieveErbFieldsRequested } from '@features/people-directory';

interface InitialAppStateAndUser {
  initialSetup: RetrieveInitialSetupResponse;
  paymentMethodsResponse: RetrievePaymentMethodsResponse;
}

function* retrieveInitialAppStateAndUserGenerator() {
  let initialData: InitialAppStateAndUser | undefined;

  try {
    initialData = yield all({
      initialSetup: call(retrieveInitialSetup, new Empty()),
      paymentMethodsResponse: call(retrievePaymentMethods, new Empty())
    });
  } catch (error) {
    Sentry.captureException(error);
    if (error instanceof ConnectError && error.code !== Code.InvalidArgument) {
      yield put(redirectToErrorPage(error.code));
      return;
    }

    yield put(retrieveInitialAppStateAndUserFailed());
  }

  const { employeeId, tenantId, adminOnboardingFlowStateResponse, employeesResponse } = initialData!.initialSetup;
  const { paymentMethods } = initialData!.paymentMethodsResponse;

  yield put(retrieveInitialAppStateAndUserSucceeded(adminOnboardingFlowStateResponse!));
  yield put(retrieveEmployeesSucceeded(employeesResponse!));
  yield put(setCurrentEmployeeIdAndTenantId({ employeeId, tenantId }));

  yield put(setCurrentAuthor(employeeId));
  yield put(setAbacUserId(employeeId));
  yield put(loadPaymentMethods(paymentMethods));

  yield put(retrieveLatestDeploymentRequested(new RetrieveLatestDeploymentRequest({ branch: '' })));

  yield put(retrieveAudiencesByEmployeeIdRequested(new RetrieveAudiencesByEmployeeIdRequest({ employeeId })));

  yield put(retrieveLastNotificationDateTimeRequested(new CheckNewNotificationRequest({ employeeId })));
  yield put(retrieveNotificationsRequested(new RetrieveNotificationsRequest({ employeeId, pageNumber: 1, pageSize: 10 })));
  yield put(hasUnreadNotificationsRequested(new HasUnreadNotificationsRequest({ employeeId })));

  yield put(retrieveOrganizationRequested());
  yield put(retrieveOrganizationLogoSasTokenRequested());
  yield put(retrieveEmployeeProfileAndCoverReadSasTokenRequested());

  yield put(retrieveErTemplateRequested(new RetrieveErTemplateRequest({ erTemplateId: DEFAULT_ER_TEMPLATE_ID })));
  yield put(retrieveGroupsRequested(new RetrieveGroupsRequest({ employeeId })));

  // Needed for ErbFieldTable on Employee Record page
  yield put(retrieveErbFieldsRequested());
}

function* retrieveInitialAppStateAndUserRequestedWatcher() {
  yield takeLatest(retrieveInitialAppStateAndUserRequested.type, retrieveInitialAppStateAndUserGenerator);
}

export function* protectedRoutesSagas() {
  yield fork(retrieveInitialAppStateAndUserRequestedWatcher);
}
