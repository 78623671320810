import { ListItemButton, ListItemButtonProps, styled } from '@mui/material';

export const StyledNavListItemButton = styled(ListItemButton)<ListItemButtonProps>(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  height: 40,
  padding: '12px 8px',
  alignItems: 'center',
  borderRadius: '6px',
  backgroundColor: 'transparent',
  '&.Mui-selected': {
    backgroundColor: theme.palette.customTheme.drawerBackground
  },
  transition: theme.transitions.create(['background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.complex
  })
})) as typeof ListItemButton;
