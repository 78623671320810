import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

interface BinIconProps extends SvgIconProps {
  customColor?: string;
  opacity?: number;
}

export const BinIcon: React.FC<BinIconProps> = ({ width = 25, height = 28, customColor = '#251D38', opacity = 1, ...props }) => {
  return (
    <SvgIcon
      viewBox="0 0 25 28"
      sx={{
        width,
        height,
        opacity,
        fill: 'transparent'
      }}
      {...props}
    >
      <path d="M3.73828 7.33984H5.73828H21.7383" stroke={customColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M8.73828 7.33998V5.04694C8.73828 4.43879 8.949 3.85555 9.32407 3.42552C9.69914 2.99549 10.2078 2.75391 10.7383 2.75391H14.7383C15.2687 2.75391 15.7774 2.99549 16.1525 3.42552C16.5276 3.85555 16.7383 4.43879 16.7383 5.04694V7.33998M19.7383 7.33998V23.3912C19.7383 23.9994 19.5276 24.5826 19.1525 25.0127C18.7774 25.4427 18.2687 25.6843 17.7383 25.6843H7.73828C7.20785 25.6843 6.69914 25.4427 6.32407 25.0127C5.94899 24.5826 5.73828 23.9994 5.73828 23.3912V7.33998H19.7383Z"
        stroke={customColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M14.7383 13.0728V19.9519" stroke={customColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.7383 13.0728V19.9519" stroke={customColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </SvgIcon>
  );
};
