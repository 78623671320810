import { Chip, chipClasses, ChipProps, styled } from '@mui/material';

export const RecommendedPlanChip = styled(Chip)<ChipProps>(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  padding: '4px 12px',
  borderRadius: '8px',
  fontSize: 14,
  fontWeight: 700,
  height: 24,
  position: 'absolute',
  top: -15,
  left: 10,
  zIndex: 2,
  [`& .${chipClasses.label}`]: {
    padding: 0
  }
}));
