import { PayloadAction } from '@reduxjs/toolkit';
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import {
  retrieveOrganizationRequested,
  retrieveOrganizationSucceeded,
  retrieveOrganizationFailed,
  updateOrganizationRequested,
  updateOrganizationSucceeded,
  updateOrganizationFailed,
  selectOrganizationId,
  selectOrganizationLogoFile,
  retrieveSiteListItemsRequested,
  retrieveSiteListItemsSucceeded,
  retrieveSiteListItemsFailed,
  retrieveSiteByIdSucceeded,
  retrieveSiteByIdRequested,
  duplicateSiteSucceeded,
  duplicateSiteFailed,
  duplicateSiteRequested,
  upsertSiteNameAndLocationSucceeded,
  upsertSiteNameAndLocationFailed,
  upsertSiteNameAndLocationRequested,
  upsertSiteLocalizationSucceeded,
  upsertSiteLocalizationFailed,
  upsertSiteLocalizationRequested,
  updateSiteCalendarSucceeded,
  updateSiteCalendarFailed,
  updateSiteCalendarRequested,
  upsertSiteWorkingPatternSucceeded,
  upsertSiteWorkingPatternFailed,
  upsertSiteWorkingPatternRequested,
  deleteSiteByIdSucceeded,
  deleteSiteByIdFailed,
  deleteSiteByIdRequested,
  retrieveEmployeesBySiteIdSucceeded,
  retrieveEmployeesBySiteIdFailed,
  retrieveEmployeeBasicInfoSucceeded,
  retrieveEmployeeBasicInfoFailed,
  retrieveEmployeesBySiteIdRequested,
  retrieveEmployeeBasicInfoRequested,
  selectSiteCoverImageFile,
  retrieveCalendarsSucceeded,
  retrieveCalendarsFailed,
  retrieveCalendarsRequested,
  retrieveOrganizationLogoSasTokenSucceeded,
  retrieveOrganizationLogoSasTokenFailed,
  retrieveOrganizationLogoSasTokenRequested,
  retrieveSiteReadSasTokenSucceeded,
  retrieveSiteReadSasTokenRequested,
  retrieveWorkingPatternsSucceeded,
  retrieveWorkingPatternsFailed,
  retrieveWorkingPatternsRequested,
  selectSiteName,
  selectSiteListItems,
  retrieveSiteByIdFailed,
  retrieveEmployeesByWorkingPatternIdSucceeded,
  retrieveEmployeesByWorkingPatternIdFailed,
  retrieveEmployeesByWorkingPatternIdRequested,
  retrieveWorkingPatternItemsSucceeded,
  retrieveWorkingPatternItemsFailed,
  retrieveWorkingPatternItemsRequested,
  retrieveSitesByWorkingPatternIdSucceeded,
  retrieveSitesByWorkingPatternIdFailed,
  retrieveSitesByWorkingPatternIdRequested,
  createWorkingPatternSucceeded,
  createWorkingPatternFailed,
  createWorkingPatternRequested,
  selectWorkingPatternListItems,
  deleteWorkingPatternByIdRequested,
  deleteWorkingPatternByIdSucceeded,
  deleteWorkingPatternByIdFailed,
  retrieveWorkingPatternByIdSucceeded,
  retrieveWorkingPatternByIdFailed,
  retrieveWorkingPatternByIdRequested,
  updateWorkingPatternByIdSucceeded,
  updateWorkingPatternByIdFailed,
  updateWorkingPatternByIdRequested,
  retrieveEmployeesByManagerIdSucceeded,
  retrieveEmployeesByManagerIdFailed,
  retrieveEmployeesByManagerIdRequested,
  retrieveSiteListItemByIdSucceeded,
  retrieveSiteListItemByIdFailed,
  retrieveSiteListItemByIdRequested,
  retrieveEmployeesByJobTitleSucceeded,
  retrieveEmployeesByJobTitleFailed,
  retrieveEmployeesByJobTitleRequested,
  importEmployeesRequested,
  importEmployeesFailed,
  importEmployeesSucceeded
} from '@features/admin-settings';
import { Empty } from '@bufbuild/protobuf';
import { importEmployees, retrieveCalendars, retrieveOrganization, updateOrganization } from '@api/settings.api';
import {
  DeleteSiteByIdRequest,
  DuplicateSiteRequest,
  DuplicateSiteResponse,
  RetrieveCalendarsResponse,
  RetrieveEmployeeBasicInfoRequest,
  RetrieveEmployeeBasicInfoResponse,
  RetrieveEmployeesBySiteIdRequest,
  RetrieveEmployeesBySiteIdResponse,
  RetrieveEmployeesByWorkingPatternIdRequest,
  RetrieveEmployeesByWorkingPatternIdResponse,
  RetrieveIndustrySubCategoriesRequest,
  RetrieveOrganizationResponse,
  RetrieveSiteByIdRequest,
  RetrieveSiteByIdResponse,
  RetrieveSitesByWorkingPatternIdRequest,
  RetrieveSitesByWorkingPatternIdResponse,
  RetrieveSiteListRequest,
  RetrieveSiteListResponse,
  RetrieveWorkingPatternListItemsRequest,
  RetrieveWorkingPatternsResponse,
  UpdateOrganizationRequest,
  UpsertSiteNameAndLocationRequest,
  UpsertSiteNameAndLocationResponse,
  CreateWorkingPatternRequest,
  CreateWorkingPatternResponse,
  DeleteWorkingPatternByIdRequest,
  UpdateSiteLocalizationRequest,
  UpdateSiteLocalizationResponse,
  UpdateSiteCalendarRequest,
  UpdateSiteCalendarResponse,
  UpdateSiteWorkingPatternRequest,
  UpdateSiteWorkingPatternResponse,
  RetrieveWorkingPatternByIdRequest,
  UpdateWorkingPatternRequest,
  UpdateWorkingPatternResponse,
  RetrieveEmployeesByManagerIdRequest,
  RetrieveEmployeesByManagerIdResponse,
  RetrieveSiteListItemByIdRequest,
  RetrieveSiteListItemByIdResponse,
  RetrieveEmployeesByJobTitleRequest,
  RetrieveEmployeesByJobTitleResponse,
  ImportEmployeesRequest,
  ImportEmployeesResponse
} from '@thrivea/organization-client';
import { retrieveIndustrySubcategoriesRequested, retrieveSiteReadSasTokenFailed } from '@features/admin-onboarding';
import * as Sentry from '@sentry/react';
import { RetrieveOrganizationLogoCreateSasUriRequest, RetrieveSiteCreateSasUriRequest, SasToken, SasUri } from '@thrivea/auth-client';
import { showSuccess, showWarning } from '@features/snackbar/snackbar.slice';
import { t } from 'i18next';
import { MultiSizeImageBlobs } from '@/shared';
import {
  createWorkingPattern,
  deleteWorkingPatternById,
  retreiveWorkingPatternById,
  retrieveWorkingPatternListItems,
  updateWorkingPatternById
} from '@api/working-pattern.api';
import { UploadFileMultipleSizes, uploadFileMultipleSizes } from '@api/blob-storage.api';
import {
  deleteSiteById,
  duplicateSite,
  retrieveSiteById,
  retrieveSiteByWorkingPatternId,
  retrieveSiteList,
  upsertSiteCalendar,
  upsertSiteLocalization,
  upsertSiteNameAndLocation,
  updateSiteWorkingPattern,
  retrieveSiteListItemById
} from '@api/sites.api';
import { retrieveWorkingPatterns } from '@api/organization.api';
import {
  retrieveEmployeeBasicInfo,
  retrieveEmployeesByJobTitle,
  retrieveEmployeesByManagerId,
  retrieveEmployeesBySiteId,
  retrieveEmployeesByWorkingPatternId
} from '@api/employees.api';
import {
  retrieveOrganizationLogoCreateSasUri,
  retrieveOrganizationLogoReadSasToken,
  retrieveSiteCreateSasUri,
  retrieveSiteReadSasToken
} from '@api/shared-access-signature.api';

function* retrieveOrganizationGenerator() {
  try {
    const response: RetrieveOrganizationResponse = yield call(retrieveOrganization, new Empty());
    yield put(retrieveOrganizationSucceeded(response));
    yield put(retrieveIndustrySubcategoriesRequested({ industryCategoryId: response.industryCategoryId } as RetrieveIndustrySubCategoriesRequest));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveOrganizationFailed());
  }
}

function* updateOrganizationGenerator(action: PayloadAction<UpdateOrganizationRequest>) {
  const organizationId: string = yield select(selectOrganizationId);
  const organizationLogoFile: MultiSizeImageBlobs = yield select(selectOrganizationLogoFile);
  let logoUrl = '';

  try {
    if (organizationLogoFile) {
      const sasUri: SasUri = yield call(
        retrieveOrganizationLogoCreateSasUri,
        new RetrieveOrganizationLogoCreateSasUriRequest({
          organizationId
        })
      );
      const request = {
        sasUri: sasUri.uri,
        multiSizeImage: organizationLogoFile
      } as UploadFileMultipleSizes;
      logoUrl = yield call(uploadFileMultipleSizes, request);
    } else {
      logoUrl = action.payload.logoUrl;
    }

    yield call(updateOrganization, {
      ...action.payload,
      logoUrl
    } as UpdateOrganizationRequest);
    yield put(updateOrganizationSucceeded(action.payload));
    // TODO @Stevo - put success message
  } catch (error) {
    Sentry.captureException(error);
    yield put(updateOrganizationFailed());
  }
}

function* retrieveSiteListItemsGenerator(action: PayloadAction<RetrieveSiteListRequest>) {
  try {
    const response: RetrieveSiteListResponse = yield call(retrieveSiteList, action.payload);
    yield put(retrieveSiteListItemsSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveSiteListItemsFailed());
  }
}

function* retrieveSiteListItemGenerator(action: PayloadAction<RetrieveSiteByIdRequest>) {
  try {
    const response: RetrieveSiteByIdResponse = yield call(retrieveSiteById, action.payload);
    yield put(retrieveSiteByIdSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveSiteByIdFailed());
  }
}

function* duplicateSiteGenerator(action: PayloadAction<DuplicateSiteRequest>) {
  try {
    const response: DuplicateSiteResponse = yield call(duplicateSite, action.payload);
    yield put(duplicateSiteSucceeded(response.site!));
    const siteList = yield select(selectSiteListItems);
    const siteName = siteList[action.payload.siteId].name;
    const successMessage = t('site_duplicated', { ns: 'settings_sites', value: siteName });
    yield put(showSuccess(successMessage));
  } catch (error) {
    Sentry.captureException(error);
    yield put(duplicateSiteFailed());
    yield put(showWarning(error));
  }
}

function* deleteSiteByIdGenerator(action: PayloadAction<DeleteSiteByIdRequest>) {
  try {
    const { siteId } = action.payload;
    const siteList = yield select(selectSiteListItems);
    const siteName = siteList[siteId].name;
    const successMessage = t('site_deleted', { ns: 'settings_sites', value: siteName });
    yield call(deleteSiteById, action.payload);
    yield put(deleteSiteByIdSucceeded(siteId));
    yield put(showSuccess(successMessage));
  } catch (error) {
    Sentry.captureException(error);
    yield put(showWarning(error));
    yield put(deleteSiteByIdFailed());
  }
}

function* retrieveAdminSettingsSiteReadSasTokenGenerator() {
  try {
    const sasToken: SasToken = yield call(retrieveSiteReadSasToken, new Empty());
    yield put(retrieveSiteReadSasTokenSucceeded(sasToken));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveSiteReadSasTokenFailed());
  }
}

function* upsertSiteNameAndLocationRequestedGenerator(action: PayloadAction<UpsertSiteNameAndLocationRequest>) {
  const siteImageFile: MultiSizeImageBlobs = yield select(selectSiteCoverImageFile);
  let coverImageUrl = '';

  try {
    const successMessage = t('site_updated', { ns: 'common', value: action.payload.siteName });
    if (siteImageFile) {
      const sasUri: SasUri = yield call(
        retrieveSiteCreateSasUri,
        new RetrieveSiteCreateSasUriRequest({
          siteId: action.payload.siteId
        })
      );
      const request = {
        sasUri: sasUri.uri,
        multiSizeImage: siteImageFile
      } as UploadFileMultipleSizes;

      coverImageUrl = yield call(uploadFileMultipleSizes, request);
    } else {
      coverImageUrl = action.payload.coverImageUrl;
    }
    const response: UpsertSiteNameAndLocationResponse = yield call(upsertSiteNameAndLocation, {
      ...action.payload,
      coverImageUrl
    } as UpsertSiteNameAndLocationRequest);
    yield put(showSuccess(successMessage));
    yield put(upsertSiteNameAndLocationSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(upsertSiteNameAndLocationFailed());
    yield put(showWarning(error));
  }
}

function* upsertSiteLocalizationGenerator(action: PayloadAction<UpdateSiteLocalizationRequest>) {
  try {
    const response: UpdateSiteLocalizationResponse = yield call(upsertSiteLocalization, action.payload);
    const siteName = yield select(selectSiteName);
    const successMessage = t('site_updated', { ns: 'common', value: siteName });

    yield put(upsertSiteLocalizationSucceeded(response));
    yield put(showSuccess(successMessage));
  } catch (error) {
    Sentry.captureException(error);
    yield put(upsertSiteLocalizationFailed());
    yield put(showWarning(error));
  }
}

function* upsertSiteCalendarGenerator(action: PayloadAction<UpdateSiteCalendarRequest>) {
  try {
    const response: UpdateSiteCalendarResponse = yield call(upsertSiteCalendar, action.payload);
    const siteName = yield select(selectSiteName);
    const successMessage = t('site_updated', { ns: 'common', value: siteName });

    yield put(updateSiteCalendarSucceeded(response));
    yield put(showSuccess(successMessage));
  } catch (error) {
    Sentry.captureException(error);
    yield put(updateSiteCalendarFailed());
    yield put(showWarning(error));
  }
}

function* upsertSiteWorkingPatternGenerator(action: PayloadAction<UpdateSiteWorkingPatternRequest>) {
  try {
    const response: UpdateSiteWorkingPatternResponse = yield call(updateSiteWorkingPattern, action.payload);
    const siteName = yield select(selectSiteName);
    const successMessage = t('site_updated', { ns: 'common', value: siteName });

    yield put(upsertSiteWorkingPatternSucceeded(response));
    yield put(showSuccess(successMessage));
  } catch (error) {
    Sentry.captureException(error);
    yield put(upsertSiteWorkingPatternFailed());
    yield put(showWarning(error));
  }
}

function* retrieveEmployeesBySiteIdGenerator(action: PayloadAction<RetrieveEmployeesBySiteIdRequest>) {
  try {
    const response: RetrieveEmployeesBySiteIdResponse = yield call(retrieveEmployeesBySiteId, action.payload);
    yield put(retrieveEmployeesBySiteIdSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveEmployeesBySiteIdFailed());
  }
}

function* retrieveEmployeeBasicInfoGenerator(action: PayloadAction<RetrieveEmployeeBasicInfoRequest>) {
  try {
    const response: RetrieveEmployeeBasicInfoResponse = yield call(retrieveEmployeeBasicInfo, action.payload);
    yield put(retrieveEmployeeBasicInfoSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveEmployeeBasicInfoFailed());
  }
}

function* retrieveCalendarsGenerator(action: PayloadAction<Empty>) {
  try {
    const { calendars }: RetrieveCalendarsResponse = yield call(retrieveCalendars, action.payload);
    yield put(retrieveCalendarsSucceeded(calendars));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveCalendarsFailed());
  }
}

function* retrieveOrganizationLogoReadSasTokenGenerator() {
  try {
    const sasToken: SasToken = yield call(retrieveOrganizationLogoReadSasToken, new Empty());
    yield put(retrieveOrganizationLogoSasTokenSucceeded(sasToken));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveOrganizationLogoSasTokenFailed());
  }
}

function* retrieveWorkingPatternsGenerator() {
  try {
    const workingPatterns: RetrieveWorkingPatternsResponse = yield call(retrieveWorkingPatterns, new Empty());
    yield put(retrieveWorkingPatternsSucceeded(workingPatterns));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveWorkingPatternsFailed());
  }
}

function* retrieveWorkingPatternListItemGenerator(action: PayloadAction<RetrieveWorkingPatternListItemsRequest>) {
  try {
    const response = yield call(retrieveWorkingPatternListItems, action.payload);
    yield put(retrieveWorkingPatternItemsSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveWorkingPatternItemsFailed());
  }
}

function* retrieveSitesByWorkingPatternIdGenerator(action: PayloadAction<RetrieveSitesByWorkingPatternIdRequest>) {
  try {
    const response: RetrieveSitesByWorkingPatternIdResponse = yield call(retrieveSiteByWorkingPatternId, action.payload);
    yield put(retrieveSitesByWorkingPatternIdSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveSitesByWorkingPatternIdFailed());
  }
}

function* retrieveEmployeesByWorkingPatternIdGenerator(action: PayloadAction<RetrieveEmployeesByWorkingPatternIdRequest>) {
  try {
    const response: RetrieveEmployeesByWorkingPatternIdResponse = yield call(retrieveEmployeesByWorkingPatternId, action.payload);
    yield put(retrieveEmployeesByWorkingPatternIdSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveEmployeesByWorkingPatternIdFailed());
  }
}

function* createWorkingPatternGenerator(action: PayloadAction<CreateWorkingPatternRequest>) {
  try {
    const { workingPatternItem }: CreateWorkingPatternResponse = yield call(createWorkingPattern, action.payload);
    yield put(createWorkingPatternSucceeded(workingPatternItem!));
    const successMessage = t('wp_created', { ns: 'settings_sites', value: workingPatternItem!.name });
    yield put(showSuccess(successMessage));
  } catch (error) {
    Sentry.captureException(error);
    yield put(createWorkingPatternFailed());
  }
}

function* deleteWorkingPatternByIdGenerator(action: PayloadAction<DeleteWorkingPatternByIdRequest>) {
  try {
    const { workingPatternId } = action.payload;
    const wpList = yield select(selectWorkingPatternListItems);
    const wpName = wpList[workingPatternId].name;
    const successMessage = t('wp_deleted', { ns: 'settings_sites', value: wpName });
    yield call(deleteWorkingPatternById, action.payload);
    yield put(deleteWorkingPatternByIdSucceeded(workingPatternId));
    yield put(showSuccess(successMessage));
  } catch (error) {
    Sentry.captureException(error);
    yield put(showWarning(error));
    yield put(deleteWorkingPatternByIdFailed());
  }
}

function* retrieveWorkingPatternByIdGenerator(action: PayloadAction<RetrieveWorkingPatternByIdRequest>) {
  try {
    const { workingPattern } = yield call(retreiveWorkingPatternById, action.payload);
    yield put(retrieveWorkingPatternByIdSucceeded(workingPattern));
  } catch (error) {
    Sentry.captureException(error);
    yield put(showWarning(error));
    yield put(retrieveWorkingPatternByIdFailed());
  }
}

function* updateWorkingPatternByIdGenerator(action: PayloadAction<UpdateWorkingPatternRequest>) {
  try {
    const { workingPatternItem }: UpdateWorkingPatternResponse = yield call(updateWorkingPatternById, action.payload);
    const successMessage = t('wp_updated', { ns: 'settings_working_patterns', name: workingPatternItem?.name });
    yield put(updateWorkingPatternByIdSucceeded(workingPatternItem!));
    yield put(showSuccess(successMessage));
  } catch (error) {
    Sentry.captureException(error);
    yield put(showWarning(error));
    yield put(updateWorkingPatternByIdFailed());
  }
}

function* retrieveEmployeesByManagerIdGenerator(action: PayloadAction<RetrieveEmployeesByManagerIdRequest>) {
  try {
    const response: RetrieveEmployeesByManagerIdResponse = yield call(retrieveEmployeesByManagerId, action.payload);
    yield put(retrieveEmployeesByManagerIdSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveEmployeesByManagerIdFailed());
  }
}

function* retrieveSiteListItemByIdGenerator(action: PayloadAction<RetrieveSiteListItemByIdRequest>) {
  try {
    const response: RetrieveSiteListItemByIdResponse = yield call(retrieveSiteListItemById, action.payload);
    yield put(retrieveSiteListItemByIdSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveSiteListItemByIdFailed());
  }
}

function* retrieveEmployeesByJobTitleGenerator(action: PayloadAction<RetrieveEmployeesByJobTitleRequest>) {
  try {
    const response: RetrieveEmployeesByJobTitleResponse = yield call(retrieveEmployeesByJobTitle, action.payload);
    yield put(retrieveEmployeesByJobTitleSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveEmployeesByJobTitleFailed());
  }
}

function* importEmployeesGenerator(action: PayloadAction<ImportEmployeesRequest>) {
  try {
    const response: ImportEmployeesResponse = yield call(importEmployees, action.payload);
    const successMessage = t('employee_with_count', { ns: 'settings_info', count: response.importedEmployeesCount });
    yield put(importEmployeesSucceeded());
    yield put(showSuccess(successMessage));
  } catch (error) {
    Sentry.captureException(error);
    yield put(importEmployeesFailed());
  }
}

function* retrieveOrganizationRequestedWatcher() {
  yield takeLatest(retrieveOrganizationRequested.type, retrieveOrganizationGenerator);
}

function* updateOrganizationRequestedWatcher() {
  yield takeLatest(updateOrganizationRequested.type, updateOrganizationGenerator);
}

function* retrieveCalendarsRequestedWatcher() {
  yield takeLatest(retrieveCalendarsRequested.type, retrieveCalendarsGenerator);
}

function* retrieveSiteListItemsRequestedWatcher() {
  yield takeLatest(retrieveSiteListItemsRequested.type, retrieveSiteListItemsGenerator);
}

function* retrieveSiteListItemRequestedWatcher() {
  yield takeLatest(retrieveSiteByIdRequested.type, retrieveSiteListItemGenerator);
}

function* duplicateSiteRequestedWatcher() {
  yield takeLatest(duplicateSiteRequested.type, duplicateSiteGenerator);
}

function* deleteSiteByIdRequestedWatcher() {
  yield takeLatest(deleteSiteByIdRequested.type, deleteSiteByIdGenerator);
}

function* retrieveSiteAdminSettingsReadSasTokenRequestedWatcher() {
  yield takeLatest(retrieveSiteReadSasTokenRequested.type, retrieveAdminSettingsSiteReadSasTokenGenerator);
}

function* upsertSiteNameAndLocationRequestedWatcher() {
  yield takeLatest(upsertSiteNameAndLocationRequested.type, upsertSiteNameAndLocationRequestedGenerator);
}

function* upsertSiteLocalizationRequestedWatcher() {
  yield takeLatest(upsertSiteLocalizationRequested.type, upsertSiteLocalizationGenerator);
}

function* upsertSiteCalendarRequestedWatcher() {
  yield takeLatest(updateSiteCalendarRequested.type, upsertSiteCalendarGenerator);
}

function* upsertSiteWorkingPatternRequestedWatcher() {
  yield takeLatest(upsertSiteWorkingPatternRequested.type, upsertSiteWorkingPatternGenerator);
}

function* retrieveEmployeesBySiteIdRequestedWatcher() {
  yield takeLatest(retrieveEmployeesBySiteIdRequested.type, retrieveEmployeesBySiteIdGenerator);
}

function* retrieveEmployeeBasicInfoRequestedWatcher() {
  yield takeLatest(retrieveEmployeeBasicInfoRequested.type, retrieveEmployeeBasicInfoGenerator);
}

function* retrieveOrganizationLogoReadSasTokenRequestedWatcher() {
  yield takeLatest(retrieveOrganizationLogoSasTokenRequested.type, retrieveOrganizationLogoReadSasTokenGenerator);
}

function* retrieveAdminSettingsWorkingPatternsWatcher() {
  yield takeLatest(retrieveWorkingPatternsRequested.type, retrieveWorkingPatternsGenerator);
}

function* retrieveWorkingPatternListItemsRequestedWatcher() {
  yield takeLatest(retrieveWorkingPatternItemsRequested.type, retrieveWorkingPatternListItemGenerator);
}

function* retrieveSitesByWorkingPatternRequestedWatcher() {
  yield takeLatest(retrieveSitesByWorkingPatternIdRequested.type, retrieveSitesByWorkingPatternIdGenerator);
}

function* retrieveEmployeesByWorkingPatternIdRequestedWatcher() {
  yield takeLatest(retrieveEmployeesByWorkingPatternIdRequested.type, retrieveEmployeesByWorkingPatternIdGenerator);
}

function* createWorkingPatternRequestedWatcher() {
  yield takeLatest(createWorkingPatternRequested.type, createWorkingPatternGenerator);
}

function* deleteWorkingPatternByIdRequestedWatcher() {
  yield takeLatest(deleteWorkingPatternByIdRequested.type, deleteWorkingPatternByIdGenerator);
}

function* retrieveWorkingPatternByIdRequestedWatcher() {
  yield takeLatest(retrieveWorkingPatternByIdRequested.type, retrieveWorkingPatternByIdGenerator);
}

function* updateWorkingPatternByIdRequestedWatcher() {
  yield takeLatest(updateWorkingPatternByIdRequested.type, updateWorkingPatternByIdGenerator);
}

function* retrieveEmployeesByManagerIdWatcher() {
  yield takeLatest(retrieveEmployeesByManagerIdRequested.type, retrieveEmployeesByManagerIdGenerator);
}

function* retrieveSiteListItemByIdWatcher() {
  yield takeLatest(retrieveSiteListItemByIdRequested.type, retrieveSiteListItemByIdGenerator);
}

function* retrieveEmployeesByJobTitleWatcher() {
  yield takeLatest(retrieveEmployeesByJobTitleRequested.type, retrieveEmployeesByJobTitleGenerator);
}

function* importEmployeesRequestedWatcher() {
  yield takeLatest(importEmployeesRequested.type, importEmployeesGenerator);
}

export function* adminSettingsSagas() {
  yield all([
    fork(updateOrganizationRequestedWatcher),
    fork(retrieveOrganizationRequestedWatcher),
    fork(retrieveCalendarsRequestedWatcher),
    fork(retrieveSiteListItemsRequestedWatcher),
    fork(retrieveSiteListItemRequestedWatcher),
    fork(duplicateSiteRequestedWatcher),
    fork(deleteSiteByIdRequestedWatcher),
    fork(upsertSiteNameAndLocationRequestedWatcher),
    fork(upsertSiteLocalizationRequestedWatcher),
    fork(upsertSiteCalendarRequestedWatcher),
    fork(upsertSiteWorkingPatternRequestedWatcher),
    fork(retrieveEmployeesBySiteIdRequestedWatcher),
    fork(retrieveEmployeeBasicInfoRequestedWatcher),
    fork(retrieveOrganizationLogoReadSasTokenRequestedWatcher),
    fork(retrieveSiteAdminSettingsReadSasTokenRequestedWatcher),
    fork(retrieveAdminSettingsWorkingPatternsWatcher),
    fork(retrieveWorkingPatternListItemsRequestedWatcher),
    fork(retrieveSitesByWorkingPatternRequestedWatcher),
    fork(retrieveEmployeesByWorkingPatternIdRequestedWatcher),
    fork(createWorkingPatternRequestedWatcher),
    fork(deleteWorkingPatternByIdRequestedWatcher),
    fork(retrieveWorkingPatternByIdRequestedWatcher),
    fork(updateWorkingPatternByIdRequestedWatcher),
    fork(retrieveEmployeesByManagerIdWatcher),
    fork(retrieveSiteListItemByIdWatcher),
    fork(retrieveEmployeesByJobTitleWatcher),
    fork(importEmployeesRequestedWatcher)
  ]);
}
