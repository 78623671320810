import { create } from '@bufbuild/protobuf';

import { put } from 'redux-saga/effects';
import {
  retrieveSiteByIdRequested,
  retrieveEmployeesBySiteIdRequested,
  retrieveEmployeeBasicInfoRequested,
  retrieveEmployeesByWorkingPatternIdRequested,
  retrieveSitesByWorkingPatternIdRequested,
  setCurrentWorkingPatternDrawer,
  retrieveEmployeesByJobTitleRequested,
  retrieveAudienceByIdRequested,
  setCurrentAudienceRole,
  RoleListItem
} from '../admin-settings';
import { retrieveFilesAccessRequested, retrieveFilesInfoRequested, selectMultipleFiles } from '../assets';
import { retrievePostAnalyticsRequested } from '../homepage';
import { retrieveNewNotificationsRequested } from '../notifications';
import { retrieveErbFieldsByCategoryIdRequested, setActiveSectionId } from '../employee-record-builder';
import { TasksEditTaskDrawerRequest } from './drawer.model';
import { openTasksEditTaskDrawer } from './drawer.slice';
import { RetrievePostAnalyticsRequestSchema } from '@thrivea/networking-analytics-client';
import { RetrieveAudienceByIdRequestSchema } from '@thrivea/networking-client';
import { RetrieveNotificationsRequestSchema } from '@thrivea/notification-client';
import {
  RetrieveSiteByIdRequestSchema,
  RetrieveEmployeesBySiteIdRequestSchema,
  RetrieveEmployeesByJobTitleRequestSchema,
  RetrieveErbFieldsByCategoryIdRequestSchema,
  RetrieveEmployeeBasicInfoRequestSchema,
  RetrieveEmployeesByWorkingPatternIdRequestSchema,
  RetrieveSitesByWorkingPatternIdRequestSchema,
  RetrieveFilesInfoRequestSchema,
  RetrieveFilesAccessRequestSchema
} from '@thrivea/organization-client';

export function* openSiteDrawer(siteId: string) {
  yield put(
    retrieveSiteByIdRequested(
      create(RetrieveSiteByIdRequestSchema, {
        id: siteId
      })
    )
  );

  yield put(
    retrieveEmployeesBySiteIdRequested(
      create(RetrieveEmployeesBySiteIdRequestSchema, {
        siteId,
        pageNumber: 1,
        pageSize: 20
      })
    )
  );
}

export function* openJobTitle(jobTitle: string) {
  // TODO: move to dedicated generator functions to declutter this function
  yield put(
    retrieveEmployeesByJobTitleRequested(
      create(RetrieveEmployeesByJobTitleRequestSchema, {
        jobTitle,
        pageNumber: 1,
        pageSize: 10
      })
    )
  );
}

export function* openAudience(audienceId: string) {
  yield put(retrieveAudienceByIdRequested(create(RetrieveAudienceByIdRequestSchema, { audienceId })));
}

export function* openAudienceRole(role: RoleListItem) {
  yield put(setCurrentAudienceRole(role));
}

export function* openPostAnalytics(postId: string) {
  yield put(
    retrievePostAnalyticsRequested(
      create(RetrievePostAnalyticsRequestSchema, {
        postId
      })
    )
  );
}

export function* openNotifications(hasUnreadNotifications: boolean, notificationsEmployeeId: string) {
  if (!hasUnreadNotifications) return;

  yield put(
    retrieveNewNotificationsRequested(
      create(RetrieveNotificationsRequestSchema, {
        employeeId: notificationsEmployeeId,
        pageNumber: 1,
        pageSize: 10
      })
    )
  );
}

export function* openEmployeeRecord(erbCategoryId: string, erTemplateSectionId: string) {
  yield put(
    retrieveErbFieldsByCategoryIdRequested({
      request: create(RetrieveErbFieldsByCategoryIdRequestSchema, { erbCategoryId }),
      sectionId: erTemplateSectionId
    })
  );

  yield put(setActiveSectionId(erTemplateSectionId));
}

export function* openSingleEmployeeDrawer(employeeId: string) {
  yield put(
    retrieveEmployeeBasicInfoRequested(
      create(RetrieveEmployeeBasicInfoRequestSchema, {
        employeeId
      })
    )
  );
}

export function* openWorkingPatternDrawer(workingPatternId: string, workingPatternName: string) {
  yield put(
    retrieveEmployeesByWorkingPatternIdRequested(
      create(RetrieveEmployeesByWorkingPatternIdRequestSchema, {
        workingPatternId,
        pageNumber: 1,
        pageSize: 10
      })
    )
  );

  yield put(
    retrieveSitesByWorkingPatternIdRequested(
      create(RetrieveSitesByWorkingPatternIdRequestSchema, {
        workingPatternId,
        pageNumber: 1,
        pageSize: 10
      })
    )
  );

  yield put(
    setCurrentWorkingPatternDrawer({
      id: workingPatternId,
      name: workingPatternName
    })
  );
}

export function* openAssetsDrawer(fileId: string) {
  yield put(selectMultipleFiles([fileId]));

  yield put(
    retrieveFilesInfoRequested(
      create(RetrieveFilesInfoRequestSchema, {
        selectedFileIds: [fileId]
      })
    )
  );
}

export function* openAssetsMultipleFilesDrawer(fileIds: string[]) {
  yield put(
    retrieveFilesInfoRequested(
      create(RetrieveFilesInfoRequestSchema, {
        selectedFileIds: fileIds
      })
    )
  );
}

export function* openAssetsShareFile(fileId: string) {
  yield put(
    retrieveFilesAccessRequested(
      create(RetrieveFilesAccessRequestSchema, {
        selectedFileIds: [fileId]
      })
    )
  );
}

export function* handleOpenTasksEditTaskDrawer(action: { payload: TasksEditTaskDrawerRequest }) {
  const { activeTab } = action.payload;
  yield put(openTasksEditTaskDrawer({ activeTab }));
}
