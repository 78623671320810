import React from 'react';
import { Box, Stack, Drawer, List, ListItem, ListItemButton, Toolbar, useTheme, useMediaQuery, SvgIcon } from '@mui/material';
import { DocsIcon, HomeIcon, OperationsIcon, PeopleIcon, PictureSizeSuffix, SettingsIcon, StyledAvatar } from '@/shared';
import { Link } from 'react-router';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { selectOrganizationLogoFile, selectOrganizationLogoImageUrl, selectOrganizationLogoReadSasToken } from '@features/admin-settings';
import { NavListItem, NavSettingsListItem, openNavigationDrawer } from '@features/navigation';
import { AllowedTo } from 'react-abac';
import { GroupPermissions } from '@features/abac';
import { isSvgUrl, pictureUrl, scrollbarStyles } from 'src/utils';
import { selectCurrentEmployeeId } from '@features/shared';

interface NavigationProps {
  isOpen: boolean;
}

export const Navigation: React.FC<NavigationProps> = ({ isOpen }) => {
  const dispatch = useAppDispatch();
  const logoUrl = useAppSelector(selectOrganizationLogoImageUrl);
  const logoFile = useAppSelector(selectOrganizationLogoFile);
  const organizationSasToken = useAppSelector(selectOrganizationLogoReadSasToken);
  const currentEmployeeId = useAppSelector(selectCurrentEmployeeId);

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.up('md'));
  const isBelowLgHeight = useMediaQuery('(max-height: 900px)');
  const breakingHeight = useMediaQuery('(max-height: 740px)');

  const getLogo = () => {
    if (logoFile) {
      return URL.createObjectURL(logoFile.sm);
    } else if (logoUrl) {
      if (isSvgUrl(logoUrl)) {
        return new URL(organizationSasToken, decodeURIComponent(logoUrl)).toString();
      }
      return pictureUrl(logoUrl, organizationSasToken, PictureSizeSuffix.lg);
    } else {
      return '/images/logo-placeholder.png';
    }
  };

  const navContent = (
    <Stack
      component={'nav'}
      gap={'12px'}
      sx={{
        width: '100%',
        height: '100%',
        alignItems: 'center',
        padding: '32px 16px 24px 16px',
        justifyContent: 'space-between',
        backgroundColor: (theme) => theme.palette.customTheme.navBgr,
        borderRight: '1px solid',
        borderColor: (theme) => theme.palette.customTheme.borderMaster
      }}
    >
      <Stack gap={'26px'}>
        <Toolbar
          sx={{
            width: '100%',
            minHeight: {
              xs: 32
            },
            padding: {
              xs: 0
            },
            justifyContent: 'center'
          }}
        >
          {isOpen && (
            <Box
              component="img"
              src="/images/nav-logo-expanded.png"
              alt="Thrivea logo"
              width={32}
              height={32}
              sx={{
                maxWidth: '100%'
              }}
            />
          )}
          {!isOpen && (
            <Box
              component="img"
              src="/images/nav-logo-default.png"
              alt="Thrivea logo"
              width={32}
              height={32}
              sx={{
                maxWidth: '100%'
              }}
            />
          )}
        </Toolbar>
        <List
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            color: 'inherit',
            width: '100%',
            maxHeight: breakingHeight ? 468 : 600,
            padding: 0,
            overflowY: 'scroll',
            overflowX: 'hidden',
            marginLeft: '4px'
          }}
        >
          <AllowedTo
            perform={GroupPermissions.ACCESS_NEWS_FEED}
            data={currentEmployeeId}
            no={() => <NavListItem isDisabled={true} name="Home" url="/" icon={<HomeIcon />} />}
          >
            <NavListItem isDisabled={false} name="Home" url="/" icon={<HomeIcon />} />
          </AllowedTo>
          <AllowedTo
            perform={GroupPermissions.ACCESS_SETTINGS}
            data={currentEmployeeId}
            no={() => <NavListItem isDisabled={true} name="People" url="/people-directory" icon={<PeopleIcon />} />}
          >
            <NavListItem isDisabled={false} name="People" url="/people-directory" icon={<PeopleIcon />} />
          </AllowedTo>
          {/* <NavListItem isDisabled={true} name="Org" url="/organization" icon={<OrganizationIcon />} /> */}
          <NavListItem isDisabled={false} name="Assets" url="/assets" icon={<DocsIcon />} />
          {/* <NavListItem isDisabled={true} name="Feedback" url="/feedback" icon={<HeartIcon />} /> */}
          <NavListItem isDisabled={false} name="Operations" url="/operations/my-tasks" icon={<OperationsIcon />} />
          {/* <NavListItem isDisabled={true} name="Time" url="/time" icon={<TimeIcon />} /> */}
          {/* <NavListItem isDisabled={true} name="Comms" url="/comms" icon={<TalkIcon />} /> */}
          {/* <NavListItem isDisabled={true} name="Work" url="/work" icon={<WorkIcon />} /> */}
          {/* <NavListItem isDisabled={true} name="Payroll" url="/payroll" icon={<BenefitsIcon />} /> */}
        </List>
      </Stack>
      <Stack>
        <List
          sx={{
            width: '100%',
            backgroundColor: (theme) => theme.palette.common.white,
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            padding: 0
          }}
        >
          <AllowedTo perform={GroupPermissions.ACCESS_SETTINGS} data={currentEmployeeId} no={() => <NavSettingsListItem isDisabled={true} />}>
            <NavListItem isDisabled={false} name="Settings" url="/settings" icon={<SettingsIcon />} />
          </AllowedTo>
          <ListItem
            sx={{
              padding: '0'
            }}
          >
            <ListItemButton
              disableGutters
              component={Link}
              to="#"
              sx={{
                width: 46,
                height: 46,
                justifyContent: 'center',
                '&:hover': {
                  ' .hoverText': {
                    opacity: '1'
                  }
                }
              }}
            >
              <Stack
                gap={1}
                sx={{
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                {isSvgUrl(logoUrl) && (
                  <svg width="48" height="48" xmlns="http://www.w3.org/2000/svg">
                    <image href={getLogo()} height="48" width="48" />
                  </svg>
                )}
                {!isSvgUrl(logoUrl) && (
                  <Box
                    component="img"
                    src={getLogo()}
                    alt="Organization Logo"
                    width="46"
                    height="46"
                    sx={{
                      width: 48,
                      height: 48,
                      borderRadius: 2,
                      backgroundColor: 'transparent',
                      objectFit: 'contain'
                    }}
                  />
                )}
              </Stack>
            </ListItemButton>
          </ListItem>
        </List>
      </Stack>
    </Stack>
  );

  const navigationDrawerOpenButtonClick = () => {
    dispatch(openNavigationDrawer());
  };

  return (
    <>
      {!isTablet && (
        <Drawer
          variant="temporary"
          open={isOpen}
          onClose={navigationDrawerOpenButtonClick}
          sx={{
            display: {
              xs: 'block',
              lg: 'none'
            },
            '& .MuiDrawer-paper': {
              width: 150
            },
            '& .MuiBackdrop-root': {
              background: 'rgba(217, 217, 217, 0.60)',
              backdropFilter: 'blur(10px)'
            }
          }}
        >
          {navContent}
        </Drawer>
      )}
      {isTablet && <>{navContent}</>}
    </>
  );
};
