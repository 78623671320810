import { TreeViewBaseItem } from '@mui/x-tree-view/models';
import { Empty } from '@bufbuild/protobuf';
import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { SasToken, SasUri } from '@thrivea/auth-client';
import {
  CopyFilesRequest,
  CreateFolderRequest,
  DeletedFileItem,
  EditDescriptionRequest,
  EmployeeAccess,
  EmployeeListItem,
  FileAccessRole,
  FileInfo,
  FileItem,
  FileType,
  FolderInfo,
  HardDeleteFilesRequest,
  MoveFilesRequest,
  MultipleFilesInfo,
  PinFolderRequest,
  RenameFileRequest,
  RestoreFilesRequest,
  RetrieveFilesAccessRequest,
  RetrieveFilesAccessResponse,
  RetrieveFilesInfoRequest,
  RetrieveFilesInfoResponse,
  RetrieveFilesRequest,
  RetrieveFilesResponse,
  RetrieveRecentFilesRequest,
  RetrieveTopLevelFoldersRequest,
  RetrieveTopLevelFoldersResponse,
  RetrieveTrashBinFilesRequest,
  RetrieveTrashBinFilesResponse,
  RevokeAccessForAllRequest,
  ShareEmployee,
  ShareFilesRequest,
  SoftDeleteFilesRequest,
  StarFileRequest,
  TopLevelFolder
} from '@thrivea/organization-client';
import { FileWithStatus, UploadedFile, UploadProgress } from '@api/blob-storage.api';
import { RootState } from '@app/store';
import { ActionStatus } from '@/shared';
import { trimQueryParams } from '@/utils';
import { chain, orderBy, sortBy, uniq } from 'lodash';
import { mapMimeTypeToFileType } from './assets.service';
import { ExtendedTreeItem, FileSystemItem } from './assets.model';

export const MY_FILES_FOLDER_ID = '11d49e84-8b3c-421d-8dfb-12458fe2f911';

export interface AssetsState {
  entities: {
    files: {
      byInitialId: { [key: string]: FileItem };
      byUpdatedId: { [key: string]: FileItem };
      initialIds: string[];
      updatedIds: string[];
      addedIds: string[];
      removedIds: string[];
      recentFileIds: string[];
      starredFileIds: string[];
    };
    topLevelFolders: {
      byId: { [key: string]: TopLevelFolder };
      allIds: string[];
    };
    fileSystem: {
      byId: { [key: string]: FileSystemItem };
      allIds: string[];
    };
    trashBinFiles: {
      byId: { [key: string]: DeletedFileItem };
      allIds: string[];
      removedIds: string[];
    };
    accessItems: {
      byInitialId: { [key: string]: EmployeeAccess };
      byUpdatedId: { [key: string]: EmployeeAccess };
      initialIds: string[];
      updatedIds: string[];
      addedIds: string[];
      removedIds: string[];
    };
    tempFolder: {
      byId: { [key: string]: FileItem };
      allIds: string[];
      removedIds: string[];
    };
  };
  ui: {
    filesWithStatus: FileWithStatus[];
    fileUploadProgress: UploadProgress;
    retrieveFilesStatus: ActionStatus;
    retrieveTopLevelFoldersStatus: ActionStatus;
    uploadFilesStatus: ActionStatus;
    filesTotalCount: number;
    filesPageNumber: number;
    filesPageSize: number;
    retrieveTrashFilesStatus: ActionStatus;
    trashBinFilesTotalCount: number;
    trashBinFilesPageNumber: number;
    trashBinFilesPageSize: number;
    retrieveRecentFilesStatus: ActionStatus;
    recentFilesTotalCount: number;
    recentFilesPageNumber: number;
    recentFilesPageSize: number;
    isDragging: boolean;
    retrieveFilesAccessStatus: ActionStatus;
    filesUploading: boolean;
    isTempFolder: boolean;
  };
  activeFile: FileItem;
  myUploadsReadSasToken: string;
  myUploadsCreateDeleteSasUri: string;
  selectedFolderId: string;
  uploadFolderId: string;
  assetsDrawerItem: FileInfo | FolderInfo | MultipleFilesInfo | null;
  assetsDrawerFileItem: FileInfo;
  assetsDrawerFolderItem: FolderInfo;
  assetsDrawerMultipleFilesItem: MultipleFilesInfo;
  draggingState: 'Internal' | 'External';
  selectedFileIds: string[];
}

const initialState: AssetsState = {
  entities: {
    files: {
      byInitialId: {},
      byUpdatedId: {},
      addedIds: [],
      updatedIds: [],
      initialIds: [],
      removedIds: [],
      recentFileIds: [],
      starredFileIds: []
    },
    topLevelFolders: {
      byId: {},
      allIds: []
    },
    fileSystem: {
      byId: {},
      allIds: []
    },
    trashBinFiles: {
      byId: {},
      allIds: [],
      removedIds: []
    },
    accessItems: {
      byInitialId: {},
      byUpdatedId: {},
      initialIds: [],
      updatedIds: [],
      addedIds: [],
      removedIds: []
    },
    tempFolder: {
      byId: {},
      allIds: [],
      removedIds: []
    }
  },
  ui: {
    filesWithStatus: [],
    fileUploadProgress: {
      fileProgress: 0,
      totalProgress: 0
    },
    retrieveFilesStatus: ActionStatus.Idle,
    retrieveTopLevelFoldersStatus: ActionStatus.Idle,
    uploadFilesStatus: ActionStatus.Idle,
    filesTotalCount: 0,
    filesPageNumber: 1,
    filesPageSize: 5,
    retrieveTrashFilesStatus: ActionStatus.Idle,
    trashBinFilesTotalCount: 0,
    trashBinFilesPageNumber: 1,
    trashBinFilesPageSize: 5,
    retrieveRecentFilesStatus: ActionStatus.Idle,
    recentFilesTotalCount: 0,
    recentFilesPageNumber: 1,
    recentFilesPageSize: 5,
    isDragging: false,
    retrieveFilesAccessStatus: ActionStatus.Idle,
    filesUploading: false,
    isTempFolder: false
  },
  activeFile: {} as FileItem,
  myUploadsReadSasToken: '',
  myUploadsCreateDeleteSasUri: '',
  selectedFolderId: MY_FILES_FOLDER_ID,
  uploadFolderId: '',
  assetsDrawerItem: null,
  assetsDrawerFileItem: {} as FileInfo,
  assetsDrawerFolderItem: {} as FolderInfo,
  assetsDrawerMultipleFilesItem: {} as MultipleFilesInfo,
  draggingState: 'External',
  selectedFileIds: []
};

const makeFileSystemId = (parentFileId: string, childFileId: string) => parentFileId + '|' + childFileId;

export const assetSlice = createSlice({
  name: 'assets',
  initialState,
  reducers: {
    retrieveFilesRequested: (state, action: PayloadAction<RetrieveFilesRequest>) => {
      const { pageNumber, pageSize, folderId } = action.payload;

      state.ui.retrieveFilesStatus = ActionStatus.Pending;
      state.ui.filesPageNumber = pageNumber;
      state.ui.filesPageSize = pageSize;

      state.selectedFolderId = folderId;
    },
    retrieveFilesSucceeded: (state, action: PayloadAction<RetrieveFilesResponse>) => {
      const { files, totalCount } = action.payload;

      for (const file of files) {
        state.entities.files.byInitialId[file.id] = file;
        state.entities.files.byUpdatedId[file.id] = file; // ??
        state.entities.files.initialIds = uniq(state.entities.files.initialIds.concat(file.id));

        const fileSystemId = makeFileSystemId(state.selectedFolderId, file.id);

        if (!state.entities.fileSystem.hasOwnProperty(fileSystemId)) {
          state.entities.fileSystem.byId[fileSystemId] = {
            parentFileId: state.selectedFolderId,
            childFileId: file.id,
            type: file.type
          };
          state.entities.fileSystem.allIds.push(fileSystemId);
        }
      }

      state.ui.retrieveFilesStatus = ActionStatus.Idle;
      state.ui.filesTotalCount = totalCount;
    },
    retrieveFilesFailed: (state) => {
      state.ui.retrieveFilesStatus = ActionStatus.Failed;
    },
    retrieveTopLevelFoldersRequested: (state, action: PayloadAction<RetrieveTopLevelFoldersRequest>) => {
      state.ui.retrieveTopLevelFoldersStatus = ActionStatus.Pending;
    },
    retrieveTopLevelFoldersSucceeded: (state, action: PayloadAction<RetrieveTopLevelFoldersResponse>) => {
      const { folders } = action.payload;

      for (const folder of folders) {
        if (!state.entities.topLevelFolders.byId.hasOwnProperty(folder.id)) {
          state.entities.topLevelFolders.byId[folder.id] = folder;
          state.entities.topLevelFolders.allIds.push(folder.id);
        }
      }

      state.ui.retrieveTopLevelFoldersStatus = ActionStatus.Idle;
    },
    retrieveTopLevelFoldersFailed: (state) => {
      state.ui.retrieveTopLevelFoldersStatus = ActionStatus.Failed;
    },
    uploadFilesRequested: (state, action: PayloadAction<{ rootFolderID: string; files: FileWithStatus[] }>) => {
      state.ui.filesWithStatus = action.payload.files;
      // state.rootFolderId = action.payload.rootFolderID;
      state.uploadFolderId = action.payload.rootFolderID;

      state.ui.uploadFilesStatus = ActionStatus.Pending;
    },
    notifyBackendOnUpload: (state) => {},
    uploadFilesSucceeded: (state) => {
      state.ui.uploadFilesStatus = ActionStatus.Idle;
    },
    uploadFilesFailed: (state) => {
      state.ui.uploadFilesStatus = ActionStatus.Failed;
    },
    uploadFile: (state, action: PayloadAction<FileWithStatus>) => {
      const fileWithStatus = state.ui.filesWithStatus.find((fws) => fws.id === action.payload.id)!;
      fileWithStatus.isUploading = true;
      fileWithStatus.isUploaded = false;
    },
    updateFileUploadProgress: (state, action: PayloadAction<UploadProgress>) => {
      state.ui.fileUploadProgress = {
        fileProgress: action.payload.fileProgress,
        totalProgress: action.payload.totalProgress
      };
    },
    fileUploaded: (state, action: PayloadAction<UploadedFile>) => {
      const fileWithStatus = state.ui.filesWithStatus.find((fws) => fws.id === action.payload.fileWithStatus.id)!;
      fileWithStatus.isUploading = false;
      fileWithStatus.isUploaded = true;
      fileWithStatus.url = trimQueryParams(action.payload.url);

      if (state.uploadFolderId === state.selectedFolderId) {
        state.entities.files.byInitialId[fileWithStatus.id] = new FileItem({
          id: fileWithStatus.id,
          name: fileWithStatus.name,
          sizeInBytes: fileWithStatus.file.size,
          type: mapMimeTypeToFileType(fileWithStatus.file?.type),
          url: fileWithStatus.url
        });
        state.entities.files.initialIds.push(fileWithStatus.id);
        state.entities.files.addedIds.push(fileWithStatus.id);
      }

      state.ui.fileUploadProgress = {
        fileProgress: 0,
        totalProgress: 0
      };
    },
    setActiveFile: (state, action: PayloadAction<FileItem>) => {
      state.activeFile = action.payload;
    },
    createTempFolder: (state, action: PayloadAction<CreateFolderRequest>) => {
      const newFolder = action.payload;

      state.entities.tempFolder.allIds.push(newFolder.id);
      state.entities.tempFolder.byId[newFolder.id] = new FileItem({
        id: newFolder.id,
        description: '',
        isStarred: false,
        name: newFolder.name,
        sizeInBytes: 0,
        type: FileType.FOLDER,
        url: ''
      });

      state.ui.isTempFolder = true;
    },
    createFolderRequested: (state, action: PayloadAction<CreateFolderRequest>) => {
      // delete state.entities.tempFolder.byId[action.payload.id];
      state.entities.tempFolder.removedIds.push(action.payload.id);
    },
    createFolderSucceeded: (state, action: PayloadAction<CreateFolderRequest>) => {
      const newFolder = action.payload;

      state.entities.files.byInitialId[newFolder.id] = new FileItem({
        id: newFolder.id,
        description: '',
        isStarred: false,
        name: newFolder.name,
        sizeInBytes: 0,
        type: FileType.FOLDER,
        url: ''
      });
      state.entities.files.initialIds.push(newFolder.id);

      state.entities.topLevelFolders.byId[newFolder.id] = new TopLevelFolder({
        id: newFolder.id,
        isPinned: false,
        name: newFolder.name
      });
      state.entities.topLevelFolders.allIds.push(newFolder.id);

      state.ui.isTempFolder = false;
      state.ui.filesTotalCount += 1;
    },
    createFolderFailed: (state) => {},
    retrieveMyUploadsReadSasTokenRequested: (state, action: PayloadAction<Empty>) => {},
    retrieveMyUploadsReadSasTokenSucceeded: (state, action: PayloadAction<SasToken>) => {
      state.myUploadsReadSasToken = action.payload.token;
    },
    retrieveMyUploadsReadSasTokenFailed: (state) => {},
    retrieveMyUploadsCreateDeleteSasUriRequested: (state, action: PayloadAction<Empty>) => {},
    retrieveMyUploadsCreateDeleteSasUriSucceeded: (state, action: PayloadAction<SasUri>) => {
      state.myUploadsCreateDeleteSasUri = action.payload.uri;
    },
    retrieveMyUploadsCreateDeleteSasUriFailed: (state) => {},
    refreshMyUploadsCreateDeleteSasUriRequested: (state, action: PayloadAction<Empty>) => {},
    refreshMyUploadsCreateDeleteSasUriSucceeded: (state, action: PayloadAction<SasUri>) => {
      state.myUploadsCreateDeleteSasUri = action.payload.uri;
    },
    refreshMyUploadsCreateDeleteSasUriFailed: (state) => {},
    renameFileRequested: (state, action: PayloadAction<RenameFileRequest>) => {},
    renameFileSucceeded: (state, action: PayloadAction<string>) => {
      const activeFile = state.activeFile;

      state.entities.files.byUpdatedId[activeFile.id] = {
        ...activeFile,
        name: action.payload
      };

      state.entities.topLevelFolders.byId[activeFile.id] = {
        ...state.entities.topLevelFolders.byId[activeFile.id],
        name: action.payload
      };

      state.activeFile.name = action.payload;
    },
    renameFileFailed: (state) => {},
    editDescriptionRequested: (state, action: PayloadAction<EditDescriptionRequest>) => {},
    editDescriptionSucceeded: (state) => {},
    editDescriptionFailed: (state) => {},
    softDeleteFilesRequested: (state, action: PayloadAction<SoftDeleteFilesRequest>) => {},
    softDeleteFilesSucceeded: (state, action: PayloadAction<string[]>) => {
      const removedIds = action.payload;
      for (const id of removedIds) {
        delete state.entities.files.byUpdatedId[id];
        state.entities.files.removedIds.push(id);
      }
    },
    softDeleteFilesFailed: (state) => {},
    retrieveFilesInfoRequested: (state, action: PayloadAction<RetrieveFilesInfoRequest>) => {
      const { selectedFileIds } = action.payload;

      state.selectedFileIds = [];
      state.assetsDrawerFileItem = {} as FileInfo;
      state.assetsDrawerFolderItem = {} as FolderInfo;
      state.assetsDrawerMultipleFilesItem = {} as MultipleFilesInfo;

      for (const fileId of selectedFileIds) {
        state.selectedFileIds.push(fileId);
      }
    },
    retrieveFilesInfoSucceeded: (state, action: PayloadAction<RetrieveFilesInfoResponse>) => {
      const { kind } = action.payload;

      switch (kind.case) {
        case 'fileInfo': {
          state.assetsDrawerFileItem = kind.value;
          break;
        }
        case 'folderInfo': {
          state.assetsDrawerFolderItem = kind.value;
          break;
        }
        case 'multipleFilesInfo': {
          state.assetsDrawerMultipleFilesItem = kind.value;
          break;
        }
        default: {
          state.assetsDrawerFileItem = {} as FileInfo;
          state.assetsDrawerFolderItem = {} as FolderInfo;
          state.assetsDrawerMultipleFilesItem = {} as MultipleFilesInfo;
        }
      }
    },
    retrieveFilesInfoFailed: (state) => {},
    retrieveTrashBinFilesRequested: (state, action: PayloadAction<RetrieveTrashBinFilesRequest>) => {
      const { pageNumber, pageSize } = action.payload;

      state.ui.trashBinFilesPageNumber = pageNumber;
      state.ui.trashBinFilesPageSize = pageSize;
      state.ui.retrieveTrashFilesStatus = ActionStatus.Pending;

      state.entities.trashBinFiles.byId = {};
      state.entities.trashBinFiles.allIds = [];
    },
    retrieveTrashBinFilesSucceeded: (state, action: PayloadAction<RetrieveTrashBinFilesResponse>) => {
      const { files, totalCount } = action.payload;

      for (const file of files) {
        if (!state.entities.trashBinFiles.byId.hasOwnProperty(file.id)) {
          state.entities.trashBinFiles.byId[file.id] = file;
          state.entities.trashBinFiles.allIds.push(file.id);
        }
      }

      state.ui.trashBinFilesTotalCount = totalCount;
      state.ui.retrieveTrashFilesStatus = ActionStatus.Idle;
    },
    retrieveTrashBinFilesFailed: (state) => {
      state.ui.retrieveTrashFilesStatus = ActionStatus.Failed;
    },
    retrieveRecentFilesRequested: (state, action: PayloadAction<RetrieveRecentFilesRequest>) => {
      const { pageNumber, pageSize } = action.payload;

      state.ui.recentFilesPageNumber = pageNumber;
      state.ui.recentFilesPageSize = pageSize;
      state.ui.retrieveRecentFilesStatus = ActionStatus.Pending;

      state.entities.files.recentFileIds = [];
    },
    retrieveRecentFilesSucceeded: (state, action: PayloadAction<RetrieveFilesResponse>) => {
      const { files, totalCount } = action.payload;

      for (const file of files) {
        state.entities.files.byInitialId[file.id] = file;
        state.entities.files.recentFileIds = uniq(state.entities.files.recentFileIds.concat(file.id));
      }

      state.ui.recentFilesTotalCount = totalCount;
      state.ui.retrieveRecentFilesStatus = ActionStatus.Idle;
    },
    retrieveRecentFilesFailed: (state) => {
      state.ui.retrieveRecentFilesStatus = ActionStatus.Failed;
    },
    clearRecentFiles: (state) => {
      state.entities.files.recentFileIds = [];
    },
    retrieveFilesAccessRequested: (state, action: PayloadAction<RetrieveFilesAccessRequest>) => {
      state.ui.retrieveFilesAccessStatus = ActionStatus.Pending;
    },
    retrieveFilesAccessSucceeded: (state, action: PayloadAction<RetrieveFilesAccessResponse>) => {
      const { accessItems } = action.payload;

      for (const item of accessItems) {
        if (!state.entities.accessItems.byInitialId.hasOwnProperty(item.id)) {
          state.entities.accessItems.byInitialId[item.id] = item;
          state.entities.accessItems.initialIds.push(item.id);
        }
      }

      state.ui.retrieveFilesAccessStatus = ActionStatus.Idle;
    },
    retrieveFilesAccessFailed: (state) => {
      state.ui.retrieveFilesAccessStatus = ActionStatus.Failed;
    },
    copyFilesRequested: (state, action: PayloadAction<CopyFilesRequest>) => {},
    copyFilesSucceeded: (state) => {},
    copyFilesFailed: (state) => {},
    selectFolder: (state, action: PayloadAction<string>) => {
      state.selectedFolderId = action.payload;
    },
    starFileRequested: (state, action: PayloadAction<StarFileRequest>) => {},
    starFileSucceeded: (state, action: PayloadAction<string>) => {
      state.entities.files.byUpdatedId[action.payload] = {
        ...state.entities.files.byUpdatedId[action.payload],
        isStarred: !state.entities.files.byUpdatedId[action.payload].isStarred
      };

      if (!state.entities.files.updatedIds.includes(action.payload)) {
        state.entities.files.updatedIds.push(action.payload);
      }
    },
    starFileFailed: (state) => {},
    moveFilesRequested: (state, action: PayloadAction<MoveFilesRequest>) => {},
    moveFilesSucceeded: (state, action: PayloadAction<string[]>) => {
      const fileIds = action.payload;

      for (const id of fileIds) {
        state.entities.files.removedIds.push(id);
      }
    },
    moveFilesFailed: (state) => {},
    setInnerDragging: (state, action: PayloadAction<'Internal' | 'External'>) => {
      state.draggingState = action.payload;
    },
    setIsDragging: (state, action: PayloadAction<boolean>) => {
      state.ui.isDragging = action.payload;
    },
    shareFilesRequested: (state, action: PayloadAction<ShareFilesRequest>) => {},
    shareFilesSucceeded: (state, action: PayloadAction<ShareEmployee[]>) => {
      const shareEmployees = action.payload;

      for (const employee of shareEmployees) {
        state.entities.accessItems.byInitialId[employee.employeeId] = {
          ...state.entities.accessItems.byInitialId[employee.employeeId],
          accessRole: employee.accessRole
        };
      }
    },
    shareFilesFailed: (state) => {},
    hardDeleteFilesRequested: (state, action: PayloadAction<HardDeleteFilesRequest>) => {},
    hardDeleteFilesSucceeded: (state, action: PayloadAction<string[]>) => {
      for (const id of action.payload) {
        state.entities.trashBinFiles.removedIds.push(id);
      }

      state.ui.trashBinFilesTotalCount -= action.payload.length;
    },
    hardDeleteFilesFailed: (state) => {},
    restoreFilesRequested: (state, action: PayloadAction<RestoreFilesRequest>) => {},
    restoreFilesSucceeded: (state, action: PayloadAction<string[]>) => {
      for (const id of action.payload) {
        const restoredFile = state.entities.trashBinFiles.byId[id];

        state.entities.trashBinFiles.removedIds.push(id);

        state.entities.files.byUpdatedId[id] = new FileItem({
          id: restoredFile.name,
          description: restoredFile.description,
          isStarred: false,
          sizeInBytes: restoredFile.sizeInBytes,
          type: restoredFile.type,
          url: restoredFile.url
        });
        state.entities.files.addedIds.push(id);
      }

      state.ui.trashBinFilesTotalCount -= action.payload.length;
    },
    restoreFilesFailed: (state) => {},
    pinFolderRequested: (state, action: PayloadAction<PinFolderRequest>) => {},
    pinFolderSucceeded: (state, action: PayloadAction<string>) => {
      const folder = state.entities.topLevelFolders.byId[action.payload];

      state.entities.topLevelFolders.byId[action.payload] = {
        ...folder,
        isPinned: !folder.isPinned
      };
    },
    pinFolderFailed: (state) => {},
    revokeAccessForAllRequested: (state, action: PayloadAction<RevokeAccessForAllRequest>) => {},
    revokeAccessForAllSucceeded: (state) => {},
    revokeAccessForAllFailed: (state) => {},
    addEmployeeToShareList: (state, action: PayloadAction<EmployeeAccess[]>) => {
      const shareEmployees = action.payload;

      for (const employee of shareEmployees) {
        state.entities.accessItems.byUpdatedId[employee.id] = employee;
        state.entities.accessItems.updatedIds.push(employee.id);
        state.entities.accessItems.addedIds.push(employee.id);
      }
    },
    removeEmployeeFromShareList: (state, action: PayloadAction<string>) => {
      state.entities.accessItems.removedIds.push(action.payload);
    },
    updateEmployeeAccessRole: (state, action: PayloadAction<{ employee: EmployeeAccess; role: FileAccessRole }>) => {
      const employee = action.payload.employee;
      const role = action.payload.role;

      state.entities.accessItems.byUpdatedId[employee.id] = {
        ...state.entities.accessItems.byUpdatedId[employee.id],
        accessRole: role
      };
    }
  }
});

export const selectFilesByInitialId = (state: RootState) => state.assets.entities.files.byInitialId;
export const selectFilesByUpdatedId = (state: RootState) => state.assets.entities.files.byUpdatedId;
export const selectRecentFilesId = (state: RootState) => state.assets.entities.files.recentFileIds;
export const selectStarredFilesId = (state: RootState) => state.assets.entities.files.starredFileIds;

export const selectFilesInitalIds = (state: RootState) => state.assets.entities.files.initialIds;
export const selectFilesUpdatedIds = (state: RootState) => state.assets.entities.files.updatedIds;
export const selectFilesAddedIds = (state: RootState) => state.assets.entities.files.addedIds;
export const selectFilesRemovedIds = (state: RootState) => state.assets.entities.files.removedIds;
export const selectFilesTotalCount = (state: RootState) => state.assets.ui.filesTotalCount;

const selectFileSystem = (state: RootState) => state.assets.entities.fileSystem;

// const buildTreeItems = (
//   fileSystem: { byId: { [key: string]: FileSystemItem }; allIds: string[] },
//   filesByInitialId: { [key: string]: FileItem },
//   parentId: string,
//   nestingLevel: number
// ): TreeViewBaseItem<ExtendedTreeItem>[] =>
//   chain(fileSystem.allIds)
//     .filter((id) => fileSystem.byId[id].type === FileType.FOLDER && fileSystem.byId[id].parentFileId === parentId)
//     .map((id) => {
//       const folderId = fileSystem.byId[id].childFileId;
//       const folderName = filesByInitialId[folderId].name;

//       return {
//         id: folderId,
//         label: folderName,
//         nestingLevel,
//         children: buildTreeItems(fileSystem, filesByInitialId, folderId, nestingLevel + 1)
//       } as ExtendedTreeItem;
//     })
//     .value();

// export const selectMyFoldersAsTreeItems = createSelector([selectFileSystem, selectFilesByInitialId], (fileSystem, filesByInitialId) =>
//   buildTreeItems(fileSystem, filesByInitialId, MY_FILES_FOLDER_ID, 1)
// );

const buildTreeItems = (
  fileSystem: { byId: { [key: string]: FileSystemItem }; allIds: string[] },
  filesByInitialId: { [key: string]: FileItem },
  parentId: string,
  nestingLevel: number
): TreeViewBaseItem<ExtendedTreeItem>[] =>
  chain(fileSystem.allIds)
    .uniq()
    .filter((id) => fileSystem.byId[id].type === FileType.FOLDER && fileSystem.byId[id].parentFileId === parentId)
    .map((id) => {
      const folderId = fileSystem.byId[id].childFileId;
      const folderName = filesByInitialId[folderId].name;

      return {
        id: folderId,
        label: folderName,
        nestingLevel,
        children: buildTreeItems(fileSystem, filesByInitialId, folderId, nestingLevel + 1)
      } as ExtendedTreeItem;
    })
    .value();

export const selectMyFoldersAsTreeItems = createSelector([selectFileSystem, selectFilesByInitialId], (fileSystem, filesByInitialId) =>
  buildTreeItems(fileSystem, filesByInitialId, MY_FILES_FOLDER_ID, 1)
);

export const selectMyFiles = (state: RootState) => {
  const items: TreeViewBaseItem<ExtendedTreeItem>[] = [
    {
      id: '1',
      label: 'Documents',
      nestingLevel: 1,
      children: [
        {
          id: '1.1',
          label: 'Company',
          nestingLevel: 2,
          children: [
            { id: '1.1.1', label: 'Invoice', nestingLevel: 3 },
            { id: '1.1.2', label: 'Meeting notes', nestingLevel: 3 },
            { id: '1.1.3', label: 'Tasks list', nestingLevel: 3 },
            { id: '1.1.4', label: 'Equipment', nestingLevel: 3 },
            { id: '1.1.5', label: 'Video conference', nestingLevel: 3 }
          ]
        },
        { id: '1.2', label: 'Personal', nestingLevel: 2 },
        { id: '1.3', label: 'Group photo', nestingLevel: 2 }
      ]
    },
    {
      id: '2',
      label: 'Bookmarked',
      nestingLevel: 1,
      children: [{ id: '2.1', label: 'Learning materials', nestingLevel: 2 }]
    },
    { id: '3', label: 'History', nestingLevel: 1 },
    { id: '4', label: 'Trash', nestingLevel: 1 }
  ];
  return items;
};

export const selectFileIds = createSelector(
  [selectFilesInitalIds, selectFilesAddedIds, selectFilesUpdatedIds, selectFilesRemovedIds],
  (initialIds, addedIds, updatedIds, removedIds) => Array.from(new Set([...initialIds, ...addedIds, ...updatedIds].filter((id) => !removedIds.includes(id))))
);

export const selectSelectedFolderId = (state: RootState) => state.assets.selectedFolderId;

export const selectFiles = createSelector(
  [selectFilesByInitialId, selectFilesByUpdatedId, selectFilesUpdatedIds, selectFileSystem, selectSelectedFolderId, selectRecentFilesId, selectStarredFilesId],
  (byInitialId, byUpdatedId, updatedIds, fileSystem, selectedFolderId, recentFileIds, starredFileIds) => {
    console.log('recentFileIds:', recentFileIds); // Log recentFileIds
    return chain(fileSystem.byId)
      .entries()
      .filter(([_key, value]) => value.parentFileId === selectedFolderId)
      .map(([_key, value]) => value.childFileId)
      .filter((id) => !updatedIds.includes(id))
      .filter((id) => (recentFileIds.length > 0 ? recentFileIds.includes(id) : true))
      .filter((id) => (starredFileIds.length > 0 ? starredFileIds.includes(id) : true))
      .map((id) => byInitialId[id])
      .concat(updatedIds.map((id) => byUpdatedId[id]))
      .sortBy('name')
      .value();
  }
);
export const selectFolderByTempId = (state: RootState) => state.assets.entities.tempFolder.byId;
export const selectTempFolderAllIds = (state: RootState) => state.assets.entities.tempFolder.allIds;
export const selectTempFolderRemovedIds = (state: RootState) => state.assets.entities.tempFolder.removedIds;

export const selectTempFolderIds = createSelector([selectTempFolderAllIds, selectTempFolderRemovedIds], (allIds, removedIds) =>
  allIds.filter((id) => !removedIds.includes(id))
);

export const selectTempFolders = createSelector([selectFolderByTempId, selectTempFolderIds], (byId, ids) => ids.map((id) => byId[id]));

export const selectFilesPageNumber = (state: RootState) => state.assets.ui.filesPageNumber;
export const selectFilesPageSize = (state: RootState) => state.assets.ui.filesPageSize;

export const selectMemoizedFilePagesStatusText = createSelector(
  [selectFilesPageNumber, selectFilesPageSize, selectFilesTotalCount],
  (pageNumber, pageSize, totalCount) => {
    const start = (pageNumber - 1) * pageSize + 1;
    const end = Math.min(pageNumber * pageSize, totalCount);

    return `${start} - ${end} of ${totalCount}`;
  }
);

export const selectFilesPageCount = createSelector([selectFilesTotalCount, selectFilesPageSize], (totalCount, pageSize) => Math.ceil(totalCount / pageSize));

export const selectRetrieveFilesStatus = (state: RootState) => state.assets.ui.retrieveFilesStatus;

export const selectTopLevelFolderById = (state: RootState) => state.assets.entities.topLevelFolders.byId;
export const selectTopLevelFolderIds = (state: RootState) => state.assets.entities.topLevelFolders.allIds;
export const selectTopLevelFolders = createSelector([selectTopLevelFolderById, selectTopLevelFolderIds], (byId, ids) =>
  orderBy(
    ids.map((id) => byId[id]),
    ['isPinned'],
    ['desc']
  )
);

export const selectRetrieveTopLevelFoldersStatus = (state: RootState) => state.assets.ui.retrieveTopLevelFoldersStatus;

export const selectUploadFilesStatus = (state: RootState) => state.assets.ui.uploadFilesStatus;

export const selectActiveFile = (state: RootState) => state.assets.activeFile;

export const selectMyUploadsCreateDeleteSasUri = (state: RootState) => state.assets.myUploadsCreateDeleteSasUri;
export const selectMyUploadsReadSasToken = (state: RootState) => state.assets.myUploadsReadSasToken;

export const selectFilesWithStatusForUpload = (state: RootState) =>
  state.assets.ui.filesWithStatus.filter((fws) => fws.isUploaded === false && fws.isUploading === false && fws.url === '');

export const selectUploadedFilesWithStatuses = (state: RootState) => state.assets.ui.filesWithStatus;

export const selectAssestsDrawerItem = (state: RootState) => state.assets.assetsDrawerItem!;
export const selectAssetsDrawerFileItem = (state: RootState) => state.assets.assetsDrawerFileItem!;
export const selectAssetsDrawerFolderItem = (state: RootState) => state.assets.assetsDrawerFolderItem!;
export const selectAssetsDrawerMultipleFilesItem = (state: RootState) => state.assets.assetsDrawerMultipleFilesItem!;

export const selectDraggingState = (state: RootState) => state.assets.draggingState;

export const selectTrashBinFileById = (state: RootState) => state.assets.entities.trashBinFiles.byId;
export const selectTrashBinFileAllIds = (state: RootState) => state.assets.entities.trashBinFiles.allIds;
export const selectTrashBinFileRemovedIds = (state: RootState) => state.assets.entities.trashBinFiles.removedIds;
export const selectTrashBinTotalCount = (state: RootState) => state.assets.ui.trashBinFilesTotalCount;

export const selectTrashBinFileIds = createSelector([selectTrashBinFileAllIds, selectTrashBinFileRemovedIds], (initialIds, removedIds) =>
  Array.from(new Set([...initialIds].filter((id) => !removedIds.includes(id))))
);

export const selectTrashBinFiles = createSelector([selectTrashBinFileById, selectTrashBinFileIds], (byId, ids) =>
  sortBy(
    ids.map((id) => byId[id]),
    ['name']
  )
);

export const selectTrashFilesPageNumber = (state: RootState) => state.assets.ui.trashBinFilesPageNumber;
export const selectTrashFilesPageSize = (state: RootState) => state.assets.ui.trashBinFilesPageSize;
export const selectTrashFilesStatus = (state: RootState) => state.assets.ui.retrieveTrashFilesStatus;

export const selectTrashBinPagesCount = createSelector([selectTrashBinTotalCount, selectTrashFilesPageSize], (totalCount, pageSize) =>
  Math.ceil(totalCount / pageSize)
);

export const selectMemoizedTrashFilePagesStatusText = createSelector(
  [selectTrashFilesPageNumber, selectTrashFilesPageSize, selectTrashBinTotalCount],
  (pageNumber, pageSize, totalCount) => {
    const start = (pageNumber - 1) * pageSize + 1;
    const end = Math.min(pageNumber * pageSize, totalCount);

    return `${start} - ${end} of ${totalCount}`;
  }
);

export const selectTrashFilesPageCount = createSelector([selectTrashBinTotalCount, selectTrashFilesPageSize], (totalCount, pageSize) =>
  Math.ceil(totalCount / pageSize)
);

export const selectFileUploadTotalProgress = (state: RootState) => state.assets.ui.fileUploadProgress.totalProgress;
export const selectIsDragging = (state: RootState) => state.assets.ui.isDragging;

export const selectAccessItemsByInitialId = (state: RootState) => state.assets.entities.accessItems.byInitialId;
export const selectAccessItemsByUpdatedId = (state: RootState) => state.assets.entities.accessItems.byUpdatedId;
export const selectAccessItemsInitialIds = (state: RootState) => state.assets.entities.accessItems.initialIds;
export const selectAccessItemsUpdatedIds = (state: RootState) => state.assets.entities.accessItems.updatedIds;
export const selectAccessItemsAddedIds = (state: RootState) => state.assets.entities.accessItems.addedIds;
export const selectAccessItemsRemovedIds = (state: RootState) => state.assets.entities.accessItems.removedIds;

export const selectAccessItemsIds = createSelector(
  [selectAccessItemsInitialIds, selectAccessItemsAddedIds, selectAccessItemsUpdatedIds, selectAccessItemsRemovedIds],
  (initialIds, addedIds, updatedIds, removedIds) => Array.from(new Set([...initialIds, ...addedIds, ...updatedIds].filter((id) => !removedIds.includes(id))))
);

export const selectAccessItems = createSelector(
  [selectAccessItemsIds, selectAccessItemsByInitialId, selectAccessItemsByUpdatedId, selectAccessItemsUpdatedIds],
  (selectedAccessItems, byInitialId, byUpdatedId, updatedIds) =>
    sortBy(
      selectedAccessItems
        .filter((id) => !updatedIds.includes(id))
        .map((id) => byInitialId[id])
        .concat(updatedIds.map((id) => byUpdatedId[id])),
      ['name']
    )
);

// export const selectAccessItems = createSelector([selectAccessItemsByInitialId, selectAccessItemsInitialIds], (byId, ids) =>
//   sortBy(
//     ids.map((id) => byId[id]),
//     ['accessRole']
//   )
// );

export const selectIsTempFolder = (state: RootState) => state.assets.ui.isTempFolder;
export const selectSelectedFileIds = (state: RootState) => state.assets.selectedFileIds;

export const {
  copyFilesRequested,
  copyFilesSucceeded,
  copyFilesFailed,
  retrieveFilesRequested,
  retrieveFilesSucceeded,
  retrieveFilesFailed,
  retrieveFilesInfoRequested,
  retrieveFilesInfoSucceeded,
  retrieveFilesInfoFailed,
  retrieveTopLevelFoldersRequested,
  retrieveTopLevelFoldersSucceeded,
  retrieveTopLevelFoldersFailed,
  uploadFilesRequested,
  notifyBackendOnUpload,
  uploadFilesSucceeded,
  uploadFilesFailed,
  uploadFile,
  updateFileUploadProgress,
  fileUploaded,
  createFolderRequested,
  createFolderSucceeded,
  createFolderFailed,
  retrieveMyUploadsReadSasTokenRequested,
  retrieveMyUploadsReadSasTokenSucceeded,
  retrieveMyUploadsReadSasTokenFailed,
  retrieveMyUploadsCreateDeleteSasUriRequested,
  retrieveMyUploadsCreateDeleteSasUriSucceeded,
  retrieveMyUploadsCreateDeleteSasUriFailed,
  retrieveFilesAccessRequested,
  retrieveFilesAccessSucceeded,
  retrieveFilesAccessFailed,
  refreshMyUploadsCreateDeleteSasUriRequested,
  refreshMyUploadsCreateDeleteSasUriSucceeded,
  refreshMyUploadsCreateDeleteSasUriFailed,
  renameFileRequested,
  renameFileSucceeded,
  renameFileFailed,
  retrieveTrashBinFilesRequested,
  retrieveTrashBinFilesSucceeded,
  retrieveTrashBinFilesFailed,
  retrieveRecentFilesRequested,
  retrieveRecentFilesSucceeded,
  retrieveRecentFilesFailed,
  clearRecentFiles,
  editDescriptionRequested,
  editDescriptionSucceeded,
  editDescriptionFailed,
  softDeleteFilesRequested,
  softDeleteFilesSucceeded,
  softDeleteFilesFailed,
  setActiveFile,
  selectFolder,
  starFileRequested,
  starFileSucceeded,
  starFileFailed,
  setInnerDragging,
  moveFilesRequested,
  moveFilesSucceeded,
  moveFilesFailed,
  setIsDragging,
  shareFilesRequested,
  shareFilesSucceeded,
  shareFilesFailed,
  hardDeleteFilesRequested,
  hardDeleteFilesSucceeded,
  hardDeleteFilesFailed,
  restoreFilesRequested,
  restoreFilesSucceeded,
  restoreFilesFailed,
  pinFolderRequested,
  pinFolderSucceeded,
  pinFolderFailed,
  revokeAccessForAllRequested,
  revokeAccessForAllSucceeded,
  revokeAccessForAllFailed,
  addEmployeeToShareList,
  removeEmployeeFromShareList,
  updateEmployeeAccessRole,
  createTempFolder
} = assetSlice.actions;
export default assetSlice.reducer;
