import { styled, Tab, tabClasses } from '@mui/material';

export const ViewFilterTab = styled(Tab, {
  shouldForwardProp: (prop) => prop !== 'isEditable' && prop !== 'isActive'
})<{
  isEditable?: boolean;
  isActive?: boolean;
}>(({ isEditable, isActive, theme }) => ({
  backgroundColor: 'transparent',
  border: '1px solid',
  borderColor: theme.palette.primary.main,
  padding: '4px 10px 4px 12px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 28,
  borderRadius: '16px',
  color: theme.palette.primary.main,
  opacity: 1,
  fontWeight: 500,
  [`&.${tabClasses.selected}`]: {
    backgroundColor: theme.palette.customTheme.selectItem,
    borderColor: 'transparent'
  },
  '&:hover': {
    backgroundColor: theme.palette.customTheme.contentBorder,
    borderColor: 'transparent'
  }
}));
