import React from 'react';

interface CircleOutlinedIconProps {
  width?: number;
  height?: number;
  color?: string;
}

export const CircleOutlinedIcon: React.FC<CircleOutlinedIconProps> = ({ width = 5, height = 5, color = '#251D38' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 5 5" fill="none">
      <path
        d="M4.5 2.5C4.5 3.60457 3.60457 4.5 2.5 4.5C1.39543 4.5 0.5 3.60457 0.5 2.5C0.5 1.39543 1.39543 0.5 2.5 0.5C3.60457 0.5 4.5 1.39543 4.5 2.5Z"
        stroke={color}
      />
    </svg>
  );
};
