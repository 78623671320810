import { create } from '@bufbuild/protobuf';
import { ForwardedRef, useId, useState, forwardRef } from 'react';
import { Box, Skeleton, Stack } from '@mui/material';
import {
  RowCenterStack,
  CancelEditIcon,
  PrimaryDarkButton,
  StyledFormSubmitButton,
  InlineImageUpload,
  MultiSizeImageBlobs,
  PictureSizeSuffix,
  StyledFormLabel,
  StyledGenericForm,
  EditPenIcon,
  StyledErbButton,
  PrimaryTypographyHeaderTitleCase,
  ActionStatus
} from '@/shared';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import {
  removeEmployeeProfileCoverPictureFileMultiSize,
  removeEmployeeProfilePictureFileMultipleSizes,
  resetEmployeeProfileCoverPictureFileMultiSize,
  resetEmployeeProfilePictureFileMultipleSizes,
  selectEmployeeCoverPictureFileMultiSize,
  selectEmployeeProfileAndCoverReadSasToken,
  selectEmployeeProfilePictureFileMultiSize,
  setEmployeeCoverPictureFileMultipleSizes,
  setEmployeeProfilePictureFileMultipleSizes,
  updateProfileMediaRequested
} from '@features/employee-profile';
import { UpdateProfileMediaRequest, UpdateProfileMediaRequestSchema } from '@thrivea/organization-client';
import { Controller, useForm } from 'react-hook-form';
import { pictureUrl } from '@utils/pictureUrl';
import { AllowedTo } from 'react-abac';
import { GroupPermissions, ProfileCategoryInfo } from '@features/abac';
import { snakeCase } from 'lodash';
import { selectEmployeeBasicInfoStatus } from '@features/admin-settings';
import Grid from '@mui/material/Grid2';
import { selectEmployeeRecordCoverPicture, selectEmployeeRecordProfilePicture } from '@features/employee-record-page';

interface PersonalBrandingCategoryProps {
  ref: ForwardedRef<HTMLDivElement>;
  employeeId: string;
}

export const EMPLOYEE_PROFILE_PICTURE_PLACEHOLDER = '/images/logo-placeholder.png';

export const PersonalBrandingCategory = forwardRef<HTMLDivElement, PersonalBrandingCategoryProps>(({ employeeId }, ref) => {
  const { t } = useTranslation(['common', 'employee_profile']);
  const name = t('personal_branding', { ns: 'employee_profile' });

  const dispatch = useAppDispatch();

  const [isEditable, setIsEditable] = useState(false);
  const employeeProfilePictureFileMultiSize = useAppSelector(selectEmployeeProfilePictureFileMultiSize);
  const employeeProfileCoverFileMultiSize = useAppSelector(selectEmployeeCoverPictureFileMultiSize);
  const employeeProfileAndCoverReadSasToken = useAppSelector(selectEmployeeProfileAndCoverReadSasToken);
  const employeeBasicInfoStatus = useAppSelector(selectEmployeeBasicInfoStatus);

  const profilePictureUrl = useAppSelector(selectEmployeeRecordProfilePicture);
  const coverPictureUrl = useAppSelector(selectEmployeeRecordCoverPicture);

  const profilePictureId = useId();
  const coverPictureId = useId();

  const { control, handleSubmit, reset } = useForm<UpdateProfileMediaRequest>({
    mode: 'all',
    defaultValues: {
      employeeId: employeeId,
      profilePictureUrl,
      coverPictureUrl
    }
  });

  const handleToggleEditable = () => {
    setIsEditable(!isEditable);
  };

  const handleCancel = () => {
    dispatch(resetEmployeeProfilePictureFileMultipleSizes());
    dispatch(resetEmployeeProfileCoverPictureFileMultiSize());
    setIsEditable(false);
  };

  const handleSetEmployeeProfilePictureFile = (file: MultiSizeImageBlobs | undefined) => {
    if (file) {
      dispatch(setEmployeeProfilePictureFileMultipleSizes(file));
    } else {
      dispatch(setEmployeeProfilePictureFileMultipleSizes(undefined));
    }
  };

  const handleRemoveEmployeeProfilePictureFile = () => {
    dispatch(removeEmployeeProfilePictureFileMultipleSizes());
    reset({
      employeeId: employeeId,
      profilePictureUrl: ''
    });
  };

  const handleSetEmployeeProfileCoverFileMultipleSize = (file: MultiSizeImageBlobs | undefined) => {
    if (file) {
      dispatch(setEmployeeCoverPictureFileMultipleSizes(file));
    } else {
      dispatch(setEmployeeCoverPictureFileMultipleSizes(undefined));
    }
  };

  const handleRemoveEmployeeProfileCoverFile = () => {
    dispatch(removeEmployeeProfileCoverPictureFileMultiSize());
    reset({
      employeeId: employeeId,
      coverPictureUrl: ''
    });
  };

  const onSubmit = (data: UpdateProfileMediaRequest) => {
    dispatch(
      updateProfileMediaRequested(
        create(UpdateProfileMediaRequestSchema, {
          employeeId: employeeId,
          profilePictureUrl: data.profilePictureUrl,
          coverPictureUrl: data.coverPictureUrl
        })
      )
    );
    setIsEditable(false);
  };

  return (
    <StyledGenericForm isEditable={isEditable} id={snakeCase(name)} ref={ref}>
      <Box
        component="form"
        name={name}
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          backgroundColor: 'transparent'
        }}
      >
        <RowCenterStack
          sx={{
            padding: (theme) => theme.spacing(2),
            backgroundColor: (theme) => theme.palette.customTheme.headerColorSub,
            justifyContent: 'space-between'
          }}
        >
          <PrimaryTypographyHeaderTitleCase>{name}</PrimaryTypographyHeaderTitleCase>
          <RowCenterStack
            gap={1}
            sx={{
              display: isEditable ? 'none' : 'inline-flex'
            }}
          >
            <AllowedTo perform={GroupPermissions.EDIT_PROFILE} data={{ employeeId, categoryName: 'personal_branding' } as ProfileCategoryInfo}>
              <StyledErbButton variant="contained" startIcon={<EditPenIcon />} onClick={handleToggleEditable}>
                {t('edit', { ns: 'common' })}
              </StyledErbButton>
            </AllowedTo>
          </RowCenterStack>
          <RowCenterStack
            gap={2}
            sx={{
              display: isEditable ? 'flex' : 'none'
            }}
          >
            <PrimaryDarkButton variant="contained" onClick={handleCancel} startIcon={<CancelEditIcon />}>
              {t('cancel', { ns: 'common' })}
            </PrimaryDarkButton>
            <StyledFormSubmitButton type="submit" variant="contained">
              {t('done', { ns: 'common' })}
            </StyledFormSubmitButton>
          </RowCenterStack>
        </RowCenterStack>
        <Grid
          container
          rowSpacing={2}
          sx={{
            p: 2
          }}
        >
          <Grid size={12}>
            {employeeBasicInfoStatus === ActionStatus.Pending && <Skeleton height={50} />}
            {employeeBasicInfoStatus === ActionStatus.Idle && (
              <Controller
                name="profilePictureUrl"
                control={control}
                render={({ field }) => (
                  <Stack gap={2}>
                    <StyledFormLabel disabled shrink htmlFor={profilePictureId}>
                      {t('profile_image', { ns: 'employee_profile' })}
                    </StyledFormLabel>
                    <Box id={profilePictureId}>
                      <InlineImageUpload
                        isEditable={isEditable}
                        width={150}
                        height={150}
                        sm={1000}
                        md={1000}
                        lg={1000}
                        placeholderSrc={EMPLOYEE_PROFILE_PICTURE_PLACEHOLDER}
                        urlSrc={
                          field.value
                            ? pictureUrl(field.value, employeeProfileAndCoverReadSasToken, PictureSizeSuffix.lg)!
                            : EMPLOYEE_PROFILE_PICTURE_PLACEHOLDER
                        }
                        imageAltText="user profile image"
                        buttonTextLabel={t('upload_btn_txt', { ns: 'employee_profile' })}
                        uploadTextLabel={t('upload_copy', { ns: 'employee_profile' })}
                        removeTextLabel={t('remove_logo_txt', { ns: 'employee_profile' })}
                        multiSizeImage={employeeProfilePictureFileMultiSize}
                        onChange={handleSetEmployeeProfilePictureFile}
                        onRemove={handleRemoveEmployeeProfilePictureFile}
                      />
                    </Box>
                  </Stack>
                )}
              />
            )}
          </Grid>
          <Grid size={12}>
            {employeeBasicInfoStatus === ActionStatus.Pending && <Skeleton height={150} />}
            {employeeBasicInfoStatus === ActionStatus.Idle && (
              <Controller
                name="coverPictureUrl"
                control={control}
                render={({ field }) => (
                  <Stack
                    gap={2}
                    sx={{
                      '& img': {
                        objectFit: 'cover'
                      }
                    }}
                  >
                    <StyledFormLabel disabled shrink htmlFor={coverPictureId}>
                      {t('cover_image', { ns: 'employee_profile' })}
                    </StyledFormLabel>
                    <Box id={coverPictureId}>
                      <InlineImageUpload
                        isEditable={isEditable}
                        width={433}
                        height={220}
                        sm={500}
                        md={700}
                        lg={900}
                        placeholderSrc="/images/cover-image-placeholder.png"
                        urlSrc={
                          field.value
                            ? pictureUrl(field.value, employeeProfileAndCoverReadSasToken, PictureSizeSuffix.lg)!
                            : '/images/cover-image-placeholder.png'
                        }
                        imageAltText="user profile image"
                        buttonTextLabel={t('upload_cover_btn_txt', { ns: 'employee_profile' })}
                        uploadTextLabel={t('upload_cover_copy', { ns: 'employee_profile' })}
                        removeTextLabel={t('remove_cover_txt', { ns: 'employee_profile' })}
                        multiSizeImage={employeeProfileCoverFileMultiSize}
                        onChange={handleSetEmployeeProfileCoverFileMultipleSize}
                        onRemove={handleRemoveEmployeeProfileCoverFile}
                      />
                    </Box>
                  </Stack>
                )}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </StyledGenericForm>
  );
});

PersonalBrandingCategory.displayName = 'PersonalBrandingCategory';
