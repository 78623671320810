import React, { useState } from 'react';
import { CheckedIcon, RowCenterStack, StyledRenameInput, TableFilterIcon } from '@/shared';
import { Close } from '@mui/icons-material';
import { PeopleDirView, RenameSavedViewRequest, UpdatePeopleDirViewRequest } from '@thrivea/organization-client';
import { Box, Chip, IconButton } from '@mui/material';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import {
  FilterTab,
  ALL_DATA_ID,
  toggleVisibilityPeopleDirView,
  renameSavedViewRequested,
  selectActivePeopleDirViewId,
  selectIsViewChanged,
  setActivePeopleDirViewId,
  updatePeopleDirViewRequested
} from '@features/people-directory';
import { useTranslation } from 'react-i18next';
import { ALL_TASKS_ID } from '../tasks.service';
import { selectActiveTaskViewId, setActiveTaskId } from '..';

interface TasksTabProps {
  view: PeopleDirView;
}

export const TasksTab: React.FC<TasksTabProps> = ({ view }) => {
  const { t } = useTranslation(['common']);
  const dispatch = useAppDispatch();

  const activeTasksViewId = useAppSelector(selectActiveTaskViewId);
  const isViewChanged = useAppSelector((state) => selectIsViewChanged(state, view.id));

  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState(view.name);

  const handleTabClick = () => {
    dispatch(setActiveTaskId(view.id));
  };

  const handleTabDoubleClick = () => {
    setIsEditing(true);
  };

  const handleTabInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (event.relatedTarget && event.relatedTarget.tagName === 'BUTTON') {
      return;
    }

    setIsEditing(false);
  };

  const handleTabCheckedButtonClick = () => {
    dispatch(
      renameSavedViewRequested(
        new RenameSavedViewRequest({
          name: name,
          viewId: view.id
        })
      )
    );

    setIsEditing(false);
  };

  const handleHideTabButtonClick = () => {
    dispatch(toggleVisibilityPeopleDirView({ viewId: view.id, hide: true }));
  };

  const handleTabInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      dispatch(
        renameSavedViewRequested(
          new RenameSavedViewRequest({
            name: name,
            viewId: view.id
          })
        )
      );

      setIsEditing(false);
    }
  };

  const handleUpdateChipClick = (view: PeopleDirView) => {
    dispatch(
      updatePeopleDirViewRequested(
        new UpdatePeopleDirViewRequest({
          ...view,
          viewId: view.id
        })
      )
    );
  };

  return (
    <FilterTab
      isActive={activeTasksViewId === view.id}
      isEditable={isEditing}
      label={
        isEditing ? (
          <StyledRenameInput
            width={name.length * 10 - 10}
            value={name}
            onChange={(e) => setName(e.target.value)}
            onKeyDown={handleTabInputKeyDown}
            autoFocus
            size="small"
            onBlur={handleTabInputBlur}
            onFocus={(event) => {
              event.target.select();
            }}
            endAdornment={
              <IconButton onClick={handleTabCheckedButtonClick}>
                <CheckedIcon />
              </IconButton>
            }
          />
        ) : (
          <RowCenterStack gap={1}>
            {name}
            {isViewChanged && <Chip onClick={() => handleUpdateChipClick(view)} label="Update View" color="secondary" />}
          </RowCenterStack>
        )
      }
      onClick={handleTabClick}
      onDoubleClick={handleTabDoubleClick}
      icon={
        <Box
          className="Mui-DeleteFilterButton"
          sx={{
            opacity: 0,
            padding: 0,
            transition: (theme) =>
              theme.transitions.create('opacity', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen
              })
          }}
          onClick={handleHideTabButtonClick}
        >
          <Close />
        </Box>
      }
      iconPosition="end"
      sx={{
        position: 'relative',
        '&:hover': {
          '& .Mui-DeleteFilterButton': {
            display: 'flex',
            opacity: 1
          }
        }
      }}
    />
  );
};
