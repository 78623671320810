import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
  retrievePermissionGroupsRequested,
  retrievePermissionGroupsSucceeded,
  retrievePermissionGroupsFailed,
  retrievePermissionGroupByIdSucceeded,
  retrievePermissionGroupByIdFailed,
  retrievePermissionGroupByIdRequested,
  retrievePermissionGroupMembersByIdsRequested,
  updatePermissionGroupSucceeded,
  updatePermissionGroupFailed,
  updatePermissionGroupRequested,
  retrievePermissionGroupAutocompleteItemsSucceeded,
  retrievePermissionGroupAutocompleteItemsFailed,
  retrievePermissionGroupAutocompleteItemsRequested,
  retrievePermissionGroupSelectedEmployeesTotalCountSucceeded,
  retrievePermissionGroupSelectedEmployeesTotalCountFailed,
  retrievePermissionGroupSelectedEmployeesTotalCountRequested,
  retrievePermissionGroupMembersByIdsSucceeded,
  retrievePermissionGroupMembersByIdsFailed
} from '@features/admin-settings';
import {
  EmployeeResults,
  RetrieveEmployeesByIdsRequest,
  RetrieveEmployeesByQueryRequest,
  RetrieveEmployeesByQueryResponse,
  RetrieveEmployeesTotalCountBySelectedRequest,
  RetrieveEmployeesTotalCountBySelectedResponse
} from '@thrivea/organization-client';
import { PayloadAction } from '@reduxjs/toolkit';
import { retrieveEmployeesByIds, retrieveEmployeesByQuery, retrieveEmployeesTotalCountBySelected } from '@api/employees.api';
import {
  GroupItem,
  RetrieveGroupByIdRequest,
  RetrieveGroupByIdResponse,
  RetrieveGroupItemsRequest,
  RetrieveGroupItemsResponse,
  UpdateGroupRequest
} from '@thrivea/auth-client';
import { retrieveGroupById, retrieveGroupItems, updateGroup } from '@api/group.api';
import { t } from 'i18next';
import { showSuccess } from '@features/snackbar';
import * as Sentry from '@sentry/react';

function* retrievePermissionGroupsGenerator(action: PayloadAction<RetrieveGroupItemsRequest>) {
  try {
    const response: RetrieveGroupItemsResponse = yield call(retrieveGroupItems, action.payload);
    yield put(retrievePermissionGroupsSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrievePermissionGroupsFailed());
  }
}

function* retrievePermissionGroupEmployeesByQueryRequestedGenerator(action: PayloadAction<RetrieveEmployeesByQueryRequest>) {
  try {
    const response: RetrieveEmployeesByQueryResponse = yield call(retrieveEmployeesByQuery, action.payload);
    yield put(retrievePermissionGroupAutocompleteItemsSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrievePermissionGroupAutocompleteItemsFailed());
  }
}

function* retrievePermissionGroupSelectedEmployeesTotalCountRequestedGenerator(action: PayloadAction<RetrieveEmployeesTotalCountBySelectedRequest>) {
  try {
    const response: RetrieveEmployeesTotalCountBySelectedResponse = yield call(retrieveEmployeesTotalCountBySelected, action.payload);
    yield put(retrievePermissionGroupSelectedEmployeesTotalCountSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrievePermissionGroupSelectedEmployeesTotalCountFailed());
  }
}

function* retrievePermissionGroupByIdGenerator(action: PayloadAction<RetrieveGroupByIdRequest>) {
  try {
    const response: RetrieveGroupByIdResponse = yield call(retrieveGroupById, action.payload);
    yield put(retrievePermissionGroupByIdSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrievePermissionGroupByIdFailed());
  }
}

function* retrievePermissionGroupMembersByIdsRequestedGenerator(action: PayloadAction<RetrieveEmployeesByIdsRequest>) {
  try {
    const response: EmployeeResults = yield call(retrieveEmployeesByIds, action.payload);
    yield put(retrievePermissionGroupMembersByIdsSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrievePermissionGroupMembersByIdsFailed());
  }
}

function* updatePermissionGroupRequestedGenerator(action: PayloadAction<UpdateGroupRequest>) {
  try {
    const response: GroupItem = yield call(updateGroup, action.payload);
    const successMessage = t('permission_group_created', { ns: 'settings_permissions', name: response.name });
    yield put(updatePermissionGroupSucceeded(response));
    yield put(showSuccess(successMessage));
  } catch (error) {
    Sentry.captureException(error);
    yield put(updatePermissionGroupFailed());
  }
}

function* retrievePermissionGroupsWatcher() {
  yield takeLatest(retrievePermissionGroupsRequested.type, retrievePermissionGroupsGenerator);
}

function* retrievePermissionGroupEmployeesByQueryRequestedWatcher() {
  yield takeLatest(retrievePermissionGroupAutocompleteItemsRequested.type, retrievePermissionGroupEmployeesByQueryRequestedGenerator);
}

function* retrieveEmployeesTotalCountBySelectedWatcher() {
  yield takeLatest(retrievePermissionGroupSelectedEmployeesTotalCountRequested.type, retrievePermissionGroupSelectedEmployeesTotalCountRequestedGenerator);
}

function* retrievePermissionGroupByIdWatcher() {
  yield takeLatest(retrievePermissionGroupByIdRequested.type, retrievePermissionGroupByIdGenerator);
}

function* retrievePermissionGroupMembersByIdsRequestedWatcher() {
  yield takeLatest(retrievePermissionGroupMembersByIdsRequested.type, retrievePermissionGroupMembersByIdsRequestedGenerator);
}

function* updatePermissionGroupRequestedWatcher() {
  yield takeLatest(updatePermissionGroupRequested.type, updatePermissionGroupRequestedGenerator);
}

export function* permissionsSagas() {
  yield all([
    fork(retrievePermissionGroupsWatcher),
    fork(retrievePermissionGroupEmployeesByQueryRequestedWatcher),
    fork(retrieveEmployeesTotalCountBySelectedWatcher),
    fork(retrievePermissionGroupByIdWatcher),
    fork(retrievePermissionGroupMembersByIdsRequestedWatcher),
    fork(updatePermissionGroupRequestedWatcher)
  ]);
}
