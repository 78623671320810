import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { EmployeeRecord, ErbFieldValue, ErTemplateField, RetrieveEmployeeRecordRequest, UpdateErSectionRequest } from '@thrivea/organization-client';
import * as Sentry from '@sentry/react';
import { retrieveEmployeeRecord, updateErSection } from '@api/erp.api';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  getCalculatedValueAfterUpdate,
  retrieveEmployeeRecordFailed,
  retrieveEmployeeRecordRequested,
  retrieveEmployeeRecordSucceeded,
  selectErbFieldValues,
  updateCalculatedFields,
  updateErSectionFailed,
  updateErSectionRequested,
  updateErSectionSucceeded
} from '@features/employee-record-page';
import { selectErTemplateFieldsAllIds, selectErTemplateFieldsById, selectErTemplateSectionsByInitialId } from '@features/employee-record-builder';
import { showSuccess } from '@features/snackbar';
import { t } from 'i18next';
import { keys } from 'lodash';

function* retrieveEmployeeRecordRequestedGenerator(action: PayloadAction<RetrieveEmployeeRecordRequest>) {
  try {
    const response: EmployeeRecord = yield call(retrieveEmployeeRecord, action.payload);
    yield put(retrieveEmployeeRecordSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveEmployeeRecordFailed());
  }
}

function* updateErSectionRequestedGenerator(action: PayloadAction<UpdateErSectionRequest>) {
  const section = yield select(selectErTemplateSectionsByInitialId);
  const erTemplateFieldsAllIds: string[] = yield select(selectErTemplateFieldsAllIds);
  const erTemplateFieldsById: Record<string, ErTemplateField> = yield select(selectErTemplateFieldsById);
  const allErbFieldValues: ErbFieldValue[] = yield select(selectErbFieldValues);

  try {
    yield call(updateErSection, action.payload);

    yield put(updateErSectionSucceeded(action.payload));

    const changedCalculatedFieldValues: { erbFieldId: string; value: any } = {} as { erbFieldId: string; value: any };

    for (const fieldId of erTemplateFieldsAllIds) {
      const fieldData = erTemplateFieldsById[fieldId];

      // Skip non-scalar fields early
      if (fieldData.kind.case !== 'scalarField') continue;

      const field = fieldData.kind.value;
      const scalarField = field.erbScalarField;

      // Skip non-calculated fields early
      if (!scalarField || !scalarField.isCalculated) continue;

      const dependentFieldIds = scalarField.options!.kind.value!.dependsOnErbFieldIds;

      // Perform calculation only if a dependency is found
      if (action.payload.changedFieldValues.some((cf) => dependentFieldIds.includes(cf.erbFieldId))) {
        action.payload.changedFieldValues;

        changedCalculatedFieldValues[scalarField.id] = getCalculatedValueAfterUpdate(
          scalarField.options!.kind.value!,
          allErbFieldValues.filter((efv) => !action.payload.changedFieldValues.some((cf) => cf.erbFieldId === efv.erbFieldId)),
          action.payload.changedFieldValues
        );
      }
    }

    if (keys(changedCalculatedFieldValues).length > 0) {
      yield put(updateCalculatedFields(changedCalculatedFieldValues));
    }

    yield put(showSuccess(t('updated_section', { ns: 'employee_record', name: section[action.payload.erTemplateSectionId].name })));
  } catch (error) {
    Sentry.captureException(error);
    yield put(updateErSectionFailed());
  }
}

function* retrieveEmployeeRecordRequestedWatcher() {
  yield takeLatest(retrieveEmployeeRecordRequested.type, retrieveEmployeeRecordRequestedGenerator);
}

function* updateErSectionRequestedWatcher() {
  yield takeLatest(updateErSectionRequested.type, updateErSectionRequestedGenerator);
}

export function* employeeRecordSagas() {
  yield all([fork(retrieveEmployeeRecordRequestedWatcher), fork(updateErSectionRequestedWatcher)]);
}
