import { ListItem, ListItemButton, ListItemIcon, Typography } from '@mui/material';
import { Stack } from '@mui/material';
import { NavLink } from 'react-router';
import { SettingsIcon } from '@/shared';

interface NavSettingsListItemProps {
  isDisabled: boolean;
}

export const NavSettingsListItem: React.FC<NavSettingsListItemProps> = ({ isDisabled }) => (
  <ListItem
    sx={{
      padding: 0,
      mb: 1
    }}
  >
    <ListItemButton
      disabled={isDisabled}
      disableGutters
      component={NavLink}
      to="/settings"
      sx={{
        padding: 0,
        justifyContent: 'center',
        '&.active': {
          color: (theme) => theme.palette.customTheme.focusItem,
          '& path': {
            fill: (theme) => theme.palette.customTheme.focusItem
          }
        },
        opacity: 1
      }}
    >
      <Stack
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          '&:hover': {
            '& path': {
              fill: (theme) => theme.palette.secondary.main
            },
            '& .MuiLinkText': {
              transform: 'translateY(3px)',
              color: (theme) => theme.palette.secondary.main
            }
          }
        }}
      >
        <ListItemIcon
          className="MuiLinkIcon"
          sx={{
            minWidth: 'auto',
            '& svg': {
              height: '22px'
            },
            '& path': {
              fill: (theme) => theme.palette.common.black
            }
          }}
        >
          <SettingsIcon />
        </ListItemIcon>
        {/* <Typography
          className="MuiLinkText"
          variant="body2"
          sx={{
            transform: 'translateY(0)',
            transition: (theme) =>
              theme.transitions.create(['color', 'transform', 'width', 'height'], {
                easing: theme.transitions.easing.easeInOut,
                duration: theme.transitions.duration.standard
              })
          }}
        >
          Settings
        </Typography> */}
      </Stack>
    </ListItemButton>
  </ListItem>
);
