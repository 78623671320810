import React from 'react';

interface StarIconAttributes {
  width?: number;
  height?: number;
  color?: string;
  isStarred?: boolean;
}

export const StarIcon: React.FC<StarIconAttributes> = ({ width = 20, height = 20, color = '#251D38', isStarred = false }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20" fill={isStarred ? color : 'none'}>
      <path
        d="M9.4022 2.8778C9.59427 2.48869 9.69031 2.29413 9.82068 2.23197C9.93411 2.17789 10.0659 2.17789 10.1793 2.23197C10.3097 2.29413 10.4057 2.48869 10.5978 2.8778L12.42 6.56944C12.4767 6.68432 12.5051 6.74176 12.5465 6.78635C12.5832 6.82584 12.6272 6.85783 12.6761 6.88056C12.7313 6.90623 12.7947 6.91549 12.9214 6.93402L16.9975 7.5298C17.4267 7.59253 17.6413 7.6239 17.7406 7.72874C17.8271 7.81995 17.8677 7.94529 17.8512 8.06985C17.8323 8.21302 17.677 8.36436 17.3662 8.66702L14.4179 11.5387C14.326 11.6282 14.28 11.673 14.2503 11.7263C14.2241 11.7734 14.2072 11.8252 14.2008 11.8788C14.1934 11.9393 14.2043 12.0025 14.2259 12.129L14.9216 16.1851C14.995 16.6129 15.0317 16.8269 14.9627 16.9538C14.9028 17.0642 14.7961 17.1417 14.6725 17.1646C14.5305 17.1909 14.3384 17.0899 13.9542 16.8879L10.3103 14.9716C10.1968 14.9119 10.14 14.882 10.0802 14.8703C10.0272 14.8599 9.97277 14.8599 9.91982 14.8703C9.86001 14.882 9.80324 14.9119 9.6897 14.9716L6.04576 16.8879C5.66159 17.0899 5.4695 17.1909 5.32747 17.1646C5.20389 17.1417 5.09726 17.0642 5.03727 16.9538C4.96832 16.8269 5.00501 16.6129 5.07839 16.1851L5.77406 12.129C5.79575 12.0025 5.80659 11.9393 5.79926 11.8788C5.79276 11.8252 5.77592 11.7734 5.74966 11.7263C5.72001 11.673 5.67405 11.6282 5.58214 11.5387L2.63379 8.66702C2.32304 8.36436 2.16767 8.21302 2.14876 8.06985C2.13231 7.94529 2.17295 7.81995 2.25937 7.72874C2.35869 7.6239 2.5733 7.59253 3.00253 7.5298L7.07858 6.93402C7.20534 6.91549 7.26872 6.90623 7.32392 6.88056C7.37279 6.85783 7.41679 6.82584 7.45348 6.78635C7.49491 6.74176 7.52326 6.68432 7.57997 6.56944L9.4022 2.8778Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
