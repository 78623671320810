import { Button, ButtonProps, styled } from '@mui/material';

export const ButtonTextLink = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.customTheme.focusItem,
  padding: 0,
  margin: 0,
  fontSize: 14,
  fontWeight: 400,
  backgroundColor: 'transparent',
  textDecoration: 'underline',
  '&:hover': {
    backgroundColor: 'transparent',
    textDecoration: 'underline'
  }
}));
