import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { styled, typographyClasses } from '@mui/material';

export const CommentButton = styled(LoadingButton)<LoadingButtonProps>(({ theme }) => ({
  minWidth: 132,
  height: 31,
  padding: '16px 12px',
  backgroundColor: theme.palette.customTheme.selectItem,
  borderRadius: 8,
  marginBottom: '7px',
  [`& .${typographyClasses.root}`]: {
    color: theme.palette.common.black,
    fontWeight: 700
  }
}));
