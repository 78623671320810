import { Paper, PaperProps, styled } from '@mui/material';

export const AudienceGroupAutocompletePaper = styled(Paper)<PaperProps>(() => ({
  backgroundColor: 'transparent',
  px: '4px',
  paddingBottom: '4px',
  position: 'sticky',
  bottom: 1,
  '& .MuiAutocomplete-noOptions': { padding: '0 4px', margin: '16px 0' }
}));
