import { styled, Switch, SwitchProps } from '@mui/material';

export const PostScheduleSwitch = styled(Switch)<SwitchProps>(({ theme }) => ({
  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.customTheme.focusItem
      }
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: theme.palette.customTheme.focusItem
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.grey[100]
    }
  },
  '& .MuiSwitch-track': {
    backgroundColor: '#E9E9EA',
    transition: theme.transitions.create(['background-color'], {
      duration: 500
    })
  }
}));
