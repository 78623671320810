import React from 'react';

interface UploadIconProps {
  width?: number;
  height?: number;
  color?: string;
}

export const UploadIcon: React.FC<UploadIconProps> = ({ width: width = 24, height = 25, color = '#717680' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 24 25">
      <path
        d="M19.4156 14.2002V17.5105C19.4156 17.9495 19.2471 18.3705 18.947 18.6809C18.6469 18.9913 18.24 19.1657 17.8156 19.1657H6.61562C6.19128 19.1657 5.78431 18.9913 5.48425 18.6809C5.1842 18.3705 5.01562 17.9495 5.01562 17.5105V14.2002"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M16.2148 8.40674L12.2148 4.26881L8.21484 8.40674" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.2148 4.26916L12.2148 14.2002" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
