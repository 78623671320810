import { Button, ButtonProps, styled } from '@mui/material';
import React from 'react';

const StyledExtendableIconButton = styled(Button)<ButtonProps>(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minWidth: '40px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  padding: '5px 10px',
  fontSize: '0.8125rem',
  borderRadius: '7px',

  '& .MuiButton-startIcon': {
    margin: 0
  },

  '& .MuiButton-startIcon + span': {
    width: 0,
    overflow: 'hidden'
  },

  '&:hover': {
    '& .MuiButton-startIcon + span': {
      width: 'auto',
      marginLeft: '4px',
      // TODO: Transition is not working because of width: 'auto', we should think of better approach to animate this
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.standard
      })
    }
  }
}));

export const ExtendableIconButton: React.FC<ButtonProps> = ({ children, ...props }) => (
  <StyledExtendableIconButton {...props}>
    {/* TODO: This span is just a hack to enable selecting it in wrapper styled component, we should replace that */}
    <span>{children}</span>
  </StyledExtendableIconButton>
);
