import { create } from '@bufbuild/protobuf';
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import {
  addPaymentMethodFailed,
  addPaymentMethodRequested,
  addPaymentMethodSucceeded,
  changePrimaryPaymentMethodFailed,
  changePrimaryPaymentMethodRequested,
  changePrimaryPaymentMethodSucceeded,
  editInvoiceSettingsFailed,
  editInvoiceSettingsRequested,
  editInvoiceSettingsSucceeded,
  loadBillingFailed,
  LoadBillingInitially,
  loadBillingRequested,
  loadBillingSucceeded,
  LoadSubscriptionAndPaymentMethods,
  loadSubscriptionAndPaymentMethodsFailed,
  loadSubscriptionAndPaymentMethodsRequested,
  loadSubscriptionAndPaymentMethodsSucceeded
} from '@features/admin-settings';
import { selectCurrentUserTenantId } from '@features/shared';
import { retrieveEmployeesTotalCount } from '@api/employees.api';
import { Empty, EmptySchema } from '@bufbuild/protobuf/wkt';
import { retrieveTotalStorageUsage } from '@api/assets.api';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  addPaymentMethod,
  changePrimaryPaymentMethod,
  editInvoiceSettings,
  retrieveInvoices,
  retrieveInvoiceSettings,
  retrievePaymentMethods,
  retrieveSubscription
} from '@api/payment.api';
import { AddPaymentMethodRequest, ChangePrimaryPaymentMethodRequest, EditInvoiceSettingsRequest } from '@thrivea/payment-client';
import * as Sentry from '@sentry/react';
import { showSuccess, showWarning } from '@features/snackbar';
import { RetrieveTotalStorageUsageRequestSchema } from '@thrivea/organization-client';

function* loadSubscriptionAndPaymentMethodsRequestedGenerator() {
  try {
    const [subscription, paymentMethodsResponse] = yield all([
      call(retrieveSubscription, create(EmptySchema)),
      call(retrievePaymentMethods, create(EmptySchema))
    ]);

    const loadSubscriptionAndPaymentMethods: LoadSubscriptionAndPaymentMethods = {
      subscription,
      paymentMethodsResponse
    };

    yield put(loadSubscriptionAndPaymentMethodsSucceeded(loadSubscriptionAndPaymentMethods));
  } catch (error) {
    Sentry.captureException(error);
    yield put(loadSubscriptionAndPaymentMethodsFailed());
  }
}

function* loadBillingRequestedGenerator() {
  const tenantId: string = yield select(selectCurrentUserTenantId);

  try {
    const [employeesCountResponse, totalStorageUsageResponse, subscription, invoiceSettingsResponse, invoicesResponse, paymentMethodsResponse] = yield all([
      call(retrieveEmployeesTotalCount, create(EmptySchema)),
      call(retrieveTotalStorageUsage, create(RetrieveTotalStorageUsageRequestSchema, { tenantId })),
      call(retrieveSubscription, create(EmptySchema)),
      call(retrieveInvoiceSettings, create(EmptySchema)),
      call(retrieveInvoices, create(EmptySchema)),
      call(retrievePaymentMethods, create(EmptySchema))
    ]);

    const loadTasksInitially: LoadBillingInitially = {
      employeesCountResponse,
      totalStorageUsageResponse,
      subscription,
      invoiceSettingsResponse,
      invoicesResponse,
      paymentMethodsResponse
    };

    yield put(loadBillingSucceeded(loadTasksInitially));
  } catch (error) {
    Sentry.captureException(error);
    yield put(loadBillingFailed());
  }
}

function* editInvoiceSettingsRequestedGenerator(action: PayloadAction<EditInvoiceSettingsRequest>) {
  try {
    const response: Empty = yield call(editInvoiceSettings, action.payload);

    yield put(editInvoiceSettingsSucceeded());
    yield put(showSuccess('Invoice settings updated successfully'));
  } catch (error) {
    Sentry.captureException(error);
    yield put(editInvoiceSettingsFailed());
    yield put(showWarning('Failed to update invoice settings'));
  }
}

function* changePrimaryPaymentMethodRequestedGenerator(action: PayloadAction<ChangePrimaryPaymentMethodRequest>) {
  try {
    const response: Empty = yield call(changePrimaryPaymentMethod, action.payload);

    yield put(changePrimaryPaymentMethodSucceeded(action.payload));
    yield put(showSuccess('Primary payment method changed successfully'));
  } catch (error) {
    Sentry.captureException(error);
    yield put(changePrimaryPaymentMethodFailed());
    yield put(showWarning('Failed to change primary payment method'));
  }
}

function* addPaymentMethodRequestedGenerator(action: PayloadAction<AddPaymentMethodRequest>) {
  try {
    const response: Empty = yield call(addPaymentMethod, action.payload);

    const retrievePaymentMethodsResponse = yield call(retrievePaymentMethods, create(EmptySchema));
    yield put(addPaymentMethodSucceeded(retrievePaymentMethodsResponse.paymentMethods));
    yield put(showSuccess('Payment method added successfully'));
  } catch (error) {
    Sentry.captureException(error);
    yield put(addPaymentMethodFailed());
    yield put(showWarning('Failed to add payment method'));
  }
}

function* loadSubscriptionAndPaymentMethodsRequestedWatcher() {
  yield takeLatest(loadSubscriptionAndPaymentMethodsRequested.type, loadSubscriptionAndPaymentMethodsRequestedGenerator);
}

function* loadBillingRequestedWatcher() {
  yield takeLatest(loadBillingRequested.type, loadBillingRequestedGenerator);
}

function* editInvoiceSettingsRequestedWatcher() {
  yield takeLatest(editInvoiceSettingsRequested.type, editInvoiceSettingsRequestedGenerator);
}

function* changePrimaryPaymentMethodRequestedWatcher() {
  yield takeLatest(changePrimaryPaymentMethodRequested.type, changePrimaryPaymentMethodRequestedGenerator);
}

function* addPaymentMethodRequestedWatcher() {
  yield takeLatest(addPaymentMethodRequested.type, addPaymentMethodRequestedGenerator);
}

export function* billingSagas() {
  yield all([
    fork(loadSubscriptionAndPaymentMethodsRequestedWatcher),
    fork(loadBillingRequestedWatcher),
    fork(editInvoiceSettingsRequestedWatcher),
    fork(changePrimaryPaymentMethodRequestedWatcher),
    fork(addPaymentMethodRequestedWatcher)
  ]);
}
