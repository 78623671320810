import { styled } from '@mui/material';
import { TreeItem2Content } from '@mui/x-tree-view/TreeItem2';

export const AssetsTreeItemContent = styled(TreeItem2Content)(({ theme }) => ({
  width: '100%',
  height: 40,
  padding: '12px 8px',
  flexDirection: 'row-reverse',
  borderRadius: theme.spacing(0.7),
  margin: '0',
  color: theme.palette.common.black,
  fontSize: 16,
  fontWeight: 600,
  backgroundColor: 'transparent',
  transition: theme.transitions.create('background-color', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.standard
  }),
  '&.Mui-focused': {
    backgroundColor: theme.palette.customTheme.drawerBackground
  }
}));
