import { useEffect, useState } from 'react';
import { GridRenderCellParams, useGridApiRef } from '@mui/x-data-grid-pro';
import { Box, FormControl, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import {
  EmployeeStatusChip,
  PeopleDirectoryGridToolbar,
  PeopleDirectoryTablePersonEntityCell,
  PeopleDirectoryTableSiteEntityCell,
  TableTooltip,
  isPresentInEmployeeListItems,
  isPresentInSites,
  selectEmployeeRecordByEmployeeId,
  selectEmployeeRecords,
  selectEmployeeRecordsStatus,
  selectErbFieldsById,
  selectMemoizedVisibleColumns,
  selectPageNumber,
  selectSelectedEmployeeRowIds,
  updateSelectedEmployeeRowIds
} from '@features/people-directory';
import { EmployeeRecord, EmployeeStatus, EntityType, ErbScalarField, ErbTableField, GenericSetLocalizedOption } from '@thrivea/organization-client';
import { mapEmployeeRecordsToRows } from '@features/employee-record-page';
import {
  ActionStatus,
  DataGridNoResultsOverlay,
  DataGridNoRowsOverlay,
  PictureSizeSuffix,
  RowCenterStack,
  StyledAvatar,
  StyledDataGrid,
  StyledFixedWidthTypography
} from '@/shared';
import { initials, pictureUrl } from '@/utils';
import { selectEmployeeProfileAndCoverReadSasToken } from '@features/employee-profile';
import { DrawerType, OpenDrawerRequest, openDrawer } from '@features/drawer';
import { useTranslation } from 'react-i18next';
import { selectEmployeeListItemsById, selectSitesById } from 'src/features/shared';

const employeeStatusOptions = Object.keys(EmployeeStatus)
  .filter((key) => isNaN(Number(key))) // Exclude numeric keys
  .slice(1)
  .map((key) => ({
    value: EmployeeStatus[key as keyof typeof EmployeeStatus], // Enum value
    label: key.replace(/_/g, ' ').toLowerCase() // Human-readable label
  }));

export const PeopleDirectoryDataGrid = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const visibleColumns = useAppSelector(selectMemoizedVisibleColumns);
  const employeeRecords: EmployeeRecord[] = useAppSelector(selectEmployeeRecords);
  const erbFieldsById = useAppSelector(selectErbFieldsById);
  const employeeProfileSasToken = useAppSelector(selectEmployeeProfileAndCoverReadSasToken);
  const employeeRecordsStatus = useAppSelector(selectEmployeeRecordsStatus);
  const pageNumber = useAppSelector(selectPageNumber);
  const selectedEmployeeRowIds = useAppSelector(selectSelectedEmployeeRowIds);
  const sitesById = useAppSelector(selectSitesById);
  const employeeListItemsById = useAppSelector(selectEmployeeListItemsById);

  const apiRef = useGridApiRef();
  const [dataGridRows, setDataGridRows] = useState<Record<string, any>[]>([]);

  const [paginationModel, setPaginationModel] = useState({
    page: pageNumber || 0,
    pageSize: 50
  });

  useEffect(() => {
    const mappedRows = mapEmployeeRecordsToRows(employeeRecords, visibleColumns, erbFieldsById);
    setDataGridRows(mappedRows);
  }, [employeeRecords, visibleColumns, erbFieldsById]);

  const handlePaginationModelChange = (newModel: { page: number; pageSize: number }) => {
    setPaginationModel(newModel);
  };

  const handlePersonItemClick = (employeeId: string) => {
    dispatch(openDrawer({ type: DrawerType.SingleEmployee, request: { employeeId } } as OpenDrawerRequest));
  };

  const handleSiteLinkClick = (siteId: string) => {
    dispatch(openDrawer({ type: DrawerType.Site, request: { siteId } } as OpenDrawerRequest));
  };

  const columns = [
    {
      field: 'employee',
      headerName: 'Employee',
      width: 250,
      sortable: false,
      filterable: false,
      renderCell: (params: GridRenderCellParams) => {
        const employee = useAppSelector((state) => selectEmployeeRecordByEmployeeId(state, params.row.employeeId));
        if (!employee) return null;

        return (
          <RowCenterStack gap="28px" sx={{ height: '100%' }}>
            <StyledAvatar
              onClick={() => handlePersonItemClick(employee.employeeId)}
              src={pictureUrl(employee.profilePictureUrl, employeeProfileSasToken, PictureSizeSuffix.sm)}
              width={34}
              height={34}
            >
              {initials(employee.employeeName)}
            </StyledAvatar>
            <Stack>
              <Typography sx={{ fontWeight: 700, fontSize: 14 }} onClick={() => handlePersonItemClick(employee.employeeId)}>
                {employee.employeeName}
              </Typography>
              <Typography sx={{ fontSize: 12 }} onClick={() => handlePersonItemClick(employee.employeeId)}>
                {employee.jobTitle}
              </Typography>
            </Stack>
          </RowCenterStack>
        );
      }
    },
    {
      field: '11183939-f57c-49fe-8a25-48c4a30f3a41',
      headerName: 'Email Address',
      width: 250,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        const employee = useAppSelector((state) => selectEmployeeRecordByEmployeeId(state, params.row.employeeId));
        if (!employee) return null;

        return (
          <TableTooltip
            title={employee.emailAddress}
            children={
              <StyledFixedWidthTypography
                sx={{
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: 14
                }}
              >
                {employee.emailAddress}
              </StyledFixedWidthTypography>
            }
          />
        );
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      sortable: true,
      filterable: true,
      renderCell: (params: GridRenderCellParams) => {
        const employee = useAppSelector((state) => selectEmployeeRecordByEmployeeId(state, params.row.employeeId));
        if (!employee) return null;

        return (
          <Stack sx={{ width: '100%', height: '100%', alignItems: 'flex-start', justifyContent: 'center' }}>
            <EmployeeStatusChip label={t(EmployeeStatus[employee.status].toLowerCase(), { ns: 'common' })} status={employee.status} />
          </Stack>
        );
      },
      filterOperators: [
        {
          label: 'is',
          value: 'equals',
          getApplyFilterFn: (filterItem) => {
            if (!filterItem.value) {
              return null;
            }
            return (row) => {
              return row === filterItem.value;
            };
          },
          InputComponent: (props) => {
            const { item, applyValue } = props;

            const handleFilterChange = (event) => {
              applyValue({ ...item, value: event.target.value });
            };

            return (
              <FormControl
                sx={{
                  position: 'relative'
                }}
              >
                <InputLabel shrink id="employee_status" sx={{ fontWeight: 500, top: 5, left: '-10px' }}>
                  Value
                </InputLabel>
                <Select id="employee_status" value={item.value || ''} onChange={handleFilterChange} variant="standard" displayEmpty fullWidth label="Value">
                  <MenuItem value="">All</MenuItem>
                  {employeeStatusOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      <EmployeeStatusChip label={t(EmployeeStatus[option.value].toLowerCase(), { ns: 'common' })} status={option.value} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            );
          }
        }
      ]
    },
    ...visibleColumns
      .filter((vc) => vc.fieldId !== '11183939-f57c-49fe-8a25-48c4a30f3a41')
      .map((column) => {
        if (column.tableFieldId) {
          const erbTableField = erbFieldsById[column.tableFieldId].kind.value as ErbTableField;

          return {
            field: column.fieldId,
            headerName: column.name,
            width: 250,
            sortable: column.isSortable,
            filterable: false,
            renderCell: (params: GridRenderCellParams) => {
              const erbTableFieldColumn = erbTableField.columns.find((c) => c.id === column.fieldId)!;

              if (
                erbTableFieldColumn.type!.options!.kind.case === 'linkedEntities' &&
                erbTableFieldColumn.type!.options!.kind.value.entityType === EntityType.PERSON &&
                isPresentInEmployeeListItems(params.value, employeeListItemsById)
              ) {
                const employeeItem = employeeListItemsById[params.value as string];

                return (
                  <PeopleDirectoryTablePersonEntityCell
                    key={employeeItem.employeeId}
                    column={column}
                    handlePersonItemClick={() => handlePersonItemClick(employeeItem.employeeId)}
                    employeeItem={employeeItem}
                    employeeProfileSasToken={employeeProfileSasToken}
                  />
                );
              }

              if (
                erbTableFieldColumn.type!.options!.kind.case === 'linkedEntities' &&
                erbTableFieldColumn.type!.options!.kind.value.entityType === EntityType.SITE &&
                isPresentInSites(params.value, sitesById)
              ) {
                const site = sitesById[params.value as string];
                return (
                  <PeopleDirectoryTableSiteEntityCell
                    key={column.fieldId}
                    column={column}
                    site={site}
                    handleSiteLinkClick={() => handleSiteLinkClick(site.id)}
                  />
                );
              }

              // Default rendering
              return <span>{params.value ?? '-'}</span>;
            }
          };
        }
        const erbScalarField = erbFieldsById[column.fieldId].kind.value as ErbScalarField;

        return {
          field: column.fieldId,
          headerName: column.name,
          width: 250,
          sortable: column.isSortable,
          filterable: false,
          renderCell: (params: GridRenderCellParams) => {
            if (erbScalarField.type!.options!.kind.case === 'multipleCheckboxes') {
              const options = erbScalarField.type!.options!.kind.value.options as GenericSetLocalizedOption[];

              // Filter and map `params.value` if it's an array
              const values = Array.isArray(params.value) ? options.filter((o) => params.value.some((f) => f.key === o.key)) : ['-'];

              return (
                <span>
                  {values
                    .map((value) => (typeof value === 'string' ? value : value.label || value.translations['en'] || '-')) // Fallbacks if `label` or `translations.en` doesn't exist
                    .join(', ')}
                </span>
              );
            }

            if (
              erbScalarField.type!.options!.kind.case === 'linkedEntities' &&
              erbScalarField.type!.options!.kind.value.entityType === EntityType.PERSON &&
              isPresentInEmployeeListItems(params.value, employeeListItemsById)
            ) {
              const employeeItem = employeeListItemsById[params.value as string];

              return (
                <PeopleDirectoryTablePersonEntityCell
                  key={employeeItem.employeeId}
                  column={column}
                  handlePersonItemClick={() => handlePersonItemClick(employeeItem.employeeId)}
                  employeeItem={employeeItem}
                  employeeProfileSasToken={employeeProfileSasToken}
                />
              );
            }

            if (
              erbScalarField.type!.options!.kind.case === 'linkedEntities' &&
              erbScalarField.type!.options!.kind.value.entityType === EntityType.SITE &&
              isPresentInSites(params.value, sitesById)
            ) {
              const site = sitesById[params.value as string];
              return (
                <PeopleDirectoryTableSiteEntityCell key={column.fieldId} column={column} site={site} handleSiteLinkClick={() => handleSiteLinkClick(site.id)} />
              );
            }

            // Default rendering
            return <span>{params.value ?? '-'}</span>;
          }
        };
      })
  ];

  return (
    <Box sx={{ width: '100%', height: 'calc(100% - 110px)' }}>
      <StyledDataGrid
        apiRef={apiRef}
        pagination
        checkboxSelection
        disableRowSelectionOnClick
        disableColumnSelector
        disableDensitySelector
        hideFooterSelectedRowCount
        loading={employeeRecordsStatus === ActionStatus.Pending}
        rows={dataGridRows}
        columns={columns}
        getRowId={(row) => row.employeeId}
        isRowSelectable={(params) => {
          return dataGridRows.some((row) => row.employeeId === params.row.id);
        }}
        filterDebounceMs={500}
        paginationModel={paginationModel}
        onPaginationModelChange={handlePaginationModelChange}
        columnBufferPx={100}
        rowHeight={46}
        rowSelectionModel={selectedEmployeeRowIds}
        onRowSelectionModelChange={(params) => dispatch(updateSelectedEmployeeRowIds(params as string[]))}
        pageSizeOptions={[25, 50, 100, { value: -1, label: 'All' }]}
        slots={{
          toolbar: PeopleDirectoryGridToolbar,
          noRowsOverlay: DataGridNoRowsOverlay,
          noResultsOverlay: DataGridNoResultsOverlay
        }}
        slotProps={{
          loadingOverlay: {
            variant: 'skeleton',
            noRowsVariant: 'skeleton'
          },
          toolbar: {
            showQuickFilter: true
          },
          pagination: {
            showFirstButton: true,
            showLastButton: true
          }
        }}
        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
      />
    </Box>
  );
};
