import { Avatar, styled } from '@mui/material';

interface TaskAvatarProps {
  position?: number;
}

export const TaskAvatar = styled(
  ({ position, ...props }: TaskAvatarProps & React.ComponentProps<typeof Avatar>) => (
    <Avatar
      {...props}
      slotProps={{
        img: {
          loading: 'lazy',
          width: 24,
          height: 24
        }
      }}
    />
  ),
  {
    shouldForwardProp: (prop) => prop !== 'position'
  }
)(({ theme, position }) => ({
  width: 24,
  height: 24,
  border: `1px solid ${theme.palette.customTheme.contentBorder}`,
  borderRadius: '50%',
  position: 'relative',
  cursor: 'pointer',
  fontSize: 12
}));
