import { SvgIcon } from '@mui/material';

interface IconSortNeutralProps {
  // define props here
  width?: number;
  height?: number;
  color?: string;
}

export const IconSortNeutral: React.FC<IconSortNeutralProps> = ({ width = 24, height = 24, color = '#251D38' }) => {
  return (
    <SvgIcon>
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
        <path d="M9 7.99207L13.2426 3.74942L17.4853 7.99207" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M17.4853 16.9626L13.2426 21.2053L9 16.9626" stroke={color} strokeOpacity="0.45" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </SvgIcon>
  );
};
