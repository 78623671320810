import { Stack, StackProps, styled } from '@mui/material';

export const AudienceNewGroupHoverStack = styled(Stack)<StackProps>(({ theme }) => ({
  width: '100%',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  padding: 2,
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-30px)',
    '& .Mui-GroupButton': {
      backgroundColor: theme.palette.customTheme.drawerBackground
    },
    '& .Mui-GroupDesc': {
      opacity: 1
    },
    '& path': {
      fill: theme.palette.customTheme.focusItem
    }
  }
}));
