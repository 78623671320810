import { IconButton, iconButtonClasses, IconButtonProps, styled } from '@mui/material';

interface TasksCollapseButtonProps extends IconButtonProps {
  isCollapsed?: boolean;
}

export const TasksCollapseButton = styled(({ isCollapsed, ...props }: TasksCollapseButtonProps) => <IconButton {...props} />, {
  shouldForwardProp: (prop) => prop !== 'isCollapsed'
})(({ isCollapsed }) => ({
  padding: 0,
  display: 'flex',
  flexDirection: 'row',
  gap: '8px',
  '& svg': {
    transform: `rotate(${isCollapsed ? 180 : 0}deg)`
  }
}));
