import { createClient } from '@connectrpc/connect';
import { createGrpcWebTransport } from '@connectrpc/connect-web';
import {
  CreatePeopleDirViewRequest,
  DeletePeopleDirViewRequest,
  EmployeeService,
  RenameSavedViewRequest,
  RetrieveAllPeopleDirCategoriesSectionsFieldsResponse,
  RetrieveEmployeeRecordsRequest,
  RetrieveEmployeeRecordsResponse,
  RetrievePeopleDirViewsRequest,
  RetrievePeopleDirViewsResponse,
  UpdatePeopleDirViewRequest
} from '@thrivea/organization-client';
import { msalInstance } from '@/main';
import { tokenRequest } from '@utils/authConfig';
import { Empty } from '@bufbuild/protobuf/wkt';

const peopleDirectoryClient = createClient(
  EmployeeService,
  createGrpcWebTransport({
    baseUrl: import.meta.env.VITE_APP_API_URL,
    useBinaryFormat: true
  })
);

export const retrieveEmployeeRecords = async (request: RetrieveEmployeeRecordsRequest): Promise<RetrieveEmployeeRecordsResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await peopleDirectoryClient.retrieveEmployeeRecords(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveAllPeopleDirCategoriesSectionsFields = async (request: Empty): Promise<RetrieveAllPeopleDirCategoriesSectionsFieldsResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await peopleDirectoryClient.retrieveAllPeopleDirCategoriesSectionsFields(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const createPeopleDirView = async (request: CreatePeopleDirViewRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await peopleDirectoryClient.createPeopleDirView(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrievePeopleDirViews = async (request: RetrievePeopleDirViewsRequest): Promise<RetrievePeopleDirViewsResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await peopleDirectoryClient.retrievePeopleDirViews(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const renameSavedView = async (request: RenameSavedViewRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await peopleDirectoryClient.renameSavedView(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const updatePeopleDirView = async (request: UpdatePeopleDirViewRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await peopleDirectoryClient.updatePeopleDirView(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const deletePeopleDirView = async (request: DeletePeopleDirViewRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await peopleDirectoryClient.deletePeopleDirView(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};
