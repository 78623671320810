import { Chip, chipClasses, ChipProps, styled } from '@mui/material';
import { CardType } from './CardTypeContent';

interface BillingCardTypeStyleProps {
  backgroundColor: string;
  textColor: string;
}

interface BillingCardTypeProps extends ChipProps {
  cardType: CardType;
}

export const BillingCardType = styled(Chip)<BillingCardTypeProps>(({ cardType, theme }) => {
  const row: BillingCardTypeStyleProps[] = [
    { backgroundColor: '#64C9FF', textColor: theme.palette.primary.main },
    { backgroundColor: '#FF9864', textColor: theme.palette.primary.main },
    { backgroundColor: '#47ECA1', textColor: theme.palette.primary.main }
  ];

  let idx = CardType.Visa;
  if (cardType === CardType.MasterCard) idx = CardType.MasterCard;
  if (cardType === CardType.AmericanExpress) idx = CardType.AmericanExpress;

  return {
    backgroundColor: row[idx].backgroundColor,
    color: row[idx].textColor,
    maxWidth: 117,
    height: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '3px 7px',
    borderRadius: 8,
    [`& .${chipClasses.label}`]: {
      fontSize: 12,
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: 'normal',
      padding: 0
    }
  };
});
