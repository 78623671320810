import { Typography, FormControlLabel, PaperProps } from '@mui/material';
import { RowCenterStack, StyledGroupCheckbox } from '@/shared';
import { AudienceGroupAutocompletePaper, AudienceGroupAutocompletePaperStack } from '@features/admin-settings';

// props are passed in autocomplete trough slotProps: { paper }, this way rerender of this paper component is prevented and it does not cause the scroll to top behavior if option is checked: https://github.com/mui/material-ui/issues/29508

export interface CustomAudienceGroupAutocompletePaperProps extends PaperProps {
  sitesSelected?: boolean;
  jobTitlesSelected?: boolean;
  handleSitesChecked?: () => void;
  handleJobTitlesChecked?: () => void;
  searchText: string;
}

export const CustomAudienceGroupAutocompletePaper = (paperProps) => (
  <AudienceGroupAutocompletePaper
    elevation={0}
    onMouseDown={(event) => {
      event.preventDefault();
    }}
  >
    {paperProps.children}
    {paperProps.searchText.length >= 2 && (
      <AudienceGroupAutocompletePaperStack gap={4}>
        <Typography>Displaying results for:</Typography>
        <RowCenterStack gap={2}>
          <FormControlLabel
            control={
              <StyledGroupCheckbox
                checked
                disabled
                onMouseDown={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                }}
              />
            }
            label="Employees"
          />
          <FormControlLabel
            onChange={paperProps.handleJobTitlesChecked}
            control={
              <StyledGroupCheckbox
                checked={paperProps.jobTitlesSelected}
                onMouseDown={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                }}
              />
            }
            label="Job Titles"
          />
          <FormControlLabel
            onChange={paperProps.handleSitesChecked}
            control={
              <StyledGroupCheckbox
                checked={paperProps.sitesSelected}
                onMouseDown={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                }}
              />
            }
            label="Sites"
          />
        </RowCenterStack>
      </AudienceGroupAutocompletePaperStack>
    )}
  </AudienceGroupAutocompletePaper>
);
