import { Box, Collapse, MenuItem, SelectChangeEvent, Stack, Typography } from '@mui/material';
import { TasksTabs } from './TasksTabs';
import { TaskViewModeTabs } from './TaskViewModeTabs';
import { ActionsIcon, ChevronDownIcon, FilterIcon, RowCenterStack, SearchIcon, StyledFilledInput, StyledSelect } from '@/shared';
import { Dropdown } from './styled/Dropdown';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { selectActivePeopleDirViewCategories, selectCategoryListItems, selectPartiallySelectedCategories } from '@/features/people-directory';
import {
  changeTasksGroupBy,
  NewTaskButton,
  selectIsActiveViewSingleTaskView,
  selectTasksGroupBy,
  TasksDropdownSearch,
  TasksFromToDateFilter,
  TasksGroupBy,
  TasksHeaderIconButton,
  TasksViewsTabs,
  TaskVewFilterDropdown
} from '@features/tasks';
import { DrawerType, openDrawer, OpenDrawerRequest } from '@features/drawer';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { debounce, snakeCase } from 'lodash';
import { EmployeeListItem, SearchExpression, TaskType } from '@thrivea/organization-client';
import Grid from '@mui/material/Grid2';

const searchExpressions = Object.entries(SearchExpression)
  .filter(([_key, value]) => typeof value === 'number')
  .slice(1)
  .map(([key, _value]) => {
    const id = snakeCase(key); // Convert to snake_case
    const name = id;
    return { id, name };
  });

const mockAssignees = [
  {
    employeeId: 'c91b3e58-e6d4-4f8e-a0c5-6e1c929dc001',
    displayName: 'John Doe',
    jobTitle: 'Software Engineer',
    emailAddress: 'john.doe@example.com',
    siteId: 'f39b7a88-ae3d-4b7f-b8c2-1f7e29cc1001',
    siteName: 'Headquarters',
    profilePictureUrl: 'https://example.com/images/john_doe.jpg'
  },
  {
    employeeId: 'd52f7c11-b3e2-4b6f-a123-5b9f9aa94002',
    displayName: 'Jane Smith',
    jobTitle: 'Product Manager',
    emailAddress: 'jane.smith@example.com',
    siteId: '9a12f3b7-4ae2-417e-bc43-2dc7a91f4002',
    siteName: 'Remote Office',
    profilePictureUrl: 'https://example.com/images/jane_smith.jpg'
  },
  {
    employeeId: 'b873cd12-8391-495b-98dc-6c2f8e2a7003',
    displayName: 'Alice Johnson',
    jobTitle: 'UX Designer',
    emailAddress: 'alice.johnson@example.com',
    siteId: 'f39b7a88-ae3d-4b7f-b8c2-1f7e29cc1001',
    siteName: 'Headquarters',
    profilePictureUrl: 'https://example.com/images/alice_johnson.jpg'
  },
  {
    employeeId: 'fa23b7e1-1b6f-4a9d-ae25-8f9b7ad45004',
    displayName: 'Bob Brown',
    jobTitle: 'DevOps Engineer',
    emailAddress: 'bob.brown@example.com',
    siteId: '1e7f9c8b-dfe2-43ab-bc98-2e4f0ac71003',
    siteName: 'Regional Hub',
    profilePictureUrl: 'https://example.com/images/bob_brown.jpg'
  },
  {
    employeeId: '93c12df7-b3b4-49c8-9adc-5c7b4fa64005',
    displayName: 'Emma Wilson',
    jobTitle: 'Marketing Specialist',
    emailAddress: 'emma.wilson@example.com',
    siteId: '9a12f3b7-4ae2-417e-bc43-2dc7a91f4002',
    siteName: 'Remote Office',
    profilePictureUrl: 'https://example.com/images/emma_wilson.jpg'
  }
] as EmployeeListItem[];

export const TasksHeader = () => {
  const { t } = useTranslation(['common, tasks']);
  const dispatch = useAppDispatch();

  const groupBy = useAppSelector(selectTasksGroupBy);
  const isActiveViewSingleTaskView = useAppSelector(selectIsActiveViewSingleTaskView);
  // temp
  const categoryListItems = useAppSelector(selectCategoryListItems);
  const activePeopleDirViewCategories = useAppSelector(selectActivePeopleDirViewCategories);
  const partiallySelectedCategories = useAppSelector(selectPartiallySelectedCategories);

  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isFilterRowVisible, setIsFilterRowVisible] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchExpression, setSearchExpression] = useState(searchExpressions[0].id);

  const [taskTypeFilterOptionsState] = useState<TaskType[]>([TaskType.TASK_TYPE_UNSPECIFIED, TaskType.SINGLE_TASK, TaskType.TASK_LIST]);

  const taskTypeFilterOptions = taskTypeFilterOptionsState.map((type) => ({
    id: type.toString(),
    name: type.toString()
  }));

  const employeesFilterOptions = mockAssignees.map((assignee) => ({
    id: assignee.employeeId,
    name: assignee.displayName
  }));

  const debouncedSearch = useCallback(
    debounce((value) => {
      console.log(value);
    }, 500),
    []
  );

  useEffect(() => {
    debouncedSearch(searchText);

    // Cleanup the debounced function
    return () => {
      debouncedSearch.cancel();
    };
  }, [searchText, debouncedSearch]);

  const handleGroupByChange = (e: SelectChangeEvent<string>) => {
    dispatch(changeTasksGroupBy(e.target.value as unknown as number));
  };

  const handleTaskTypeSelect = (selectedOption: { id: string; name: string }, isEnabled: boolean) => {
    // dispatch(toggleTaskTypeFilterOption({id, isEnabled}))
  };

  const handleTextChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const handleSearchExpressionChange = (event: SelectChangeEvent<unknown>, child?: React.ReactNode) => {
    setSearchExpression(event.target.value as string);
  };

  const handleTaskAssigneeSelect = (selectedOption: { id: string; name: string }, isEnabled: boolean): void => {
    // dispatch(filterByAssignees())
    console.log(selectedOption);
  };

  const handleTaskReviewerSelect = (selectedOption: { id: string; name: string }, isEnabled: boolean): void => {
    // throw new Error('Function not implemented.');
  };

  const handleTaskStatusSelect = () => {};

  const handleTaskOwnerSelect = () => {};

  const handleTaskDueDateSelect = () => {};

  return (
    <Stack
      gap={2}
      sx={{
        padding: 2,
        borderRadius: 4,
        border: '1px solid',
        borderColor: (theme) => theme.palette.customTheme.borderMaster,
        backgroundColor: (theme) => theme.palette.customTheme.bgr,
        zIndex: (theme) => theme.zIndex.appBar
      }}
    >
      {!isActiveViewSingleTaskView && (
        <RowCenterStack sx={{ justifyContent: 'space-between' }}>
          <RowCenterStack gap="10px">
            <TasksTabs />
            <TasksViewsTabs />
          </RowCenterStack>
        </RowCenterStack>
      )}
      <Collapse in={isFilterRowVisible}>
        <Grid container spacing={2}>
          <Grid size={2}>
            <TasksDropdownSearch options={categoryListItems} values={[]} label={'Type'} onOptionSelect={handleTaskTypeSelect} />
          </Grid>
          <Grid size={2}>
            <TasksDropdownSearch options={categoryListItems} values={[]} label={'Status'} onOptionSelect={handleTaskStatusSelect} />
          </Grid>
          <Grid size={2}>
            <TasksDropdownSearch options={categoryListItems} values={[]} label={'Owner'} onOptionSelect={handleTaskOwnerSelect} />
          </Grid>
          <Grid size={2}>
            <TasksDropdownSearch options={employeesFilterOptions} values={[]} label={'Assignee'} onOptionSelect={handleTaskAssigneeSelect} />
          </Grid>
          <Grid size={2}>
            <TasksDropdownSearch options={employeesFilterOptions} values={[]} label={'Reviewer'} onOptionSelect={handleTaskReviewerSelect} />
          </Grid>
          <Grid size={2}>
            <TasksFromToDateFilter label="Due Date" />
          </Grid>
        </Grid>
      </Collapse>
      <RowCenterStack sx={{ justifyContent: 'space-between' }}>
        <RowCenterStack gap={1}>
          <TaskViewModeTabs />
          <Dropdown
            value={groupBy}
            onChange={handleGroupByChange}
            variant="outlined"
            IconComponent={ChevronDownIcon}
            renderValue={(value) => {
              return (
                <RowCenterStack gap={'4px'} sx={{ height: 20 }}>
                  <Typography sx={{ fontWeight: 400, fontSize: 12 }}>Group by |</Typography>
                  {value === TasksGroupBy.DueDate && <Typography sx={{ fontWeight: 700, fontSize: 12 }}>{t('group_by_due_date', { ns: 'tasks' })}</Typography>}
                  {value === TasksGroupBy.Status && <Typography sx={{ fontWeight: 700, fontSize: 12 }}>{t('group_by_status', { ns: 'tasks' })}</Typography>}
                </RowCenterStack>
              );
            }}
          >
            <MenuItem sx={{ borderRadius: '8px' }} value={TasksGroupBy.DueDate}>
              {t('group_by_due_date', { ns: 'tasks' })}
            </MenuItem>
            <MenuItem sx={{ borderRadius: '8px' }} value={TasksGroupBy.Status}>
              {t('group_by_status', { ns: 'tasks' })}
            </MenuItem>
          </Dropdown>
        </RowCenterStack>
        <RowCenterStack gap={'10px'}>
          <Collapse in={isSearchOpen}>
            <RowCenterStack gap={'4px'}>
              <StyledSelect
                onChange={handleSearchExpressionChange}
                value={searchExpression}
                IconComponent={() => {
                  return (
                    <Box className="MuiSvg-icon">
                      <ChevronDownIcon />
                    </Box>
                  );
                }}
                variant="outlined"
              >
                {searchExpressions.map((se) => (
                  <MenuItem key={se.id} value={se.id}>
                    {t(`${se.name}`, { ns: 'tasks' })}
                  </MenuItem>
                ))}
              </StyledSelect>
              <Box sx={{ width: 249 }}>
                <StyledFilledInput label="Search" value={searchText} onChange={(e) => handleTextChange(e)} />
              </Box>
            </RowCenterStack>
          </Collapse>
          <TasksHeaderIconButton onClick={() => setIsSearchOpen(!isSearchOpen)}>
            <SearchIcon />
          </TasksHeaderIconButton>
          <TasksHeaderIconButton onClick={() => setIsFilterRowVisible(!isFilterRowVisible)}>
            <FilterIcon />
          </TasksHeaderIconButton>
          <TaskVewFilterDropdown />
          <NewTaskButton
            onClick={() => {
              dispatch(openDrawer({ type: DrawerType.TasksAction } as OpenDrawerRequest));
            }}
            startIcon={<ActionsIcon color="#FFFFFF" />}
          >
            {t('new_task', { ns: 'tasks' })}
          </NewTaskButton>
        </RowCenterStack>
      </RowCenterStack>
    </Stack>
  );
};
