import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import commonEN from '@locales/en/common.json';
import landingPageEN from '@locales/en/landing_page.json';
import homePageEN from '@locales/en/homepage.json';
import settingsInfoEN from '@locales/en/settings_info.json';
import settingsSitesEN from '@locales/en/settings_sites.json';
import settingsCalendarsEN from '@locales/en/settings_calendars.json';
import settingsWorkingPatternLibraryEN from '@locales/en/settings_working-patterns.json';
import settingsPeopleDataFieldsEN from '@locales/en/settings_people-data.json';
import settingsGroupsEN from '@locales/en/settings_groups.json';
import settingsImportEN from '@locales/en/settings_imports.json';
import settingsBillingEN from '@locales/en/settings_billing.json';
import settingsPlansEN from '@locales/en/settings_plan.json';
import onboardingEN from '@locales/en/onboarding.json';
import employeeEN from '@locales/en/employee_profile.json';
import salaryPayFrequencyEN from '@locales/en/salary_pay_frequency_enum.json';
import salaryPayPeriodEN from '@locales/en/salary_pay_period_enum.json';
import emergencyContactRelationEN from '@locales/en/emergency_contact_relation_enum.json';
import employmentContractTypeEN from '@locales/en/employment_contract_type_enum.json';
import salaryPayTypeEN from '@locales/en/salary_pay_type.json';
import flsaCodesEN from '@locales/en/flsa_codes_enum.json';
import accountTypesEN from '@locales/en/account_types_enum.json';
import maritalStatusEN from '@locales/en/marital_status_enum.json';
import workChangeTypeEN from '@locales/en/work_change_type.json';
import userStatusEN from '@locales/en/user_status_enum.json';
import permissionEN from '@locales/en/settings_permissions.json';
import audienceEN from '@locales/en/settings_audience.json';
import drawerEN from '@locales/en/drawer.json';
import assetsEN from '@locales/en/assets.json';
import employeeRecordEN from '@locales/en/employee_record.json';
import tasksEN from '@locales/en/tasks.json';

const resources = {
  en: {
    common: commonEN,
    landing_page: landingPageEN,
    homepage: homePageEN,
    settings_info: settingsInfoEN,
    settings_sites: settingsSitesEN,
    settings_calendars: settingsCalendarsEN,
    settings_working_patterns: settingsWorkingPatternLibraryEN,
    settings_people_data: settingsPeopleDataFieldsEN,
    settings_groups: settingsGroupsEN,
    settings_imports: settingsImportEN,
    settings_permissions: permissionEN,
    settings_audience: audienceEN,
    settings_billing: settingsBillingEN,
    settings_plans: settingsPlansEN,
    onboarding: onboardingEN,
    employee_profile: employeeEN,
    salary_pay_frequency: salaryPayFrequencyEN,
    salary_pay_period: salaryPayPeriodEN,
    emergency_contact_relation: emergencyContactRelationEN,
    employment_contract_type: employmentContractTypeEN,
    salary_pay_type: salaryPayTypeEN,
    flsa_codes: flsaCodesEN,
    account_types: accountTypesEN,
    marital_status: maritalStatusEN,
    work_change_type: workChangeTypeEN,
    user_status: userStatusEN,
    drawer: drawerEN,
    assets: assetsEN,
    employee_record: employeeRecordEN,
    tasks: tasksEN
  }
};

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    fallbackLng: 'en',
    debug: true,
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json'
    },
    detection: {
      order: ['htmlTag', 'cookie', 'localStorage', 'path', 'subdomain'],
      caches: ['cookie']
    },
    // ns: [
    //   'common',
    //   'homepage',
    //   'employee_profile',
    //   'settings_main',
    //   'settings_info',
    //   'settings_calendars',
    //   'settings_sites',
    //   'settings_working_patterns',
    //   'settings_people_data',
    //   'settings_imports',
    //   'settings_groups',
    //   'settings_permissions'
    // ],
    defaultNS: 'common',
    interpolation: {
      escapeValue: false
    }
  });
