import { Tabs, tabsClasses } from '@mui/material';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { changeTasksViewMode, selectTasksViewMode, TaskViewMode } from '@features/tasks';
import { FilterTab } from '@features/people-directory';
import { RowCenterStack, TableColumnsIcon, TableListIcon } from '@/shared';
import { SyntheticEvent } from 'react';

export const TaskViewModeTabs = () => {
  const dispatch = useAppDispatch();

  const listOrBoard = useAppSelector(selectTasksViewMode);

  const handleTaskViewTabsChange = (_event: SyntheticEvent, newValue: TaskViewMode) => {
    dispatch(changeTasksViewMode(newValue));
  };

  return (
    <Tabs
      value={listOrBoard}
      variant="scrollable"
      scrollButtons="auto"
      onChange={handleTaskViewTabsChange}
      sx={{
        minHeight: 43,
        borderRadius: 2,
        maxWidth: '90%',
        justifyContent: 'left',
        [`& .${tabsClasses.flexContainer}`]: {
          gap: '8px'
        }
      }}
    >
      <FilterTab
        value={TaskViewMode.List}
        label={
          <RowCenterStack gap={1}>
            <TableListIcon />
            List View
          </RowCenterStack>
        }
      />
      <FilterTab
        value={TaskViewMode.Board}
        label={
          <RowCenterStack gap={1}>
            <TableColumnsIcon />
            Board
          </RowCenterStack>
        }
      />
      {/* <FilterTab
        disabled
        value={TaskViewMode.Timeline}
        label={
          <RowCenterStack gap={1}>
            <TimelineIcon disabled />
            Timeline
          </RowCenterStack>
        }
      <FilterTab
      />
        disabled
        value={TaskViewMode.Calendar}
        label={
          <RowCenterStack gap={1}>
            <CalendarDaysIcon disabled />
            Calendar
          </RowCenterStack>
        }
      /> */}
      {/* <FilterTab value={TaskViewMode.SingleTask} label={'Single Task View'} /> */}
    </Tabs>
  );
};
