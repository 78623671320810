import React from 'react';

interface CircleFilledIconProps {
  width?: number;
  height?: number;
  color?: string;
}

export const CircleFilledIcon: React.FC<CircleFilledIconProps> = ({ width = 5, height = 5, color = '#251D38' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 5 5" fill="none">
      <path d="M2.5 5C3.88071 5 5 3.88071 5 2.5C5 1.11929 3.88071 0 2.5 0C1.11929 0 0 1.11929 0 2.5C0 3.88071 1.11929 5 2.5 5Z" fill={color} />
    </svg>
  );
};
