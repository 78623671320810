import { SvgIcon } from '@mui/material';

interface IconSortDownProps {
  // define props here
  width?: number;
  height?: number;
  color?: string;
}

export const IconSortDown: React.FC<IconSortDownProps> = ({ width = 24, height = 24, color = '#251D38' }) => {
  return (
    <SvgIcon>
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
        <path
          d="M8.80029 7.99207L13.0429 3.74942L17.2856 7.99207"
          stroke={color}
          strokeOpacity="0.45"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M17.2856 16.9626L13.0429 21.2053L8.80029 16.9626" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </SvgIcon>
  );
};
