import { Stack, Box, Typography, FormControl, InputLabel, IconButton, MenuItem, CircularProgress } from '@mui/material';
import Grid from '@mui/material/Grid2';
import {
  RowCenterStack,
  VisuallyHidden,
  EditIcon,
  CancelEditIcon,
  StyledSelect,
  ChevronDownIcon,
  StyledFormWrapper,
  PrimaryDarkButton,
  StyledFormHeader,
  StyledFormSubmitButton,
  StyledFormLabel,
  ActionStatus
} from '@/shared';
import { ForwardedRef, forwardRef, useEffect, useId, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useAppSelector } from '@app/hooks';
import {
  createEEOCategorySchema,
  selectEEOCategory,
  selectEthnicities,
  selectJobCategories,
  selectProfileNotification,
  updateEEORequested
} from '@features/employee-profile';
import { Eeo, UpdateEeoRequest } from '@thrivea/organization-client';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { camelCase, snakeCase } from 'lodash';
import { zodResolver } from '@hookform/resolvers/zod';
import { AllowedTo } from 'react-abac';
import { GroupPermissions, ProfileCategoryInfo } from '@features/abac';

interface EEOCategoryProps {
  ref: ForwardedRef<HTMLDivElement>;
  employeeId: string;
}

export const EEOCategory = forwardRef<HTMLDivElement, EEOCategoryProps>(({ employeeId }, ref) => {
  const id = useId();
  const { t } = useTranslation(['common', 'employee_profile']);
  const name = t('eeo_category_title', { ns: 'employee_profile' });
  const [isEditable, setIsEditable] = useState(false);
  const dispatch = useDispatch();
  const { status, category } = useAppSelector(selectProfileNotification);
  const isPendingAndMatchingName = status === ActionStatus.Pending && category === camelCase(name);
  const ethnicities = useAppSelector(selectEthnicities);
  const jobCategories = useAppSelector(selectJobCategories);
  const eeoCategory = useAppSelector(selectEEOCategory);
  const eeoCategorySchema = useMemo(() => createEEOCategorySchema(t), [t]);

  const {
    control,
    getValues,
    handleSubmit,
    reset,
    formState: { dirtyFields, errors }
  } = useForm<Eeo>({
    mode: 'all',
    resolver: zodResolver(eeoCategorySchema),
    defaultValues: {
      ethnicityId: eeoCategory.ethnicityId,
      jobCategoryId: eeoCategory.jobCategoryId
    }
  });

  const onSubmit = (data: Eeo) => {
    setIsEditable(false);
    dispatch(
      updateEEORequested({
        employeeId: employeeId,
        ethnicityId: data.ethnicityId,
        jobCategoryId: data.jobCategoryId
      } as UpdateEeoRequest)
    );
  };

  const handleToggleEditable = () => {
    setIsEditable(!isEditable);
  };

  const handleCloseEditable = () => {
    setIsEditable(false);
    // Maybe needs eeoCategory
    reset({
      ethnicityId: eeoCategory.ethnicityId,
      jobCategoryId: eeoCategory.jobCategoryId
    });
  };

  useEffect(() => {
    reset({
      ethnicityId: eeoCategory.ethnicityId,
      jobCategoryId: eeoCategory.jobCategoryId
    });
  }, [eeoCategory]);

  return (
    <StyledFormWrapper isEditable={isEditable} id={snakeCase(name)} ref={ref}>
      <Box
        component="form"
        name={name}
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          backgroundColor: 'transparent'
        }}
      >
        <Stack>
          <StyledFormHeader isEditable={isEditable} className="Mui-ProfileFiledHeader">
            <RowCenterStack gap={1}>
              {isPendingAndMatchingName && <CircularProgress size={24} thickness={4} />}
              <Typography component="h3" variant="h5" fontWeight={700}>
                {name}
              </Typography>
            </RowCenterStack>
            <AllowedTo perform={GroupPermissions.EDIT_PROFILE} data={{ employeeId, categoryName: 'payroll' } as ProfileCategoryInfo}>
              <IconButton
                onClick={handleToggleEditable}
                sx={{
                  opacity: '0',
                  display: isEditable ? 'none' : 'inline-flex'
                }}
              >
                <VisuallyHidden>Edit {name}</VisuallyHidden>
                <EditIcon />
              </IconButton>
            </AllowedTo>
            <RowCenterStack
              gap={2}
              sx={{
                display: isEditable ? 'flex' : 'none'
              }}
            >
              <PrimaryDarkButton variant="contained" onClick={handleCloseEditable} startIcon={<CancelEditIcon />}>
                {t('cancel', { ns: 'common' })}
              </PrimaryDarkButton>
              <StyledFormSubmitButton
                type="submit"
                disabled={Object.values(dirtyFields).length === 0 || Object.values(errors).length !== 0}
                variant="contained"
              >
                {t('done', { ns: 'common' })}
              </StyledFormSubmitButton>
            </RowCenterStack>
          </StyledFormHeader>

          <Grid
            container
            spacing={2}
            sx={{
              padding: 2
            }}
          >
            <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
              <Controller
                name="ethnicityId"
                control={control}
                render={({ field }) => (
                  <FormControl
                    fullWidth
                    sx={{
                      position: 'relative'
                    }}
                  >
                    <StyledFormLabel shrink disabled={!isEditable} htmlFor={id + field.name}>
                      {t(snakeCase(field.name), { ns: 'employee_profile' })}
                    </StyledFormLabel>
                    <StyledSelect
                      {...field}
                      value={getValues('ethnicityId') ?? 'none'}
                      disabled={!isEditable}
                      inputProps={{ id: id + field.name }}
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                      IconComponent={() => {
                        return (
                          <Box className="MuiSvg-icon">
                            <ChevronDownIcon />
                          </Box>
                        );
                      }}
                    >
                      <MenuItem value="none" disabled>
                        {t('select', { ns: 'common' })}
                      </MenuItem>
                      {ethnicities &&
                        ethnicities.map((ethnicity) => (
                          <MenuItem key={ethnicity.id} value={ethnicity.id}>
                            {ethnicity.name}
                          </MenuItem>
                        ))}
                    </StyledSelect>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
              <Controller
                name="jobCategoryId"
                control={control}
                render={({ field }) => (
                  <FormControl
                    fullWidth
                    sx={{
                      position: 'relative'
                    }}
                  >
                    <InputLabel
                      shrink
                      disabled={!isEditable}
                      htmlFor="job_categories"
                      sx={{
                        fontSize: '12px',
                        top: '14px',
                        left: '-2px'
                      }}
                    >
                      {t('job_categories', { ns: 'employee_profile' })}
                    </InputLabel>
                    <StyledSelect
                      {...field}
                      value={getValues('jobCategoryId') ?? 'none'}
                      disabled={!isEditable}
                      inputProps={{ id: 'job_categories' }}
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                      IconComponent={() => {
                        return (
                          <Box className="MuiSvg-icon">
                            <ChevronDownIcon />
                          </Box>
                        );
                      }}
                      variant="outlined"
                      sx={{
                        '.MuiSelect-select.MuiSelect-outlined.Mui-disabled MuiInputBase-input.MuiOutlinedInput-input': {
                          padding: '10px 0'
                        }
                      }}
                    >
                      <MenuItem value="none" disabled>
                        {t('select', { ns: 'common' })}
                      </MenuItem>
                      {jobCategories &&
                        jobCategories.map((jobCategory) => (
                          <MenuItem key={jobCategory.id} value={jobCategory.id}>
                            {jobCategory.name}
                          </MenuItem>
                        ))}
                    </StyledSelect>
                  </FormControl>
                )}
              />
            </Grid>
          </Grid>
        </Stack>
      </Box>
    </StyledFormWrapper>
  );
});
