import { styled, ListItemButton } from '@mui/material';

export const EmployeeItemButton = styled(ListItemButton)<{ selected: boolean }>(({ theme, selected }) => ({
  height: 40,
  padding: 0,
  border: 0,
  borderRadius: '4px',
  backgroundColor: selected ? theme.palette.customTheme.drawerBackground : 'transparent',
  margin: selected ? 0 : undefined,
  transition: theme.transitions.create('border-color, background-color', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  })
}));
