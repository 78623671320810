import { createClient } from '@connectrpc/connect';
import { createGrpcWebTransport } from '@connectrpc/connect-web';
import {
  ChangeTaskOwnerRequest,
  CommentOnTaskRequest,
  CreateSingleTaskRequest,
  CreateTaskListRequest,
  CreateTasksViewRequest,
  DeleteSingleTaskRequest,
  DeleteTaskCommentRequest,
  DeleteTaskListRequest,
  DeleteTasksViewRequest,
  EditTaskCommentRequest,
  EditTaskListRequest,
  EditTaskRequest,
  ReactToTaskCommentRequest,
  ReactToTaskRequest,
  RenameTasksViewRequest,
  RetrieveSingleTaskRelationsResponse,
  RetrieveSingleTaskRequest,
  RetrieveSingleTaskResponse,
  RetrieveSingleTasksForTaskListResponse,
  RetrieveSingleTasksInTaskListResponse,
  RetrieveTaskListRelationsResponse,
  RetrieveTasksForDependenciesResponse,
  RetrieveTasksRequest,
  RetrieveTasksResponse,
  RetrieveTasksViewsRequest,
  RetrieveTasksViewsResponse,
  SingleTaskId,
  SingleTaskIdWithEmployeeId,
  TaskListId,
  TasksService,
  UpdateTasksViewRequest,
  WithdrawTaskCommentReactionRequest,
  WithdrawTaskReactionRequest
} from '@thrivea/organization-client';
import { msalInstance } from '@/main';
import { tokenRequest } from '@/utils';
import { Empty } from '@bufbuild/protobuf/wkt';

const tasksClient = createClient(
  TasksService,
  createGrpcWebTransport({
    baseUrl: import.meta.env.VITE_APP_API_URL,
    useBinaryFormat: true
  })
);

export const retrieveTasks = async (request: RetrieveTasksRequest): Promise<RetrieveTasksResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await tasksClient.retrieveTasks(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveTasksViews = async (request: RetrieveTasksViewsRequest): Promise<RetrieveTasksViewsResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await tasksClient.retrieveTasksViews(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const createSingleTask = async (request: CreateSingleTaskRequest): Promise<SingleTaskId> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await tasksClient.createSingleTask(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const createTaskView = async (request: CreateTasksViewRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await tasksClient.createTasksView(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const renameTasksView = async (request: RenameTasksViewRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await tasksClient.renameTasksView(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const deleteTasksView = async (request: DeleteTasksViewRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await tasksClient.deleteTasksView(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const updateTasksView = async (request: UpdateTasksViewRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await tasksClient.updateTasksView(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveTasksForDependencies = async (request: Empty): Promise<RetrieveTasksForDependenciesResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await tasksClient.retrieveTasksForDependencies(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveSingleTasksForTaskList = async (request: Empty): Promise<RetrieveSingleTasksForTaskListResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await tasksClient.retrieveSingleTasksForTaskList(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const deleteSingleTask = async (request: DeleteSingleTaskRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await tasksClient.deleteSingleTask(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveSingleTask = async (request: RetrieveSingleTaskRequest): Promise<RetrieveSingleTaskResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await tasksClient.retrieveSingleTask(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const reactToTask = async (request: ReactToTaskRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await tasksClient.reactToTask(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const withdrawTaskReaction = async (request: WithdrawTaskReactionRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await tasksClient.withdrawTaskReaction(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const commentOnTask = async (request: CommentOnTaskRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await tasksClient.commentOnTask(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const reactToTaskComment = async (request: ReactToTaskCommentRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await tasksClient.reactToTaskComment(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const withdrawTaskCommentReaction = async (request: WithdrawTaskCommentReactionRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await tasksClient.withdrawTaskCommentReaction(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const editTaskComment = async (request: EditTaskCommentRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await tasksClient.editTaskComment(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const deleteTaskComment = async (request: DeleteTaskCommentRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await tasksClient.deleteTaskComment(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const createTaskList = async (request: CreateTaskListRequest): Promise<TaskListId> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await tasksClient.createTaskList(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const editTaskList = async (request: EditTaskListRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await tasksClient.editTaskList(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const deleteTaskList = async (request: DeleteTaskListRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await tasksClient.deleteTaskList(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveSingleTaskRelations = async (request: SingleTaskId): Promise<RetrieveSingleTaskRelationsResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await tasksClient.retrieveSingleTaskRelations(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveTaskListRelations = async (request: TaskListId): Promise<RetrieveTaskListRelationsResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await tasksClient.retrieveTaskListRelations(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const markTaskAsCompleted = async (request: SingleTaskIdWithEmployeeId): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await tasksClient.markTaskAsCompleted(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const sendTaskToReviewer = async (request: SingleTaskIdWithEmployeeId): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await tasksClient.sendTaskToReviewer(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const approveTask = async (request: SingleTaskIdWithEmployeeId): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await tasksClient.approveTask(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const returnToReviewer = async (request: SingleTaskIdWithEmployeeId): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await tasksClient.returnToReviewer(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const returnTaskToAssignee = async (request: SingleTaskIdWithEmployeeId): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await tasksClient.returnTaskToAssignee(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const editTask = async (request: EditTaskRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await tasksClient.editTask(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const archiveTask = async (request: SingleTaskId): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await tasksClient.archiveTask(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveSingleTasksInTaskList = async (request: TaskListId): Promise<RetrieveSingleTasksInTaskListResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await tasksClient.retrieveSingleTasksInTaskList(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const changeTaskOwner = async (request: ChangeTaskOwnerRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await tasksClient.changeTaskOwner(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};
