import { Reaction } from '@thrivea/networking-client';
import { DateTime } from 'luxon';

export const EMPTY_EDITOR = '<p><br></p>';

export interface CommentModel {
  id: string;
  text: string;
  authorId: string;
  commentTime: DateTime;
  editedTime?: DateTime;
  reactions: Record<string, Reaction[]>;
}

export interface PostCommentModel {
  postId: string;
  commentId: string;
}

export type PostModel = ShoutoutModel | KudosModel | PollModel;

export interface ShoutoutModel {
  id: string;
  title: string;
  text: string;
  mediaUrls: string[];
  docUrls: string[];
  authorId: string;
  commentIds: string[];
  audienceIds: string[];
  publishTime: DateTime;
  reactions: Record<string, Reaction[]>;
  editedTime?: DateTime;
  case: 'shoutout';
  isImportant: boolean;
  hasReadConfirmation: boolean;
}

export interface KudosModel {
  id: string;
  title: string;
  reason: string;
  commentIds: string[];
  audienceIds: string[];
  authorId: string;
  publishTime: DateTime;
  reactions: Record<string, Reaction[]>;
  case: 'kudos';
}

export interface PollModel {
  id: string;
  title: string;
  question: string;
  options: string[];
  commentIds: string[];
  audienceIds: string[];
  authorId: string;
  publishTime: DateTime;
  reactions: Record<string, Reaction[]>;
  case: 'poll';
}

export interface PostMedia {
  src: string;
  alt: string;
  name?: string;
}

export interface PostDoc {
  src: string;
  alt: string;
  name: string;
}

export interface PostContentModel {
  message: string;
  files: PostMedia[];
  docs: string[];
}

export interface PostAnalytic {
  id: string;
  time: string;
}
