import { create } from '@bufbuild/protobuf';
import { Box, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import {
  PostView,
  retrievePostsRequested,
  selectCurrentPostsPage,
  selectHasMorePosts,
  selectPostFeedIds,
  selectPostReadSasToken,
  PostViewReadConfirmation,
  selectPostsById,
  selectFeedPostsStatus,
  PostModel,
  PostSkeleton
} from '@features/homepage';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { useTranslation } from 'react-i18next';
import { init } from 'emoji-mart';
import data from '@emoji-mart/data/sets/14/apple.json';
import { createRef, useCallback, useEffect, useRef, useState } from 'react';
import { ActionStatus, AlertIcon, RowCenterStack } from 'src/shared';
import { DateTime } from 'luxon';
import { PostIdWithTime, PostIdWithTimeSchema, SendPostAnalyticsRequestSchema } from '@thrivea/networking-analytics-client';
import { selectCurrentEmployeeId } from '@features/shared';
import { sendPostAnalytics } from '@api/network-analytics.api';
import { DrawerType, OpenDrawerRequest, openDrawer } from '@features/drawer';
import * as Sentry from '@sentry/react';
import { fromPairs } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { RetrievePostsRequestSchema } from '@thrivea/networking-client';

// init emoji mart data
init({ data });

export const PostFeed = () => {
  const { t } = useTranslation(['homepage']);
  const dispatch = useAppDispatch();
  const postsById = useAppSelector(selectPostsById);
  const postIds = useAppSelector(selectPostFeedIds);
  const postsStatus = useAppSelector(selectFeedPostsStatus);

  const postReadSasToken = useAppSelector(selectPostReadSasToken);
  const employeeId = useAppSelector(selectCurrentEmployeeId);
  const observer = useRef<IntersectionObserver>();
  const postsObserver = useRef<IntersectionObserver>();
  const bottomElementRef = useRef<HTMLDivElement>(null);
  const postRefs = fromPairs(postIds.map((id) => [id, createRef<HTMLDivElement>()])) as Record<string, React.RefObject<HTMLDivElement>>;

  const hasMore = useAppSelector(selectHasMorePosts);
  const pageNumber = useAppSelector(selectCurrentPostsPage);
  const [hasRetrievedAllPosts, setHasRetrievedAllPosts] = useState<boolean>(false);

  const handleScroll = useCallback(() => {
    if (hasMore) {
      dispatch(retrievePostsRequested(create(RetrievePostsRequestSchema, { pageNumber: pageNumber + 1, pageSize: 10 })));
    } else {
      setHasRetrievedAllPosts(true);
    }
  }, [hasMore, pageNumber]);

  const dispatchActionToService = async (posts: PostIdWithTime[], employeeId: string) => {
    try {
      await sendPostAnalytics(
        create(SendPostAnalyticsRequestSchema, {
          posts,
          employeeId
        })
      );

      localStorage.setItem('viewedPosts', '[]');
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    const worker = new Worker(new URL('@app/workers/posts_worker.ts', import.meta.url), { type: 'module' });

    if (postIds && employeeId) {
      // Handle messages from the web worker
      worker.onmessage = (event) => {
        const { type } = event.data;
        if (type === 'SEND_POST_ANALYTICS') {
          // Dispatch action to service with stored IDs
          // Check if there are any new IDs to send
          const viewedPosts = JSON.parse(localStorage.getItem('viewedPosts') || '[]');
          if (viewedPosts.length > 0) {
            dispatchActionToService(viewedPosts, employeeId);
          }
        }
      };

      // Request stored IDs from the main thread every 15 seconds
      const sendIdsToWorker = () => {
        const ids = JSON.parse(localStorage.getItem('viewedPosts') || '[]'); // or sessionStorage
        worker.postMessage({ type: 'SEND_IDS', data: ids });
      };

      // Initial request and set up interval
      sendIdsToWorker();
      intervalId = setInterval(sendIdsToWorker, 15000);
    }

    // Clean up the worker and interval on component unmount
    return () => {
      worker.terminate();
      clearInterval(intervalId);
    };
  }, [postIds, employeeId]);

  useEffect(() => {
    postsObserver.current = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting && postsObserver.current) {
            const postId = entry.target.id;
            const viewedAt = DateTime.utc().toISO();

            storeIdInLocalStorage(create(PostIdWithTimeSchema, { postId, viewedAt }));
            postsObserver.current.unobserve(entry.target);
          }
        }
      },
      {
        threshold: 0.5
      }
    );

    for (const id of postIds) {
      const postRef = postRefs[id].current;

      if (postsObserver.current && postRef) {
        postsObserver.current.observe(postRef);
      }
    }

    //cleanup
    return () => {
      for (const id of postIds) {
        const postRef = postRefs[id].current;

        if (postsObserver.current && postRef) {
          postsObserver.current.unobserve(postRef);
        }
      }
    };
  }, [postRefs, postIds]);

  useEffect(() => {
    observer.current = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          handleScroll();
        }
      },
      { threshold: 1 } // Trigger the observer when the target is 100% visible
    );

    const currentElement = bottomElementRef.current;
    const currentObserver = observer.current;

    // Start observing the target element
    if (currentElement && currentObserver) {
      currentObserver.observe(currentElement);
    }

    return () => {
      // Clean up the observer and scroll event listener when the component unmounts
      if (currentObserver) {
        currentObserver.disconnect();
      }
    };
  }, [handleScroll]);

  useEffect(() => {
    const mentions = document.querySelectorAll('.mention');

    for (const mention of mentions) {
      mention.addEventListener('click', (e) => {
        e.stopPropagation();
        e.stopImmediatePropagation();

        if (e.currentTarget instanceof Element) {
          const employeeId = e.currentTarget.getAttribute('data-id')!;
          dispatch(openDrawer({ type: DrawerType.SingleEmployee, request: { employeeId } } as OpenDrawerRequest));
        }
      });
    }
  }, [postIds]);

  const storeIdInLocalStorage = (post: PostIdWithTime) => {
    const viewedPosts = JSON.parse(localStorage.getItem('viewedPosts') || '[]') as PostIdWithTime[];
    const postExists = viewedPosts.some((viewedPost) => viewedPost.postId === post.postId);

    if (!postExists) {
      viewedPosts.push(post);
      localStorage.setItem('viewedPosts', JSON.stringify(viewedPosts));
    }
  };

  return (
    <>
      <Grid
        container
        gap={'20px 0'}
        sx={{
          position: 'relative'
        }}
      >
        {postsStatus === ActionStatus.Idle && postIds.length === 0 && (
          <RowCenterStack gap={1} sx={{ padding: 2, width: '100%' }}>
            <AlertIcon />
            <Typography fontWeight={600}>{t('blank_posts', { ns: 'homepage' })}</Typography>
          </RowCenterStack>
        )}
        {postIds.map((id) => (
          <PostFeedItem key={id} post={postsById[id]} postRefs={postRefs} postReadSasToken={postReadSasToken} />
        ))}
        {postsStatus === ActionStatus.Idle && hasRetrievedAllPosts && postIds.length > 0 && (
          <RowCenterStack gap={1} sx={{ padding: 2, width: '100%' }}>
            <AlertIcon />
            <Typography fontWeight={600}>{t('all_posts_retrieved', { ns: 'homepage' })}</Typography>
          </RowCenterStack>
        )}
        {postsStatus === ActionStatus.Pending && (
          <Grid size={12}>
            <Stack gap={2}>
              {[...Array(3)].map(() => (
                <PostSkeleton key={uuidv4()} />
              ))}
            </Stack>
          </Grid>
        )}
      </Grid>
      <div ref={bottomElementRef} style={{ width: '100%', height: '50px' }}></div>
    </>
  );
};

interface PostFeedItemProps {
  post: PostModel;
  postRefs: Record<string, React.RefObject<HTMLDivElement>>;
  postReadSasToken: string;
}

const PostFeedItem = ({ post, postRefs, postReadSasToken }: PostFeedItemProps): JSX.Element => (
  <Grid size={12} key={post.id}>
    {post.case === 'shoutout' && (
      <Box ref={postRefs[post.id]} id={post.id}>
        <PostViewReadConfirmation requiresReadConfirmation={post.hasReadConfirmation} postId={post.id} authorId={post.authorId} />
        <PostView
          postId={post.id}
          date={post.publishTime}
          authorId={post.authorId}
          message={post.text}
          files={post.mediaUrls.map((mu) => ({
            alt: 'Media',
            src: new URL(postReadSasToken, mu).toString(),
            name: mu
          }))}
          docs={post.docUrls.map((du) => ({
            alt: 'Document',
            src: new URL(postReadSasToken, du).toString(),
            name: du
          }))}
          audienceId={post.audienceIds[0]}
          editedTime={post.editedTime}
          isImportant={post.isImportant}
        />
      </Box>
    )}
  </Grid>
);
