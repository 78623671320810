import { createBrowserRouter, Navigate, redirect, RouterProvider } from 'react-router';
import { CssBaseline, GlobalStyles, responsiveFontSizes, ThemeProvider, useTheme } from '@mui/material';
import { AbacProvider } from 'react-abac';
import { AudiencePermissions, selectAbacRules, selectAbacUser } from '@features/abac';
import { SignalRProvider } from '@app/contexts';
import { useAppSelector } from '@app/hooks';
import { linkedinTheme } from '@utils/themes';
import { UnexpectedAppErrorPage, ForbiddenPage, NotFoundPage, InternalServerErrorPage } from '@app/error';

const router = createBrowserRouter(
  [
    {
      path: '/',
      errorElement: <UnexpectedAppErrorPage />,
      lazy: async () => {
        const { ProtectedRoutes } = await import('@features/protected-routes');
        return { Component: ProtectedRoutes };
      },
      children: [
        {
          path: '',
          lazy: async () => {
            const { Homepage } = await import('@features/homepage');
            return { Component: Homepage };
          }
        },
        {
          path: 'employee-record-builder',
          lazy: async () => {
            const { EmployeeRecordBuilderPage } = await import('@features/employee-record-builder');
            return { Component: EmployeeRecordBuilderPage };
          }
        },
        {
          path: 'employee-record',
          lazy: async () => {
            const { EmployeeRecordPage } = await import('@features/employee-record-page');
            return { Component: EmployeeRecordPage };
          },
          children: [
            {
              path: ':employeeId',
              lazy: async () => {
                const { EmployeeRecordPage } = await import('@features/employee-record-page');
                return { Component: EmployeeRecordPage };
              }
            }
          ]
        },
        {
          path: '/settings',
          lazy: async () => {
            const { AdminSettingsPage } = await import('@features/admin-settings');
            return { Component: AdminSettingsPage };
          },
          children: [
            {
              path: '',
              index: true,
              element: <Navigate replace to="my-company" />
            },
            {
              path: 'my-company',
              lazy: async () => {
                const { AdminSettingsCompany } = await import('@features/admin-settings');
                return { Component: AdminSettingsCompany };
              }
            },
            {
              path: 'sites',
              lazy: async () => {
                const { AdminSettingsSites } = await import('@features/admin-settings');
                return { Component: AdminSettingsSites };
              },
              children: [
                {
                  path: 'create',
                  lazy: async () => {
                    const { AdminSettingsCreateSite } = await import('@features/admin-settings');
                    return { Component: AdminSettingsCreateSite };
                  }
                },
                {
                  path: ':siteId',
                  lazy: async () => {
                    const { AdminSettingsSiteDetail } = await import('@features/admin-settings');
                    return { Component: AdminSettingsSiteDetail };
                  }
                },
                {
                  path: '',
                  lazy: async () => {
                    const { AdminSettingsSiteList } = await import('@features/admin-settings');
                    return { Component: AdminSettingsSiteList };
                  }
                }
              ]
            },
            {
              path: 'working-patterns',
              lazy: async () => {
                const { AdminSettingsWorkingPatternsLibrary } = await import('@features/admin-settings');
                return { Component: AdminSettingsWorkingPatternsLibrary };
              }
            },
            {
              path: 'audiences',
              lazy: async () => {
                const { AdminSettingsAudienceGroupsPage } = await import('@features/admin-settings');
                return { Component: AdminSettingsAudienceGroupsPage };
              },
              children: [
                {
                  path: 'create',
                  lazy: async () => {
                    const { AdminSettingsAudienceGroupCreate } = await import('@features/admin-settings');
                    return { Component: AdminSettingsAudienceGroupCreate };
                  }
                },
                {
                  path: ':audienceId',
                  lazy: async () => {
                    const { AdminSettingsEditAudienceGroup } = await import('@features/admin-settings');
                    return { Component: AdminSettingsEditAudienceGroup };
                  }
                },
                {
                  path: '',
                  lazy: async () => {
                    const { AdminSettingsAudienceGroupsList } = await import('@features/admin-settings');
                    return { Component: AdminSettingsAudienceGroupsList };
                  }
                }
              ]
            },
            {
              path: 'permissions',
              lazy: async () => {
                const { AdminSettingsPermissionsPage } = await import('@features/admin-settings');
                return { Component: AdminSettingsPermissionsPage };
              },
              children: [
                {
                  path: ':permissionGroupId',
                  lazy: async () => {
                    const { AdminSettingsPermissionGroupDetails } = await import('@features/admin-settings');
                    return { Component: AdminSettingsPermissionGroupDetails };
                  }
                },
                {
                  path: '',
                  lazy: async () => {
                    const { AdminSettingsPermissionGroupsList } = await import('@features/admin-settings');
                    return { Component: AdminSettingsPermissionGroupsList };
                  }
                }
              ]
            },
            {
              path: 'import',
              lazy: async () => {
                const { AdminSettingsImportPeople } = await import('@features/admin-settings');
                return { Component: AdminSettingsImportPeople };
              }
            },
            {
              path: 'builder',
              lazy: async () => {
                const { AdminSettingsBuilderPage } = await import('@features/admin-settings');
                return { Component: AdminSettingsBuilderPage };
              }
            },
            {
              path: 'billing',
              lazy: async () => {
                const { AdminSettingsBillingPage } = await import('@features/admin-settings');
                return { Component: AdminSettingsBillingPage };
              }
            }
          ]
        },
        {
          path: 'people-directory',
          lazy: async () => {
            const { PeopleDirectoryPage } = await import('@features/people-directory');
            return { Component: PeopleDirectoryPage };
          }
        },
        {
          path: 'assets',
          lazy: async () => {
            const { AssetsPage } = await import('@features/assets');
            return { Component: AssetsPage };
          },
          children: [
            {
              path: '',
              lazy: async () => {
                const { AssetsMain } = await import('@features/assets');
                return { Component: AssetsMain };
              }
            },
            {
              path: 'trash-bin',
              lazy: async () => {
                const { AssetsTrashBinMain } = await import('@features/assets');
                return { Component: AssetsTrashBinMain };
              }
            },
            {
              path: 'shared-with-me',
              lazy: async () => {
                const { AssetsSharedWithMeMain } = await import('@features/assets');
                return { Component: AssetsSharedWithMeMain };
              }
            },
            {
              path: 'my-uploads',
              lazy: async () => {
                const { AssetsMyUploadsMain } = await import('@features/assets');
                return { Component: AssetsMyUploadsMain };
              }
            }
          ]
        },
        {
          path: 'operations',
          lazy: async () => {
            const { TasksPage } = await import('@features/tasks');
            return { Component: TasksPage };
          },
          children: [
            {
              path: '',
              lazy: async () => {
                const { TasksMain } = await import('@features/tasks');
                return { Component: TasksMain };
              }
            },
            {
              path: 'my-tasks',
              lazy: async () => {
                const { TasksMain } = await import('@features/tasks');
                return { Component: TasksMain };
              }
            },
            {
              path: 'all-tasks',
              lazy: async () => {
                const { TasksMain } = await import('@features/tasks');
                return { Component: TasksMain };
              }
            }
          ]
        },
        {
          path: '/403',
          element: <ForbiddenPage />
        },
        {
          path: '/404',
          element: <NotFoundPage />
        },
        {
          path: '/500',
          element: <InternalServerErrorPage />
        },
        {
          path: '*',
          element: <NotFoundPage />
        }
      ]
    },
    {
      path: '/signup',
      lazy: async () => {
        const { SignUp } = await import('@features/auth');
        return { Component: SignUp };
      }
    },
    {
      path: '/welcome',
      lazy: async () => {
        const { WelcomePage } = await import('@features/welcome');
        return { Component: WelcomePage };
      }
    },
    {
      path: '/admin-onboarding',
      lazy: async () => {
        const { ProtectedAdminOnboardingFlowRoutes } = await import('@features/protected-routes');
        return { Component: ProtectedAdminOnboardingFlowRoutes };
      },
      children: [
        {
          loader({ request }) {
            if (new URL(request.url).pathname === '/admin-onboarding/') {
              return redirect('/admin-onboarding/start');
            }
            return null;
          }
        },
        {
          path: 'start',
          lazy: async () => {
            const { AdminOnboardingWelcomePage } = await import('@features/admin-onboarding');
            return { Component: AdminOnboardingWelcomePage };
          }
        },
        {
          path: 'setup-organization',
          lazy: async () => {
            const { AdminOnboardingMainSetupPage } = await import('@features/admin-onboarding');
            return { Component: AdminOnboardingMainSetupPage };
          }
        }
      ]
    },
    {
      path: 'payment-method-added',
      lazy: async () => {
        const { PaymentMethodAdded } = await import('@features/snackbar');
        return { Component: PaymentMethodAdded };
      }
    },
    {
      path: 'signout',
      lazy: async () => {
        const { DevSignout } = await import('@features/auth');
        return { Component: DevSignout };
      }
    }
  ],
  {
    future: {
      v7_relativeSplatPath: true,
      v7_fetcherPersist: true,
      v7_normalizeFormMethod: true,
      v7_partialHydration: true,
      v7_skipActionErrorRevalidation: true
    }
  }
);

export const App = () => {
  const theme = useTheme();
  const abacRules = useAppSelector(selectAbacRules);
  const abacUser = useAppSelector(selectAbacUser);

  return (
    <SignalRProvider>
      <AbacProvider
        rules={abacRules}
        user={abacUser}
        roles={abacUser.groupNames}
        permissions={Object.keys(AudiencePermissions).filter((item) => isNaN(Number(item)))}
      >
        <ThemeProvider theme={responsiveFontSizes(linkedinTheme)}>
          <GlobalStyles
            styles={{
              '@keyframes mui-auto-fill': { from: { display: 'block' } },
              '@keyframes mui-auto-fill-cancel': { from: { display: 'block' } },
              body: { backgroundColor: theme.palette.common.black }
            }}
          />
          <CssBaseline />
          <RouterProvider router={router} />
        </ThemeProvider>
      </AbacProvider>
    </SignalRProvider>
  );
};
