import { Stack, StackProps, styled } from '@mui/material';

export const SettingsActionsHeader = styled(Stack)<StackProps>(() => ({
  width: '100%',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  padding: '16px',
  gap: 1
}));
