import { Stack, StackProps, styled } from '@mui/material';

export const AudienceGroupAutocompletePaperStack = styled(Stack)<StackProps>(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  backgroundColor: theme.palette.customTheme.drawerBackground,
  p: '4px',
  borderRadius: theme.spacing(0.5)
}));
