import { IconButton, IconButtonProps, styled } from '@mui/material';

export const FiltersButton = styled(IconButton)<IconButtonProps>(({ theme }) => ({
  backgroundColor: 'transparent',
  height: 32,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '6px 14px',
  borderRadius: '8px',
  border: '1px solid',
  borderColor: 'transparent',
  fontSize: 16,
  color: '#838383',
  fontWeight: 700
}));
