import { create } from '@bufbuild/protobuf';
import React, { useCallback, useEffect, useState } from 'react';
import { FormControlLabel, Stack, Typography, Divider, Box } from '@mui/material';
import {
  HighlightText,
  RowCenterStack,
  StyledGroupWrapper,
  StyledGroupCheckbox,
  ChevronDownIcon,
  PictureSizeSuffix,
  ActionStatus,
  PrimaryTypographyBodyBold,
  MembersInput,
  StyledAvatar,
  GenericSelect,
  PrimaryDarkButton,
  ThinBorderCheckbox
} from '@/shared';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import {
  retrieveAudienceGroupAutocompleteItemsRequested,
  GroupAutocompleteItemsSkeleton,
  CustomAudienceGroupAutocompletePopper,
  CustomAudienceGroupAutocompletePaperProps,
  selectChosenAudienceGroupMemberIds,
  GroupAutocomplete,
  ListSubHeader,
  AudienceListItemButton,
  selectAudienceGroupAutocompleteOptions,
  selectAudienceGroupAutocompleteItemsStatus
} from '@features/admin-settings';
import {
  EmployeeAccessSchema,
  EmployeeListItem,
  FileAccessRole,
  QueryFilter,
  QueryFilterSchema,
  RetrieveEmployeesByQueryRequestSchema,
  SearchFilterCategory
} from '@thrivea/organization-client';
import { useTranslation } from 'react-i18next';
import { initials, pictureUrl } from '@/utils';
import { selectEmployeeProfileAndCoverReadSasToken } from '@features/employee-profile';
import debounce from 'lodash/debounce';
import { addEmployeeToShareList, selectAccessItemsIds } from '@features/assets';
import { capitalize } from 'lodash';
import { selectCurrentEmployeeId } from '@features/shared';
import { openDrawer, DrawerType, OpenDrawerRequest } from '@features/drawer';

const fileAccessRoleOptions = Object.values(FileAccessRole)
  .filter((value) => typeof value === 'number') // Keep only numeric enum values
  .slice(1)
  .map((value) => ({
    label: FileAccessRole[value as FileAccessRole], // Get the name from the enum
    value: value as number // Ensure the value is a number
  }));

export const AssetsFileAccessAutocomplete = () => {
  const { t } = useTranslation(['common', 'assets']);
  const dispatch = useAppDispatch();

  const employeeProfileSasToken = useAppSelector(selectEmployeeProfileAndCoverReadSasToken);
  const currentEmployeeId = useAppSelector(selectCurrentEmployeeId);
  const chosenAudienceGroupEmployeeIds = useAppSelector(selectChosenAudienceGroupMemberIds);
  const accessItemsIds = useAppSelector(selectAccessItemsIds);

  const [searchText, setSearchText] = useState('');

  const [role, setRole] = useState<FileAccessRole>(FileAccessRole.VIEWER);
  const [isExpanded, setIsExpanded] = useState(false);

  const [selectedEmployees, setSelectedEmployees] = useState<EmployeeListItem[]>([]);

  const assetsAccessAutocompleteOptions = useAppSelector((state) => selectAudienceGroupAutocompleteOptions(state, searchText)) as EmployeeListItem[];
  const audienceGroupAutocompleteItemsStatus = useAppSelector(selectAudienceGroupAutocompleteItemsStatus);

  const debouncedSearch = useCallback(
    debounce((value) => {
      const filters: QueryFilter[] = [
        create(QueryFilterSchema, {
          filter: SearchFilterCategory.EMPLOYEE,
          pageNumber: 1,
          pageSize: 10
        })
      ];

      dispatch(
        retrieveAudienceGroupAutocompleteItemsRequested(
          create(RetrieveEmployeesByQueryRequestSchema, {
            searchText: value,
            filters
          })
        )
      );
    }, 500),
    []
  );

  useEffect(() => {
    debouncedSearch(searchText);

    // Cleanup the debounced function
    return () => {
      debouncedSearch.cancel();
    };
  }, [searchText, debouncedSearch]);

  const handleInputChange = (_event, value) => {
    setSearchText(value);
  };

  const isOptionSelected = (option: EmployeeListItem) => {
    return selectedEmployees.some((employee) => employee === option);
  };

  const optionAlreadyExists = (option: EmployeeListItem) => {
    return accessItemsIds.some((employee) => employee === option.employeeId);
  };

  const handleSelectedCheckboxChange = (option: EmployeeListItem, event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedEmployees((prev) => {
      if (event.target.checked) {
        return !prev.includes(option) ? [...prev, option] : prev;
      } else {
        return prev.filter((employee) => employee !== option);
      }
    });
  };

  const handleViewEmployeeProfileClick = (employeeId: string) => {
    dispatch(openDrawer({ type: DrawerType.SingleEmployee, request: { employeeId } } as OpenDrawerRequest));
  };

  return (
    <Stack gap={2}>
      <StyledGroupWrapper expanded={isExpanded}>
        <GroupAutocomplete
          open={isExpanded}
          onInputChange={handleInputChange}
          loading={audienceGroupAutocompleteItemsStatus === ActionStatus.Pending}
          loadingText={<GroupAutocompleteItemsSkeleton />}
          multiple
          renderTags={() => null}
          options={assetsAccessAutocompleteOptions}
          filterOptions={(x) => x.filter((x) => x.employeeId !== currentEmployeeId)}
          noOptionsText={t('no_results', { ns: 'common', query: searchText })}
          disableCloseOnSelect
          disableClearable
          clearOnBlur={false}
          blurOnSelect={false}
          clearOnEscape={true}
          popupIcon={<ChevronDownIcon />}
          forcePopupIcon
          autoFocus
          onFocus={() => setIsExpanded(true)}
          groupBy={(option: EmployeeListItem) => option.displayName[0].toUpperCase()}
          slots={{
            popper: CustomAudienceGroupAutocompletePopper
          }}
          renderInput={(params) => <MembersInput {...params} variant="outlined" placeholder={t('search_audience_placeholder', { ns: 'settings_audience' })} />}
          renderOption={(_props, option) => {
            return (
              <AudienceListItemButton key={option.employeeId} selected={isOptionSelected(option)} disableRipple disabled={optionAlreadyExists(option)}>
                <FormControlLabel
                  disabled={chosenAudienceGroupEmployeeIds.includes(option.employeeId)}
                  control={
                    <StyledGroupCheckbox
                      icon={<ThinBorderCheckbox />}
                      checked={isOptionSelected(option) || chosenAudienceGroupEmployeeIds.includes(option.employeeId)}
                      onChange={(event) => {
                        handleSelectedCheckboxChange(option, event);
                      }}
                    />
                  }
                  label={
                    <RowCenterStack gap={1}>
                      <StyledAvatar
                        onClick={(e) => handleViewEmployeeProfileClick(option.employeeId)}
                        src={pictureUrl(option.profilePictureUrl, employeeProfileSasToken, PictureSizeSuffix.sm)}
                        width={34}
                        height={34}
                      >
                        {initials(option.displayName)}
                      </StyledAvatar>
                      <Stack>
                        <HighlightText color="rgba(47, 188, 196, 0.40)" bold text={option.displayName} highlight={searchText} />
                        <HighlightText color="rgba(47, 188, 196, 0.40)" text={option.jobTitle} highlight={searchText} />
                      </Stack>
                    </RowCenterStack>
                  }
                />
              </AudienceListItemButton>
            );
          }}
          renderGroup={(params) => (
            <Box component="div" key={params.key}>
              <ListSubHeader disableGutters disableSticky inset>
                <RowCenterStack gap={'20px'}>
                  <Typography variant="body2">{params.group}</Typography>
                  <Divider sx={{ width: 257 }} />
                </RowCenterStack>
              </ListSubHeader>
              {params.children}
            </Box>
          )}
          getOptionLabel={(option) => {
            return option.displayName;
          }}
          slotProps={{
            paper: {
              searchText: searchText
            } as CustomAudienceGroupAutocompletePaperProps
          }}
        />
      </StyledGroupWrapper>
      <RowCenterStack sx={{ justifyContent: 'space-between', zIndex: (theme) => theme.zIndex.mobileStepper }}>
        <RowCenterStack gap={1}>
          <PrimaryTypographyBodyBold>{t('give_permissions', { ns: 'assets' })}</PrimaryTypographyBodyBold>
          <GenericSelect<number>
            options={fileAccessRoleOptions}
            value={role}
            onChange={setRole}
            renderValue={(value) => (value === FileAccessRole.FILE_ACCESS_ROLE_UNDEFINED ? 'Select Role' : capitalize(FileAccessRole[value]))}
            displayEmpty
          />
        </RowCenterStack>
        <PrimaryDarkButton
          variant="text"
          onClick={() => {
            setIsExpanded(false);
            dispatch(
              addEmployeeToShareList(
                selectedEmployees.map((employee: EmployeeListItem) =>
                  create(EmployeeAccessSchema, {
                    id: employee.employeeId,
                    name: employee.displayName,
                    jobTitle: employee.jobTitle,
                    profilePicture: employee.profilePictureUrl,
                    accessRole: role
                  })
                )
              )
            );
          }}
        >
          {t('add_to_list', { ns: 'assets' })}
        </PrimaryDarkButton>
      </RowCenterStack>
    </Stack>
  );
};
