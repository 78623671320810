export enum BillingPeriod {
  Monthly = 'monthly',
  Annually = 'annually'
}

export interface SubscriptionPlan {
  name: 'free-monthly' | 'starter-monthly' | 'growth-monthly' | 'free-annually' | 'starter-annually' | 'growth-annually';
  price: number;
  usersCount: number;
  storage: number;
  billingPeriod: BillingPeriod;
  stripeLookupKey: string;
}

export const ADDITIONAL_SEATS_PRICE_IN_USD: number = 5;
export const ADDITIONAL_STORAGE_PRICE_IN_USD: number = 5;

export const subscriptionPlans: Record<string, SubscriptionPlan> = {
  'free-monthly': {
    name: 'free-monthly',
    price: 0,
    usersCount: 10,
    storage: 1,
    billingPeriod: BillingPeriod.Monthly,
    stripeLookupKey: 'free_monthly_usd'
  },
  'starter-monthly': {
    name: 'starter-monthly',
    price: 20,
    usersCount: 250,
    storage: 5,
    billingPeriod: BillingPeriod.Monthly,
    stripeLookupKey: 'starter_monthly_usd'
  },
  'growth-monthly': {
    name: 'growth-monthly',
    price: 50,
    usersCount: 1000,
    storage: 10,
    billingPeriod: BillingPeriod.Monthly,
    stripeLookupKey: 'growth_monthly_usd'
  },
  'free-annually': {
    name: 'free-annually',
    price: 0,
    usersCount: 10,
    storage: 1,
    billingPeriod: BillingPeriod.Annually,
    stripeLookupKey: 'free_yearly_usd'
  },
  'starter-annually': {
    name: 'starter-annually',
    price: 200,
    usersCount: 50,
    storage: 5,
    billingPeriod: BillingPeriod.Annually,
    stripeLookupKey: 'starter_yearly_usd'
  },
  'growth-annually': {
    name: 'growth-annually',
    price: 500,
    usersCount: 1000,
    storage: 10,
    billingPeriod: BillingPeriod.Annually,
    stripeLookupKey: 'growth_yearly_usd'
  }
};
