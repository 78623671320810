import { Button, ButtonProps, styled } from '@mui/material';

export const TasksAttachmentButton = styled(Button)<ButtonProps>(({ theme }) => ({
  backgroundColor: theme.palette.customTheme.contColor,
  color: theme.palette.common.white,
  padding: '12px 16px',
  borderRadius: 8,
  height: 35,
  lineHeight: 'normal'
}));
