import React from 'react';

interface CircleDotIconProps {
  width?: number;
  height?: number;
  color?: string;
}

export const CircleDotIcon: React.FC<CircleDotIconProps> = ({ width = 22, height = 22, color = '#251D38' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill={color} viewBox="0 0 512 512">
      <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-352a96 96 0 1 1 0 192 96 96 0 1 1 0-192z" />
    </svg>
  );
};
