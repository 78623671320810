import styled from '@emotion/styled';
import { treeItemClasses } from '@mui/x-tree-view/TreeItem';
import { TreeItem2Root } from '@mui/x-tree-view/TreeItem2';

export const AssetsTreeItemRoot = styled(TreeItem2Root)(({ theme }) => ({
  position: 'relative',
  [`& .${treeItemClasses.groupTransition}`]: {
    padding: 0,
    background: 'red'
  }
})) as unknown as typeof TreeItem2Root;
