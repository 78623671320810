import { Box, BoxProps, styled } from '@mui/material';
import { shouldForwardProp } from '@mui/system';

interface RoundDropDownSearchBoxProps extends BoxProps {
  isOpen?: boolean;
}

export const RoundDropDownSearchBox = styled(Box, {
  shouldForwardProp: (prop) => shouldForwardProp(prop) && prop !== 'isOpen'
})<RoundDropDownSearchBoxProps>(({ theme, isOpen }) => ({
  width: '100%',
  zIndex: theme.zIndex.tooltip,
  position: 'relative',
  borderRadius: '8px',
  border: isOpen ? '1px solid' : 0,
  borderColor: theme.palette.primary.main,
  backgroundColor: theme.palette.common.white
}));
