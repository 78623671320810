import { Empty } from '@bufbuild/protobuf';
import { createClient } from '@connectrpc/connect';
import { createGrpcWebTransport } from '@connectrpc/connect-web';
import {
  EmployeeProfile,
  EmployeeProfileService,
  RetrieveCalendarItemsResponse,
  RetrieveEmployeeItemsResponse,
  RetrieveEmployeeProfileRequest,
  RetrieveEmploymentTypesResponse,
  RetrieveEthnicitiesResponse,
  RetrieveGendersResponse,
  RetrieveJobCategoriesResponse,
  RetrievePrefixesResponse,
  RetrievePronounsResponse,
  RetrieveWorkEligibilityTypesResponse,
  UpdateEeoRequest,
  UpdateEmergencyRequest,
  UpdateEmploymentRequest,
  UpdateFinancialRequest,
  UpdateHomeRequest,
  UpdatePayrollRequest,
  UpdateWorkEligibilityRequest,
  UpdateAboutRequest,
  UpdateAddressRequest,
  UpdateBasicInfoRequest,
  UpdateIdentificationRequest,
  UpdatePersonalContactDetailsRequest,
  UpdatePersonalRequest,
  UpdateWorkContactDetailsRequest,
  UpdateWorkRequest,
  RetrieveSitesResponse,
  RetrieveDepartmentsResponse,
  RetrieveSuperPowersResponse,
  RetrieveHobbiesResponse,
  RetrieveFoodPreferencesResponse,
  UpdateProfileMediaRequest
} from '@thrivea/organization-client';
import { msalInstance } from '@/main';
import { tokenRequest } from '@utils/authConfig';

export const employeeProfileClient = createClient(
  EmployeeProfileService,
  createGrpcWebTransport({
    baseUrl: import.meta.env.VITE_APP_API_URL,
    useBinaryFormat: true
  })
);

export const retrieveEmployeeProfile = async (request: RetrieveEmployeeProfileRequest): Promise<EmployeeProfile> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await employeeProfileClient.retrieveEmployeeProfile(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveEmployeeItems = async (request: Empty): Promise<RetrieveEmployeeItemsResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await employeeProfileClient.retrieveEmployeeItems(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrievePrefixes = async (request: Empty): Promise<RetrievePrefixesResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await employeeProfileClient.retrievePrefixes(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrievePronouns = async (request: Empty): Promise<RetrievePronounsResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await employeeProfileClient.retrievePronouns(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveGenders = async (request: Empty): Promise<RetrieveGendersResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await employeeProfileClient.retrieveGenders(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveJobCategories = async (request: Empty): Promise<RetrieveJobCategoriesResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await employeeProfileClient.retrieveJobCategories(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveEthnicities = async (request: Empty): Promise<RetrieveEthnicitiesResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await employeeProfileClient.retrieveEthnicities(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveWorkEligibilityTypes = async (request: Empty): Promise<RetrieveWorkEligibilityTypesResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await employeeProfileClient.retrieveWorkEligibilityTypes(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveEmploymentTypes = async (request: Empty): Promise<RetrieveEmploymentTypesResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await employeeProfileClient.retrieveEmploymentTypes(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveCalendarItems = async (request: Empty): Promise<RetrieveCalendarItemsResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await employeeProfileClient.retrieveCalendarItems(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveSites = async (request: Empty): Promise<RetrieveSitesResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await employeeProfileClient.retrieveSites(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveDepartments = async (request: Empty): Promise<RetrieveDepartmentsResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await employeeProfileClient.retrieveDepartments(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveSuperpowers = async (request: Empty): Promise<RetrieveSuperPowersResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await employeeProfileClient.retrieveSuperPowers(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveHobbies = async (request: Empty): Promise<RetrieveHobbiesResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await employeeProfileClient.retrieveHobbies(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveFoodPreferences = async (request: Empty): Promise<RetrieveFoodPreferencesResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await employeeProfileClient.retrieveFoodPreferences(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const updateHome = async (request: UpdateHomeRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await employeeProfileClient.updateHome(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const updateFinancial = async (request: UpdateFinancialRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await employeeProfileClient.updateFinancial(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const updateWorkEligibility = async (request: UpdateWorkEligibilityRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await employeeProfileClient.updateWorkEligibility(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const updateEmployment = async (request: UpdateEmploymentRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await employeeProfileClient.updateEmployment(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const updatePayroll = async (request: UpdatePayrollRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await employeeProfileClient.updatePayroll(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const updateEmergency = async (request: UpdateEmergencyRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await employeeProfileClient.updateEmergency(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const updateEEO = async (request: UpdateEeoRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await employeeProfileClient.updateEeo(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const updateBasicInfo = async (request: UpdateBasicInfoRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await employeeProfileClient.updateBasicInfo(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const updatePersonal = async (request: UpdatePersonalRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await employeeProfileClient.updatePersonal(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const updatePersonalContactDetails = async (request: UpdatePersonalContactDetailsRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await employeeProfileClient.updatePersonalContactDetails(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const updateIdentification = async (request: UpdateIdentificationRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await employeeProfileClient.updateIdentification(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const updateWork = async (request: UpdateWorkRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await employeeProfileClient.updateWork(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const updateWorkContactDetails = async (request: UpdateWorkContactDetailsRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await employeeProfileClient.updateWorkContactDetails(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const updateAddress = async (request: UpdateAddressRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await employeeProfileClient.updateAddress(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const updateAbout = async (request: UpdateAboutRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await employeeProfileClient.updateAbout(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const updateProfileMedia = async (request: UpdateProfileMediaRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await employeeProfileClient.updateProfileMedia(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};
