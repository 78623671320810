import { Stack } from '@mui/material';
import { TasksListCategory } from '@features/tasks';

export const TasksListView = () => {
  return (
    <Stack gap={'24px'}>
      <TasksListCategory title="Due Today" description="A short description" tasksCount={0} />
      <TasksListCategory title="Due this week" description="A short description" tasksCount={8} />
    </Stack>
  );
};
