import React, { MutableRefObject, Suspense, lazy, memo, useId } from 'react';
import { IconButton, Skeleton, Popover, Box } from '@mui/material';
import { AssetsTooltip } from 'src/features/assets';
import { useTranslation } from 'react-i18next';

const LazyPicker = lazy(() => import('@emoji-mart/react'));

interface Emoji {
  id: number;
  native: string;
}

interface EmojiPickerProps {
  onEmojiSelect?: (emoji: Emoji, event?: any) => void;
  buttonRef?: MutableRefObject<HTMLButtonElement | null>;
  disableIcon?: boolean;
}

export const EmojiPicker: React.FC<EmojiPickerProps> = memo(({ onEmojiSelect, buttonRef, disableIcon }) => {
  const { t } = useTranslation(['common']);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const open = Boolean(anchorEl);
  const popoverId = useId();
  const id = open ? popoverId : undefined;

  const handleLocalEmojiSelect = (emoji: Emoji, event?: any) => {
    if (onEmojiSelect) {
      onEmojiSelect(emoji, event);
    }
    setAnchorEl(null);
  };

  const handleEmojiOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleEmojiClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      className="MuiButton-react"
      sx={{
        position: 'relative',
        display: 'inline-flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        border: '1px solid',
        borderColor: (theme) => theme.palette.grey[300],
        borderRadius: '7px',
        height: 28,
        opacity: 0,
        transition: (theme) =>
          theme.transitions.create('opacity, background-color, border-color', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
          }),
        '&:hover': {
          backgroundColor: (theme) => theme.palette.customTheme.drawerBackground,
          borderColor: (theme) => theme.palette.customTheme.drawerBackground
        }
      }}
    >
      <AssetsTooltip arrow title={t('add_reaction', { ns: 'common' })}>
        <IconButton
          ref={buttonRef}
          onClick={handleEmojiOpen}
          sx={{
            borderRadius: '7px',
            justifyContent: disableIcon ? 'flex-start' : 'center',
            padding: '0 7px',
            width: 40,
            height: 28
          }}
        >
          <Box
            component="img"
            src="/images/emoji-transparent-icon.png"
            sx={{
              height: 28,
              opacity: 0.5
            }}
          />
        </IconButton>
      </AssetsTooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleEmojiClose}
        disableScrollLock
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left'
        }}
        disablePortal
      >
        <Suspense fallback={<Skeleton width={'100%'} height={'100%'} />}>
          <LazyPicker theme="light" set="apple" onEmojiSelect={handleLocalEmojiSelect} />
        </Suspense>
      </Popover>
    </Box>
  );
});
