import { Stack, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useAppSelector } from '@app/hooks';
import { BoardGridItem, BoardViewDivider, Task, TasksBoardItem, TasksBoardTask } from '@features/tasks';
import { selectIsDrawerOpen } from '@features/drawer';

enum TaskStatus {
  Draft,
  Assigned,
  InReview,
  Completed
}

const tempTaskListData: Task[] = [
  {
    id: '#M9N001-P2',
    title: 'Enhancing the onboarding experience for new team members',
    description: 'Fugiat non magna excepteur voluptate exercitation esse et dolor in anim excepteur id.',
    reviewer: 'None',
    dueDate: '12/12/2025 | 12:30',
    status: TaskStatus.Draft
  },
  {
    id: '#M9N001-P2',
    title: 'Enhancing the onboarding experience for new team members',
    description: 'Fugiat non magna excepteur voluptate exercitation esse et dolor in anim excepteur id.',
    reviewer: 'None',
    dueDate: '12/12/2025 | 12:30',
    status: TaskStatus.Assigned
  },
  {
    id: '#M9N002-P2',
    title: 'Implementing a new performance review system',
    description:
      'Exercitation qui Lorem esse ullamco Lorem excepteur deserunt anim. Culpa ullamco Lorem officia qui. Ad veniam cupidatat cupidatat aute nisi enim duis fugiat do officia fugiat ea fugiat. Anim anim excepteur eu proident mollit sit quis. Irure ex sint minim pariatur consequat id duis ut sit elit. Excepteur id do eiusmod non duis.Sunt nisi laboris et consequat eiusmod eu pariatur eiusmod veniam id magna incididunt consequat cillum. Amet nostrud aute commodo do magna occaecat cupidatat. Dolore Lorem mollit mollit cillum adipisicing non dolor cillum tempor dolor. Anim enim consectetur amet adipisicing mollit dolor. Reprehenderit eiusmod consequat velit exercitation irure cupidatat fugiat.',
    reviewer: 'Alex Johnson',
    dueDate: '11/22/2025 | 10:00',
    status: TaskStatus.InReview
  },
  {
    id: '#M9N002-P2-T4',
    title: 'Test review system with pilot group',
    description: 'Select a pilot group from various departments to test the new performance review system. Gather feedback on usability and effectiveness.',
    reviewer: 'Alex Johnson',
    dueDate: '11/22/2025 | 10:00',
    status: TaskStatus.Assigned,
    taskList: [
      {
        id: '#M9N002-P2-T1',
        title: 'Define performance review goals',
        description:
          'Collaborate with HR to define the goals and objectives of the new performance review system. Establish key performance indicators and review criteria.',
        reviewer: 'Jessica Lee',
        dueDate: '11/10/2025 | 15:00',
        status: TaskStatus.Assigned
      },
      {
        id: '#M9N002-P2-T2',
        title: 'Develop review form templates',
        description:
          'Design templates for performance review forms. These should include sections for comments, ratings, and objectives for each review period.',
        reviewer: 'Michael Brown',
        dueDate: '11/15/2025 | 12:00',
        status: TaskStatus.InReview
      },
      {
        id: '#M9N002-P2-T3',
        title: 'Implement feedback system in the HR portal',
        description:
          'Integrate feedback mechanisms within the HR portal to allow managers and employees to leave performance feedback. Ensure all feedback is confidential and secure.',
        reviewer: 'Sarah Taylor',
        dueDate: '11/18/2025 | 17:00',
        status: TaskStatus.Completed
      }
    ]
  }
];

export const TasksBoardView = () => {
  const theme = useTheme();
  const isDrawerOpen = useAppSelector(selectIsDrawerOpen);

  return (
    <Grid
      container
      sx={{
        overflow: 'hidden',
        [theme.breakpoints.up('sm')]: {
          height: '100%'
        },
        [theme.breakpoints.up('lg')]: {
          height: isDrawerOpen ? 'calc(100vh - 306px)' : 'calc(100vh - 306px)'
        }
      }}
    >
      <BoardGridItem size={3}>
        <TasksBoardItem title="Draft" tasksCount={1} />
        <Stack gap={2}>
          {tempTaskListData
            .filter((task) => task.status === TaskStatus.Draft)
            .map((task) => (
              <TasksBoardTask key={task.id} task={task} />
            ))}
        </Stack>
        <BoardViewDivider orientation="vertical" />
      </BoardGridItem>
      <BoardGridItem size={3}>
        <TasksBoardItem title="Assigned" tasksCount={0} />
        <Stack gap={2}>
          {tempTaskListData
            .filter((task) => task.status === TaskStatus.Assigned)
            .map((task) => (
              <TasksBoardTask key={task.id} task={task} />
            ))}
        </Stack>
        <BoardViewDivider orientation="vertical" />
      </BoardGridItem>
      <BoardGridItem size={3}>
        <TasksBoardItem title="In Review" tasksCount={0} />
        <Stack gap={2}>
          {tempTaskListData
            .filter((task) => task.status === TaskStatus.InReview)
            .map((task) => (
              <TasksBoardTask key={task.id} task={task} />
            ))}
        </Stack>
        <BoardViewDivider orientation="vertical" />
      </BoardGridItem>
      <BoardGridItem size={3}>
        <TasksBoardItem title="Completed" tasksCount={0} />
        <Stack gap={2}>
          {tempTaskListData
            .filter((task) => task.status === TaskStatus.Completed)
            .map((task) => (
              <TasksBoardTask key={task.id} task={task} />
            ))}
        </Stack>
      </BoardGridItem>
    </Grid>
  );
};
