import { Typography, IconButton, CircularProgress } from '@mui/material';
import { Stack, Box } from '@mui/material';
import {
  RowCenterStack,
  VisuallyHidden,
  EditIcon,
  CancelEditIcon,
  StyledFormWrapper,
  StyledFormHeader,
  PrimaryDarkButton,
  StyledFormSubmitButton,
  ActionStatus
} from '@/shared';
import { ForwardedRef, forwardRef, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAppSelector } from '@app/hooks';
import { UpdateWorkEligibilityRequest, WorkEligibility } from '@thrivea/organization-client';
import {
  createWorkEligibilityCategorySchema,
  removeNewWorkEligibilityItems,
  selectNewWorkEligibilityItems,
  selectProfileNotification,
  selectWorkEligibilityCategory,
  updateWorkEligibilityRequested
} from '@features/employee-profile';
import { WorkEligibilityCategoryTable } from './WorkEligibilityCategoryTable';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { camelCase, snakeCase } from 'lodash';
import { AllowedTo } from 'react-abac';
import { GroupPermissions, ProfileCategoryInfo } from '@features/abac';

interface FinancialCategoryProps {
  ref: ForwardedRef<HTMLDivElement>;
  employeeId: string;
}

export const WorkEligibilityCategory = forwardRef<HTMLDivElement, FinancialCategoryProps>(({ employeeId }, ref) => {
  const { t } = useTranslation(['common', 'employee_profile']);
  const name = t('work_eligibility_category_title', { ns: 'employee_profile' });
  const workEligibilityCategory = useAppSelector(selectWorkEligibilityCategory);
  const newWorkEligibilityItems = useAppSelector(selectNewWorkEligibilityItems);
  const { status, category } = useAppSelector(selectProfileNotification);
  const isPendingAndMatchingName = status === ActionStatus.Pending && category === camelCase(name);
  const [isEditable, setIsEditable] = useState(false);
  const dispatch = useDispatch();
  const workEligibilitySchema = useMemo(() => createWorkEligibilityCategorySchema(t), [t]);

  const {
    formState: { dirtyFields, errors },
    reset,
    handleSubmit,
    setValue
  } = useForm<WorkEligibility>({
    mode: 'all',
    resolver: zodResolver(workEligibilitySchema),
    defaultValues: {
      workEligibilityItems: workEligibilityCategory.workEligibilityItems
    }
  });

  const onSubmit = () => {
    setIsEditable(false);
    dispatch(
      updateWorkEligibilityRequested({
        employeeId: employeeId,
        workEligibilityItems: workEligibilityCategory.workEligibilityItems
      } as UpdateWorkEligibilityRequest)
    );
  };

  const handleToggleEditable = () => {
    setIsEditable(!isEditable);
  };

  const handleSetEditable = () => {
    setIsEditable(true);
  };

  const handleCloseEditable = () => {
    setIsEditable(false);
    dispatch(removeNewWorkEligibilityItems());
    reset({
      workEligibilityItems: workEligibilityCategory.workEligibilityItems
    });
  };

  useEffect(() => {
    reset({
      workEligibilityItems: workEligibilityCategory.workEligibilityItems
    });
  }, [workEligibilityCategory]);

  useEffect(() => {
    // Check if newWorkEligibilityItem exists which means new event is added and set isDirty to true
    if (newWorkEligibilityItems.length > 0) {
      setValue('workEligibilityItems', workEligibilityCategory.workEligibilityItems, { shouldDirty: true });
    }
  }, [newWorkEligibilityItems]);

  return (
    <StyledFormWrapper isEditable={isEditable} id={snakeCase(name)} ref={ref}>
      <Box
        component="form"
        name={name}
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          backgroundColor: 'transparent'
        }}
      >
        <Stack>
          <StyledFormHeader isEditable={isEditable} className="Mui-ProfileFiledHeader">
            <RowCenterStack gap={1}>
              {isPendingAndMatchingName && <CircularProgress size={24} thickness={4} />}
              <Typography component="h3" variant="h5" fontWeight={700}>
                {name}
              </Typography>
            </RowCenterStack>
            <AllowedTo perform={GroupPermissions.EDIT_PROFILE} data={{ employeeId, categoryName: 'workEligibility' } as ProfileCategoryInfo}>
              <IconButton
                className="Mui-Edit"
                onClick={handleToggleEditable}
                sx={{
                  opacity: '0',
                  display: isEditable ? 'none' : 'inline-flex'
                }}
              >
                <VisuallyHidden>Edit {name}</VisuallyHidden>
                <EditIcon />
              </IconButton>
            </AllowedTo>
            <RowCenterStack
              gap={2}
              sx={{
                display: isEditable ? 'flex' : 'none'
              }}
            >
              <PrimaryDarkButton variant="contained" onClick={handleCloseEditable} startIcon={<CancelEditIcon />}>
                {t('cancel', { ns: 'common' })}
              </PrimaryDarkButton>
              <StyledFormSubmitButton
                type="submit"
                disabled={Object.values(dirtyFields).length === 0 || Object.values(errors).length !== 0}
                variant="contained"
              >
                {t('done', { ns: 'common' })}
              </StyledFormSubmitButton>
            </RowCenterStack>
          </StyledFormHeader>
        </Stack>
      </Box>
      <WorkEligibilityCategoryTable handleSetEditable={handleSetEditable} employeeId={employeeId} />
    </StyledFormWrapper>
  );
});
