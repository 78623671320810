import { CircleFilledIcon, CircleOutlinedIcon, RowCenterStack } from '@/shared';
import { useMediaQuery, useTheme } from '@mui/material';

interface AssetsTreeItemIconProps {
  level: number;
}

export const AssetsTreeItemIcon: React.FC<AssetsTreeItemIconProps> = ({ level }) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <RowCenterStack gap={'4px'} sx={{ height: 5, minWidth: 7 }}>
      {Array.from({ length: level - 1 }).map((_, index) => (
        <CircleOutlinedIcon key={index} width={isTablet ? 5 : 7} height={isTablet ? 5 : 7} />
      ))}
      <CircleFilledIcon width={isTablet ? 5 : 7} height={isTablet ? 5 : 7} />
    </RowCenterStack>
  );
};
