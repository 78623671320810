import { Empty } from '@bufbuild/protobuf/wkt';
import { createClient } from '@connectrpc/connect';
import { createGrpcWebTransport } from '@connectrpc/connect-web';
import {
  RefreshPostCreateDeleteSasUriRequest,
  RetrieveEmployeeProfileAndCoverCreateSasUriRequest,
  RetrieveSiteCreateSasUriRequest,
  RetrieveSiteDeleteSasTokenRequest,
  SasToken,
  SasUri,
  SharedAccessSignatureService
} from '@thrivea/auth-client';
import { msalInstance } from '@/main';
import { tokenRequest } from '@utils/authConfig';

export const sharedAccessSignatureClient = createClient(
  SharedAccessSignatureService,
  createGrpcWebTransport({
    baseUrl: import.meta.env.VITE_APP_API_URL,
    useBinaryFormat: true
  })
);

export const retrieveEmployeeProfileAndCoverReadSasToken = async (request: Empty): Promise<SasToken> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await sharedAccessSignatureClient.retrieveEmployeeProfileAndCoverReadSasToken(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveEmployeeProfileAndCoverCreateSasUri = async (request: RetrieveEmployeeProfileAndCoverCreateSasUriRequest): Promise<SasUri> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await sharedAccessSignatureClient.retrieveEmployeeProfileAndCoverCreateSasUri(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveSiteCreateSasUri = async (request: RetrieveSiteCreateSasUriRequest): Promise<SasUri> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await sharedAccessSignatureClient.retrieveSiteCreateSasUri(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveSiteDeleteSasToken = async (request: RetrieveSiteDeleteSasTokenRequest): Promise<SasToken> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await sharedAccessSignatureClient.retrieveSiteDeleteSasToken(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveSiteReadSasToken = async (request: Empty): Promise<SasToken> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await sharedAccessSignatureClient.retrieveSiteReadSasToken(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveOrganizationLogoReadSasToken = async (request: Empty): Promise<SasToken> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await sharedAccessSignatureClient.retrieveOrganizationLogoReadSasToken(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveOrganizationLogoCreateSasUri = async (request: Empty): Promise<SasUri> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await sharedAccessSignatureClient.retrieveOrganizationLogoCreateSasUri(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveAdminOnboardingFlowSiteCreateSasUri = async (request: Empty): Promise<SasUri> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await sharedAccessSignatureClient.retrieveAdminOnboardingFlowSiteCreateSasUri(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrievePostCreateDeleteSasUri = async (request: Empty): Promise<SasUri> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await sharedAccessSignatureClient.retrievePostCreateDeleteSasUri(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrievePostReadSasToken = async (request: Empty): Promise<SasToken> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await sharedAccessSignatureClient.retrievePostReadSasToken(request, { headers: new Headers({ Authorization: `Bearer ${account.accessToken}` }) });
};

export const refreshPostCreateDeleteSasUri = async (request: RefreshPostCreateDeleteSasUriRequest): Promise<SasUri> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await sharedAccessSignatureClient.refreshPostCreateDeleteSasUri(request, { headers: new Headers({ Authorization: `Bearer ${account.accessToken}` }) });
};

export const retrieveMyUploadsReadSasToken = async (request: Empty): Promise<SasToken> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await sharedAccessSignatureClient.retrieveMyUploadsReadSasToken(request, { headers: new Headers({ Authorization: `Bearer ${account.accessToken}` }) });
};

export const retrieveMyUploadsCreateDeleteSasUri = async (request: Empty): Promise<SasUri> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await sharedAccessSignatureClient.retrieveMyUploadsCreateDeleteSasUri(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const refreshMyUploadsCreateDeleteSasUri = async (request: Empty): Promise<SasUri> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await sharedAccessSignatureClient.refreshMyUploadsCreateDeleteSasUri(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};
