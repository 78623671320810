import { Box, Button, Typography } from '@mui/material';
import React, { ReactNode, useState, useRef, useEffect } from 'react';

interface EclipsedTypographyProps {
  children: ReactNode;
  isBolded?: boolean;
}

export const EclipsedTypography: React.FC<EclipsedTypographyProps> = ({ children, isBolded }) => {
  const [wrapText, setWrapText] = useState(true);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const textRef = useRef<HTMLDivElement | null>(null);

  // Check if the text overflows its container
  useEffect(() => {
    const checkOverflow = () => {
      if (textRef.current) {
        setIsOverflowing(textRef.current.scrollHeight > textRef.current.clientHeight);
      }
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);

    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, []);

  const toggleText = () => {
    setWrapText((prev) => !prev);
  };

  return (
    <Box>
      <Typography
        ref={textRef}
        sx={{
          fontSize: 14,
          fontWeight: isBolded ? 700 : 400,
          overflow: wrapText ? 'hidden' : 'visible',
          textOverflow: wrapText ? 'ellipsis' : 'unset',
          display: '-webkit-box',
          WebkitLineClamp: wrapText ? 2 : 'unset',
          WebkitBoxOrient: 'vertical'
        }}
      >
        {children}
      </Typography>

      {/* Show More / Show Less button */}
      {isOverflowing && (
        <Button
          variant="text"
          sx={{
            color: (theme) => theme.palette.customTheme.focusItem,
            fontWeight: 700,
            fontSize: 14,
            cursor: 'pointer',
            margin: 0,
            padding: 0,
            display: 'inline'
          }}
          onClick={toggleText}
        >
          {wrapText ? 'Show More' : 'Show Less'}
        </Button>
      )}
    </Box>
  );
};
