import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import {
  EmployeeRecord,
  ErbFieldValue,
  RetrieveEmployeeRecordRequest,
  TableErbFieldValue,
  TextErbFieldValue,
  UpdateErSectionRequest
} from '@thrivea/organization-client';
import { RootState } from '@app/store';
import { ActionStatus } from 'src/shared';
import { getScalarFieldValue } from '@features/employee-record-page';
import { selectErbScalarFieldsBySectionId } from '@features/employee-record-builder';
import { keyBy, map, mapValues } from 'lodash';

export interface EmployeeRecordState {
  entities: {
    employeeRecordFieldValues: {
      byId: { [key: string]: ErbFieldValue };
      allIds: string[];
    };
  };
  ui: {
    retrieveEmployeeRecordRequestedStatus: ActionStatus;
    updateErSectionRequestedStatus: ActionStatus;
    retrieveEmployeeItemsRequestedStatus: ActionStatus;
    resetEmployeeRecordFormValues: boolean;
  };
  employeeRecordProfilePicture: string;
  employeeRecordCoverPicture: string;
}

const initialState: EmployeeRecordState = {
  entities: {
    employeeRecordFieldValues: {
      byId: {},
      allIds: []
    }
  },
  ui: {
    retrieveEmployeeRecordRequestedStatus: ActionStatus.Idle,
    updateErSectionRequestedStatus: ActionStatus.Idle,
    retrieveEmployeeItemsRequestedStatus: ActionStatus.Idle,
    resetEmployeeRecordFormValues: false
  },
  employeeRecordProfilePicture: '',
  employeeRecordCoverPicture: ''
};

export const employeeRecordSlice = createSlice({
  name: 'employeeRecord',
  initialState,
  reducers: {
    retrieveEmployeeRecordRequested: (state, _action: PayloadAction<RetrieveEmployeeRecordRequest>) => {
      state.ui.retrieveEmployeeRecordRequestedStatus = ActionStatus.Pending;
      state.entities.employeeRecordFieldValues.byId = {};
      state.entities.employeeRecordFieldValues.allIds = [];
    },
    retrieveEmployeeRecordSucceeded: (state, action: PayloadAction<EmployeeRecord>) => {
      const { fieldValues, profilePictureUrl, coverPictureUrl } = action.payload;

      for (const value of fieldValues) {
        state.entities.employeeRecordFieldValues.byId[value.erbFieldId] = value;
        state.entities.employeeRecordFieldValues.allIds.push(value.erbFieldId);
      }

      state.employeeRecordProfilePicture = profilePictureUrl;
      state.employeeRecordCoverPicture = coverPictureUrl;

      state.ui.retrieveEmployeeRecordRequestedStatus = ActionStatus.Idle;
    },
    retrieveEmployeeRecordFailed: (state) => {
      state.ui.retrieveEmployeeRecordRequestedStatus = ActionStatus.Failed;
    },
    updateErSectionRequested: (state, _action: PayloadAction<UpdateErSectionRequest>) => {
      state.ui.updateErSectionRequestedStatus = ActionStatus.Pending;
    },
    updateErSectionSucceeded: (state, action: PayloadAction<UpdateErSectionRequest>) => {
      const { changedFieldValues } = action.payload;

      for (const value of changedFieldValues) {
        if (value.kind.case !== 'tableValues') {
          state.entities.employeeRecordFieldValues.byId[value.erbFieldId] = value;
        } else {
          const tableFieldValue = state.entities.employeeRecordFieldValues.byId[value.erbFieldId].kind.value! as TableErbFieldValue;
          tableFieldValue.rows.push(...value.kind.value.rows);
          state.entities.employeeRecordFieldValues.byId[value.erbFieldId] = new ErbFieldValue({
            erbFieldId: value.erbFieldId,
            kind: { case: 'tableValues', value: tableFieldValue }
          });
        }
      }
      state.ui.updateErSectionRequestedStatus = ActionStatus.Idle;
      state.ui.resetEmployeeRecordFormValues = true;
    },
    updateErSectionFailed: (state) => {
      state.ui.updateErSectionRequestedStatus = ActionStatus.Failed;
    },
    employeeRecordFormValuesResetted: (state) => {
      state.ui.resetEmployeeRecordFormValues = false;
    },

    updateCalculatedFields: (state, action: PayloadAction<{ erbFieldId: string; value: any }>) => {
      for (const fieldId of Object.keys(action.payload)) {
        switch (state.entities.employeeRecordFieldValues.byId[fieldId].kind.case) {
          case 'textValue':
            state.entities.employeeRecordFieldValues.byId[fieldId].kind.value.value = action.payload[fieldId];
            break;
          case 'numberValue':
            state.entities.employeeRecordFieldValues.byId[fieldId].kind.value.value = action.payload[fieldId];
            break;
          case 'dateValue':
            state.entities.employeeRecordFieldValues.byId[fieldId].kind.value.value = action.payload[fieldId];
            break;
        }
      }
    }
  }
});

const selectErbFieldValuesById = (state: RootState) => state.employeeRecord.entities.employeeRecordFieldValues.byId;
const selectErbFieldValuesIds = (state: RootState) => state.employeeRecord.entities.employeeRecordFieldValues.allIds;

export const selectErbFieldValues = createSelector([selectErbFieldValuesById, selectErbFieldValuesIds], (byId, ids) => ids.map((id) => byId[id]));

export const selectErbFieldValueById = (state: RootState, fieldId: string) => state.employeeRecord.entities.employeeRecordFieldValues.byId[fieldId];

export const selectEmployeeRecordProfilePicture = (state: RootState) => state.employeeRecord.employeeRecordProfilePicture;
export const selectEmployeeRecordCoverPicture = (state: RootState) => state.employeeRecord.employeeRecordCoverPicture;

export const selectRetrieveEmployeeRecordRequestedStatus = (state: RootState) => state.employeeRecord.ui.retrieveEmployeeRecordRequestedStatus;

export const selectResetEmployeeRecordValues = (state: RootState) => state.employeeRecord.ui.resetEmployeeRecordFormValues;

export const selectErbScalarFieldDefaultValuesBySectionId = createSelector(
  [selectErbFieldValuesById, selectErbScalarFieldsBySectionId, selectErbFieldValues],
  (fieldValuesById, erbFieldsInSection, erbFieldValues) => {
    const erbFieldsAndValues = map(erbFieldsInSection, (field) => ({
      erbScalarField: field!,
      erbFieldValue: fieldValuesById[field!.id]
    }));

    return mapValues(
      keyBy(erbFieldsAndValues, (fav) => fav.erbScalarField.id),
      (fav) => getScalarFieldValue(fav.erbScalarField, erbFieldValues) ?? ''
    );
  }
);

export const {
  retrieveEmployeeRecordRequested,
  retrieveEmployeeRecordSucceeded,
  retrieveEmployeeRecordFailed,
  updateErSectionRequested,
  updateErSectionSucceeded,
  updateErSectionFailed,
  updateCalculatedFields,
  employeeRecordFormValuesResetted
} = employeeRecordSlice.actions;

export default employeeRecordSlice.reducer;
