import { Tab, TabProps, styled, tabClasses } from '@mui/material';

export const EmployeeRecordTab = styled(Tab)<TabProps>(({ theme }) => ({
  position: 'relative',
  backgroundColor: 'transparent',
  height: 44,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '6px 14px',
  borderRadius: 0,
  fontSize: 14,
  fontWeight: 700,
  '&:after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: 0,
    height: 2,
    backgroundColor: 'transparent',
    transition: theme.transitions.create(['background-color', 'color', 'width'])
  },
  [`&.${tabClasses.selected}`]: {
    backgroundColor: 'transparent',
    color: theme.palette.customTheme.navColorSelected,
    '&:after': {
      width: '100%',
      backgroundColor: theme.palette.customTheme.navColorSelected
    }
  },
  '&:hover': {
    color: theme.palette.customTheme.navColorSelected,
    backgroundColor: 'transparent',
    '&:after': {
      width: '100%',
      backgroundColor: theme.palette.customTheme.navColorSelected
    }
  }
}));
