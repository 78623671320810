import { Stack, StackProps, styled } from '@mui/material';

interface PlanTypeContentStyleProps {
  backgroundColor: string;
  borderColor: string;
  textColor: string;
}

export enum PlanType {
  Free,
  Starter,
  Growth,
  Enterprise
}

interface PlanTypeContentProps extends StackProps {
  planType: PlanType;
  isSelected?: boolean;
}

export const PlanTypeContent = styled(Stack)<PlanTypeContentProps>(({ planType, isSelected, theme }) => {
  const row: PlanTypeContentStyleProps[] = [
    { backgroundColor: theme.palette.customTheme.zebraFreezeOne, borderColor: theme.palette.primary.main, textColor: theme.palette.primary.main },
    { backgroundColor: theme.palette.customTheme.zebraFreezeOne, borderColor: theme.palette.primary.main, textColor: theme.palette.primary.main },
    { backgroundColor: theme.palette.customTheme.selectLabel, borderColor: theme.palette.secondary.main, textColor: theme.palette.primary.main },
    { backgroundColor: theme.palette.customTheme.zebraFreezeOne, borderColor: theme.palette.primary.main, textColor: theme.palette.primary.main }
  ];

  let idx = PlanType.Free;
  if (planType === PlanType.Starter) idx = PlanType.Starter;
  if (planType === PlanType.Growth) idx = PlanType.Growth;
  if (planType === PlanType.Enterprise) idx = PlanType.Enterprise;

  return {
    backgroundColor: row[idx].backgroundColor,
    color: row[idx].textColor,
    border: '2px solid',
    borderColor: isSelected ? theme.palette.customTheme.focusItem : row[idx].borderColor,
    padding: '16px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: '36px',
    borderRadius: '12px',
    minHeight: 199,
    width: '100%',
    position: 'relative'
  };
});
