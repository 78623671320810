import { Fragment } from 'react/jsx-runtime';
import { CircleFilledIcon, CircleOutlinedIcon, RowCenterStack } from '@/shared';

interface AssetsTreeItemIconProps {
  level: number;
}

export const AssetsTreeItemIcon: React.FC<AssetsTreeItemIconProps> = ({ level }) => {
  return (
    <RowCenterStack gap={'4px'}>
      {Array.from({ length: level - 1 }).map((_, index) => (
        <CircleOutlinedIcon key={index} width={5} height={5} />
      ))}
      <CircleFilledIcon width={5} height={5} />
    </RowCenterStack>
  );
};
