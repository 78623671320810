import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@app/store';
import { EmployeeListItem, PeopleDirView } from '@thrivea/organization-client';
import { sortBy, uniq } from 'lodash';
import { ALL_TASKS_ID } from './tasks.service';
import { Task, TaskStatus, TaskViewMode } from '@features/tasks';

export interface TaskState {
  entities: {
    taskViews: {
      // TODO @Stevo same interface change later -> TaskView
      byId: { [key: string]: PeopleDirView };
      savedIds: string[];
      unsavedIds: string[];
      hiddenIds: string[];
    };
    assigneList: {
      byInitialId: { [key: string]: EmployeeListItem };
      byUpdatedId: { [key: string]: EmployeeListItem };
      initialIds: string[];
      updatedIds: string[];
      addedIds: string[];
      removedIds: string[];
    };
    reviewersList: {
      byInitialId: { [key: string]: EmployeeListItem };
      byUpdatedId: { [key: string]: EmployeeListItem };
      initialIds: string[];
      updatedIds: string[];
      addedIds: string[];
      removedIds: string[];
    };
  };
  ui: {
    taskViewMode: TaskViewMode;
  };
  activeTaskViewId: string;
}

const initialState: TaskState = {
  entities: {
    taskViews: {
      byId: {},
      savedIds: [],
      unsavedIds: [],
      hiddenIds: []
    },
    assigneList: {
      byInitialId: {},
      byUpdatedId: {},
      initialIds: [],
      updatedIds: [],
      addedIds: [],
      removedIds: []
    },
    reviewersList: {
      byInitialId: {},
      byUpdatedId: {},
      initialIds: [],
      updatedIds: [],
      addedIds: [],
      removedIds: []
    }
  },
  ui: {
    taskViewMode: TaskViewMode.List
  },
  activeTaskViewId: ALL_TASKS_ID
};

export const taskSlice = createSlice({
  name: 'task',
  initialState,
  reducers: {
    loadTasksRequested: (state, _action: PayloadAction<string>) => {},
    loadTasksSucceeded: (state, action: PayloadAction) => {},
    setActiveTaskId: (state, action: PayloadAction<string>) => {
      state.activeTaskViewId = action.payload;
    },
    setActiveTaskViewMode: (state, action: PayloadAction<TaskViewMode>) => {
      state.ui.taskViewMode = action.payload;
    },
    addEmployeeToAssigneeList: (state, action: PayloadAction<EmployeeListItem[]>) => {
      const assigneList = action.payload;

      for (const employee of assigneList) {
        state.entities.assigneList.byUpdatedId[employee.employeeId] = employee;
        state.entities.assigneList.updatedIds.push(employee.employeeId);
        state.entities.assigneList.addedIds.push(employee.employeeId);
      }
    },
    removeEmployeeFromAssigneeList: (state, action: PayloadAction<string>) => {
      state.entities.assigneList.removedIds.push(action.payload);
    },
    addEmployeeToReviewersList: (state, action: PayloadAction<EmployeeListItem[]>) => {
      const assigneList = action.payload;

      for (const employee of assigneList) {
        state.entities.reviewersList.byUpdatedId[employee.employeeId] = employee;
        state.entities.reviewersList.updatedIds.push(employee.employeeId);
        state.entities.reviewersList.addedIds.push(employee.employeeId);
      }
    },
    removeEmployeeFromReviewersList: (state, action: PayloadAction<string>) => {
      state.entities.reviewersList.removedIds.push(action.payload);
    }
  }
});

export const selectActiveTaskViewId = (state: RootState) => state.tasks.activeTaskViewId;
export const selectTaskViewMode = (state: RootState) => state.tasks.ui.taskViewMode;

export const selectTasksViewsById = (state: RootState) => state.tasks.entities.taskViews.byId;
export const selectTasksViewsSavedIds = (state: RootState) => state.tasks.entities.taskViews.savedIds;
export const selectTasksViewsUnsavedIds = (state: RootState) => state.tasks.entities.taskViews.unsavedIds;
export const selectTasksDirViewsHiddenIds = (state: RootState) => state.tasks.entities.taskViews.hiddenIds;

const selectAllTasksViewsIds = createSelector([selectTasksViewsSavedIds, selectTasksViewsUnsavedIds], (savedIds, unsavedIds) =>
  uniq([...savedIds, ...unsavedIds])
);

export const selectTasksViews = createSelector([selectAllTasksViewsIds, selectTasksViewsById], (visibleViewIds, byId) => visibleViewIds.map((id) => byId[id]));

export const selectAssigneeByInitialId = (state: RootState) => state.tasks.entities.assigneList.byInitialId;
export const selectAssigneeByUpdatedId = (state: RootState) => state.tasks.entities.assigneList.byUpdatedId;
export const selectAssigneeInitialIds = (state: RootState) => state.tasks.entities.assigneList.initialIds;
export const selectAssigneeUpdatedIds = (state: RootState) => state.tasks.entities.assigneList.updatedIds;
export const selectAssigneeAddedIds = (state: RootState) => state.tasks.entities.assigneList.addedIds;
export const selectAssigneeRemovedIds = (state: RootState) => state.tasks.entities.assigneList.removedIds;

export const selectAssigneeIds = createSelector(
  [selectAssigneeInitialIds, selectAssigneeAddedIds, selectAssigneeUpdatedIds, selectAssigneeRemovedIds],
  (initialIds, addedIds, updatedIds, removedIds) => Array.from(new Set([...initialIds, ...addedIds, ...updatedIds].filter((id) => !removedIds.includes(id))))
);

export const selectAssignees = createSelector(
  [selectAssigneeIds, selectAssigneeByInitialId, selectAssigneeByUpdatedId, selectAssigneeUpdatedIds],
  (selectedAssigneesIds, byInitialId, byUpdatedId, updatedIds) =>
    sortBy(
      selectedAssigneesIds
        .filter((id) => !updatedIds.includes(id))
        .map((id) => byInitialId[id])
        .concat(updatedIds.map((id) => byUpdatedId[id])),
      ['name']
    )
);

export const selectReviewersByInitialId = (state: RootState) => state.tasks.entities.reviewersList.byInitialId;
export const selectReviewersByUpdatedId = (state: RootState) => state.tasks.entities.reviewersList.byUpdatedId;
export const selectReviewerInitialIds = (state: RootState) => state.tasks.entities.reviewersList.initialIds;
export const selectReviewerUpdatedIds = (state: RootState) => state.tasks.entities.reviewersList.updatedIds;
export const selectReviewerAddedIds = (state: RootState) => state.tasks.entities.reviewersList.addedIds;
export const selectReviewerRemovedIds = (state: RootState) => state.tasks.entities.reviewersList.removedIds;

export const selectReviewersIds = createSelector(
  [selectReviewerInitialIds, selectReviewerAddedIds, selectReviewerUpdatedIds, selectReviewerRemovedIds],
  (initialIds, addedIds, updatedIds, removedIds) => Array.from(new Set([...initialIds, ...addedIds, ...updatedIds].filter((id) => !removedIds.includes(id))))
);

export const selectReviewers = createSelector(
  [selectReviewersIds, selectReviewersByInitialId, selectReviewersByUpdatedId, selectReviewerUpdatedIds],
  (selectedReviewers, byInitialId, byUpdatedId, updatedIds) =>
    sortBy(
      selectedReviewers
        .filter((id) => !updatedIds.includes(id))
        .map((id) => byInitialId[id])
        .concat(updatedIds.map((id) => byUpdatedId[id])),
      ['name']
    )
);

export const selectTempDependancyTasks = (state: RootState) => {
  const tempTasks: Task[] = [
    {
      id: '#M9N001-P2',
      title: 'Design Homepage',
      description: 'Create a responsive design for the homepage.',
      status: TaskStatus.Assigned,
      dueDate: '2024-12-01',
      reviewer: 'John Doe'
    },
    {
      id: '#M9N002-P2',
      title: 'Write Documentation',
      description: 'Prepare technical documentation for the API.',
      status: TaskStatus.Assigned,
      dueDate: '2024-12-05',
      reviewer: 'Jane Smith'
    },
    {
      id: '#M9N003-P2',
      title: 'Code Review',
      description: 'Review the pull request for the new feature.',
      status: TaskStatus.Assigned,
      dueDate: '2024-11-10',
      reviewer: 'Alice Johnson'
    },
    {
      id: '#M9N004-P2',
      title: 'Fix Bugs',
      description: 'Resolve high-priority bugs in the production environment.',
      status: TaskStatus.Assigned,
      dueDate: '2024-11-20',
      reviewer: 'Bob Brown'
    }
  ];

  return tempTasks;
};

export const {
  loadTasksRequested,
  loadTasksSucceeded,
  setActiveTaskId,
  setActiveTaskViewMode,
  addEmployeeToAssigneeList,
  removeEmployeeFromAssigneeList,
  addEmployeeToReviewersList,
  removeEmployeeFromReviewersList
} = taskSlice.actions;
export default taskSlice.reducer;
