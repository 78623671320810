import { CloseIcon, PrimaryDarkButton, StyledBlurryModal, StyledModalContentAutoWidth } from '@/shared';
import Grid from '@mui/material/Grid2';
import { IconButton, Stack } from '@mui/material';
import { TaskAssetsModalNav, TaskNewCommentAssetsListView } from '@features/drawer';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '@app/hooks';
import { resetNewCommentAttachmentUrls } from '@features/tasks';

interface TaskNewCommentModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const TaskNewCommentModal: React.FC<TaskNewCommentModalProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation(['common']);
  const dispatch = useAppDispatch();

  const handleCloseButtonClick = () => {
    dispatch(resetNewCommentAttachmentUrls());
    onClose();
  };

  return (
    <StyledBlurryModal open={isOpen} onClose={onClose}>
      <StyledModalContentAutoWidth
        sx={{
          position: 'relative',
          maxWidth: {
            xs: 'auto',
            lg: '80%'
          },
          height: {
            xs: '90%'
          },
          padding: '16px',
          display: 'flex',
          borderRadius: '20px',
          boxShadow: 'none'
        }}
      >
        <IconButton onClick={handleCloseButtonClick} sx={{ ml: 'auto' }}>
          <CloseIcon width={24} height={24} />
        </IconButton>
        <Grid container sx={{ height: '100%' }} columnSpacing={'12px'}>
          <Grid size={3}>
            <TaskAssetsModalNav />
          </Grid>
          <Grid size={9}>
            <TaskNewCommentAssetsListView />
          </Grid>
        </Grid>
        <Stack
          sx={{
            alignItems: 'flex-end'
          }}
        >
          <PrimaryDarkButton onClick={onClose}>{t('add', { ns: 'common' })}</PrimaryDarkButton>
        </Stack>
      </StyledModalContentAutoWidth>
    </StyledBlurryModal>
  );
};
