import { LinearProgress, LinearProgressProps, linearProgressClasses, styled } from '@mui/material';

export const BIllingProgressBar = styled(LinearProgress)<LinearProgressProps>(({ theme }) => ({
  backgroundColor: theme.palette.customTheme.selectItem,
  padding: '3px 7px',
  height: 28,
  borderRadius: 8,
  [`& .${linearProgressClasses.bar1Determinate}`]: {
    backgroundColor: theme.palette.customTheme.focusItem
  }
}));
