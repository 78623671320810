import { useTheme } from '@mui/material';
import React from 'react';

interface DuplicateIconProps {
  width?: number;
  height?: number;
  color?: string;
}

export const DuplicateIcon: React.FC<DuplicateIconProps> = ({ width = 24, height = 24, color = '#FFFFFF' }) => {
  const theme = useTheme();

  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
      <g fill={color} fillRule="evenodd" clipRule="evenodd">
        <path d="M0 15.38c0 .401.326.727.727.727H10.91a.727.727 0 0 0 .727-.727V5.198a.727.727 0 0 0-.727-.727H.727A.727.727 0 0 0 0 5.198V15.38Zm1.455-.728V5.925h8.727v8.727H1.455Z" />
        <path d="M14.545 10.29H10.91v1.454h4.363a.727.727 0 0 0 .728-.727V.835a.727.727 0 0 0-.728-.728H5.091a.727.727 0 0 0-.728.728v4.363h1.455V1.562h8.727v8.727ZM5.09 13.198V7.38h1.455v5.818H5.091Z" />
        <path d="M8.727 11.016H2.91V9.562h5.818v1.454Z" />
      </g>
    </svg>
  );
};
