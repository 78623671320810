import React from 'react';

interface VideoTypeFileIconProps {
  width?: number;
  height?: number;
  color?: string;
}

export const VideoTypeFileIcon: React.FC<VideoTypeFileIconProps> = ({ width = 24, height = 24, color = '#000000' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 76 76" fill="none">
      <path
        d="M1.5 8C1.5 4.41015 4.41015 1.5 8 1.5H68C71.5898 1.5 74.5 4.41015 74.5 8V68C74.5 71.5899 71.5899 74.5 68 74.5H8C4.41015 74.5 1.5 71.5899 1.5 68V8Z"
        stroke={color}
        strokeWidth="3"
      />
      <path d="M1 12.2856H12.5" stroke={color} strokeWidth="3" strokeLinejoin="round" />
      <path d="M1 32.8569H12.5" stroke={color} strokeWidth="3" strokeLinejoin="round" />
      <path d="M1 22.5713H12.5" stroke={color} strokeWidth="3" strokeLinejoin="round" />
      <path d="M1 43.1431H12.5" stroke={color} strokeWidth="3" strokeLinejoin="round" />
      <path d="M1 53.4287H12.5" stroke={color} strokeWidth="3" strokeLinejoin="round" />
      <path d="M1 63.7144H12.5" stroke={color} strokeWidth="3" strokeLinejoin="round" />
      <path d="M13 2V74.5" stroke={color} strokeWidth="3" strokeLinejoin="round" />
      <path d="M75 12.2856H63.5" stroke={color} strokeWidth="3" strokeLinejoin="round" />
      <path d="M75 22.5713H63.5" stroke={color} strokeWidth="3" strokeLinejoin="round" />
      <path d="M75 32.8569H63.5" stroke={color} strokeWidth="3" strokeLinejoin="round" />
      <path d="M75 43.1431H63.5" stroke={color} strokeWidth="3" strokeLinejoin="round" />
      <path d="M75 53.4287H63.5" stroke={color} strokeWidth="3" strokeLinejoin="round" />
      <path d="M75 63.7144H63.5" stroke={color} strokeWidth="3" strokeLinejoin="round" />
      <path d="M63 2V74.5" stroke={color} strokeWidth="3" strokeLinejoin="round" />
      <path d="M53 38L29 51.8564L29 24.1436L53 38Z" stroke={color} strokeWidth="3" strokeLinejoin="round" />
    </svg>
  );
};
