import { styled, Button, ButtonProps } from '@mui/material';

export const ScheduleCallButton = styled(Button)<ButtonProps>(({ theme }) => ({
  height: 43,
  backgroundColor: theme.palette.common.black,
  borderRadius: '10px',
  color: theme.palette.common.white,
  fontWeight: 700,
  padding: '12px 32px',
  '& path': {
    fill: theme.palette.common.white
  },
  '&.Mui-disabled': {
    '& path': {
      fill: 'rgba(0, 0, 0, 0.12)'
    }
  }
}));
