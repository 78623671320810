import { styled, Typography, TypographyProps } from '@mui/material';

export const BillingProgressTypography = styled(Typography)<TypographyProps>(() => ({
  position: 'absolute',
  top: 5,
  left: '50%',
  transform: 'translateX(-50%)',
  fontSize: 14,
  fontWeight: 700
}));
