import { Empty } from '@bufbuild/protobuf';
import { createClient } from '@connectrpc/connect';
import { createGrpcWebTransport } from '@connectrpc/connect-web';
import {
  CreateOrganizationRequest,
  OrganizationService,
  RetrieveIndustryCategoryResponse,
  RetrieveIndustrySubCategoriesRequest,
  RetrieveIndustrySubCategoriesResponse,
  RetrieveWorkingPatternsResponse,
  UpdateEmployeeIdStepRequest,
  UpdateIncorporationStepRequest,
  UpdateNameAndIndustryStepRequest,
  UpdateSiteCalendarStepRequest,
  UpsertSiteDetailsStepRequest,
  UpsertLocalizationStepRequest,
  RetrieveAdminOnboardingFlowResponse,
  RetrieveAdminOnboardingFlowStepsResponse,
  UpdateSiteWorkingPatternStepRequest,
  RetrieveImportedEmployeesRequest,
  ImportEmployeesRequest,
  UpdateManagerToEmployeesRequest,
  RetrieveAdminOnboardingFlowStateResponse,
  RetrieveAuthorsRequest,
  RetrieveAuthorsResponse,
  RetrieveUserResponse,
  Employees,
  UpsertSiteDetailsStepResponse,
  RetrieveAssignedManagersRequest,
  AssignedManagers
} from '@thrivea/organization-client';
import { msalInstance } from 'src/main';
import { tokenRequest } from '@utils/authConfig';

export const organizationClient = createClient(
  OrganizationService,
  createGrpcWebTransport({
    baseUrl: import.meta.env.VITE_APP_API_URL,
    useBinaryFormat: true
  })
);

export const createOrganization = async (request: CreateOrganizationRequest): Promise<Empty> => {
  return await organizationClient.createOrganization(request);
};

export const retrieveIndustryCategories = async (request: Empty): Promise<RetrieveIndustryCategoryResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await organizationClient.retrieveIndustryCategories(request, { headers: new Headers({ Authorization: `Bearer ${account.accessToken}` }) });
};

export const retrieveIndustrySubcategories = async (request: RetrieveIndustrySubCategoriesRequest): Promise<RetrieveIndustrySubCategoriesResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await organizationClient.retrieveIndustrySubCategories(request, { headers: new Headers({ Authorization: `Bearer ${account.accessToken}` }) });
};

export const updateNameAndIndustryStep = async (request: UpdateNameAndIndustryStepRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await organizationClient.updateNameAndIndustryStep(request, { headers: new Headers({ Authorization: `Bearer ${account.accessToken}` }) });
};

export const updateIncorporationStep = async (request: UpdateIncorporationStepRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await organizationClient.updateIncorporationStep(request, { headers: new Headers({ Authorization: `Bearer ${account.accessToken}` }) });
};

export const updateEmployeeIdStep = async (request: UpdateEmployeeIdStepRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await organizationClient.updateEmployeeIdStep(request, { headers: new Headers({ Authorization: `Bearer ${account.accessToken}` }) });
};

export const upsertSiteDetailsStep = async (request: UpsertSiteDetailsStepRequest): Promise<UpsertSiteDetailsStepResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await organizationClient.upsertSiteDetailsStep(request, { headers: new Headers({ Authorization: `Bearer ${account.accessToken}` }) });
};

export const upsertLocalizationStep = async (request: UpsertLocalizationStepRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await organizationClient.upsertLocalizationStep(request, { headers: new Headers({ Authorization: `Bearer ${account.accessToken}` }) });
};

export const updateSiteCalendarStep = async (request: UpdateSiteCalendarStepRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await organizationClient.updateSiteCalendarStep(request, { headers: new Headers({ Authorization: `Bearer ${account.accessToken}` }) });
};

export const retrieveWorkingPatterns = async (request: Empty): Promise<RetrieveWorkingPatternsResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await organizationClient.retrieveWorkingPatterns(request, { headers: new Headers({ Authorization: `Bearer ${account.accessToken}` }) });
};

export const updateSiteWorkingPatternStep = async (request: UpdateSiteWorkingPatternStepRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await organizationClient.updateSiteWorkingPatternStep(request, { headers: new Headers({ Authorization: `Bearer ${account.accessToken}` }) });
};

export const retrieveAdminOnboardingFlowSteps = async (request: Empty): Promise<RetrieveAdminOnboardingFlowStepsResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await organizationClient.retrieveAdminOnboardingFlowSteps(request, { headers: new Headers({ Authorization: `Bearer ${account.accessToken}` }) });
};

export const retrieveAdminOnboardingFlow = async (request: Empty): Promise<RetrieveAdminOnboardingFlowResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await organizationClient.retrieveAdminOnboardingFlow(request, { headers: new Headers({ Authorization: `Bearer ${account.accessToken}` }) });
};

export const importEmployeesStep = async (request: ImportEmployeesRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await organizationClient.importEmployees(request, { headers: new Headers({ Authorization: `Bearer ${account.accessToken}` }) });
};

export const retrieveImportedEmployees = async (request: RetrieveImportedEmployeesRequest): Promise<Employees> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await organizationClient.retrieveImportedEmployees(request, { headers: new Headers({ Authorization: `Bearer ${account.accessToken}` }) });
};

export const updateManagerToEmployees = async (request: UpdateManagerToEmployeesRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await organizationClient.updateManagerToEmployees(request, { headers: new Headers({ Authorization: `Bearer ${account.accessToken}` }) });
};

export const retrieveAssignedManagers = async (request: RetrieveAssignedManagersRequest): Promise<AssignedManagers> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await organizationClient.retrieveAssignedManagers(request, { headers: new Headers({ Authorization: `Bearer ${account.accessToken}` }) });
};

export const retrieveAdminOnboardingFlowState = async (request: Empty): Promise<RetrieveAdminOnboardingFlowStateResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await organizationClient.retrieveAdminOnboardingFlowState(request, { headers: new Headers({ Authorization: `Bearer ${account.accessToken}` }) });
};

export const retrieveAuthors = async (request: RetrieveAuthorsRequest): Promise<RetrieveAuthorsResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await organizationClient.retrieveAuthors(request, { headers: new Headers({ Authorization: `Bearer ${account.accessToken}` }) });
};

export const retrieveUser = async (request: Empty): Promise<RetrieveUserResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await organizationClient.retrieveUser(request, { headers: new Headers({ Authorization: `Bearer ${account.accessToken}` }) });
};

export const sendEmployeeInvitationEMails = async (request: Empty): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await organizationClient.sendEmployeeInvitationEmails(request, { headers: new Headers({ Authorization: `Bearer ${account.accessToken}` }) });
};
