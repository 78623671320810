import { create } from '@bufbuild/protobuf';
import { store } from '@app/store';
import { FileWithStatus, UploadedFile } from '@api/blob-storage.api';
import { fileUploaded, updateFileUploadProgress, uploadFile } from '@features/assets';
import { UploadFile, FileType, SortFile, OrderDirection, UploadFileSchema } from '@thrivea/organization-client';
import { isDocumentMimeType, isImageMimeType, isVideoMimeType } from 'src/utils';
import { isArchiveMimeType } from '@utils/isArchive';
import { first } from 'lodash';
import { Order } from 'src/shared';

export const handleUpdateFileUploadProgress = (fileProgress: number, totalProgress: number): void => {
  store.dispatch(updateFileUploadProgress({ fileProgress, totalProgress }));
};

export const handleStartUploadingFile = (fileWithStatus: FileWithStatus): void => {
  store.dispatch(uploadFile(fileWithStatus));
};

export const handleFileUploaded = (uploadedFile: UploadedFile): void => {
  store.dispatch(fileUploaded(uploadedFile));
};

// Helper function to map MIME type to FileType
export const mapMimeTypeToFileType = (mimeType: string): FileType => {
  if (mimeType === 'application/pdf') return FileType.PDF;

  if (mimeType === 'directory') return FileType.FOLDER;

  if (isDocumentMimeType(mimeType)) return FileType.DOCUMENT;

  if (isImageMimeType(mimeType)) return FileType.PHOTO;

  if (isVideoMimeType(mimeType)) return FileType.VIDEO;

  if (isArchiveMimeType(mimeType)) return FileType.ARCHIVES;

  return FileType.DOCUMENT;
};

// Helper function to find or create a folder in the given level
const findOrCreateFolder = (name: string, currentLevel: UploadFile[]): UploadFile => {
  let existingFolder = currentLevel.find((file) => file.name === name && file.type === FileType.FOLDER);

  if (!existingFolder) {
    existingFolder = create(UploadFileSchema, {
      id: crypto.randomUUID(),
      url: '', // Folders don't have URLs
      name: name,
      type: FileType.FOLDER,
      sizeInBytes: 0,
      uploadedAt: new Date().toISOString(),
      childrenFiles: []
    });

    currentLevel.push(existingFolder);
  }

  return existingFolder;
};

// Build the tree structure from acceptedFiles
export const buildFileTree = (filesWithStatuses: FileWithStatus[]): UploadFile[] => {
  const root: UploadFile[] = [];

  for (const fws of filesWithStatuses) {
    let pathParts = fws.file.path!.split('/');
    if (first(pathParts) === '.') pathParts = pathParts.slice(1);

    let currentLevel = root;

    for (let i = 0; i < pathParts.length; i++) {
      const part = pathParts[i];
      const isFile = i === pathParts.length - 1;

      if (isFile) {
        // It's a file, so we create the file and add it to the current level
        const fileEntry = create(UploadFileSchema, {
          id: fws.id,
          url: fws.url, // This URL is temporary and will be replaced after upload
          name: part,
          type: mapMimeTypeToFileType(fws.file.type), // Use MIME type to set file type
          sizeInBytes: fws.file.size,
          uploadedAt: new Date().toISOString(),
          childrenFiles: [] // Files don't have children
        });

        currentLevel.push(fileEntry);
      } else {
        // It's a folder, so we either find or create it and move into its children
        const folder = findOrCreateFolder(part, currentLevel);
        currentLevel = folder.childrenFiles; // Move into the folder's children
      }
    }
  }

  return root;
};

export const sortByField = (sort: SortFile) => SORT_FILE_TO_SORT_BY_FIELD[sort];

export const invertToOrder = (sortDirection: Order) => INVERT_ORDER[sortDirection];

export const invertToOrderDirection = (sortDirection: Order) => INVERT_TO_ORDER_DIRECTION[sortDirection];

export const toSortFile = (sortBy: string) => SORT_BY_FIELD_TO_SORT_FILE[sortBy];

export const toOrderDirection = (direction: Order) => TO_ORDER_DIRECTION[direction];

const SORT_FILE_TO_SORT_BY_FIELD: Record<SortFile, string> = {
  [SortFile.NAME]: 'name',
  [SortFile.SIZE]: 'sizeInBytes',
  [SortFile.TYPE]: 'type',
  [SortFile.SORT_FILES_UNDEFINED]: ''
};

const SORT_BY_FIELD_TO_SORT_FILE: Record<string, SortFile> = {
  ['name']: SortFile.NAME,
  ['sizeInBytes']: SortFile.SIZE,
  ['type']: SortFile.TYPE
};

const INVERT_ORDER: Record<Order, Order> = {
  ['asc']: 'desc',
  ['desc']: 'asc'
};

const TO_ORDER_DIRECTION: Record<Order, OrderDirection> = {
  ['asc']: OrderDirection.ASCENDING,
  ['desc']: OrderDirection.DESCENDING
};

const INVERT_TO_ORDER_DIRECTION: Record<Order, OrderDirection> = {
  ['asc']: OrderDirection.DESCENDING,
  ['desc']: OrderDirection.ASCENDING
};
