import React, { ReactNode, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { Navigation, selectNavigationIsDesktopOpen, selectNavigationWidth } from '@features/navigation';
import { retrieveOrganizationLogoSasTokenRequested, retrieveOrganizationRequested } from '@features/admin-settings';
import { AsideGridItem } from './styled/AsideGridItem';
import { GridContainer } from './styled/GridContainer';
import { GridContentItem } from './styled/GridContentItem';

interface ErrorLayoutProps {
  children: ReactNode;
}

export const ErrorLayout: React.FC<ErrorLayoutProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const navOpen = useAppSelector(selectNavigationIsDesktopOpen);
  const navWidth = useAppSelector(selectNavigationWidth);

  useEffect(() => {
    const loaderElem = document.getElementById('loader');

    dispatch(retrieveOrganizationLogoSasTokenRequested());
    dispatch(retrieveOrganizationRequested());

    const loaderTimeout = setTimeout(() => {
      if (loaderElem) {
        loaderElem.remove();
      }
    }, 500);

    return () => {
      clearTimeout(loaderTimeout);
    };
  }, []);

  return (
    <GridContainer container sx={{ padding: 0 }}>
      <AsideGridItem component="aside">
        <Navigation isOpen={navOpen} />
      </AsideGridItem>
      <GridContentItem>{children}</GridContentItem>
    </GridContainer>
  );
};
