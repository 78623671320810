import { Stack, StackProps, styled } from '@mui/material';

export const SiteDetailsHeader = styled(Stack)<StackProps>(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  backgroundColor: theme.palette.customTheme.contBgr,
  borderRadius: 'inherit',
  padding: theme.spacing(2),
  border: '1px solid',
  borderColor: theme.palette.customTheme.contentBorderColor
}));
