import Quill from 'quill';
import { store } from '@app/store';
import { DrawerType, OpenDrawerRequest, openDrawer } from '@features/drawer';

const Embed = Quill.import('blots/embed') as any;

interface EmployeeData {
  id: string;
  name: string;
}

export class EmployeeBlot extends Embed {
  static create(data: EmployeeData) {
    const node = super.create();
    node.classList.add('mention'); // More efficient way to add a class
    node.setAttribute('data-id', data.id);
    node.setAttribute('data-name', data.name);
    node.textContent = `@${data.name}`;

    node.addEventListener('click', () => {
      if (data.id) {
        store.dispatch(openDrawer({ type: DrawerType.SingleEmployee, request: { employeeId: data.id } } as OpenDrawerRequest));
      } else {
        console.error('Employee ID is missing.');
      }
    });

    return node;
  }

  static value(node) {
    return {
      id: node.getAttribute('data-id'),
      name: node.getAttribute('data-name')
    };
  }
}

EmployeeBlot.blotName = 'employee';
EmployeeBlot.tagName = 'span';
