import { Collapse, Fade } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

export const AssetsTransitionComponent = (props: TransitionProps) => {
  return (
    <Collapse
      in={props.in}
      timeout={300}
      style={{
        transformOrigin: '0 0 0',
        transform: props.in ? 'translate3d(0, 0, 0)' : 'translate3d(0, 20px, 0)',
        padding: 0
      }}
      unmountOnExit
      {...props}
    >
      <Fade in={props.in} timeout={300}>
        <div>{props.children}</div>
      </Fade>
    </Collapse>
  );
};
