import { CSSObject, Theme, alpha } from '@mui/material/styles';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import createTheme from '@mui/material/styles/createTheme';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    smob: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    xxl: true;
  }
}

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    customTheme: {
      navColor: string;
      navColorSelected: string;
      navBgr: string;
      contColor: string;
      contColorSub: string;
      contBgr: string;
      bgr: string;
      headerColor: string;
      headerColorSub: string;
      highLight: string;
      defaultItemBgr: string;
      focusItem: string;
      selectItem: string;
      drawerBackground: string;
      drawerColor: string;
      contentBorder: string;
      contentBorderColor: string;
      contentBorderNone: string;
      borderMaster: string;
      solidBgr: string;
      hoverItem: string;
      primaryA: string;
      primaryA40: string;
      primary40: string;
      zebra: string;
      zebraFreezeOne: string;
      zebraFreezeTwo: string;
      selectLabel: string;
      globalContentColorMuted: string;
      brand: string;
      success50: string;
      success100: string;
      error50: string;
      error100: string;
    };
  }

  interface PaletteOptions {
    customTheme?: {
      navColor: string;
      navColorSelected: string;
      navBgr: string;
      contColor: string;
      contColorSub: string;
      contBgr: string;
      bgr: string;
      headerColor: string;
      headerColorSub: string;
      highLight: string;
      defaultItemBgr: string;
      focusItem: string;
      selectItem: string;
      drawerBackground: string;
      drawerColor: string;
      contentBorder: string;
      contentBorderColor: string;
      contentBorderNone: string;
      borderMaster: string;
      solidBgr: string;
      hoverItem: string;
      primaryA: string;
      primaryA40: string;
      primary40: string;
      zebra: string;
      zebraFreezeOne: string;
      zebraFreezeTwo: string;
      selectLabel: string;
      globalContentColorMuted: string;
      brand: string;
      success50: string;
      success100: string;
      error50: string;
      error100: string;
    };
  }
}

export const linkedinTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#251D38',
      light: '#DDD5E8',
      dark: '#7B61FF'
    },
    secondary: {
      main: '#2FBCC4',
      dark: '#CEAEF7',
      light: '#F5EDFF'
    },
    background: {
      default: '#F4F2ED',
      paper: '#FFFFFF'
    },
    text: {
      primary: '#251D38',
      secondary: '#424343'
    },
    success: {
      main: '#2FBCC4'
    },
    grey: {
      50: '#F3F2F3',
      100: '#DADADA',
      200: '#A1A1A1',
      400: '#9098a5',
      500: '#C4C4C4',
      600: '#F2F2F2',
      700: '#EEEEEE',
      800: '#E7E7E7',
      900: '#898989'
    },
    customTheme: {
      navColor: '#251D38',
      navColorSelected: '#9747FF',
      navBgr: '#FFFFFF',
      contColor: '#251D38',
      contColorSub: alpha('#251D38', 0.45),
      contBgr: '#FFFFFF',
      bgr: '#F4F2ED',
      headerColor: '#251D38',
      headerColorSub: alpha('#EEEEEE', 0.45),
      highLight: alpha('#FFFFFF', 0.75),
      defaultItemBgr: alpha('#FFFFFF', 0.05),
      focusItem: '#9747FF',
      selectItem: alpha('#9747FF', 0.4),
      drawerBackground: '#DDD5E8',
      drawerColor: '#251D38',
      contentBorder: '#DFDEDA',
      contentBorderColor: '#DFDEDA',
      contentBorderNone: alpha('#DFDEDA', 0),
      borderMaster: '#DFDEDA',
      solidBgr: '#E5E5E5',
      hoverItem: alpha('#2FBCC4', 0.4),
      primaryA: '#1314140D',
      primaryA40: '#2FBCC466',
      primary40: 'rgba(151, 71, 255, 0.40)',
      zebra: '#F5F3F5',
      zebraFreezeOne: '#FAF8F5',
      zebraFreezeTwo: '#EEECEC',
      selectLabel: '#D5E8E5',
      globalContentColorMuted: 'rgba(37, 29, 56, 0.45)',
      brand: '#BF5AC2',
      success50: '#ECFDF3',
      success100: '#D1FADF',
      error50: '#FEF3F2',
      error100: '#FEE4E2'
    }
  },
  typography: {
    h1: {
      fontSize: 36,
      fontWeight: 700
    },
    h2: {
      fontSize: 32,
      fontWeight: 700
    },
    h3: {
      fontSize: 28,
      fontWeight: 700
    },
    h4: {
      fontSize: 24,
      fontWeight: 700
    },
    h5: {
      fontSize: 20,
      fontWeight: 600
    },
    h6: {
      fontSize: 16,
      fontWeight: 400
    },
    subtitle1: {
      fontSize: 20,
      fontWeight: 700
    },
    subtitle2: {
      fontSize: 18,
      fontWeight: 700
    },
    body1: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: 'normal'
    },
    body2: {
      fontSize: 14,
      fontWeight: '400',
      lineHeight: 'normal'
    },
    button: {
      fontSize: 16,
      fontFamily: 'Karla',
      fontWeight: 400,
      minWidth: 90,
      textTransform: 'capitalize'
    },
    fontFamily: [
      'Karla',
      'Syncopate',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(',')
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        background: 'background.default',
        '&::-webkit-scrollbar': {
          width: 7,
          height: 7
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(37, 29, 56, 0.45)'
          // borderRadius: '7px'
          // border: '4px solid rgba(0, 0, 0, 0)'
        }
      }
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
        disableTouchRipple: true
      },
      styleOverrides: {
        root: {
          fontSize: 14,
          '&.MuiMenuItem-root.Mui-focusVisible': {
            backgroundColor: 'rgba(47, 188, 196, 0.40)'
          }
        }
      }
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
        disableFocusRipple: true,
        disableTouchRipple: true
      }
    },
    MuiIconButton: {
      defaultProps: {
        disableRipple: true
      }
    },
    MuiTabs: {
      defaultProps: {
        TabIndicatorProps: {
          hidden: true
        }
      },
      styleOverrides: {
        flexContainer: {
          gap: '4px'
        }
      }
    },
    MuiTab: {
      defaultProps: {},
      styleOverrides: {
        root: {
          minHeight: 'auto',
          lineHeight: 'normal',
          padding: '7px 18px',
          backgroundColor: 'grey[600]',
          borderRadius: '16px',
          '&.Mui-selected': {
            backgroundColor: '#CEAEF7'
          }
        }
      }
    },
    MuiInputBase: {
      defaultProps: {
        disableInjectingGlobalStyles: true
      },
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            '& svg': {
              display: 'none'
            }
          }
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          color: 'common.black',
          fontWeight: 500,
          '&.Mui-disabled': {
            color: '#908C98'
          }
        }
      }
    },
    MuiDateCalendar: {
      styleOverrides: {
        root: {
          width: 340,
          color: '#000',
          border: 0,
          borderRadius: 4,
          backgroundColor: '#EEEEF1'
        }
      }
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
          backgroundColor: '#FFFFFF',
          fontWeight: 700,
          '&.Mui-selected': {
            backgroundColor: '#0047FF'
          }
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          '&.MuiButtonBase-root.Mui-disabled': {
            color: '#FFFFFF',
            backgroundColor: '#0000008a'
          }
        }
      }
    },
    MuiStack: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent'
        }
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '& .MuiListItemButton-root': {
            '&:hover': {
              backgroundColor: 'transparent'
            }
          }
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiFilledInput-root': {
            paddingTop: '12px'
          }
        },
        listbox: {
          overflowX: 'hidden',
          '& .MuiAutocomplete-option.MuiListItem-root': {
            padding: '8px',
            borderRadius: '8px',
            transition: 'background-color, padding-left 0.6 ease-in-out'
          },
          '& .MuiAutocomplete-option.MuiListItem-root:hover': {
            backgroundColor: alpha('#2FBCC4', 0.4)
          },
          '& .MuiAutocomplete-option.MuiListItem-root[aria-selected="true"]': {
            backgroundColor: '#DDD5E8'
          }
        },
        option: {
          backgroundColor: 'inherit',
          borderRadius: '7px',
          '&.Mui-focused.Mui-focusVisible': {
            backgroundColor: 'rgba(47, 188, 196, 0.40)',
            padding: '7px'
          }
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: 12,
          minWidth: 70,
          borderRadius: '7px',
          margin: '0 8px',
          borderLeft: '7px solid',
          borderColor: 'transparent',
          '&:hover': {
            borderColor: '#9747FF'
          },
          '&.Mui-selected': {
            backgroundColor: '#CEAEF7'
          }
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: 14,
          '&.Mui-error': {
            color: '#9747FF'
          }
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
          '& .MuiMenuItem-root': {
            borderRadius: '7px'
          },
          '& .Mui-selected': {
            backgroundColor: '#CEAEF7'
          }
        }
      }
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          backgroundColor: 'inherit'
          // borderRadius: '16px'
        }
      }
    },
    MuiTable: {
      styleOverrides: {
        root: {
          tableLayout: 'fixed'
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          backgroundColor: 'inherit'
        }
      }
    },
    MuiAvatar: {
      defaultProps: {
        slotProps: {
          img: {
            loading: 'lazy'
          }
        }
      }
    },
    MuiCheckbox: {
      defaultProps: {
        disableRipple: true
      }
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          borderRadius: 10,
          '&.Mui-selected': {
            backgroundColor: '#9747FF66'
          }
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: 14
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#251D38',
          padding: '12px 8px',
          borderRadius: '8px'
        }
      }
    }
  },
  //TODO @Stevo -check naming
  breakpoints: {
    values: {
      xs: 0,
      smob: 500, // small mobile
      sm: 768, // tablet
      md: 1024, // small desktop
      lg: 1200, // large desktop
      xl: 1536, //  extra large desktop
      xxl: 1920 //  extra extra large desktop
    }
  }
});

export const scrollbarStyles = (theme: Theme): CSSObject => ({
  '&::-webkit-scrollbar': {
    width: '8px'
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent'
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.customTheme.globalContentColorMuted,
    borderRadius: '20px',
    border: '2px solid transparent',
    backgroundClip: 'content-box'
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: theme.palette.customTheme.globalContentColorMuted
  }
});
