import {
  BoolErbFieldValue,
  CalculatedErbFieldOptions,
  DateErbFieldValue,
  ErbFieldValue,
  ErbScalarField,
  ErbTableFieldRowCell,
  ErTemplateField,
  GenericSetErbFieldValue,
  GenericSetErbFieldValueLocalizedMulti,
  GenericSetErbFieldValueLocalizedSingle,
  LocalizedValue,
  NumberErbFieldValue,
  TableErbFieldValue,
  TextErbFieldValue
} from '@thrivea/organization-client';
import { DateTime } from 'luxon';

export const getScalarFieldValue = (field: ErbScalarField, allFieldValues: ErbFieldValue[]) => {
  if (field.isCalculated) {
    return getCalculatedValue(field.options!.kind.value as CalculatedErbFieldOptions, allFieldValues);
  }

  return getNonCalculatedValue(allFieldValues.find((f) => f.erbFieldId === field.id)!);
};

export const getNonCalculatedValue = (fieldValue: ErbFieldValue) => {
  if (fieldValue === undefined) return;
  if (fieldValue.kind.case === 'textValue') return getStringValue(fieldValue);
  if (fieldValue.kind.case === 'numberValue') return fieldValue.kind.value.value as number;
  if (fieldValue.kind.case === 'dateValue') return getDateValue(fieldValue);
  if (fieldValue.kind.case === 'boolValue') return fieldValue.kind.value.value as boolean;
  if (fieldValue.kind.case === 'genericSetValue') return fieldValue.kind.value.option;
  // temp hardcode language code
  if (fieldValue.kind.case === 'genericSetLocalizedSingle') return fieldValue.kind.value.values['en'];
  // TODO: Add for other types
};

export const getFieldValuePpd = (field: ErbScalarField, allFieldValues: ErbFieldValue[]): string | string[] | number | boolean | undefined => {
  if (field.isCalculated) {
    return getCalculatedValue(field.options!.kind.value as CalculatedErbFieldOptions, allFieldValues);
  }

  return getNonCalculatedValuePpd(allFieldValues.find((f) => f.erbFieldId === field.id)!);
};

const getNonCalculatedValuePpd = (fieldValue: ErbFieldValue): string | string[] | number | boolean | undefined => {
  if (fieldValue === undefined) return undefined;
  if (fieldValue.kind.case === 'textValue') return getStringValue(fieldValue);
  if (fieldValue.kind.case === 'numberValue') return fieldValue.kind.value.value as number;
  if (fieldValue.kind.case === 'dateValue') return fieldValue.kind.value.value as string;
  if (fieldValue.kind.case === 'boolValue') return fieldValue.kind.value.value as boolean;
  if (fieldValue.kind.case === 'genericSetValue') return fieldValue.kind.value.option;
  // temp hardcode language code
  if (fieldValue.kind.case === 'genericSetLocalizedSingle') return fieldValue.kind.value.values['en'];
  // TODO: Add for other types
};

export const getColumnFieldValuePpd = (tableId: string, columnId: string, allFieldValues: ErbFieldValue[]) => {
  // TODO: temporary till correct seed
  if (allFieldValues.find((f) => f.erbFieldId === tableId) === undefined) return;

  const tableFieldValue = allFieldValues.find((f) => f.erbFieldId === tableId)!.kind.value as TableErbFieldValue;
  const cellValue = tableFieldValue.rows[0].cells.find((f) => f.erbTableFieldColumnId === columnId);

  if (!cellValue) return;

  if (cellValue.value.case === 'textValue') return cellValue.value.value.value;
  if (cellValue.value.case === 'numberValue') return cellValue.value.value.value as number;
  if (cellValue.value.case === 'dateValue') return cellValue.value.value.value as string;
  if (cellValue.value.case === 'boolValue') return cellValue.value.value.value as boolean;
  if (cellValue.value.case === 'genericSetValue') return cellValue.value.value.option;
};

export const getErbScalarFieldId = (field: ErTemplateField) => (field.kind.case === 'scalarField' && field.kind.value.erbScalarField!.id) as string;

export const getStringValue = (field: ErbFieldValue) =>
  ((field.kind.case === 'textValue' || field.kind.case === 'numberValue') && field.kind.value.value) as string;

export const getDateValue = (field: ErbFieldValue) => DateTime.fromISO((field.kind.case === 'dateValue' && field.kind.value.value) as string);

export const getCalculatedValue = (fieldOptions: CalculatedErbFieldOptions, allFieldValues: ErbFieldValue[]): string | undefined => {
  const jsExpression = fieldOptions.jsExpression;
  const params = fieldOptions.params;

  try {
    const paramNames = Object.keys(params);
    const paramValues = Object.values(params).map((fieldId) => getNonCalculatedValue(allFieldValues.find((f) => f.erbFieldId === fieldId)!));

    const runFunc = new Function(...paramNames, jsExpression);

    const result = runFunc(...paramValues);

    return paramValues.some((p) => p === undefined) ? undefined : result;
  } catch (error) {
    // TODO: Sentry
    return undefined;
  }
};

export const mapToErbFieldValue = (fieldId: string, value: any): ErbFieldValue => {
  const erbFieldValue = new ErbFieldValue();
  erbFieldValue.erbFieldId = fieldId;

  // Set the appropriate kind based on value type
  if (typeof value === 'string') {
    const textValue = new TextErbFieldValue();
    textValue.value = value;
    erbFieldValue.kind = { case: 'textValue', value: textValue };
  } else if (typeof value === 'boolean') {
    const boolValue = new BoolErbFieldValue();
    boolValue.value = value;
    erbFieldValue.kind = { case: 'boolValue', value: boolValue };
  } else if (typeof value === 'number') {
    const numberValue = new NumberErbFieldValue();
    numberValue.value = value;
    erbFieldValue.kind = { case: 'numberValue', value: numberValue };
  } else if (value instanceof Date) {
    const dateValue = new DateErbFieldValue();
    dateValue.value = value.toISOString();
    erbFieldValue.kind = { case: 'dateValue', value: dateValue };
  } else if (Array.isArray(value)) {
    console.warn('Received array, converting to key-value pairs');

    const genericSetValue = new GenericSetErbFieldValueLocalizedMulti();

    // Transform array values to a localized format
    genericSetValue.values = Object.fromEntries(
      value.map((val, index) => {
        const localizedValue = new LocalizedValue();
        // Assuming val has a property translations which is an object
        localizedValue.translations = {
          ...val.translations // Spread the translations from val into the LocalizedValue
        };

        return [index.toString(), localizedValue];
      })
    );

    erbFieldValue.kind = { case: 'genericSetLocalizedMulti', value: genericSetValue };
  } else if (typeof value === 'object' && value !== null) {
    // Directly use the value object since it is already structured correctly
    const valuesObject = value as { [key: string]: any };

    const genericSetValue = new GenericSetErbFieldValueLocalizedSingle();
    genericSetValue.id = crypto.randomUUID();

    // Assigning the values directly if they are already in the expected format
    genericSetValue.values = {
      [valuesObject.key]: valuesObject.translations.en || ''
    };

    erbFieldValue.kind = { case: 'genericSetLocalizedSingle', value: genericSetValue };
  } else {
    // Handle cases where the value doesn't match any predefined kind
    console.warn('Unsupported value type for field:', value);
    erbFieldValue.kind = { case: undefined };
  }

  return erbFieldValue;
};

// Utility function to retrieve value as a string for rendering in a table cell
export const retrieveTableRowCellValue = (field: ErbTableFieldRowCell): string | number | boolean => {
  switch (field.value.case) {
    case 'textValue':
      return (field.value.value as TextErbFieldValue).value;
    case 'boolValue':
      return (field.value.value as BoolErbFieldValue).value;
    case 'numberValue':
      return (field.value.value as NumberErbFieldValue).value;
    case 'dateValue':
      return (field.value.value as DateErbFieldValue).value;
    case 'genericSetValue':
      return (field.value.value as GenericSetErbFieldValue).option.join(', ');
    case 'genericSetLocalizedMulti':
      return JSON.stringify((field.value.value as GenericSetErbFieldValueLocalizedMulti).values);
    case 'genericSetLocalizedSingle':
      const singleValue = field.value.value as GenericSetErbFieldValueLocalizedSingle;
      return `ID: ${singleValue.id}, Values: ${JSON.stringify(singleValue.values)}`;
    default:
      return '-';
  }
};
