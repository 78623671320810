import React from 'react';
import { Box, Stack, Drawer, List, useTheme, useMediaQuery } from '@mui/material';
import { HomeIcon, OperationsIcon, PeopleIcon, PictureSizeSuffix, RowCenterStack } from '@/shared';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { selectOrganizationLogoFile, selectOrganizationLogoImageUrl, selectOrganizationLogoReadSasToken } from '@features/admin-settings';
import { DocumentsAccordion, NavListItem, openNavigationDrawer, SettingsAccordion } from '@features/navigation';
import { AllowedTo } from 'react-abac';
import { GroupPermissions } from '@features/abac';
import { isSvgUrl, pictureUrl } from '@/utils';
import { selectCurrentEmployeeId } from '@features/shared';

const ORG_LOGO_WIDTH = 38;
interface NavigationProps {
  isOpen: boolean;
}

export const Navigation: React.FC<NavigationProps> = ({ isOpen }) => {
  const dispatch = useAppDispatch();
  const logoUrl = useAppSelector(selectOrganizationLogoImageUrl);
  const logoFile = useAppSelector(selectOrganizationLogoFile);
  const organizationSasToken = useAppSelector(selectOrganizationLogoReadSasToken);
  const currentEmployeeId = useAppSelector(selectCurrentEmployeeId);

  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down('smob'));

  const getLogo = () => {
    if (logoFile) {
      return URL.createObjectURL(logoFile.sm);
    } else if (logoUrl) {
      if (isSvgUrl(logoUrl)) {
        return new URL(organizationSasToken, decodeURIComponent(logoUrl)).toString();
      }
      return pictureUrl(logoUrl, organizationSasToken, PictureSizeSuffix.lg);
    } else {
      return '/images/logo-placeholder.png';
    }
  };

  const navContent = (
    <Stack
      component={'nav'}
      sx={{
        position: 'relative',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        padding: '20px 16px 16px',
        justifyContent: 'space-between',
        backgroundColor: (theme) => theme.palette.customTheme.navBgr,
        boxShadow: '0px 1px 2px 0px var(#0A0D12, rgba(10, 13, 18, 0.05))',
        border: '1px solid  #E9EAEB'
      }}
    >
      <Stack
        gap={'20px'}
        sx={{
          width: '100%',
          height: '100%'
        }}
      >
        {isSmDown && <Box component="img" src="/images/nav-logo-expanded.png" alt="Thrivea logo" width={140} height={38} />}
        <List
          sx={{
            display: 'flex',
            flexDirection: 'column',
            color: 'inherit',
            width: '100%',
            overflowY: 'auto',
            padding: 0,
            gap: '8px'
          }}
        >
          <AllowedTo
            perform={GroupPermissions.ACCESS_NEWS_FEED}
            data={currentEmployeeId}
            no={() => <NavListItem isDisabled={true} name="Home" url="/" icon={<HomeIcon />} />}
          >
            <NavListItem isDisabled={false} name="Home" url="/" icon={<HomeIcon />} />
          </AllowedTo>
          <AllowedTo perform={GroupPermissions.ACCESS_SETTINGS} data={currentEmployeeId} no={() => null}>
            <NavListItem isDisabled={false} name="People" url="/people-directory" icon={<PeopleIcon />} />
          </AllowedTo>
          <NavListItem isDisabled={false} name="Operations" url="/operations/my-tasks" icon={<OperationsIcon />} />
          <DocumentsAccordion isDisabled={false} url="/documents" />
        </List>
        <List
          sx={{
            display: 'flex',
            gap: '8px',
            flexDirection: 'column',
            color: 'inherit',
            width: '100%',
            padding: 0,
            marginTop: 'auto',
            position: 'unset'
          }}
        >
          <AllowedTo perform={GroupPermissions.ACCESS_SETTINGS} data={currentEmployeeId}>
            <SettingsAccordion />
          </AllowedTo>
          <RowCenterStack gap={2} sx={{ marginTop: 'auto', justifyContent: 'center' }}>
            {isSvgUrl(logoUrl) && (
              <svg width={ORG_LOGO_WIDTH} height="38" xmlns="http://www.w3.org/2000/svg">
                <image href={getLogo()} width={ORG_LOGO_WIDTH} height="38" />
              </svg>
            )}
            {!isSvgUrl(logoUrl) && <Box component="img" src={getLogo()} alt="Organization Logo" width={ORG_LOGO_WIDTH} height="38" />}
          </RowCenterStack>
        </List>
      </Stack>
    </Stack>
  );

  const navigationDrawerOpenButtonClick = () => {
    dispatch(openNavigationDrawer());
  };

  return (
    <>
      {isSmDown && (
        <Drawer
          variant="temporary"
          open={isOpen}
          onClose={navigationDrawerOpenButtonClick}
          sx={{
            display: {
              xs: 'block',
              lg: 'none'
            },
            '& .MuiDrawer-paper': {
              width: 250
            },
            '& .MuiBackdrop-root': {
              background: 'rgba(217, 217, 217, 0.60)',
              backdropFilter: 'blur(10px)'
            }
          }}
        >
          {navContent}
        </Drawer>
      )}
      {!isSmDown && <>{navContent}</>}
    </>
  );
};
