import { all, call, delay, fork, put, select, takeLatest } from 'redux-saga/effects';
import {
  CopyFilesRequest,
  CreateFolderRequest,
  EditDescriptionRequest,
  FileItem,
  HardDeleteFilesRequest,
  MoveFilesRequest,
  OrderDirection,
  PinFolderRequest,
  RenameFileRequest,
  RestoreFilesRequest,
  RetrieveFileActivitiesRequest,
  RetrieveFileActivitiesResponse,
  RetrieveFilesAccessRequest,
  RetrieveFilesAccessResponse,
  RetrieveFilesInfoRequest,
  RetrieveFilesInfoResponse,
  RetrieveFilesRequest,
  RetrieveFilesResponse,
  RetrieveMyUploadsRequest,
  RetrieveMyUploadsResponse,
  RetrieveRecentFilesRequest,
  RetrieveSharedFilesRequest,
  RetrieveStarredFilesRequest,
  RetrieveTopLevelFoldersRequest,
  RetrieveTopLevelFoldersResponse,
  RetrieveTrashBinFilesRequest,
  RetrieveTrashBinFilesResponse,
  RevokeAccessForAllRequest,
  ShareFileRequest,
  SoftDeleteFilesRequest,
  SortFile,
  StarFileRequest,
  UploadFilesRequest
} from '@thrivea/organization-client';
import * as Sentry from '@sentry/react';
import {
  createFolderFailed,
  createFolderRequested,
  createFolderSucceeded,
  editDescriptionRequested,
  editDescriptionSucceeded,
  editDescriptionFailed,
  renameFileFailed,
  renameFileRequested,
  renameFileSucceeded,
  retrieveFilesFailed,
  retrieveFilesRequested,
  retrieveFilesSucceeded,
  retrieveMyUploadsReadSasTokenFailed,
  retrieveMyUploadsReadSasTokenSucceeded,
  retrieveTopLevelFoldersFailed,
  retrieveTopLevelFoldersRequested,
  retrieveTopLevelFoldersSucceeded,
  uploadFilesFailed,
  uploadFilesRequested,
  uploadFilesSucceeded,
  retrieveMyUploadsCreateDeleteSasUriSucceeded,
  retrieveMyUploadsCreateDeleteSasUriFailed,
  refreshMyUploadsCreateDeleteSasUriSucceeded,
  refreshMyUploadsCreateDeleteSasUriFailed,
  retrieveMyUploadsReadSasTokenRequested,
  retrieveMyUploadsCreateDeleteSasUriRequested,
  refreshMyUploadsCreateDeleteSasUriRequested,
  selectMyUploadsCreateDeleteSasUri,
  selectFilesWithStatusForUpload,
  notifyBackendOnUpload,
  selectUploadedFilesWithStatuses,
  softDeleteFilesSucceeded,
  softDeleteFilesFailed,
  softDeleteFilesRequested,
  retrieveFilesInfoSucceeded,
  retrieveFilesInfoFailed,
  retrieveFilesInfoRequested,
  selectSelectedFolderId,
  copyFilesSucceeded,
  copyFilesFailed,
  copyFilesRequested,
  starFileSucceeded,
  starFileFailed,
  starFileRequested,
  moveFilesSucceeded,
  moveFilesFailed,
  moveFilesRequested,
  retrieveTrashBinFilesSucceeded,
  retrieveTrashBinFilesFailed,
  retrieveTrashBinFilesRequested,
  retrieveRecentFilesRequested,
  retrieveRecentFilesSucceeded,
  retrieveRecentFilesFailed,
  retrieveFilesAccessSucceeded,
  retrieveFilesAccessFailed,
  retrieveFilesAccessRequested,
  shareFilesSucceeded,
  shareFilesFailed,
  shareFileRequested,
  hardDeleteFilesSucceeded,
  hardDeleteFilesFailed,
  restoreFilesSucceeded,
  restoreFilesFailed,
  hardDeleteFilesRequested,
  restoreFilesRequested,
  pinFolderSucceeded,
  pinFolderFailed,
  pinFolderRequested,
  revokeAccessForAllSucceeded,
  revokeAccessForAllFailed,
  revokeAccessForAllRequested,
  selectFilesById,
  selectAccessItemsInitialIds,
  toggleStarredFiles,
  toggleRecentFiles,
  selectActiveFilter,
  retrieveStarredFilesRequested,
  selectStarredFilesPageNumber,
  selectStarredFilesPageSize,
  selectRecentFilesPageNumber,
  selectRecentFilesPageSize,
  retrieveStarredFilesSucceeded,
  retrieveStarredFilesFailed,
  retrieveSharedFilesFailed,
  retrieveSharedFilesSucceeded,
  retrieveSharedFilesRequested,
  retrieveMyUploadsRequested,
  retrieveMyUploadsSucceeded,
  retrieveMyUploadsFailed,
  selectSelectedFolderLabel,
  retrieveFileActivitiesSucceeded,
  retrieveFileActivitiesFailed,
  retrieveFileActivitiesRequested,
  selectFolder
} from '@features/assets';
import {
  copyFiles,
  createFolder,
  editDescription,
  hardDeleteFiles,
  moveFiles,
  pinFolder,
  renameFile,
  restoreFiles,
  retrieveFileActivities,
  retrieveFiles,
  retrieveFilesAccess,
  retrieveFilesInfo,
  retrieveMyUploads,
  retrieveRecentFiles,
  retrieveSharedFiles,
  retrieveStarredFiles,
  retrieveTopLevelFolders,
  retrieveTrashBinFiles,
  revokeAccessForAll,
  shareFiles,
  softDeleteFiles,
  starFile,
  uploadFiles
} from '@api/assets.api';
import { PayloadAction } from '@reduxjs/toolkit';
import { refreshMyUploadsCreateDeleteSasUri, retrieveMyUploadsCreateDeleteSasUri, retrieveMyUploadsReadSasToken } from '@api/shared-access-signature.api';
import { Empty } from '@bufbuild/protobuf';
import { SasToken, SasUri } from '@thrivea/auth-client';
import { FileWithStatus, UploadFilesInDirectoryWithProgressRequest, uploadFilesWithProgressInDirectory } from '@api/blob-storage.api';
import { buildFileTree, handleFileUploaded, handleStartUploadingFile, handleUpdateFileUploadProgress } from '@features/assets';
import { selectCurrentEmployeeId } from '@app/user';
import { showSuccess } from '../snackbar';
import { t } from 'i18next';
import { closeDrawer } from '../drawer';

const MAX_RETRY_COUNT = 5;

function* retrieveFilesRequestedGenerator(action: PayloadAction<RetrieveFilesRequest>) {
  try {
    const response: RetrieveFilesResponse = yield call(retrieveFiles, action.payload);
    yield put(retrieveFilesSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveFilesFailed());
  }
}

function* retrieveTopLevelFoldersRequestedGenerator(action: PayloadAction<RetrieveTopLevelFoldersRequest>) {
  try {
    const response: RetrieveTopLevelFoldersResponse = yield call(retrieveTopLevelFolders, action.payload);
    yield put(retrieveTopLevelFoldersSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveTopLevelFoldersFailed());
  }
}

function* uploadFilesRequestedGenerator() {
  let retryCount = 0;
  const filesWithStatus: FileWithStatus[] = yield select(selectFilesWithStatusForUpload);
  let lastError: unknown = undefined;

  while (retryCount < MAX_RETRY_COUNT) {
    const sasUri: string = yield select(selectMyUploadsCreateDeleteSasUri);

    const request: UploadFilesInDirectoryWithProgressRequest = {
      sasUri,
      filesWithStatus,
      handleUpdateFileUploadProgress,
      handleStartUploadingFile,
      handleFileUploaded
    };

    try {
      yield call(uploadFilesWithProgressInDirectory, request);

      const employeeId: string = yield select(selectCurrentEmployeeId);
      const filesWithStatus: FileWithStatus[] = yield select(selectUploadedFilesWithStatuses);
      const selectedFolderId = yield select(selectSelectedFolderId);

      // Convert acceptedFiles to tree structure
      const uploadedFiles = buildFileTree(filesWithStatus);

      const uploadFilesRequest = new UploadFilesRequest({
        uploadedFiles,
        uploadedBy: employeeId,
        rootFolderId: selectedFolderId
      });

      yield put(notifyBackendOnUpload(uploadFilesRequest));

      return;
    } catch (error) {
      retryCount++;
      lastError = error;

      if (sasUri === '') {
        const newSasUri: SasUri = yield call(retrieveMyUploadsCreateDeleteSasUriRequested, new Empty());
        yield put(retrieveMyUploadsCreateDeleteSasUriSucceeded(newSasUri));
      } else {
        const newSasUri: SasUri = yield call(refreshMyUploadsCreateDeleteSasUri, new Empty());
        yield put(refreshMyUploadsCreateDeleteSasUriSucceeded(newSasUri));
      }

      yield delay(500 * retryCount + 1);
    }
  }

  Sentry.captureException(lastError);
  yield put(uploadFilesFailed());
}

function* notifyBackendOnUploadGenerator(action: PayloadAction<UploadFilesRequest>) {
  try {
    const _: Empty = yield call(uploadFiles, action.payload);
    yield put(uploadFilesSucceeded());
    yield put(showSuccess(t('file_with_count', { ns: 'assets', count: uploadFiles.length })));
  } catch (error) {
    Sentry.captureException(error);
    yield put(uploadFilesFailed());
  }
}

function* createFolderRequestedGenerator(action: PayloadAction<CreateFolderRequest>) {
  try {
    yield call(createFolder, action.payload);
    yield put(createFolderSucceeded(action.payload.id));
    yield put(showSuccess(t('created_folder', { ns: 'assets', name: action.payload.name })));
  } catch (error) {
    Sentry.captureException(error);
    yield put(createFolderFailed());
  }
}

function* renameFileRequestedGenerator(action: PayloadAction<RenameFileRequest>) {
  const file = yield select(selectFilesById);

  try {
    yield call(renameFile, action.payload);
    yield put(renameFileSucceeded(action.payload));
    yield put(showSuccess(t('renamed_folder', { ns: 'assets', name: file[action.payload.fileId].name })));
  } catch (error) {
    Sentry.captureException(error);
    yield put(renameFileFailed());
  }
}

function* editDescriptionRequestedGenerator(action: PayloadAction<EditDescriptionRequest>) {
  const file = yield select(selectFilesById);

  try {
    const response = yield call(editDescription, action.payload);
    yield put(editDescriptionSucceeded(response));
    yield put(showSuccess(t('edit_description', { ns: 'assets', name: file[action.payload.fileId].name })));
  } catch (error) {
    Sentry.captureException(error);
    yield put(editDescriptionFailed());
  }
}

function* retrieveMyUploadsReadSasTokenRequestedGenerator(action: PayloadAction<Empty>) {
  try {
    const response: SasToken = yield call(retrieveMyUploadsReadSasToken, action.payload);
    yield put(retrieveMyUploadsReadSasTokenSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveMyUploadsReadSasTokenFailed());
  }
}

function* retrieveMyUploadsCreateDeleteSasUriRequestedGenerator(action: PayloadAction<Empty>) {
  try {
    const response: SasUri = yield call(retrieveMyUploadsCreateDeleteSasUri, action.payload);
    yield put(retrieveMyUploadsCreateDeleteSasUriSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveMyUploadsCreateDeleteSasUriFailed());
  }
}

function* refreshMyUploadsCreateDeleteSasUriRequestedGenerator(action: PayloadAction<Empty>) {
  try {
    const response: SasUri = yield call(refreshMyUploadsCreateDeleteSasUri, action.payload);
    yield put(refreshMyUploadsCreateDeleteSasUriSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(refreshMyUploadsCreateDeleteSasUriFailed());
  }
}

function* softDeleteFilesRequestedGenerator(action: PayloadAction<SoftDeleteFilesRequest>) {
  try {
    yield call(softDeleteFiles, action.payload);
    yield put(softDeleteFilesSucceeded(action.payload.fileIds));
    yield put(showSuccess(t('file_removed_with_count', { ns: 'assets', count: action.payload.fileIds.length })));
  } catch (error) {
    Sentry.captureException(error);
    yield put(softDeleteFilesFailed());
  }
}

function* copyFilesRequestedGenerator(action: PayloadAction<CopyFilesRequest>) {
  try {
    yield call(copyFiles, action.payload);
    yield put(copyFilesSucceeded());
  } catch (error) {
    Sentry.captureException(error);
    yield put(copyFilesFailed());
  }
}

function* retrieveFilesInfoRequestedGenerator(action: PayloadAction<RetrieveFilesInfoRequest>) {
  try {
    const response: RetrieveFilesInfoResponse = yield call(retrieveFilesInfo, action.payload);
    console.log('retrieveFilesInfoRequestedGenerator request:', action.payload);
    console.log('retrieveFilesInfoRequestedGenerator response:', response);
    yield put(retrieveFilesInfoSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveFilesInfoFailed());
  }
}

function* starFileRequestedGenerator(action: PayloadAction<StarFileRequest>) {
  try {
    yield call(starFile, action.payload);
    yield put(starFileSucceeded(action.payload.fileId));
  } catch (error) {
    Sentry.captureException(error);
    yield put(starFileFailed());
  }
}

function* moveFilesRequestedGenerator(action: PayloadAction<MoveFilesRequest>) {
  const folderName = yield select(selectSelectedFolderLabel);
  const employeeId = yield select(selectCurrentEmployeeId);

  try {
    yield call(moveFiles, action.payload);
    yield put(moveFilesSucceeded(action.payload.sourceFileIds));
    yield put(
      retrieveFilesRequested(
        new RetrieveFilesRequest({
          employeeId,
          folderId: action.payload.destinationFolderId,
          pageNumber: 1,
          pageSize: 10,
          sortBy: SortFile.NAME,
          sortDirection: OrderDirection.ASCENDING
        })
      )
    );
    yield put(closeDrawer());
    yield put(selectFolder(action.payload.destinationFolderId));
    yield put(showSuccess(t('file_moved_with_count', { ns: 'assets', count: action.payload.sourceFileIds.length, name: folderName })));
  } catch (error) {
    console.log('moveFilesRequestedGenerator', error);
    Sentry.captureException(error);
    yield put(moveFilesFailed());
  }
}

function* retrieveTrashBinFilesRequestedGenerator(action: PayloadAction<RetrieveTrashBinFilesRequest>) {
  try {
    const response: RetrieveTrashBinFilesResponse = yield call(retrieveTrashBinFiles, action.payload);
    yield put(retrieveTrashBinFilesSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveTrashBinFilesFailed());
  }
}

function* retrieveRecentFilesRequestedGenerator(action: PayloadAction<RetrieveRecentFilesRequest>) {
  try {
    const response: RetrieveFilesResponse = yield call(retrieveRecentFiles, action.payload);
    yield put(retrieveRecentFilesSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveRecentFilesFailed());
  }
}

function* retrieveStarredFilesRequestedGenerator(action: PayloadAction<RetrieveStarredFilesRequest>) {
  try {
    const response: RetrieveFilesResponse = yield call(retrieveStarredFiles, action.payload);
    yield put(retrieveStarredFilesSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveStarredFilesFailed());
  }
}

function* retrieveFilesAccessRequestedGenerator(action: PayloadAction<RetrieveFilesAccessRequest>) {
  try {
    const response: RetrieveFilesAccessResponse = yield call(retrieveFilesAccess, action.payload);
    yield put(retrieveFilesAccessSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveFilesAccessFailed());
  }
}

function* shareFileRequestedGenerator(action: PayloadAction<ShareFileRequest>) {
  const file: Record<string, FileItem> = yield select(selectFilesById);

  try {
    yield call(shareFiles, action.payload);
    yield put(shareFilesSucceeded(action.payload.shareEmployees));
    yield put(
      showSuccess(t('share_file_with_count', { ns: 'assets', name: file[action.payload.fileId].name, count: action.payload.shareEmployees.length }))
    );
  } catch (error) {
    Sentry.captureException(error);
    yield put(shareFilesFailed());
  }
}

function* hardDeleteFilesRequestedGenerator(action: PayloadAction<HardDeleteFilesRequest>) {
  try {
    yield call(hardDeleteFiles, action.payload);
    yield put(hardDeleteFilesSucceeded(action.payload.fileIds));
  } catch (error) {
    Sentry.captureException(error);
    yield put(hardDeleteFilesFailed());
  }
}

function* restoreFilesRequestedGenerator(action: PayloadAction<RestoreFilesRequest>) {
  try {
    yield call(restoreFiles, action.payload);
    yield put(restoreFilesSucceeded(action.payload.fileIds));
  } catch (error) {
    Sentry.captureException(error);
    yield put(restoreFilesFailed());
  }
}

function* pinFolderRequestedGenerator(action: PayloadAction<PinFolderRequest>) {
  const pinnedFolder = yield select(selectSelectedFolderId);

  try {
    yield call(pinFolder, action.payload);
    yield put(pinFolderSucceeded(action.payload.folderId));
    yield put(showSuccess(`Successfully pinned ${pinnedFolder[action.payload.folderId].name}`));
  } catch (error) {
    Sentry.captureException(error);
    yield put(pinFolderFailed());
  }
}

function* revokeAccessForAllRequestedGenerator(action: PayloadAction<RevokeAccessForAllRequest>) {
  const file = yield select(selectFilesById);
  const accessCount = yield select(selectAccessItemsInitialIds);

  try {
    yield call(revokeAccessForAll, action.payload);
    yield put(revokeAccessForAllSucceeded());
    yield put(showSuccess(t('revoke_access_with_count', { ns: 'assets', name: file[action.payload.fileId].name, count: accessCount.length })));
  } catch (error) {
    Sentry.captureException(error);
    yield put(revokeAccessForAllFailed());
  }
}

function* toggleRecentFilesGenerator() {
  const activeFilter: 'recents' | 'starred' | 'none' = yield select(selectActiveFilter);

  if (activeFilter === 'recents') {
    const pageNumber: number = yield select(selectRecentFilesPageNumber);
    const pageSize: number = yield select(selectRecentFilesPageSize);

    yield put(
      retrieveRecentFilesRequested(
        new RetrieveRecentFilesRequest({
          pageNumber,
          pageSize,
          sortBy: SortFile.NAME,
          sortDirection: OrderDirection.DESCENDING
        })
      )
    );
  }
}

function* toggleStarredFilesGenerator() {
  const activeFilter: 'recents' | 'starred' | 'none' = yield select(selectActiveFilter);

  if (activeFilter === 'starred') {
    const pageNumber: number = yield select(selectStarredFilesPageNumber);
    const pageSize: number = yield select(selectStarredFilesPageSize);

    yield put(
      retrieveStarredFilesRequested(
        new RetrieveStarredFilesRequest({
          pageNumber,
          pageSize,
          sortBy: SortFile.NAME,
          sortDirection: OrderDirection.DESCENDING
        })
      )
    );
  }
}

function* retrieveSharedFilesRequestedGenerator(action: PayloadAction<RetrieveSharedFilesRequest>) {
  try {
    const response: RetrieveFilesResponse = yield call(retrieveSharedFiles, action.payload);
    yield put(retrieveSharedFilesSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveSharedFilesFailed());
  }
}

function* retrieveMyUploadsRequestedGenerator(action: PayloadAction<RetrieveMyUploadsRequest>) {
  try {
    const response: RetrieveMyUploadsResponse = yield call(retrieveMyUploads, action.payload);
    yield put(retrieveMyUploadsSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveMyUploadsFailed());
  }
}

function* retrieveFileActivitiesRequestedGenerator(action: PayloadAction<RetrieveFileActivitiesRequest>) {
  try {
    const response: RetrieveFileActivitiesResponse = yield call(retrieveFileActivities, action.payload);
    yield put(retrieveFileActivitiesSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveFileActivitiesFailed());
  }
}

function* retrieveFilesRequestedWatcher() {
  yield takeLatest(retrieveFilesRequested.type, retrieveFilesRequestedGenerator);
}

function* retrieveRecentFilesRequestedWatcher() {
  yield takeLatest(retrieveRecentFilesRequested.type, retrieveRecentFilesRequestedGenerator);
}

function* retrieveStarredFilesRequestedWatcher() {
  yield takeLatest(retrieveStarredFilesRequested.type, retrieveStarredFilesRequestedGenerator);
}

function* retrieveTopLevelFoldersRequestedWatcher() {
  yield takeLatest(retrieveTopLevelFoldersRequested.type, retrieveTopLevelFoldersRequestedGenerator);
}

function* uploadFilesRequestedWatcher() {
  yield takeLatest(uploadFilesRequested.type, uploadFilesRequestedGenerator);
}

function* notifyBackendOnUploadWatcher() {
  yield takeLatest(notifyBackendOnUpload.type, notifyBackendOnUploadGenerator);
}

function* createFolderRequestedWatcher() {
  yield takeLatest(createFolderRequested.type, createFolderRequestedGenerator);
}

function* renameFileRequestedWatcher() {
  yield takeLatest(renameFileRequested.type, renameFileRequestedGenerator);
}

function* editDescriptionRequestedWatcher() {
  yield takeLatest(editDescriptionRequested.type, editDescriptionRequestedGenerator);
}

function* retrieveMyUploadsReadSasTokenRequestedWatcher() {
  yield takeLatest(retrieveMyUploadsReadSasTokenRequested.type, retrieveMyUploadsReadSasTokenRequestedGenerator);
}

function* retrieveMyUploadsCreateDeleteSasUriRequestedWatcher() {
  yield takeLatest(retrieveMyUploadsCreateDeleteSasUriRequested.type, retrieveMyUploadsCreateDeleteSasUriRequestedGenerator);
}

function* refreshMyUploadsCreateDeleteSasUriRequestedWatcher() {
  yield takeLatest(refreshMyUploadsCreateDeleteSasUriRequested.type, refreshMyUploadsCreateDeleteSasUriRequestedGenerator);
}

function* softDeleteFilesRequestedWatcher() {
  yield takeLatest(softDeleteFilesRequested.type, softDeleteFilesRequestedGenerator);
}

function* retrieveFilesInfoRequestedWatcher() {
  yield takeLatest(retrieveFilesInfoRequested.type, retrieveFilesInfoRequestedGenerator);
}

function* copyFilesRequestedWatcher() {
  yield takeLatest(copyFilesRequested.type, copyFilesRequestedGenerator);
}

function* starFileRequestedWatcher() {
  yield takeLatest(starFileRequested.type, starFileRequestedGenerator);
}

function* moveFilesRequestedWatcher() {
  yield takeLatest(moveFilesRequested.type, moveFilesRequestedGenerator);
}

function* retrieveTrashBinFilesRequestedWatcher() {
  yield takeLatest(retrieveTrashBinFilesRequested.type, retrieveTrashBinFilesRequestedGenerator);
}

function* retrieveFilesAccessRequestedWatcher() {
  yield takeLatest(retrieveFilesAccessRequested.type, retrieveFilesAccessRequestedGenerator);
}

function* shareFileRequestedWatcher() {
  yield takeLatest(shareFileRequested.type, shareFileRequestedGenerator);
}

function* hardDeleteFilesRequestedWatcher() {
  yield takeLatest(hardDeleteFilesRequested.type, hardDeleteFilesRequestedGenerator);
}

function* restoreFilesRequestedWatcher() {
  yield takeLatest(restoreFilesRequested.type, restoreFilesRequestedGenerator);
}

function* pinFolderRequestedWatcher() {
  yield takeLatest(pinFolderRequested.type, pinFolderRequestedGenerator);
}

function* revokeAccessForAllRequestedWatcher() {
  yield takeLatest(revokeAccessForAllRequested.type, revokeAccessForAllRequestedGenerator);
}

function* toggleRecentFilesWatcher() {
  yield takeLatest(toggleRecentFiles.type, toggleRecentFilesGenerator);
}

function* toggleStarredFilesWatcher() {
  yield takeLatest(toggleStarredFiles.type, toggleStarredFilesGenerator);
}

function* retrieveSharedFilesRequestedWatcher() {
  yield takeLatest(retrieveSharedFilesRequested.type, retrieveSharedFilesRequestedGenerator);
}

function* retrieveMyUploadsRequestedWatcher() {
  yield takeLatest(retrieveMyUploadsRequested.type, retrieveMyUploadsRequestedGenerator);
}

function* retrieveFileActivitiesRequestedWatcher() {
  yield takeLatest(retrieveFileActivitiesRequested.type, retrieveFileActivitiesRequestedGenerator);
}

export function* assetsSagas() {
  yield all([
    fork(copyFilesRequestedWatcher),
    fork(retrieveFilesRequestedWatcher),
    fork(retrieveRecentFilesRequestedWatcher),
    fork(retrieveStarredFilesRequestedWatcher),
    fork(retrieveFilesInfoRequestedWatcher),
    fork(retrieveTopLevelFoldersRequestedWatcher),
    fork(uploadFilesRequestedWatcher),
    fork(notifyBackendOnUploadWatcher),
    fork(createFolderRequestedWatcher),
    fork(renameFileRequestedWatcher),
    fork(editDescriptionRequestedWatcher),
    fork(retrieveMyUploadsReadSasTokenRequestedWatcher),
    fork(retrieveMyUploadsCreateDeleteSasUriRequestedWatcher),
    fork(refreshMyUploadsCreateDeleteSasUriRequestedWatcher),
    fork(softDeleteFilesRequestedWatcher),
    fork(starFileRequestedWatcher),
    fork(moveFilesRequestedWatcher),
    fork(retrieveTrashBinFilesRequestedWatcher),
    fork(retrieveFilesAccessRequestedWatcher),
    fork(shareFileRequestedWatcher),
    fork(hardDeleteFilesRequestedWatcher),
    fork(restoreFilesRequestedWatcher),
    fork(pinFolderRequestedWatcher),
    fork(revokeAccessForAllRequestedWatcher),
    fork(toggleRecentFilesWatcher),
    fork(toggleStarredFilesWatcher),
    fork(retrieveSharedFilesRequestedWatcher),
    fork(retrieveFileActivitiesRequestedWatcher),
    fork(retrieveMyUploadsRequestedWatcher)
  ]);
}
