import { create } from '@bufbuild/protobuf';
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import {
  CreatePeopleDirViewRequest,
  DeactivateEmployeesRequest,
  DeletePeopleDirViewRequest,
  EmployeeListItem,
  EmployeeRecord,
  EmployeeResultsSchema,
  OrderDirection,
  RenameSavedViewRequest,
  ResendInvitationEmailRequest,
  RetrieveAllPeopleDirCategoriesSectionsFieldsResponse,
  RetrieveEmployeeRecordRequestSchema,
  RetrieveEmployeeRecordsRequest,
  RetrieveEmployeeRecordsRequestSchema,
  RetrieveEmployeeRecordsResponse,
  RetrieveErbFieldsResponse,
  RetrievePeopleDirViewsRequest,
  RetrievePeopleDirViewsRequestSchema,
  UpdateErSectionRequest,
  UpdatePeopleDirViewRequest
} from '@thrivea/organization-client';
import * as Sentry from '@sentry/react';
import {
  createPeopleDirView,
  deletePeopleDirView,
  renameSavedView,
  retrieveAllPeopleDirCategoriesSectionsFields,
  retrieveEmployeeRecords,
  retrievePeopleDirViews,
  updatePeopleDirView
} from '@api/people-dir.api';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  createPeopleDirViewFailed,
  createPeopleDirViewRequested,
  createPeopleDirViewSucceeded,
  deactivateEmployeesFailed,
  deactivateEmployeesRequested,
  deactivateEmployeesSucceeded,
  deletePeopleDirViewFailed,
  deletePeopleDirViewRequested,
  deletePeopleDirViewSucceeded,
  loadPeopleDirectoryFailed,
  loadPeopleDirectoryRequested,
  loadPeopleDirectorySucceeded,
  PpdAdditionalData,
  renameSavedViewFailed,
  renameSavedViewRequested,
  renameSavedViewSucceeded,
  retrieveAllPeopleDirCategoriesSectionsFieldsFailed,
  retrieveAllPeopleDirCategoriesSectionsFieldsRequested,
  retrieveAllPeopleDirCategoriesSectionsFieldsSucceeded,
  retrieveEmployeeRecordsFailed,
  retrieveEmployeeRecordsRequested,
  retrieveEmployeeRecordsSucceeded,
  retrieveErbFieldsFailed,
  retrieveErbFieldsRequested,
  retrieveErbFieldsSucceeded,
  retrievePeopleDirViewsFailed,
  retrievePeopleDirViewsRequested,
  retrievePeopleDirViewsSucceeded,
  retrieveTwoStepDataFailed,
  retrieveTwoStepDataRequested,
  retrieveTwoStepDataSucceeded,
  selectIsViewSaved,
  selectPeopleDirViewsSavedIds,
  selectViewName,
  updatePeopleDirViewFailed,
  updatePeopleDirViewRequested,
  updatePeopleDirViewSucceeded,
  resendInvitationEmailSucceeded,
  resendInvitationEmailFailed,
  resendInvitationEmailRequested,
  retrieveEmployeeRecordSucceeded,
  PpdData
} from '@features/people-directory';
import { Empty, EmptySchema } from '@bufbuild/protobuf/wkt';
import { retrieveErbFields } from '@api/erb.api';
import { showSuccess } from '@features/snackbar';
import { t } from 'i18next';
import { deactivateEmployees, resendInvitationEmail } from '@api/employees.api';
import { retrieveDepartments, retrieveSites } from '@api/employee-profile.api';
import { retrieveSitesSucceeded, selectEmployeeListItems } from '@features/shared';
import { updateErSectionSucceeded } from '@features/employee-record-page';
import { retrieveEmployeeRecord } from '@api/erp.api';

function* loadPeopleDirectoryRequestedGenerator(action: PayloadAction<string>) {
  try {
    const peopleDirectoryData: PpdData = yield all({
      allErbFields: call(retrieveErbFields, create(EmptySchema)),
      employeeRecords: call(
        retrieveEmployeeRecords,
        create(RetrieveEmployeeRecordsRequestSchema, { pageNumber: 1, pageSize: 1500, orderDirection: OrderDirection.DESCENDING })
      ),
      allPeopleDirCategoriesSectionsFields: call(retrieveAllPeopleDirCategoriesSectionsFields, create(EmptySchema)),
      peopleDirViews: call(retrievePeopleDirViews, create(RetrievePeopleDirViewsRequestSchema, { employeeId: action.payload }))
    });

    yield put(loadPeopleDirectorySucceeded(peopleDirectoryData));
    yield put(retrieveTwoStepDataRequested([]));
  } catch (error) {
    Sentry.captureException(error);
    yield put(loadPeopleDirectoryFailed());
  }
}

function* retrieveTwoStepDataRequestedGenerator(_: PayloadAction<string[]>) {
  const employees: EmployeeListItem[] = yield select(selectEmployeeListItems);

  try {
    const peopleDirAdditionalData: PpdAdditionalData = yield all({
      employeeResults: create(EmployeeResultsSchema, { employees, totalCount: employees.length }),
      sitesResponse: call(retrieveSites, create(EmptySchema)),
      departmentsResponse: call(retrieveDepartments, create(EmptySchema))
    });

    yield put(retrieveTwoStepDataSucceeded(peopleDirAdditionalData));
    yield put(retrieveSitesSucceeded(peopleDirAdditionalData.sitesResponse));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveTwoStepDataFailed());
  }
}

function* retrieveEmployeeRecordsRequestedGenerator(action: PayloadAction<RetrieveEmployeeRecordsRequest>) {
  try {
    const response: RetrieveEmployeeRecordsResponse = yield call(retrieveEmployeeRecords, action.payload);
    yield put(retrieveEmployeeRecordsSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveEmployeeRecordsFailed());
  }
}

function* retrieveAllPeopleDirCategoriesSectionsFieldsRequestedGenerator(action: PayloadAction<Empty>) {
  try {
    const response: RetrieveAllPeopleDirCategoriesSectionsFieldsResponse = yield call(retrieveAllPeopleDirCategoriesSectionsFields, action.payload);
    yield put(retrieveAllPeopleDirCategoriesSectionsFieldsSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveAllPeopleDirCategoriesSectionsFieldsFailed());
  }
}

function* createPeopleDirViewRequestedGenerator(action: PayloadAction<CreatePeopleDirViewRequest>) {
  try {
    yield call(createPeopleDirView, action.payload);
    yield put(createPeopleDirViewSucceeded());

    const viewName = yield select(selectViewName, action.payload.viewId);
    yield put(showSuccess(t('successfully_saved_ppd_view', { ns: 'common', viewName })));
  } catch (error) {
    Sentry.captureException(error);
    yield put(createPeopleDirViewFailed());
  }
}

function* retrievePeopleDirViewsRequestedGenerator(action: PayloadAction<RetrievePeopleDirViewsRequest>) {
  try {
    const response = yield call(retrievePeopleDirViews, action.payload);
    yield put(retrievePeopleDirViewsSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrievePeopleDirViewsFailed());
  }
}

function* renameSavedViewRequestedGenerator(action: PayloadAction<RenameSavedViewRequest>) {
  const viewOldName = yield select(selectViewName, action.payload.viewId);
  const isRenamedViewSaved = yield select(selectIsViewSaved, action.payload.viewId);

  try {
    if (isRenamedViewSaved) yield call(renameSavedView, action.payload);

    yield put(
      renameSavedViewSucceeded({
        id: action.payload.viewId,
        name: action.payload.name
      })
    );

    const viewNewName = yield select(selectViewName, action.payload.viewId);
    yield put(showSuccess(t('successfully_renamed_ppd_view', { ns: 'common', viewOldName, viewNewName })));
  } catch (error) {
    Sentry.captureException(error);
    yield put(renameSavedViewFailed());
  }
}

function* deletePeopleDirViewRequestedGenerator(action: PayloadAction<DeletePeopleDirViewRequest>) {
  const { viewId } = action.payload;
  const savedIds = yield select(selectPeopleDirViewsSavedIds);
  const viewName = yield select(selectViewName, viewId);

  try {
    if (savedIds.includes(viewId)) {
      yield call(deletePeopleDirView, action.payload);
    }

    yield put(deletePeopleDirViewSucceeded(viewId));
    yield put(showSuccess(t('successfully_deleted_pdd_view', { ns: 'common', viewName })));
  } catch (error) {
    Sentry.captureException(error);
    yield put(deletePeopleDirViewFailed());
  }
}

function* updatePeopleDirViewRequestedGenerator(action: PayloadAction<UpdatePeopleDirViewRequest>) {
  try {
    yield call(updatePeopleDirView, action.payload);
    yield put(updatePeopleDirViewSucceeded(action.payload.viewId));

    const viewName = yield select(selectViewName, action.payload.viewId);
    yield put(showSuccess(t('successfully_updated_ppd_view', { ns: 'common', viewName })));
  } catch (error) {
    Sentry.captureException(error);
    yield put(updatePeopleDirViewFailed());
  }
}

function* resendInvitationEmailRequestedGenerator(action: PayloadAction<ResendInvitationEmailRequest>) {
  try {
    yield call(resendInvitationEmail, action.payload);
    yield put(resendInvitationEmailSucceeded());
    yield put(showSuccess('Successfully sent invitation emails'));
  } catch (error) {
    console.error('resendInvitationEmailRequestedGenerator error: ', error);
    yield put(resendInvitationEmailFailed());
  }
}

function* deactivateEmployeesRequestedGenerator(action: PayloadAction<DeactivateEmployeesRequest>) {
  try {
    const _: Empty = yield call(deactivateEmployees, action.payload);
    yield put(deactivateEmployeesSucceeded(action.payload));
    yield put(showSuccess(t('deactivate_employee_with_count', { ns: 'common', count: action.payload.employeeIds.length })));
  } catch (error) {
    Sentry.captureException(error);
    yield put(deactivateEmployeesFailed());
  }
}

function* retrieveErbFieldsGenerator() {
  try {
    const erbFields: RetrieveErbFieldsResponse = yield call(retrieveErbFields, create(EmptySchema));
    yield put(retrieveErbFieldsSucceeded(erbFields));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveErbFieldsFailed());
  }
}

function* updateErSectionSucceededGenerator(action: PayloadAction<UpdateErSectionRequest>) {
  const { employeeId } = action.payload;

  try {
    const employeeRecord: EmployeeRecord = yield call(retrieveEmployeeRecord, create(RetrieveEmployeeRecordRequestSchema, { employeeId }));
    yield put(retrieveEmployeeRecordSucceeded(employeeRecord));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveErbFieldsFailed());
  }
}

function* retrieveEmployeeRecordsRequestedWatcher() {
  yield takeLatest(retrieveEmployeeRecordsRequested.type, retrieveEmployeeRecordsRequestedGenerator);
}

function* retrieveAllPeopleDirCategoriesSectionsFieldsRequestedWatcher() {
  yield takeLatest(retrieveAllPeopleDirCategoriesSectionsFieldsRequested.type, retrieveAllPeopleDirCategoriesSectionsFieldsRequestedGenerator);
}

function* createPeopleDirViewRequestedWatcher() {
  yield takeLatest(createPeopleDirViewRequested.type, createPeopleDirViewRequestedGenerator);
}

function* retrievePeopleDirViewsRequestedWatcher() {
  yield takeLatest(retrievePeopleDirViewsRequested.type, retrievePeopleDirViewsRequestedGenerator);
}

function* renameSavedViewRequestedWatcher() {
  yield takeLatest(renameSavedViewRequested.type, renameSavedViewRequestedGenerator);
}

function* deletePeopleDirViewRequestedWatcher() {
  yield takeLatest(deletePeopleDirViewRequested.type, deletePeopleDirViewRequestedGenerator);
}

function* loadPeopleDirectoryRequestedWatcher() {
  yield takeLatest(loadPeopleDirectoryRequested.type, loadPeopleDirectoryRequestedGenerator);
}

function* updatePeopleDirViewRequestedWatcher() {
  yield takeLatest(updatePeopleDirViewRequested.type, updatePeopleDirViewRequestedGenerator);
}

function* retrieveTwoStepDataRequestedWatcher() {
  yield takeLatest(retrieveTwoStepDataRequested.type, retrieveTwoStepDataRequestedGenerator);
}

function* resendInvitationEmailRequestedWatcher() {
  yield takeLatest(resendInvitationEmailRequested.type, resendInvitationEmailRequestedGenerator);
}

function* deactivateEmployeesRequestedWatcher() {
  yield takeLatest(deactivateEmployeesRequested.type, deactivateEmployeesRequestedGenerator);
}

function* retrieveErbFieldsRequestedWatcher() {
  yield takeLatest(retrieveErbFieldsRequested.type, retrieveErbFieldsGenerator);
}

function* updateErSectionSucceededWatcher() {
  yield takeLatest(updateErSectionSucceeded.type, updateErSectionSucceededGenerator);
}

export function* peopleDirSagas() {
  yield all([
    fork(retrieveEmployeeRecordsRequestedWatcher),
    fork(retrieveAllPeopleDirCategoriesSectionsFieldsRequestedWatcher),
    fork(createPeopleDirViewRequestedWatcher),
    fork(retrievePeopleDirViewsRequestedWatcher),
    fork(renameSavedViewRequestedWatcher),
    fork(deletePeopleDirViewRequestedWatcher),
    fork(loadPeopleDirectoryRequestedWatcher),
    fork(updatePeopleDirViewRequestedWatcher),
    fork(retrieveTwoStepDataRequestedWatcher),
    fork(resendInvitationEmailRequestedWatcher),
    fork(deactivateEmployeesRequestedWatcher),
    fork(retrieveErbFieldsRequestedWatcher),
    fork(updateErSectionSucceededWatcher)
  ]);
}
