import { CloseIcon, PreviewPostFileModal, RowCenterStack, StyledFixedWidthTypography } from '@/shared';
import { PostMedia, selectNewPostFiles, selectPostFilesWithStatus } from '@features/homepage';
import React from 'react';
import { Box, IconButton, ImageListItem } from '@mui/material';
import { useAppSelector } from '@app/hooks';
import { isDocumentUrl, isImageUrl, isVideoUrl, getDocThumbnail } from '@/utils';
import { SlideshowOutlined } from '@mui/icons-material';

interface InlinePostMediaEditProps {
  namedFiles: PostMedia[];
  handleDeleteFile: (src: string) => void;
}

export const InlinePostMediaEdit: React.FC<InlinePostMediaEditProps> = ({ namedFiles, handleDeleteFile }) => {
  const newPostMedia = useAppSelector(selectNewPostFiles);
  const postFilesWithStatus = useAppSelector(selectPostFilesWithStatus);

  return (
    <RowCenterStack gap={2}>
      {namedFiles.map((nf) => (
        <Box key={nf.src}>
          {isImageUrl(nf.src) && (
            <ImageListItem
              key={nf.name}
              sx={{
                position: 'relative',
                width: 135
              }}
            >
              <RowCenterStack
                gap={1}
                sx={{
                  '&:hover': {
                    '& > .Mui-DeleteFile': {
                      display: 'flex'
                    }
                  }
                }}
              >
                <Box
                  className="Mui-Media"
                  component="img"
                  src={nf.src}
                  key={nf.name}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 25,
                    height: 25,
                    backgroundColor: (theme) => theme.palette.grey[100],
                    position: 'relative',
                    borderRadius: 1,
                    border: '1px solid',
                    borderColor: (theme) => theme.palette.common.black
                  }}
                />
                <StyledFixedWidthTypography title={nf.name}>{nf.name}</StyledFixedWidthTypography>
                <IconButton
                  className="Mui-DeleteFile"
                  sx={{
                    width: 25,
                    height: 25,
                    zIndex: 1,
                    backgroundColor: (theme) => theme.palette.grey[100],
                    color: (theme) => theme.palette.common.black,
                    display: 'none',
                    borderRadius: 1,
                    padding: 0.5
                  }}
                  onClick={() => handleDeleteFile(nf.src)}
                >
                  <CloseIcon />
                </IconButton>
              </RowCenterStack>
            </ImageListItem>
          )}
          {isVideoUrl(nf.src) && (
            <ImageListItem
              sx={{
                position: 'relative',
                width: 135
              }}
            >
              <RowCenterStack
                gap={1}
                sx={{
                  '&:hover': {
                    '& > .Mui-DeleteFile': {
                      display: 'flex'
                    }
                  }
                }}
              >
                <Box
                  className="Mui-Media"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minWidth: 25,
                    minHeight: 25,
                    backgroundColor: (theme) => theme.palette.grey[100],
                    position: 'relative',
                    borderRadius: 1,
                    border: '1px solid',
                    borderColor: (theme) => theme.palette.common.black
                  }}
                >
                  <SlideshowOutlined />
                </Box>
                <StyledFixedWidthTypography title={nf.name}>{nf.name}</StyledFixedWidthTypography>
                <IconButton
                  className="Mui-DeleteFile"
                  sx={{
                    width: 25,
                    height: 25,
                    zIndex: 1,
                    backgroundColor: (theme) => theme.palette.grey[100],
                    color: (theme) => theme.palette.common.black,
                    display: 'none',
                    borderRadius: 1,
                    padding: 0.5
                  }}
                  onClick={() => handleDeleteFile(nf.src)}
                >
                  <CloseIcon />
                </IconButton>
              </RowCenterStack>
            </ImageListItem>
          )}
          {isDocumentUrl(nf.src) && (
            <ImageListItem
              sx={{
                position: 'relative',
                width: 135
              }}
            >
              <RowCenterStack
                gap={1}
                sx={{
                  '&:hover': {
                    '& > .Mui-DeleteFile': {
                      display: 'flex'
                    }
                  }
                }}
              >
                <Box
                  className="Mui-Media"
                  component="img"
                  src={getDocThumbnail(nf.name!)}
                  key={nf.name}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 25,
                    height: 25,
                    backgroundColor: (theme) => theme.palette.grey[100],
                    position: 'relative',
                    borderRadius: 1,
                    border: '1px solid',
                    borderColor: (theme) => theme.palette.common.black
                  }}
                />
                <StyledFixedWidthTypography title={nf.name}>{nf.name}</StyledFixedWidthTypography>
                <IconButton
                  className="Mui-DeleteFile"
                  sx={{
                    width: 25,
                    height: 25,
                    zIndex: 1,
                    backgroundColor: (theme) => theme.palette.grey[100],
                    color: (theme) => theme.palette.common.black,
                    display: 'none',
                    borderRadius: 1,
                    padding: 0.5
                  }}
                  onClick={() => handleDeleteFile(nf.src)}
                >
                  <CloseIcon />
                </IconButton>
              </RowCenterStack>
            </ImageListItem>
          )}
        </Box>
      ))}
      {newPostMedia.map((fws) => (
        <PreviewPostFileModal key={fws.file.name} fileWithStatus={fws} />
      ))}
    </RowCenterStack>
  );
};
