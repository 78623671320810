import { styled, Tab, TabProps } from '@mui/material';

export const AssetsMoveTab = styled(Tab)<TabProps>(({ theme }) => ({
  fontWeight: 500,
  backgroundColor: 'transparent',
  borderBottom: '1px solid',
  borderColor: theme.palette.customTheme.globalContentColorMuted,
  borderRadius: 0,
  boxShadow: 'none',
  '&.Mui-selected': {
    backgroundColor: 'transparent',
    borderBottom: '2px solid',
    borderColor: theme.palette.customTheme.selectItem
  }
}));
