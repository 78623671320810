import { memo } from 'react';
import { Stack } from '@mui/material';
import { AddComment, reactToPostRequested, selectPostReactionsGroupedByEmoji, selectPostById, PostReactions, CommentList } from '@features/homepage';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { ReactToPostRequest } from '@thrivea/networking-client';
import { RowCenterStack } from '@/shared';
import { selectCurrentEmployeeId } from '@features/shared';
import { EmojiPicker } from './EmojiPicker';
import { AllowedTo } from 'react-abac';
import { AudiencePermissions } from '@features/abac';

interface Emoji {
  id: number;
  native: string;
}

interface PostActionsProps {
  postId: string;
}

export const PostActions: React.FC<PostActionsProps> = memo(({ postId }) => {
  const dispatch = useAppDispatch();
  const currentUserId = useAppSelector(selectCurrentEmployeeId);
  const groupedPostReactionsDict = useAppSelector((state) => selectPostReactionsGroupedByEmoji(state, postId));
  const post = useAppSelector((state) => selectPostById(state, postId));

  const hasCurrentUserAlreadyReacted = (emoji) =>
    groupedPostReactionsDict.hasOwnProperty(emoji) && groupedPostReactionsDict[emoji]?.some((r) => r.authorId === currentUserId);

  const handleEmojiSelect = (emoji: Emoji) => {
    if (hasCurrentUserAlreadyReacted(emoji.native)) return;

    dispatch(reactToPostRequested(new ReactToPostRequest({ authorId: currentUserId, emoji: emoji.native, postId })));
  };

  return (
    <Stack>
      <RowCenterStack
        gap={1}
        sx={{
          width: '100%',
          padding: '0 16px',
          mt: 3,
          mb: 1
        }}
      >
        {Object.keys(groupedPostReactionsDict).length > 0 && <PostReactions postId={postId} />}
        <EmojiPicker onEmojiSelect={handleEmojiSelect} />
      </RowCenterStack>
      {post.commentIds.length > 0 && <CommentList postId={postId} />}
      <Stack sx={{ padding: '8px 16px' }}>
        <AllowedTo perform={AudiencePermissions.COMMENT_ON_POST} data={post.audienceIds[0]}>
          <AddComment postId={postId} authorId={currentUserId} audienceId={post.audienceIds[0]} />
        </AllowedTo>
      </Stack>
    </Stack>
  );
});
