import React, { memo, useRef, useState } from 'react';
import { Typography } from '@mui/material';
import {
  addCurrentUserToAuthors,
  selectAuthorsByIds,
  selectCommentOnPostStatus,
  commentOnPostRequested,
  EMPTY_EDITOR,
  selectAudienceIdByPostId
} from '@features/homepage';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { CommentOnPostRequest } from '@thrivea/networking-client';
import { useTranslation } from 'react-i18next';
import { selectCurrentUserDisplayName, selectCurrentEmployeeId, selectCurrentUserProfilePictureUrl } from '@app/user';
import { ActionStatus, QuillEditor, StyledCommentBox } from '@/shared';
import 'quill-mention/autoregister';
import { Author } from '@thrivea/organization-client';
import { LoadingButton } from '@mui/lab';
import Quill, { Delta } from 'quill/core';
import { removeBomCharacters } from '@utils/removeBomCharacters';

interface AddCommentProps {
  postId: string;
  authorId: string;
}

export const AddComment: React.FC<AddCommentProps> = memo(({ postId, authorId }) => {
  const { t } = useTranslation('common');
  const dispatch = useAppDispatch();
  const commentOnPostStatus = useAppSelector<ActionStatus>(selectCommentOnPostStatus);
  const authors = useAppSelector(selectAuthorsByIds);
  const currentUserProfilePicture = useAppSelector(selectCurrentUserProfilePictureUrl);
  const currentUserDisplayName = useAppSelector(selectCurrentUserDisplayName);
  const currentUserId = useAppSelector(selectCurrentEmployeeId);
  const audienceIdByPostId = useAppSelector((state) => selectAudienceIdByPostId(state, postId));
  const [inputValue, setInputValue] = useState('');
  const innerButtonRef = useRef<HTMLButtonElement | null>(null);
  const quillRef = useRef<Quill | null>(null);

  const handleCommentButtonClick = () => {
    if (!authors[currentUserId]) {
      dispatch(addCurrentUserToAuthors(new Author({ id: currentUserId, name: currentUserDisplayName, profilePictureUrl: currentUserProfilePicture })));
    }

    dispatch(
      commentOnPostRequested(
        new CommentOnPostRequest({
          postId,
          comment: removeBomCharacters(inputValue),
          authorId
        })
      )
    );

    quillRef.current!.setContents(new Delta());
    setInputValue('');
  };

  const handleRemoveFocus = () => {
    const activeElement = document.activeElement as HTMLElement;
    if (activeElement) {
      activeElement.blur();
    }
  };

  return (
    <StyledCommentBox
      onKeyDown={(event) => {
        if (event.key === 'Escape') {
          handleRemoveFocus();
        }
      }}
    >
      <QuillEditor emojiOnly setText={setInputValue} ref={quillRef} audienceId={audienceIdByPostId} />
      <LoadingButton
        loading={commentOnPostStatus === ActionStatus.Pending}
        ref={innerButtonRef}
        variant="contained"
        color="primary"
        disabled={inputValue.length === 0 || inputValue === EMPTY_EDITOR || commentOnPostStatus === ActionStatus.Pending}
        onClick={handleCommentButtonClick}
        sx={{
          my: 1
        }}
      >
        <Typography sx={{ color: (theme) => theme.palette.common.white }}>{t('comment')}</Typography>
      </LoadingButton>
    </StyledCommentBox>
  );
});
