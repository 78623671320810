import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { DrawerType, OpenDrawerRequest, TasksEditTaskDrawerRequest, TasksEditTaskListDrawerRequest, TasksReadOnlyTaskDrawerRequest } from '@features/drawer';
import { RootState } from '@app/store';
import { last } from 'lodash';
import { SingleTaskTabs, TaskListTaskTabs } from '../tasks';

interface DrawerState {
  drawerStack: DrawerType[];
  activeEditTaskTab: SingleTaskTabs;
  activeEditTaskListTab: TaskListTaskTabs;
  activeReadOnlyTaskTab: SingleTaskTabs;
}

const initialState: DrawerState = {
  drawerStack: [],
  activeEditTaskTab: SingleTaskTabs.Details,
  activeEditTaskListTab: TaskListTaskTabs.Details,
  activeReadOnlyTaskTab: SingleTaskTabs.Details
};

export const drawerSlice = createSlice({
  name: 'drawer',
  initialState,
  reducers: {
    openDrawer: (state, action: PayloadAction<OpenDrawerRequest>) => {
      const { type } = action.payload;

      if (last(state.drawerStack) !== type) {
        state.drawerStack.push(type);
      }
    },
    goToPrevious: (state) => {
      state.drawerStack.pop();
    },
    closeDrawer: (state) => {
      state.drawerStack = [];
    },
    openTasksEditTaskDrawer: (state, action: PayloadAction<TasksEditTaskDrawerRequest>) => {
      const { activeTab } = action.payload;

      if (last(state.drawerStack) !== DrawerType.TasksEditTask) {
        state.drawerStack.push(DrawerType.TasksEditTask);
      }

      state.activeEditTaskTab = activeTab;
    },
    openTasksEditTaskListDrawer: (state, action: PayloadAction<TasksEditTaskListDrawerRequest>) => {
      const { activeTab } = action.payload;

      if (last(state.drawerStack) !== DrawerType.TasksEditTaskList) {
        state.drawerStack.push(DrawerType.TasksEditTaskList);
      }

      state.activeEditTaskListTab = activeTab;
    },
    openReadOnlyTaskDrawer: (state, action: PayloadAction<TasksReadOnlyTaskDrawerRequest>) => {
      const { activeTab } = action.payload;

      if (last(state.drawerStack) !== DrawerType.TasksReadOnlyTask) {
        state.drawerStack.push(DrawerType.TasksReadOnlyTask);
      }

      state.activeReadOnlyTaskTab = activeTab;
    }
  }
});

const selectDrawerStack = (state: RootState) => state.drawer.drawerStack;

export const selectIsDrawerOpen = createSelector([selectDrawerStack], (drawerStack) => drawerStack.length > 0);
export const selectIsMoreThanOneDrawer = createSelector([selectDrawerStack], (drawerStack) => drawerStack.length > 1);
export const selectCurrentDrawerType = createSelector([selectDrawerStack], (drawerStack) => last(drawerStack));

export const selectActiveEditTaskTab = (state: RootState) => state.drawer.activeEditTaskTab;
export const selectActiveEditTaskListTab = (state: RootState) => state.drawer.activeEditTaskListTab;
export const selectActiveReadOnlyTaskTab = (state: RootState) => state.drawer.activeReadOnlyTaskTab;

export const selectIsTasksDrawer = (state) => {
  const currentDrawer = selectCurrentDrawerType(state);
  return (
    currentDrawer !== undefined &&
    [
      DrawerType.TasksAction,
      DrawerType.TasksNewTask,
      DrawerType.TasksEditTask,
      DrawerType.TasksNewTaskList,
      DrawerType.TasksEditTaskList,
      DrawerType.TasksReadOnlyTask
    ].includes(currentDrawer)
  );
};

export const { openDrawer, goToPrevious, closeDrawer, openTasksEditTaskDrawer, openTasksEditTaskListDrawer, openReadOnlyTaskDrawer } = drawerSlice.actions;
export default drawerSlice.reducer;
