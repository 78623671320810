import { create } from '@bufbuild/protobuf';
import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@app/store';
import {
  DeleteCommentRequest,
  DeletePostRequest,
  Post,
  Comment,
  ReactToCommentRequest,
  WithdrawPostReactionRequest,
  WithdrawCommentReactionRequest,
  ReactToPostRequest,
  CommentOnPostDeleted,
  CommentOnPostRequest,
  CommentReactionWithdrawn,
  CommentedOnPost,
  PostDeleted,
  PostReactionWithdrawn,
  PublishShoutoutRequest,
  ReactedToComment,
  ReactedToPost,
  RetrievePostsRequest,
  Shoutout,
  RetrievePostsResponse,
  RetrieveRecentPostsByAuthorRequest,
  RetrieveRecentPostsByAuthorResponse,
  SaveDraftPostRequest,
  RetrieveLastDraftPostRequest,
  DraftPost,
  DeleteDraftPostsRequest,
  EditShoutoutRequest,
  EditCommentRequest,
  RetrieveAudiencesWithPostingRightsByEmployeeIdRequest,
  RetrieveAudiencesWithPostingRightsByEmployeeIdResponse,
  AudienceWithName,
  RetrieveAudiencesByIdsResponse,
  RetrieveEmployeeIdsByAudienceIdRequest,
  ReadPostRequest,
  RetrieveArePostsReadRequest,
  RetrieveArePostsReadResponse,
  RetrievePostReadAnalyticsRequest,
  RetrievePostReadAnalyticsResponse,
  PostIdWithReadConfirmation,
  EmployeeReadPost,
  PostReadConfirmed,
  ShoutoutEdited,
  RetrievePostsByIdsRequest,
  ReactionSchema,
  PostIdWithReadConfirmationSchema,
  ShoutoutPublished,
  DraftPostSchema
} from '@thrivea/networking-client';
import { CommentModel, PostModel, ShoutoutModel } from '@features/homepage';
import { ActionStatus } from '@/shared';
import { FileWithStatus, UploadProgress, UploadedFile } from '@api/blob-storage.api';
import { EmployeeListItem } from '@thrivea/organization-client';
import { DateTime } from 'luxon';
import { trimQueryParams } from '@utils/trimQueryParams';
import { RefreshPostCreateDeleteSasUriRequest, SasToken, SasUri } from '@thrivea/auth-client';
import { EmployeeIdWithTime, RetrievePostAnalyticsRequest, RetrievePostAnalyticsResponse } from '@thrivea/networking-analytics-client';
import { Empty } from '@bufbuild/protobuf/wkt';
import { chain, groupBy, keys, uniq } from 'lodash';

const mapShoutout = (shoutout: Shoutout): ShoutoutModel => {
  const reactionDict = groupBy(shoutout.reactions, (r) => r.emoji);

  return {
    id: shoutout.id,
    authorId: shoutout.authorId,
    title: shoutout.title,
    text: shoutout.text,
    docUrls: shoutout.docUrls,
    mediaUrls: shoutout.mediaUrls,
    publishTime: DateTime.fromISO(shoutout.publishTime),
    commentIds: [],
    audienceIds: shoutout.audienceIds,
    reactions: reactionDict,
    editedTime: shoutout.editedTime ? DateTime.fromISO(shoutout.editedTime) : undefined,
    case: 'shoutout',
    isImportant: shoutout.isImportant,
    hasReadConfirmation: shoutout.hasReadConformation
  } as ShoutoutModel;
};

const mapComment = (comment: Comment): CommentModel => {
  const reactionDict = groupBy(comment.reactions, (r) => r.emoji);

  return {
    id: comment.id,
    authorId: comment.authorId,
    text: comment.text,
    commentTime: DateTime.fromISO(comment.commentTime),
    editedTime: comment.editedTime ? DateTime.fromISO(comment.editedTime) : undefined,
    reactions: reactionDict
  } as CommentModel;
};

interface PostsState {
  entities: {
    posts: {
      byId: Record<string, PostModel>;
      feedIds: string[];
      employeeRecentPostIds: string[];
      notificationPostIds: string[];
    };
    comments: {
      byId: Record<string, CommentModel>;
      allIds: string[];
    };
    audiences: {
      byId: Record<string, AudienceWithName>;
      withPostingPrivilegesAudienceIds: string[];
      retrievedPostsAudienceIds: string[];
    };
    mentionEmployees: {
      allIds: string[];
    };
    employeesWithTime: {
      byId: Record<string, EmployeeIdWithTime>;
      allIds: string[];
    };
    readPosts: {
      byId: Record<string, PostIdWithReadConfirmation>;
      allIds: string[];
    };
  };
  ui: {
    isPostDialogOpened: boolean;
    newPostsCounter: number;
    publishPostStatus: ActionStatus;
    commentOnPostStatus: ActionStatus;
    deleteCommentStatus: ActionStatus;
    deletePostStatus: ActionStatus;
    deletePostId: string;
    reactToCommentStatus: ActionStatus;
    postsStatus: ActionStatus;
    uploadPostFileStatus: ActionStatus;
    fileUploadProgress: UploadProgress;
    postFilesWithStatus: FileWithStatus[];
    employeeRecentPostsStatus: ActionStatus;
    isPostSavedToDraftStatus: ActionStatus;
    isPostSavedToDraft: boolean;
    isPostDraftDeletedStatus: ActionStatus;
    isPostDraftDeleted: boolean;
    audiencesWithPostingPrivilegesStatus: ActionStatus;
    mentionEmployeesStatus: ActionStatus;
    postAnalyticsStatus: ActionStatus;
    postNotificationStatus: ActionStatus;
  };
  commentedPostId?: string;
  currentAuthorId: string;
  postCreateDeleteSasUri: string;
  postReadSasToken: string;
  hasMore: boolean;
  hasMoreRecentPosts: boolean;
  currentPostsPage: number;
  currentRecentPostsPage: number;
  deletedCommentId?: string;
  postDraft: DraftPost;
  postDraftText: string;
  defaultAudienceId: string | null;
  readPostEmployees: EmployeeReadPost[];
  unreadEmployeeIds: string[];
  currentPostAnalytic: PostModel | undefined;
  currentPostAnalyticAudienceLength: number;
  currentAudience: AudienceWithName | null | undefined;
}

const initialState: PostsState = {
  entities: {
    posts: {
      byId: {},
      feedIds: [],
      employeeRecentPostIds: [],
      notificationPostIds: []
    },
    comments: { byId: {}, allIds: [] },
    audiences: {
      byId: {},
      retrievedPostsAudienceIds: [],
      withPostingPrivilegesAudienceIds: []
    },
    mentionEmployees: {
      allIds: []
    },
    employeesWithTime: { byId: {}, allIds: [] },
    readPosts: { byId: {}, allIds: [] }
  },
  ui: {
    isPostDialogOpened: false,
    newPostsCounter: 0,
    publishPostStatus: ActionStatus.Idle,
    commentOnPostStatus: ActionStatus.Idle,
    deleteCommentStatus: ActionStatus.Idle,
    reactToCommentStatus: ActionStatus.Idle,
    deletePostStatus: ActionStatus.Idle,
    deletePostId: '',
    postsStatus: ActionStatus.Idle,
    uploadPostFileStatus: ActionStatus.Idle,
    fileUploadProgress: {
      fileProgress: 0,
      totalProgress: 0
    },
    postFilesWithStatus: [],
    employeeRecentPostsStatus: ActionStatus.Idle,
    isPostSavedToDraftStatus: ActionStatus.Idle,
    isPostSavedToDraft: false,
    isPostDraftDeletedStatus: ActionStatus.Idle,
    isPostDraftDeleted: false,
    audiencesWithPostingPrivilegesStatus: ActionStatus.Idle,
    mentionEmployeesStatus: ActionStatus.Idle,
    postAnalyticsStatus: ActionStatus.Idle,
    postNotificationStatus: ActionStatus.Idle
  },
  commentedPostId: undefined,
  currentAuthorId: '',
  postReadSasToken: '',
  postCreateDeleteSasUri: '',
  hasMore: false,
  hasMoreRecentPosts: false,
  currentPostsPage: 0,
  currentRecentPostsPage: 1,
  postDraft: {} as DraftPost,
  postDraftText: '',
  defaultAudienceId: null,
  readPostEmployees: [],
  unreadEmployeeIds: [],
  currentPostAnalytic: undefined,
  currentPostAnalyticAudienceLength: 0,
  currentAudience: undefined
};

export const postsSlice = createSlice({
  name: 'posts',
  initialState,
  reducers: {
    setCurrentAuthorId: (state, action: PayloadAction<string>) => {
      state.currentAuthorId = action.payload;
    },
    openPostDialog: (state) => {
      state.ui.isPostDialogOpened = true;
    },
    closePostDialogRequested: (state) => {
      state.ui.fileUploadProgress = {
        fileProgress: 0,
        totalProgress: 0
      };
      state.ui.isPostDialogOpened = false;
    },
    closePostDialogSucceeded: (state) => {
      state.ui.isPostDialogOpened = false;
      state.ui.postFilesWithStatus = [];
    },
    closePostDialogFailed: (_state) => {
      // TODO:
    },
    newPostPublished: (state, _action: PayloadAction<ShoutoutPublished>) => {
      state.ui.newPostsCounter++;
    },
    showNewReaction: (state, action: PayloadAction<ReactedToPost>) => {
      const { postId, emoji, authorId } = action.payload;

      if (!state.entities.posts.byId.hasOwnProperty(postId)) return;

      state.entities.posts.byId[postId].reactions[emoji] ??= [];
      state.entities.posts.byId[postId].reactions[emoji].push(create(ReactionSchema, { emoji, authorId }));
    },
    withdrawPostReaction: (state, action: PayloadAction<PostReactionWithdrawn>) => {
      const { postId, emoji, authorId } = action.payload;

      if (!state.entities.posts.byId.hasOwnProperty(postId)) return;

      state.entities.posts.byId[postId].reactions[emoji] = state.entities.posts.byId[postId].reactions[emoji].filter((r) => r.authorId !== authorId);

      if (state.entities.posts.byId[postId].reactions[emoji].length === 0) delete state.entities.posts.byId[postId].reactions[emoji];
    },
    showNewComment: (state, action: PayloadAction<CommentedOnPost>) => {
      const { comment, commentId, authorId, postId } = action.payload;

      if (!state.entities.posts.byId.hasOwnProperty(postId)) return;

      state.entities.comments.byId[commentId] = {
        id: commentId,
        authorId,
        text: comment,
        commentTime: DateTime.utc(),
        reactions: {}
      } as CommentModel;

      state.entities.comments.allIds.push(commentId);
      state.entities.posts.byId[postId].commentIds.push(commentId);
    },
    deleteCommentOnPost: (state, action: PayloadAction<CommentOnPostDeleted>) => {
      const { postId, commentId } = action.payload;

      if (!state.entities.posts.byId.hasOwnProperty(postId)) return;

      delete state.entities.comments.byId[commentId];
      state.entities.comments.allIds = state.entities.comments.allIds.filter((id) => id !== commentId);
      state.entities.posts.byId[postId].commentIds = state.entities.posts.byId[postId].commentIds.filter((id) => id !== commentId);
    },
    showNewReactionToComment: (state, action: PayloadAction<ReactedToComment>) => {
      const { commentId, emoji, authorId } = action.payload;

      if (!state.entities.comments.byId.hasOwnProperty(commentId)) return;

      state.entities.comments.byId[commentId].reactions[emoji] ??= [];
      state.entities.comments.byId[commentId].reactions[emoji].push(create(ReactionSchema, { emoji, authorId }));
    },
    withdrawCommentReaction: (state, action: PayloadAction<CommentReactionWithdrawn>) => {
      const { commentId, emoji, authorId } = action.payload;
      if (!state.entities.comments.byId.hasOwnProperty(commentId)) return;

      state.entities.comments.byId[commentId].reactions[emoji] = state.entities.comments.byId[commentId].reactions[emoji].filter(
        (r) => r.authorId !== authorId
      );

      if (state.entities.comments.byId[commentId].reactions[emoji].length === 0) delete state.entities.comments.byId[commentId].reactions[emoji];
    },
    deletePost: (state, action: PayloadAction<PostDeleted>) => {
      const { postId } = action.payload;
      for (const commentId of state.entities.posts.byId[postId].commentIds) {
        delete state.entities.comments.byId[commentId];
        state.entities.comments.allIds = state.entities.comments.allIds.filter((id) => id !== commentId);
      }

      delete state.entities.posts.byId[postId];
      state.entities.posts.feedIds = state.entities.posts.feedIds.filter((id) => id !== postId);
    },
    editPostRequested: (_state, _action: PayloadAction<EditShoutoutRequest>) => {},
    editPostSucceeded: (state, action: PayloadAction<EditShoutoutRequest>) => {
      const { id, text, docUrls, mediaUrls } = action.payload;

      state.entities.posts.byId[id] = {
        ...state.entities.posts.byId[id],
        text,
        mediaUrls,
        docUrls,
        editedTime: DateTime.utc()
      } as ShoutoutModel;
      state.ui.postFilesWithStatus = [];
    },
    editPostFailed: (_state) => {},
    editShoutout: (state, action: PayloadAction<ShoutoutEdited>) => {
      const { id, text, docUrls, mediaUrls, editedTime } = action.payload;

      if (state.entities.posts.byId.hasOwnProperty(id)) {
        state.entities.posts.byId[id] = {
          ...state.entities.posts.byId[id],
          text,
          mediaUrls,
          docUrls,
          editedTime: DateTime.fromISO(editedTime)
        } as ShoutoutModel;
      }
    },
    retrievePostsRequested: (state, action: PayloadAction<RetrievePostsRequest>) => {
      state.ui.postsStatus = ActionStatus.Pending;

      if (action.payload.pageNumber === 1) {
        state.ui.newPostsCounter = 0;
        state.entities.posts = initialState.entities.posts;
      }
    },
    retrievePostsSucceeded: (state, action: PayloadAction<{ postsResponse: RetrievePostsResponse; audiencesResponse?: RetrieveAudiencesByIdsResponse }>) => {
      const { posts, totalCount } = action.payload.postsResponse;

      for (const { content } of posts) {
        const post = content.value!;

        switch (content.case) {
          case 'shoutout':
            const shoutout = post as Shoutout;

            if (!state.entities.posts.byId.hasOwnProperty(shoutout.id)) {
              state.entities.posts.byId[shoutout.id] = mapShoutout(shoutout);
            }

            break;
        }

        for (const comment of post.comments) {
          if (!state.entities.posts.byId[post.id].commentIds.includes(comment.id)) {
            state.entities.posts.byId[post.id].commentIds.push(comment.id);
          }

          if (!state.entities.comments.byId.hasOwnProperty(comment.id)) {
            state.entities.comments.byId[comment.id] = mapComment(comment);
            state.entities.comments.allIds.push(comment.id);
          }
        }
      }

      const postIds = state.entities.posts.feedIds.concat(posts.map((p) => p.content.value!.id));

      state.entities.posts.feedIds = uniq(postIds).sort((a, b) => {
        const postA = state.entities.posts.byId[a];
        const postB = state.entities.posts.byId[b];
        // Sort in descending order (newest first)
        return postB.publishTime.toMillis() - postA.publishTime.toMillis();
      });

      if (action.payload.audiencesResponse) {
        const { audiences } = action.payload.audiencesResponse;

        for (const audience of audiences) {
          state.entities.audiences.byId[audience.audienceId] = audience;
          state.entities.audiences.retrievedPostsAudienceIds.push(audience.audienceId);
        }

        state.entities.audiences.retrievedPostsAudienceIds = uniq(
          state.entities.audiences.retrievedPostsAudienceIds.concat(audiences.map((a) => a.audienceId))
        );
      }

      state.currentPostsPage += 1;
      state.hasMore = state.entities.posts.feedIds.length < totalCount;
      state.ui.postsStatus = ActionStatus.Idle;
      state.ui.postNotificationStatus = ActionStatus.Idle;
    },
    retrievePostsFailed: (state) => {
      state.ui.postsStatus = ActionStatus.Failed;
    },
    retrieveNotificationPostsByIdsRequested: (state, _: PayloadAction<RetrievePostsByIdsRequest>) => {
      state.ui.postNotificationStatus = ActionStatus.Pending;
    },
    retrieveNotificationPostsByIdsSucceeded: (
      state,
      action: PayloadAction<{ postsResponse: RetrievePostsResponse; audiencesResponse?: RetrieveAudiencesByIdsResponse }>
    ) => {
      const { posts } = action.payload.postsResponse;

      for (const { content } of posts) {
        const post = content.value!;

        state.entities.posts.feedIds.push(post.id);

        switch (content.case) {
          case 'shoutout':
            const shoutout = post as Shoutout;

            if (!state.entities.posts.byId.hasOwnProperty(shoutout.id)) {
              state.entities.posts.byId[shoutout.id] = mapShoutout(shoutout);
            }

            break;
        }

        for (const comment of post.comments) {
          if (!state.entities.posts.byId[post.id].commentIds.includes(comment.id)) {
            state.entities.posts.byId[post.id].commentIds.push(comment.id);
          }

          if (!state.entities.comments.byId.hasOwnProperty(comment.id)) {
            state.entities.comments.byId[comment.id] = mapComment(comment);
            state.entities.comments.allIds.push(comment.id);
          }
        }
      }

      state.entities.posts.notificationPostIds = uniq(state.entities.posts.notificationPostIds.concat(posts.map((p) => p.content.value!.id)));

      if (action.payload.audiencesResponse) {
        const { audiences } = action.payload.audiencesResponse;

        for (const audience of audiences.filter((a) => !state.entities.audiences.retrievedPostsAudienceIds.includes(a.audienceId))) {
          state.entities.audiences.byId[audience.audienceId] = audience;
          state.entities.audiences.retrievedPostsAudienceIds.push(audience.audienceId);
        }
      }

      state.ui.postNotificationStatus = ActionStatus.Idle;
    },
    retrieveNotificationPostsByIdsFailed: (state) => {
      state.ui.postNotificationStatus = ActionStatus.Failed;
    },
    uploadPostFilesRequested: (state, action: PayloadAction<FileList>) => {
      const files: FileWithStatus[] = [];

      for (const file of action.payload) {
        files.push({
          id: crypto.randomUUID(),
          isUploaded: false,
          isUploading: false,
          name: file.name,
          file,
          url: ''
        });
      }

      state.ui.postFilesWithStatus = state.ui.postFilesWithStatus.concat(files);
      state.ui.uploadPostFileStatus = ActionStatus.Pending;
    },
    uploadPostFilesSucceeded: (state) => {
      state.ui.uploadPostFileStatus = ActionStatus.Idle;
    },
    uploadPostFilesFailed: (state) => {
      // TODO: remove only file that failed to send?
      state.ui.uploadPostFileStatus = ActionStatus.Failed;
    },
    uploadPostFile: (state, action: PayloadAction<FileWithStatus>) => {
      const fileWithStatus = state.ui.postFilesWithStatus.find((fws) => fws.id === action.payload.id)!;
      fileWithStatus.isUploading = true;
      fileWithStatus.isUploaded = false;
    },
    postFileUploaded: (state, action: PayloadAction<UploadedFile>) => {
      const fileWithStatus = state.ui.postFilesWithStatus.find((fws) => fws.id === action.payload.fileWithStatus.id)!;
      fileWithStatus.isUploading = false;
      fileWithStatus.isUploaded = true;
      fileWithStatus.url = trimQueryParams(action.payload.url);
    },
    deletePostSingleFileRequested: (_state, _action: PayloadAction<string>) => {},
    deletePostSingleFileSucceeded: (state, action: PayloadAction<string>) => {
      state.ui.postFilesWithStatus = state.ui.postFilesWithStatus.filter((fws) => fws.url !== action.payload);
    },
    deletePostSingleFileFailed: (_state, _action: PayloadAction<string>) => {},
    publishShoutoutRequested: (state, _: PayloadAction<PublishShoutoutRequest>) => {
      state.ui.publishPostStatus = ActionStatus.Pending;
    },
    publishShoutoutSucceeded: (state, action: PayloadAction<Post>) => {
      const shoutout = action.payload.content.value as Shoutout;

      state.entities.posts.byId[shoutout.id] = mapShoutout(shoutout);
      state.entities.posts.feedIds.unshift(shoutout.id);

      state.ui.publishPostStatus = ActionStatus.Idle;
      state.ui.postFilesWithStatus = [];
      state.ui.isPostDialogOpened = false;
      state.postDraft = create(DraftPostSchema, {});
    },
    publishShoutoutFailed: (state) => {
      state.ui.publishPostStatus = ActionStatus.Failed;
      state.ui.isPostDialogOpened = false;
      state.ui.postFilesWithStatus = [];
    },
    commentOnPostRequested: (state, action: PayloadAction<CommentOnPostRequest>) => {
      state.commentedPostId = action.payload.postId;
      state.ui.commentOnPostStatus = ActionStatus.Pending;
    },
    commentOnPostSucceeded: (state, action: PayloadAction<Comment>) => {
      state.entities.comments.allIds.push(action.payload.id);
      state.entities.comments.byId[action.payload.id] = mapComment(action.payload);
      state.entities.posts.byId[state.commentedPostId!].commentIds.push(action.payload.id);

      state.ui.commentOnPostStatus = ActionStatus.Idle;
      state.commentedPostId = undefined;
    },
    commentOnPostFailed: (state) => {
      state.ui.isPostDialogOpened = false;
      state.ui.commentOnPostStatus = ActionStatus.Failed;
      state.commentedPostId = undefined;
    },
    deleteCommentRequested: (state, action: PayloadAction<DeleteCommentRequest>) => {
      state.ui.deleteCommentStatus = ActionStatus.Pending;
      state.deletedCommentId = action.payload.commentId;
    },
    deleteCommentSucceeded: (state, action: PayloadAction<DeleteCommentRequest>) => {
      state.entities.comments.allIds = state.entities.comments.allIds.filter((commentId) => commentId !== action.payload.commentId);

      delete state.entities.comments.byId[action.payload.commentId];

      state.entities.posts.byId[action.payload.postId].commentIds = state.entities.posts.byId[action.payload.postId].commentIds.filter(
        (commentId) => commentId !== action.payload.commentId
      );
      state.ui.deleteCommentStatus = ActionStatus.Idle;
    },
    deleteCommentFailed: (state) => {
      state.ui.deleteCommentStatus = ActionStatus.Failed;
    },
    reactToPostRequested: (state, action: PayloadAction<ReactToPostRequest>) => {
      const { postId, emoji } = action.payload;
      state.entities.posts.byId[postId].reactions[emoji] ??= [];
      state.entities.posts.byId[postId].reactions[emoji].push(create(ReactionSchema, { emoji, authorId: action.payload.authorId }));
    },
    reactToPostSucceeded: (_state) => {},
    reactToPostFailed: (state, action: PayloadAction<ReactToPostRequest>) => {
      state.entities.posts.byId[action.payload.postId].reactions[action.payload.emoji] = state.entities.posts.byId[action.payload.postId].reactions[
        action.payload.emoji
      ].filter((r) => r.authorId !== action.payload.authorId);
    },
    withdrawPostReactionRequested: (state, action: PayloadAction<WithdrawPostReactionRequest>) => {
      const { postId, emoji, authorId } = action.payload;
      state.entities.posts.byId[postId].reactions[emoji] = state.entities.posts.byId[postId].reactions[emoji].filter((r) => r.authorId !== authorId);

      if (state.entities.posts.byId[postId].reactions[emoji].length === 0) delete state.entities.posts.byId[postId].reactions[emoji];
    },
    withdrawPostReactionSucceeded: (_state) => {},
    withdrawPostReactionFailed: (state, action: PayloadAction<WithdrawPostReactionRequest>) => {
      const { emoji, authorId } = action.payload;
      state.entities.posts.byId[action.payload.postId].reactions[action.payload.emoji] ??= [];
      state.entities.posts.byId[action.payload.postId].reactions[action.payload.emoji].push(create(ReactionSchema, { emoji, authorId }));
    },
    reactToCommentRequested: (state, action: PayloadAction<ReactToCommentRequest>) => {
      const { emoji, authorId } = action.payload;
      state.entities.comments.byId[action.payload.commentId].reactions[action.payload.emoji] ??= [];
      state.entities.comments.byId[action.payload.commentId].reactions[action.payload.emoji].push(create(ReactionSchema, { emoji, authorId }));
    },
    reactToCommentSucceeded: (_state) => {},
    reactToCommentFailed: (state, action: PayloadAction<ReactToCommentRequest>) => {
      state.entities.comments.byId[action.payload.commentId].reactions[action.payload.emoji] = state.entities.comments.byId[action.payload.commentId].reactions[
        action.payload.emoji
      ].filter((r) => r.authorId !== action.payload.authorId);
    },
    withdrawCommentReactionRequested: (state, action: PayloadAction<WithdrawCommentReactionRequest>) => {
      state.entities.comments.byId[action.payload.commentId].reactions[action.payload.emoji] = state.entities.comments.byId[action.payload.commentId].reactions[
        action.payload.emoji
      ].filter((r) => r.authorId !== action.payload.authorId);

      if (state.entities.comments.byId[action.payload.commentId].reactions[action.payload.emoji].length === 0)
        delete state.entities.comments.byId[action.payload.commentId].reactions[action.payload.emoji];
    },
    withdrawCommentReactionSucceeded: (_state) => {},
    withdrawCommentReactionFailed: (state, action: PayloadAction<WithdrawCommentReactionRequest>) => {
      const { emoji, authorId } = action.payload;

      state.entities.comments.byId[action.payload.commentId].reactions[action.payload.emoji] ??= [];
      state.entities.comments.byId[action.payload.commentId].reactions[action.payload.emoji].push(create(ReactionSchema, { emoji, authorId }));
    },
    deletePostRequested: (state, action: PayloadAction<DeletePostRequest>) => {
      state.ui.deletePostStatus = ActionStatus.Pending;
      state.ui.deletePostId = action.payload.postId;
    },
    deletePostSucceeded: (state, action: PayloadAction<DeletePostRequest>) => {
      // TODO: delete reactions and comments
      const { postId } = action.payload;

      for (const commentId of state.entities.posts.byId[postId].commentIds) {
        delete state.entities.comments.byId[commentId];
        state.entities.comments.allIds = state.entities.comments.allIds.filter((id) => id !== commentId);
      }

      delete state.entities.posts.byId[postId];
      state.entities.posts.feedIds = state.entities.posts.feedIds.filter((id) => id !== postId);

      state.ui.deletePostStatus = ActionStatus.Idle;
      state.ui.deletePostId = '';

      if (state.currentPostAnalytic?.id === action.payload.postId) {
        state.currentPostAnalytic = undefined;
      }
    },
    deletePostFailed: (state) => {
      state.ui.deletePostStatus = ActionStatus.Failed;
      state.ui.deletePostId = '';
    },
    updatePostFileUploadProgress: (state, action: PayloadAction<UploadProgress>) => {
      state.ui.fileUploadProgress = {
        fileProgress: action.payload.fileProgress,
        totalProgress: action.payload.totalProgress
      };
    },
    retrievePostCreateDeleteSasUriRequested: (state) => {
      state.postCreateDeleteSasUri = '';
    },
    retrievePostCreateDeleteSasUriSucceeded: (state, action: PayloadAction<SasUri>) => {
      state.postCreateDeleteSasUri = action.payload.uri;
    },
    retrievePostCreateDeleteSasUriFailed: (_state) => {},
    retrievePostReadSasTokenRequested: (_state) => {},
    retrievePostReadSasTokenSucceeded: (state, action: PayloadAction<SasToken>) => {
      state.postReadSasToken = action.payload.token;
    },
    retrievePostReadSasTokenFailed: (_state) => {},
    refreshPostCreateDeleteSasUriRequested: (_state, _action: PayloadAction<RefreshPostCreateDeleteSasUriRequest>) => {},
    refreshPostCreateDeleteSasUriSucceeded: (state, action: PayloadAction<SasUri>) => {
      state.postCreateDeleteSasUri = action.payload.uri;
    },
    refreshPostCreateDeleteSasUriFailed: (_state) => {},
    retrieveEmployeeRecentPostsRequested: (state, action: PayloadAction<RetrieveRecentPostsByAuthorRequest>) => {
      state.ui.employeeRecentPostsStatus = ActionStatus.Pending;

      if (action.payload.pageNumber === 1) {
        state.entities.posts.employeeRecentPostIds = [];
      }

      state.currentRecentPostsPage = action.payload.pageNumber;
    },
    retrieveEmployeeRecentPostsSucceeded: (state, action: PayloadAction<RetrieveRecentPostsByAuthorResponse>) => {
      const { posts } = action.payload;

      for (let i = 0; i < posts.length; i++) {
        const post = posts[i].content.value!;

        switch (posts[i].content.case) {
          case 'shoutout':
            const shoutout = post as Shoutout;
            if (!state.entities.posts.byId.hasOwnProperty(shoutout.id)) state.entities.posts.byId[shoutout.id] = mapShoutout(shoutout);
            break;
        }

        for (const comment of post.comments) {
          if (!state.entities.posts.byId[post.id].commentIds.includes(comment.id)) {
            state.entities.posts.byId[post.id].commentIds.push(comment.id);
          }

          if (!state.entities.comments.byId.hasOwnProperty(comment.id)) {
            state.entities.comments.byId[comment.id] = mapComment(comment);
            state.entities.comments.allIds.push(comment.id);
          }
        }
      }

      state.entities.posts.employeeRecentPostIds = uniq(state.entities.posts.employeeRecentPostIds.concat(posts.map((p) => p.content.value!.id))).sort(
        (a, b) => {
          const postA = state.entities.posts.byId[a];
          const postB = state.entities.posts.byId[b];
          // Sort in descending order (newest first)
          return postB.publishTime.toMillis() - postA.publishTime.toMillis();
        }
      );

      state.hasMoreRecentPosts = state.entities.posts.employeeRecentPostIds.length < action.payload.totalCount;
      state.ui.employeeRecentPostsStatus = ActionStatus.Idle;
    },
    retrieveEmployeeRecentPostsFailed: (state) => {
      state.ui.employeeRecentPostsStatus = ActionStatus.Failed;
    },
    saveDraftPostRequested: (state, _action: PayloadAction<SaveDraftPostRequest>) => {
      state.ui.isPostSavedToDraftStatus = ActionStatus.Pending;
    },
    saveDraftPostSucceeded: (state) => {
      state.ui.isPostSavedToDraft = true;
      state.ui.isPostSavedToDraftStatus = ActionStatus.Idle;
    },
    saveDraftPostFailed: (state) => {
      state.ui.isPostSavedToDraftStatus = ActionStatus.Failed;
    },
    retrieveLastDraftPostRequested: (_state, _action: PayloadAction<RetrieveLastDraftPostRequest>) => {},
    retrieveLastDraftPostSucceeded: (state, action: PayloadAction<DraftPost>) => {
      state.postDraft = action.payload;
    },
    retrieveLastDraftPostFailed: (_state) => {},
    deleteDraftPostsRequested: (state, _action: PayloadAction<DeleteDraftPostsRequest>) => {
      state.ui.isPostDraftDeletedStatus = ActionStatus.Pending;
    },
    deleteDraftPostsSucceeded: (state) => {
      state.ui.isPostDraftDeleted = true;
      state.ui.isPostDraftDeletedStatus = ActionStatus.Idle;
      state.postDraft = {} as DraftPost;
    },
    deleteDraftPostsFailed: (state) => {
      state.ui.isPostDraftDeletedStatus = ActionStatus.Failed;
    },
    applyDraftedPost: (state) => {
      state.postDraftText = state.postDraft.text;
    },
    clearDraftedPost: (state) => {
      state.postDraftText = '';
    },
    editCommentRequested: (_state, _action: PayloadAction<EditCommentRequest>) => {},
    editCommentSucceeded: (state, action: PayloadAction<EditCommentRequest>) => {
      state.entities.comments.byId[action.payload.commentId] = {
        ...state.entities.comments.byId[action.payload.commentId],
        text: action.payload.commentText,
        editedTime: DateTime.utc()
      } as CommentModel;
    },
    editCommentFailed: (_state) => {},
    removeAudience: (state, action: PayloadAction<string>) => {
      const deletedAudienceId = action.payload;

      delete state.entities.audiences.byId[deletedAudienceId];
      state.entities.audiences.withPostingPrivilegesAudienceIds = state.entities.audiences.withPostingPrivilegesAudienceIds.filter(
        (audienceId) => audienceId !== deletedAudienceId
      );
    },
    retrieveAudiencesWithPostingRightsByEmployeeIdRequested: (state, _action: PayloadAction<RetrieveAudiencesWithPostingRightsByEmployeeIdRequest>) => {
      state.entities.audiences.withPostingPrivilegesAudienceIds = [];
      state.ui.audiencesWithPostingPrivilegesStatus = ActionStatus.Pending;
    },
    retrieveAudiencesWithPostingRightsByEmployeeIdSucceeded: (state, action: PayloadAction<RetrieveAudiencesWithPostingRightsByEmployeeIdResponse>) => {
      const { audiences } = action.payload;

      for (const audience of audiences) {
        state.entities.audiences.byId[audience.audienceId] = audience;
        state.entities.audiences.withPostingPrivilegesAudienceIds.push(audience.audienceId);
      }

      if (!state.defaultAudienceId && audiences.length > 0) {
        state.defaultAudienceId = audiences[0].audienceId;
      }

      state.ui.audiencesWithPostingPrivilegesStatus = ActionStatus.Idle;
    },
    retrieveAudiencesWithPostingRightsByEmployeeIdFailed: (state) => {
      state.ui.audiencesWithPostingPrivilegesStatus = ActionStatus.Failed;
    },
    retrieveMentionEmployeesRequested: (state, _action: PayloadAction<RetrieveEmployeeIdsByAudienceIdRequest>) => {
      state.ui.mentionEmployeesStatus = ActionStatus.Pending;
      state.entities.mentionEmployees.allIds = [];
    },
    retrieveMentionEmployeesSucceeded: (state, action: PayloadAction<EmployeeListItem[]>) => {
      const employees = action.payload;

      for (const employee of employees) {
        state.entities.mentionEmployees.allIds.push(employee.employeeId);
      }

      state.ui.mentionEmployeesStatus = ActionStatus.Idle;
    },
    retrieveMentionEmployeesFailed: (state) => {
      state.ui.mentionEmployeesStatus = ActionStatus.Failed;
    },
    retrievePostAnalyticsRequested: (state, _action: PayloadAction<RetrievePostAnalyticsRequest>) => {
      state.entities.employeesWithTime.byId = {};
      state.entities.employeesWithTime.allIds = [];
      state.ui.postAnalyticsStatus = ActionStatus.Pending;
    },
    retrievePostAnalyticsSucceeded: (state, action: PayloadAction<RetrievePostAnalyticsResponse>) => {
      const { employeesWithTime } = action.payload;

      for (const employee of employeesWithTime) {
        state.entities.employeesWithTime.byId[employee.employeeId] = employee;
        state.entities.employeesWithTime.allIds.push(employee.employeeId);
      }

      state.ui.postAnalyticsStatus = ActionStatus.Idle;
    },
    retrievePostAnalyticsFailed: (state) => {
      state.ui.postAnalyticsStatus = ActionStatus.Failed;
    },
    readPostRequested: (_state, _action: PayloadAction<ReadPostRequest>) => {},
    readPostSucceeded: (_state, _action: PayloadAction<Empty>) => {},
    readPostFailed: (_state) => {},
    confirmReadPost: (state, action: PayloadAction<PostReadConfirmed>) => {
      const { postId } = action.payload;

      state.entities.readPosts.byId[postId] = create(PostIdWithReadConfirmationSchema, { postId, isRead: true });
      state.entities.readPosts.allIds = uniq(state.entities.readPosts.allIds.concat(action.payload.postId));
    },
    retrieveArePostsReadRequested: (_state, _action: PayloadAction<RetrieveArePostsReadRequest>) => {},
    retrieveArePostsReadSucceeded: (state, action: PayloadAction<RetrieveArePostsReadResponse>) => {
      const { arePostsRead } = action.payload;

      for (const post of arePostsRead) {
        state.entities.readPosts.byId[post.postId] = post;
        state.entities.readPosts.allIds.push(post.postId);
      }
    },
    retrieveArePostsReadFailed: (_state) => {},
    retrievePostReadAnalyticsRequested: (_state, _action: PayloadAction<RetrievePostReadAnalyticsRequest>) => {},
    retrievePostReadAnalyticsSucceeded: (state, action: PayloadAction<RetrievePostReadAnalyticsResponse>) => {
      const { readEmployees, unreadEmployeeIds } = action.payload;

      state.readPostEmployees = readEmployees;
      state.unreadEmployeeIds = unreadEmployeeIds;
    },
    retrievePostReadAnalyticsFailed: (_state) => {},
    setCurrentPostAnalytic: (state, action: PayloadAction<PostModel>) => {
      state.currentPostAnalytic = action.payload;
    },
    retrieveCurrentPostAnalyticAudienceLengthRequested: (_state, _action: PayloadAction<RetrieveEmployeeIdsByAudienceIdRequest>) => {},
    retrieveCurrentPostAnalyticAudienceLengthSucceeded: (state, action: PayloadAction<EmployeeListItem[]>) => {
      state.currentPostAnalyticAudienceLength = action.payload.length;
    },
    retrieveCurrentPostAnalyticAudienceLengthFailed: (state) => {
      state.ui.mentionEmployeesStatus = ActionStatus.Failed;
    },
    setCurrentAudience: (state, action: PayloadAction<AudienceWithName | null>) => {
      state.currentAudience = action.payload;
    },
    removePostsFromLeftAudience: (state, action: PayloadAction<string>) => {
      state.entities.audiences.withPostingPrivilegesAudienceIds = state.entities.audiences.withPostingPrivilegesAudienceIds.filter(
        (audienceId) => audienceId !== action.payload
      );

      delete state.entities.audiences.byId[action.payload];

      state.entities.audiences.retrievedPostsAudienceIds = state.entities.audiences.retrievedPostsAudienceIds.filter(
        (audienceId) => audienceId !== action.payload
      );

      state.entities.posts.feedIds = state.entities.posts.feedIds.filter(
        (postId) => (state.entities.posts.byId[postId] as ShoutoutModel).audienceIds[0] !== action.payload
      );

      for (const postId of keys(state.entities.posts.byId)) {
        if ((state.entities.posts.byId[postId] as ShoutoutModel).audienceIds[0] === action.payload) {
          delete state.entities.posts.byId[postId];
        }
      }
    }
  }
});

export const selectIsPostDialogOpened = (state: RootState) => state.posts.ui.isPostDialogOpened;
export const selectNewPostsCounter = (state: RootState) => state.posts.ui.newPostsCounter;
export const selectFeedPostsStatus = (state: RootState) => state.posts.ui.postsStatus;
export const selectPublishPostStatus = (state: RootState) => state.posts.ui.publishPostStatus;
export const selectCommentOnPostStatus = (state: RootState) => state.posts.ui.commentOnPostStatus;
export const selectDeleteCommentStatus = (state: RootState) => state.posts.ui.deleteCommentStatus;
export const selectUploadPostFileStatus = (state: RootState) => state.posts.ui.uploadPostFileStatus;
export const selectPostFileUploadProgress = (state: RootState) => state.posts.ui.fileUploadProgress.fileProgress;
export const selectPostFilesUploadProgress = (state: RootState) => state.posts.ui.fileUploadProgress.totalProgress;

export const selectPinnedPostReactionsGroupedByEmoji = (state: RootState, postId: string) => state.posts.entities.posts.byId[postId].reactions;

export const selectIsPostFileUploading = (state: RootState, filename: string) =>
  state.posts.ui.postFilesWithStatus.find((fws) => fws.file.name === filename)!.isUploading;

export const selectIsPostFileUploaded = (state: RootState, filename: string) =>
  state.posts.ui.postFilesWithStatus.find((fws) => fws.file.name === filename)!.isUploaded;

export const selectPostFileUrl = (state: RootState, localFilename: string) => state.posts.ui.postFilesWithStatus.find((fws) => fws.name === localFilename)!.url;

export const selectNewPostFiles = (state: RootState) => state.posts.ui.postFilesWithStatus;

export const selectPostFilesWithStatus = (state: RootState) => state.posts.ui.postFilesWithStatus;

export const selectPostFileWithStatus = (state: RootState, filename: string) => state.posts.ui.postFilesWithStatus.find((fws) => fws.file.name === filename);
export const selectPostFilesWithStatusForUpload = (state: RootState) =>
  state.posts.ui.postFilesWithStatus.filter((fws) => fws.isUploaded === false && fws.isUploading === false && fws.url === '');

export const selectNewFilesUrls = createSelector([selectNewPostFiles], (fws) => fws.map((m) => m.url));

export const selectDeletePostStatus = (state: RootState) => state.posts.ui.deletePostStatus;
export const selectDeletePostId = (state: RootState) => state.posts.ui.deletePostId;

export const selectPostReactionsGroupedByEmoji = (state: RootState, postId: string) => state.posts.entities.posts.byId[postId].reactions;

export const selectCommentReactionsGroupedByEmoji = (state: RootState, commentId: string) => state.posts.entities.comments.byId[commentId].reactions;

export const selectComments = (state: RootState) => state.posts.entities.comments;
export const selectCommentsById = (state: RootState) => state.posts.entities.comments.byId;

export const selectCommentIdsByPostId = (state: RootState, postId: string) => state.posts.entities.posts.byId[postId].commentIds;

export const selectPostStatus = (state: RootState) => state.posts.ui.postsStatus;

export const selectPostFeedIds = (state: RootState) => state.posts.entities.posts.feedIds;

export const selectPostsById = (state: RootState) => state.posts.entities.posts.byId;

export const selectPostsFeedCount = (state: RootState) => state.posts.entities.posts.feedIds.length;

export const selectPostById = (state: RootState, postId: string) => state.posts.entities.posts.byId[postId];

export const selectPostCreateDeleteSasUri = (state: RootState) => state.posts.postCreateDeleteSasUri;
export const selectPostCreateDeleteSasToken = createSelector([selectPostCreateDeleteSasUri], (pwds) => new URL(pwds).search);
export const selectPostReadSasToken = (state: RootState) => state.posts.postReadSasToken;
export const selectHasMorePosts = (state: RootState) => state.posts.hasMore;
export const selectCurrentPostsPage = (state: RootState) => state.posts.currentPostsPage;
export const selectEmployeeRecentPostsIds = (state: RootState) => state.posts.entities.posts.employeeRecentPostIds;
export const selectEmployeeRecentPostsStatus = (state: RootState) => state.posts.ui.employeeRecentPostsStatus;
export const selectEmployeeHasMoreRecentPosts = (state: RootState) => state.posts.hasMoreRecentPosts;
export const selectEmployeeCurrentRecentPostsPage = (state: RootState) => state.posts.currentRecentPostsPage;
export const selectDeletedCommentId = (state: RootState) => state.posts.deletedCommentId!;

export const selectIsPostSavedToDraft = (state: RootState) => state.posts.ui.isPostSavedToDraft;
export const selectIsPostSavedToDraftStatus = (state: RootState) => state.posts.ui.isPostSavedToDraftStatus;
export const selectIsPostDraftDeleted = (state: RootState) => state.posts.ui.isPostDraftDeleted;
export const selectIsPostDraftDeletedStatus = (state: RootState) => state.posts.ui.isPostDraftDeletedStatus;
export const selectPostDraft = (state: RootState) => state.posts.postDraft;
export const selectPostDraftText = (state: RootState) => state.posts.postDraftText;

export const selectAudiencesById = (state: RootState) => state.posts.entities.audiences.byId;
export const selectPostsAudienceIds = (state: RootState) => state.posts.entities.audiences.retrievedPostsAudienceIds;
export const selectAudiencesWithPostingPrivilegesIds = (state: RootState) => state.posts.entities.audiences.withPostingPrivilegesAudienceIds;
export const selectAudienceIdsWithPostingPrivilegesStatus = (state: RootState) => state.posts.ui.audiencesWithPostingPrivilegesStatus;

export const selectAudienceWithNameByAudienceId = createSelector([selectAudiencesById, (_, audienceId) => audienceId], (byId, audienceId) => byId[audienceId]);

const selectDefaultAudienceId = (state: RootState) => state.posts.defaultAudienceId;
export const selectDefaultAudience = createSelector([selectAudiencesById, selectDefaultAudienceId], (byId, defaultAudienceId) => {
  if (!defaultAudienceId) return undefined;
  return byId[defaultAudienceId];
});

export const selectAudiencesWithNames = createSelector([selectAudiencesById, selectAudiencesWithPostingPrivilegesIds], (byId, ids) =>
  ids.map((id) => byId[id])
);

export const selectAudienceIdByPostId = createSelector([selectPostById], (post) => post.audienceIds[0]);

export const selectMentionEmployeesIds = (state: RootState) => state.posts.entities.mentionEmployees.allIds;

export const selectEmployeesWithTimeById = (state: RootState) => state.posts.entities.employeesWithTime.byId;
export const selectEmployeesWithTimeIds = (state: RootState) => state.posts.entities.employeesWithTime.allIds;

export const selectEmployeesWithTime = createSelector([selectEmployeesWithTimeById, selectEmployeesWithTimeIds], (byId, ids) => ids.map((id) => byId[id]));

const groupViewData = (data: EmployeeIdWithTime[]) =>
  chain(data)
    .groupBy((item) => DateTime.fromISO(item.viewedAt).toFormat('dd-MM-yyyy'))
    .map((items, date) => ({ date, views: items.length }))
    .value();

export const selectPostViewData = createSelector([selectEmployeesWithTime], (views) => groupViewData(views));

export const selectIsPostRead = (state: RootState, postId: string) => state.posts.entities.readPosts.byId[postId];
export const selectReadPostsById = (state: RootState) => state.posts.entities.readPosts.byId;
export const selectReadPostsIds = (state: RootState) => state.posts.entities.readPosts.allIds;

export const selectReadPosts = createSelector([selectEmployeesWithTimeById, selectEmployeesWithTimeIds], (byId, ids) => ids.map((id) => byId[id]));

export const selectCurrentPostAnalytic = (state: RootState) => state.posts.currentPostAnalytic;
export const selectCurrentPostAnalyticId = (state: RootState) => state.posts.currentPostAnalytic?.id ?? '';
export const selectCurrentPostAnalyticAudienceLength = (state: RootState) => state.posts.currentPostAnalyticAudienceLength;

const groupReadData = (data: EmployeeReadPost[]) =>
  chain(data)
    .groupBy((item) => DateTime.fromISO(item.readAt).toFormat('dd-MM-yyyy'))
    .map((items, date) => ({ date, reads: items.length }))
    .value();

export const selectReadEmployees = (state: RootState) => state.posts.readPostEmployees;
export const selectUnreadEmployeeIds = (state: RootState) => state.posts.unreadEmployeeIds;

export const selectPostReadData = createSelector([selectReadEmployees], (reads) => groupReadData(reads));

export const selectPostViewAndReadData = createSelector([selectPostViewData, selectPostReadData], (groupedViews, groupedReads) =>
  chain([...groupedViews, ...groupedReads])
    .groupBy('date')
    .map((items, date) => ({
      date,
      views: chain(items).sumBy('views').value() || 0,
      reads: chain(items).sumBy('reads').value() || 0
    }))
    .value()
);
export const selectPostAnalyticsStatus = (state: RootState) => state.posts.ui.postAnalyticsStatus;
export const selectCurrentAudience = (state: RootState) => state.posts.currentAudience!;

export const selectPostNotificationStatus = (state: RootState) => state.posts.ui.postNotificationStatus;

export const {
  setCurrentAuthorId,
  openPostDialog,
  newPostPublished,
  closePostDialogRequested,
  closePostDialogSucceeded,
  closePostDialogFailed,
  withdrawPostReaction,
  showNewComment,
  showNewReaction,
  deleteCommentOnPost,
  showNewReactionToComment,
  withdrawCommentReaction,
  deletePost,
  editPostRequested,
  editPostSucceeded,
  editPostFailed,
  retrievePostsRequested,
  retrievePostsSucceeded,
  retrievePostsFailed,
  uploadPostFilesRequested,
  uploadPostFilesSucceeded,
  uploadPostFilesFailed,
  deletePostSingleFileRequested,
  deletePostSingleFileSucceeded,
  deletePostSingleFileFailed,
  publishShoutoutRequested,
  publishShoutoutSucceeded,
  publishShoutoutFailed,
  commentOnPostRequested,
  commentOnPostSucceeded,
  commentOnPostFailed,
  deleteCommentRequested,
  deleteCommentSucceeded,
  deleteCommentFailed,
  reactToPostRequested,
  reactToPostSucceeded,
  reactToPostFailed,
  withdrawPostReactionRequested,
  withdrawPostReactionSucceeded,
  withdrawPostReactionFailed,
  reactToCommentRequested,
  reactToCommentSucceeded,
  reactToCommentFailed,
  withdrawCommentReactionRequested,
  withdrawCommentReactionSucceeded,
  withdrawCommentReactionFailed,
  deletePostRequested,
  deletePostSucceeded,
  deletePostFailed,
  updatePostFileUploadProgress,
  postFileUploaded,
  uploadPostFile,
  retrievePostCreateDeleteSasUriFailed,
  retrievePostCreateDeleteSasUriRequested,
  retrievePostCreateDeleteSasUriSucceeded,
  retrievePostReadSasTokenFailed,
  retrievePostReadSasTokenRequested,
  retrievePostReadSasTokenSucceeded,
  refreshPostCreateDeleteSasUriFailed,
  refreshPostCreateDeleteSasUriRequested,
  refreshPostCreateDeleteSasUriSucceeded,
  retrieveEmployeeRecentPostsRequested,
  retrieveEmployeeRecentPostsSucceeded,
  retrieveEmployeeRecentPostsFailed,
  saveDraftPostRequested,
  saveDraftPostSucceeded,
  saveDraftPostFailed,
  retrieveLastDraftPostRequested,
  retrieveLastDraftPostSucceeded,
  retrieveLastDraftPostFailed,
  deleteDraftPostsRequested,
  deleteDraftPostsSucceeded,
  deleteDraftPostsFailed,
  applyDraftedPost,
  clearDraftedPost,
  editCommentRequested,
  editCommentSucceeded,
  editCommentFailed,
  editShoutout,
  removeAudience,
  retrieveAudiencesWithPostingRightsByEmployeeIdFailed,
  retrieveAudiencesWithPostingRightsByEmployeeIdRequested,
  retrieveAudiencesWithPostingRightsByEmployeeIdSucceeded,
  retrieveMentionEmployeesRequested,
  retrieveMentionEmployeesSucceeded,
  retrieveMentionEmployeesFailed,
  retrievePostAnalyticsRequested,
  retrievePostAnalyticsSucceeded,
  retrievePostAnalyticsFailed,
  confirmReadPost,
  readPostRequested,
  readPostSucceeded,
  readPostFailed,
  retrieveNotificationPostsByIdsRequested,
  retrieveNotificationPostsByIdsSucceeded,
  retrieveNotificationPostsByIdsFailed,
  retrieveArePostsReadRequested,
  retrieveArePostsReadSucceeded,
  retrieveArePostsReadFailed,
  retrievePostReadAnalyticsRequested,
  retrievePostReadAnalyticsSucceeded,
  retrievePostReadAnalyticsFailed,
  setCurrentPostAnalytic,
  retrieveCurrentPostAnalyticAudienceLengthRequested,
  retrieveCurrentPostAnalyticAudienceLengthSucceeded,
  retrieveCurrentPostAnalyticAudienceLengthFailed,
  setCurrentAudience,
  removePostsFromLeftAudience
} = postsSlice.actions;
export default postsSlice.reducer;
