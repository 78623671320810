import { Typography } from '@mui/material';
import { TreeItem2Label } from '@mui/x-tree-view/TreeItem2';
import { ElementType, ReactNode } from 'react';
import { AssetsTooltip } from './styled/AssetsTooltip';
import { FolderIcon, RowCenterStack } from '@/shared';
import { AssetsMoveButton } from './styled/AssetsMoveButton';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { closeMoveModal, moveFilesRequested, selectSelectedFolderId } from '../assets.slice';
import { MoveFilesRequest } from '@thrivea/organization-client';
import { selectCurrentEmployeeId } from '@app/user';

interface AssetsModalTreeItemLabelProps {
  children: ReactNode;
  id: string;
  icon?: ElementType;
  expandable?: boolean;
  focused?: boolean;
}

export const AssetsModalTreeItemLabel: React.FC<AssetsModalTreeItemLabelProps> = ({ children, id, icon, expandable, focused, ...props }) => {
  const dispatch = useAppDispatch();
  const selectedFolderId = useAppSelector(selectSelectedFolderId);
  const signedInEmployeeId = useAppSelector(selectCurrentEmployeeId);

  const handleMoveFileButtonClick = () => {
    dispatch(moveFilesRequested(new MoveFilesRequest({
      destinationFolderId: selectedFolderId,
      sourceFileIds: [id],
      movedBy: signedInEmployeeId
    })));
    dispatch(closeMoveModal());
  }

  return (
    <TreeItem2Label
      {...props}
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: 1,
        gap: '8px',
        height: 25,
        width: '100%',
        justifyContent: 'space-between'
      }}
    >
      <RowCenterStack gap={'4px'} sx={{ width: '100%' }}>
        <FolderIcon width={24} height={24} />
        <AssetsTooltip title={children} arrow>
          <Typography
            variant="body2"
            noWrap
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap'
            }}
          >
            {children}
          </Typography>
        </AssetsTooltip>
      </RowCenterStack>
      {/* <AssetsMoveButton onClick={handleMoveFileButtonClick}>Move</AssetsMoveButton> */}
    </TreeItem2Label>
  );
};
