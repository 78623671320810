import { Stack, StackProps, styled } from "@mui/material";

export const AudienceMemberCountStack = styled(Stack)<StackProps>(({ theme }) => ({
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    minHeight: 'unset',
    backgroundColor: theme.palette.customTheme.drawerBackground,
    pl: theme.spacing(8),
    pr: theme.spacing(4),
    py: theme.spacing(2),
    justifyContent: 'space-between'
}))