import { Stack, StackProps, styled } from '@mui/material';

export const TasksDrawerActions = styled(Stack)<StackProps>(() => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-end',
  position: 'sticky',
  bottom: 0,
  marginTop: 'auto',
  minHeight: 67,
  backgroundColor: 'transparent',
  zIndex: 10,
  boxShadow: '0px -2px 50px 0px rgba(45, 43, 43, 0.05)'
}));
