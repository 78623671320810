import { Button, ButtonProps, styled } from '@mui/material';

export const MenuDropdownActionButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isActive'
})<ButtonProps & { isActive?: boolean }>(({ theme, isActive }) => ({
  width: '100%',
  color: theme.palette.primary.main,
  fontSize: 14,
  fontWeight: 700,
  backgroundColor: isActive ? theme.palette.customTheme.selectItem : 'transparent',
  border: '1px solid',
  borderColor: isActive ? theme.palette.customTheme.selectItem : theme.palette.customTheme.contentBorder,
  borderRadius: '8px',
  padding: '6px 14px',
  justifyContent: 'flex-start',
  transition: theme.transitions.create('background-color, border-color', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.standard
  }),
  '&:hover': {
    backgroundColor: theme.palette.customTheme.contentBorderColor,
    borderColor: theme.palette.customTheme.contentBorderColor
  }
}));
