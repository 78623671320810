import { styled, Button, ButtonProps } from '@mui/material';

export const BillingButtonOutlined = styled(Button)<ButtonProps>(({ theme }) => ({
  height: 43,
  backgroundColor: 'transparent',
  borderRadius: '10px',
  color: theme.palette.primary.main,
  fontWeight: 700,
  padding: '12px 32px',
  border: '1px solid',
  borderColor: theme.palette.grey[900]
}));
