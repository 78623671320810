import React from 'react';
import { Link as RouterLink } from 'react-router';
import { useTranslation } from 'react-i18next';
import { StyledListItemButton } from '@/shared';

interface AdminSettingsSiteLinkProps {
  siteId: string;
}

export const AdminSettingsSiteLink: React.FC<AdminSettingsSiteLinkProps> = ({ siteId }) => {
  const { t } = useTranslation(['settings_sites']);
  return (
    <StyledListItemButton component={RouterLink} to={siteId} state={{ siteId }}>
      {t('view_and_edit', { ns: 'settings_sites' })}
    </StyledListItemButton>
  );
};
