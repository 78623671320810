import { Box, PopperProps, autocompleteClasses, paperClasses } from '@mui/material';
import { forwardRef } from 'react';

export const CustomAudienceGroupAutocompletePopper = forwardRef<HTMLDivElement, PopperProps>(function CustomPopper(props, ref) {
  const { children, open, anchorEl } = props;

  const getChildren = () => {
    if (typeof children === 'function') {
      return children({ placement: 'bottom-start' });
    }

    return children;
  };

  return (
    <Box
      ref={ref}
      sx={{
        display: open ? 'block' : 'none',
        padding: '7px 11px',
        width: anchorEl ? (anchorEl as HTMLElement).clientWidth : '100%',
        [`&.${autocompleteClasses.popper}`]: {
          position: 'static',
          boxShadow: 'none'
        },
        [`& .${paperClasses.root}`]: {
          boxShadow: 'none'
        }
      }}
    >
      {getChildren()}
    </Box>
  );
});
