import { forwardRef } from 'react';
import { TreeItem2Provider } from '@mui/x-tree-view/TreeItem2Provider';
import { useTreeItem2, UseTreeItem2Parameters } from '@mui/x-tree-view/useTreeItem2';
import {
  AssetsTransitionComponent,
  AssetsTreeItemContent,
  AssetsTreeItemIcon,
  AssetsTreeItemLabel,
  AssetsTreeItemRoot,
  selectSelectedFolderId
} from '@features/assets';
import { TreeItem2Checkbox, TreeItem2IconContainer } from '@mui/x-tree-view/TreeItem2';
import { TreeItem2Icon } from '@mui/x-tree-view/TreeItem2Icon';
import { TreeItem2DragAndDropOverlay } from '@mui/x-tree-view/TreeItem2DragAndDropOverlay';
import clsx from 'clsx';
import { useAppSelector } from 'src/app/hooks';

interface AssetsTreeItemProps extends Omit<UseTreeItem2Parameters, 'rootRef'>, Omit<React.HTMLAttributes<HTMLLIElement>, 'onFocus'> {}

const isExpandable = (reactChildren: React.ReactNode) => {
  if (Array.isArray(reactChildren)) {
    return reactChildren.length > 0 && reactChildren.some(isExpandable);
  }
  return Boolean(reactChildren);
};

export const AssetsTreeItem = forwardRef(function AssetsTreeItem(props: AssetsTreeItemProps, ref: React.Ref<HTMLLIElement>) {
  const { id, itemId, label, disabled, children, ...other } = props;

  const uniqueId = `${id}-${itemId}-${label}`; // Generate a unique ID for each item

  const {
    getRootProps,
    getContentProps,
    getIconContainerProps,
    getCheckboxProps,
    getLabelProps,
    getDragAndDropOverlayProps,
    status,
    getGroupTransitionProps,
    publicAPI
  } = useTreeItem2({ id: uniqueId, itemId, children, label, disabled, rootRef: ref }); // Pass `uniqueId` here

  const item = publicAPI.getItem(itemId);
  const expandable = isExpandable(children);
  const icon = () => <AssetsTreeItemIcon level={item.nestingLevel} />;
  const selectedFolderId = useAppSelector(selectSelectedFolderId);

  return (
    <TreeItem2Provider itemId={item.id}>
      <AssetsTreeItemRoot {...getRootProps(other)}>
        <AssetsTreeItemContent
          {...getContentProps({
            className: clsx('content', {
              'Mui-expanded': status.expanded,
              'Mui-selected': item.id === selectedFolderId,
              'Mui-focused': item.id === selectedFolderId,
              'Mui-disabled': status.disabled
            })
          })}
        >
          <TreeItem2IconContainer {...getIconContainerProps()}>
            <TreeItem2Icon status={status} />
          </TreeItem2IconContainer>
          <TreeItem2Checkbox {...getCheckboxProps()} />
          <AssetsTreeItemLabel {...getLabelProps({ icon, expandable: expandable && status.expanded, nestingLevel: item.nestingLevel })} />
          <TreeItem2DragAndDropOverlay {...getDragAndDropOverlayProps()} />
        </AssetsTreeItemContent>
        {children && <AssetsTransitionComponent {...getGroupTransitionProps()} />}
      </AssetsTreeItemRoot>
    </TreeItem2Provider>
  );
});
