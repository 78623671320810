import React, { useCallback, useEffect, useState } from 'react';
import { Popover, FormControlLabel, Box, TextField, InputAdornment, Stack, IconButton, Typography, typographyClasses } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import {
  BinIcon,
  CancelEditIcon,
  CustomCheckBox,
  HighlightText,
  RowCenterStack,
  SlidersIcon,
  StyledBlurryModal,
  CancelButton,
  StyledFormSubmitButton,
  StyledModalContent,
  StyledAutocompleteListItem,
  StyledCheckbox
} from '@/shared';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import {
  ALL_DATA_ID,
  Option,
  deletePeopleDirViewRequested,
  toggleVisibilityPeopleDirView,
  selectPeopleDirViewsAsOptions,
  selectVisiblePeopleDirViewsIds,
  FilterButton
} from '@features/people-directory';
import { DeletePeopleDirViewRequest } from '@thrivea/organization-client';
import { useTranslation } from 'react-i18next';

// The hook for filtering dropdown Options
const useFilteredFields = (providedOptions: Option[], value: string) => {
  const [options, setOptions] = useState<Option[]>([]);

  const filterOptions = useCallback(
    () => providedOptions.filter((option) => option.name.toLowerCase().includes(value.toLowerCase())),
    [providedOptions, value]
  );

  useEffect(() => {
    setOptions(filterOptions());
  }, [filterOptions]);

  return options;
};

export const TaskVewFilterDropdown = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['common']);

  const views = useAppSelector(selectPeopleDirViewsAsOptions);
  const visibleViewIds = useAppSelector(selectVisiblePeopleDirViewsIds);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [viewToDelete, setViewToDelete] = useState<Option>({ id: '', name: '' });

  const filteredViews = useFilteredFields(views, searchText);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteModalOpen = (view: Option) => {
    setIsDeleteModalOpen(true);
    setViewToDelete(view);
  };

  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
  };

  const handleDeleteViewSubmitButtonClick = () => {
    dispatch(
      deletePeopleDirViewRequested(
        new DeletePeopleDirViewRequest({
          viewId: viewToDelete!.id
        })
      )
    );

    setIsDeleteModalOpen(false);
  };

  const isOpened = Boolean(anchorEl);
  const id = isOpened ? 'filter-popover' : undefined;

  return (
    <>
      <FilterButton aria-describedby={id} onClick={handleClick}>
        <SlidersIcon />
      </FilterButton>
      <Popover
        id={id}
        open={isOpened}
        anchorEl={anchorEl}
        onClose={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              borderRadius: 2,
              border: '1px solid',
              borderColor: (theme) => theme.palette.primary.main
            }
          }
        }}
      >
        <Stack
          gap={1}
          sx={{
            pl: 3,
            pr: 1,
            py: 2
          }}
        >
          <TextField
            onChange={(e) => setSearchText(e.target.value)}
            placeholder="Search"
            fullWidth
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="primary" sx={{ fontSize: '2rem' }} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <SlidersIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }
            }}
            sx={{
              marginLeft: -1,
              '& .MuiInputBase-root': {
                paddingLeft: 0
              },
              '& input': {
                padding: 1
              },
              '& fieldset': {
                border: '0 !important'
              }
            }}
          />
          {filteredViews.map((view) => (
            <StyledAutocompleteListItem key={view.id} aria-selected={visibleViewIds.includes(view.id)}>
              <FormControlLabel
                key={view.id}
                control={
                  <StyledCheckbox
                    icon={<CustomCheckBox size={18} />}
                    checked={visibleViewIds.includes(view.id)}
                    onChange={(_, checked) => dispatch(toggleVisibilityPeopleDirView({ viewId: view.id, hide: !checked }))}
                    disabled={view.id === ALL_DATA_ID}
                    name={view.name}
                  />
                }
                label={
                  <RowCenterStack
                    sx={{
                      justifyContent: 'space-between'
                    }}
                  >
                    <Typography>
                      <HighlightText text={view.name} highlight={searchText} />
                    </Typography>
                    {view.id !== ALL_DATA_ID && (
                      <IconButton onClick={() => handleDeleteModalOpen(view)}>
                        <BinIcon width={18} height={18} />
                      </IconButton>
                    )}
                  </RowCenterStack>
                }
                sx={{
                  width: '100%',
                  gap: 1,
                  alignItems: 'center',
                  marginLeft: 0,
                  [`& .${typographyClasses.root}`]: {
                    width: '100%'
                  }
                }}
              />
              <StyledBlurryModal component="div" open={isDeleteModalOpen} onClose={handleDeleteModalClose}>
                <StyledModalContent
                  sx={{
                    position: 'relative',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    minWidth: {
                      xs: 'auto',
                      lg: '670px'
                    },
                    height: {
                      xs: '80%',
                      lg: 'auto'
                    },
                    padding: '120px',
                    display: 'flex',
                    borderRadius: '20px',
                    boxShadow: 'none'
                  }}
                >
                  <Stack
                    sx={{
                      width: '100%',
                      height: '100%',
                      alignItems: 'center',
                      justifyContent: 'center',
                      textAlign: 'center'
                    }}
                  >
                    <Typography variant="subtitle1">{t('delete_view_title', { ns: 'common', viewName: viewToDelete.name })}</Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        margin: '24px 0',
                        color: '#637381'
                      }}
                    >
                      {t('delete_view_question', {
                        ns: 'common',
                        viewName: viewToDelete.name
                      })}
                    </Typography>
                    <RowCenterStack gap={2}>
                      <CancelButton variant="contained" startIcon={<CancelEditIcon />} onClick={handleDeleteModalClose}>
                        {t('cancel', { ns: 'common' })}
                      </CancelButton>
                      <StyledFormSubmitButton variant="contained" onClick={handleDeleteViewSubmitButtonClick}>
                        {t('delete', { ns: 'common' })}
                      </StyledFormSubmitButton>
                    </RowCenterStack>
                  </Stack>
                </StyledModalContent>
              </StyledBlurryModal>
            </StyledAutocompleteListItem>
          ))}
        </Stack>
      </Popover>
    </>
  );
};
