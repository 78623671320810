import {
  RetrieveSiteByIdRequest,
  RetrieveEmployeesBySiteIdRequest,
  RetrieveEmployeeBasicInfoRequest,
  RetrieveEmployeesByWorkingPatternIdRequest,
  RetrieveSitesByWorkingPatternIdRequest,
  RetrieveFilesInfoRequest,
  RetrieveEmployeesByJobTitleRequest,
  RetrieveFilesAccessRequest,
  RetrieveErbFieldsByCategoryIdRequest
} from '@thrivea/organization-client';
import { put } from 'redux-saga/effects';
import {
  retrieveSiteByIdRequested,
  retrieveEmployeesBySiteIdRequested,
  retrieveEmployeeBasicInfoRequested,
  retrieveEmployeesByWorkingPatternIdRequested,
  retrieveSitesByWorkingPatternIdRequested,
  setCurrentWorkingPatternDrawer,
  retrieveEmployeesByJobTitleRequested,
  retrieveAudienceByIdRequested,
  setCurrentAudienceRole,
  RoleListItem
} from '../admin-settings';
import { retrieveFilesAccessRequested, retrieveFilesInfoRequested, selectMultipleFiles } from '../assets';
import { RetrieveAudienceByIdRequest } from '@thrivea/networking-client';
import { RetrievePostAnalyticsRequest } from '@thrivea/networking-analytics-client';
import { retrievePostAnalyticsRequested } from '../homepage';
import { RetrieveNotificationsRequest } from '@thrivea/notification-client';
import { retrieveNewNotificationsRequested } from '../notifications';
import { retrieveErbFieldsByCategoryIdRequested, setActiveSectionId } from '../employee-record-builder';
import { TasksEditTaskDrawerRequest } from './drawer.model';
import { openTasksEditTaskDrawer } from './drawer.slice';

export function* openSiteDrawer(siteId: string) {
  yield put(
    retrieveSiteByIdRequested(
      new RetrieveSiteByIdRequest({
        id: siteId
      })
    )
  );

  yield put(
    retrieveEmployeesBySiteIdRequested(
      new RetrieveEmployeesBySiteIdRequest({
        siteId,
        pageNumber: 1,
        pageSize: 20
      })
    )
  );
}

export function* openJobTitle(jobTitle: string) {
  // TODO: move to dedicated generator functions to declutter this function
  yield put(
    retrieveEmployeesByJobTitleRequested(
      new RetrieveEmployeesByJobTitleRequest({
        jobTitle,
        pageNumber: 1,
        pageSize: 10
      })
    )
  );
}

export function* openAudience(audienceId: string) {
  yield put(retrieveAudienceByIdRequested(new RetrieveAudienceByIdRequest({ audienceId })));
}

export function* openAudienceRole(role: RoleListItem) {
  yield put(setCurrentAudienceRole(role));
}

export function* openPostAnalytics(postId: string) {
  yield put(
    retrievePostAnalyticsRequested(
      new RetrievePostAnalyticsRequest({
        postId
      })
    )
  );
}

export function* openNotifications(hasUnreadNotifications: boolean, notificationsEmployeeId: string) {
  if (hasUnreadNotifications)
    yield put(
      retrieveNewNotificationsRequested(
        new RetrieveNotificationsRequest({
          employeeId: notificationsEmployeeId,
          pageSize: 10,
          pageNumber: 1
        })
      )
    );
}

export function* openEmployeeRecord(erbCategoryId: string, erTemplateSectionId: string) {
  yield put(
    retrieveErbFieldsByCategoryIdRequested({
      request: new RetrieveErbFieldsByCategoryIdRequest({ erbCategoryId }),
      sectionId: erTemplateSectionId
    })
  );

  yield put(setActiveSectionId(erTemplateSectionId));
}

export function* openSingleEmployeeDrawer(employeeId: string) {
  yield put(
    retrieveEmployeeBasicInfoRequested(
      new RetrieveEmployeeBasicInfoRequest({
        employeeId
      })
    )
  );
}

export function* openWorkingPatternDrawer(workingPatternId: string, workingPatternName: string) {
  yield put(
    retrieveEmployeesByWorkingPatternIdRequested(
      new RetrieveEmployeesByWorkingPatternIdRequest({
        workingPatternId,
        pageNumber: 1,
        pageSize: 10
      })
    )
  );

  yield put(
    retrieveSitesByWorkingPatternIdRequested(
      new RetrieveSitesByWorkingPatternIdRequest({
        workingPatternId,
        pageNumber: 1,
        pageSize: 10
      })
    )
  );

  yield put(
    setCurrentWorkingPatternDrawer({
      id: workingPatternId,
      name: workingPatternName
    })
  );
}

export function* openAssetsDrawer(fileId: string) {
  yield put(selectMultipleFiles([fileId]));

  yield put(
    retrieveFilesInfoRequested(
      new RetrieveFilesInfoRequest({
        selectedFileIds: [fileId]
      })
    )
  );
}

export function* openAssetsMultipleFilesDrawer(fileIds: string[]) {
  yield put(
    retrieveFilesInfoRequested(
      new RetrieveFilesInfoRequest({
        selectedFileIds: fileIds
      })
    )
  );
}

export function* openAssetsShareFile(fileId: string) {
  yield put(
    retrieveFilesAccessRequested(
      new RetrieveFilesAccessRequest({
        selectedFileIds: [fileId]
      })
    )
  );
}

export function* handleOpenTasksEditTaskDrawer(action: { payload: TasksEditTaskDrawerRequest }) {
  const { activeTab } = action.payload;
  yield put(openTasksEditTaskDrawer({ activeTab }));
}
