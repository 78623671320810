import { Typography } from '@mui/material';
import { StackCenterAlign, StyledHomeLinkButton } from '@/shared';
import { useTranslation } from 'react-i18next';

export const UnexpectedAppErrorPage = () => {
  const { t } = useTranslation(['common']);

  return (
    <StackCenterAlign gap={2}>
      <Typography variant="h4">{t('something_went_wrong', { ns: 'common' })}</Typography>
      <StyledHomeLinkButton
        variant="contained"
        onClick={() => {
          window.location.href = '/';
        }}
      >
        {t('return_to_home', { ns: 'common' })}
      </StyledHomeLinkButton>
    </StackCenterAlign>
  );
};
