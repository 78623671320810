import { PictureSizeSuffix } from 'src/shared';

export const pictureUrl = (pictureUrl: string, sasToken: string, size: PictureSizeSuffix) => {
  if (pictureUrl === '' || sasToken === '') return undefined;

  const picturePath = new URL(pictureUrl);
  const pathNameWithoutExtension = picturePath.pathname.replace(/\.[^/.]+$/, '');
  const urlWithSize = `${picturePath.origin}${pathNameWithoutExtension}${size}.webp`;

  return new URL(sasToken, urlWithSize).toString();
};
