import { BlobClient, BlockBlobClient, BlockBlobUploadOptions, ContainerClient, Metadata } from '@azure/storage-blob';
import { joinUrlParts } from '@utils/joinUrlParts';
import { trimQueryParams } from '@utils/trimQueryParams';
import { MultiSizeImageBlobs } from '@/shared';

export interface CustomFile extends File {
  path?: string;
}

export interface UploadProgress {
  fileProgress: number;
  totalProgress: number;
}

export interface UploadedFile {
  fileWithStatus: FileWithStatus;
  url: string;
}

export interface FileWithStatus {
  id: string;
  isUploaded: boolean;
  isUploading: boolean;
  name: string;
  file: CustomFile;
  url: string;
}

const getTotalFilesSize = (filesWithStatus: FileWithStatus[]) => {
  let totalFileSize = 0;

  for (const fileWithStatus of filesWithStatus) {
    totalFileSize += fileWithStatus.file.size;
  }

  return totalFileSize;
};

export interface UploadFilesInDirectoryWithProgressRequest {
  sasUri: string;
  filesWithStatus: FileWithStatus[];
  handleUpdateFileUploadProgress: (fileProgress: number, totalProgress: number) => void;
  handleStartUploadingFile: (fileWithStatus: FileWithStatus) => void;
  handleFileUploaded: (uploadedFile: UploadedFile) => void;
}

export interface UploadFilesWithProgressRequest {
  sasUri: string;
  filesWithStatus: FileWithStatus[];
  handleUpdateFileUploadProgress: (fileProgress: number, totalProgress: number) => void;
  handleStartUploadingFile: (fileWithStatus: FileWithStatus) => void;
  handleFileUploaded: (uploadedFile: UploadedFile) => void;
}

export interface UploadFileWithProgressRequest {
  sasUri: string;
  fileWithStatus: FileWithStatus;
  handleUpdateFileUploadProgress: (fileProgress: number) => void;
  handleStartUploadingFile: (fileWithStatus: FileWithStatus) => void;
  handleFileUploaded: (uploadedFile: UploadedFile) => void;
}

export interface UploadFileMultipleSizes {
  sasUri: string;
  multiSizeImage: MultiSizeImageBlobs;
}

export const uploadFilesWithProgressInDirectory = async (request: UploadFilesInDirectoryWithProgressRequest): Promise<void> => {
  const { sasUri, filesWithStatus, handleUpdateFileUploadProgress, handleStartUploadingFile, handleFileUploaded } = request;

  const containerClient = new ContainerClient(sasUri);
  const totalFileSize = getTotalFilesSize(filesWithStatus);
  let currentProgress = 0;

  for (const fileWithStatus of filesWithStatus) {
    const fileSizePercentageInTotalFileSize = (fileWithStatus.file.size / totalFileSize) * 100;
    const uploadOptions = {
      metadata: {} as Metadata,
      blobHTTPHeaders: {
        blobContentType: fileWithStatus.file.type,
        blobContentDisposition: `attachment; fileName="${encodeURIComponent(fileWithStatus.file.name)}"`
      },
      onProgress: (e: { loadedBytes: number }) => {
        const fileUploadedRatio = e.loadedBytes / fileWithStatus.file.size;
        const totalPercentage = currentProgress + fileUploadedRatio * fileSizePercentageInTotalFileSize;
        const percentage = fileUploadedRatio * 100;

        handleUpdateFileUploadProgress(percentage, totalPercentage);
      }
    };

    handleStartUploadingFile(fileWithStatus);

    const fileExtension = fileWithStatus.file.type.split('/')[1];
    const filename = `${fileWithStatus.id}.${fileExtension}`;
    const blockBlobClient = containerClient.getBlockBlobClient(filename);

    await blockBlobClient.upload(fileWithStatus.file, fileWithStatus.file.size, uploadOptions);

    handleFileUploaded({ fileWithStatus, url: blockBlobClient.url });

    currentProgress += fileSizePercentageInTotalFileSize;
  }
};

export const uploadFileWithProgress = async (request: UploadFileWithProgressRequest): Promise<void> => {
  const { sasUri, fileWithStatus, handleUpdateFileUploadProgress, handleStartUploadingFile, handleFileUploaded } = request;
  const containerClient = new ContainerClient(sasUri);
  const uploadOptions: BlockBlobUploadOptions = {
    metadata: {} as Metadata,
    blobHTTPHeaders: {
      blobContentType: fileWithStatus.file.type,
      blobContentDisposition: `attachment; fileName="${encodeURIComponent(fileWithStatus.file.name)}"`
    },
    onProgress: (e: { loadedBytes: number }) => {
      const fileUploadedRatio = e.loadedBytes / fileWithStatus.file.size;
      const percentage = fileUploadedRatio * 100;
      handleUpdateFileUploadProgress(percentage);
    }
  };

  handleStartUploadingFile(fileWithStatus);

  const fileExtension = fileWithStatus.file.type.split('/')[1];
  const filename = joinUrlParts(`${fileWithStatus.id}.${fileExtension}`);
  const blockBlobClient = containerClient.getBlockBlobClient(filename);

  await blockBlobClient.upload(fileWithStatus.file, fileWithStatus.file.size, uploadOptions);

  handleFileUploaded({ fileWithStatus, url: blockBlobClient.url });
};

export const deleteFiles = async (sasToken: string, fileUrls: string[]): Promise<void> => {
  for (const fileUrl of fileUrls) {
    await deleteFile(sasToken, fileUrl);
  }
};

export const deleteFile = async (sasToken: string, fileUrl: string): Promise<void> => {
  const blockBlobClient = new BlockBlobClient(fileUrl + sasToken);
  await blockBlobClient.deleteIfExists();
};

export const uploadFileMultipleSizes = async (request: UploadFileMultipleSizes): Promise<string> => {
  const { sasUri, multiSizeImage } = request;
  const containerClient = new ContainerClient(sasUri);
  const uploadOptions: BlockBlobUploadOptions = {
    metadata: {} as Metadata,
    blobHTTPHeaders: {
      blobContentDisposition: `attachment; fileName="${encodeURIComponent(multiSizeImage.og.name)}"`
    }
  };

  const filename = crypto.randomUUID();
  const blockBlobClientOg = containerClient.getBlockBlobClient(`${filename}.${multiSizeImage.og.type.split('/')[1]}`);
  const blockBlobClientSm = containerClient.getBlockBlobClient(`${filename}_sm.webp`);
  const blockBlobClientMd = containerClient.getBlockBlobClient(`${filename}_md.webp`);
  const blockBlobClientLg = containerClient.getBlockBlobClient(`${filename}_lg.webp`);

  await blockBlobClientOg.upload(multiSizeImage.og, multiSizeImage.og.size, uploadOptions);
  await blockBlobClientSm.upload(multiSizeImage.sm, multiSizeImage.sm.size, uploadOptions);
  await blockBlobClientMd.upload(multiSizeImage.md, multiSizeImage.md.size, uploadOptions);
  await blockBlobClientLg.upload(multiSizeImage.lg, multiSizeImage.lg.size, uploadOptions);

  // remove suffix
  return trimQueryParams(blockBlobClientOg.url);
};

const CLEAR_QUOTES_PATTERN = /^["'](.+(?=["']$))["']$/;

export const fetchBlobFileNames = async (blobUrls: string[]): Promise<string[]> => {
  const fileNames: string[] = [];

  for (const blobUrl of blobUrls) {
    const properties = await new BlobClient(blobUrl).getProperties();

    // attachment; fileName="Free_Test_Data_100KB_PDF.pdf"
    const fileName = decodeURIComponent(properties.contentDisposition!.split(';')[1].split('=')[1]);

    fileNames.push(fileName.replace(CLEAR_QUOTES_PATTERN, '$1'));
  }

  return fileNames;
};
