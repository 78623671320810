import { styled } from '@mui/material';
import Grid, { Grid2Props } from '@mui/material/Grid2';

export const AsideGridItem = styled(Grid)<Grid2Props>(({ theme }) => ({
    width: 0,
    height: 'calc(100vh - 32px)',
    position: 'fixed',
    top: 16,
    bottom: 16,
    zIndex: theme.zIndex.drawer + 1,
    [theme.breakpoints.up('md')]: {
        width: 106
    }
}))