import { Chip, chipClasses, ChipProps, styled } from '@mui/material';

export const TaskViewUpdateChip = styled(Chip)<ChipProps>(({ theme }) => ({
  backgroundColor: theme.palette.customTheme.navColorSelected,
  color: theme.palette.common.white,
  padding: '3px 7px',
  borderRadius: '8px',
  fontSize: 12,
  fontWeight: 700,
  height: 20,
  zIndex: 2,
  [`& .${chipClasses.label}`]: {
    padding: 0
  }
}));
