import { Tabs, Typography, tabsClasses } from '@mui/material';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { selectTaskViewMode, setActiveTaskViewMode, TaskViewMode } from '@features/tasks';
import { FilterTab } from '@features/people-directory';
import { CalendarDaysIcon, RowCenterStack, TableColumnsIcon, TableListIcon, TimelineIcon } from '@/shared';
import { SyntheticEvent } from 'react';

export const TaskViewModeTabs = () => {
  const dispatch = useAppDispatch();

  const taskViewMode = useAppSelector(selectTaskViewMode);

  const handleTaskViewTabsChange = (_event: SyntheticEvent, newValue: TaskViewMode) => {
    dispatch(setActiveTaskViewMode(newValue));
  };

  return (
    <Tabs
      value={taskViewMode}
      variant="scrollable"
      scrollButtons="auto"
      onChange={handleTaskViewTabsChange}
      sx={{
        minHeight: 43,
        borderRadius: 2,
        maxWidth: '90%',
        justifyContent: 'left',
        [`& .${tabsClasses.flexContainer}`]: {
          gap: '8px'
        }
      }}
    >
      <FilterTab
        value={TaskViewMode.List}
        label={
          <RowCenterStack gap={1}>
            <TableListIcon />
            List View
          </RowCenterStack>
        }
      />
      <FilterTab
        value={TaskViewMode.Board}
        label={
          <RowCenterStack gap={1}>
            <TableColumnsIcon />
            Board
          </RowCenterStack>
        }
      />
      <FilterTab
        disabled
        value={TaskViewMode.Timeline}
        label={
          <RowCenterStack gap={1}>
            <TimelineIcon disabled />
            Timeline
          </RowCenterStack>
        }
      />
      <FilterTab
        disabled
        value={TaskViewMode.Calendar}
        label={
          <RowCenterStack gap={1}>
            <CalendarDaysIcon disabled />
            Calendar
          </RowCenterStack>
        }
      />
      <FilterTab value={TaskViewMode.SingleTask} label={'Single Task View'} />
    </Tabs>
  );
};
