import { Stack, CardMedia, CardContent } from '@mui/material';
import { FileType } from '@thrivea/organization-client';
import { ImageTypeFileIcon, VideoTypeFileIcon, DocTypeFileIcon, StyledFixedWidthTypography, FolderIcon } from 'src/shared';

interface FileTypeMediaProps {
  fileType: FileType;
  width: number;
  height: number;
}

export const FileTypeMedia: React.FC<FileTypeMediaProps> = ({ fileType, width, height }) => {
  return (
    <Stack
      sx={{
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <CardMedia
        sx={{
          padding: 0,
          marginBottom: '-10px'
        }}
      >
        {fileType === FileType.FOLDER && <FolderIcon width={width} height={height} />}
        {fileType === FileType.PHOTO && <ImageTypeFileIcon width={width} height={height} />}
        {fileType === FileType.AUDIO && <VideoTypeFileIcon width={width} height={height} />}
        {(fileType === FileType.PDF || fileType === FileType.PRESENTATION || fileType === FileType.SPREADSHEET || fileType === FileType.DOCUMENT) && (
          <DocTypeFileIcon width={width} height={height} />
        )}
      </CardMedia>
      <CardContent
        sx={{
          padding: 0,
          '&:last-child': {
            padding: 0
          }
        }}
      >
        <Stack
          sx={{
            width: 106,
            height: 27,
            padding: 0,
            borderRadius: '4px',
            border: '3px solid',
            borderColor: (theme) => theme.palette.common.black,
            backgroundColor: (theme) => theme.palette.common.white,
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <StyledFixedWidthTypography sx={{ textTransform: 'uppercase', fontWeight: 700, fontSize: 18, maxWidth: 350 }}>
            {FileType[fileType]}
          </StyledFixedWidthTypography>
        </Stack>
      </CardContent>
    </Stack>
  );
};
