import { styled } from '@mui/material';
import Grid, { Grid2Props } from '@mui/material/Grid2';

export const AsideGridItem = styled(Grid)<Grid2Props>(({ theme }) => ({
  width: 0,
  height: '100vh',
  position: 'fixed',
  top: 0,
  bottom: 0,
  zIndex: theme.zIndex.drawer + 1,
  [theme.breakpoints.up('md')]: {
    width: 106
  }
}));
