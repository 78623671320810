import { CloseIcon, RowCenterEndStack, StyledBlurryModal, StyledTransparentIconButton, StyledViewModal } from '@/shared';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import '@cyntler/react-doc-viewer/dist/index.css';

interface PostDocumentViewModalProps {
  fileUrl: string;
  isModalOpen: boolean;
  onClose: () => void;
}

export const PostDocumentViewModal = ({ fileUrl, isModalOpen, onClose }: PostDocumentViewModalProps): JSX.Element => (
  <StyledBlurryModal open={isModalOpen} onClose={onClose}>
    <StyledViewModal>
      <RowCenterEndStack>
        <StyledTransparentIconButton onClick={() => onClose()}>
          <CloseIcon customColor="#000000" />
        </StyledTransparentIconButton>
      </RowCenterEndStack>
      <DocViewer
        documents={[{ uri: fileUrl }]}
        pluginRenderers={DocViewerRenderers}
        config={{
          header: {
            disableFileName: true,
            disableHeader: true
          },
          pdfZoom: {
            defaultZoom: 1.2,
            zoomJump: 0.2
          }
        }}
        theme={{
          primary: '#F4F2ED'
        }}
      />
    </StyledViewModal>
  </StyledBlurryModal>
);
