import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { CreateNewTenantRequest, createNewTenantFailed, createNewTenantRequested, createNewTenantSucceeded } from '@features/welcome';
import { PayloadAction } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { CreateOrganizationRequest } from '@thrivea/organization-client';
import { CreateTenantRequest } from '@thrivea/admin-onboarding-client';
import { createOrganization } from '@api/organization.api';
import { createTenant } from '@api/admin.api';

function* createNewTenantRequestedGenerator(action: PayloadAction<CreateNewTenantRequest>) {
  const { organizationId, organizationName, firstName, lastName, emailAddress } = action.payload;

  try {
    yield all([
      call(
        createOrganization,
        new CreateOrganizationRequest({
          organizationId,
          organizationName,
          firstName,
          lastName,
          emailAddress
        })
      ),
      call(createTenant, new CreateTenantRequest({ organizationId, organizationName }))
    ]);

    yield put(createNewTenantSucceeded());
  } catch (error) {
    Sentry.captureException(error);
    yield put(createNewTenantFailed());
  }
}

function* createNewTenantRequestedWatcher() {
  yield takeLatest(createNewTenantRequested.type, createNewTenantRequestedGenerator);
}

export function* welcomeSagas() {
  yield all([fork(createNewTenantRequestedWatcher)]);
}
