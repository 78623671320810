import { Button, buttonClasses, ButtonProps, styled } from '@mui/material';

export const AssetsFileActionButton = styled(Button)<ButtonProps>(({ theme }) => ({
  [`&.${buttonClasses.root}`]: {
    minWidth: 'auto',
    width: 45,
    height: 45,
    backgroundColor: theme.palette.customTheme.drawerBackground,
    padding: '10px',
    borderRadius: '8px'
  }
}));
