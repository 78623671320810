import { styled } from '@mui/material/styles';
import { Box, paperClasses } from '@mui/material';
import { autocompleteClasses } from '@mui/material/Autocomplete';

export const PopperBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'anchorEl'
})<{ open: boolean; anchorEl?: HTMLElement | null }>(({ open, anchorEl }) => ({
  display: open ? 'block' : 'none',
  padding: '7px 11px',
  width: anchorEl ? (anchorEl as HTMLElement).clientWidth : '100%',
  [`&.${autocompleteClasses.popper}`]: {
    position: 'static',
    boxShadow: 'none'
  },
  [`& .${paperClasses.root}`]: {
    boxShadow: 'none'
  }
}));
