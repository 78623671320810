import { Stack, StackProps, styled } from '@mui/material';

export const SmModalContent = styled(Stack)<StackProps>(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: theme.palette.customTheme.contBgr,
  padding: '16px',
  borderRadius: '12px',
  boxShadow: 'none',
  width: 368
}));
