import React from 'react';
import { AvatarGroup, Divider, Stack, Typography } from '@mui/material';
import { ListTaskTitle, Task, TaskActionMenu, TaskAvatar, TaskCommentList, TaskIconButton, TaskReactions, TaskStatus, TaskStatusChip } from '@features/tasks';
import { CheckDoubleIcon, ClockIcon, PenToSquareIcon, RowCenterStack, ShuffleIcon, StampIcon, UserPenIcon } from 'src/shared';
import { TasksPrimaryButton } from '@/features/drawer';
import { useTranslation } from 'react-i18next';
import { Dictionary, startCase } from 'lodash';
import { Reaction } from '@thrivea/networking-client';
import { AddComment, CommentList, EmojiPicker, selectPostReadSasToken } from '@/features/homepage';
import { useAppSelector } from 'src/app/hooks';
import { selectCurrentEmployeeId } from 'src/app/user';
import { TasksAddComment } from './TasksAddComment';
import { TasksSingleTaskMedia } from './TasksSingleTaskMedia';

interface Emoji {
  id: number;
  native: string;
}
interface TasksSingleTaskProps {
  task: Task;
}

const reactions = {
  '👋': [
    {
      emoji: '👋',
      authorId: '0192ae7c-7dc0-43e2-8c39-ffaa5233c675',
      reactionTime: '2024-10-29T18:52:19.756968700Z'
    }
  ],
  '🤣': [
    {
      emoji: '🤣',
      authorId: '0192ae7c-7dc0-43e2-8c39-ffaa5233c675',
      reactionTime: '2024-10-29T18:52:19.756968700Z'
    },
    {
      emoji: '🤣',
      authorId: '0192ae7c-7dc0-43e2-8c39-ffaa5233c675',
      reactionTime: '2024-11-29T18:52:19.756968700Z'
    }
  ]
} as unknown as Dictionary<Reaction[]>;

const tempMedias = [
  'https://stthriveadev001.blob.core.windows.net/data/d6147406-6ecd-40c4-8a75-c4c660e18be3/posts/0192ae7c-7dc0-43e2-8c39-ffaa5233c675/temp-8f536ec1-d5fb-4f2e-ac5e-3f6646ee9425/d044ccc6-4761-4116-a53d-6dc26fb4b593.jpeg',
  'https://stthriveadev001.blob.core.windows.net/data/d6147406-6ecd-40c4-8a75-c4c660e18be3/posts/0192ae7c-7dc0-43e2-8c39-ffaa5233c675/temp-8d3cf2e3-4e2d-4795-b911-b581016cb8b5/4b0a4cad-1475-4dd8-9f04-187a17d3b280.png'
];

const tempDocs = [
  'https://stthriveadev001.blob.core.windows.net/data/d6147406-6ecd-40c4-8a75-c4c660e18be3/posts/0192ae7c-7dc0-43e2-8c39-ffaa5233c675/temp-43720cf8-b1ea-4220-bc14-401a2db4a9a2/85a880dc-0fc2-4471-93c9-e5a52936751e.pdf',
  'https://stthriveadev001.blob.core.windows.net/data/d6147406-6ecd-40c4-8a75-c4c660e18be3/posts/0192ae7c-7dc0-43e2-8c39-ffaa5233c675/temp-43720cf8-b1ea-4220-bc14-401a2db4a9a2/85a880dc-0fc2-4471-93c9-e5a52936751e.pdf',
  'https://stthriveadev001.blob.core.windows.net/data/d6147406-6ecd-40c4-8a75-c4c660e18be3/posts/0192ae7c-7dc0-43e2-8c39-ffaa5233c675/temp-43720cf8-b1ea-4220-bc14-401a2db4a9a2/85a880dc-0fc2-4471-93c9-e5a52936751e.pdf',
  'https://stthriveadev001.blob.core.windows.net/data/d6147406-6ecd-40c4-8a75-c4c660e18be3/posts/0192ae7c-7dc0-43e2-8c39-ffaa5233c675/temp-43720cf8-b1ea-4220-bc14-401a2db4a9a2/85a880dc-0fc2-4471-93c9-e5a52936751e.pdf',
  'https://stthriveadev001.blob.core.windows.net/data/d6147406-6ecd-40c4-8a75-c4c660e18be3/posts/0192ae7c-7dc0-43e2-8c39-ffaa5233c675/temp-43720cf8-b1ea-4220-bc14-401a2db4a9a2/85a880dc-0fc2-4471-93c9-e5a52936751e.pdf'
];

export const TasksSingleTask: React.FC<TasksSingleTaskProps> = ({ task }) => {
  const { t } = useTranslation(['common, tasks']);

  const currentUserId = useAppSelector(selectCurrentEmployeeId);
  const postReadSasToken = useAppSelector(selectPostReadSasToken);

  const handleEmojiSelect = (emoji: Emoji, event?: any) => {};

  return (
    <Stack
      gap={'16px'}
      sx={{
        padding: 2,
        borderRadius: 2,
        backgroundColor: (theme) => theme.palette.grey[600],
        maxWidth: 1200
      }}
    >
      <RowCenterStack sx={{ justifyContent: 'space-between' }}>
        <RowCenterStack gap={2}>
          <Typography>{task.id}</Typography>
          <TaskStatusChip status={TaskStatus.Assigned} label={startCase(TaskStatus[task.status])} />
        </RowCenterStack>
        <TaskActionMenu taskId={task.id} />
      </RowCenterStack>
      <Stack
        sx={{
          flexDirection: 'row',
          alignItems: 'flex-start',
          justifyContent: 'space-between'
        }}
      >
        <ListTaskTitle>{task.title}</ListTaskTitle>
        <Stack gap={2} sx={{ alignItems: 'flex-end' }}>
          <TasksPrimaryButton startIcon={<CheckDoubleIcon width={16} height={16} color={'#FFFFFF'} />}>
            {t('mark_task_complete', { ns: 'tasks' })}
          </TasksPrimaryButton>
          <TasksPrimaryButton startIcon={<PenToSquareIcon width={16} height={16} color={'#FFFFFF'} />}>
            {t('task_not_completed', { ns: 'tasks' })}
          </TasksPrimaryButton>
        </Stack>
      </Stack>
      <RowCenterStack gap={'4px'}>
        <RowCenterStack gap={'4px'}>
          <ClockIcon />
          <Typography sx={{ fontSize: 14 }}>{t('due_date_label', { ns: 'tasks' })} | </Typography>
        </RowCenterStack>
        <Typography sx={{ fontSize: 14, fontWeight: 700 }}>{task.dueDate}</Typography>
      </RowCenterStack>
      <RowCenterStack gap={'4px'}>
        <RowCenterStack gap={'4px'}>
          <ClockIcon />
          <Typography sx={{ fontSize: 14 }}>{t('due_date_created', { ns: 'tasks' })} | </Typography>
        </RowCenterStack>
        <Typography sx={{ fontSize: 14, fontWeight: 700 }}>{task.dueDate}</Typography>
      </RowCenterStack>
      <RowCenterStack gap={'4px'}>
        <RowCenterStack gap={'4px'}>
          <ClockIcon />
          <Typography sx={{ fontSize: 14 }}>{t('due_date_modified', { ns: 'tasks' })} | </Typography>
        </RowCenterStack>
        <Typography sx={{ fontSize: 14, fontWeight: 700 }}>{task.dueDate}</Typography>
      </RowCenterStack>
      <RowCenterStack gap={'8px'}>
        <Typography sx={{ fontSize: 14 }}>{t('task_owner_label', { ns: 'tasks' })} </Typography>
        <TaskAvatar position={1} alt="Remy Sharp" src={'https://thispersondoesnotexist.com/'} />
        <TaskIconButton>
          <ShuffleIcon color="#251D3873" />
        </TaskIconButton>
      </RowCenterStack>
      <RowCenterStack gap={'8px'}>
        <Typography sx={{ fontSize: 14 }}>{t('task_assignees_title', { ns: 'tasks' })} </Typography>
        <AvatarGroup
          max={3}
          variant="rounded"
          spacing={-(-17)}
          sx={{
            '.MuiAvatar-root': {
              border: (theme) => `1px solid ${theme.palette.common.black}`,
              width: 34,
              height: 34
            },
            '.MuiAvatarGroup-avatar': {
              position: 'relative',
              ':first-of-type': {
                zIndex: (theme) => theme.zIndex.tooltip
              }
            }
          }}
          slotProps={{}}
        >
          <TaskAvatar position={1} alt="Remy Sharp" src={'https://thispersondoesnotexist.com/'} />
          <TaskAvatar position={2} alt="Travis Howard" src={'https://thispersondoesnotexist.com/'} />
          <TaskAvatar position={3} alt="Cindy Baker" src={'https://thispersondoesnotexist.com/'} />
          <TaskAvatar position={4} alt="Agnes Walker" src={'https://thispersondoesnotexist.com/'} />
          <TaskAvatar position={5} alt="Trevor Henderson" src={'https://thispersondoesnotexist.com/'} />
        </AvatarGroup>
        <TaskIconButton>
          <UserPenIcon color="#251D3873" />
        </TaskIconButton>
      </RowCenterStack>
      <RowCenterStack gap={'8px'}>
        <Typography sx={{ fontSize: 14 }}>{t('reviewers_title', { ns: 'tasks' })} </Typography>
        <AvatarGroup
          max={3}
          variant="rounded"
          spacing={-(-17)}
          sx={{
            '.MuiAvatar-root': {
              border: (theme) => `1px solid ${theme.palette.common.black}`,
              width: 34,
              height: 34
            },
            '.MuiAvatarGroup-avatar': {
              position: 'relative',
              ':first-of-type': {
                zIndex: (theme) => theme.zIndex.tooltip
              }
            }
          }}
          slotProps={{}}
        >
          <TaskAvatar position={1} alt="Remy Sharp" src={'https://thispersondoesnotexist.com/'} />
          <TaskAvatar position={2} alt="Travis Howard" src={'https://thispersondoesnotexist.com/'} />
          <TaskAvatar position={3} alt="Cindy Baker" src={'https://thispersondoesnotexist.com/'} />
          <TaskAvatar position={4} alt="Agnes Walker" src={'https://thispersondoesnotexist.com/'} />
          <TaskAvatar position={5} alt="Trevor Henderson" src={'https://thispersondoesnotexist.com/'} />
        </AvatarGroup>
        <TaskIconButton>
          <StampIcon color="#251D3873" />
        </TaskIconButton>
      </RowCenterStack>
      <Typography
        sx={{
          whiteSpace: 'pre-wrap'
        }}
      >
        {task.description}
      </Typography>
      <TasksSingleTaskMedia
        docs={tempDocs.map((du) => ({
          alt: 'Document',
          src: new URL(postReadSasToken, du).toString(),
          name: du
        }))}
        medias={tempMedias.map((mu) => ({
          alt: 'Media',
          src: new URL(postReadSasToken, mu).toString(),
          name: mu
        }))}
      />
      <RowCenterStack
        gap={1}
        sx={{
          width: '100%',
          padding: 0
        }}
      >
        <TaskReactions reactionsGroupedByEmoji={reactions} />
        <EmojiPicker onEmojiSelect={handleEmojiSelect} />
      </RowCenterStack>
      <Divider />
      <TaskCommentList postId="0192d99f-18aa-4bad-beae-ea1ed1ce86ce" />
      <TasksAddComment postId="0192d99f-18aa-4bad-beae-ea1ed1ce86ce" authorId={currentUserId} />
    </Stack>
  );
};
