import { createGrpcWebTransport } from '@connectrpc/connect-web';
import { createClient } from '@connectrpc/connect';
import {
  PostService,
  PublishShoutoutRequest,
  RetrievePostsRequest,
  RetrievePostsResponse,
  CommentOnPostRequest,
  Comment,
  DeleteCommentRequest,
  Post,
  ReactToPostRequest,
  Reaction,
  ReactToCommentRequest,
  WithdrawPostReactionRequest,
  WithdrawCommentReactionRequest,
  DeletePostRequest,
  RetrieveRecentPostsByAuthorRequest,
  RetrieveRecentPostsByAuthorResponse,
  SaveDraftPostRequest,
  DeleteDraftPostsRequest,
  RetrieveLastDraftPostRequest,
  DraftPost,
  EditShoutoutRequest,
  EditCommentRequest,
  ReadPostRequest,
  RetrieveArePostsReadRequest,
  RetrieveArePostsReadResponse,
  RetrievePostReadAnalyticsRequest,
  RetrievePostReadAnalyticsResponse,
  RetrievePostsByIdsRequest
} from '@thrivea/networking-client';
import { Empty } from '@bufbuild/protobuf/wkt';
import { tokenRequest } from '@utils/authConfig';
import { msalInstance } from '@/main';

const postClient = createClient(
  PostService,
  createGrpcWebTransport({
    baseUrl: import.meta.env.VITE_APP_API_URL,
    useBinaryFormat: true
  })
);

export const retrievePosts = async (request: RetrievePostsRequest): Promise<RetrievePostsResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await postClient.retrievePosts(request, { headers: new Headers({ Authorization: `Bearer ${account.accessToken}` }) });
};

export const retrievePostsByIds = async (request: RetrievePostsByIdsRequest): Promise<RetrievePostsResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await postClient.retrievePostsByIds(request, { headers: new Headers({ Authorization: `Bearer ${account.accessToken}` }) });
};

export const deletePost = async (request: DeletePostRequest, signalRConnectionId: string): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await postClient.deletePost(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}`, 'x-signalr-connection': signalRConnectionId })
  });
};

export const publishShoutout = async (request: PublishShoutoutRequest, signalRConnectionId: string): Promise<Post> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await postClient.publishShoutout(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}`, 'x-signalr-connection': signalRConnectionId })
  });
};

export const commentOnPost = async (request: CommentOnPostRequest, signalRConnectionId: string): Promise<Comment> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await postClient.commentOnPost(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}`, 'x-signalr-connection': signalRConnectionId })
  });
};

export const deleteComment = async (request: DeleteCommentRequest, signalRConnectionId: string): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await postClient.deleteComment(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}`, 'x-signalr-connection': signalRConnectionId })
  });
};

export const reactToPost = async (request: ReactToPostRequest, signalRConnectionId: string): Promise<Reaction> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await postClient.reactToPost(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}`, 'x-signalr-connection': signalRConnectionId })
  });
};

export const withdrawPostReaction = async (request: WithdrawPostReactionRequest, signalRConnectionId: string): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await postClient.withdrawPostReaction(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}`, 'x-signalr-connection': signalRConnectionId })
  });
};

export const reactToComment = async (request: ReactToCommentRequest, signalRConnectionId: string): Promise<Reaction> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await postClient.reactToComment(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}`, 'x-signalr-connection': signalRConnectionId })
  });
};

export const withdrawCommentReaction = async (request: WithdrawCommentReactionRequest, signalRConnectionId: string): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await postClient.withdrawCommentReaction(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}`, 'x-signalr-connection': signalRConnectionId })
  });
};

export const retrieveRecentPostsByAuthor = async (request: RetrieveRecentPostsByAuthorRequest): Promise<RetrieveRecentPostsByAuthorResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await postClient.retrieveRecentPostsByAuthor(request, { headers: new Headers({ Authorization: `Bearer ${account.accessToken}` }) });
};

export const saveDraftPost = async (request: SaveDraftPostRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await postClient.saveDraftPost(request, { headers: new Headers({ Authorization: `Bearer ${account.accessToken}` }) });
};

export const retrieveLastDraftPost = async (request: RetrieveLastDraftPostRequest): Promise<DraftPost> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await postClient.retrieveLastDraftPost(request, { headers: new Headers({ Authorization: `Bearer ${account.accessToken}` }) });
};

export const deleteDraftPosts = async (request: DeleteDraftPostsRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await postClient.deleteDraftPosts(request, { headers: new Headers({ Authorization: `Bearer ${account.accessToken}` }) });
};

export const editShoutout = async (request: EditShoutoutRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await postClient.editShoutout(request, { headers: new Headers({ Authorization: `Bearer ${account.accessToken}` }) });
};

export const editComment = async (request: EditCommentRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await postClient.editComment(request, { headers: new Headers({ Authorization: `Bearer ${account.accessToken}` }) });
};

export const readPost = async (request: ReadPostRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await postClient.readPost(request, { headers: new Headers({ Authorization: `Bearer ${account.accessToken}` }) });
};

export const retrieveArePostsRead = async (request: RetrieveArePostsReadRequest): Promise<RetrieveArePostsReadResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await postClient.retrieveArePostsRead(request, { headers: new Headers({ Authorization: `Bearer ${account.accessToken}` }) });
};

export const retrievePostReadAnalytics = async (request: RetrievePostReadAnalyticsRequest): Promise<RetrievePostReadAnalyticsResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await postClient.retrievePostReadAnalytics(request, { headers: new Headers({ Authorization: `Bearer ${account.accessToken}` }) });
};
