import React, { useState } from 'react';
import { Box, IconButton, Menu, MenuItem, Theme } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useTranslation } from 'react-i18next';

interface TaskActionMenuProps {
  taskId: string;
}

export const TaskActionMenu: React.FC<TaskActionMenuProps> = ({ taskId }) => {
  const { t } = useTranslation(['common, tasks']);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <IconButton
        aria-label="more"
        id={`menu-${taskId}`}
        aria-controls={open ? `menu-${taskId}` : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleMenuOpen}
        sx={{
          padding: '0',
          height: 20,
          zIndex: open ? (theme) => theme.zIndex.tooltip : 0
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={`anchorMenu-${taskId}`}
        MenuListProps={{
          'aria-labelledby': `anchorMenu-${taskId}`
        }}
        disablePortal
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        open={open}
        onClose={handleMenuClose}
        slotProps={{
          paper: {
            elevation: 0,
            style: {
              width: '20ch',
              marginTop: '-30px',
              paddingTop: '30px',
              maxHeight: 350,
              border: '1px solid',
              borderColor: '#000',
              borderRadius: 4,
              zIndex: 999
            }
          }
        }}
      >
        <MenuItem onClick={handleMenuClose}>{t('edit_task', { ns: 'tasks' })}</MenuItem>
        <MenuItem onClick={handleMenuClose}>{t('save_task', { ns: 'tasks' })}</MenuItem>
        <MenuItem onClick={handleMenuClose}>{t('duplicate_task', { ns: 'tasks' })}</MenuItem>
        <MenuItem onClick={handleMenuClose}>{t('archive_task', { ns: 'tasks' })}</MenuItem>
        <MenuItem onClick={handleMenuClose}>{t('delete_task', { ns: 'tasks' })}</MenuItem>
        <MenuItem onClick={handleMenuClose}>{t('add_follow_up_task', { ns: 'tasks' })}</MenuItem>
        <MenuItem onClick={handleMenuClose}>{t('add_dependency', { ns: 'tasks' })}</MenuItem>
      </Menu>
    </Box>
  );
};
