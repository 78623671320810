import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
  createErbScalarField,
  deleteErTemplateSection,
  retrieveDefaultErTemplateSection,
  retrieveErbFieldCategoriesAndSections,
  retrieveErbFieldsByCategoryId,
  retrieveErbFieldTypes,
  retrieveErTemplate,
  saveErTemplateSection
} from '@api/erb.api';
import {
  createErbScalarFieldFailed,
  createErbScalarFieldRequested,
  createErbScalarFieldSucceeded,
  deleteErbTemplateSectionFailed,
  deleteErbTemplateSectionRequested,
  deleteErbTemplateSectionSucceeded,
  retrieveDefaultErTemplateSectionFailed,
  retrieveDefaultErTemplateSectionRequested,
  retrieveDefaultErTemplateSectionSucceeded,
  retrieveErbFieldCategoriesAndSectionsFailed,
  retrieveErbFieldCategoriesAndSectionsRequested,
  retrieveErbFieldCategoriesAndSectionsSucceeded,
  retrieveErbFieldsByCategoryIdFailed,
  retrieveErbFieldsByCategoryIdRequested,
  retrieveErbFieldsByCategoryIdSucceeded,
  retrieveErbFieldTypesFailed,
  retrieveErbFieldTypesRequested,
  retrieveErbFieldTypesSucceeded,
  retrieveErTemplateFailed,
  retrieveErTemplateRequested,
  retrieveErTemplateSucceeded,
  saveErTemplateSectionFailed,
  saveErTemplateSectionRequested,
  saveErTemplateSectionSucceeded,
  setActiveSectionId
} from '@features/employee-record-builder';
import { PayloadAction } from '@reduxjs/toolkit';
import { Empty } from '@bufbuild/protobuf';
import {
  CreateErbScalarFieldRequest,
  DeleteErTemplateSectionRequest,
  ErbFieldCategories,
  RetrieveDefaultErTemplateSectionRequest,
  RetrieveErbFieldsByCategoryIdRequest,
  RetrieveErbFieldTypesResponse,
  RetrieveErTemplateRequest,
  SaveErTemplateSectionRequest
} from '@thrivea/organization-client';
import * as Sentry from '@sentry/react';
import { resetDrawer } from '../drawer';
import { showSuccess } from '../snackbar';
import { t } from 'i18next';

function* retrieveErbFieldCategoriesAndSectionsRequestedGenerator() {
  try {
    const response: ErbFieldCategories = yield call(retrieveErbFieldCategoriesAndSections, new Empty());
    yield put(retrieveErbFieldCategoriesAndSectionsSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveErbFieldCategoriesAndSectionsFailed());
  }
}

function* retrieveDefaultErTemplateSectionRequestedGenerator(action: PayloadAction<RetrieveDefaultErTemplateSectionRequest>) {
  try {
    const response = yield call(retrieveDefaultErTemplateSection, action.payload);
    yield put(retrieveDefaultErTemplateSectionSucceeded(response));
    yield put(setActiveSectionId(response.id));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveDefaultErTemplateSectionFailed());
  }
}

function* saveErTemplateSectionRequestedGenerator(action: PayloadAction<SaveErTemplateSectionRequest>) {
  try {
    const response = yield call(saveErTemplateSection, action.payload);
    yield put(saveErTemplateSectionSucceeded(response));
    yield put(resetDrawer());
    yield put(showSuccess(t('successfully_saved', { ns: 'common' })));
  } catch (error) {
    Sentry.captureException(error);
    yield put(saveErTemplateSectionFailed());
  }
}

function* retrieveErTemplateRequestedGenerator(action: PayloadAction<RetrieveErTemplateRequest>) {
  try {
    const response = yield call(retrieveErTemplate, action.payload);
    yield put(retrieveErTemplateSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveErTemplateFailed());
  }
}

function* retrieveErbFieldsByCategoryIdRequestedGenerator(action: PayloadAction<{ request: RetrieveErbFieldsByCategoryIdRequest; sectionId?: string }>) {
  try {
    const response = yield call(retrieveErbFieldsByCategoryId, action.payload.request);
    yield put(retrieveErbFieldsByCategoryIdSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveErbFieldsByCategoryIdFailed());
  }
}

function* retrieveErbFieldTypesRequestedGenerator(action: PayloadAction<Empty>) {
  try {
    const response: RetrieveErbFieldTypesResponse = yield call(retrieveErbFieldTypes, action.payload);
    yield put(retrieveErbFieldTypesSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveErbFieldTypesFailed());
  }
}

function* deleteErbTemplateSectionRequestedGenerator(action: PayloadAction<DeleteErTemplateSectionRequest>) {
  try {
    const response = yield call(deleteErTemplateSection, action.payload);
    yield put(deleteErbTemplateSectionSucceeded(action.payload.erTemplateSectionId));
    yield put(showSuccess(t('successfully_deleted_section', { ns: 'common' })));
  } catch (error) {
    Sentry.captureException(error);
    yield put(deleteErbTemplateSectionFailed());
  }
}

function* createErbScalarFieldRequestedGenerator(action: PayloadAction<CreateErbScalarFieldRequest>) {
  try {
    yield call(createErbScalarField, action.payload);
    yield put(createErbScalarFieldSucceeded());
    // yield put(showSuccess(t('successfully_deleted_section', { ns: 'common' })));
  } catch (error) {
    Sentry.captureException(error);
    yield put(createErbScalarFieldFailed());
  }
}

function* retrieveErbFieldCategoriesAndSectionsRequestedWatcher() {
  yield takeLatest(retrieveErbFieldCategoriesAndSectionsRequested.type, retrieveErbFieldCategoriesAndSectionsRequestedGenerator);
}

function* retrieveDefaultErTemplateSectionRequestedWatcher() {
  yield takeLatest(retrieveDefaultErTemplateSectionRequested.type, retrieveDefaultErTemplateSectionRequestedGenerator);
}

function* saveErTemplateSectionRequestedWatcher() {
  yield takeLatest(saveErTemplateSectionRequested.type, saveErTemplateSectionRequestedGenerator);
}

function* retrieveErTemplateRequestedWatcher() {
  yield takeLatest(retrieveErTemplateRequested.type, retrieveErTemplateRequestedGenerator);
}

function* retrieveErbFieldsByCategoryIdRequestedWatcher() {
  yield takeLatest(retrieveErbFieldsByCategoryIdRequested.type, retrieveErbFieldsByCategoryIdRequestedGenerator);
}

function* deleteErbTemplateSectionRequestedWatcher() {
  yield takeLatest(deleteErbTemplateSectionRequested.type, deleteErbTemplateSectionRequestedGenerator);
}

function* retrieveErbFieldTypesRequestedWatcher() {
  yield takeLatest(retrieveErbFieldTypesRequested.type, retrieveErbFieldTypesRequestedGenerator);
}

function* createErbScalarFieldRequestedWatcher() {
  yield takeLatest(createErbScalarFieldRequested.type, createErbScalarFieldRequestedGenerator);
}

export function* employeeRecordBuilderSagas() {
  yield all([
    fork(retrieveErbFieldCategoriesAndSectionsRequestedWatcher),
    fork(retrieveDefaultErTemplateSectionRequestedWatcher),
    fork(saveErTemplateSectionRequestedWatcher),
    fork(retrieveErTemplateRequestedWatcher),
    fork(retrieveErbFieldsByCategoryIdRequestedWatcher),
    fork(deleteErbTemplateSectionRequestedWatcher),
    fork(retrieveErbFieldTypesRequestedWatcher),
    fork(createErbScalarFieldRequestedWatcher)
  ]);
}
