import { Paper, PaperProps, styled } from "@mui/material";

interface AudienceGroupPaperProps extends PaperProps {
    transparent?: boolean;
}

export const AudienceGroupPaper = styled(Paper, {
    shouldForwardProp: (prop) => prop !== 'transparent'
})<AudienceGroupPaperProps>(({ theme, transparent }) => ({
    width: '100%',
    borderRadius: 2,
    backgroundColor: transparent ? 'transparent' : theme.palette.customTheme.drawerBackground,
    border: 0,
    padding: 2
}))