import { createClient } from '@connectrpc/connect';
import { createGrpcWebTransport } from '@connectrpc/connect-web';
import { EmployeeRecord, EmployeeService, RetrieveEmployeeRecordRequest, UpdateErSectionRequest } from '@thrivea/organization-client';
import { msalInstance } from '@/main';
import { tokenRequest } from '@utils/authConfig';
import { Empty } from '@bufbuild/protobuf/wkt';

const employeeRecordBuilderClient = createClient(
  EmployeeService,
  createGrpcWebTransport({
    baseUrl: import.meta.env.VITE_APP_API_URL,
    useBinaryFormat: true
  })
);

export const retrieveEmployeeRecord = async (request: RetrieveEmployeeRecordRequest): Promise<EmployeeRecord> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await employeeRecordBuilderClient.retrieveEmployeeRecord(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const updateErSection = async (request: UpdateErSectionRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await employeeRecordBuilderClient.updateErSection(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};
