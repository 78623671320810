import { create } from '@bufbuild/protobuf';
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import {
  deleteAllNotificationsFailed,
  deleteAllNotificationsRequested,
  deleteAllNotificationsSucceeded,
  deleteNotificationByIdFailed,
  deleteNotificationByIdRequested,
  deleteNotificationByIdSucceeded,
  hasUnreadNotificationsFailed,
  hasUnreadNotificationsRequested,
  hasUnreadNotificationsSucceeded,
  markAllNotificationsAsReadFailed,
  markAllNotificationsAsReadRequested,
  markAllNotificationsAsReadSucceeded,
  markNotificationAsReadFailed,
  markNotificationAsReadRequested,
  markNotificationAsReadSucceeded,
  retrieveLastNotificationDateTimeFailed,
  retrieveLastNotificationDateTimeRequested,
  retrieveLastNotificationDateTimeSucceeded,
  retrieveNewNotificationsFailed,
  retrieveNewNotificationsRequested,
  retrieveNewNotificationsSucceeded,
  retrieveNotificationsFailed,
  retrieveNotificationsRequested,
  retrieveNotificationsSucceeded
} from '@features/notifications';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  CheckNewNotificationRequest,
  CheckNewNotificationResponse,
  DeleteAllNotificationsRequest,
  DeleteNotificationByIdRequest,
  HasUnreadNotificationsRequest,
  HasUnreadNotificationsRequestSchema,
  HasUnreadNotificationsResponse,
  MarkAllAsReadRequest,
  MarkNotificationAsReadRequest,
  RetrieveNotificationsRequest,
  RetrieveNotificationsResponse
} from '@thrivea/notification-client';
import {
  checkNewNotification,
  deleteAllNotifications,
  deleteNotificationById,
  hasUnreadNotifications,
  markAllAsRead,
  markNotificationAsRead,
  retrieveNotifications
} from '@api/notification.api';
import * as Sentry from '@sentry/react';
import { selectCurrentEmployeeId } from '@features/shared';
import uniq from 'lodash/uniq';
import { retrieveNotificationPostsByIdsRequested } from '@features/homepage';
import { RetrievePostsByIdsRequestSchema } from '@thrivea/networking-client';

function* retrieveNotificationsRequestedGenerator(action: PayloadAction<RetrieveNotificationsRequest>) {
  try {
    const response: RetrieveNotificationsResponse = yield call(retrieveNotifications, action.payload);
    const postIds = uniq(response.notifications.map((n) => n.data!.content.value!.postId));

    yield put(retrieveNotificationsSucceeded(response));
    if (postIds.length > 0) {
      yield put(retrieveNotificationPostsByIdsRequested(create(RetrievePostsByIdsRequestSchema, { postIds })));
    }
  } catch (error) {
    yield put(retrieveNotificationsFailed());
    Sentry.captureException(error);
  }
}

function* retrieveNewNotificationsRequestedGenerator(action: PayloadAction<RetrieveNotificationsRequest>) {
  try {
    const response: RetrieveNotificationsResponse = yield call(retrieveNotifications, action.payload);
    const postIds = uniq(response.notifications.map((n) => n.data!.content.value!.postId));

    if (postIds.length > 0) {
      yield put(retrieveNotificationPostsByIdsRequested(create(RetrievePostsByIdsRequestSchema, { postIds })));
    }
    yield put(retrieveNewNotificationsSucceeded(response));
  } catch (error) {
    console.error(error);
    yield put(retrieveNewNotificationsFailed());
    Sentry.captureException(error);
  }
}

function* retrieveLastNotificationDateTimeRequestedGenerator(action: PayloadAction<CheckNewNotificationRequest>) {
  try {
    const response: CheckNewNotificationResponse = yield call(checkNewNotification, action.payload);
    yield put(retrieveLastNotificationDateTimeSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveLastNotificationDateTimeFailed());
  }
}

function* deleteNotificationByIdRequestedGenerator(action: PayloadAction<DeleteNotificationByIdRequest>) {
  try {
    yield call(deleteNotificationById, action.payload);
    yield put(deleteNotificationByIdSucceeded(action.payload));
  } catch (error) {
    Sentry.captureException(error);
    yield put(deleteNotificationByIdFailed());
  }
}

function* deleteAllNotificationsRequestedGenerator(action: PayloadAction<DeleteAllNotificationsRequest>) {
  try {
    yield call(deleteAllNotifications, action.payload);
    yield put(deleteAllNotificationsSucceeded());
  } catch (error) {
    Sentry.captureException(error);
    yield put(deleteAllNotificationsFailed());
  }
}

function* markNotificationAsReadRequestedGenerator(action: PayloadAction<MarkNotificationAsReadRequest>) {
  try {
    yield call(markNotificationAsRead, action.payload);
    yield put(markNotificationAsReadSucceeded(action.payload));
    const employeeId = yield select(selectCurrentEmployeeId);
    yield put(hasUnreadNotificationsRequested(create(HasUnreadNotificationsRequestSchema, { employeeId })));
  } catch (error) {
    Sentry.captureException(error);
    yield put(markNotificationAsReadFailed(action.payload));
  }
}

function* markAllNotificationsAsReadRequestedGenerator(action: PayloadAction<MarkAllAsReadRequest>) {
  try {
    yield call(markAllAsRead, action.payload);
    yield put(markAllNotificationsAsReadSucceeded());
  } catch (error) {
    Sentry.captureException(error);
    yield put(markAllNotificationsAsReadFailed());
  }
}

function* hasUnreadNotificationsRequestedGenerator(action: PayloadAction<HasUnreadNotificationsRequest>) {
  try {
    const response: HasUnreadNotificationsResponse = yield call(hasUnreadNotifications, action.payload);
    yield put(hasUnreadNotificationsSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(hasUnreadNotificationsFailed());
  }
}

function* retrieveNotificationsRequestedWatcher() {
  yield takeLatest(retrieveNotificationsRequested.type, retrieveNotificationsRequestedGenerator);
}

function* retrieveNewNotificationsRequestedWatcher() {
  yield takeLatest(retrieveNewNotificationsRequested.type, retrieveNewNotificationsRequestedGenerator);
}

function* retrieveLastNotificationDateTimeRequestedWatcher() {
  yield takeLatest(retrieveLastNotificationDateTimeRequested.type, retrieveLastNotificationDateTimeRequestedGenerator);
}

function* deleteNotificationByIdRequestedWatcher() {
  yield takeLatest(deleteNotificationByIdRequested.type, deleteNotificationByIdRequestedGenerator);
}

function* deleteAllNotificationsRequestedWatcher() {
  yield takeLatest(deleteAllNotificationsRequested.type, deleteAllNotificationsRequestedGenerator);
}

function* markNotificationAsReadRequestedWatcher() {
  yield takeLatest(markNotificationAsReadRequested.type, markNotificationAsReadRequestedGenerator);
}

function* markAllNotificationsAsReadRequestedWatcher() {
  yield takeLatest(markAllNotificationsAsReadRequested.type, markAllNotificationsAsReadRequestedGenerator);
}

function* hasUnreadNotificationsRequestedWatcher() {
  yield takeLatest(hasUnreadNotificationsRequested.type, hasUnreadNotificationsRequestedGenerator);
}

export function* notificationsSagas() {
  yield all([
    fork(retrieveLastNotificationDateTimeRequestedWatcher),
    fork(retrieveNotificationsRequestedWatcher),
    fork(retrieveNewNotificationsRequestedWatcher),
    fork(deleteNotificationByIdRequestedWatcher),
    fork(deleteAllNotificationsRequestedWatcher),
    fork(markAllNotificationsAsReadRequestedWatcher),
    fork(markNotificationAsReadRequestedWatcher),
    fork(hasUnreadNotificationsRequestedWatcher)
  ]);
}
