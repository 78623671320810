import { create } from '@bufbuild/protobuf';
import { Control, Controller, FieldValues } from 'react-hook-form';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { z } from 'zod';
import { ErTemplateScalarField, NumberOptions, NumberOptionsSchema, SeparatorsAndDecimals } from '@thrivea/organization-client';
import { StyledFilledInput } from '@/shared';
import { formatNumber } from '@/utils';
import { forwardRef } from 'react';

const NumberOptionsZodSchema = (options: NumberOptions) =>
  z.object({
    number: z
      .number()
      .refine((value) => options.allowNegative || value >= 0, {
        message: 'Negative values are not allowed.'
      })
      .transform((value) => {
        const precision = Math.pow(10, options.decimalPrecision);
        return Math.round(value * precision) / precision; // Round to the specified decimal precision
      })
      .or(z.literal(''))
  });

// Define the props for NumericFormatWrapper
type NumericFormatWrapperProps = NumericFormatProps<any>;

// Create a wrapper around NumericFormat to make it ref-compatible
const NumericFormatWrapper = forwardRef<HTMLInputElement, NumericFormatWrapperProps>((props, ref) => <NumericFormat {...props} getInputRef={ref} />);

NumericFormatWrapper.displayName = 'NumericFormatWrapper';

interface EmployeeRecordNumberFieldProps {
  control: Control<FieldValues, any>;
  fieldId: string;
  isEditable: boolean;
  templateField: ErTemplateScalarField;
}

export const EmployeeRecordNumberField = ({ control, fieldId, isEditable, templateField }: EmployeeRecordNumberFieldProps): JSX.Element => {
  const numberFieldOptions = templateField.erbScalarField!.type!.options!.kind.value as NumberOptions;
  const options = create(NumberOptionsSchema, {
    separatorsAndDecimals: numberFieldOptions.separatorsAndDecimals,
    decimalPrecision: numberFieldOptions.decimalPrecision,
    allowNegative: numberFieldOptions.allowNegative,
    numberShortener: numberFieldOptions.numberShortener
  });

  return (
    <Controller
      name={fieldId}
      control={control}
      rules={{
        validate: (value) => {
          const schema = NumberOptionsZodSchema(options);
          const result = schema.safeParse({ number: value });
          return result.success || result.error.errors.map((err) => err.message).join(', ');
        }
      }}
      render={({ field, fieldState }) => (
        <NumericFormatWrapper
          {...field}
          onValueChange={(values) => {
            // Extracting the numeric value for the form state
            field.onChange(values.floatValue); // Pass the numeric value to the form
          }}
          disabled={templateField.erbScalarField!.isCalculated || !isEditable}
          label={templateField.name}
          thousandSeparator={options.separatorsAndDecimals === SeparatorsAndDecimals.COMMA_PERIOD}
          decimalScale={options.decimalPrecision}
          fixedDecimalScale
          // this can potentially cause problems with validation because it returns a string like 100k or 100M
          value={formatNumber(field.value, options.separatorsAndDecimals, options.numberShortener)}
          error={!!fieldState.error}
          helperText={fieldState.error?.message}
          customInput={StyledFilledInput}
          fullWidth
        />
      )}
    />
  );
};
