import { styled, TableRow, TableRowProps } from '@mui/material';
import { TaskStatus } from '@features/tasks';

interface TasksListRowStyleProps {
  backgroundColor: string;
  textColor: string;
}

interface TasksListTaskRowProps extends TableRowProps {
  status: TaskStatus;
  isDivider?: boolean;
}

export const TasksListTaskRow = styled(TableRow, {
  shouldForwardProp: (prop) => prop !== 'status' && prop !== 'isDivider'
})<TasksListTaskRowProps>(({ status, isDivider, theme }) => {
  // Define an array for row styling based on TaskStatus
  const row: TasksListRowStyleProps[] = [
    { backgroundColor: theme.palette.grey[600], textColor: theme.palette.primary.main },
    { backgroundColor: '#F1E9F7', textColor: theme.palette.primary.main },
    { backgroundColor: 'rgba(252, 199, 93, 0.30)', textColor: theme.palette.primary.main },
    { backgroundColor: theme.palette.customTheme.selectLabel, textColor: theme.palette.primary.main }
  ];

  let idx = TaskStatus.Draft;
  if (status === TaskStatus.Assigned) idx = TaskStatus.Assigned;
  if (status === TaskStatus.InReview) idx = TaskStatus.InReview;
  if (status === TaskStatus.Completed) idx = TaskStatus.Completed;

  return {
    height: isDivider ? 8 : 'auto',
    backgroundColor: row[idx].backgroundColor,
    color: row[idx].textColor,
    border: 0,
    position: 'relative'
  };
});
