export const DEFAULT_ER_TEMPLATE_ID = '11183939-f57c-49fe-8a25-48c4a30f3a11';
export const EMPlOYEE_RECORD_CARD_SECTION_ID = '1188ec97-a6a0-42b4-8203-40ffe15d6911';

export const ERB_FIELD_EMAIL_ADDRESS_ID = '11183939-f57c-49fe-8a25-48c4a30f3a41';

export const ERB_FIELD_DISPLAY_NAME_ID = '11183939-f57c-49fe-8a25-48c4a30f3a15';
export const ERB_FIELD_WORK_HISTORY_ID = '11183939-f57c-49fe-8a25-48c4a30f3a3a';

export const WORK_HISTORY_EFFECTIVE_DATE_ID = '5a79d3e4-ba80-472d-a32a-2abd915eaf91';
export const WORK_HISTORY_JOB_TITLE_ID = '5a79d3e4-ba80-472d-a32a-2abd915eaf92';
