import { useState } from 'react';
import { Stack, Box, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import {
  AudienceChip,
  PostActions,
  PostAuthor,
  PostContent,
  PostDoc,
  PostMedia,
  PostMenu,
  PostSkeleton,
  selectAudienceWithNameByAudienceId,
  selectDeletePostId,
  selectDeletePostStatus,
  selectPostById
} from '@features/homepage';
import { ActionStatus, RowCenterStack } from '@/shared';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { DrawerType, openDrawer, OpenDrawerRequest } from '@features/drawer';
import { AllowedTo } from 'react-abac';
import { AudiencePermissions, PostInfo } from 'src/features/abac';

interface PostViewProps {
  postId: string;
  authorId: string;
  date: DateTime;
  files: PostMedia[];
  docs: PostDoc[];
  message: string;
  audienceId: string;
  editedTime?: DateTime;
  isImportant: boolean;
}

export const PostView = ({ postId, authorId, date, files, docs, message, audienceId, editedTime, isImportant }: PostViewProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const [editablePost, setEditablePost] = useState<string | null>(null);
  const audienceWithName = useAppSelector((state) => selectAudienceWithNameByAudienceId(state, audienceId));
  const deletePostStatus = useAppSelector(selectDeletePostStatus);
  const deletePostId = useAppSelector(selectDeletePostId);
  const post = useAppSelector((state) => selectPostById(state, postId));

  const handleSetPostEditable = (id: string) => {
    setEditablePost(editablePost === id ? null : id);
  };

  const handleRetrieveAudienceById = (audienceId: string) => {
    dispatch(openDrawer({ type: DrawerType.Audience, request: { audienceId } } as OpenDrawerRequest));
  };

  if (deletePostStatus === ActionStatus.Pending && deletePostId === postId) {
    return <PostSkeleton />;
  }

  return (
    <Stack
      component="article"
      sx={{
        backgroundColor: (theme) => (isImportant ? theme.palette.customTheme.drawerBackground : theme.palette.customTheme.contBgr),
        border: '1px solid',
        borderColor: (theme) => theme.palette.customTheme.borderMaster,
        borderRadius: 2,
        padding: 0,
        '&:hover': {
          '& .MuiButton-react': {
            opacity: 1
          }
        }
      }}
    >
      <RowCenterStack
        sx={{
          padding: '0 16px',
          my: 1,
          flexWrap: 'wrap',
          justifyContent: {
            xs: 'flex-end',
            md: 'space-between'
          }
        }}
      >
        <PostAuthor authorId={authorId} postId={postId} date={date} editedTime={editedTime} />
        <RowCenterStack gap={1}>
          <AudienceChip
            onClick={() => handleRetrieveAudienceById(audienceWithName.audienceId)}
            label={
              <RowCenterStack>
                <Typography component="span" variant="body2" sx={{ color: (theme) => theme.palette.customTheme.focusItem }}>
                  @
                </Typography>
                <Typography component="span" variant="body2">
                  {audienceWithName.audienceName}
                </Typography>
              </RowCenterStack>
            }
          />
          {editablePost !== postId && (
            <AllowedTo perform={AudiencePermissions.EDIT_POST} data={{ audienceId: post.audienceIds[0], authorId: post.authorId } as PostInfo} no={() => null}>
              <PostMenu postId={postId} handleSetPostEditable={handleSetPostEditable} />
            </AllowedTo>
          )}
        </RowCenterStack>
      </RowCenterStack>
      <Box
        sx={{
          padding: '0 16px'
        }}
      >
        <PostContent
          postId={postId}
          authorId={authorId}
          message={message}
          medias={files}
          docs={docs}
          isEditable={editablePost === postId}
          handleSetPostEditable={handleSetPostEditable}
        />
      </Box>
      {editablePost !== postId && <PostActions postId={postId} />}
    </Stack>
  );
};
