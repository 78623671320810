import { styled, Tab, tabClasses } from '@mui/material';

export const FilterTab = styled(Tab, {
  shouldForwardProp: (prop) => prop !== 'isEditable' && prop !== 'isActive'
})<{
  isEditable?: boolean;
  isActive?: boolean;
}>(({ isEditable, isActive, theme }) => ({
  backgroundColor: isActive && !isEditable ? theme.palette.customTheme.drawerBackground : '#F7F5F7',
  padding: '12px 8px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 43,
  borderRadius: 8,
  color: theme.palette.primary.main,
  opacity: 1,
  fontWeight: 700,
  '&:hover': {
    [`& .${tabClasses.icon}`]: {
      opacity: 1
    }
  },
  [`&.${tabClasses.selected}`]: {
    backgroundColor: theme.palette.customTheme.drawerBackground
  },
  [`& .${tabClasses.icon}`]: {
    opacity: 0
  }
}));
