import { Select, SelectProps, styled } from '@mui/material';
import { ChevronDownIcon } from 'src/shared';

const StyledMenuProps = {
  elevation: 0,
  sx: {
    '& .MuiList-root': {
      margin: '8px 0',
      padding: 0,
      backgroundColor: 'transparent',
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      borderRadius: 4
    },
    '& .MuiMenuItem-root': {
      height: 26,
      margin: 0,
      backgroundColor: (theme) => theme.palette.common.white,
      transition: (theme) =>
        theme.transitions.create('background-color', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen
        }),
      '&.Mui-selected': {
        backgroundColor: 'rgba(47, 188, 196, 0.40)'
      },
      '&:hover': {
        backgroundColor: (theme) => theme.palette.common.white
      }
    }
  }
};

export const EmployeeRecordFieldOptionsDropdown = styled(({ MenuProps, ...props }: SelectProps<any>) => (
  <Select {...props} MenuProps={StyledMenuProps} IconComponent={ChevronDownIcon} />
))(({ theme }) => ({
  minWidth: 126,
  maxWidth: 290,
  height: 26,
  backgroundColor: 'rgba(47, 188, 196, 0.40)',
  borderRadius: '16px',
  padding: '3px 7px',
  fontWeight: 'bold',
  color: '#1E1E1E',
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  },
  '& svg': {
    width: 24,
    height: 24
  }
}));
