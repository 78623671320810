import React, { memo, useRef, useState } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Quill, { Delta } from 'quill/core';
import 'quill-mention/autoregister';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { selectCurrentUserDisplayName, selectCurrentEmployeeId, selectCurrentUserProfilePictureUrl } from '@app/user';
import { addCurrentUserToAuthors, selectAuthorsByIds } from '@features/homepage';
import { CommentButton, commentOnTaskRequested, selectCommentOnTaskCommentStatus } from '@features/tasks';
import { ActionStatus, QuillEditor, StyledCommentBox } from '@/shared';
import { Author, CommentOnTaskRequest } from '@thrivea/organization-client';
import { removeBomCharacters } from '@utils/removeBomCharacters';

interface TasksAddCommentProps {
  taskId: string;
  authorId: string;
}

export const TasksAddComment: React.FC<TasksAddCommentProps> = memo(({ taskId, authorId }) => {
  const { t } = useTranslation(['common', 'tasks']);
  const dispatch = useAppDispatch();
  const commentOnTaskStatus = useAppSelector<ActionStatus>(selectCommentOnTaskCommentStatus);
  const [inputValue, setInputValue] = useState('');
  const innerButtonRef = useRef<HTMLButtonElement | null>(null);
  const quillRef = useRef<Quill | null>(null);

  const handleCommentButtonClick = () => {
    dispatch(
      commentOnTaskRequested(
        new CommentOnTaskRequest({
          taskId,
          commentText: removeBomCharacters(inputValue),
          authorId,
          attachmentUrls: ['']
        })
      )
    );

    quillRef.current!.setContents(new Delta());
    setInputValue('');
  };

  const handleRemoveFocus = () => {
    const activeElement = document.activeElement as HTMLElement;
    if (activeElement) {
      activeElement.blur();
    }
  };

  return (
    <StyledCommentBox
      onKeyDown={(event) => {
        if (event.key === 'Escape') {
          handleRemoveFocus();
        }
      }}
    >
      <QuillEditor emojiOnly uploadAttachment={true} setText={setInputValue} ref={quillRef} audienceId="" />
      <CommentButton
        loading={commentOnTaskStatus === ActionStatus.Pending}
        ref={innerButtonRef}
        variant="contained"
        color="primary"
        onClick={handleCommentButtonClick}
      >
        <Typography sx={{ color: (theme) => theme.palette.common.white }}>{t('leave_review', { ns: 'tasks' })}</Typography>
      </CommentButton>
    </StyledCommentBox>
  );
});
