import { Box } from '@mui/material';
import React from 'react';

interface HighlightTextProps {
  text: string;
  highlight: string;
  bold?: boolean;
  color?: string;
}

export const HighlightText: React.FC<HighlightTextProps> = ({ text, highlight, bold = false, color = 'rgba(151, 71, 255, 0.40)' }) => {
  const parts = text.split(new RegExp(`(${highlight})`, 'gi'));

  return (
    <Box
      component="span"
      sx={{
        fontWeight: bold ? 600 : 400,
        fontSize: 14
      }}
    >
      {parts.map((part, index) =>
        part.toLowerCase() === highlight.toLowerCase() ? (
          <Box component="span" key={index} style={{ backgroundColor: color }}>
            {part}
          </Box>
        ) : (
          part
        )
      )}
    </Box>
  );
};
