import { styled, Tab } from '@mui/material';

export const StyledDrawerTab = styled(Tab)(({ theme }) => ({
  borderRadius: '8px',
  padding: '8px 14px',
  fontSize: 14,
  fontWeight: 700,
  transition: theme.transitions.create('background-color', {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.leavingScreen
  })
}));
