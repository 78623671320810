import { MenuItem, MenuItemProps, styled } from '@mui/material';

export const ProfileMenuItem = styled(MenuItem)<MenuItemProps>(() => ({
  padding: 0,
  '&:hover': {
    borderColor: 'transparent',
    backgroundColor: 'transparent'
  },
  '&.Mui-selected': {
    backgroundColor: 'transparent'
  }
}));
