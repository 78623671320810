// emojiMartUtils.js

import { getEmojiDataFromNative } from 'emoji-mart';
import { chain, Dictionary } from 'lodash';

export const fetchEmojisWithShortcodes = async (emojis: string[]): Promise<Dictionary<string>> => {
  const emojisData = await Promise.all(emojis.map((emoji) => getEmojiDataFromNative(emoji)));

  return chain(emojis)
    .map((emoji) => [emoji, emojisData.find((emojiData) => emojiData.native === emoji)])
    .filter(([_, emojiData]) => emojiData.shortcodes && emojiData.shortcodes.length > 0)
    .keyBy('native')
    .mapValues('shortcodes')
    .value();
};
