import { ForwardedRef, forwardRef, useEffect, useId, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import {
  createPersonalSchema,
  selectPersonalCategory,
  selectPrefixes,
  selectProfileNotification,
  selectPronouns,
  updatePersonalRequested
} from '@features/employee-profile';
import { Controller, useForm } from 'react-hook-form';
import { Autocomplete, Box, FormControl, IconButton, InputLabel, MenuItem, Stack, Typography, ListItem, CircularProgress } from '@mui/material';
import Grid from '@mui/material/Grid2';
import {
  RowCenterStack,
  VisuallyHidden,
  EditIcon,
  CancelEditIcon,
  StyledSelect,
  ChevronDownIcon,
  StyledFormWrapper,
  StyledFormHeader,
  StyledFilledInput,
  StyledFormLabel,
  PrimaryDarkButton,
  StyledFormSubmitButton,
  StyledDatePickerFilledInput,
  ActionStatus
} from '@/shared';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';
import { Personal, UpdatePersonalRequest } from '@thrivea/organization-client';
import { useTranslation } from 'react-i18next';
import { i18nNationalities } from '@utils/getNationalities';
import { zodResolver } from '@hookform/resolvers/zod';
import { snakeCase } from 'lodash';
import { AllowedTo } from 'react-abac';
import { GroupPermissions, ProfileCategoryInfo } from '@features/abac';

const calculateAge = (dateOfBirth: string) => {
  const birthDate = DateTime.fromISO(dateOfBirth);
  const today = DateTime.local();
  const age = today.diff(birthDate, 'years').years;

  return Math.round(age);
};

const extractMonthAndDay = (dateOfBirth: string) => {
  return DateTime.fromISO(dateOfBirth).toLocaleString({ month: 'long', day: 'numeric' });
};

interface PersonalCategoryProps {
  ref: ForwardedRef<HTMLDivElement>;
  employeeId: string;
}

export const PersonalCategory = forwardRef<HTMLDivElement, PersonalCategoryProps>(({ employeeId }, ref) => {
  const id = useId();
  const { t } = useTranslation(['common', 'employee_profile']);
  const name = t('personal', { ns: 'employee_profile' });
  const dispatch = useAppDispatch();
  const { status, category } = useAppSelector(selectProfileNotification);
  const isPendingAndMatchingName = status === ActionStatus.Pending && category === 'personal';
  const personalCategory = useAppSelector(selectPersonalCategory);
  const prefixesOptions = useAppSelector(selectPrefixes);
  const pronounceOptions = useAppSelector(selectPronouns);
  const [isEditable, setIsEditable] = useState(false);
  const personalCategorySchema = useMemo(() => createPersonalSchema(t), [t]);

  const {
    formState: { dirtyFields, errors },
    control,
    reset,
    handleSubmit,
    getValues,
    watch
  } = useForm<Personal>({
    mode: 'all',
    resolver: zodResolver(personalCategorySchema),
    defaultValues: {
      prefixId: personalCategory.prefixId,
      pronounsId: personalCategory.pronounsId,
      dateOfBirth: personalCategory.dateOfBirth,
      nationalityCodes: personalCategory.nationalityCodes
    }
  });

  const dateOfBirth = watch('dateOfBirth');

  const handleCloseEditable = () => {
    reset({
      prefixId: personalCategory.prefixId,
      pronounsId: personalCategory.pronounsId,
      dateOfBirth: personalCategory.dateOfBirth,
      nationalityCodes: personalCategory.nationalityCodes
    });
    setIsEditable(false);
  };

  const handleToggleEditable = () => {
    setIsEditable(!isEditable);
  };

  const onSubmit = (data: Personal) => {
    dispatch(
      updatePersonalRequested({
        employeeId: employeeId,
        prefixId: data.prefixId,
        pronounsId: data.pronounsId,
        dateOfBirth: data.dateOfBirth,
        nationalityCodes: data.nationalityCodes
      } as UpdatePersonalRequest)
    );
    handleCloseEditable();
  };

  useEffect(() => {
    reset({
      prefixId: personalCategory.prefixId,
      pronounsId: personalCategory.pronounsId,
      dateOfBirth: personalCategory.dateOfBirth,
      nationalityCodes: personalCategory.nationalityCodes
    });
  }, [personalCategory]);

  return (
    <StyledFormWrapper isEditable={isEditable} id={snakeCase(name)} ref={ref}>
      <Box
        component="form"
        name={name}
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          backgroundColor: 'transparent'
        }}
      >
        <Stack>
          <StyledFormHeader className="Mui-ProfileFiledHeader" isEditable={isEditable}>
            <RowCenterStack gap={1}>
              {isPendingAndMatchingName && (
                <CircularProgress
                  size={24}
                  thickness={4}
                  sx={{
                    color: (theme) => theme.palette.primary.dark
                  }}
                />
              )}
              <Typography component="h3" variant="h5" fontWeight={700}>
                {name}
              </Typography>
            </RowCenterStack>
            <AllowedTo perform={GroupPermissions.EDIT_PROFILE} data={{ employeeId, categoryName: 'personal' } as ProfileCategoryInfo}>
              <IconButton
                onClick={handleToggleEditable}
                sx={{
                  opacity: '0',
                  display: isEditable ? 'none' : 'inline-flex'
                }}
              >
                <VisuallyHidden>
                  {t('edit', { ns: 'common' })} {name}
                </VisuallyHidden>
                <EditIcon />
              </IconButton>
            </AllowedTo>
            <RowCenterStack
              gap={2}
              sx={{
                display: isEditable ? 'flex' : 'none'
              }}
            >
              <PrimaryDarkButton variant="contained" onClick={handleCloseEditable} startIcon={<CancelEditIcon />}>
                {t('cancel', { ns: 'common' })}
              </PrimaryDarkButton>
              <StyledFormSubmitButton
                type="submit"
                disabled={Object.values(dirtyFields).length === 0 || Object.values(errors).length !== 0}
                variant="contained"
              >
                {t('done', { ns: 'common' })}
              </StyledFormSubmitButton>
            </RowCenterStack>
          </StyledFormHeader>
          <Grid
            container
            spacing={2}
            sx={{
              padding: 2
            }}
          >
            <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
              <Controller
                name="prefixId"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth>
                    <StyledFormLabel shrink disabled={!isEditable} htmlFor="prefix">
                      {t(snakeCase(field.name), { ns: 'employee_profile' })}
                    </StyledFormLabel>
                    <StyledSelect
                      {...field}
                      value={field.value ?? 'none'}
                      disabled={!isEditable}
                      inputProps={{ id: id + field.name }}
                      // placeholder="Select"
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                      IconComponent={() => {
                        return (
                          <Box className="MuiSvg-icon">
                            <ChevronDownIcon />
                          </Box>
                        );
                      }}
                      variant="outlined"
                    >
                      <MenuItem value="none" disabled>
                        {t('select', { ns: 'common' })}
                      </MenuItem>
                      {prefixesOptions &&
                        prefixesOptions.map((prefix) => (
                          <MenuItem key={prefix.id} value={prefix.id}>
                            {prefix.name}
                          </MenuItem>
                        ))}
                    </StyledSelect>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
              <Controller
                name="pronounsId"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth>
                    <InputLabel
                      shrink
                      disabled={!isEditable}
                      htmlFor="pronouns"
                      sx={{
                        fontSize: '12px',
                        top: '14px',
                        left: '-2px'
                      }}
                    >
                      {t('pronouns', { ns: 'employee_profile' })}
                    </InputLabel>
                    <StyledSelect
                      {...field}
                      value={field.value ?? 'none'}
                      disabled={!isEditable}
                      inputProps={{
                        id: id + 'pronouns'
                      }}
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                      IconComponent={() => {
                        return (
                          <Box className="MuiSvg-icon">
                            <ChevronDownIcon />
                          </Box>
                        );
                      }}
                      variant="outlined"
                    >
                      <MenuItem value="none" disabled>
                        {t('select', { ns: 'common' })}
                      </MenuItem>
                      {pronounceOptions &&
                        pronounceOptions.map((pronounce) => (
                          <MenuItem key={pronounce.id} value={pronounce.id}>
                            {pronounce.name}
                          </MenuItem>
                        ))}
                    </StyledSelect>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
              <Controller
                name="dateOfBirth"
                control={control}
                render={({ field, fieldState }) => (
                  <LocalizationProvider dateAdapter={AdapterLuxon}>
                    <DatePicker
                      {...field}
                      className="MuiDate-root"
                      format="yyyy-MM-dd"
                      value={field.value ? DateTime.fromISO(field.value) : null}
                      disableFuture
                      disabled={!isEditable}
                      slots={{
                        textField: StyledDatePickerFilledInput
                      }}
                      slotProps={{
                        textField: {
                          id: id + field.name,
                          variant: 'filled',
                          fullWidth: true,
                          placeholder: 'YYYY-MM-DD',
                          label: t(snakeCase(field.name), { ns: 'employee_profile' }),
                          error: !!fieldState.error,
                          helperText: fieldState.error?.message,
                          InputProps: {
                            disableUnderline: true,
                            readOnly: true
                          }
                        }
                      }}
                      onChange={(date: DateTime | null) => {
                        if (date) {
                          const jsDate = date.toJSDate();
                          const stringDate = DateTime.fromJSDate(jsDate).toFormat('yyyy-MM-dd');
                          field.onChange(stringDate);
                        }
                      }}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
              <StyledFilledInput
                id={id + 'age'}
                label={t('age', { ns: 'employee_profile' })}
                value={dateOfBirth ? calculateAge(dateOfBirth) : ''}
                disabled
                InputProps={{
                  readOnly: true
                }}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
              <StyledFilledInput
                id={id + 'birthday'}
                label={t('birthday', { ns: 'employee_profile' })}
                value={dateOfBirth ? extractMonthAndDay(dateOfBirth) : ''}
                disabled
                InputProps={{
                  readOnly: true
                }}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
              <Controller
                name="nationalityCodes"
                control={control}
                render={({ field, fieldState }) => (
                  <Autocomplete
                    multiple
                    disableClearable
                    value={i18nNationalities.filter((option) => getValues('nationalityCodes').includes(option.value))}
                    disabled={!isEditable}
                    popupIcon={<ChevronDownIcon />}
                    limitTags={isEditable ? -1 : 1}
                    options={i18nNationalities}
                    getOptionLabel={(option) => option.label}
                    onChange={(_, values) => {
                      field.onChange(values.map((option) => option.value));
                    }}
                    renderInput={(params) => (
                      <StyledFilledInput
                        {...params}
                        id={id + field.name}
                        label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                    renderTags={(tagValue, getTagProps) => {
                      return tagValue.map((option, index) => (
                        <Typography {...getTagProps({ index })} key={id + option.label}>
                          {option.label}
                        </Typography>
                      ));
                    }}
                    renderOption={(props, option) => {
                      return (
                        <ListItem {...props} key={id + option.label}>
                          {option.label}
                        </ListItem>
                      );
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Stack>
      </Box>
    </StyledFormWrapper>
  );
});
