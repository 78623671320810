import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@app/store';
import { ActionStatus } from '@shared/shared.model';
import { EmployeeListItem, RetrieveAllEmployeesResponse } from '@thrivea/organization-client';
import { groupEmployeesAlphabetically, initials } from '@/utils';
import { keys, uniq } from 'lodash';

interface EmployeesState {
  entities: {
    employeeListItems: {
      byId: Record<string, EmployeeListItem>;
      allIds: string[];
    };
  };
  ui: {
    employeeListItemsStatus: ActionStatus;
    userStatus: ActionStatus;
  };
  currentEmployeeId: string;
  tenantId: string;
  signalRConnectionId: string;
}

const initialState: EmployeesState = {
  entities: {
    employeeListItems: {
      byId: {},
      allIds: []
    }
  },
  ui: {
    employeeListItemsStatus: ActionStatus.Idle,
    userStatus: ActionStatus.Idle
  },
  currentEmployeeId: '',
  tenantId: '',
  signalRConnectionId: ''
};

export const employeesSlice = createSlice({
  name: 'employees',
  initialState,
  reducers: {
    retrieveEmployeesRequested: (state) => {
      state.ui.employeeListItemsStatus = ActionStatus.Pending;
    },
    retrieveEmployeesSucceeded: (state, action: PayloadAction<RetrieveAllEmployeesResponse>) => {
      const { employees } = action.payload;

      for (const employee of employees) {
        state.entities.employeeListItems.byId[employee.employeeId] = employee;
      }

      state.entities.employeeListItems.allIds = uniq(state.entities.employeeListItems.allIds.concat(employees.map((e) => e.employeeId)));

      state.ui.employeeListItemsStatus = ActionStatus.Idle;
    },
    retrieveEmployeesFailed: (state) => {
      state.ui.employeeListItemsStatus = ActionStatus.Failed;
    },
    updateEmployee: (state, action: PayloadAction<EmployeeListItem>) => {
      state.entities.employeeListItems.byId[action.payload.employeeId] = action.payload;
    },
    updateCurrentUserProfilePicture: (state, action: PayloadAction<string>) => {
      state.entities.employeeListItems.byId[state.currentEmployeeId] = {
        ...state.entities.employeeListItems.byId[state.currentEmployeeId],
        profilePictureUrl: action.payload
      };
    },
    setSignalRConnectionId: (state, action: PayloadAction<string>) => {
      state.signalRConnectionId = action.payload;
    },
    setCurrentEmployeeIdAndTenantId: (state, action: PayloadAction<{ employeeId: string; tenantId: string }>) => {
      const { employeeId, tenantId } = action.payload;

      state.currentEmployeeId = employeeId;
      state.tenantId = tenantId;
    }
  }
});

export const selectEmployeeListItemsIds = (state: RootState) => state.employees.entities.employeeListItems.allIds;

export const selectEmployeeListItemsById = (state: RootState) => state.employees.entities.employeeListItems.byId;

export const selectEmployeeListItemById = (state: RootState, employeeId: string) => state.employees.entities.employeeListItems.byId[employeeId];

export const selectEmployeeListItemsStatus = (state: RootState) => state.employees.ui.employeeListItemsStatus;

export const selectEmployeeListItems = createSelector([selectEmployeeListItemsById, selectEmployeeListItemsIds], (byId, allIds) =>
  allIds.map((id) => byId[id])
);

const selectAutocompleteEmployeeItemsGroupedAlphabetically = createSelector([selectEmployeeListItems], (items) => groupEmployeesAlphabetically(items));

export const selectEmployeesAutocompleteOptionsGroupedAlphabetically = createSelector(
  [selectAutocompleteEmployeeItemsGroupedAlphabetically],
  (groupedEmployeesAlphabetically) => keys(groupedEmployeesAlphabetically).flatMap((key) => groupedEmployeesAlphabetically[key])
);

export const selectCurrentUserDisplayName = (state: RootState) =>
  state.employees.entities.employeeListItems.byId[state.employees.currentEmployeeId]?.displayName;
export const selectCurrentUserFirstName = (state: RootState) =>
  state.employees.entities.employeeListItems.byId[state.employees.currentEmployeeId]?.displayName.split(' ')[0] || 'Unknown';

export const selectCurrentUserEmailAddress = (state: RootState) =>
  state.employees.entities.employeeListItems.byId[state.employees.currentEmployeeId]?.emailAddress;
export const selectCurrentUserJobTitle = (state: RootState) => state.employees.entities.employeeListItems.byId[state.employees.currentEmployeeId]?.jobTitle;
export const selectCurrentUserProfilePictureUrl = (state: RootState) =>
  state.employees.entities.employeeListItems.byId[state.employees.currentEmployeeId]!.profilePictureUrl;
export const selectCurrentEmployeeId = (state: RootState) => state.employees.currentEmployeeId;
export const selectActiveEmployeeIdByParam = (state: RootState, employeeId?: string) => employeeId || state.employees.currentEmployeeId;

export const selectCurrentEmployee = (state: RootState) => state.employees.entities.employeeListItems.byId[state.employees.currentEmployeeId];

export const selectCurrentUserFullName = (state: RootState) => state.employees.entities.employeeListItems.byId[state.employees.currentEmployeeId]?.displayName;

export const selectCurrentUserInitials = createSelector([selectCurrentUserDisplayName], (name) => initials(name));

export const selectCurrentUserTenantId = (state: RootState) => state.employees.tenantId;

export const selectSignalRConnectionId = (state: RootState) => state.employees.signalRConnectionId;

export const {
  updateCurrentUserProfilePicture,
  setSignalRConnectionId,
  retrieveEmployeesRequested,
  retrieveEmployeesSucceeded,
  retrieveEmployeesFailed,
  updateEmployee,
  setCurrentEmployeeIdAndTenantId
} = employeesSlice.actions;
export default employeesSlice.reducer;
