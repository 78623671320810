import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '@app/store';

interface NavigationState {
  isNavigationDrawerOpen: boolean;
  isNavigationExpanded: boolean;
  drawerWidth: number;
}

const initialState: NavigationState = {
  isNavigationDrawerOpen: false,
  isNavigationExpanded: false,
  drawerWidth: 227
};

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    openNavigationDrawer: (state) => {
      state.isNavigationDrawerOpen = !state.isNavigationDrawerOpen;
    },
    expandNavigationDrawer: (state) => {
      state.isNavigationExpanded = !state.isNavigationExpanded;
    },
    forceExpandedNavigation: (state) => {
      state.isNavigationExpanded = true;
    }
  }
});

export const { openNavigationDrawer, expandNavigationDrawer, forceExpandedNavigation } = navigationSlice.actions;

export const selectNavigationIsDesktopOpen = (state: RootState) => state.navigation.isNavigationDrawerOpen;
export const selectNavigationIsExpanded = (state: RootState) => state.navigation.isNavigationExpanded;
export const selectNavigationWidth = (state: RootState) => state.navigation.drawerWidth;

export default navigationSlice.reducer;
