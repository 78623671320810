import { create } from '@bufbuild/protobuf';
import { Box, Stack } from '@mui/material';
import Quill, { Range } from 'quill/core';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { CancelEditIcon, PrimaryDarkButton, QuillEditor, RowCenterEndStack, StyledFormSubmitButton } from '@/shared';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  editPostRequested,
  InlinePostMediaEdit,
  PostDoc,
  PostMedia,
  retrieveMentionEmployeesRequested,
  selectAudienceIdByPostId,
  selectMentionEmployeesIds,
  selectNewFilesUrls
} from '@features/homepage';
import { fetchBlobFileNames } from '@api/blob-storage.api';
import { trimQueryParams, isImageUrl, isVideoUrl, isDocumentUrl } from '@/utils';
import * as Sentry from '@sentry/react';
import { selectEmployeeListItemsById } from '@features/shared';
import { EditShoutoutRequestSchema, RetrieveEmployeeIdsByAudienceIdRequestSchema } from '@thrivea/networking-client';

interface InlinePostEditProps {
  postId: string;
  authorId: string;
  content: string;
  files: PostMedia[];
  docs: PostDoc[];
  handleSetPostEditable: (id: string) => void;
}

export const InlinePostEdit = ({ postId, authorId, content, files, docs, handleSetPostEditable }: InlinePostEditProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['common', 'homepage']);
  const [text, setText] = useState(content);
  const [isMediaToolbarVisible] = useState(false);
  const [isMentionToolbarVisible] = useState(false);
  const [filenames, setFilenames] = useState<(PostMedia | PostDoc)[]>([]);
  const [_, setSelectionRange] = useState<Range | null>(null);

  const uploadedFilesUrls = useAppSelector<string[]>(selectNewFilesUrls);
  const audienceId = useAppSelector((state) => selectAudienceIdByPostId(state, postId));
  const mentionEmployeesIds = useAppSelector(selectMentionEmployeesIds);
  const employeeListItemById = useAppSelector(selectEmployeeListItemsById);

  const mentionEmployees = mentionEmployeesIds.map((id) => employeeListItemById[id]);

  const quillRef = useRef<Quill | null>(null);

  useEffect(() => {
    if (audienceId) {
      dispatch(
        retrieveMentionEmployeesRequested(
          create(RetrieveEmployeeIdsByAudienceIdRequestSchema, {
            audienceId
          })
        )
      );
    }
  }, []);

  useEffect(() => {
    const quill = quillRef.current!;
    if (quill) {
      const updateSelectionRange = (range) => {
        if (range) {
          setSelectionRange(range);
        } else {
          setSelectionRange(null);
        }
      };

      quill.on('selection-change', updateSelectionRange);

      return () => {
        quill.off('selection-change', updateSelectionRange);
      };
    }
  }, [quillRef]);

  useEffect(() => {
    const fetchAttachmentFileNames = async () => {
      try {
        const docFiles = await fetchBlobFileNames(docs.map((df) => df.src));
        // old docs were in mediaUrls
        const mediaFiles = await fetchBlobFileNames(files.map((f) => f.src));
        const docFileNames = docFiles.map((name, index) => ({
          name,
          src: docs[index].src,
          alt: docs[index].alt
        }));
        // old docs were in mediaUrls
        const mediaFileNames = mediaFiles.map((name, index) => ({
          name,
          src: files[index].src,
          alt: files[index].alt
        }));
        setFilenames([...mediaFileNames, ...docFileNames]);
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    fetchAttachmentFileNames();
  }, [files, docs, uploadedFilesUrls]);

  const handleDeleteFile = (src: string) => {
    setFilenames(filenames.filter((file) => file.src !== src));
  };

  const handleUpdatePostButtonClick = () => {
    const oldMediaUrls = filenames.map((nf) => trimQueryParams(nf.src)).filter((url) => isImageUrl(url) || isVideoUrl(url));
    const oldDocumentUrls = filenames.map((nf) => trimQueryParams(nf.src)).filter((url) => isDocumentUrl(url));
    const newFilesUrls = uploadedFilesUrls.map((uf) => trimQueryParams(uf)).filter((url) => isImageUrl(url) || isVideoUrl(url));
    const docFileUrls = uploadedFilesUrls.map((uf) => trimQueryParams(uf)).filter((url) => isDocumentUrl(url));

    const mergedMediaUrls = [...oldMediaUrls, ...newFilesUrls];
    const mergedDocumentUrls = [...oldDocumentUrls, ...docFileUrls];

    dispatch(
      editPostRequested(
        create(EditShoutoutRequestSchema, {
          id: postId,
          editAuthorId: authorId,
          text,
          mediaUrls: mergedMediaUrls,
          docUrls: mergedDocumentUrls
        })
      )
    );

    handleSetPostEditable(postId);
  };

  return (
    <Stack
      onKeyDown={(event) => {
        if (event.key === 'Escape') {
          handleSetPostEditable(postId);
        }
        if (event.key === 'Enter' && event.shiftKey) {
          handleUpdatePostButtonClick();
        }
      }}
      gap={2}
      sx={{
        backgroundColor: 'transparent',
        borderRadius: 4,
        mb: 2
      }}
    >
      <Stack gap={2}>
        <Box
          sx={{
            position: 'relative',
            '& .ql-editor': {
              whiteSpace: 'pre-wrap'
            }
          }}
        >
          <QuillEditor ref={quillRef} text={text} setText={setText} employeeListItems={mentionEmployees} />
        </Box>
        {filenames.length > 0 && <InlinePostMediaEdit namedFiles={filenames} handleDeleteFile={handleDeleteFile} />}
        <Stack
          sx={{
            p: 2,
            borderRadius: 4,
            backgroundColor: (theme) =>
              isMediaToolbarVisible || isMentionToolbarVisible ? theme.palette.customTheme.drawerBackground : theme.palette.common.white
          }}
        >
          <RowCenterEndStack gap={1}>
            <PrimaryDarkButton startIcon={<CancelEditIcon />} variant="contained" onClick={() => handleSetPostEditable(postId)}>
              {t('cancel', { ns: 'common' })}
            </PrimaryDarkButton>
            <StyledFormSubmitButton onClick={handleUpdatePostButtonClick}>{t('update', { ns: 'common' })}</StyledFormSubmitButton>
          </RowCenterEndStack>
        </Stack>
      </Stack>
    </Stack>
  );
};
