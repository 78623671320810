import React, { useState } from 'react';
import { IconButton, Menu, MenuItem, Modal, Typography, Stack, Box } from '@mui/material';
import { PrimaryDarkButton, RowCenterStack, SecondaryDarkButton, CancelEditIcon } from '@/shared';
import { deleteCommentRequested, selectPostById } from '@features/homepage';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { DeleteCommentRequest } from '@thrivea/networking-client';
import { AllowedTo } from 'react-abac';
import { useTranslation } from 'react-i18next';
import { AudiencePermissions, CommentInfo } from '@features/abac';
import { MoreHoriz } from '@mui/icons-material';
import { SmModalContent } from 'src/features/admin-settings';

interface PostCommentMenuProps {
  postId: string;
  authorId: string;
  commentId: string;
  handleSetCommentEditable: (id: string) => void;
}

export const PostCommentMenu: React.FC<PostCommentMenuProps> = ({ postId, authorId, commentId, handleSetCommentEditable }) => {
  const { t } = useTranslation(['common', 'homepage']);
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const open = Boolean(anchorEl);
  const post = useAppSelector((state) => selectPostById(state, postId));

  const handleThreeDotsMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteModalOpen = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
  };

  const handleEditComment = () => {
    handleSetCommentEditable(commentId);
    setAnchorEl(null);
  };

  const handleDeleteComment = () => {
    dispatch(
      deleteCommentRequested({
        postId,
        commentId
      } as DeleteCommentRequest)
    );
  };

  return (
    <Box
      sx={{
        minWidth: 24
      }}
    >
      <IconButton
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleThreeDotsMenuClick}
        sx={{
          padding: '0'
        }}
      >
        <MoreHoriz />
      </IconButton>
      <Menu
        id="comment_actions"
        MenuListProps={{
          'aria-labelledby': 'comment_actions'
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            style: {
              maxHeight: 216,
              width: '20ch'
            }
          }
        }}
      >
        <AllowedTo
          perform={AudiencePermissions.EDIT_COMMENT}
          data={{ audienceId: post.audienceIds[0], authorId: authorId } as CommentInfo}
          no={() => <MenuItem disabled>{t('edit', { ns: 'common' })}</MenuItem>}
        >
          <MenuItem onClick={handleEditComment}>{t('edit', { ns: 'common' })}</MenuItem>
        </AllowedTo>
        <AllowedTo
          perform={AudiencePermissions.DELETE_COMMENT}
          data={{ audienceId: post.audienceIds[0], authorId: authorId } as CommentInfo}
          no={() => <MenuItem disabled>{t('delete', { ns: 'common' })}</MenuItem>}
        >
          <MenuItem onClick={handleDeleteModalOpen}>{t('delete', { ns: 'common' })}</MenuItem>
        </AllowedTo>
      </Menu>
      <Modal
        open={isDeleteModalOpen}
        onClose={handleDeleteModalClose}
        aria-labelledby="delete-post-modal-title"
        aria-description={t('delete_comment', { ns: 'homepage' })}
        sx={{
          background: 'rgba(217, 217, 217, 0.60)',
          backdropFilter: 'blur(10px)'
        }}
      >
        <SmModalContent>
          <Stack gap={2}>
            <Typography variant="h3" component="h5">
              {t('delete_comment', { ns: 'homepage' })}
            </Typography>
            <Typography variant="body1">{t('delete_comment_question', { ns: 'homepage' })}</Typography>
            <RowCenterStack
              gap={2}
              sx={{
                width: '100%',
                justifyContent: 'flex-end'
              }}
            >
              <PrimaryDarkButton startIcon={<CancelEditIcon />} onClick={handleDeleteModalClose} variant="outlined">
                {t('cancel', { ns: 'common' })}
              </PrimaryDarkButton>
              <SecondaryDarkButton variant="contained" onClick={handleDeleteComment}>
                {t('done', { ns: 'common' })}
              </SecondaryDarkButton>
            </RowCenterStack>
          </Stack>
        </SmModalContent>
      </Modal>
    </Box>
  );
};
