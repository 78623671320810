import { Button, ButtonProps, styled, typographyClasses } from '@mui/material';

export const TasksCtaButton = styled(Button)<ButtonProps>(({ theme }) => ({
  backgroundColor: theme.palette.customTheme.selectLabel,
  color: theme.palette.customTheme.contColor,
  padding: '12px 16px',
  borderRadius: 8,
  height: 35,
  lineHeight: 'normal'
}));
