import { FormControlLabel, FormControlLabelProps, styled, typographyClasses } from '@mui/material';
import { forwardRef } from 'react';

export const StyledFormControlLabel = styled(
  forwardRef<HTMLDivElement, FormControlLabelProps>(({ ...props }, ref) => <FormControlLabel ref={ref} {...props} />)
)(() => ({
  width: '100%',
  [`& .${typographyClasses.root}`]: {
    width: '100%'
  }
}));
