import React, { useState, useCallback, useEffect } from 'react';
import { FormControlLabel, Box, TextField, InputAdornment, Stack, Typography, Collapse, List, typographyClasses, ClickAwayListener } from '@mui/material';
import {
  ChevronDownIcon,
  CustomCheckBox,
  HighlightText,
  RowCenterStack,
  StyledAutocompleteListItem,
  StyledCheckbox,
  StyledTransparentButton,
  Option,
  MagnifyingGlassIcon
} from '@/shared';
import { useTranslation } from 'react-i18next';
import { DropDownSearchBox } from './styled/DropDownSearchBox';

// The hook for filtering dropdown Options
const useFilteredFields = (options: Option[], value: string) => {
  const { t } = useTranslation(['tasks']);
  const [fields, setFields] = useState<Option[]>([]);

  const filterFields = useCallback(() => options.filter((option) => option.name.toLowerCase().includes(value.toLowerCase())), [options, value]);

  useEffect(() => {
    setFields(filterFields());
  }, [filterFields]);

  return fields;
};

interface TasksDropdownSearchProps {
  options: Option[];
  values?: Option[];
  label: string;
  onOptionSelect: (
    selectedOption: {
      id: string;
      name: string;
    },
    isEnabled: boolean
  ) => void;
}

export const TasksDropdownSearch: React.FC<TasksDropdownSearchProps> = ({ options, values, label, onOptionSelect }) => {
  const { t } = useTranslation(['common']);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchText, setSearchText] = useState('');

  const filteredFields = useFilteredFields(options, searchText);
  const isOpened = Boolean(anchorEl);

  const handleToggleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl((prevAnchorEl) => (prevAnchorEl ? null : event.currentTarget));
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <DropDownSearchBox isOpen={isOpened}>
        <Stack
          onClick={handleToggleClick}
          sx={{
            width: '100%',
            pl: 1,
            pr: 2,
            py: 1,
            height: 50,
            gap: 0,
            borderRadius: 1,
            position: 'relative'
          }}
        >
          <Typography
            variant="caption"
            sx={{
              position: 'absolute',
              top: 3,
              left: 5,
              color: (theme) => theme.palette.customTheme.globalContentColorMuted
            }}
          >
            {label}
          </Typography>
          <RowCenterStack
            sx={{
              justifyContent: 'space-between'
            }}
          >
            <TextField
              fullWidth
              slotProps={{
                input: {
                  startAdornment: (
                    <InputAdornment position="start">
                      <MagnifyingGlassIcon width={16} height={16} />
                    </InputAdornment>
                  )
                }
              }}
              sx={{
                borderRadius: 4,
                fontSize: 14,
                '& .MuiInputBase-root': {
                  paddingLeft: 0
                },
                '& input': {
                  padding: 1
                },
                '& fieldset': {
                  border: '0 !important'
                }
              }}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <StyledTransparentButton
              sx={{
                transform: isOpened ? 'rotate(180deg)' : 'rotate(0deg)'
              }}
            >
              <ChevronDownIcon />
            </StyledTransparentButton>
          </RowCenterStack>
        </Stack>
        <Collapse
          in={isOpened}
          sx={{
            position: 'absolute',
            top: 50,
            left: -1,
            right: -1,
            borderBottomLeftRadius: 8,
            borderBottomRightRadius: 8,
            border: isOpened ? '1px solid' : 0,
            borderTop: 0,
            borderColor: (theme) => theme.palette.primary.main,
            backgroundColor: (theme) => theme.palette.customTheme.primaryA
          }}
        >
          <Stack
            gap={1}
            sx={{
              padding: '8px',
              backgroundColor: (theme) => theme.palette.common.white,
              borderBottomLeftRadius: 8,
              borderBottomRightRadius: 8
            }}
          >
            <List
              role="listbox"
              sx={{
                maxHeight: 270,
                overflowY: 'scroll',
                display: 'flex',
                flexDirection: 'column',
                gap: 0
              }}
            >
              {filteredFields.length === 0 ? (
                <StyledAutocompleteListItem>
                  <Typography>{t('no_options_found', { ns: 'common' })}</Typography>
                </StyledAutocompleteListItem>
              ) : (
                <>
                  <StyledAutocompleteListItem
                    sx={{
                      backgroundColor: (theme) => theme.palette.customTheme.drawerBackground
                    }}
                  >
                    All
                  </StyledAutocompleteListItem>
                  {filteredFields.map((field) => (
                    <StyledAutocompleteListItem key={field.id} aria-selected={values?.includes(field)}>
                      <FormControlLabel
                        key={field.id}
                        control={
                          <StyledCheckbox
                            icon={<CustomCheckBox size={18} />}
                            checked={values?.includes(field)}
                            onChange={(event) => onOptionSelect(field, event.target.checked)}
                          />
                        }
                        label={
                          <Typography>
                            <HighlightText text={field.name} highlight={searchText} />
                          </Typography>
                        }
                        sx={{
                          width: '100%',
                          gap: 1,
                          alignItems: 'center',
                          marginLeft: 0,
                          [`& .${typographyClasses.root}`]: {
                            width: '100%'
                          }
                        }}
                      />
                    </StyledAutocompleteListItem>
                  ))}
                </>
              )}
            </List>
          </Stack>
          <RowCenterStack></RowCenterStack>
        </Collapse>
      </DropDownSearchBox>
    </ClickAwayListener>
  );
};
