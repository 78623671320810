import { styled, ListItemButton } from '@mui/material';

export const AudienceListItemButton = styled(ListItemButton)<{ selected: boolean }>(({ theme, selected }) => ({
  height: 34,
  padding: '8px',
  border: 0,
  borderRadius: '4px',
  backgroundColor: 'transparent',
  margin: selected ? 0 : undefined,
  marginBottom: '8px',
  transition: theme.transitions.create('background-color', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  '&:hover': {
    backgroundColor: 'transparent'
  },
  '&.Mui-selected': {
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
}));
