import { debounce } from 'lodash';
import { useCallback, useEffect } from 'react';
import { FieldValues, UseFormTrigger, Path } from 'react-hook-form';

export const useDebounceValidate = <TFieldValues extends FieldValues>(trigger: UseFormTrigger<TFieldValues>, delay: number) => {
  const debounceValidate = useCallback(
    debounce((field: Path<TFieldValues>) => {
      trigger(field);
    }, delay),
    [trigger, delay]
  );

  useEffect(() => {
    return () => {
      debounceValidate.cancel();
    };
  }, [debounceValidate]);

  return debounceValidate;
};
