import { Stack, StackProps, styled } from '@mui/material';

export const PaymentMethodCenterStack = styled(Stack)<StackProps>(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.customTheme.drawerBackground,
  borderBottomLeftRadius: '16px',
  borderBottomRightRadius: '16px',
  padding: '8px 16px',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start'
}));
