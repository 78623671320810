import { create } from '@bufbuild/protobuf';
import { useRef, useState } from 'react';
import { Button, Typography } from '@mui/material';
import {
  selectCommentOnPostStatus,
  commentOnPostRequested,
  EMPTY_EDITOR,
  selectMentionEmployeesIds,
  retrieveMentionEmployeesRequested
} from '@features/homepage';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { useTranslation } from 'react-i18next';
import { ActionStatus, QuillEditor, StyledCommentBox } from '@/shared';
import 'quill-mention/autoregister';
import Quill, { Delta } from 'quill/core';
import { removeBomCharacters } from '@utils/removeBomCharacters';
import { selectEmployeeListItemsById } from '@features/shared';
import { CommentOnPostRequestSchema, RetrieveEmployeeIdsByAudienceIdRequestSchema } from '@thrivea/networking-client';

interface AddCommentProps {
  postId: string;
  authorId: string;
  audienceId: string;
}

export const AddComment = ({ postId, authorId, audienceId }: AddCommentProps): JSX.Element => {
  const { t } = useTranslation('common');
  const dispatch = useAppDispatch();

  const commentOnPostStatus = useAppSelector<ActionStatus>(selectCommentOnPostStatus);
  const mentionEmployeesIds = useAppSelector(selectMentionEmployeesIds);
  const employeeListItemById = useAppSelector(selectEmployeeListItemsById);

  const [inputValue, setInputValue] = useState('');

  const innerButtonRef = useRef<HTMLButtonElement | null>(null);
  const quillRef = useRef<Quill | null>(null);

  const mentionEmployees = mentionEmployeesIds.map((id) => employeeListItemById[id]);

  const handleCommentButtonClick = () => {
    dispatch(
      commentOnPostRequested(
        create(CommentOnPostRequestSchema, {
          postId,
          comment: removeBomCharacters(inputValue),
          authorId
        })
      )
    );

    quillRef.current!.setContents(new Delta());
    setInputValue('');
  };

  const handleRemoveFocus = () => {
    const activeElement = document.activeElement as HTMLElement;
    if (activeElement) {
      activeElement.blur();
    }
  };

  return (
    <StyledCommentBox
      onFocus={() => {
        dispatch(
          retrieveMentionEmployeesRequested(
            create(RetrieveEmployeeIdsByAudienceIdRequestSchema, {
              audienceId
            })
          )
        );
      }}
      onKeyDown={(event) => {
        if (event.key === 'Escape') {
          handleRemoveFocus();
        }
      }}
    >
      <QuillEditor setText={setInputValue} ref={quillRef} isComment employeeListItems={mentionEmployees} />
      <Button
        loading={commentOnPostStatus === ActionStatus.Pending}
        ref={innerButtonRef}
        variant="contained"
        color="primary"
        disabled={inputValue.length === 0 || inputValue === EMPTY_EDITOR || commentOnPostStatus === ActionStatus.Pending}
        onClick={handleCommentButtonClick}
        sx={{
          my: 1
        }}
      >
        <Typography sx={{ color: (theme) => theme.palette.common.white }}>{t('comment')}</Typography>
      </Button>
    </StyledCommentBox>
  );
};
