import { Stack, StackProps, styled } from '@mui/material';
interface TasksListTaskRowProps extends StackProps {
  statusId: string;
  isInTaskList?: boolean;
}

export const BoardTask = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'isInTaskList'
})<TasksListTaskRowProps>(({ isInTaskList, theme }) => {
  return {
    backgroundColor: theme.palette.common.white,
    position: 'relative',
    borderRadius: '6px',
    padding: isInTaskList ? 4 : 8,
    border: `1px solid ${theme.palette.customTheme.contentBorder}`
  };
});
