import { create } from '@bufbuild/protobuf';
import React, { useState } from 'react';
import { Typography, Box, Stack, IconButton } from '@mui/material';
import {
  AssetsSharePeopleIcon,
  PictureSizeSuffix,
  PrimaryDarkButton,
  RowCenterJustifyBetweenStack,
  RowCenterStack,
  StyledAvatar,
  StyledBlurryModal,
  StyledModalContent,
  StyledTransparentButton
} from '@/shared';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { selectEmployeeListItemsById } from '@features/shared';
import { selectEmployeeProfileAndCoverReadSasToken } from '@features/employee-profile';
import { initials, pictureUrl } from 'src/utils';
import {
  resendInvitationEmailRequested,
  deactivateEmployeesRequested,
  removeSelectedEmployeeRowIds,
  selectSelectedEmployeeRowIds,
  selectCanSelectedEmployeesBeReinvited,
  selectCanSelectedEmployeesBeDeactivated,
  TableTooltip
} from '@features/people-directory';
import { AssetsTooltip } from '@features/assets';
import { useTranslation } from 'react-i18next';
import PersonOff from '@mui/icons-material/PersonOff';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import { ResendInvitationEmailRequestSchema, DeactivateEmployeesRequestSchema } from '@thrivea/organization-client';

interface PeopleDirectoryTableActionsProps {
  isDisabled?: boolean;
}

export const PeopleDirectoryTableActions: React.FC<PeopleDirectoryTableActionsProps> = ({ isDisabled }) => {
  const { t } = useTranslation(['people_directory']);
  const dispatch = useAppDispatch();

  const employeeListItemById = useAppSelector(selectEmployeeListItemsById);
  const employeeProfileSasToken = useAppSelector(selectEmployeeProfileAndCoverReadSasToken);
  const selectedEmployeeIds = useAppSelector(selectSelectedEmployeeRowIds);
  const canSelectedEmployeesBeReinvited = useAppSelector(selectCanSelectedEmployeesBeReinvited);
  const canSelectedEmployeesBeDeactivated = useAppSelector(selectCanSelectedEmployeesBeDeactivated);

  const [isDeactivateModalOpen, setIsDeactivateModalOpen] = useState(false);
  const [isResendEmailModalOpen, setIsResendEmailModalOpen] = useState(false);

  const handleResendEmailButtonClick = () => {
    setIsResendEmailModalOpen(true);
  };

  const handleDeactivateButtonClick = () => {
    setIsDeactivateModalOpen(true);
  };

  const handleSubmitEmailsButtonClick = () => {
    dispatch(
      resendInvitationEmailRequested(
        create(ResendInvitationEmailRequestSchema, {
          employeeIds: selectedEmployeeIds!
        })
      )
    );
    setIsResendEmailModalOpen(false);
  };

  const handleDeactivateEmailsButtonClick = () => {
    dispatch(
      deactivateEmployeesRequested(
        create(DeactivateEmployeesRequestSchema, {
          employeeIds: selectedEmployeeIds!
        })
      )
    );
    setIsDeactivateModalOpen(false);
  };

  return (
    <Box>
      <RowCenterStack gap={1}>
        <StyledTransparentButton
          startIcon={<MarkEmailUnreadIcon sx={{ width: 16, height: 16, color: isDisabled || !canSelectedEmployeesBeReinvited ? '#00000042' : '#000000' }} />}
          onClick={handleResendEmailButtonClick}
          disableRipple
          disabled={isDisabled || !canSelectedEmployeesBeReinvited}
        >
          <AssetsTooltip title="Only employees that have invited status can be resent invitation email.">
            <RowCenterStack gap={1}>
              <Typography variant="body2">{t('resend_invitation_email', { ns: 'people_directory' })}</Typography>
            </RowCenterStack>
          </AssetsTooltip>
        </StyledTransparentButton>
        <StyledTransparentButton
          startIcon={<PersonOff sx={{ width: 16, height: 16, color: isDisabled || !canSelectedEmployeesBeDeactivated ? '#00000042' : '#000000' }} />}
          onClick={handleDeactivateButtonClick}
          disableRipple
          disabled={isDisabled || !canSelectedEmployeesBeDeactivated}
        >
          <RowCenterStack gap={1}>
            <Typography variant="body2">{t('deactivate_accounts', { ns: 'people_directory' })}</Typography>
          </RowCenterStack>
        </StyledTransparentButton>
      </RowCenterStack>
      <StyledBlurryModal open={isResendEmailModalOpen} onClose={() => setIsResendEmailModalOpen(false)}>
        <StyledModalContent
          sx={{
            position: 'relative',
            justifyContent: 'center',
            alignItems: 'flex-start',
            minWidth: {
              xs: 'auto',
              lg: '670px'
            },
            height: {
              xs: '80%',
              lg: 'auto'
            },
            padding: '120px',
            display: 'flex',
            borderRadius: '20px',
            boxShadow: 'none'
          }}
        >
          <Stack gap={2} sx={{ width: '100%' }}>
            <Typography variant="h3">Resend invitations ?</Typography>
            <Typography variant="body2">Below is the list of employees to whom emails will be resent:</Typography>
            {selectedEmployeeIds?.map((employeeId) => {
              const employee = employeeListItemById[employeeId];

              return (
                <RowCenterJustifyBetweenStack key={employeeId}>
                  <RowCenterStack gap={1}>
                    <StyledAvatar src={pictureUrl(employee.profilePictureUrl, employeeProfileSasToken, PictureSizeSuffix.sm)} width={36} height={36}>
                      {initials(employee.displayName)}
                    </StyledAvatar>
                    <Stack>
                      <Typography variant="body2">{employee.displayName}</Typography>
                      <Typography variant="caption">{employee.emailAddress}</Typography>
                    </Stack>
                  </RowCenterStack>
                  <TableTooltip title={t('remove', { ns: 'common' })}>
                    <IconButton
                      sx={{ padding: 0 }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        dispatch(removeSelectedEmployeeRowIds(employee.employeeId));
                      }}
                    >
                      <AssetsSharePeopleIcon width={24} height={24} />
                    </IconButton>
                  </TableTooltip>
                </RowCenterJustifyBetweenStack>
              );
            })}
            <RowCenterStack gap={1}>
              <PrimaryDarkButton variant="contained" color="primary" onClick={() => setIsResendEmailModalOpen(false)}>
                Cancel
              </PrimaryDarkButton>
              <PrimaryDarkButton variant="contained" color="primary" onClick={handleSubmitEmailsButtonClick}>
                Resend
              </PrimaryDarkButton>
            </RowCenterStack>
          </Stack>
        </StyledModalContent>
      </StyledBlurryModal>
      <StyledBlurryModal open={isDeactivateModalOpen} onClose={() => setIsDeactivateModalOpen(false)}>
        <StyledModalContent
          sx={{
            position: 'relative',
            justifyContent: 'center',
            alignItems: 'flex-start',
            minWidth: {
              xs: 'auto',
              lg: '670px'
            },
            height: {
              xs: '80%',
              lg: 'auto'
            },
            padding: '120px',
            display: 'flex',
            borderRadius: '20px',
            boxShadow: 'none'
          }}
        >
          <Stack gap={2} sx={{ width: '100%' }}>
            <Typography variant="h3">Ready to Disable Accounts?</Typography>
            <Typography variant="body2">
              Here is the list of employees whose accounts will be deactivated. Please review carefully before proceeding.
            </Typography>
            {selectedEmployeeIds.map((employeeId) => {
              const employee = employeeListItemById[employeeId];

              return (
                <RowCenterJustifyBetweenStack key={employeeId}>
                  <RowCenterStack gap={1}>
                    <StyledAvatar src={pictureUrl(employee.profilePictureUrl, employeeProfileSasToken, PictureSizeSuffix.sm)} width={36} height={36}>
                      {initials(employee.displayName)}
                    </StyledAvatar>
                    <Stack>
                      <Typography variant="body2">{employee.displayName}</Typography>
                      <Typography variant="caption">{employee.emailAddress}</Typography>
                    </Stack>
                  </RowCenterStack>
                  <IconButton
                    sx={{ padding: 0 }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      dispatch(removeSelectedEmployeeRowIds(employee.employeeId));
                    }}
                  >
                    <AssetsSharePeopleIcon width={24} height={24} />
                  </IconButton>
                </RowCenterJustifyBetweenStack>
              );
            })}
            <RowCenterStack gap={1}>
              <PrimaryDarkButton variant="contained" color="primary" onClick={() => setIsDeactivateModalOpen(false)}>
                Cancel
              </PrimaryDarkButton>
              <PrimaryDarkButton variant="contained" color="primary" onClick={handleDeactivateEmailsButtonClick}>
                Deactivate
              </PrimaryDarkButton>
            </RowCenterStack>
          </Stack>
        </StyledModalContent>
      </StyledBlurryModal>
    </Box>
  );
};
