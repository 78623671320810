import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  LeaveAudienceRequest,
  RetrieveAudienceDrawerItemsByEmployeeIdRequest,
  RetrieveAudienceDrawerItemsByEmployeeIdResponse
} from '@thrivea/networking-client';
import { leaveAudience, retrieveAudienceDrawerItemsByEmployeeId } from '@api/audience.api';
import {
  retrieveAudienceDrawerItemsByEmployeeIdSucceeded,
  retrieveAudienceDrawerItemsByEmployeeIdFailed,
  retrieveAudienceDrawerItemsByEmployeeIdRequested,
  retrieveAudienceDrawerEmployeeItemsSucceeded,
  retrieveAudienceDrawerEmployeeItemsFailed,
  retrieveAudienceDrawerEmployeeItemsRequested,
  removeAudienceDrawerItemFailed,
  removeAudienceDrawerItemSucceeded,
  removeAudienceDrawerEmployeeItemSucceeded,
  removeAudienceDrawerEmployeeItemFailed,
  removeAudienceDrawerItemRequested,
  removeAudienceDrawerEmployeeItemRequested
} from '@features/drawer';
import { EmployeeResults, RetrieveEmployeesByIdsRequest } from '@thrivea/organization-client';
import { retrieveEmployeesByIds } from '@api/employees.api';
import { showSuccess } from '@features/snackbar';
import { t } from 'i18next';

function* retrievePostAudienceItemsGenerator(action: PayloadAction<RetrieveAudienceDrawerItemsByEmployeeIdRequest>) {
  try {
    const response: RetrieveAudienceDrawerItemsByEmployeeIdResponse = yield call(retrieveAudienceDrawerItemsByEmployeeId, action.payload);
    yield put(retrieveAudienceDrawerItemsByEmployeeIdSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveAudienceDrawerItemsByEmployeeIdFailed());
  }
}

function* retrieveAudienceDrawerEmployeeItemsRequestedGenerator(action: PayloadAction<RetrieveEmployeesByIdsRequest>) {
  try {
    const response: EmployeeResults = yield call(retrieveEmployeesByIds, action.payload);
    yield put(retrieveAudienceDrawerEmployeeItemsSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveAudienceDrawerEmployeeItemsFailed());
  }
}

function* removeAudienceDrawerItemRequestedGenerator(action: PayloadAction<LeaveAudienceRequest>) {
  try {
    const successMessage = t('audience_left', { ns: 'drawer' });

    yield call(leaveAudience, action.payload);
    yield put(removeAudienceDrawerItemSucceeded(action.payload));
    yield put(showSuccess(successMessage));
  } catch (error) {
    Sentry.captureException(error);
    yield put(removeAudienceDrawerItemFailed);
  }
}

function* removeAudienceDrawerEmployeeItemGenerator(action: PayloadAction<LeaveAudienceRequest>) {
  try {
    const successMessage = t('audience_left', { ns: 'drawer' });

    yield call(leaveAudience, action.payload);
    yield put(removeAudienceDrawerEmployeeItemSucceeded(action.payload));
    yield put(showSuccess(successMessage));
  } catch (error) {
    Sentry.captureException(error);
    yield put(removeAudienceDrawerEmployeeItemFailed);
  }
}

function* retrievePostAudienceItemsWatcher() {
  yield takeLatest(retrieveAudienceDrawerItemsByEmployeeIdRequested.type, retrievePostAudienceItemsGenerator);
}

function* retrieveAudienceDrawerEmployeeItemsRequestedWatcher() {
  yield takeLatest(retrieveAudienceDrawerEmployeeItemsRequested.type, retrieveAudienceDrawerEmployeeItemsRequestedGenerator);
}

function* removeAudienceDrawerItemRequestedWatcher() {
  yield takeLatest(removeAudienceDrawerItemRequested.type, removeAudienceDrawerItemRequestedGenerator);
}

function* removeAudienceDrawerEmployeeItemRequestedWatcher() {
  yield takeLatest(removeAudienceDrawerEmployeeItemRequested.type, removeAudienceDrawerEmployeeItemGenerator);
}

export function* audienceDrawerSagas() {
  yield all([
    fork(retrievePostAudienceItemsWatcher),
    fork(retrieveAudienceDrawerEmployeeItemsRequestedWatcher),
    fork(removeAudienceDrawerItemRequestedWatcher),
    fork(removeAudienceDrawerEmployeeItemRequestedWatcher)
  ]);
}
