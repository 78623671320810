import { useState } from 'react';
import { loadStripe, StripeAddressElementOptions, StripeElementsOptions, StripePaymentElementOptions } from '@stripe/stripe-js';
import { PaymentElement, Elements, useStripe, useElements, AddressElement } from '@stripe/react-stripe-js';
import { Box, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { InfoIcon, RowCenterStack, StyledPrimaryButton } from 'src/shared';
import { useTranslation } from 'react-i18next';

const addressElementOptions: StripeAddressElementOptions = {
  mode: 'billing'
};

const paymentElementOptions: StripePaymentElementOptions = {
  layout: {
    type: 'tabs'
  }
};

const PaymentForm = () => {
  const { t } = useTranslation(['landing_page']);
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState<string | undefined>('');

  // const handleSubmit = async (event) => {
  //     event.preventDefault();

  //     if (elements == null) {
  //         return;
  //     }

  //     // Trigger form validation and wallet collection
  //     const { error: submitError } = await elements.submit();
  //     if (submitError) {
  //         // Show error to your customer
  //         setErrorMessage(submitError.message);
  //         return;
  //     }

  //     // Create the PaymentIntent and obtain clientSecret from your server endpoint
  //     const res = await fetch('/create-intent', {
  //         method: 'POST',
  //     });

  //     const { client_secret: clientSecret } = await res.json();

  //     const { error } = await stripe!.confirmPayment({
  //         //`Elements` instance that was used to create the Payment Element
  //         elements,
  //         clientSecret,
  //         confirmParams: {
  //             return_url: 'https://example.com/order/123/complete',
  //         },
  //     });

  //     if (error) {
  //         // This point will only be reached if there is an immediate error when
  //         // confirming the payment. Show error to your customer (for example, payment
  //         // details incomplete)
  //         setErrorMessage(error.message);
  //     } else {
  //         // Your customer will be redirected to your `return_url`. For some payment
  //         // methods like iDEAL, your customer will be redirected to an intermediate
  //         // site first to authorize the payment, then redirected to the `return_url`.
  //     }
  // };

  const handleSubmit = async (event) => {
    console.log(event);
  };

  return (
    <Stack
      gap={3}
      sx={{
        width: 568,
        height: '100%',
        overflowY: 'scroll',
        overflowX: 'hidden',
        alignItems: 'flex-start'
      }}
    >
      <Typography
        component="h1"
        sx={{
          fontSize: 32,
          fontWeight: 700,
        }}
      >
        {t('add_payment_label', { ns: 'landing_page' })}
      </Typography>
      <Box
        component={'form'}
        onSubmit={handleSubmit}
        sx={{
          width: '100%',
          '& .Input:focus': {
            borderColor: 'red'
          }
        }}
      >
        <Stack gap={3}>
          <Grid container sx={{ width: '100%' }} rowSpacing={'12px'}>
            <Grid size={12}>
              <PaymentElement options={paymentElementOptions} />
            </Grid>
            <Grid size={12}>
              <AddressElement options={addressElementOptions} />
            </Grid>
            <Grid size={12}>
              <RowCenterStack gap={'8px'}>
                <InfoIcon />
                <Typography
                  sx={{
                    fontSize: 10,
                    fontWeight: 700
                  }}
                >
                  {t('payment_note', { ns: 'landing_page' })}
                </Typography>
              </RowCenterStack>
            </Grid>
          </Grid>
          <Box>
            <StyledPrimaryButton
              type="submit"
              variant="contained"
              disabled={!stripe || !elements}
              sx={{
                color: (theme) => theme.palette.common.white
              }}
            >
              {t('payment_btn_text', { ns: 'landing_page' })}
            </StyledPrimaryButton>
          </Box>
        </Stack>
        {/* Show error message to your customers */}
        {errorMessage && <div>{errorMessage}</div>}
      </Box>
    </Stack>
  );
};

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

const options = {
  mode: 'setup',
  currency: 'usd',
  fonts: [
    {
      cssSrc: 'https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200..800;1,200..800&display=swap',
      family: 'Karla',
      style: 'normal',
      weight: '400',
      display: 'swap'
    }
  ],
  // Fully customizable with appearance API.
  appearance: {
    labels: 'floating',
    variables: {
      fontFamily: 'Karla',
      colorText: '#251D38',
      borderRadius: '4px',
      focusBoxShadow: 'none',
      focusOutline: 'none',
      colorTextSecondary: '#251D38',
      fontSize3Xs: '10px',
      colorDanger: '#BF5AC2'
    }
  }
} as StripeElementsOptions;

export const StripePaymentForm = () => (
  <Elements stripe={stripePromise} options={options}>
    <PaymentForm />
  </Elements>
);
