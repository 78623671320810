import React from 'react';

interface RegularPenIconProps {
  width?: number;
  height?: number;
  color?: string;
  opacity?: number;
}

export const RegularPenIcon: React.FC<RegularPenIconProps> = ({ width = 20, height = 20, color = '#251D38', opacity = 1 }) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 8.333 11.667 5M2.083 17.917l2.82-.314c.345-.038.517-.057.678-.11.143-.045.28-.11.405-.194.141-.093.264-.215.509-.46L17.5 5.833A2.357 2.357 0 1 0 14.167 2.5L3.16 13.505c-.245.245-.367.368-.46.51a1.667 1.667 0 0 0-.195.403c-.052.162-.071.334-.11.678l-.313 2.82Z"
        stroke={color}
        strokeWidth="1.667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
