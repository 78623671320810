import { createClient } from '@connectrpc/connect';
import { createGrpcWebTransport } from '@connectrpc/connect-web';
import {
  SiteService,
  RetrieveSiteByIdRequest,
  RetrieveSiteByIdResponse,
  RetrieveSiteListRequest,
  RetrieveSiteListResponse,
  DuplicateSiteRequest,
  DeleteSiteByIdRequest,
  UpdateSiteWorkingPatternRequest,
  UpdateSiteWorkingPatternResponse,
  UpsertSiteNameAndLocationRequest,
  UpsertSiteNameAndLocationResponse,
  DuplicateSiteResponse,
  RetrieveSitesByWorkingPatternIdRequest,
  RetrieveSitesByWorkingPatternIdResponse,
  UpdateSiteCalendarRequest,
  UpdateSiteCalendarResponse,
  UpdateSiteLocalizationRequest,
  UpdateSiteLocalizationResponse,
  RetrieveSiteListItemByIdRequest,
  RetrieveSiteListItemByIdResponse
} from '@thrivea/organization-client';
import { msalInstance } from '@/main';
import { tokenRequest } from '@utils/authConfig';
import { Empty } from '@bufbuild/protobuf';

export const sitesClient = createClient(
  SiteService,
  createGrpcWebTransport({
    baseUrl: import.meta.env.VITE_APP_API_URL,
    useBinaryFormat: true
  })
);

export const retrieveSiteList = async (request: RetrieveSiteListRequest): Promise<RetrieveSiteListResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await sitesClient.retrieveSiteList(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveSiteById = async (request: RetrieveSiteByIdRequest): Promise<RetrieveSiteByIdResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await sitesClient.retrieveSiteById(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const duplicateSite = async (request: DuplicateSiteRequest): Promise<DuplicateSiteResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await sitesClient.duplicateSite(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const deleteSiteById = async (request: DeleteSiteByIdRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await sitesClient.deleteSiteById(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const upsertSiteNameAndLocation = async (request: UpsertSiteNameAndLocationRequest): Promise<UpsertSiteNameAndLocationResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await sitesClient.upsertSiteNameAndLocation(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const upsertSiteLocalization = async (request: UpdateSiteLocalizationRequest): Promise<UpdateSiteLocalizationResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await sitesClient.updateSiteLocalization(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const upsertSiteCalendar = async (request: UpdateSiteCalendarRequest): Promise<UpdateSiteCalendarResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await sitesClient.updateSiteCalendar(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const updateSiteWorkingPattern = async (request: UpdateSiteWorkingPatternRequest): Promise<UpdateSiteWorkingPatternResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await sitesClient.updateSiteWorkingPattern(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveSiteByWorkingPatternId = async (request: RetrieveSitesByWorkingPatternIdRequest): Promise<RetrieveSitesByWorkingPatternIdResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await sitesClient.retrieveSitesByWorkingPatternId(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveSiteListItemById = async (request: RetrieveSiteListItemByIdRequest): Promise<RetrieveSiteListItemByIdResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await sitesClient.retrieveSiteListItemById(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};
