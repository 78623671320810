import { create } from '@bufbuild/protobuf';
import React, { Fragment, memo, useEffect, useRef, useState } from 'react';
import { Box, IconButton, ImageList, ImageListItem, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Quill, { Delta } from 'quill/core';
import 'quill-mention/autoregister';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import {
  closeAssetsModalOnTaskComment,
  CommentButton,
  commentOnTaskRequested,
  removeNewCommentAttachmentUrls,
  selectCommentOnTaskCommentStatus,
  selectIsAssetsModalOpenedOnTaskComment,
  selectNewCommentUrls
} from '@features/tasks';
import {
  ActionStatus,
  CloseIcon,
  DocTypeFileIcon,
  QuillEditor,
  RowCenterJustifyBetweenStack,
  RowCenterStack,
  StyledCommentBox,
  StyledFixedWidthTypography,
  VideoTypeFileIcon
} from '@/shared';
import { removeBomCharacters } from '@utils/removeBomCharacters';
import { v4 as uuidv4 } from 'uuid';
import { TaskNewCommentModal } from 'src/features/drawer';
import { selectMyUploadsReadSasToken } from 'src/features/assets';
import { PostDoc, PostMedia } from '@features/homepage';
import { fetchBlobFileNames } from '@api/blob-storage.api';
import { isImageUrl, isVideoUrl, isDocumentUrl } from '@/utils';
import * as Sentry from '@sentry/react';
import { selectEmployeeListItems } from '@features/shared';
import { CommentOnTaskRequestSchema } from '@thrivea/organization-client';

interface TasksAddCommentProps {
  taskId: string;
  authorId: string;
  shouldGiveReview: boolean;
  setShouldGiveReview: (value: boolean) => void;
}

// eslint-disable-next-line react/display-name
export const TasksAddComment: React.FC<TasksAddCommentProps> = memo(({ taskId, authorId, shouldGiveReview, setShouldGiveReview }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['common', 'tasks']);
  const commentOnTaskStatus = useAppSelector<ActionStatus>(selectCommentOnTaskCommentStatus);
  const [inputValue, setInputValue] = useState('');
  const innerButtonRef = useRef<HTMLButtonElement | null>(null);
  const quillRef = useRef<Quill | null>(null);
  const isAssetsModalOpenedOnTaskComment = useAppSelector(selectIsAssetsModalOpenedOnTaskComment);
  const attachmentUrls = useAppSelector(selectNewCommentUrls);
  const myUploadsReadSasToken = useAppSelector(selectMyUploadsReadSasToken);
  const employeeListItems = useAppSelector(selectEmployeeListItems);

  const [docFileNames, setDocFileNames] = useState<PostDoc[]>([]);
  const [mediaFileNames, setMediaFileNames] = useState<PostMedia[]>([]);

  const mediaUrls = attachmentUrls
    ? attachmentUrls
        .filter((url) => url !== '')
        .filter((url) => isImageUrl(url) || isVideoUrl(url))
        .map((url) => new URL(myUploadsReadSasToken, url).toString())
    : [];

  const docUrls = attachmentUrls
    ? attachmentUrls
        .filter((url) => url !== '')
        .filter((url) => isDocumentUrl(url))
        .map((url) => new URL(myUploadsReadSasToken, url).toString())
    : [];

  useEffect(() => {
    const fetchAttachmentFileNames = async () => {
      try {
        const docFiles = await fetchBlobFileNames(docUrls);
        const mediaFiles = await fetchBlobFileNames(mediaUrls);

        const docFileNames = docFiles.map((name, index) => ({
          name,
          src: docUrls[index],
          alt: name
        }));

        const mediaFileNames = mediaFiles.map((name, index) => ({
          name,
          src: mediaUrls[index],
          alt: name
        }));

        setDocFileNames([...docFileNames]);
        setMediaFileNames([...mediaFileNames]);
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    fetchAttachmentFileNames();
  }, [attachmentUrls]);

  const handleCommentButtonClick = () => {
    dispatch(
      commentOnTaskRequested(
        create(CommentOnTaskRequestSchema, {
          taskId,
          commentId: uuidv4(),
          commentText: removeBomCharacters(inputValue),
          authorId,
          attachmentUrls,
          isImportant: shouldGiveReview
        })
      )
    );
    setShouldGiveReview(false);
    quillRef.current!.setContents(new Delta());
    setInputValue('');
  };

  const handleRemoveFocus = () => {
    const activeElement = document.activeElement as HTMLElement;
    if (activeElement) {
      activeElement.blur();
    }
  };

  const handleTaskCommentModalClose = () => {
    dispatch(closeAssetsModalOnTaskComment());
  };

  const handleNewCommentRemoveUrl = (url: string) => {
    dispatch(removeNewCommentAttachmentUrls(url.split('?sv=')[0]));
  };

  return (
    <Stack gap={1}>
      <StyledCommentBox
        onKeyDown={(event) => {
          if (event.key === 'Escape') {
            handleRemoveFocus();
          }
        }}
      >
        <QuillEditor uploadAttachment={true} setText={setInputValue} ref={quillRef} employeeListItems={employeeListItems} isComment />
        <CommentButton
          loading={commentOnTaskStatus === ActionStatus.Pending}
          ref={innerButtonRef}
          variant="contained"
          color="primary"
          onClick={handleCommentButtonClick}
          disabled={inputValue.trim() === ''}
        >
          {shouldGiveReview ? (
            <Typography sx={{ color: (theme) => theme.palette.common.white }}>{t('leave_review', { ns: 'tasks' })}</Typography>
          ) : (
            <Typography sx={{ color: (theme) => theme.palette.common.white }}>{t('comment', { ns: 'tasks' })}</Typography>
          )}
        </CommentButton>
      </StyledCommentBox>
      {attachmentUrls.length > 0 && (
        <ImageList cols={1} gap={16} sx={{ borderRadius: '7px', p: 1, backgroundColor: (theme) => theme.palette.customTheme.primaryA }}>
          {docFileNames.map((file, index) => (
            <Fragment key={index}>
              <ImageListItem
                sx={{
                  position: 'relative'
                }}
              >
                <RowCenterJustifyBetweenStack
                  gap={1}
                  sx={{
                    '&:hover': {
                      '& > .Mui-DeleteFile': {
                        display: 'flex'
                      }
                    }
                  }}
                >
                  <RowCenterStack
                    gap={1}
                    sx={{
                      width: '80%'
                    }}
                  >
                    <DocTypeFileIcon width={25} height={25} />
                    {file.name}
                  </RowCenterStack>
                  <IconButton
                    className="Mui-DeleteFile"
                    sx={{
                      width: 30,
                      height: 30,
                      zIndex: 1,
                      color: (theme) => theme.palette.common.black,
                      borderRadius: 1,
                      padding: 0
                    }}
                    onClick={() => handleNewCommentRemoveUrl(file.src)}
                  >
                    <CloseIcon />
                  </IconButton>
                </RowCenterJustifyBetweenStack>
              </ImageListItem>
            </Fragment>
          ))}
          {mediaFileNames.map((file, index) => (
            <Fragment key={index}>
              {isImageUrl(file.src) && (
                <ImageListItem
                  key={index}
                  sx={{
                    position: 'relative'
                  }}
                >
                  <RowCenterJustifyBetweenStack
                    sx={{
                      '&:hover': {
                        '& > .Mui-DeleteFile': {
                          display: 'flex'
                        }
                      }
                    }}
                  >
                    <RowCenterStack
                      gap={1}
                      sx={{
                        width: '80%'
                      }}
                    >
                      <Box
                        className="Mui-Media"
                        component="img"
                        src={new URL(myUploadsReadSasToken, file.src).toString()}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: 25,
                          height: 25,
                          backgroundColor: (theme) => theme.palette.grey[100],
                          position: 'relative',
                          borderRadius: 1,
                          border: '1px solid',
                          borderColor: (theme) => theme.palette.common.black
                        }}
                      />
                      <StyledFixedWidthTypography title={file.name}>{file.name}</StyledFixedWidthTypography>
                    </RowCenterStack>
                    <IconButton
                      className="Mui-DeleteFile"
                      sx={{
                        width: 25,
                        height: 25,
                        zIndex: 1,
                        color: (theme) => theme.palette.common.black,
                        borderRadius: 1,
                        padding: 0.5
                      }}
                      onClick={() => handleNewCommentRemoveUrl(file.src)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </RowCenterJustifyBetweenStack>
                </ImageListItem>
              )}
              {isVideoUrl(file.src) && (
                <ImageListItem
                  sx={{
                    position: 'relative'
                  }}
                >
                  <RowCenterJustifyBetweenStack
                    gap={1}
                    sx={{
                      '&:hover': {
                        '& > .Mui-DeleteFile': {
                          display: 'flex'
                        }
                      }
                    }}
                  >
                    <RowCenterStack gap={1}>
                      <VideoTypeFileIcon width={25} height={25} />
                      {file.name}
                    </RowCenterStack>
                    <IconButton
                      className="Mui-DeleteFile"
                      sx={{
                        width: 30,
                        height: 30,
                        zIndex: 1,
                        color: (theme) => theme.palette.common.black,
                        borderRadius: 1,
                        padding: 0
                      }}
                      onClick={() => handleNewCommentRemoveUrl(file.src)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </RowCenterJustifyBetweenStack>
                </ImageListItem>
              )}
            </Fragment>
          ))}
        </ImageList>
      )}
      {isAssetsModalOpenedOnTaskComment && <TaskNewCommentModal isOpen={isAssetsModalOpenedOnTaskComment} onClose={handleTaskCommentModalClose} />}
    </Stack>
  );
});
