import { create } from '@bufbuild/protobuf';
import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import {
  ErbField,
  ErbFieldCategories,
  ErbFieldCategory,
  ErTemplate,
  ErTemplateSection,
  RetrieveDefaultErTemplateSectionRequest,
  RetrieveErbFieldsByCategoryIdRequest,
  RetrieveErbFieldsByCategoryIdResponse,
  RetrieveErTemplateRequest,
  SaveErTemplateSectionRequest,
  DeleteErTemplateSectionRequest,
  ErTemplateField,
  ErbFieldWidth,
  ErTemplateScalarField,
  RetrieveErbFieldTypesResponse,
  ErbFieldTypeAndItsPresets,
  CreateErbScalarFieldRequest,
  ErbFieldTypeOptions,
  AllowedInputChars,
  NumberShortener,
  SeparatorsAndDecimals,
  UrlSource,
  EntityType,
  BaseErbFieldType,
  Case,
  CurrencyOptionsSchema,
  DateTimeOptionsSchema,
  DropdownSelectOptionsSchema,
  EmailAddressOptionsSchema,
  ErbFieldTypeOptionsSchema,
  ErTemplateFieldSchema,
  ErTemplateScalarFieldSchema,
  ErTemplateSectionSchema,
  ErTemplateTableFieldSchema,
  FileOptionsSchema,
  GenericSetLocalizedOptionSchema,
  LinkedEntitiesOptionsSchema,
  MultiLineTextOptionsSchema,
  MultipleCheckBoxesOptionsSchema,
  NumberOptionsSchema,
  PercentOptionsSchema,
  PhoneNumberOptionsSchema,
  SingleCheckboxOptionsSchema,
  SingleLineTextOptionsSchema,
  UrlOptionsSchema
} from '@thrivea/organization-client';
import { RootState } from '@app/store';
import { ActionStatus } from '@/shared';
import { groupBy, orderBy, sortBy, uniq } from 'lodash';

export interface employeeRecordBuilderState {
  entities: {
    erTemplateSections: {
      byInitialId: { [key: string]: ErTemplateSection };
      byUpdatedId: { [key: string]: ErTemplateSection };
      initialIds: string[];
      addedIds: string[];
      removedIds: string[];
      updatedIds: string[];
    };
    erbFieldCategories: {
      byId: { [key: string]: ErbFieldCategory };
      allIds: string[];
    };
    erTemplateFields: {
      byId: { [key: string]: ErTemplateField };
      allIds: string[];
    };
    erbFields: {
      byId: { [key: string]: ErbField };
      allIds: string[];
    };
  };
  ui: {
    retrieveErbFieldCategoriesAndSectionsStatus: ActionStatus;
    retrieveDefaultErTemplateSectionStatus: ActionStatus;
    saveErTemplateSectionStatus: ActionStatus;
    retrieveErTemplateStatus: ActionStatus;
    retrieveErbFieldsByCategoryIdStatus: ActionStatus;
    deleteErbTemplateSectionStatus: ActionStatus;
    erbFieldTypeOptions: Record<BaseErbFieldType, ErbFieldTypeOptions>;
  };
  erTemplate: ErTemplate;
  activeSectionId: string | undefined;
  activeCategoryId: string | undefined;
  erbFieldTypesAndItsPresets: ErbFieldTypeAndItsPresets[];
  activeErbFieldTypeOptionsKey: BaseErbFieldType;
}

const initialState: employeeRecordBuilderState = {
  entities: {
    erTemplateSections: {
      byInitialId: {},
      byUpdatedId: {},
      initialIds: [],
      addedIds: [],
      removedIds: [],
      updatedIds: []
    },
    erbFieldCategories: {
      byId: {},
      allIds: []
    },
    erTemplateFields: {
      byId: {},
      allIds: []
    },
    erbFields: {
      byId: {},
      allIds: []
    }
  },
  ui: {
    retrieveErbFieldCategoriesAndSectionsStatus: ActionStatus.Idle,
    retrieveDefaultErTemplateSectionStatus: ActionStatus.Idle,
    saveErTemplateSectionStatus: ActionStatus.Idle,
    retrieveErTemplateStatus: ActionStatus.Idle,
    retrieveErbFieldsByCategoryIdStatus: ActionStatus.Idle,
    deleteErbTemplateSectionStatus: ActionStatus.Idle,
    erbFieldTypeOptions: {
      [BaseErbFieldType.SINGLE_LINE_TEXT]: create(ErbFieldTypeOptionsSchema, {
        kind: {
          case: 'singleLineText',
          value: create(SingleLineTextOptionsSchema, {
            allowSpecialCharacters: false,
            case: Case.TITLE_CASE,
            input: AllowedInputChars.TEXT_AND_NUMBERS
          })
        }
      }),
      [BaseErbFieldType.MULTI_LINE_TEXT]: create(ErbFieldTypeOptionsSchema, {
        kind: {
          case: 'multiLineText',
          value: create(MultiLineTextOptionsSchema, {
            characterLimit: 1000
          })
        }
      }),
      [BaseErbFieldType.NUMBER]: create(ErbFieldTypeOptionsSchema, {
        kind: {
          case: 'number',
          value: create(NumberOptionsSchema, {
            allowNegative: false,
            decimalPrecision: 0,
            numberShortener: NumberShortener.NUMBER_SHORTENER_UNSPECIFIED,
            separatorsAndDecimals: SeparatorsAndDecimals.SEPARATORS_AND_DECIMALS_UNDEFINED
          })
        }
      }),
      [BaseErbFieldType.PERCENT]: create(ErbFieldTypeOptionsSchema, {
        kind: {
          case: 'percent',
          value: create(PercentOptionsSchema, {
            allowNegative: false,
            decimalPrecision: 0,
            numberShortener: NumberShortener.NUMBER_SHORTENER_UNSPECIFIED,
            separatorsAndDecimals: SeparatorsAndDecimals.SEPARATORS_AND_DECIMALS_UNDEFINED
          })
        }
      }),
      [BaseErbFieldType.CURRENCY]: create(ErbFieldTypeOptionsSchema, {
        kind: {
          case: 'currency',
          value: create(CurrencyOptionsSchema, {
            allowNegative: false,
            decimalPrecision: 0,
            numberShortener: NumberShortener.NUMBER_SHORTENER_UNSPECIFIED,
            currencySymbol: 'USD',
            separatorAndDecimal: SeparatorsAndDecimals.SEPARATORS_AND_DECIMALS_UNDEFINED
          })
        }
      }),
      [BaseErbFieldType.DROPDOWN_SELECT]: create(ErbFieldTypeOptionsSchema, {
        kind: {
          case: 'dropdownSelect',
          value: create(DropdownSelectOptionsSchema, {
            options: [create(GenericSetLocalizedOptionSchema, { key: 'option1', translations: { ['en']: 'Option1' } })]
          })
        }
      }),
      [BaseErbFieldType.MULTIPLE_CHECKBOXES]: create(ErbFieldTypeOptionsSchema, {
        kind: {
          case: 'multipleCheckboxes',
          value: create(MultipleCheckBoxesOptionsSchema, {
            options: [create(GenericSetLocalizedOptionSchema, { key: 'option1', translations: { ['en']: 'Option1' } })]
          })
        }
      }),
      [BaseErbFieldType.SINGLE_CHECKBOX]: create(ErbFieldTypeOptionsSchema, {
        kind: {
          case: 'singleCheckbox',
          value: create(SingleCheckboxOptionsSchema, {
            label: 'Checkbox',
            showLabel: true
          })
        }
      }),
      [BaseErbFieldType.FILE]: create(ErbFieldTypeOptionsSchema, {
        kind: {
          case: 'file',
          value: create(FileOptionsSchema, {
            displaySize: true,
            fileTypes: ['pdf', 'doc', 'docx']
          })
        }
      }),
      [BaseErbFieldType.EMAIL_ADDRESS]: create(ErbFieldTypeOptionsSchema, {
        kind: {
          case: 'emailAddress',
          value: create(EmailAddressOptionsSchema, {
            allowOnClickOpenInEmailClient: false,
            allowOnlyDomainAddresses: false,
            domains: []
          })
        }
      }),
      [BaseErbFieldType.DATE_TIME]: create(ErbFieldTypeOptionsSchema, {
        kind: {
          case: 'dateTime',
          value: create(DateTimeOptionsSchema, {})
        }
      }),
      [BaseErbFieldType.URL]: create(ErbFieldTypeOptionsSchema, {
        kind: {
          case: 'url',
          value: create(UrlOptionsSchema, {
            urlSource: UrlSource.URL_SOURCE_UNSPECIFIED
          })
        }
      }),
      [BaseErbFieldType.LINKED_ENTITIES]: create(ErbFieldTypeOptionsSchema, {
        kind: {
          case: 'linkedEntities',
          value: create(LinkedEntitiesOptionsSchema, {
            entityType: EntityType.PERSON
          })
        }
      }),
      [BaseErbFieldType.PHONE_NUMBER]: create(ErbFieldTypeOptionsSchema, {
        kind: {
          case: 'phoneNumber',
          value: create(PhoneNumberOptionsSchema, {
            enableAreaCode: true,
            enableCountryCode: true
          })
        }
      }),
      [BaseErbFieldType.BASE_ERB_FIELD_TYPE_UNSPECIFIED]: create(ErbFieldTypeOptionsSchema, {})
    }
  },
  erTemplate: {} as ErTemplate,
  activeSectionId: undefined,
  activeCategoryId: undefined,
  erbFieldTypesAndItsPresets: [],
  activeErbFieldTypeOptionsKey: BaseErbFieldType.SINGLE_LINE_TEXT
};

export const employeeRecordBuilderBuilderSlice = createSlice({
  name: 'employeeRecordBuilder',
  initialState,
  reducers: {
    changeErbFieldType: (state, action: PayloadAction<BaseErbFieldType>) => {
      state.activeErbFieldTypeOptionsKey = action.payload;
    },
    retrieveErbFieldCategoriesAndSectionsRequested: (state) => {
      state.ui.retrieveErbFieldCategoriesAndSectionsStatus = ActionStatus.Pending;
    },
    retrieveErbFieldCategoriesAndSectionsSucceeded: (state, action: PayloadAction<ErbFieldCategories>) => {
      const { categories } = action.payload;

      for (const category of categories) {
        if (!state.entities.erbFieldCategories.byId.hasOwnProperty(category.id)) {
          state.entities.erbFieldCategories.byId[category.id] = category;
          state.entities.erbFieldCategories.allIds.push(category.id);
        }
      }

      state.ui.retrieveErbFieldCategoriesAndSectionsStatus = ActionStatus.Idle;
    },
    retrieveErbFieldCategoriesAndSectionsFailed: (state) => {
      state.ui.retrieveErbFieldCategoriesAndSectionsStatus = ActionStatus.Failed;
    },
    retrieveDefaultErTemplateSectionRequested: (state, action: PayloadAction<RetrieveDefaultErTemplateSectionRequest>) => {
      state.ui.retrieveDefaultErTemplateSectionStatus = ActionStatus.Pending;
    },
    retrieveDefaultErTemplateSectionSucceeded: (state, action: PayloadAction<ErTemplateSection>) => {
      const position =
        state.entities.erTemplateSections.initialIds.length +
        state.entities.erTemplateSections.addedIds.length -
        state.entities.erTemplateSections.removedIds.length +
        1;

      state.entities.erTemplateSections.byUpdatedId[action.payload.id] = create(ErTemplateSectionSchema, {
        ...action.payload,
        position
      });

      state.entities.erTemplateSections.addedIds.push(action.payload.id);
      state.ui.retrieveDefaultErTemplateSectionStatus = ActionStatus.Idle;
    },
    retrieveDefaultErTemplateSectionFailed: (state) => {
      state.ui.retrieveDefaultErTemplateSectionStatus = ActionStatus.Failed;
    },
    saveErTemplateSectionRequested: (state, _action: PayloadAction<SaveErTemplateSectionRequest>) => {
      state.ui.saveErTemplateSectionStatus = ActionStatus.Pending;
    },
    saveErTemplateSectionSucceeded: (state) => {
      state.ui.saveErTemplateSectionStatus = ActionStatus.Idle;
    },
    saveErTemplateSectionFailed: (state) => {
      state.ui.saveErTemplateSectionStatus = ActionStatus.Failed;
    },
    retrieveErTemplateRequested: (state, _action: PayloadAction<RetrieveErTemplateRequest>) => {
      state.ui.retrieveErTemplateStatus = ActionStatus.Pending;
    },
    retrieveErTemplateSucceeded: (state, action: PayloadAction<ErTemplate>) => {
      state.erTemplate = action.payload;
      for (const section of action.payload.sections) {
        state.entities.erTemplateSections.byInitialId[section.id] = section;
        state.entities.erTemplateSections.byUpdatedId[section.id] = section;
        state.entities.erTemplateSections.initialIds.push(section.id);
        for (const field of section.fields) {
          state.entities.erTemplateFields.byId[field.kind.value!.id] = field;
          state.entities.erTemplateFields.allIds.push(field.kind.value!.id);
        }
      }
      state.ui.retrieveErTemplateStatus = ActionStatus.Idle;
    },
    retrieveErTemplateFailed: (state) => {
      state.ui.retrieveErTemplateStatus = ActionStatus.Failed;
    },
    retrieveErbFieldsByCategoryIdRequested: (
      state,
      action: PayloadAction<{
        request: RetrieveErbFieldsByCategoryIdRequest;
        sectionId?: string;
      }>
    ) => {
      state.ui.retrieveErbFieldsByCategoryIdStatus = ActionStatus.Pending;
      state.activeCategoryId = action.payload.request.erbCategoryId;

      state.entities.erbFields.byId = {};
      state.entities.erbFields.allIds = [];
      if (action.payload.sectionId) {
        state.activeSectionId = action.payload.sectionId;
      }
    },
    retrieveErbFieldsByCategoryIdSucceeded: (state, action: PayloadAction<RetrieveErbFieldsByCategoryIdResponse>) => {
      const { erbFields } = action.payload;

      for (const field of erbFields) {
        if (field.kind.value) {
          state.entities.erbFields.byId[field.kind.value!.id] = field;
          state.entities.erbFields.allIds.push(field.kind.value!.id);
        }
      }

      state.ui.retrieveErbFieldsByCategoryIdStatus = ActionStatus.Idle;
    },
    retrieveErbFieldsByCategoryIdFailed: (state) => {
      state.ui.retrieveErbFieldsByCategoryIdStatus = ActionStatus.Failed;
    },
    deleteErbTemplateSectionRequested: (state, action: PayloadAction<DeleteErTemplateSectionRequest>) => {
      state.ui.deleteErbTemplateSectionStatus = ActionStatus.Pending;
    },
    deleteErbTemplateSectionSucceeded: (state, action: PayloadAction<string>) => {
      state.ui.deleteErbTemplateSectionStatus = ActionStatus.Idle;
      state.entities.erTemplateSections.removedIds.push(action.payload);
      delete state.entities.erTemplateSections.byUpdatedId[action.payload];
    },
    deleteErbTemplateSectionFailed: (state) => {
      state.ui.deleteErbTemplateSectionStatus = ActionStatus.Failed;
    },
    addFieldToSection: (state, action: PayloadAction<ErbField>) => {
      const field = action.payload;
      let erTemplateField;
      if (field.kind.case === 'scalarField') {
        erTemplateField = create(ErTemplateFieldSchema, {
          kind: {
            value: create(ErTemplateScalarFieldSchema, {
              id: crypto.randomUUID(),
              erbScalarField: field.kind.value,
              name: field.kind.value.name,
              width: ErbFieldWidth.THIRD,
              position: state.entities.erTemplateSections.byUpdatedId[state.activeSectionId!].fields.length + 1
            }),
            case: 'scalarField'
          }
        });
      } else {
        erTemplateField = create(ErTemplateFieldSchema, {
          kind: {
            value: create(ErTemplateTableFieldSchema, {
              erbTableField: field.kind.value,
              id: crypto.randomUUID(),
              name: field.kind.value!.name,
              position: state.entities.erTemplateSections.byUpdatedId[state.activeSectionId!].fields.length + 1
            }),
            case: 'tableField'
          }
        });
      }

      state.entities.erTemplateSections.byUpdatedId = {
        ...state.entities.erTemplateSections.byUpdatedId,
        [state.activeSectionId!]: {
          ...state.entities.erTemplateSections.byUpdatedId[state.activeSectionId!],
          fields: [...state.entities.erTemplateSections.byUpdatedId[state.activeSectionId!].fields, erTemplateField]
        }
      };
    },
    changeFieldWidth: (state, action: PayloadAction<{ fieldId: string; width: ErbFieldWidth }>) => {
      const { fieldId, width } = action.payload;

      for (const field of state.entities.erTemplateSections.byUpdatedId[state.activeSectionId!].fields) {
        switch (field.kind.case) {
          case 'scalarField': {
            if (field.kind.value.id === fieldId) {
              field.kind.value.width = width;
            }

            break;
          }
          case 'tableField': {
            break;
          }
        }
      }

      state.entities.erTemplateSections.byUpdatedId[state.activeSectionId!] = {
        ...state.entities.erTemplateSections.byUpdatedId[state.activeSectionId!]
      };

      if (!state.entities.erTemplateSections.updatedIds.includes(state.activeSectionId!)) {
        state.entities.erTemplateSections.updatedIds.push(state.activeSectionId!);
      }
    },
    reorderActiveSectionFieldsIds: (state, action: PayloadAction<string[]>) => {
      const fields: ErTemplateField[] = [];

      for (const [index, fieldId] of action.payload.entries()) {
        const field: ErTemplateField = create(ErTemplateFieldSchema, {
          ...state.entities.erTemplateSections.byUpdatedId[state.activeSectionId!].fields.find((field) => field.kind.value!.id === fieldId)!
        });
        field.kind.value!.position = index + 1;

        fields.push(field);
      }

      state.entities.erTemplateSections.byUpdatedId[state.activeSectionId!] = {
        ...state.entities.erTemplateSections.byUpdatedId[state.activeSectionId!],
        fields
      };

      if (!state.entities.erTemplateSections.updatedIds.includes(state.activeSectionId!)) {
        state.entities.erTemplateSections.updatedIds.push(state.activeSectionId!);
      }
    },
    removeFieldFromSection: (state, action: PayloadAction<string>) => {
      const fieldId = action.payload;

      state.entities.erTemplateSections.byUpdatedId[state.activeSectionId!] = {
        ...state.entities.erTemplateSections.byUpdatedId[state.activeSectionId!],
        fields: state.entities.erTemplateSections.byUpdatedId[state.activeSectionId!].fields.filter((field) => field.kind.value!.id !== fieldId)
      };

      if (!state.entities.erTemplateSections.updatedIds.includes(state.activeSectionId!)) {
        state.entities.erTemplateSections.updatedIds.push(state.activeSectionId!);
      }
    },
    cancelEditSection: (state) => {
      if (state.entities.erTemplateSections.initialIds.includes(state.activeSectionId!)) {
        state.entities.erTemplateSections.byUpdatedId[state.activeSectionId!] = state.entities.erTemplateSections.byInitialId[state.activeSectionId!];
      } else {
        state.entities.erTemplateSections.removedIds.push(state.activeSectionId!);
      }

      state.activeSectionId = undefined;
    },
    setActiveSectionId: (state, action: PayloadAction<string | undefined>) => {
      state.activeSectionId = action.payload;
    },
    retrieveErbFieldTypesRequested: (state) => {},
    retrieveErbFieldTypesSucceeded: (state, action: PayloadAction<RetrieveErbFieldTypesResponse>) => {
      const { typesAndTheirPresets } = action.payload;

      for (const erbFieldType of typesAndTheirPresets) {
        if (!state.erbFieldTypesAndItsPresets.hasOwnProperty(erbFieldType.genericType)) {
          state.erbFieldTypesAndItsPresets.push(erbFieldType);
        }
      }
    },
    retrieveErbFieldTypesFailed: (state) => {},
    createErbScalarFieldRequested: (state, action: PayloadAction<CreateErbScalarFieldRequest>) => {},
    createErbScalarFieldSucceeded: (state) => {},
    createErbScalarFieldFailed: (state) => {},
    setActiveErbFieldTypeOptions: (state, action: PayloadAction<ErbFieldTypeOptions>) => {
      state.ui.erbFieldTypeOptions[state.activeErbFieldTypeOptionsKey] = action.payload;
    }
  }
});

export const selectErbFieldById = (state: RootState) => state.employeeRecordBuilder.entities.erbFields.byId;
export const selectErbFieldIds = (state: RootState) => state.employeeRecordBuilder.entities.erbFields.allIds;
export const selectErbFields = createSelector([selectErbFieldById, selectErbFieldIds], (byId, ids) => ids.map((id) => byId[id]));

export const selectRetrieveErbFieldsBySectionIdStatus = (state: RootState) => state.employeeRecordBuilder.ui.retrieveErbFieldsByCategoryIdStatus;

export const selectRetrieveDefaultErTemplateSectionStatus = (state: RootState) => state.employeeRecordBuilder.ui.retrieveDefaultErTemplateSectionStatus;

const selectErTemplate = (state: RootState) => state.employeeRecordBuilder.erTemplate;
export const selectOrderedErTemplateSections = createSelector([selectErTemplate], (template) => orderBy(template.sections, ['position'], ['asc']));

export const selectErTemplateSectionsByInitialId = (state: RootState) => state.employeeRecordBuilder.entities.erTemplateSections.byInitialId;
export const selectErTemplateSectionsByUpdatedId = (state: RootState) => state.employeeRecordBuilder.entities.erTemplateSections.byUpdatedId;
export const selectErTemplateSectionsInitialIds = (state: RootState) => state.employeeRecordBuilder.entities.erTemplateSections.initialIds;
export const selectErTemplateSectionsRemovedIds = (state: RootState) => state.employeeRecordBuilder.entities.erTemplateSections.removedIds;
export const selectErTemplateSectionsAddedIds = (state: RootState) => state.employeeRecordBuilder.entities.erTemplateSections.addedIds;
export const selectErTemplateSectionsUpdatedIds = (state: RootState) => state.employeeRecordBuilder.entities.erTemplateSections.updatedIds;

export const selectErTemplateSectionIds = createSelector(
  [selectErTemplateSectionsInitialIds, selectErTemplateSectionsAddedIds, selectErTemplateSectionsRemovedIds],
  (initialIds, addedIds, removedIds) => [...initialIds, ...addedIds].filter((id) => !removedIds.includes(id))
);

export const selectErbScalarFieldsBySectionId = createSelector(
  [selectErTemplateSectionsByUpdatedId, (_, sectionId: string) => sectionId],
  (byUpdatedId, sectionId) =>
    byUpdatedId[sectionId].fields
      .filter((field) => field.kind.case === 'scalarField')
      .map((field) => field.kind.value as ErTemplateScalarField)
      .map((field) => field.erbScalarField)
);

export const selectErbFieldsBySectionId = createSelector([selectErTemplateSectionsByUpdatedId, (_, sectionId: string) => sectionId], (byUpdatedId, sectionId) =>
  byUpdatedId[sectionId].fields.map((field) => field.kind.value as ErTemplateScalarField).map((field) => field.erbScalarField)
);

export const selectErTemplateSections = createSelector(
  [
    selectErTemplateSectionsInitialIds,
    selectErTemplateSectionsAddedIds,
    selectErTemplateSectionsRemovedIds,
    selectErTemplateSectionsUpdatedIds,
    selectErTemplateSectionsByUpdatedId
  ],
  (initialIds, addedIds, removedIds, updatedIds, byUpdatedId) => {
    return sortBy(uniq([...initialIds, ...addedIds, ...updatedIds].filter((id) => !removedIds.includes(id))), (id) => byUpdatedId[id].position).map(
      (id) => byUpdatedId[id]
    );
  }
);

export const selectErTemplateSectionsGroupedByCategoryId = createSelector([selectErTemplateSections], (sections) => groupBy(sections, 'erbFieldCategoryId'));

export const selectRetrieveErTemplateStatus = (state: RootState) => state.employeeRecordBuilder.ui.retrieveErTemplateStatus;

export const selectSaveErTemplateSectionStatus = (state: RootState) => state.employeeRecordBuilder.ui.saveErTemplateSectionStatus;
export const selectDeleteErbTemplateSectionRequested = (state: RootState) => state.employeeRecordBuilder.ui.deleteErbTemplateSectionStatus;

export const selectActiveSectionId = (state: RootState) => state.employeeRecordBuilder.activeSectionId;
export const selectActiveSectionFields = (state: RootState) =>
  state.employeeRecordBuilder.entities.erTemplateSections.byUpdatedId[state.employeeRecordBuilder.activeSectionId!].fields;
export const selectActiveSectionErbSectionId = (state: RootState) =>
  state.employeeRecordBuilder.entities.erTemplateSections.byUpdatedId[state.employeeRecordBuilder.activeSectionId!].erbFieldSectionId;

export const selectActiveSectionFieldsIds = (state: RootState) =>
  state.employeeRecordBuilder.entities.erTemplateSections.byUpdatedId[state.employeeRecordBuilder.activeSectionId!].fields.map((field) => field.kind.value!.id);
export const selectActiveSectionScalarFieldsIds = (state: RootState) =>
  state.employeeRecordBuilder.entities.erTemplateSections.byUpdatedId[state.employeeRecordBuilder.activeSectionId!].fields.map(
    (field) => (field.kind.case === 'scalarField' && field.kind.value.erbScalarField!.id) as string
  );

export const selectActiveSectionDraggingFieldId = createSelector([selectActiveSectionFieldsIds, (_, activeItemId) => activeItemId], (fieldIds, activeItemId) =>
  fieldIds.find((id) => id === activeItemId)
);

export const selectUnassignedFields = createSelector(
  [selectErbFieldById, selectErbFieldIds, selectActiveSectionScalarFieldsIds],
  (erbFieldById, erbFieldIds, activeSectionFieldIds) => erbFieldIds.filter((id) => !activeSectionFieldIds.includes(id)).map((id) => erbFieldById[id])
);

export const selectAssignedFields = createSelector(
  [selectErbFieldById, selectErbFieldIds, selectActiveSectionScalarFieldsIds],
  (erbFieldById, erbFieldIds, activeSectionFieldIds) => erbFieldIds.filter((id) => activeSectionFieldIds.includes(id)).map((id) => erbFieldById[id])
);

export const selectErbFieldCategoriesById = (state: RootState) => state.employeeRecordBuilder.entities.erbFieldCategories.byId;
export const selectErbFieldCategoriesIds = (state: RootState) => state.employeeRecordBuilder.entities.erbFieldCategories.allIds;
export const selectErbFieldCategoriesStatus = (state: RootState) => state.employeeRecordBuilder.ui.retrieveErbFieldCategoriesAndSectionsStatus;
export const selectErbFieldCategories = createSelector([selectErbFieldCategoriesById, selectErbFieldCategoriesIds], (byId, ids) => ids.map((id) => byId[id]));
export const selectErbFieldSectionsByCategoryId = (state: RootState, categoryId: string) => {
  if (state.employeeRecordBuilder.entities.erbFieldCategories.byId.hasOwnProperty(categoryId))
    return state.employeeRecordBuilder.entities.erbFieldCategories.byId[categoryId].sections;

  return [];
};

export const selectErbFieldCategoriesWithActiveSections = createSelector(
  [selectErTemplateSectionsGroupedByCategoryId, selectErbFieldCategories],
  (sectionsGroupedByCategory, categories) =>
    categories
      .map((c) => ({
        categoryId: c.id,
        categoryName: c.name,
        sections: sectionsGroupedByCategory[c.id] || []
      }))
      .filter((c) => c.sections.length > 0)
);

export const selectEmployeeRecordBuilderActiveCategory = (state: RootState) =>
  state.employeeRecordBuilder.entities.erbFieldCategories.byId[state.employeeRecordBuilder.activeCategoryId!];

export const selectEmployeeRecordBuilderActiveSection = (state: RootState) =>
  state.employeeRecordBuilder.entities.erTemplateSections.byUpdatedId[state.employeeRecordBuilder.activeSectionId!];

export const selectErbFieldIdsInTemplate = createSelector([selectErTemplateSections], (erTemplateSections) =>
  erTemplateSections.flatMap((s) =>
    s.fields.flatMap((f) => {
      if (f.kind.case === 'scalarField') return f.kind.value!.erbScalarField!.id;
      else return f.kind.value!.erbTableField!.id;
    })
  )
);

export const selectErbFieldTypesAndItsPresets = (state: RootState) => state.employeeRecordBuilder.erbFieldTypesAndItsPresets;

export const selectErbFieldPresetsByErbFieldType = createSelector(
  [selectErbFieldTypesAndItsPresets, (_: RootState, fieldType) => fieldType],
  (erbFieldTypesAndItsPresets, fieldType) => erbFieldTypesAndItsPresets.filter((field) => field.genericType === fieldType).map((field) => field.presets)
);

export const selectActiveErbFieldTypeOptionsKey = (state: RootState) => state.employeeRecordBuilder.activeErbFieldTypeOptionsKey;

export const selectActiveErbFieldTypeOptions = (state: RootState) =>
  state.employeeRecordBuilder.ui.erbFieldTypeOptions[state.employeeRecordBuilder.activeErbFieldTypeOptionsKey];

export const selectErTemplateFieldsById = (state: RootState) => state.employeeRecordBuilder.entities.erTemplateFields.byId;
export const selectErTemplateFieldsAllIds = (state: RootState) => state.employeeRecordBuilder.entities.erTemplateFields.allIds;

export const {
  changeErbFieldType,
  retrieveErbFieldCategoriesAndSectionsRequested,
  retrieveErbFieldCategoriesAndSectionsSucceeded,
  retrieveErbFieldCategoriesAndSectionsFailed,
  retrieveDefaultErTemplateSectionRequested,
  retrieveDefaultErTemplateSectionSucceeded,
  retrieveDefaultErTemplateSectionFailed,
  saveErTemplateSectionRequested,
  saveErTemplateSectionSucceeded,
  saveErTemplateSectionFailed,
  retrieveErTemplateRequested,
  retrieveErTemplateSucceeded,
  retrieveErTemplateFailed,
  retrieveErbFieldsByCategoryIdRequested,
  retrieveErbFieldsByCategoryIdSucceeded,
  retrieveErbFieldsByCategoryIdFailed,
  deleteErbTemplateSectionRequested,
  deleteErbTemplateSectionSucceeded,
  deleteErbTemplateSectionFailed,
  addFieldToSection,
  reorderActiveSectionFieldsIds,
  removeFieldFromSection,
  changeFieldWidth,
  setActiveSectionId,
  cancelEditSection,
  retrieveErbFieldTypesRequested,
  retrieveErbFieldTypesSucceeded,
  retrieveErbFieldTypesFailed,
  createErbScalarFieldRequested,
  createErbScalarFieldSucceeded,
  createErbScalarFieldFailed,
  setActiveErbFieldTypeOptions
} = employeeRecordBuilderBuilderSlice.actions;
export default employeeRecordBuilderBuilderSlice.reducer;
