import { styled } from '@mui/material/styles';
import { Stack } from '@mui/material';

export const EditableContainerStack = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'isEditable'
})<{ isEditable: boolean }>(({ isEditable, theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  display: isEditable ? 'flex' : 'none',
  gap: theme.spacing(2)
}));
