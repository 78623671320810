import React from 'react';

const Used = ({ width, height }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 14 14" fill="none">
    <path
      d="M0.291016 9.11091C0.291016 9.78721 0.304149 10.3897 0.369814 10.901C0.441608 11.4634 0.616725 12.2892 1.27513 12.9755C1.93442 13.6618 2.72941 13.8432 3.26961 13.918C3.86322 14 4.5698 14 5.38755 14H8.19623C9.01398 14 9.72056 14 10.3124 13.918C10.8526 13.8432 11.6485 13.6637 12.3069 12.9755C12.9662 12.2892 13.1387 11.4616 13.2122 10.8992C13.2796 10.3861 13.2884 9.78632 13.291 9.10909V9.09998V9.09086V8.69438V5.3038V4.90732V4.8982V4.88909C13.291 4.21279 13.2779 3.6103 13.2122 3.09897C13.1404 2.53659 12.9653 1.71082 12.3069 1.02449C11.6476 0.338162 10.8526 0.156768 10.3124 0.0820307C9.71881 -3.05972e-07 9.01223 0 8.19448 0H5.3858C4.56805 0 3.86147 -3.05972e-07 3.26961 0.0820307C2.72939 0.15677 1.93354 0.336318 1.27513 1.02449C0.615851 1.71082 0.443376 2.53843 0.369814 3.10079C0.302398 3.61394 0.293643 4.21368 0.291016 4.89091V4.90002V4.90914V5.30562V8.6962V9.09268V9.1018V9.11091ZM6.11863 5.6018V8.40181H1.63585V5.6018H6.11863ZM11.9462 8.40181H7.46346V5.6018H11.9462V8.40181ZM3.44913 12.5316C2.88179 12.4523 2.51667 12.2891 2.22776 11.9884C1.93882 11.6876 1.7821 11.3048 1.70594 10.7169C1.67267 10.4535 1.65428 10.1454 1.64728 9.8045H6.12132V12.6045H5.39024C4.62151 12.6045 3.95611 12.6045 3.4518 12.5343L3.44913 12.5316ZM11.3545 11.9884C11.063 12.2919 10.6979 12.4523 10.1331 12.5316C9.62883 12.6018 8.96517 12.6018 8.1947 12.6018H7.46362V9.80176H11.9377C11.9289 10.1445 11.9131 10.4507 11.879 10.7141C11.8028 11.302 11.6461 11.6821 11.3572 11.9856L11.3545 11.9884ZM10.1331 1.47456C10.7005 1.55385 11.0656 1.71702 11.3545 2.01778C11.6461 2.3213 11.8028 2.70138 11.8763 3.28927C11.9096 3.55268 11.928 3.86075 11.935 4.20165H7.46096V1.40164H8.19203C8.96076 1.40164 9.62616 1.40164 10.1305 1.47182L10.1331 1.47456ZM2.22776 2.01778C2.51932 1.71426 2.88442 1.55384 3.44913 1.47456C3.95345 1.40438 4.6171 1.40438 5.38758 1.40438H6.11865V4.20439H1.64461C1.65337 3.86167 1.66913 3.55544 1.70328 3.292C1.77945 2.70139 1.93618 2.32129 2.22509 2.02052L2.22776 2.01778Z"
      fill="#D5E0E8"
    />
  </svg>
);

const PresentInOtherSection = ({ width, height }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 14 14" fill="none">
    <path
      d="M0.291016 9.11091C0.291016 9.78721 0.304149 10.3897 0.369814 10.901C0.441608 11.4634 0.616725 12.2892 1.27513 12.9755C1.93442 13.6618 2.72941 13.8432 3.26961 13.918C3.86322 14 4.5698 14 5.38755 14H8.19623C9.01398 14 9.72056 14 10.3124 13.918C10.8526 13.8432 11.6485 13.6637 12.3069 12.9755C12.9662 12.2892 13.1387 11.4616 13.2122 10.8992C13.2796 10.3861 13.2884 9.78632 13.291 9.10909V9.09998V9.09086V8.69438V5.3038V4.90732V4.8982V4.88909C13.291 4.21279 13.2779 3.6103 13.2122 3.09897C13.1404 2.53659 12.9653 1.71082 12.3069 1.02449C11.6476 0.338162 10.8526 0.156768 10.3124 0.0820307C9.71881 -3.05972e-07 9.01223 0 8.19448 0H5.3858C4.56805 0 3.86147 -3.05972e-07 3.26961 0.0820307C2.72939 0.15677 1.93354 0.336318 1.27513 1.02449C0.615851 1.71082 0.443376 2.53843 0.369814 3.10079C0.302398 3.61394 0.293643 4.21368 0.291016 4.89091V4.90002V4.90914V5.30562V8.6962V9.09268V9.1018V9.11091ZM6.11863 5.6018V8.40181H1.63585V5.6018H6.11863ZM11.9462 8.40181H7.46346V5.6018H11.9462V8.40181ZM3.44913 12.5316C2.88179 12.4523 2.51667 12.2891 2.22776 11.9884C1.93882 11.6876 1.7821 11.3048 1.70594 10.7169C1.67267 10.4535 1.65428 10.1454 1.64728 9.8045H6.12132V12.6045H5.39024C4.62151 12.6045 3.95611 12.6045 3.4518 12.5343L3.44913 12.5316ZM11.3545 11.9884C11.063 12.2919 10.6979 12.4523 10.1331 12.5316C9.62883 12.6018 8.96517 12.6018 8.1947 12.6018H7.46362V9.80176H11.9377C11.9289 10.1445 11.9131 10.4507 11.879 10.7141C11.8028 11.302 11.6461 11.6821 11.3572 11.9856L11.3545 11.9884ZM10.1331 1.47456C10.7005 1.55385 11.0656 1.71702 11.3545 2.01778C11.6461 2.3213 11.8028 2.70138 11.8763 3.28927C11.9096 3.55268 11.928 3.86075 11.935 4.20165H7.46096V1.40164H8.19203C8.96076 1.40164 9.62616 1.40164 10.1305 1.47182L10.1331 1.47456ZM2.22776 2.01778C2.51932 1.71426 2.88442 1.55384 3.44913 1.47456C3.95345 1.40438 4.6171 1.40438 5.38758 1.40438H6.11865V4.20439H1.64461C1.65337 3.86167 1.66913 3.55544 1.70328 3.292C1.77945 2.70139 1.93618 2.32129 2.22509 2.02052L2.22776 2.01778Z"
      fill="#251D38"
    />
  </svg>
);

const Unused = ({ width, height }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 14 14" fill="none">
    <path
      d="M0.291016 9.11091C0.291016 9.78721 0.304149 10.3897 0.369814 10.901C0.441608 11.4634 0.616725 12.2892 1.27513 12.9755C1.93442 13.6618 2.72941 13.8432 3.26961 13.918C3.86322 14 4.5698 14 5.38755 14H8.19623C9.01398 14 9.72056 14 10.3124 13.918C10.8526 13.8432 11.6485 13.6637 12.3069 12.9755C12.9662 12.2892 13.1387 11.4616 13.2122 10.8992C13.2796 10.3861 13.2884 9.78632 13.291 9.10909V9.09998V9.09086V8.69438V5.3038V4.90732V4.8982V4.88909C13.291 4.21279 13.2779 3.6103 13.2122 3.09897C13.1404 2.53659 12.9653 1.71082 12.3069 1.02449C11.6476 0.338162 10.8526 0.156768 10.3124 0.0820307C9.71881 -3.05972e-07 9.01223 0 8.19448 0H5.3858C4.56805 0 3.86147 -3.05972e-07 3.26961 0.0820307C2.72939 0.15677 1.93354 0.336318 1.27513 1.02449C0.615851 1.71082 0.443376 2.53843 0.369814 3.10079C0.302398 3.61394 0.293643 4.21368 0.291016 4.89091V4.90002V4.90914V5.30562V8.6962V9.09268V9.1018V9.11091ZM6.11863 5.6018V8.40181H1.63585V5.6018H6.11863ZM11.9462 8.40181H7.46346V5.6018H11.9462V8.40181ZM3.44913 12.5316C2.88179 12.4523 2.51667 12.2891 2.22776 11.9884C1.93882 11.6876 1.7821 11.3048 1.70594 10.7169C1.67267 10.4535 1.65428 10.1454 1.64728 9.8045H6.12132V12.6045H5.39024C4.62151 12.6045 3.95611 12.6045 3.4518 12.5343L3.44913 12.5316ZM11.3545 11.9884C11.063 12.2919 10.6979 12.4523 10.1331 12.5316C9.62883 12.6018 8.96517 12.6018 8.1947 12.6018H7.46362V9.80176H11.9377C11.9289 10.1445 11.9131 10.4507 11.879 10.7141C11.8028 11.302 11.6461 11.6821 11.3572 11.9856L11.3545 11.9884ZM10.1331 1.47456C10.7005 1.55385 11.0656 1.71702 11.3545 2.01778C11.6461 2.3213 11.8028 2.70138 11.8763 3.28927C11.9096 3.55268 11.928 3.86075 11.935 4.20165H7.46096V1.40164H8.19203C8.96076 1.40164 9.62616 1.40164 10.1305 1.47182L10.1331 1.47456ZM2.22776 2.01778C2.51932 1.71426 2.88442 1.55384 3.44913 1.47456C3.95345 1.40438 4.6171 1.40438 5.38758 1.40438H6.11865V4.20439H1.64461C1.65337 3.86167 1.66913 3.55544 1.70328 3.292C1.77945 2.70139 1.93618 2.32129 2.22509 2.02052L2.22776 2.01778Z"
      fill="#47AFFF"
    />
  </svg>
);

interface TableFieldIconProps {
  width?: number;
  height?: number;
  isSelected?: boolean;
  isPresentInOtherSections?: boolean;
}

export const TableFieldIcon: React.FC<TableFieldIconProps> = ({ width = 14, height = 13, isSelected, isPresentInOtherSections }: TableFieldIconProps) => {
  if (isSelected) return <Used width={width} height={height} />;
  if (isPresentInOtherSections) return <PresentInOtherSection width={width} height={height} />;

  return <Unused width={width} height={height} />;
};
