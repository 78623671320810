import { useCallback, useEffect, useRef, useState } from 'react';
import {
  Stack,
  Typography,
  Button,
  Paper,
  TableContainer,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  TableSortLabel,
  Skeleton,
  tableCellClasses
} from '@mui/material';
import { HighlightText, ActionStatus, StyledTableCell } from '@/shared';
import { useTranslation } from 'react-i18next';
import {
  SiteActions,
  retrieveSiteListItemsRequested,
  retrieveEmployeesBySiteIdRequested,
  selectSiteListItemsStatus,
  selectHasMoreSites,
  selectCurrentSitePageNumber,
  setDrawerSite,
  retrieveSiteListItemByIdRequested
} from '@features/admin-settings';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import {
  OrderDirection,
  RetrieveEmployeesBySiteIdRequest,
  RetrieveSiteListItemByIdRequest,
  RetrieveSiteListRequest,
  SiteListColumnName,
  SiteListItem
} from '@thrivea/organization-client';
import { DrawerType, openDrawer, OpenDrawerRequest } from '@features/drawer';

const siteListColumns = Object.keys(SiteListColumnName)
  .filter((key) => !isNaN(Number(key)))
  .slice(1) // remove first option
  .map((key) => ({
    id: parseInt(key), // deduct by 1 as we are removing first option
    name: SiteListColumnName[Number(key)].toLowerCase()
  }));

interface AdminSettingsSiteListTableViewProps {
  search: string;
  sitesList: SiteListItem[];
}

export const AdminSettingsSiteListTableView: React.FC<AdminSettingsSiteListTableViewProps> = ({ search, sitesList }) => {
  const { t } = useTranslation(['common', 'settings_sites']);
  const dispatch = useAppDispatch();
  const siteListItemsStatus = useAppSelector(selectSiteListItemsStatus);

  const [openMenu, setOpenMenu] = useState<string | null>(null);
  const [activeColumn, setActiveColumn] = useState(null);
  const [orderByColumn, setOrderByColumn] = useState(SiteListColumnName.SITE_LIST_ADDRESS);
  const [sortOrder, setSortOrder] = useState(OrderDirection.ASCENDING);
  const hasMore = useAppSelector(selectHasMoreSites);
  const currentSitePageNumber = useAppSelector(selectCurrentSitePageNumber);
  const observer = useRef<IntersectionObserver>();
  const bottomElementRef = useRef<HTMLDivElement>(null);

  const handleRequestSort = (column) => {
    setSortOrder(sortOrder === OrderDirection.ASCENDING ? OrderDirection.DESCENDING : OrderDirection.ASCENDING);
    setActiveColumn(column.name);

    setOrderByColumn(column.id);
    // Use column.id for orderBy because the state of orderByColumn variable wont be updated in time
    dispatch(
      retrieveSiteListItemsRequested(
        new RetrieveSiteListRequest({
          pageNumber: 1,
          pageSize: 10,
          orderBy: column.id,
          orderDirection: sortOrder,
          searchText: search
        })
      )
    );
  };

  const handleSiteDrawerOpen = (siteId: string) => {
    dispatch(openDrawer({ type: DrawerType.Site, request: { siteId } } as OpenDrawerRequest));
  };

  const toggleMenu = (menuName: string) => {
    setOpenMenu(openMenu === menuName ? null : menuName);
  };

  const closeMenu = (menuName: string) => {
    if (openMenu === menuName) {
      setOpenMenu(null);
    }
  };

  const handleScroll = useCallback(() => {
    if (hasMore) {
      dispatch(
        retrieveSiteListItemsRequested(
          new RetrieveSiteListRequest({
            pageNumber: currentSitePageNumber + 1,
            pageSize: 10,
            orderBy: orderByColumn,
            orderDirection: sortOrder,
            searchText: search
          })
        )
      );
    }
  }, [hasMore, currentSitePageNumber]);

  useEffect(() => {
    observer.current = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && siteListItemsStatus === ActionStatus.Idle) {
          handleScroll();
        }
      },
      { threshold: 1 } // Trigger the observer when the target is 100% visible
    );

    // Start observing the target element
    if (bottomElementRef.current && observer.current) {
      observer.current.observe(bottomElementRef.current);
    }

    return () => {
      // Clean up the observer and scroll event listener when the component unmounts
      if (observer.current && bottomElementRef.current) {
        observer.current.disconnect();
      }
    };
  }, [handleScroll]);

  return (
    <Stack
      sx={{
        gap: '24px',
        backgroundColor: 'inherit',
        padding: 2,
        height: '100%',
        overflowY: 'scroll'
      }}
    >
      {sitesList.length === 0 && siteListItemsStatus === ActionStatus.Idle && (
        <Typography fontWeight={700}>{t('no_sites_match', { ns: 'settings_sites' })}</Typography>
      )}

      <TableContainer
        component={Paper}
        elevation={0}
        sx={{
          margin: '0 -16px'
        }}
      >
        <Table
          size="medium"
          sx={{
            '& .MuiTableCell-root': {
              fontSize: '16px',
              border: 0,
              padding: 2
            }
          }}
        >
          <TableHead
            sx={{
              backgroundColor: (theme) => theme.palette.common.white,
              color: (theme) => theme.palette.common.black,
              '& .MuiTableCell-root': {
                fontWeight: 700
              }
            }}
          >
            <TableRow
              sx={{
                [`& .${tableCellClasses.root}`]: {
                  padding: '10px 30px'
                }
              }}
            >
              <StyledTableCell>Site Name</StyledTableCell>
              {siteListColumns.map((slc) => (
                <StyledTableCell key={slc.id}>
                  <TableSortLabel
                    active={activeColumn === slc.name}
                    direction={activeColumn === slc.name ? (sortOrder === OrderDirection.ASCENDING ? 'asc' : 'desc') : 'asc'}
                    onClick={() => handleRequestSort(slc)}
                  >
                    {t(slc.name, { ns: 'settings_sites' })}
                  </TableSortLabel>
                </StyledTableCell>
              ))}
              <StyledTableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {sitesList.map((site) => {
              return (
                <TableRow
                  hover
                  key={site.id}
                  sx={{
                    wordBreak: 'break-all',
                    whiteSpace: 'pre-line',
                    [`& .${tableCellClasses.root}`]: {
                      padding: '10px 30px'
                    }
                  }}
                >
                  <StyledTableCell sx={{ fontWeight: 700 }}>
                    <HighlightText text={site.name} highlight={search} />
                  </StyledTableCell>
                  <StyledTableCell>
                    <HighlightText text={site.address} highlight={search} />
                  </StyledTableCell>
                  <StyledTableCell>
                    <HighlightText text={site.city} highlight={search} />
                  </StyledTableCell>
                  <StyledTableCell>
                    <HighlightText text={site.country} highlight={search} />
                  </StyledTableCell>
                  <StyledTableCell sx={{ color: (theme) => theme.palette.customTheme.focusItem, textDecoration: 'underline' }}>
                    <Button
                      onClick={() => handleSiteDrawerOpen(site.id)}
                      variant="text"
                      sx={{
                        color: (theme) => theme.palette.customTheme.focusItem,
                        padding: 0,
                        margin: 0,
                        backgroundColor: 'transparent',
                        textDecoration: 'underline',
                        '&:hover': {
                          backgroundColor: 'transparent',
                          textDecoration: 'underline'
                        }
                      }}
                    >
                      {t('employee_with_count', { count: site.employeeCount, ns: 'common' })}
                    </Button>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <SiteActions
                      site={site}
                      menuName={site.name}
                      isSiteMenuOpen={openMenu === site.name}
                      handleSiteMenuToggle={toggleMenu}
                      closeMenu={closeMenu}
                    />
                  </StyledTableCell>
                </TableRow>
              );
            })}
            {siteListItemsStatus === ActionStatus.Pending && (
              <>
                <TableRow key={1} sx={{ wordBreak: 'break-all', whiteSpace: 'pre-line' }}>
                  <TableCell sx={{ width: 176 }}>
                    <Skeleton variant="text" width="80%" />
                  </TableCell>
                  <TableCell sx={{ width: 230 }}>
                    <Skeleton variant="text" width="80%" />
                  </TableCell>
                  <TableCell sx={{ width: 167 }}>
                    <Skeleton variant="text" width="80%" />
                  </TableCell>
                  <TableCell sx={{ width: 163 }}>
                    <Skeleton variant="text" width="80%" />
                  </TableCell>
                  <TableCell sx={{ width: 237 }}>
                    <Skeleton variant="text" width="80%" />
                  </TableCell>
                  <TableCell sx={{ width: 250 }} align="center">
                    <Skeleton variant="rectangular" width={150} height={40} />
                  </TableCell>
                </TableRow>
                <TableRow key={2} sx={{ wordBreak: 'break-all', whiteSpace: 'pre-line' }}>
                  <TableCell sx={{ width: 176 }}>
                    <Skeleton variant="text" width="80%" />
                  </TableCell>
                  <TableCell sx={{ width: 230 }}>
                    <Skeleton variant="text" width="80%" />
                  </TableCell>
                  <TableCell sx={{ width: 167 }}>
                    <Skeleton variant="text" width="80%" />
                  </TableCell>
                  <TableCell sx={{ width: 163 }}>
                    <Skeleton variant="text" width="80%" />
                  </TableCell>
                  <TableCell sx={{ width: 237 }}>
                    <Skeleton variant="text" width="80%" />
                  </TableCell>
                  <TableCell sx={{ width: 250 }} align="center">
                    <Skeleton variant="rectangular" width={150} height={40} />
                  </TableCell>
                </TableRow>
                <TableRow key={3} sx={{ wordBreak: 'break-all', whiteSpace: 'pre-line' }}>
                  <TableCell sx={{ width: 176 }}>
                    <Skeleton variant="text" width="80%" />
                  </TableCell>
                  <TableCell sx={{ width: 230 }}>
                    <Skeleton variant="text" width="80%" />
                  </TableCell>
                  <TableCell sx={{ width: 167 }}>
                    <Skeleton variant="text" width="80%" />
                  </TableCell>
                  <TableCell sx={{ width: 163 }}>
                    <Skeleton variant="text" width="80%" />
                  </TableCell>
                  <TableCell sx={{ width: 237 }}>
                    <Skeleton variant="text" width="80%" />
                  </TableCell>
                  <TableCell sx={{ width: 250 }} align="center">
                    <Skeleton variant="rectangular" width={150} height={40} />
                  </TableCell>
                </TableRow>
                <TableRow key={4} sx={{ wordBreak: 'break-all', whiteSpace: 'pre-line' }}>
                  <TableCell sx={{ width: 176 }}>
                    <Skeleton variant="text" width="80%" />
                  </TableCell>
                  <TableCell sx={{ width: 230 }}>
                    <Skeleton variant="text" width="80%" />
                  </TableCell>
                  <TableCell sx={{ width: 167 }}>
                    <Skeleton variant="text" width="80%" />
                  </TableCell>
                  <TableCell sx={{ width: 163 }}>
                    <Skeleton variant="text" width="80%" />
                  </TableCell>
                  <TableCell sx={{ width: 237 }}>
                    <Skeleton variant="text" width="80%" />
                  </TableCell>
                  <TableCell sx={{ width: 250 }} align="center">
                    <Skeleton variant="rectangular" width={150} height={40} />
                  </TableCell>
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>
        <div ref={bottomElementRef} style={{ width: '100%', height: '1px' }}></div>
      </TableContainer>
    </Stack>
  );
};
