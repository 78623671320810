import { Divider, DividerProps, styled } from '@mui/material';

export const BoardViewDivider = styled(Divider)<DividerProps>(({ theme }) => ({
  width: 2,
  height: '100%',
  borderColor: theme.palette.common.white,
  position: 'absolute',
  top: 0,
  right: 0
}));
