import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import { ErrorLayout } from '@app/error';
import { StackCenterAlign, StyledHomeLinkButton } from '@/shared';
import { useTranslation } from 'react-i18next';

export const NotFoundPage = () => {
  const { t } = useTranslation(['common']);
  return (
    <ErrorLayout>
      <StackCenterAlign
        gap={2}
      >
        <Typography variant="h4">{t('page_not_found', { ns: 'common' })}</Typography>
        <StyledHomeLinkButton component={Link} to="/" variant="contained">
          {t('return_to_home', { ns: 'common' })}
        </StyledHomeLinkButton>
      </StackCenterAlign>
    </ErrorLayout>
  );
};
