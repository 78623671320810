import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import {
  EmployeeRecord,
  RetrieveDepartmentsResponse,
  RetrieveEmployeeRecordRequest,
  RetrieveEmployeesByQueryRequest,
  RetrieveEmployeesByQueryResponse,
  RetrieveSitesResponse,
  UpdateErSectionRequest
} from '@thrivea/organization-client';
import * as Sentry from '@sentry/react';
import { retrieveEmployeeRecord, updateErSection } from '@api/erp.api';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  retrieveDepartmentsFailed,
  retrieveDepartmentsRequested,
  retrieveDepartmentsSucceeded,
  retrieveEmployeeItemsFailed,
  retrieveEmployeeItemsRequested,
  retrieveEmployeeItemsSucceeded,
  retrieveEmployeeRecordFailed,
  retrieveEmployeeRecordRequested,
  retrieveEmployeeRecordSucceeded,
  retrieveSitesFailed,
  retrieveSitesRequested,
  retrieveSitesSucceeded,
  updateErSectionFailed,
  updateErSectionRequested,
  updateErSectionSucceeded
} from '@features/employee-record-page';
import { selectErTemplateSectionsByInitialId } from '@features/employee-record-builder';
import { showSuccess } from '@features/snackbar';
import { t } from 'i18next';
import { retrieveEmployeesByQuery } from '@api/employees.api';
import { retrieveDepartments, retrieveSites } from '@api/employee-profile.api';
import { Empty } from '@bufbuild/protobuf';

function* retrieveEmployeeRecordRequestedGenerator(action: PayloadAction<RetrieveEmployeeRecordRequest>) {
  try {
    const response: EmployeeRecord = yield call(retrieveEmployeeRecord, action.payload);
    yield put(retrieveEmployeeRecordSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveEmployeeRecordFailed());
  }
}

function* updateErSectionRequestedGenerator(action: PayloadAction<UpdateErSectionRequest>) {
  const section = yield select(selectErTemplateSectionsByInitialId);

  try {
    const response = yield call(updateErSection, action.payload);
    yield put(updateErSectionSucceeded(response));
    yield put(showSuccess(t('updated_section', { ns: 'employee_record', name: section[action.payload.erTemplateSectionId].name })));
  } catch (error) {
    Sentry.captureException(error);
    yield put(updateErSectionFailed());
  }
}

function* retrieveEmployeeItemsRequestedGenerator(action: PayloadAction<RetrieveEmployeesByQueryRequest>) {
  try {
    const response: RetrieveEmployeesByQueryResponse = yield call(retrieveEmployeesByQuery, action.payload);
    yield put(retrieveEmployeeItemsSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveEmployeeItemsFailed());
  }
}

function* retrieveDepartmentsRequestedGenerator(action: PayloadAction<Empty>) {
  try {
    const response: RetrieveDepartmentsResponse = yield call(retrieveDepartments, action.payload);
    yield put(retrieveDepartmentsSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveDepartmentsFailed());
  }
}

function* retrieveSitesRequestedGenerator(action: PayloadAction<Empty>) {
  try {
    const response: RetrieveSitesResponse = yield call(retrieveSites, action.payload);
    yield put(retrieveSitesSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveSitesFailed());
  }
}

function* retrieveEmployeeRecordRequestedWatcher() {
  yield takeLatest(retrieveEmployeeRecordRequested.type, retrieveEmployeeRecordRequestedGenerator);
}

function* retrieveEmployeeItemsRequestedWatcher() {
  yield takeLatest(retrieveEmployeeItemsRequested.type, retrieveEmployeeItemsRequestedGenerator);
}

function* updateErSectionRequestedWatcher() {
  yield takeLatest(updateErSectionRequested.type, updateErSectionRequestedGenerator);
}

function* retrieveDepartmentsRequestedWatcher() {
  yield takeLatest(retrieveDepartmentsRequested.type, retrieveDepartmentsRequestedGenerator);
}

function* retrieveSitesRequestedWatcher() {
  yield takeLatest(retrieveSitesRequested.type, retrieveSitesRequestedGenerator);
}

export function* employeeRecordSagas() {
  yield all([
    fork(retrieveEmployeeRecordRequestedWatcher),
    fork(updateErSectionRequestedWatcher),
    fork(retrieveEmployeeItemsRequestedWatcher),
    fork(retrieveDepartmentsRequestedWatcher),
    fork(retrieveSitesRequestedWatcher)
  ]);
}
