import { create } from '@bufbuild/protobuf';
import React, { useState, useEffect, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router';
import { ListItemText, Box, Collapse, IconButton, ListItemIcon, Stack } from '@mui/material';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import { BinIcon, ChevronDownIcon, ClockIcon, DocsIcon, FolderIcon, RowCenterStack, ShareIcon, StarIcon, VisuallyHidden } from '@/shared';
import { SortFile, OrderDirection, RetrieveFilesRequestSchema } from '@thrivea/organization-client';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import {
  selectFilesPageSize,
  selectFilesPageNumber,
  selectMyFoldersAsTreeItems,
  selectSelectedFolderId,
  selectExpandedFolderIds,
  MY_FILES_FOLDER_ID,
  SHARED_WITH_ME_FILES_ID,
  selectFolder,
  TRASH_BIN_FILES_ID,
  MY_UPLOADS_FILES_FOLDER_ID,
  retrieveFilesRequested,
  expandFolders,
  AssetsTreeItem,
  toggleStarredFiles,
  toggleRecentFiles,
  selectActiveFilter
} from '@features/assets';
import { selectCurrentEmployeeId } from '@features/shared';
import { NavListItem, selectNavigationIsExpanded, StyledNavListItemButton } from '@features/navigation';

interface DocumentsFilterButtonProps {
  icon: React.ReactNode;
  name: string;
  onClick: () => void;
  selected: boolean;
  isDisabled: boolean;
}

const DocumentsFilterButton = ({ icon, name, onClick, selected, isDisabled }: DocumentsFilterButtonProps): JSX.Element => (
  <StyledNavListItemButton selected={selected} onClick={onClick} disabled={isDisabled} disableGutters>
    <RowCenterStack gap="8px">
      <ListItemIcon sx={{ width: 20, height: 20, minWidth: 'unset' }}>{icon}</ListItemIcon>
      <ListItemText
        primary={name}
        sx={{
          '& .MuiTypography-root': {
            fontSize: '16px',
            lineHeight: '24px',
            fontWeight: 400
          }
        }}
      />
    </RowCenterStack>
  </StyledNavListItemButton>
);

interface DocumentsButtonProps {
  icon: React.ReactNode;
  primaryText: string;
  selectedPath: string;
  onClick: () => void;
  isDisabled: boolean;
}

const DocumentsButton = ({ icon, primaryText, selectedPath, onClick, isDisabled }: DocumentsButtonProps): JSX.Element => {
  const location = useLocation();

  return (
    <StyledNavListItemButton selected={location.pathname === selectedPath} onClick={onClick} disabled={isDisabled} disableGutters>
      <RowCenterStack gap="8px">
        <ListItemIcon sx={{ width: 20, height: 20, minWidth: 'unset' }}>{icon}</ListItemIcon>
        <ListItemText
          primary={primaryText}
          sx={{
            '& .MuiTypography-root': {
              fontSize: '16px',
              lineHeight: '24px',
              fontWeight: 500
            }
          }}
        />
      </RowCenterStack>
    </StyledNavListItemButton>
  );
};

interface DocumentsAccordionProps {
  isDisabled: boolean;
  url: string;
}

export const DocumentsAccordion = ({ isDisabled }: DocumentsAccordionProps): JSX.Element => {
  const { t } = useTranslation(['common', 'assets']);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const filesPageSize = useAppSelector(selectFilesPageSize);
  const filesPageNumber = useAppSelector(selectFilesPageNumber);
  const signedInEmployeeId = useAppSelector(selectCurrentEmployeeId);
  const myFilesAsTreeItems = useAppSelector(selectMyFoldersAsTreeItems);
  const selectedFolderId = useAppSelector(selectSelectedFolderId);
  const expandedFolderIds = useAppSelector(selectExpandedFolderIds);
  const navigationIsExpanded = useAppSelector(selectNavigationIsExpanded);
  const activeFilter = useAppSelector(selectActiveFilter);

  const isPathActive = location.pathname.includes('/documents');

  const [isItemExpanded, setIsItemExpanded] = useState(isPathActive);
  const [isMyFilesExpanded, setIsMyFilesExpanded] = useState(false);

  useEffect(() => {
    switch (location.pathname) {
      case '/documents/shared-with-me':
        dispatch(selectFolder(SHARED_WITH_ME_FILES_ID));
        break;
      case '/documents/trash-bin':
        dispatch(selectFolder(TRASH_BIN_FILES_ID));
        break;
      case '/documents/my-uploads':
        dispatch(selectFolder(MY_UPLOADS_FILES_FOLDER_ID));
        break;
      default:
        dispatch(selectFolder(MY_FILES_FOLDER_ID));
    }
  }, []);

  const handleItemClick = (folderId: string, path: string) => {
    navigate(path);
    dispatch(selectFolder(folderId));
    dispatch(
      retrieveFilesRequested(
        create(RetrieveFilesRequestSchema, {
          employeeId: signedInEmployeeId,
          folderId,
          pageNumber: filesPageNumber,
          pageSize: filesPageSize,
          sortBy: SortFile.NAME,
          sortDirection: OrderDirection.ASCENDING
        })
      )
    );
  };

  const handleExpandedItemsChange = (_: SyntheticEvent<Element, Event>, itemIds: string[]): void => {
    dispatch(expandFolders(itemIds));
  };

  const handleSettingsItemClick = () => {
    if (location.pathname !== '/documents') {
      navigate('/documents');
      dispatch(selectFolder(MY_FILES_FOLDER_ID));
    }
    setIsItemExpanded(!isItemExpanded);
  };

  const handleToggleFilter = (filter: string) => {
    if (location.pathname !== '/documents') {
      navigate('/documents');
    }
    if (filter === 'starred') {
      dispatch(toggleStarredFiles());
    } else if (filter === 'recents') {
      dispatch(toggleRecentFiles());
    }
  };

  const handleMyFilesButtonClick = () => {
    if (location.pathname !== '/documents') {
      navigate('/documents');
    }
    handleItemClick(MY_FILES_FOLDER_ID, '/documents');
    setIsMyFilesExpanded(!isMyFilesExpanded);
  };

  const handleSharedWithMeItemClick = () => {
    navigate('/documents/shared-with-me');
    dispatch(selectFolder(SHARED_WITH_ME_FILES_ID));
  };

  const handleTrashBinItemClick = () => {
    navigate('/documents/trash-bin');
    dispatch(selectFolder(TRASH_BIN_FILES_ID));
  };

  return (
    <Stack>
      {!navigationIsExpanded && <NavListItem isDisabled={false} name="Documents" url="/documents" icon={<DocsIcon size={20} />} />}
      {navigationIsExpanded && (
        <>
          <StyledNavListItemButton onClick={handleSettingsItemClick}>
            <RowCenterStack gap="8px">
              <ListItemIcon sx={{ width: 20, height: 20, minWidth: 'unset' }}>
                <DocsIcon size={20} />
              </ListItemIcon>
              <ListItemText
                primary={'Documents'}
                sx={{
                  '& .MuiTypography-root': {
                    fontSize: '16px',
                    lineHeight: '24px',
                    fontWeight: 400
                  }
                }}
              />
            </RowCenterStack>
            <IconButton
              disableFocusRipple
              sx={{
                p: 0,
                m: 0
              }}
            >
              <Box
                sx={{
                  paddingRight: '4px',
                  '& svg': {
                    transform: isItemExpanded ? 'rotate(-90deg)' : 'none'
                  }
                }}
              >
                <ChevronDownIcon />
              </Box>
              <VisuallyHidden>{t('expand_name', { ns: 'common', name: 'My Files' })}</VisuallyHidden>
            </IconButton>
          </StyledNavListItemButton>
          <Collapse in={isItemExpanded} timeout="auto" unmountOnExit>
            <Stack
              gap={1}
              sx={{
                borderTop: '1px solid',
                borderBottom: '1px solid',
                borderColor: (theme) => theme.palette.customTheme.globalContentColorMuted,
                padding: '8px 0'
              }}
            >
              <StyledNavListItemButton onClick={handleMyFilesButtonClick} selected={selectedFolderId === MY_FILES_FOLDER_ID && activeFilter === 'none'}>
                <RowCenterStack gap="8px">
                  <ListItemIcon sx={{ width: 20, height: 20, minWidth: 'unset' }}>
                    <FolderIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={t('my_files', { ns: 'assets' })}
                    sx={{
                      '& .MuiTypography-root': {
                        fontSize: '16px',
                        lineHeight: '24px',
                        fontWeight: 400
                      }
                    }}
                  />
                </RowCenterStack>
                <IconButton
                  disableFocusRipple
                  sx={{
                    p: 0,
                    m: 0
                  }}
                >
                  <Box
                    sx={{
                      paddingRight: '4px',
                      '& svg': {
                        transform: isMyFilesExpanded ? 'rotate(-90deg)' : 'none'
                      }
                    }}
                  >
                    <ChevronDownIcon />
                  </Box>
                  <VisuallyHidden>{t('expand_name', { ns: 'common', name: 'My Files' })}</VisuallyHidden>
                </IconButton>
              </StyledNavListItemButton>
              <Collapse in={isMyFilesExpanded} timeout="auto" unmountOnExit>
                <Stack
                  gap={1}
                  sx={{
                    borderTop: '1px solid',
                    borderBottom: '1px solid',
                    borderColor: (theme) => theme.palette.customTheme.globalContentColorMuted,
                    padding: '8px 0'
                  }}
                >
                  <RichTreeView
                    items={myFilesAsTreeItems}
                    expandedItems={expandedFolderIds}
                    onExpandedItemsChange={handleExpandedItemsChange}
                    selectedItems={selectedFolderId}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '3px',
                      paddingLeft: '16px',
                      '& .MuiCollapse-root': {
                        paddingLeft: '16px'
                      }
                    }}
                    slots={{
                      item: AssetsTreeItem,
                      collapseIcon: ChevronDownIcon,
                      expandIcon: () => (
                        <Box
                          sx={{
                            '& svg': {
                              transform: 'rotate(-90deg)'
                            }
                          }}
                        >
                          <ChevronDownIcon />
                        </Box>
                      )
                    }}
                    onItemClick={(event: React.MouseEvent, itemId: string) => handleItemClick(itemId, '/documents')}
                    getItemId={(item) => item.id}
                  />
                </Stack>
              </Collapse>
              <DocumentsButton
                icon={<ShareIcon width={20} height={20} color="#717680" />}
                isDisabled={isDisabled}
                onClick={handleSharedWithMeItemClick}
                primaryText={t('shared_with_me', { ns: 'assets' })}
                selectedPath={'/documents/shared-with-me'}
              />
              <DocumentsFilterButton
                icon={<StarIcon width={20} height={20} color="#717680" />}
                name={t('starred', { ns: 'assets' })}
                onClick={() => handleToggleFilter('starred')}
                selected={activeFilter === 'starred'}
                isDisabled={isDisabled}
              />
              <DocumentsFilterButton
                icon={<ClockIcon width={20} height={20} color="#717680" />}
                name={t('recents', { ns: 'assets' })}
                onClick={() => handleToggleFilter('recents')}
                selected={activeFilter === 'recents'}
                isDisabled={isDisabled}
              />
              <DocumentsButton
                icon={<BinIcon width={20} height={20} customColor="#717680" />}
                isDisabled={isDisabled}
                onClick={handleTrashBinItemClick}
                primaryText={t('trash_bin', { ns: 'assets' })}
                selectedPath={'/documents/trash-bin'}
              />
            </Stack>
          </Collapse>
        </>
      )}
    </Stack>
  );
};
