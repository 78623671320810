import { Stack, StackProps, styled } from "@mui/material";

export const AudienceNewGroupParentStack = styled(Stack)<StackProps>(({ theme }) => ({
    borderRadius: 4,
    width: '100%',
    height: 226,
    backgroundColor: theme.palette.grey[800],
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'background-color 0.3s ease-in-out',
    '&:hover': {
        backgroundColor: theme.palette.common.white
    }
}))