import { styled, Typography, TypographyProps } from '@mui/material';

export const SiteWorkingPatternCopy = styled(Typography)<TypographyProps>(({ theme }) => ({
  textTransform: 'capitalize',
  position: 'absolute',
  top: '-16px',
  left: '50%',
  transform: 'translateX(-50%)',
  fontSize: '12px'
}));
