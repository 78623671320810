import { Stack } from '@mui/material';
import { ListTaskTitle, TaskChip, TaskListBar } from '@features/tasks';
import { RowCenterStack } from '@shared/components';
import { FilterIcon } from '@/shared';

interface TasksBoardItemProps {
  title: string;
  tasksCount: number;
}

export const TasksBoardItem: React.FC<TasksBoardItemProps> = ({ title, tasksCount }) => {
  return (
    <Stack sx={{ backgroundColor: 'transparent', marginBottom: '8px', paddingLeft: '24px' }}>
      <RowCenterStack sx={{ justifyContent: 'space-between' }}>
        <Stack gap={'4px'}>
          <ListTaskTitle sx={{ color: '#251D38', fontSize: 20, fontWeight: '700', wordWrap: 'break-word' }}>{title}</ListTaskTitle>
        </Stack>
        <RowCenterStack gap={1}>
          <TaskChip>{tasksCount}</TaskChip>
          <FilterIcon />
        </RowCenterStack>
      </RowCenterStack>
    </Stack>
  );
};
