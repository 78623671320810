import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { circuitBreaker, ConsecutiveBreaker, ExponentialBackoff, handleAll, retry, wrap } from 'cockatiel';
import { EventChannel, eventChannel } from 'redux-saga';
import { call, put, take } from 'redux-saga/effects';
import { msalInstance } from 'src/main';
import { tokenRequest } from 'src/utils';
import {
  newPostPublished,
  showNewReaction,
  showNewComment,
  deleteCommentOnPost,
  showNewReactionToComment,
  confirmReadPost,
  editShoutout,
  deletePost,
  withdrawCommentReaction,
  withdrawPostReaction
} from '@features/homepage';
import { setHasUnreadNotifications } from '@features/notifications';
import { activateEmployee } from '@features/people-directory';
import { setSignalRConnectionId } from '@features/shared';
import { store } from '@app/store';

// Define a generic NotificationEvent interface.
interface NotificationEvent<T = any> {
  type: string;
  payload: T;
  timestamp: string;
}

// Register multiple event handlers dynamically.
const eventTypes = [
  'ShoutoutPublished',
  'ReactedToPost',
  'PostReactionWithdrawn',
  'CommentedOnPost',
  'CommentOnPostDeleted',
  'ReactedToComment',
  'CommentReactionWithdrawn',
  'PostDeleted',
  'ShoutoutEdited',
  'PostReadConfirmed',
  'NotifiedEmployee',
  'B2cAccountCreated'
];

// Create an event channel that listens to notifications from the SignalR hub.
const createSignalRChannel = () =>
  eventChannel((emitter) => {
    // Create a retry policy that'll try whatever function we execute 3
    // times with a randomized exponential backoff.
    const retryPolicy = retry(handleAll, { maxAttempts: 3, backoff: new ExponentialBackoff() });

    // Create a circuit breaker that'll stop calling the executed function for 10
    // seconds if it fails 5 times in a row. This can give time for e.g. a database
    // to recover without getting tons of traffic.
    const circuitBreakerPolicy = circuitBreaker(handleAll, {
      halfOpenAfter: 10 * 1000,
      breaker: new ConsecutiveBreaker(5)
    });

    // Combine these! Create a policy that retries 3 times, calling through the circuit breaker
    const retryWithBreaker = wrap(retryPolicy, circuitBreakerPolicy);

    const connection = new HubConnectionBuilder()
      .withUrl(new URL('/notifications', import.meta.env.VITE_APP_API_URL).toString(), {
        accessTokenFactory: async () => {
          try {
            const response = await msalInstance.acquireTokenSilent(tokenRequest);
            return response.accessToken;
          } catch (error) {
            if (error instanceof InteractionRequiredAuthError) {
              await msalInstance.acquireTokenRedirect(tokenRequest);
            }

            return '';
          }
        }
      })
      .withAutomaticReconnect()
      .build();

    for (const eventType of eventTypes) {
      connection.on(eventType, (data: any) => {
        // Assume that each event has a timestamp, or you can add one here.
        const notification: NotificationEvent = { type: eventType, payload: data, timestamp: new Date().toISOString() };
        emitter(notification);
      });
    }

    const startConnection = async () => {
      await retryWithBreaker.execute(async () => {
        // If logged in, attempt to start the connection.
        await connection.start();
      });

      console.warn('Notifications Connected!');
    };

    // Wait until the connection starts and then dispatch the connection id.
    startConnection().then(() => {
      // Dispatching outside of saga scope using the direct store.dispatch.
      store.dispatch(setSignalRConnectionId(connection.connectionId!));
    });

    return () => {
      connection.stop().then(() => {
        console.warn('Notifications Disconnected.');
      });
    };
  });

// Saga to handle incoming notifications from SignalR.
export function* signalRNotificationsSagas() {
  const channel: EventChannel<any> = yield call(createSignalRChannel);

  try {
    while (true) {
      const notification = yield take(channel);

      switch (notification.type) {
        case 'ShoutoutPublished':
          yield put(newPostPublished(notification.payload));
          break;
        case 'ReactedToPost':
          yield put(showNewReaction(notification.payload));
          break;
        case 'PostReactionWithdrawn':
          yield put(withdrawPostReaction(notification.payload));
          break;
        case 'CommentedOnPost':
          yield put(showNewComment(notification.payload));
          break;
        case 'CommentOnPostDeleted':
          yield put(deleteCommentOnPost(notification.payload));
          break;
        case 'ReactedToComment':
          yield put(showNewReactionToComment(notification.payload));
          break;
        case 'CommentReactionWithdrawn':
          yield put(withdrawCommentReaction(notification.payload));
          break;
        case 'PostDeleted':
          yield put(deletePost(notification.payload));
          break;
        case 'ShoutoutEdited':
          yield put(editShoutout(notification.payload));
          break;
        case 'PostReadConfirmed':
          yield put(confirmReadPost(notification.payload));
          break;
        case 'NotifiedEmployee':
          yield put(setHasUnreadNotifications());
          break;
        case 'B2cAccountCreated':
          yield put(activateEmployee(notification.payload));
          break;
        // Handle other notification types as needed.
        default:
          console.warn('Unknown notification type:', notification.type);
      }
    }
  } finally {
    channel.close();
  }
}
