import { create } from '@bufbuild/protobuf';
import { capitalize, PopperProps } from '@mui/material';
import { forwardRef, useState } from 'react';
import { PopperBox } from '@features/admin-settings';
import { GenericSelect, PrimaryDarkButton, PrimaryTypographyBodyBold, RowCenterStack } from 'src/shared';
import { FileAccessRole, EmployeeListItem, EmployeeAccessSchema } from '@thrivea/organization-client';
import { t } from 'i18next';
import { addEmployeeToShareList } from '@features/assets';
import { useAppDispatch } from '@app/hooks';

const fileAccessRoleOptions = Object.values(FileAccessRole)
  .filter((value) => typeof value === 'number') // Keep only numeric enum values
  .slice(1)
  .map((value) => ({
    label: FileAccessRole[value as FileAccessRole], // Get the name from the enum
    value: value as number // Ensure the value is a number
  }));

interface AssetsFileAutocompletePopper extends PopperProps {
  selectedEmployees: EmployeeListItem[];
}

export const AssetsFileAutocompletePopper = forwardRef<HTMLDivElement, AssetsFileAutocompletePopper>(function CustomPopper(props, ref) {
  const { children, open, anchorEl, selectedEmployees } = props;
  const dispatch = useAppDispatch();

  const [role, setRole] = useState<FileAccessRole>(FileAccessRole.VIEWER);

  const getChildren = () => {
    if (typeof children === 'function') {
      return children({ placement: 'bottom-start' });
    }

    return children;
  };

  return (
    <PopperBox ref={ref} open={open} anchorEl={anchorEl as HTMLElement}>
      {getChildren()}
      <RowCenterStack sx={{ justifyContent: 'space-between' }}>
        <RowCenterStack gap={1}>
          <PrimaryTypographyBodyBold>{t('give_permissions', { ns: 'assets' })}</PrimaryTypographyBodyBold>
          <GenericSelect<number>
            options={fileAccessRoleOptions}
            value={role}
            onChange={setRole}
            renderValue={(value) => (value === FileAccessRole.FILE_ACCESS_ROLE_UNDEFINED ? 'Select Role' : capitalize(FileAccessRole[value]))}
            displayEmpty
          />
        </RowCenterStack>
        <PrimaryDarkButton
          variant="text"
          onClick={() => {
            dispatch(
              addEmployeeToShareList(
                selectedEmployees.map((employee: EmployeeListItem) =>
                  create(EmployeeAccessSchema, {
                    id: employee.employeeId,
                    name: employee.displayName,
                    jobTitle: employee.jobTitle,
                    profilePicture: employee.profilePictureUrl,
                    accessRole: role
                  })
                )
              )
            );
          }}
        >
          {t('add_to_list', { ns: 'assets' })}
        </PrimaryDarkButton>
      </RowCenterStack>
    </PopperBox>
  );
});
