import { RoleListItem } from '../admin-settings';
import { SingleTaskTabs, TaskListTaskTabs } from '../tasks';

export type DrawerDirection = {
  left;
  right;
};

export enum DrawerType {
  Site = 'site',
  SingleEmployee = 'singleEmployee',
  WorkingPattern = 'workingPattern',
  JobTitle = 'jobTitle',
  Audience = 'audience',
  AudienceRole = 'audienceRole',
  PostAnalytics = 'postAnalytics',
  EmployeeRecord = 'employeeRecord',
  EmployeeRecordCustomField = 'employeeRecordCustomField',
  PeopleDirectory = 'peopleDirectory',
  Assets = 'assets',
  AssetsMultipleFiles = 'assetsMultipleFiles',
  AssetsShareFile = 'assetsShareFile',
  TasksAction = 'tasksAction',
  TasksNewTask = 'tasksNewTask',
  TasksEditTask = 'tasksEditTask',
  TasksNewTaskList = 'tasksNewTaskList',
  TasksEditTaskList = 'tasksEditTaskList',
  TasksReadOnlyTask = 'tasksReadOnlyTask',
  BillingPlan = 'billingPlan',
  BillingAddons = 'billingAddons',
  BillingPaymentMethod = 'billingPaymentMethod',
  BillingPaymentMethodList = 'billingPaymentMethodList',
  BillingPaymentSummary = 'billingPaymentSummary'
}

export type OpenDrawerRequest =
  | { type: DrawerType.Site; request: SiteDrawerRequest }
  | { type: DrawerType.SingleEmployee; request: SingleEmployeeDrawerRequest }
  | { type: DrawerType.WorkingPattern; request: WorkingPatternDrawerRequest }
  | { type: DrawerType.JobTitle; request: JobTitleDrawerRequest }
  | { type: DrawerType.Audience; request: AudienceDrawerRequest }
  | { type: DrawerType.AudienceRole; request: AudienceRoleDrawerRequest }
  | { type: DrawerType.PostAnalytics; request: PostAnalyticsDrawerRequest }
  | { type: DrawerType.EmployeeRecord; request: EmployeeRecordDrawerRequest }
  | { type: DrawerType.EmployeeRecordCustomField; request: EmployeeRecordCustomFieldDrawerRequest }
  | { type: DrawerType.Assets; request: AssetsDrawerRequest }
  | { type: DrawerType.AssetsMultipleFiles; request: AssetsMultipleFilesDrawerRequest }
  | { type: DrawerType.AssetsShareFile; request: AssetsShareFileDrawerRequest }
  | { type: DrawerType.TasksAction; request: TasksActionDrawerRequest }
  | { type: DrawerType.TasksNewTask; request: TasksNewTaskDrawerRequest }
  | { type: DrawerType.TasksEditTask; request: TasksEditTaskDrawerRequest }
  | { type: DrawerType.TasksNewTaskList; request: TasksNewTaskListDrawerRequest }
  | { type: DrawerType.TasksEditTaskList; request: TasksEditTaskListDrawerRequest }
  | { type: DrawerType.TasksReadOnlyTask; request: TasksReadOnlyTaskDrawerRequest }
  | { type: DrawerType.BillingPlan; request: BillingPlanDrawerRequest }
  | { type: DrawerType.BillingAddons; request: BillingAddonsDrawerRequest }
  | { type: DrawerType.BillingPaymentMethod; request: BillingPaymentMethodDrawerRequest }
  | { type: DrawerType.BillingPaymentMethodList; request: BillingPaymentMethodListsDrawerRequest }
  | { type: DrawerType.BillingPaymentSummary; request: BillingPaymentSummaryDrawerRequest };

export interface SiteDrawerRequest {
  siteId: string;
}

export interface SingleEmployeeDrawerRequest {
  employeeId: string;
}

export interface WorkingPatternDrawerRequest {
  workingPatternId: string;
  workingPatternName: string;
}

export interface JobTitleDrawerRequest {
  jobTitle: string;
}

export interface AudienceDrawerRequest {
  audienceId: string;
}

export interface AudienceRoleDrawerRequest {
  role: RoleListItem;
}

export interface PostAnalyticsDrawerRequest {
  postId: string;
}

export interface EmployeeRecordDrawerRequest {
  erbCategoryId: string;
  erTemplateSectionId: string;
}

export interface EmployeeRecordCustomFieldDrawerRequest {}

export interface AssetsDrawerRequest {
  fileId: string;
}

export interface AssetsMultipleFilesDrawerRequest {
  fileIds: string[];
}

export interface AssetsShareFileDrawerRequest {
  fileId: string;
}

export interface TasksActionDrawerRequest {}

export interface TasksNewTaskDrawerRequest {}

export interface TasksEditTaskDrawerRequest {
  activeTab: SingleTaskTabs;
}

export interface TasksNewTaskListDrawerRequest {}

export interface TasksEditTaskListDrawerRequest {
  activeTab: TaskListTaskTabs;
}
export interface TasksReadOnlyTaskDrawerRequest {
  activeTab: SingleTaskTabs;
}

export interface BillingPlanDrawerRequest {}

export interface BillingAddonsDrawerRequest {}

export interface BillingPaymentMethodDrawerRequest {}

export interface BillingPaymentMethodListsDrawerRequest {}

export interface BillingPaymentSummaryDrawerRequest {}
