import { ListItem, ListItemButton, ListItemIcon, Typography } from '@mui/material';
import { Stack } from '@mui/material';
import { NavLink } from 'react-router';

interface NavListItemProps {
  isDisabled: boolean;
  name: string;
  url: string;
  icon: JSX.Element;
}

export const NavListItem: React.FC<NavListItemProps> = ({ isDisabled, name, url, icon }) => (
  <ListItem
    sx={{
      padding: 0,
      '& .MuiListItemButton-root:hover': {
        color: (theme) => theme.palette.customTheme.focusItem,
        backgroundColor: (theme) => theme.palette.customTheme.selectItem
      }
    }}
  >
    <ListItemButton
      disabled={isDisabled}
      disableGutters
      component={NavLink}
      to={url}
      sx={{
        width: 48,
        height: 48,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '6px',
        backgroundColor: (theme) => theme.palette.common.white,
        '&.active': {
          color: (theme) => theme.palette.customTheme.focusItem,
          backgroundColor: (theme) => theme.palette.customTheme.selectItem
        },

        transition: (theme) =>
          theme.transitions.create(['background-color'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.complex
          })
      }}
    >
      <Stack
        sx={{
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <ListItemIcon
          className="MuiLinkIcon"
          sx={{
            minWidth: 'auto',
            '& svg': {
              height: '22px'
            },
            '& path': {
              fill: (theme) => theme.palette.common.black
            }
          }}
        >
          {icon}
        </ListItemIcon>
      </Stack>
    </ListItemButton>
  </ListItem>
);
