import { Stack, StackProps, styled } from '@mui/material';

export const AudienceGroupEmptyStack = styled(Stack)<StackProps>(() => ({
  width: '100%',
  height: 250,
  alignItems: 'center',
  justifyContent: 'center',
  gap: '16px',
  '& svg': {
    width: 24,
    height: 24
  }
}));
