import { Stack, StackProps, styled } from '@mui/material';

export const MediaCard = styled(Stack)<StackProps>(({}) => ({
  position: 'relative',
  backgroundColor: 'rgba(242, 242, 242, 0.35)',
  padding: 4,
  minHeight: 100,
  borderRadius: 16,
  alignItems: 'center',
  justifyContent: 'center',
  gap: 8,
  '& label:focus-within': {
    outline: 'none',
    border: 0
  }
}));
