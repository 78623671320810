import { Link, Stack } from '@mui/material';
import { FileType } from '@thrivea/organization-client';
import { FileTypeMediaBox, StyledFixedWidthTypography } from '@/shared';
import { useAppSelector } from '@app/hooks';
import { selectPostReadSasToken } from '@features/homepage';

interface DocFileCardProps {
  src: string;
  name: string;
}

export const DocFileCard: React.FC<DocFileCardProps> = ({ src, name }) => {
  const postReadSasToken = useAppSelector(selectPostReadSasToken);

  return (
    <Stack
      gap={'12px'}
      sx={{
        backgroundColor: '#FAF8F3',
        borderRadius: 4,
        textDecoration: 'none',
        padding: '52px 16px 16px 16px',
        width: 258,
        height: 316,
        border: 0
      }}
    >
      <FileTypeMediaBox width={76} height={97} fileType={FileType.DOCUMENT} />
      <Link
        href={new URL(postReadSasToken, src).toString()}
        type="application/pdf"
        rel="alternate"
        media="print"
        sx={{
          textAlign: 'center'
        }}
      >
        <StyledFixedWidthTypography
          variant="body1"
          sx={{
            textDecoration: 'none',
            fontWeight: 400
          }}
        >
          {name}
        </StyledFixedWidthTypography>
      </Link>
    </Stack>
  );
};
