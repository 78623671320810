import { SvgIcon } from '@mui/material';
import React from 'react';

interface ClockIconAttributes {
  width?: number;
  height?: number;
  color?: string;
}

export const ClockIcon: React.FC<ClockIconAttributes> = ({ width = 20, height = 20, color = '#251D38' }) => {
  return (
    <SvgIcon>
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 20 20">
        <g clipPath="url(#clip0_4353_7608)">
          <path
            d="M9.99999 4.99984V9.99984L13.3333 11.6665M18.3333 9.99984C18.3333 14.6022 14.6024 18.3332 9.99999 18.3332C5.39762 18.3332 1.66666 14.6022 1.66666 9.99984C1.66666 5.39746 5.39762 1.6665 9.99999 1.6665C14.6024 1.6665 18.3333 5.39746 18.3333 9.99984Z"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_4353_7608">
            <rect width={width} height={height} fill="#FFFFFF" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};
