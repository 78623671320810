import { Box, Skeleton, Stack, Typography } from '@mui/material';
import { ActionStatus, RowCenterStack, PictureSizeSuffix, StyledAvatar } from 'src/shared';
import { pictureUrl, initials } from 'src/utils';
import { TableTooltip } from './styled/TableTooltip';
import { useAppSelector } from '@app/hooks';
import { PpdColumn, selectRetrieveTwoStepDataStatus } from '@features/people-directory';
import { MouseEventHandler } from 'react';
import { EmployeeListItem } from '@thrivea/organization-client';

interface PeopleDirectoryTablePersonEntityCellProps {
  column: PpdColumn;
  employeeItem: EmployeeListItem;
  handlePersonItemClick: MouseEventHandler<HTMLElement>;
  employeeProfileSasToken: string;
}

export const PeopleDirectoryTablePersonEntityCell = ({
  employeeItem,
  employeeProfileSasToken,
  handlePersonItemClick
}: PeopleDirectoryTablePersonEntityCellProps): JSX.Element => {
  const retrieveTwoStepDataStatus = useAppSelector(selectRetrieveTwoStepDataStatus);

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        alignItems: 'center'
      }}
    >
      {retrieveTwoStepDataStatus === ActionStatus.Pending && <Skeleton animation="wave" />}
      {retrieveTwoStepDataStatus === ActionStatus.Idle && (
        <RowCenterStack gap={'28px'}>
          <StyledAvatar
            onClick={handlePersonItemClick}
            src={pictureUrl(employeeItem.profilePictureUrl, employeeProfileSasToken, PictureSizeSuffix.sm)}
            width={36}
            height={36}
          >
            {initials(employeeItem.displayName)}
          </StyledAvatar>
          <Stack>
            <Typography variant="body2" sx={{ fontWeight: 700 }} onClick={handlePersonItemClick}>
              {employeeItem.displayName}
            </Typography>
            <TableTooltip
              title={employeeItem.emailAddress}
              children={
                <Typography variant="body2" onClick={handlePersonItemClick}>
                  {employeeItem.emailAddress}
                </Typography>
              }
            />
          </Stack>
        </RowCenterStack>
      )}
    </Box>
  );
};
