import { Box, Typography } from '@mui/material';
import { TreeItem2Label } from '@mui/x-tree-view/TreeItem2';
import { ElementType, ReactNode } from 'react';
import { FolderIcon } from '@/shared';

interface AssetsTreeItemLabelProps {
  children: ReactNode;
  icon?: ElementType;
  expandable?: boolean;
  nestingLevel: number;
}

export const AssetsTreeItemLabel: React.FC<AssetsTreeItemLabelProps> = ({ children, icon, expandable, nestingLevel, ...props }) => {
  return (
    <TreeItem2Label
      {...props}
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '8px'
      }}
    >
      {icon && (
        <Box
          sx={{
            minWidth: 20
          }}
        >
          <FolderIcon width={20} height={20} color="#717680" />
        </Box>
      )}
      <Typography
        noWrap
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          fontWeight: 500
        }}
      >
        {children}
      </Typography>
    </TreeItem2Label>
  );
};
