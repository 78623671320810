import { IconButton, styled } from '@mui/material';

export const ExpandIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'isActive'
})<{ isActive: boolean }>(({ isActive }) => ({
  padding: 0,
  backgroundColor: 'transparent',
  gap: '4px',
  marginLeft: 'auto',
  '& svg': {
    transition: 'transform 0.3s ease-in-out',
    transform: `rotate(${isActive ? -180 : 0}deg)`
  }
}));
