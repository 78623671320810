import React, { useState, useEffect } from 'react';
import { Stack, Modal, Avatar, Box } from '@mui/material';
import {
  BinIcon,
  CancelEditIcon,
  CloseIcon,
  RegularPenIcon,
  UploadIcon,
  ImageCropper,
  MultiSizeImageBlobs,
  PrimaryDarkButton,
  RowCenterEndStack,
  RowCenterJustifyBetweenStack,
  RowCenterStack,
  StackCenterAlign,
  StyledBlurryModal,
  StyledFormSubmitButton,
  StyledIconButton,
  StyledModalContent,
  StyledTransparentButton,
  StyledTransparentIconButton
} from '@/shared';
import { fileFromUrl, IMAGE_FILE_TYPES } from '@/utils';
import { FileUploader } from 'react-drag-drop-files';
import { resetEmployeeProfilePictureFileMultipleSizes } from '@features/employee-profile';
import { useAppDispatch } from '@app/hooks';
import { useTranslation } from 'react-i18next';

interface EmployeeRecordCoverImageProps {
  sm?: number;
  md?: number;
  lg?: number;
  uploadTextLabel: string;
  placeholderSrc: string;
  urlSrc: string;
  imageAltText: string;
  multiSizeImage: MultiSizeImageBlobs | undefined | null;
  onChange: (file: MultiSizeImageBlobs) => void;
  onRemove: () => void;
  onUpdate: () => void;
}

export const EmployeeRecordCoverImage: React.FC<EmployeeRecordCoverImageProps> = ({
  sm = 36,
  md = 46,
  lg = 150,
  placeholderSrc,
  urlSrc,
  imageAltText,
  multiSizeImage,
  onChange,
  onRemove,
  onUpdate
}) => {
  const { t } = useTranslation('common');
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [cropperFile, setCropperFile] = useState<File | null>(null);
  const [src, setSrc] = useState('');
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  useEffect(() => {
    if (multiSizeImage === undefined) {
      setSrc(urlSrc || placeholderSrc);
    }
  }, [urlSrc, placeholderSrc, multiSizeImage]);

  const handleEditButtonClick = () => {
    setIsEditModalOpen(true);
  };

  const handleEditModalClose = () => {
    setIsEditModalOpen(false);
  };

  const handleCrop = (croppedDataUrl: MultiSizeImageBlobs) => {
    onChange(croppedDataUrl);
    setOpen(false);
  };

  const handleFileChangeClick = (file: File) => {
    setCropperFile(file);
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  const handleRemoveButtonClick = () => {
    setSrc(placeholderSrc);
    onRemove();
  };

  const handleEditClick = async () => {
    const file = await fileFromUrl(urlSrc, 'profilePicture', 'image/jpeg');
    handleFileChangeClick(file);
  };

  const handleCancel = () => {
    dispatch(resetEmployeeProfilePictureFileMultipleSizes());
    setIsEditModalOpen(false);
  };

  const handleUpdateButtonClick = () => {
    onUpdate();
    setIsEditModalOpen(false);
  };

  return (
    <>
      <Stack
        sx={{
          position: 'relative'
        }}
      >
        <Box
          component={'img'}
          src={multiSizeImage ? URL.createObjectURL(multiSizeImage.lg) : src}
          sx={{
            width: '100%',
            height: 152,
            objectFit: 'cover'
          }}
        />
        <StyledIconButton
          onClick={handleEditButtonClick}
          sx={{
            position: 'absolute',
            bottom: -16,
            right: 16,
            borderRadius: '50%',
            backgroundColor: (theme) => theme.palette.customTheme.navColor,
            zIndex: (theme) => theme.zIndex.tooltip
          }}
        >
          <RegularPenIcon color="#FFFFFF" />
        </StyledIconButton>
      </Stack>
      <StyledBlurryModal open={isEditModalOpen} onClose={handleEditModalClose}>
        <StyledModalContent sx={{ width: 600, padding: 2, borderRadius: '4px' }}>
          <StackCenterAlign gap={2}>
            <RowCenterEndStack sx={{ width: '100%' }}>
              <StyledTransparentIconButton onClick={handleEditModalClose}>
                <CloseIcon />
              </StyledTransparentIconButton>
            </RowCenterEndStack>
            <Avatar
              variant="square"
              src={multiSizeImage ? URL.createObjectURL(multiSizeImage.lg) : src}
              alt={imageAltText}
              sx={{
                width: '90%',
                height: 280,
                zIndex: 1,
                objectFit: 'contain'
              }}
            />
            <RowCenterJustifyBetweenStack sx={{ width: '100%', marginTop: 3 }}>
              <RowCenterStack gap={3}>
                <StyledTransparentButton onClick={handleEditClick} startIcon={<RegularPenIcon width={20} height={20} color="#251D38" />}>
                  {t('edit', { ns: 'common' })}
                </StyledTransparentButton>
                <FileUploader multiple={false} handleChange={handleFileChangeClick} name="file" maxSize={10} types={IMAGE_FILE_TYPES} classes="Mui-FileUpload">
                  <StyledTransparentButton startIcon={<UploadIcon width={20} height={20} color="#251D38" />}>Upload</StyledTransparentButton>
                </FileUploader>
                <StyledTransparentButton onClick={handleRemoveButtonClick} startIcon={<BinIcon width={20} height={20} customColor="#251D38" />}>
                  {t('remove', { ns: 'common' })}
                </StyledTransparentButton>
              </RowCenterStack>
              <RowCenterStack gap={1}>
                <PrimaryDarkButton variant="contained" startIcon={<CancelEditIcon />} onClick={handleCancel}>
                  {t('cancel', { ns: 'common' })}
                </PrimaryDarkButton>
                <StyledFormSubmitButton onClick={handleUpdateButtonClick} variant="contained">
                  {t('update', { ns: 'common' })}
                </StyledFormSubmitButton>
              </RowCenterStack>
            </RowCenterJustifyBetweenStack>
          </StackCenterAlign>
        </StyledModalContent>
      </StyledBlurryModal>
      <Modal
        open={open}
        onClose={handleModalClose}
        sx={{
          background: 'rgba(217, 217, 217, 0.60)',
          backdropFilter: 'blur(10px)'
        }}
      >
        <StyledModalContent sx={{ minWidth: 1000, padding: 0, borderRadius: '4px' }}>
          {cropperFile && <ImageCropper src={cropperFile} onCrop={handleCrop} handleModalClose={handleModalClose} sm={sm} md={md} lg={lg} />}
        </StyledModalContent>
      </Modal>
    </>
  );
};
