import { Avatar, AvatarProps, styled } from '@mui/material';
import { forwardRef } from 'react';

interface StyledAvatarProps extends AvatarProps {
  width?: number;
  height?: number;
  position?: number;
}

export const StyledAvatar = styled(
  forwardRef<HTMLDivElement, StyledAvatarProps>(({ width = 36, height = 36, position, ...props }, ref) => (
    <Avatar
      variant="circular"
      ref={ref}
      slotProps={{
        img: {
          loading: 'lazy',
          width,
          height
        }
      }}
      {...props}
    />
  ))
)(({ theme, width, height, position }) => ({
  width,
  height,
  fontSize: 14,
  zIndex: position ?? 1,
  cursor: 'pointer'
}));
