import React from 'react';

interface PermissionLockIconProps {
  width?: number;
  height?: number;
  color?: string;
}

export const PermissionLockIcon: React.FC<PermissionLockIconProps> = ({ width = 20, height = 20, color = '#717680' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="12" cy="16" r="1" />
      <rect x="3" y="10" width="18" height="12" rx="2" />
      <path d="M7 10V7a5 5 0 0 1 10 0v3" />
    </svg>
  );
};
