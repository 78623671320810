import React from 'react';

interface ChevronLeftIconProps {
  width?: number; // Optional size prop to control the icon size
  height?: number; // Optional size prop to control the icon size
  color?: string; // Optional color prop to control the icon color
}

export const ChevronLeftIcon: React.FC<ChevronLeftIconProps> = ({ width = 12, height = 7, color = '#251D38' }: ChevronLeftIconProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 18L9 12L15 6" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
