import { CreateSingleTaskRequest, Reaction } from '@thrivea/organization-client';
import { CreateSingleTaskRequestSchema } from '@features/tasks';

// const generateHashSync = (fingerprint: string): string => {
//   const encoder = new TextEncoder();
//   const data = encoder.encode(fingerprint);

//   // Use a Promise and block execution until resolved
//   let result: string | undefined;

//   const worker = new Promise<string>((resolve) => {
//     crypto.subtle.digest('SHA-256', data).then((hashBuffer) => {
//       const hashArray = Array.from(new Uint8Array(hashBuffer));
//       const hashHex = hashArray.map((byte) => byte.toString(16).padStart(2, '0')).join('');
//       resolve(hashHex);
//     });
//   });

//   // Block execution until the promise is resolved
//   worker.then((hash) => {
//     result = hash;
//   });

//   while (result === undefined) {
//     // Busy waiting (not recommended for large tasks)
//   }

//   return result;
// };

const generateHash = async (fingerprint: string): Promise<string> => {
  const encoder = new TextEncoder();
  const data = encoder.encode(fingerprint);

  const hashBuffer = await crypto.subtle.digest('SHA-256', data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map((byte) => byte.toString(16).padStart(2, '0')).join('');

  return hashHex;
};

/**
 * Generate a unique public ID for a SingleTask entity.
 * @param task - The SingleTask object to generate the public ID for.
 * @returns A string representing the public ID.
 */
export const generateSingleTaskPublicId = async (): Promise<string> => {
  // Step 1: Create a fingerprint based on task properties
  // const fingerprint = `${task.taskListId}:${task.title}:${task.description}:${task.createdAt}`;
  const fingerprint = crypto.randomUUID();

  // Step 2: Generate a hash from the fingerprint using SHA-256
  const hash = await generateHash(fingerprint);

  // Step 3: Optionally shorten the hash for readability (e.g., use first 8 characters)
  const publicId = hash.substring(0, 8);

  // Prefix with "T-"
  return `T-${publicId}`;
};

/**
 * Generate a unique public ID for a TaskList entity.
 * @param task - The TaskList object to generate the public ID for.
 * @returns A string representing the public ID.
 */
// export const generateTaskListPublicId = (task: TaskListItem): string => {
//   // Step 1: Create a fingerprint based on task properties
//   const fingerprint = `${task.taskListId}:${task.title}:${task.description}:${task.createdAt}`;

//   // Step 2: Generate a hash from the fingerprint using SHA-256
//   const hash = generateHashSync(fingerprint);

//   // Step 3: Optionally shorten the hash for readability (e.g., use first 12 characters)
//   const publicId = hash.substring(0, 12);

//   // Prefix with "T-"
//   return `TL-${publicId}`;
// };

export const validateSingleTaskForm = (form: CreateSingleTaskRequest) => {
  const result = CreateSingleTaskRequestSchema.safeParse(form);
  return {
    isValid: result.success,
    errors: result.success ? {} : result.error.formErrors.fieldErrors
  };
};
