import { SvgIcon } from '@mui/material';
import React from 'react';

interface ImageTypeFileIconProps {
  width?: number;
  height?: number;
  color?: string;
}

export const ImageTypeFileIcon: React.FC<ImageTypeFileIconProps> = ({ width = 24, height = 24, color = '#000000' }) => {
  return (
    <SvgIcon
      sx={{
        width: 'unset',
        height: 'unset'
      }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 76 76" fill="none">
        <path
          d="M1.5 8C1.5 4.41015 4.41015 1.5 8 1.5H68C71.5898 1.5 74.5 4.41015 74.5 8V68C74.5 71.5899 71.5899 74.5 68 74.5H8C4.41015 74.5 1.5 71.5899 1.5 68V8Z"
          stroke={color}
          strokeWidth="3"
        />
        <path d="M2.5 59L22.5 39L34 50.5M58 74.5L34 50.5M34 50.5L58 26.5L74 42.5" stroke={color} strokeWidth="3" strokeLinejoin="round" />
        <circle cx="17" cy="17" r="5.5" stroke={color} strokeWidth="3" />
      </svg>
    </SvgIcon>
  );
};
