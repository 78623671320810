import { Empty } from '@bufbuild/protobuf/wkt';
import { createClient } from '@connectrpc/connect';
import { createGrpcWebTransport } from '@connectrpc/connect-web';
import {
  NetworkingAnalyticsService,
  RetrievePostAnalyticsRequest,
  RetrievePostAnalyticsResponse,
  SendPostAnalyticsRequest
} from '@thrivea/networking-analytics-client';
import { msalInstance } from '@/main';
import { tokenRequest } from '@/utils';

const networkAnalyticsClient = createClient(
  NetworkingAnalyticsService,
  createGrpcWebTransport({
    baseUrl: import.meta.env.VITE_APP_API_URL,
    useBinaryFormat: true
  })
);

export const sendPostAnalytics = async (request: SendPostAnalyticsRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await networkAnalyticsClient.sendPostAnalytics(request, { headers: new Headers({ Authorization: `Bearer ${account.accessToken}` }) });
};

export const retrievePostAnalytics = async (request: RetrievePostAnalyticsRequest): Promise<RetrievePostAnalyticsResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await networkAnalyticsClient.retrievePostAnalytics(request, { headers: new Headers({ Authorization: `Bearer ${account.accessToken}` }) });
};
