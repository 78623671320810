import { styled, Chip, ChipProps, chipClasses } from '@mui/material';

export const AudienceChip = styled(Chip)<ChipProps>(({ theme }) => ({
  [`&.${chipClasses.root}`]: {
    color: theme.palette.common.black,
    fontWeight: 400,
    height: 22
  },
  [`& .${chipClasses.icon}`]: {
    color: theme.palette.customTheme.focusItem
  }
}));
