import { Box, BoxProps, styled } from '@mui/material';
import { shouldForwardProp } from '@mui/system';

interface DropDownSearchBoxProps extends BoxProps {
  isOpen?: boolean;
}

export const DropDownSearchBox = styled(Box, {
  shouldForwardProp: (prop) => shouldForwardProp(prop) && prop !== 'isOpen'
})<DropDownSearchBoxProps>(({ theme, isOpen }) => ({
  width: '100%',
  zIndex: theme.zIndex.tooltip,
  position: 'relative',
  borderTopRightRadius: '8px',
  borderTopLeftRadius: '8px',
  border: isOpen ? '1px solid' : 0,
  borderBottom: 0,
  borderColor: theme.palette.primary.main,
  backgroundColor: isOpen ? theme.palette.common.white : theme.palette.customTheme.primaryA
}));
