import { Stack, StackProps, styled } from '@mui/material';

interface CardTypeContentStyleProps {
  backgroundColor: string;
  borderColor: string;
  textColor: string;
}

export enum CardType {
  Visa,
  MasterCard,
  AmericanExpress
}

interface CardTypeContentProps extends StackProps {
  cardType: CardType;
}

export const CardTypeContent = styled(Stack)<CardTypeContentProps>(({ cardType, theme }) => {
  const row: CardTypeContentStyleProps[] = [
    { backgroundColor: '#FFFFFF', borderColor: '#DFDEDA', textColor: theme.palette.primary.main },
    { backgroundColor: '#F4F2ED', borderColor: 'transparent', textColor: theme.palette.primary.main },
    { backgroundColor: '#F4F2ED', borderColor: 'transparent', textColor: theme.palette.primary.main }
  ];

  let idx = CardType.Visa;
  if (cardType === CardType.MasterCard) idx = CardType.MasterCard;
  if (cardType === CardType.AmericanExpress) idx = CardType.AmericanExpress;

  return {
    backgroundColor: row[idx].backgroundColor,
    color: row[idx].textColor,
    padding: '16px',
    alignItems: 'flex-start',
    gap: '10px',
    border: '1px solid',
    borderTopLeftRadius: '16px',
    borderTopRightRadius: '16px',
    borderColor: row[idx].borderColor
  };
});
