import { EmptySchema } from '@bufbuild/protobuf/wkt';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
  retrieveInitialAppStateAndUserFailed,
  retrieveInitialAppStateAndUserRequested,
  retrieveInitialAppStateAndUserSucceeded
} from '@features/protected-routes';
import { retrieveInitialSetup } from '@api/organization.api';
import { RetrieveErTemplateRequestSchema, RetrieveInitialSetupResponse } from '@thrivea/organization-client';
import { setCurrentEmployeeIdAndTenantId, retrieveEmployeesSucceeded } from '@features/shared';
import { setCurrentAuthorId } from '@features/homepage';
import * as Sentry from '@sentry/react';
import { retrieveGroupsRequested, retrieveAudiencesByEmployeeIdRequested, setAbacUserId } from '@features/abac';
import { RetrieveAudiencesByEmployeeIdRequestSchema } from '@thrivea/networking-client';
import { RetrieveGroupsRequestSchema } from '@thrivea/auth-client';
import { hasUnreadNotificationsRequested, retrieveLastNotificationDateTimeRequested, retrieveNotificationsRequested } from '@features/notifications';
import { CheckNewNotificationRequestSchema, HasUnreadNotificationsRequestSchema, RetrieveNotificationsRequestSchema } from '@thrivea/notification-client';
import { Code, ConnectError } from '@connectrpc/connect';
import { redirectToErrorPage } from '@app/error';
import { RetrieveLatestDeploymentRequestSchema } from '@thrivea/admin-onboarding-client';
import { retrieveLatestDeploymentRequested } from '@features/snackbar';
import {
  loadPaymentMethods,
  loadSubscriptionAndPaymentMethodsRequested,
  retrieveOrganizationLogoSasTokenRequested,
  retrieveOrganizationRequested
} from '@features/admin-settings';
import { retrieveEmployeeProfileAndCoverReadSasTokenRequested } from '@features/employee-profile';
import { RetrievePaymentMethodsResponse } from '@thrivea/payment-client';
import { retrievePaymentMethods } from '@api/payment.api';
import { DEFAULT_ER_TEMPLATE_ID, retrieveErTemplateRequested } from '@features/employee-record-builder';
import { retrieveErbFieldsRequested } from '@features/people-directory';
import { create } from '@bufbuild/protobuf';

interface InitialAppStateAndUser {
  initialSetup: RetrieveInitialSetupResponse;
  paymentMethodsResponse: RetrievePaymentMethodsResponse;
}

function* retrieveInitialAppStateAndUserGenerator() {
  let initialData: InitialAppStateAndUser | undefined;

  try {
    initialData = yield all({
      initialSetup: call(retrieveInitialSetup, create(EmptySchema)),
      paymentMethodsResponse: call(retrievePaymentMethods, create(EmptySchema))
    });
  } catch (error) {
    Sentry.captureException(error);
    if (error instanceof ConnectError && error.code !== Code.InvalidArgument) {
      yield put(redirectToErrorPage(error.code));
      return;
    }

    yield put(retrieveInitialAppStateAndUserFailed());
  }

  const { employeeId, tenantId, adminOnboardingFlowStateResponse, employeesResponse } = initialData!.initialSetup;
  const { paymentMethods } = initialData!.paymentMethodsResponse;

  // NOTE: Order is important here
  yield put(retrieveInitialAppStateAndUserSucceeded(adminOnboardingFlowStateResponse!));
  yield put(retrieveEmployeesSucceeded(employeesResponse!));
  yield put(setCurrentEmployeeIdAndTenantId({ employeeId, tenantId }));

  yield put(setCurrentAuthorId(employeeId));
  yield put(setAbacUserId(employeeId));
  yield put(loadPaymentMethods(paymentMethods));

  yield put(retrieveAudiencesByEmployeeIdRequested(create(RetrieveAudiencesByEmployeeIdRequestSchema, { employeeId })));

  yield put(retrieveLastNotificationDateTimeRequested(create(CheckNewNotificationRequestSchema, { employeeId })));
  yield put(retrieveNotificationsRequested(create(RetrieveNotificationsRequestSchema, { employeeId, pageNumber: 1, pageSize: 10 })));
  yield put(hasUnreadNotificationsRequested(create(HasUnreadNotificationsRequestSchema, { employeeId })));

  yield put(retrieveOrganizationRequested());
  yield put(retrieveOrganizationLogoSasTokenRequested());
  yield put(retrieveEmployeeProfileAndCoverReadSasTokenRequested());

  yield put(retrieveErTemplateRequested(create(RetrieveErTemplateRequestSchema, { erTemplateId: DEFAULT_ER_TEMPLATE_ID })));
  yield put(retrieveGroupsRequested(create(RetrieveGroupsRequestSchema, { employeeId })));

  yield put(loadSubscriptionAndPaymentMethodsRequested());
  // Needed for ErbFieldTable on Employee Record page
  yield put(retrieveErbFieldsRequested());

  yield put(retrieveLatestDeploymentRequested(create(RetrieveLatestDeploymentRequestSchema, { branch: '' })));
}

function* retrieveInitialAppStateAndUserRequestedWatcher() {
  yield takeLatest(retrieveInitialAppStateAndUserRequested.type, retrieveInitialAppStateAndUserGenerator);
}

export function* protectedRoutesSagas() {
  yield fork(retrieveInitialAppStateAndUserRequestedWatcher);
}
