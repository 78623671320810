import { ForwardedRef, forwardRef, useEffect, useId, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { createWorkContactSchema, selectProfileNotification, selectWorkContactCategory, updateWorkContactDetailsRequested } from '@features/employee-profile';
import { Controller, useForm } from 'react-hook-form';
import { Box, CircularProgress, IconButton, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import {
  RowCenterStack,
  VisuallyHidden,
  EditIcon,
  CancelEditIcon,
  StyledFormWrapper,
  StyledFormHeader,
  PrimaryDarkButton,
  StyledFormSubmitButton,
  StyledFilledInput,
  ActionStatus
} from '@/shared';
import { UpdateWorkContactDetailsRequest, WorkContactDetails } from '@thrivea/organization-client';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { snakeCase } from 'lodash';
import { AllowedTo } from 'react-abac';
import { GroupPermissions, ProfileCategoryInfo } from '@features/abac';

interface WorkContactCategoryProps {
  ref: ForwardedRef<HTMLDivElement>;
  employeeId: string;
}

export const WorkContactCategory = forwardRef<HTMLDivElement, WorkContactCategoryProps>(({ employeeId }, ref) => {
  const { t } = useTranslation(['common', 'employee_profile']);
  const name = t('work_contact_details', { ns: 'employee_profile' });
  const dispatch = useAppDispatch();
  const id = useId();
  const workContactCategory = useAppSelector(selectWorkContactCategory);
  const { status, category } = useAppSelector(selectProfileNotification);
  const isPendingAndMatchingName = status === ActionStatus.Pending && category === 'workContactDetails';
  const [isEditable, setIsEditable] = useState(false);
  const workContactCategorySchema = useMemo(() => createWorkContactSchema(t), [t]);
  const {
    formState: { dirtyFields, errors },
    control,
    reset,
    handleSubmit
  } = useForm<WorkContactDetails>({
    mode: 'all',
    resolver: zodResolver(workContactCategorySchema),
    defaultValues: {
      workMobilePhoneNumber: workContactCategory.workMobilePhoneNumber,
      workLandlinePhoneNumber: workContactCategory.workLandlinePhoneNumber,
      slackUsername: workContactCategory.slackUsername,
      skypeUsername: workContactCategory.skypeUsername
    }
  });

  const handleCloseEditable = () => {
    setIsEditable(false);
    reset({
      workMobilePhoneNumber: workContactCategory.workMobilePhoneNumber,
      workLandlinePhoneNumber: workContactCategory.workLandlinePhoneNumber,
      slackUsername: workContactCategory.slackUsername,
      skypeUsername: workContactCategory.skypeUsername
    });
  };

  const handleToggleEditable = () => {
    setIsEditable(!isEditable);
  };

  const onSubmit = (data: WorkContactDetails) => {
    dispatch(
      updateWorkContactDetailsRequested({
        employeeId: employeeId,
        workMobilePhoneNumber: data.workMobilePhoneNumber,
        workLandlinePhoneNumber: data.workLandlinePhoneNumber,
        slackUsername: data.slackUsername,
        skypeUsername: data.skypeUsername
      } as UpdateWorkContactDetailsRequest)
    );
    setIsEditable(false);
  };

  useEffect(() => {
    reset({
      workMobilePhoneNumber: workContactCategory.workMobilePhoneNumber,
      workLandlinePhoneNumber: workContactCategory.workLandlinePhoneNumber,
      slackUsername: workContactCategory.slackUsername,
      skypeUsername: workContactCategory.skypeUsername
    });
  }, [workContactCategory]);

  return (
    <StyledFormWrapper isEditable={isEditable} id={snakeCase(name)} ref={ref}>
      <Box
        component="form"
        name={name}
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          backgroundColor: 'transparent'
        }}
      >
        <Stack>
          <StyledFormHeader isEditable={isEditable} className="Mui-ProfileFiledHeader">
            <RowCenterStack gap={1}>
              {isPendingAndMatchingName && <CircularProgress size={24} thickness={4} />}
              <Typography component="h3" variant="h5" fontWeight={700}>
                {name}
              </Typography>
            </RowCenterStack>
            <AllowedTo perform={GroupPermissions.EDIT_PROFILE} data={{ employeeId, categoryName: 'workContactCategory' } as ProfileCategoryInfo}>
              <IconButton
                onClick={handleToggleEditable}
                sx={{
                  opacity: '0',
                  display: isEditable ? 'none' : 'inline-flex'
                }}
              >
                <VisuallyHidden>Edit {name}</VisuallyHidden>
                <EditIcon />
              </IconButton>
            </AllowedTo>
            <RowCenterStack
              gap={2}
              sx={{
                display: isEditable ? 'flex' : 'none'
              }}
            >
              <PrimaryDarkButton variant="contained" onClick={handleCloseEditable} startIcon={<CancelEditIcon />}>
                {t('cancel', { ns: 'common' })}
              </PrimaryDarkButton>
              <StyledFormSubmitButton
                type="submit"
                disabled={Object.values(dirtyFields).length === 0 || Object.values(errors).length !== 0}
                variant="contained"
              >
                {t('done', { ns: 'common' })}
              </StyledFormSubmitButton>
            </RowCenterStack>
          </StyledFormHeader>
          <Grid
            container
            spacing={2}
            sx={{
              padding: 2
            }}
          >
            <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
              <Controller
                name="workMobilePhoneNumber"
                control={control}
                render={({ field, fieldState }) => (
                  <StyledFilledInput
                    {...field}
                    id={id + field.name}
                    label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                    disabled={!isEditable}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    onChange={(e) => {
                      const val = e.target.value;
                      if (val.match(/[^0-9]/)) {
                        return e.preventDefault();
                      }
                      field.onChange(e.currentTarget.value);
                    }}
                    inputProps={{ inputMode: 'numeric' }}
                  />
                )}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
              <Controller
                name="workLandlinePhoneNumber"
                control={control}
                render={({ field, fieldState }) => (
                  <StyledFilledInput
                    {...field}
                    id={id + field.name}
                    label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                    disabled={!isEditable}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    onChange={(e) => {
                      const val = e.target.value;
                      if (val.match(/[^0-9]/)) {
                        return e.preventDefault();
                      }
                      field.onChange(e.currentTarget.value);
                    }}
                    inputProps={{ inputMode: 'numeric' }}
                  />
                )}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
              <Controller
                name="slackUsername"
                control={control}
                render={({ field, fieldState }) => (
                  <StyledFilledInput
                    {...field}
                    id={id + field.name}
                    label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                    disabled={!isEditable}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    onChange={(e) => field.onChange(e)}
                  />
                )}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
              <Controller
                name="skypeUsername"
                control={control}
                render={({ field, fieldState }) => (
                  <StyledFilledInput
                    {...field}
                    id={id + field.name}
                    label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                    disabled={!isEditable}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    onChange={(e) => field.onChange(e)}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Stack>
      </Box>
    </StyledFormWrapper>
  );
});
