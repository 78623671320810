import React from 'react';

interface CloseIconAttributes {
  width?: number;
  height?: number;
  color?: string;
}

export const CloseIcon: React.FC<CloseIconAttributes> = ({ width = 18, height = 19, color = '#251D38' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
      <path d="M17 7L7 17M7 7L17 17" stroke={color} strokeOpacity="0.45" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
