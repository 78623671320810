import { Chip, chipClasses, ChipProps, styled } from '@mui/material';
import { ASSIGNED, COMPLETED, DRAFT, IN_REVIEW } from '@features/tasks';

interface TaskStatusChipStyleProps {
  backgroundColor: string;
  textColor: string;
}

interface TaskStatusChipProps extends ChipProps {
  statusId: string;
}

export const TaskStatusChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'statusId'
})<TaskStatusChipProps>(({ statusId, theme }) => {
  // Define an array for row styling based on TaskStatus

  const row: Record<string, TaskStatusChipStyleProps> = {
    [DRAFT]: { backgroundColor: '#BEBABA', textColor: theme.palette.primary.main },
    [ASSIGNED]: { backgroundColor: theme.palette.customTheme.selectItem, textColor: theme.palette.primary.main },
    [IN_REVIEW]: { backgroundColor: '#FCC75D', textColor: theme.palette.primary.main },
    [COMPLETED]: { backgroundColor: theme.palette.secondary.main, textColor: theme.palette.common.white }
  };

  return {
    backgroundColor: row[statusId].backgroundColor,
    color: row[statusId].textColor,
    width: 90,
    height: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '3px 7px',
    borderRadius: 8,
    [`& .${chipClasses.label}`]: {
      fontSize: 12,
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: 'normal',
      padding: 0
    }
  };
});
