import { styled } from '@mui/material';
import Grid, { Grid2Props } from '@mui/material/Grid2';

export const GridContentItem = styled(Grid)<Grid2Props>(({ theme }) => ({
  width: 'calc(100% - 106px)',
  height: 'calc(100vh - 32px)',
  paddingLeft: '16px',
  marginLeft: 0,
  [theme.breakpoints.up('md')]: {
    marginLeft: 106
  }
}));
