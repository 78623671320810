import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { RetrieveEmployeesByQueryRequest, RetrieveEmployeesByQueryResponse } from '@thrivea/organization-client';
import { retrieveEmployeesByQuery } from '@api/employees.api';
import { retrieveEmployeesRequested, retrieveEmployeesSucceeded, retrieveEmployeesFailed } from '@features/shared';

function* retrieveEmployeesRequestedGenerator(action: PayloadAction<RetrieveEmployeesByQueryRequest>) {
  try {
    const response: RetrieveEmployeesByQueryResponse = yield call(retrieveEmployeesByQuery, action.payload);
    yield put(retrieveEmployeesSucceeded(response));
  } catch (error) {
    yield put(retrieveEmployeesFailed());
  }
}

function* retrieveEmployeesRequestedWatcher() {
  yield takeLatest(retrieveEmployeesRequested.type, retrieveEmployeesRequestedGenerator);
}

export function* employeesSagas() {
  yield all([fork(retrieveEmployeesRequestedWatcher)]);
}
