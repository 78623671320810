import { Empty } from '@bufbuild/protobuf';
import { createClient } from '@connectrpc/connect';
import { createGrpcWebTransport } from '@connectrpc/connect-web';
import {
  EmployeeProfileService,
  RetrieveSitesResponse,
  RetrieveDepartmentsResponse,
  UpdateProfileMediaRequest
} from '@thrivea/organization-client';
import { msalInstance } from '@/main';
import { tokenRequest } from '@utils/authConfig';

export const employeeProfileClient = createClient(
  EmployeeProfileService,
  createGrpcWebTransport({
    baseUrl: import.meta.env.VITE_APP_API_URL,
    useBinaryFormat: true
  })
);

export const retrieveSites = async (request: Empty): Promise<RetrieveSitesResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await employeeProfileClient.retrieveSites(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveDepartments = async (request: Empty): Promise<RetrieveDepartmentsResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await employeeProfileClient.retrieveDepartments(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const updateProfileMedia = async (request: UpdateProfileMediaRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);
  return await employeeProfileClient.updateProfileMedia(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};
