import { all, fork, put, takeLatest } from 'redux-saga/effects';
import {
  RetrieveEmployeeBasicInfoRequest,
  RetrieveEmployeesByJobTitleRequest,
  RetrieveEmployeesBySiteIdRequest,
  RetrieveEmployeesByWorkingPatternIdRequest,
  RetrieveErbFieldsByCategoryIdRequest,
  RetrieveFilesAccessRequest,
  RetrieveFilesInfoRequest,
  RetrieveSiteByIdRequest,
  RetrieveSitesByWorkingPatternIdRequest
} from '@thrivea/organization-client';
import { PayloadAction } from '@reduxjs/toolkit';
import { openDrawer, resetDrawer } from './drawer.slice';
import {
  AssetsDrawerRequest,
  AssetsMultipleFilesDrawerRequest,
  AssetsShareFileDrawerRequest,
  AudienceDrawerRequest,
  AudienceRoleDrawerRequest,
  DrawerType,
  EmployeeRecordDrawerRequest,
  JobTitleDrawerRequest,
  NotificationsDrawerRequest,
  OpenDrawerRequest,
  PostAnalyticsDrawerRequest,
  SingleEmployeeDrawerRequest,
  SiteDrawerRequest,
  WorkingPatternDrawerRequest
} from './drawer.model';
import {
  retrieveSiteByIdRequested,
  retrieveEmployeesBySiteIdRequested,
  retrieveEmployeeBasicInfoRequested,
  retrieveEmployeesByJobTitleRequested,
  retrieveEmployeesByWorkingPatternIdRequested,
  retrieveSitesByWorkingPatternIdRequested,
  setCurrentWorkingPatternDrawer,
  retrieveAudienceByIdRequested,
  setCurrentAudienceRole
} from '../admin-settings';
import { RetrieveAudienceByIdRequest } from '@thrivea/networking-client';
import { setActiveFile, retrieveFilesInfoRequested, retrieveFilesAccessRequested } from '../assets';
import { RetrieveNotificationsRequest } from '@thrivea/notification-client';
import { retrieveNewNotificationsRequested } from '../notifications';
import { RetrievePostAnalyticsRequest } from '@thrivea/networking-analytics-client';
import { retrievePostAnalyticsRequested } from '../homepage';
import { retrieveErbFieldsByCategoryIdRequested, setActiveSectionId } from '../employee-record-builder';

function* openDrawerGenerator(action: PayloadAction<OpenDrawerRequest>) {
  const { type } = action.payload;

  switch (type) {
    case DrawerType.Site:
      const { siteId } = action.payload.request as SiteDrawerRequest;

      yield put(
        retrieveSiteByIdRequested(
          new RetrieveSiteByIdRequest({
            id: siteId
          })
        )
      );

      yield put(
        retrieveEmployeesBySiteIdRequested(
          new RetrieveEmployeesBySiteIdRequest({
            siteId,
            pageNumber: 1,
            pageSize: 20
          })
        )
      );

      break;
    case DrawerType.SingleEmployee:
      const { employeeId } = action.payload.request as SingleEmployeeDrawerRequest;

      yield put(
        retrieveEmployeeBasicInfoRequested(
          new RetrieveEmployeeBasicInfoRequest({
            employeeId
          })
        )
      );

      break;
    case DrawerType.WorkingPattern:
      const { workingPatternId, workingPatternName } = action.payload.request as WorkingPatternDrawerRequest;

      yield put(
        retrieveEmployeesByWorkingPatternIdRequested(
          new RetrieveEmployeesByWorkingPatternIdRequest({
            workingPatternId,
            pageNumber: 1,
            pageSize: 10
          })
        )
      );

      yield put(
        retrieveSitesByWorkingPatternIdRequested(
          new RetrieveSitesByWorkingPatternIdRequest({
            workingPatternId,
            pageNumber: 1,
            pageSize: 10
          })
        )
      );

      yield put(
        setCurrentWorkingPatternDrawer({
          id: workingPatternId,
          name: workingPatternName
        })
      );

      break;
    case DrawerType.JobTitle:
      const { jobTitle } = action.payload.request as JobTitleDrawerRequest;

      yield put(
        retrieveEmployeesByJobTitleRequested(
          new RetrieveEmployeesByJobTitleRequest({
            jobTitle,
            pageNumber: 1,
            pageSize: 10
          })
        )
      );

      break;
    case DrawerType.Audience:
      const { audienceId } = action.payload.request as AudienceDrawerRequest;

      yield put(retrieveAudienceByIdRequested(new RetrieveAudienceByIdRequest({ audienceId })));

      break;
    case DrawerType.AudienceRole:
      const { role } = action.payload.request as AudienceRoleDrawerRequest;

      yield put(setCurrentAudienceRole(role));

      break;
    case DrawerType.PostAnalytics:
      const { postId } = action.payload.request as PostAnalyticsDrawerRequest;

      yield put(
        retrievePostAnalyticsRequested(
          new RetrievePostAnalyticsRequest({
            postId
          })
        )
      );

      break;
    case DrawerType.Notifications:
      const { hasUnreadNotifications, notificationsEmployeeId } = action.payload.request as NotificationsDrawerRequest;

      if (hasUnreadNotifications)
        yield put(
          retrieveNewNotificationsRequested(
            new RetrieveNotificationsRequest({
              employeeId: notificationsEmployeeId,
              pageSize: 10,
              pageNumber: 1
            })
          )
        );

      break;
    case DrawerType.EmployeeRecord:
      const { erbCategoryId, erTemplateSectionId } = action.payload.request as EmployeeRecordDrawerRequest;

      yield put(
        retrieveErbFieldsByCategoryIdRequested({
          request: new RetrieveErbFieldsByCategoryIdRequest({ erbCategoryId }),
          sectionId: erTemplateSectionId
        })
      );

      yield put(setActiveSectionId(erTemplateSectionId));

      break;
    case DrawerType.EmployeeRecordCustomField:
      break;
    case DrawerType.Assets:
      const { fileItem } = action.payload.request as AssetsDrawerRequest;

      yield put(setActiveFile(fileItem));

      yield put(
        retrieveFilesInfoRequested(
          new RetrieveFilesInfoRequest({
            selectedFileIds: [fileItem.id]
          })
        )
      );

      break;
    case DrawerType.AssetsMultipleFiles:
      const { fileIds } = action.payload.request as AssetsMultipleFilesDrawerRequest;

      yield put(
        retrieveFilesInfoRequested(
          new RetrieveFilesInfoRequest({
            selectedFileIds: fileIds
          })
        )
      );

      break;
    case DrawerType.AssetsShareFile:
      const { fileId } = action.payload.request as AssetsShareFileDrawerRequest;

      yield put(
        retrieveFilesAccessRequested(
          new RetrieveFilesAccessRequest({
            selectedFileIds: [fileId]
          })
        )
      );
      break;
    default:
      break;
  }
}

function* openDrawerWatcher() {
  yield takeLatest(openDrawer.type, openDrawerGenerator);
}

export function* drawerSagas() {
  yield all([fork(openDrawerWatcher)]);
}
