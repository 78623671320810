import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { DrawerType, OpenDrawerRequest, TasksEditTaskDrawerRequest } from '@features/drawer';
import { RootState } from '@app/store';
import { last } from 'lodash';
import { SingleTaskTabs } from '../tasks';

interface DrawerState {
  drawerStack: DrawerType[];
  activeEditTaskTab: SingleTaskTabs;
}

const initialState: DrawerState = {
  drawerStack: [],
  activeEditTaskTab: SingleTaskTabs.Details
};

export const drawerSlice = createSlice({
  name: 'drawer',
  initialState,
  reducers: {
    openDrawer: (state, action: PayloadAction<OpenDrawerRequest>) => {
      const { type } = action.payload;

      if (last(state.drawerStack) !== type) {
        state.drawerStack.push(type);
      }
    },
    goToPrevious: (state) => {
      state.drawerStack.pop();
    },
    closeDrawer: (state) => {
      state.drawerStack = [];
    },
    openTasksEditTaskDrawer: (state, action: PayloadAction<TasksEditTaskDrawerRequest>) => {
      const { activeTab } = action.payload;

      if (last(state.drawerStack) !== DrawerType.TasksEditTask) {
        state.drawerStack.push(DrawerType.TasksEditTask);
      }

      state.activeEditTaskTab = activeTab;
    }
  }
});

const selectDrawerStack = (state: RootState) => state.drawer.drawerStack;

export const selectIsDrawerOpen = createSelector([selectDrawerStack], (drawerStack) => drawerStack.length > 0);
export const selectIsMoreThanOneDrawer = createSelector([selectDrawerStack], (drawerStack) => drawerStack.length > 1);
export const selectCurrentDrawerType = createSelector([selectDrawerStack], (drawerStack) => last(drawerStack));

export const selectActiveEditTaskTab = (state: RootState) => state.drawer.activeEditTaskTab;

export const { openDrawer, goToPrevious, closeDrawer, openTasksEditTaskDrawer } = drawerSlice.actions;
export default drawerSlice.reducer;
