import { alpha, styled } from '@mui/material';
import { TreeItem2Content } from '@mui/x-tree-view/TreeItem2';

export const AssetsTreeItemContent = styled(TreeItem2Content)(({ theme }) => ({
  flexDirection: 'row-reverse',
  borderRadius: theme.spacing(0.7),
  marginBottom: theme.spacing(0.5),
  marginTop: theme.spacing(0.5),
  padding: theme.spacing(0.5),
  paddingRight: theme.spacing(1),
  color: theme.palette.common.black,
  fontSize: 14,
  fontWeight: 400,
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.1)
  },
  [`&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused`]: {
    backgroundColor: theme.palette.customTheme.selectItem
  }
}));
