import { Box, Stack } from '@mui/material';
import { FileType } from '@thrivea/organization-client';
import { FileTypeMedia } from '@/shared';

interface FileTypeMediaBoxProps {
  fileType: FileType;
  width: number;
  height: number;
}

export const FileTypeMediaBox: React.FC<FileTypeMediaBoxProps> = ({ fileType, width, height }) => {
  return (
    <Stack
      sx={{
        width: 126,
        height: 102,
        backgroundColor: (theme) => theme.palette.customTheme.solidBgr,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 4
      }}
    >
      <FileTypeMedia width={width} height={height} fileType={fileType} />
    </Stack>
  );
};
